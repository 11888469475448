<template>
  <TheTopBar :offset="offset" :controls="controls" :menu-offset="menuOffset">
    <template #pointer-events-disabled-relative-slot>
      <TopBarLabel v-if="!!labelText" align="left">
        {{ labelText }}
      </TopBarLabel>
    </template>
  </TheTopBar>
</template>

<script>
import TheTopBar from '@/components/Gadgets/TheTopBar'
import TopBarControlsSuite from '@/classes/TopBarControls/TopBarControlsSuite'
import TopBarButtonIconGenerator from '@/classes/TopBarControls/TopBarButtonIconGenerator'
import TopBarButtonGenerator from '@/classes/TopBarControls/TopBarButtonGenerator'
import TopBarLabel from '@/components/Gadgets/TheTopBar/snippets/TopBarLabel'
import responsiveMixin from '@/mixins/responsiveMixin'
import iconNames from '@/constants/ui-kit/standard/icon-names'

export default {
  name: 'TopviewTopBarControls',
  components: {
    TheTopBar,
    TopBarLabel,
  },
  mixins: [responsiveMixin],
  props: {
    isLevelsMenuOpen: {
      type: Boolean,
      default: undefined,
    },
    labelText: {
      type: String,
      default: undefined,
    },
    menuOffset: {
      type: Number,
      default: 0,
    },
    offset: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    controls() {
      return new TopBarControlsSuite({
        rightControls: [
          TopBarButtonGenerator.create({
            buttonText: this.$t('Cambiar planta'),
            show: () => !this.isLevelsMenuOpen && !this.isSmallScreen,
            eventHandler: () => this.$emit('open-levels-menu'),
          }),
          TopBarButtonIconGenerator.create({
            buttonIcon: iconNames.UIFloors,
            show: () => !this.isLevelsMenuOpen && this.isSmallScreen,
            eventHandler: () => this.$emit('open-levels-menu'),
          }),
        ],
      })
    },
  },
}
</script>
