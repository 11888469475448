import BaseProjectElement from '@/classes/ProjectStructure/BaseProjectElement'
import BaseProjectRootElement from '@/classes/ProjectStructure/BaseProjectRootElement'
import SlugGenerator from '@/classes/SlugGenerator'

class ExteriorTour360RouteObjectGenerator {
  static get ROUTE_NAME() {
    return 'exterior-tour360'
  }
  static make(projectElement, customParams) {
    if (!(projectElement instanceof BaseProjectElement)) {
      throw new Error(
        'ExteriorTour360RouteObjectGenerator.make(): the passed projectElement argument is not an instance of BaseProjectElement.'
      )
    }
    if (
      !projectElement.exteriorTour360Url ||
      typeof projectElement.exteriorTour360Url !== 'string' ||
      projectElement.exteriorTour360Url.trim() === ''
    ) {
      throw new Error(
        'ExteriorTour360RouteObjectGenerator.make(): the passed projectElement does not have an exterior tour360.'
      )
    }

    const isTheProjectRootElementExteriorTour360 =
      projectElement instanceof BaseProjectRootElement
    const exteriorTour360Params = isTheProjectRootElementExteriorTour360
      ? undefined
      : {
          projectElementId: projectElement.id,
          descriptiveSlug: SlugGenerator.make(projectElement),
        }

    return {
      name: ExteriorTour360RouteObjectGenerator.ROUTE_NAME,
      params: {
        ...customParams,
        ...exteriorTour360Params,
      },
    }
  }
}
export default ExteriorTour360RouteObjectGenerator
