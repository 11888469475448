<template>
  <section class="container">
    <h1>Project color pallete</h1>
    <div class="pallete">
      <div v-for="variant in variants" :key="variant" class="pallete__item">
        <p>{{ variant }}</p>
        <div
          class="sample"
          :style="`background-color: var(--primary-color-${variant});`"
        ></div>
        <p>{{ projectColorPallete.variant(variant) }}</p>
      </div>
    </div>
    <h1>Examples</h1>
    <div
      v-for="example in exampleColors"
      :key="example.name"
      class="pallete_example"
    >
      <h2>{{ example.name }}</h2>
      <div class="pallete">
        <div v-for="variant in variants" :key="variant" class="pallete__item">
          <p>{{ variant }}</p>
          <div
            class="sample"
            :style="`background-color: ${example.pallete.variant(variant)};`"
          ></div>
          <p>{{ example.pallete.variant(variant) }}</p>
        </div>
      </div>
    </div>
    <h1>Gray pallete</h1>
    <div class="pallete">
      <div v-for="variant in variants" :key="variant" class="pallete__item">
        <p>{{ variant }}</p>
        <div
          class="sample"
          :style="`background-color: var(--gray-${variant});`"
        ></div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import ColorPallete from '@/classes/ColorPallete'

export default {
  name: 'AppButtonIconViewer',
  data() {
    return {
      exampleColors: [
        {
          name: 'GRUPO BINIAN',
          pallete: new ColorPallete('#004EFF'),
        },
        {
          name: 'PH BALCONY',
          pallete: new ColorPallete('#7C572F'),
        },
        {
          name: 'THE GRAND',
          pallete: new ColorPallete('#A79A83'),
        },
        {
          name: 'THE RIVUS',
          pallete: new ColorPallete('#2D4950'),
        },
        {
          name: 'NUOVO',
          pallete: new ColorPallete('#1B1B1B'),
        },
        {
          name: 'Disponible',
          pallete: new ColorPallete('#039855'),
        },
        {
          name: 'Reservado',
          pallete: new ColorPallete('#DC6803'),
        },
        {
          name: 'Vendido',
          pallete: new ColorPallete('#D92D20'),
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      projectColorPallete: 'Project/projectColorPallete',
    }),
    variants() {
      return this.projectColorPallete.variantIds
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  margin: 80px 50px;
}
h1 {
  font-size: 1.5rem;
  margin: 2.5em 0 1.5em;
  border-bottom: 1px solid black;
}
h2 {
  font-size: 0.75rem;
  text-transform: lowercase;
  margin-bottom: 1.5em;
  color: var(--gray-500);
  border-bottom: 1px solid var(--gray-300);
  padding: 0.25em 0;
}
.pallete {
  display: flex;
}
.pallete__item p {
  font-size: 0.75rem;
  text-align: center;
  margin: 0 auto 0.5em;
}
.sample {
  width: 80px;
  height: 80px;
  margin: 5px;
}
.pallete_example {
  margin-top: 2rem;
}
</style>
