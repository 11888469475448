import Feature from './Feature'

class DecimalValueFeature extends Feature {
  constructor(featureData, i18n) {
    super(featureData, i18n)
    const parsedNumber = Number.parseFloat(featureData.value)
    if (!Number.isNaN(parsedNumber)) {
      this._value = parsedNumber
    } else {
      throw new Error(
        `Cannot parse value ${featureData.value} to a valid Decimal`
      )
    }
  }
}
export default DecimalValueFeature
