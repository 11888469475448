import BaseMenuOption from '@/classes/Navigation/MenuOption/BaseMenuOption'

export default class LinkMenuOption extends BaseMenuOption {
  constructor(rawData) {
    super(rawData)

    const { routeName, routeParams, exact } = rawData

    this._routeName = routeName
    this._routeParams = routeParams
    this._exact = exact
  }

  get routeName() {
    return this._routeName
  }
  get routeParams() {
    return this._routeParams
  }
  set routeParams(newParams) {
    this._routeParams = newParams
  }
  get exact() {
    return this._exact
  }
}
