export default class MenuFilters {
  static filterByProjectType(menuOption, projectType) {
    const supportedProjectTypes = menuOption.availableOnTheseProjectTypes

    return supportedProjectTypes
      ? supportedProjectTypes.includes(projectType)
      : true
  }
  static filterByOfflineMode(menuOption, isOfflineModeEnabled) {
    if (isOfflineModeEnabled) {
      return menuOption.availableInOfflineMode
    } else {
      return true
    }
  }
  static filterByFeatureFlags(menuOption, enabledFeatureFlags) {
    const requiredFeatureFlags = menuOption.availableIfTheseFeatureFlagsExist

    if (requiredFeatureFlags) {
      for (const requiredFeatureFlag of requiredFeatureFlags) {
        if (!enabledFeatureFlags.includes(requiredFeatureFlag)) {
          return false
        }
      }
    }

    return true
  }
  static filterByRequiredParams(menuOption, params) {
    const requiredParams = menuOption.allowedByParams

    if (requiredParams) {
      for (const requiredParamKey of requiredParams) {
        if (params[requiredParamKey] !== true) {
          return false
        }
      }
    }

    return true
  }
}
