<template>
  <TheFullViewportContainer class="gallery">
    <ThePaginationControls
      :page-routes="collectionPages"
      :prev-button-offset="leftSidebarWidth"
      @unrecognized-route="onUnrecognizedRoute"
    />
    <PointOfInterestTopBarControls
      :should-details-button-be-shown="shouldDetailsButtonBeShown"
      :menu-offset="leftSidebarWidth"
      @show-details="showDetails"
    />
    <ModalHintContainer
      v-if="shouldDisplayModalHint"
      hint-key="HorizontalScrollHint"
    />
    <TheInfoPanel
      ref="pointOfInterestDetails"
      :keep-open-in-small-screens-vertical="true"
      :hidden="isFullscreenUICleanModeEnabled"
      @width-updated="updateLeftSidebarWidth"
      @open="pointOfInterestInfoPanelOpen"
      @closed="pointOfInterestInfoPanelClosed"
    >
      <template #large>
        <PointOfInterestPanelLargeContent
          :project-element="pointOfInterestObject"
        />
      </template>
      <template #small>
        <PointOfInterestPanelSmallContent
          :project-element="pointOfInterestObject"
        />
      </template>
    </TheInfoPanel>
  </TheFullViewportContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import ThePaginationControls from '@/components/Gadgets/ThePaginationControls'
import responsiveMixin from '@/mixins/responsiveMixin'
import TheFullViewportContainer from '@/components/Gadgets/TheFullViewportContainer'
import ModalHintContainer from '@/components/Hint/ModalHintContainer'
import HomeRouteObjectGenerator from '@/classes/RouteObjectGenerators/HomeRouteObjectGenerator'
import PointOfInterestRouteObjectGenerator from '@/classes/RouteObjectGenerators/PointOfInterestRouteObjectGenerator'
import PointOfInterestTopBarControls from '@/components/ViewTopBarControls/PointOfInterestTopBarControls'
import TheInfoPanel from '@/components/Gadgets/TheInfoPanel'
import PointOfInterestPanelSmallContent from '@/components/PanelContent/panels/PointOfInterestPanel/PointOfInterestPanelSmallContent'
import PointOfInterestPanelLargeContent from '@/components/PanelContent/panels/PointOfInterestPanel/PointOfInterestPanelLargeContent'

export default {
  name: 'PointOfInterest',
  components: {
    TheFullViewportContainer,
    ThePaginationControls,
    PointOfInterestTopBarControls,
    ModalHintContainer,
    TheInfoPanel,
    PointOfInterestPanelSmallContent,
    PointOfInterestPanelLargeContent,
  },
  mixins: [responsiveMixin],
  props: {
    descriptiveSlug: {
      type: String,
      default: () => undefined,
    },
    pointOfInterestId: {
      type: String,
      default: () => undefined,
    },
    resourceId: {
      type: String,
      default: () => '1',
    },
  },
  data() {
    return {
      isInfoPanelOpen: false,
      isFullscreenUICleanModeEnabled: false,
      leftSidebarWidth: 0,
    }
  },
  computed: {
    ...mapGetters({
      findPointOfInterestById: 'Topview/findPointOfInterestById',
    }),
    pointOfInterestObject() {
      return this.findPointOfInterestById(this.pointOfInterestId)
    },
    currentCollection() {
      return this.pointOfInterestObject.gallery.defaultCollection
    },
    collectionPages() {
      const routerLinks = []
      for (let i = 0; i < this.currentCollection.size; i++) {
        const minimumResourceId = 1
        const resourceId = (minimumResourceId + i).toString()
        const routerObject = PointOfInterestRouteObjectGenerator.make(
          this.pointOfInterestObject,
          {
            useTransition: true,
            resourceId,
          }
        )
        routerLinks.push(routerObject)
      }
      return routerLinks
    },
    shouldDisplayModalHint() {
      return this.isSmallScreenVertical
    },
    shouldDetailsButtonBeShown() {
      return !this.isInfoPanelOpen && !this.isSmallScreenVertical
    },
  },
  mounted() {
    if (this.isSmallScreenVertical) {
      this.$store.dispatch('Layout/setTopLayerCurrentHint', 'RotateDeviceHint')
    }
    this.showDetails()
  },
  methods: {
    pointOfInterestInfoPanelOpen() {
      this.isInfoPanelOpen = true
    },
    pointOfInterestInfoPanelClosed() {
      this.isInfoPanelOpen = false
    },
    updateLeftSidebarWidth(infoPanelWidth) {
      this.leftSidebarWidth = infoPanelWidth
    },
    showDetails() {
      if (this.$refs.pointOfInterestDetails) {
        this.$refs.pointOfInterestDetails.open()
      }
    },
    onUnrecognizedRoute({ alternativeRoute }) {
      const homeRouteObject = HomeRouteObjectGenerator.make()
      const redirectionRoute = alternativeRoute || homeRouteObject
      redirectionRoute.params.useTransition = false
      this.$loggingService.logError(
        `PointOfInterest: The current route '${this.$route.path}' is not recognized in the GalleryNav as a valid option. ` +
          `Redirecting to: ${JSON.stringify(redirectionRoute)}.`
      )
      this.$router.replace(redirectionRoute)
    },
  },
}
</script>

<style></style>
