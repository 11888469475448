<template>
  <section>
    <VTitle text="AppMenuItem" />
    <div class="example-component">
      <div class="example-component__controls">
        <VPropControl
          v-for="(propModel, propName) in propModels"
          :key="propName"
          :model="propModel"
          :name="propName"
        />
      </div>
      <div class="example-component__component">
        <AppMenuItem
          :text="propModels.text.value"
          :icon="propModels.icon.value"
          :highlighted="propModels.highlighted.value"
          :with-submenu="propModels.withSubmenu.value"
          :with-connection-warning="propModels.withConnectionWarning.value"
        />
      </div>
    </div>
  </section>
</template>

<script>
import AppMenuItem from '@/components/UIKit/Standard/Atoms/AppMenuItem.vue'
import AppPropModel from '@/components/UIKit/Standard/ViewerSections/classes/AppPropModel'
import VTitle from '@/components/UIKit/Standard/ViewerSections/components/snippets/VTitle'
import VPropControl from '@/components/UIKit/Standard/ViewerSections/components/snippets/VPropControl'
import iconNames from '@/constants/ui-kit/standard/icon-names'

export default {
  name: 'AppButtonTextWithIconViewer',
  components: {
    AppMenuItem,
    VTitle,
    VPropControl,
  },
  data() {
    return {
      propModels: {
        text: new AppPropModel({
          type: String,
          defaultValue: 'Areas',
          required: true,
        }),
        icon: new AppPropModel({
          type: String,
        }),
        highlighted: new AppPropModel({
          type: Boolean,
          defaultValue: true,
        }),
        withSubmenu: new AppPropModel({
          type: Boolean,
          defaultValue: true,
        }),
        withConnectionWarning: new AppPropModel({
          type: Boolean,
          defaultValue: true,
        }),
      },
    }
  },
  created() {
    this.propModels.icon.addPreset(iconNames.UIAreas, iconNames.UIAreas)
    this.propModels.icon.addPreset(iconNames.UIFloors, iconNames.UIFloors)
    this.propModels.icon.addPreset(iconNames.UIHome, iconNames.UIHome)
    this.propModels.icon.addPreset(iconNames.UIAmenities, iconNames.UIAmenities)
  },
}
</script>

<style lang="scss" scoped>
.example-component {
  display: flex;
  justify-content: stretch;
  align-items: stretch;

  @at-root &__controls {
    background: #fff;
    padding: 30px;
    border: 1px solid var(--gray-200);
    flex-basis: 300px;
    border-radius: 12px;
  }

  @at-root &__component {
    padding: 30px;
    width: 340px;
  }
}
</style>
