<template>
  <div>
    <slot v-if="showSlot"></slot>
    <no-internet-connection v-else></no-internet-connection>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NoInternetConnection from '@/components/Error/NoInternetConnection'
import onlineStatusMixin from '@/mixins/onlineStatusMixin'

export default {
  name: 'ConnectionRequiredOfflineModeWrapper',
  components: {
    NoInternetConnection,
  },
  mixins: [onlineStatusMixin],
  data() {
    return {
      showSlot: false,
    }
  },
  computed: {
    ...mapGetters({
      isOfflineModeEnabled: 'Project/isOfflineModeEnabled',
    }),
  },
  created() {
    this.showSlot = this.isOfflineModeEnabled ? this.isOnline : true
  },
}
</script>

<style scoped></style>
