import MessageFormat from '@messageformat/core'
import { parse } from '@messageformat/parser'
export default class CustomFormatter {
  constructor({ locale, missingInterpolatedValuesHandler }) {
    this._locale = locale
    this._missingInterpolatedValuesHandler = missingInterpolatedValuesHandler
    this._formatter = new MessageFormat(this._locale, { returnType: 'values' })
    this._caches = Object.create(null)
  }

  interpolate(message, values) {
    let fn = this._caches[message]
    if (!fn) {
      fn = this._formatter.compile(message)
      this._caches[message] = fn
    }
    this._validateValuesToInterpolate(message, values)
    return fn(values).flat(Infinity)
  }

  _getMessageArguments(messageTokens) {
    let messageArguments = []
    let selectTokenTypes = ['plural', 'select', 'selectordinal']

    messageTokens.forEach((token) => {
      if (token.arg) {
        messageArguments.push(token.arg)
      }

      if (selectTokenTypes.includes(token.type) && token.cases) {
        token.cases.forEach((selectCase) => {
          if (selectCase.tokens) {
            messageArguments = messageArguments.concat(
              this._getMessageArguments(selectCase.tokens)
            )
          }
        })
      }
    })

    return messageArguments
  }

  _validateValuesToInterpolate(message, availableValuesToInterpolate) {
    let messageTokens
    try {
      messageTokens = parse(message)
    } catch (e) {
      throw new Error(
        `CustomFormatter._validateValuesToInterpolate(): Error trying to parse the message '${message}': ${e}`
      )
    }

    const requiredValuesFoundInMessage = this._getMessageArguments(
      messageTokens
    )

    for (let requiredValue of requiredValuesFoundInMessage) {
      if (!(requiredValue in availableValuesToInterpolate)) {
        this._missingInterpolatedValuesHandler.handleMissingInterpolatedValue(
          message,
          requiredValue
        )
      }
    }
  }
}
