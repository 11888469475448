<template>
  <TheTopBar
    :controls="controls"
    with-fullscreen-button
    :menu-offset="menuOffset"
  />
</template>

<script>
import TheTopBar from '@/components/Gadgets/TheTopBar'
import TopBarControlsSuite from '@/classes/TopBarControls/TopBarControlsSuite'
import TopBarButtonGenerator from '@/classes/TopBarControls/TopBarButtonGenerator'

export default {
  name: 'PointOfInterestTopBarControls',
  components: {
    TheTopBar,
  },
  props: {
    shouldDetailsButtonBeShown: {
      type: Boolean,
      default: undefined,
    },
    menuOffset: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    controls() {
      return new TopBarControlsSuite({
        rightControls: [
          TopBarButtonGenerator.create({
            buttonText: this.$t('Ver detalles'),
            show: () => this.shouldDetailsButtonBeShown && !this.isSmallScreen,
            eventHandler: () => this.$emit('show-details'),
          }),
        ],
      })
    },
  },
}
</script>
