import TopBarControl from '@/classes/TopBarControls/TopBarControl'

export default class TopBarControlsSuite {
  constructor({ leftControls = [], rightControls = [] }) {
    if (!TopBarControlsSuite.isControlsArrayValid(leftControls)) {
      throw new Error(
        'TopBarControlsSuite: leftControls passed is not a TopBarControls Array'
      )
    }
    if (!TopBarControlsSuite.isControlsArrayValid(rightControls)) {
      throw new Error(
        'TopBarControlsSuite: rightControls passed is not a TopBarControls Array'
      )
    }
    this._leftControls = leftControls
    this._rightControls = rightControls
    this._setControlsKeys()
  }

  _setControlsKeys() {
    this._leftControls.forEach((control, index) => {
      control.setKey(`top-bar-left-control-${index}`)
    })
    this._rightControls.forEach((control, index) => {
      control.setKey(`top-bar-right-control-${index}`)
    })
  }

  get leftControls() {
    return this._leftControls.filter((control) => control.show())
  }

  get rightControls() {
    return this._rightControls.filter((control) => control.show())
  }

  static isControlsArrayValid(controls, checkTopBarControlKeys = false) {
    const isATopBarControlArray =
      Array.isArray(controls) &&
      controls.every((control) => control instanceof TopBarControl)
    if (isATopBarControlArray && checkTopBarControlKeys) {
      const topBarControlKeys = controls.map((control) => control.key)
      return topBarControlKeys.every(
        (key, index) =>
          key !== undefined && topBarControlKeys.indexOf(key) == index
      )
    }
    return isATopBarControlArray
  }
}
