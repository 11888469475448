<template>
  <div class="the-tour-360-viewer">
    <transition name="the-tour-360-viewer__transition--slide-left-short">
      <iframe
        :key="url"
        class="the-tour-360-viewer__iframe"
        :src="url"
        @load="removeCanvasOutlineOfLayamaTour360"
      ></iframe>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'TheTour360Viewer',
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  methods: {
    removeCanvasOutlineOfLayamaTour360(event) {
      try {
        const iframe = event.target
        const iframeBody =
          iframe.contentDocument || iframe.contentWindow.document
        const styles = `canvas:focus {outline: none}`

        const styleTag = iframeBody.createElement('style')
        if (styleTag.styleSheet) {
          styleTag.styleSheet.cssText = styles
        } else {
          const styleNode = iframeBody.createTextNode(styles)
          styleTag.appendChild(styleNode)
        }

        iframeBody.getElementsByTagName('head')[0].appendChild(styleTag)
      } catch (e) {
        this.$loggingService.logError(
          `There was a problem trying to apply styles to iframe in tour360. ${e.toString()} `
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.the-tour-360-viewer {
  --layama-tour360-background-color: #111;
  background-color: var(--layama-tour360-background-color);

  @at-root #{&}__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }

  @at-root #{&}__transition--slide-left-short-enter-active,
    #{&}__transition--slide-left-short-leave-active {
    transition: all 300ms ease;
  }
  @at-root #{&}__transition--slide-left-short-enter,
    #{&}__transition--slide-left-short-leave-to {
    opacity: 0;
    transform: translateX(-10px);
  }
}
</style>
