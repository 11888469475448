<template>
  <div class="top-bar-control-wrapper">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'TopBarControlWrapper',
}
</script>

<style lang="scss" scoped>
.top-bar-control-wrapper {
  pointer-events: all;
}
</style>
