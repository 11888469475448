<template>
  <div class="app-social-network-buttons">
    <AppButtonIconExternalLink
      v-if="facebookUrl"
      :icon="facebookIcon"
      :href="facebookUrl"
      :button-style="socialIconStyle"
      :size="socialIconSize"
    />
    <AppButtonIconExternalLink
      v-if="instagramUrl"
      :icon="instagramIcon"
      :href="instagramUrl"
      :button-style="socialIconStyle"
      :size="socialIconSize"
    />
    <AppButtonIconExternalLink
      v-if="whatsappUrl"
      :icon="whatsappIcon"
      :href="whatsappUrl"
      :button-style="socialIconStyle"
      :size="socialIconSize"
    />
  </div>
</template>

<script>
import AppButtonIconExternalLink from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonIconExternalLink'
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'
import iconNames from '@/constants/ui-kit/standard/icon-names'

export default {
  name: 'AppSocialNetworkButtons',
  components: {
    AppButtonIconExternalLink,
  },
  props: {
    facebookUrl: {
      type: String,
      default: undefined,
    },
    instagramUrl: {
      type: String,
      default: undefined,
    },
    whatsappUrl: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      socialIconSize: sizes.SM,
      socialIconStyle: styles.EXTRA_LIGHT,
      facebookIcon: iconNames.UIFacebook,
      instagramIcon: iconNames.UIInstagram,
      whatsappIcon: iconNames.UIWhatsapp,
    }
  },
}
</script>

<style lang="scss" scoped>
.app-social-network-buttons {
  display: flex;
  gap: 1.25rem;
  width: 100%;
  justify-content: center;
}
</style>
