<template>
  <AppSheetPointOfInterestLarge
    :title="pointOfInterest.title"
    :preview-image-url="pointOfInterest.previewImageUrl"
    :long-rich-text="pointOfInterest.longRichText"
    :tour360-to="exteriorTour360RouteObject"
    :share-url="currentUrl"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import responsiveMixin from '@/mixins/responsiveMixin'
import PointOfInterest from '@/classes/ProjectStructure/PointOfInterest'
import AppSheetPointOfInterestLarge from '@/components/UIKit/Standard/Organisms/AppSheet/AppSheetPointOfInterestLarge'
import ExteriorTour360RouteObjectGenerator from '@/classes/RouteObjectGenerators/ExteriorTour360RouteObjectGenerator'

export default {
  name: 'PointOfInterestPanelLargeContent',
  components: {
    AppSheetPointOfInterestLarge,
  },
  mixins: [responsiveMixin],
  props: {
    projectElement: {
      type: PointOfInterest,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      logoData: 'Project/logo',
      isOfflineModeEnabled: 'Project/isOfflineModeEnabled',
    }),
    pointOfInterest() {
      return this.projectElement
    },
    hasExteriorTour360() {
      return !!this.pointOfInterest.exteriorTour360Url
    },
    exteriorTour360RouteObject() {
      return this.hasExteriorTour360
        ? ExteriorTour360RouteObjectGenerator.make(this.pointOfInterest)
        : undefined
    },
    currentUrl() {
      return this.isOfflineModeEnabled ? undefined : window.location.href
    },
  },
}
</script>
