<template>
  <SvgInteractionsListener
    class="svg-interactions-listener"
    :svg-url="svgUrl"
    :svg-data="svgData"
    use-unique-element-id-throughout-project
  />
</template>
<script>
import { mapGetters } from 'vuex'
import SvgInteractionsListener from '@/components/Gadgets/SvgInteractionsListener'

export default {
  name: 'AvailabilityInteractiveSvgLayer',
  components: {
    SvgInteractionsListener,
  },
  props: {
    svgUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      availabilityGallerySvgData:
        'AvailabilityGalleryModule/availabilityGallerySvgData',
    }),
    svgData() {
      return this.availabilityGallerySvgData
    },
  },
}
</script>
<style lang="scss" scoped>
.svg-interactions-listener {
  @at-root #{&}::v-deep {
    svg {
      [id$='-marker'] {
        opacity: 0.7;
      }
    }
  }
}
</style>
