import ProjectElementTypes from '@/constants/project-structure/project-element-types'
import BaseProjectElement from '@/classes/ProjectStructure/BaseProjectElement'

export default class Level extends BaseProjectElement {
  constructor({ name, id, svgTargetElementId, order, image, svg, slug }) {
    super({ id, svgTargetElementId })
    this._name = name
    this._order = order
    this._image = image
    this._svg = svg
    this._slug = slug
    this.initSvgData()
  }
  get name() {
    return this._name
  }
  get order() {
    return this._order
  }
  get image() {
    return this._image
  }
  get svg() {
    return this._svg
  }
  get slug() {
    return this._slug
  }
  get projectElementType() {
    return ProjectElementTypes.LEVEL
  }
  get _validChildElementTypes() {
    return [ProjectElementTypes.UNIT, ProjectElementTypes.POINT_OF_INTEREST]
  }
}
