export default class NavigationHistory {
  constructor() {
    this._savedNavigationSteps = []
  }

  pushNavigationStep(navigationStep) {
    if (!this._validateNavigationStep(navigationStep)) {
      throw new Error(
        'NavigationHistory.pushNavigationStep(): Invalid navigationStep passed'
      )
    }
    this._savedNavigationSteps.unshift(navigationStep)
  }
  getLastNavigationStep() {
    return this._savedNavigationSteps[0]
  }
  popNavigationStep() {
    return this._savedNavigationSteps.shift()
  }

  _validateNavigationStep(route) {
    return route !== null && typeof route === 'object'
  }
}
