<template>
  <div
    ref="zoomManager"
    class="the-zoom-manager"
    :class="{
      'the-zoom-manager--active': isZoomActive,
      'the-zoom-manager--use-transitions': useZoomTransitions,
    }"
    :style="{ transform: transformScale }"
  >
    <div
      ref="imageWrapper"
      class="the-zoom-manager__position"
      :class="{
        'the-zoom-manager__position--use-transitions': usePositionTransitions,
      }"
      :style="{ transform: transformPosition }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  maxZoomValue,
  minZoomValue,
  defaultZoomStep,
} from '@/constants/zoom-tracker'

export default {
  name: 'TheZoomManager',
  props: {
    scrollPositionX: {
      type: Number,
      default: 0,
    },
    scrollPositionY: {
      type: Number,
      default: 0,
    },
    enabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isZoomActive: false,
      isResetting: false,
      minZoom: minZoomValue,
      maxZoom: maxZoomValue,
      tapTimestamp: 0,
      gesturePinchOrSpreadDistance: 0,
      gestureDragInitialPosition: {
        x: 0,
        y: 0,
      },
    }
  },
  computed: {
    ...mapGetters({
      imageZoom: 'ZoomTracker/imageZoom',
      imagePosition: 'ZoomTracker/imagePosition',
      gestureDragActive: 'ZoomTracker/gestureDragActive',
      gesturePinchOrSpreadActive: 'ZoomTracker/gesturePinchOrSpreadActive',
      isAnyGestureActive: 'ZoomTracker/isAnyGestureActive',
    }),
    zoomTranslateX() {
      return `${this.imagePosition.x}px`
    },
    zoomTranslateY() {
      return `${this.imagePosition.y}px`
    },
    isZoomed() {
      return this.imageZoom !== defaultZoomStep
    },
    transformScale() {
      return this.isZoomActive ? `scale(${this.imageZoom})` : 'none'
    },
    transformPosition() {
      return this.isZoomActive
        ? `translateX(calc(-50% + ${this.zoomTranslateX})) translateY(calc(-50% + ${this.zoomTranslateY}))`
        : 'none'
    },
    useZoomTransitions() {
      return (
        this.isZoomActive &&
        !this.gestureDragActive &&
        !this.gesturePinchOrSpreadActive
      )
    },
    usePositionTransitions() {
      return (
        this.isZoomActive &&
        !this.isZoomed &&
        !this.gestureDragActive &&
        !this.gesturePinchOrSpreadActive
      )
    },
  },
  watch: {
    async imageZoom(newValue) {
      if (newValue !== defaultZoomStep) {
        await this.beginZoom()
        this.applyPositionLimits()
      } else {
        this.reset({ useTransition: true, center: false })
      }
    },
  },
  mounted() {
    const isTouchDevice =
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0

    if (isTouchDevice) {
      this.$refs.zoomManager.addEventListener('touchstart', this.onTouchStart)
      this.$refs.zoomManager.addEventListener('touchmove', this.onTouchMove, {
        passive: false,
      })
      this.$refs.zoomManager.addEventListener('touchend', this.onTouchEnd)
      this.$refs.zoomManager.addEventListener('touchcancel', this.onTouchEnd)
    } else {
      this.$refs.zoomManager.addEventListener('mousedown', this.onMouseDown)
      window.addEventListener('mouseup', this.onMouseUp)
    }
  },
  beforeDestroy() {
    this.$refs.zoomManager.removeEventListener('touchstart', this.onTouchStart)
    this.$refs.zoomManager.removeEventListener('touchmove', this.onTouchMove)
    this.$refs.zoomManager.removeEventListener('touchend', this.onTouchEnd)
    this.$refs.zoomManager.removeEventListener('touchcancel', this.onTouchEnd)

    this.$refs.zoomManager.removeEventListener('mousedown', this.onMouseDown)
    this.$refs.zoomManager.removeEventListener('mousemove', this.onMouseMove)
    window.removeEventListener('mouseup', this.onMouseUp)
  },
  methods: {
    ...mapActions({
      setImagePosition: 'ZoomTracker/setImagePosition',
      setImageZoom: 'ZoomTracker/setImageZoom',
      setGestureDragActiveStatus: 'ZoomTracker/setGestureDragActiveStatus',
      setGesturePinchOrSpreadActiveStatus:
        'ZoomTracker/setGesturePinchOrSpreadActiveStatus',
    }),
    getManhattanDistance(x1, y1, x2, y2) {
      return Math.abs(x2 - x1) + Math.abs(y2 - y1)
    },
    getWidthOverflow() {
      return this.$refs.imageWrapper.offsetWidth - window.innerWidth
    },
    constrain(value, min, max) {
      return Math.max(Math.min(value, max), min)
    },
    handleDoubleTap() {
      this.reset({ useTransition: true, center: false })
    },
    handleTap() {
      const currentTimestamp = Date.now()
      const doubleTapSpeedThreshold = 600
      if (currentTimestamp - this.tapTimestamp < doubleTapSpeedThreshold) {
        this.handleDoubleTap()
      }
      this.tapTimestamp = currentTimestamp
    },
    cancelDoubleTap() {
      if (this.tapTimestamp !== 0) {
        this.tapTimestamp = 0
      }
    },
    initGestureDrag(point) {
      const currentPositionX = this.imagePosition.x * this.imageZoom
      const currentPositionY = this.imagePosition.y * this.imageZoom
      this.gestureDragInitialPosition.x = point.pageX - currentPositionX
      this.gestureDragInitialPosition.y = point.pageY - currentPositionY
    },
    initGesturePinchOrSpread(points) {
      this.gesturePinchOrSpreadDistance = this.getManhattanDistance(
        points[0].pageX,
        points[0].pageY,
        points[1].pageX,
        points[1].pageY
      )
    },
    async updateGestureDrag(point) {
      if (!this.isZoomActive || this.isResetting) {
        return
      }

      this.setGestureDragActiveStatus(true)
      const zoomCancelation = 1 / this.imageZoom
      const newImagePositionX =
        (point.pageX - this.gestureDragInitialPosition.x) * zoomCancelation
      const newImagePositionY =
        (point.pageY - this.gestureDragInitialPosition.y) * zoomCancelation

      await this.setImagePosition({
        positionX: newImagePositionX,
        positionY: newImagePositionY,
      })
      this.applyPositionLimits()
    },
    async updateGesturePinchOrSpread(points) {
      await this.beginPinchOrSpreadGesture()

      const currentTouchesDistance = this.getManhattanDistance(
        points[0].pageX,
        points[0].pageY,
        points[1].pageX,
        points[1].pageY
      )
      const zoomIncrement =
        currentTouchesDistance / this.gesturePinchOrSpreadDistance
      const newZoom = this.constrain(
        this.imageZoom * zoomIncrement,
        this.minZoom,
        this.maxZoom
      )

      await this.setImageZoom(newZoom)
      this.gesturePinchOrSpreadDistance = currentTouchesDistance
    },
    async beginZoom() {
      if (!this.isZoomActive) {
        this.isZoomActive = true
        this.$emit('zoom-started')
        await this.$nextTick()
        const initialPosition =
          this.getWidthOverflow() / 2 - this.scrollPositionX
        await this.setImagePosition({
          positionX: initialPosition,
        })
      }
    },
    async endZoom() {
      if (this.isZoomActive) {
        const widthOverflow = this.getWidthOverflow()
        const finalPosition = widthOverflow / 2 - this.imagePosition.x
        this.isZoomActive = false
        await this.$nextTick()
        await this.setImagePosition({
          positionX: 0,
        })
        this.$emit('zoom-reset', finalPosition)
      }
    },
    endZoomOnTransitionEnd() {
      return new Promise((resolve) => {
        this.$refs.zoomManager.ontransitioncancel = () => {
          this.$refs.zoomManager.ontransitionend = null
          this.$refs.zoomManager.ontransitioncancel = null
          resolve()
        }
        this.$refs.zoomManager.ontransitionend = async () => {
          this.$refs.zoomManager.ontransitionend = null
          this.$refs.zoomManager.ontransitioncancel = null
          await this.endZoom()
          resolve()
        }
      })
    },
    async beginPinchOrSpreadGesture() {
      if (!this.gesturePinchOrSpreadActive) {
        this.setGesturePinchOrSpreadActiveStatus(true)
        await this.beginZoom()
      }
    },
    onTouchStart(e) {
      if (!this.enabled) {
        return
      }

      const points = e.touches
      const pointsCount = points.length
      if (pointsCount === 1) {
        this.handleTap()
        this.initGestureDrag(points[0])
      } else if (pointsCount === 2) {
        this.initGesturePinchOrSpread(points)
      }
    },
    onTouchMove(e) {
      if (!this.enabled) {
        return
      }

      const points = e.touches
      const pointsCount = points.length
      if (
        this.gestureDragActive ||
        (pointsCount === 1 && !this.gesturePinchOrSpreadActive)
      ) {
        this.updateGestureDrag(points[0])
      } else if (pointsCount === 2) {
        this.updateGesturePinchOrSpread(points)
      }

      this.cancelDoubleTap()
      if (e.cancelable && this.isZoomActive) {
        e.preventDefault()
      }
    },
    onTouchEnd(e) {
      if (e.touches.length === 0) {
        this.setGestureDragActiveStatus(false)
        this.setGesturePinchOrSpreadActiveStatus(false)
        if (this.imageZoom < 1.15) {
          this.reset({ useTransition: true, center: false })
        }
      }
    },
    onMouseDown(e) {
      if (!this.enabled) {
        return
      }
      const point = e
      this.handleTap()
      this.initGestureDrag(point)
      this.$refs.zoomManager.addEventListener('mousemove', this.onMouseMove)
      e.preventDefault()
    },
    onMouseMove(e) {
      const point = e
      if (this.gestureDragActive || !this.gesturePinchOrSpreadActive) {
        this.updateGestureDrag(point)
      }
      this.cancelDoubleTap()
      if (e.cancelable && this.isZoomActive) {
        e.preventDefault()
      }
    },
    onMouseUp() {
      this.setGestureDragActiveStatus(false)
      this.setGesturePinchOrSpreadActiveStatus(false)
      this.$refs.zoomManager.removeEventListener('mousemove', this.onMouseMove)
    },
    async reset({ useTransition, center }) {
      if (this.isZoomActive && !this.isResetting) {
        this.isResetting = true
        await this.setImageZoom(1)
        if (center) {
          await this.setImagePosition({
            positionX: 0,
          })
        }
        this.applyPositionLimits()

        if (useTransition) {
          await this.endZoomOnTransitionEnd()
        } else {
          await this.endZoom()
        }
        this.isResetting = false
      }
    },
    async applyPositionLimits() {
      const navigationBarHeight =
        document.body.offsetHeight - window.innerHeight
      const zoomCancelation = 1 / this.imageZoom

      const zoomedWidthOverflow =
        this.$refs.imageWrapper.offsetWidth * this.imageZoom - window.innerWidth
      const horizontalLimit = Math.floor(
        (zoomedWidthOverflow * zoomCancelation) / 2
      )
      if (zoomedWidthOverflow < 0) {
        await this.setImagePosition({
          positionX: 0,
        })
      } else {
        if (this.imagePosition.x > horizontalLimit) {
          await this.setImagePosition({
            positionX: horizontalLimit,
          })
        } else if (this.imagePosition.x < -horizontalLimit) {
          await this.setImagePosition({
            positionX: -horizontalLimit,
          })
        }
      }

      const zoomedHeightOverflow =
        this.$refs.imageWrapper.offsetHeight * this.imageZoom -
        window.innerHeight
      const verticalLimit = Math.floor(
        (zoomedHeightOverflow * zoomCancelation) / 2
      )
      if (this.imageZoom >= 1) {
        if (this.imagePosition.y > verticalLimit - navigationBarHeight) {
          await this.setImagePosition({
            positionY:
              verticalLimit - navigationBarHeight + this.scrollPositionY,
          })
        } else if (this.imagePosition.y < -verticalLimit) {
          await this.setImagePosition({
            positionY: -verticalLimit + this.scrollPositionY,
          })
        }
      } else {
        await this.setImagePosition({
          positionY: navigationBarHeight / -2,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.the-zoom-manager {
  $zoom-manager: &;
  transform-origin: 0 0;

  @at-root #{&}--use-transitions {
    transition: transform 500ms ease-in-out;
  }

  @at-root #{&}--active {
    position: fixed;
    top: calc(50% + (100vh - 100%));
    left: 50vw;
    user-select: none;
    user-zoom: none;

    #{$zoom-manager}__position {
      height: 100vh;
      width: calc((16 / 9) * 100vh);
      transform: translateX(-50%) translateY(-50%);

      @media (orientation: landscape) {
        width: 100vw;
      }

      @at-root #{&}--use-transitions {
        transition: transform 500ms cubic-bezier(0.25, 0, 0.5, 1);
      }
    }
  }
}
</style>
