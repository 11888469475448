<template>
  <a
    class="button-icon"
    :class="[styleClassName, sizeClassName, squareClassName]"
    :href="href"
    :target="target"
  >
    <AppIcon class="button-icon__icon" :size="size" :icon="icon" />
  </a>
</template>

<script>
import AppIcon from '@/components/UIKit/Standard/Atoms/AppIcon'
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'
import iconNames from '@/constants/ui-kit/standard/icon-names'

export default {
  name: 'AppButtonIconExternalLink',
  components: {
    AppIcon,
  },
  props: {
    icon: {
      type: String,
      required: true,
      validator: (value) => Object.values(iconNames).includes(value),
    },
    buttonStyle: {
      type: String,
      default: styles.PRIMARY,
      validator: (value) => Object.values(styles).includes(value),
    },
    size: {
      type: String,
      default: sizes.SM,
      validator: (value) => Object.values(sizes).includes(value),
    },
    href: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      default: '_blank',
    },
    square: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    styleClassName() {
      return `button-icon--${this.buttonStyle}`
    },
    sizeClassName() {
      return `button-icon--${this.size}`
    },
    squareClassName() {
      return this.square ? 'button-icon--square' : ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'app-button.mixin';
.button-icon {
  @include button-styles;
  @include button-icon-sizes;
  @include button-alternatives;
  @include noselect;
}
</style>
