<template>
  <section class="container">
    <h1>DropdownViewer</h1>
    <table>
      <tr>
        <th></th>
        <th v-for="size in sizes" :key="size">{{ size }}</th>
      </tr>
      <tr v-for="style in styles" :key="style">
        <th>{{ style }}</th>
        <th v-for="size in sizes" :key="style + size">
          <AppDropdown
            :text="demoText"
            :size="size"
            :dropdown-style="style"
            :options="options"
            :selected-value="selectedOption"
            @selectedOptionChange="changeSelectedOption"
          />
        </th>
      </tr>
    </table>
  </section>
</template>
<script>
import AppDropdown from '@/components/UIKit/Standard/Molecules/AppDropdown'
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'

export default {
  name: 'DropdownViewer',
  components: {
    AppDropdown,
  },
  data() {
    return {
      sizes: Object.values(sizes),
      styles: Object.values(styles),
      demoText: 'Dropdown',
      options: [
        { text: 'option 1', value: '1' },
        { text: 'option 2', value: '2' },
        { text: 'option 3', value: '3' },
        { text: 'option 4', value: '4' },
        { text: 'option disabled', value: '5', disabled: true },
      ],
      selectedOption: '1',
    }
  },
  methods: {
    changeSelectedOption(newValue) {
      this.selectedOption = newValue
    },
  },
}
</script>
<style lang="scss" scoped>
.container {
  margin: 80px 50px;
}
h1 {
  font-size: 1.5rem;
  margin: 2.5em 0 1.5em;
  border-bottom: 1px solid black;
}
th {
  font-weight: 400;
  font-size: 0.75rem;
}
th,
td {
  padding: 5px 10px;
}
</style>
