import BaseMediaResource from '@/classes/MediaResources/BaseMediaResource'

class BackgroundTree {
  constructor(router) {
    this._router = router
    this._backgroundRouteMap = {}
  }
  add({ routeObject, resource }) {
    if (!this._isRouteObjectValid(routeObject)) {
      throw new Error('BackgroundTree.add(): Invalid routeObject.')
    } else if (!this._isResourceValid(resource)) {
      throw new Error('BackgroundTree.add(): Invalid resource.')
    }
    try {
      const routePathKey = this._getRoutePath(routeObject)
      if (this._backgroundRouteMap[routePathKey] !== undefined) {
        throw new Error(
          `BackgroundTree.add(): Background for route ${routePathKey} (${JSON.stringify(
            routeObject
          )}) is already set as ${JSON.stringify(
            this._backgroundRouteMap[routePathKey]
          )}.`
        )
      }
      this._backgroundRouteMap[routePathKey] = resource
    } catch (error) {
      throw new Error(
        `BackgroundTree.add(): Error trying to resolve route. ${error.toString()}`
      )
    }
  }
  getBackgroundResourceByPath(routePathKey) {
    return this._backgroundRouteMap[routePathKey]
  }
  _isRouteObjectValid(routeObject) {
    return routeObject !== null && typeof routeObject === 'object'
  }
  _isResourceValid(resouce) {
    return resouce instanceof BaseMediaResource
  }
  _getRoutePath(routeObject) {
    return this._router.resolve(routeObject).resolved.path
  }
}

export default BackgroundTree
