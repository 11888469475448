<template>
  <AppSheetWrapper class="app-sheet-location-small">
    <AppSheetRow>
      <AppSheetTitle :title="titleContent" :align="titleAlignment" />
    </AppSheetRow>
    <AppSheetRow>
      <AppRichText :html-content="htmlContent" compact />
    </AppSheetRow>
  </AppSheetWrapper>
</template>

<script>
import AppSheetWrapper from '@/components/UIKit/Standard/Atoms/AppSheetWrapper'
import AppSheetRow from '@/components/UIKit/Standard/Atoms/AppSheetRow'
import AppSheetTitle from '@/components/UIKit/Standard/Atoms/AppSheetTitle.vue'
import AppRichText from '@/components/UIKit/Standard/Atoms/AppRichText.vue'
import alignments from '@/constants/ui-kit/standard/alignments'

export default {
  name: 'AppSheetLocationSmall',
  components: {
    AppSheetWrapper,
    AppSheetRow,
    AppSheetTitle,
    AppRichText,
  },
  props: {
    htmlContent: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      titleContent: this.$t('Detalles de ubicación'),
      titleAlignment: alignments.ALIGN_LEFT,
    }
  },
}
</script>
