import Hint from '@/classes/Hint/Hint'
import MenuGenerator from '@/classes/Navigation/MenuGenerator'
import MenuSettings from '@/classes/Navigation/MenuSettings'

import { MENU_REQUIRED_PARAMS } from '@/constants/navigation/menu-constants'

export default {
  setMenuWidth({ commit }, width) {
    commit('setMenuWidth', width)
  },
  setTopLayerCurrentHint({ commit, state }, hintKey) {
    const hint = hintKey ? state.hints[hintKey] : null
    if (hint instanceof Hint) {
      hint._incrementShowCount()
      commit('setTopLayerCurrentHint', hint)
    } else if (hint === null) {
      commit('setTopLayerCurrentHint', null)
    } else {
      throw new Error(`hintKey ${hintKey} does not match a valid hint in store`)
    }
  },
  createMenu({ commit, rootGetters }) {
    const MasterMenu = new MenuGenerator({
      projectType: rootGetters['Project/projectType'],
      isOfflineModeEnabled: rootGetters['Project/isOfflineModeEnabled'],
      enabledFeatureFlags: rootGetters['FeatureFlags/enabledFeatureFlags'],
      params: {
        [MENU_REQUIRED_PARAMS.TOWERS]: rootGetters['Topview/towers'],
        [MENU_REQUIRED_PARAMS.SUBDIVISIONS]:
          rootGetters['Topview/neighbourhoodSubdivisions'],
        [MENU_REQUIRED_PARAMS.SINGLE_TOWER]: rootGetters['Topview/singleTower'],
        [MENU_REQUIRED_PARAMS.GENERAL_AMENITIES_GALLERY]:
          rootGetters['Project/amenitiesGallery'],
        [MENU_REQUIRED_PARAMS.IS_GENERAL_AMENITIES_GALLERY_AVAILABLE]:
          rootGetters['Project/isAmenitiesGalleryAvailable'],
        [MENU_REQUIRED_PARAMS.IS_VIDEO_AVAILABLE]:
          rootGetters['Project/isVideoAvailable'],
        [MENU_REQUIRED_PARAMS.IS_LOCATION_CUSTOM_MAP_AVAILABLE]:
          rootGetters['Project/isLocationCustomMapAvailable'],
        [MENU_REQUIRED_PARAMS.IS_AVAILABILITY_VIEW_ENABLED]:
          rootGetters['Preferences/enableAvailabilityView'],
        [MENU_REQUIRED_PARAMS.PROJECT_NAME]: rootGetters['Project/projectName'],
        [MENU_REQUIRED_PARAMS.PROJECT_BROCHURE_URL]:
          rootGetters['Project/projectBrochureUrl'],
        [MENU_REQUIRED_PARAMS.IS_PROJECT_BROCHURE_URL_AVAILABLE]:
          rootGetters['Project/isProjectBrochureUrlAvailable'],
        [MENU_REQUIRED_PARAMS.IS_GENERAL_TOUR360_GALLERY_ENABLED]:
          rootGetters['Preferences/enableGeneralTour360GalleryView'],
      },
    }).make(MenuSettings.MasterMenuOptions)

    commit('storeMasterMenu', MasterMenu)
  },
}
