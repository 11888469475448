<template>
  <TheBackgroundInteractionManager
    ref="backgroundInteractionManager"
    class="the-transition-manager"
    :enabled="backgroundInteractionEnabled"
  >
    <TheTour360Viewer
      v-if="tour360Url"
      :url="tour360Url"
      class="the-transition-manager__tour360"
    />
    <div class="the-transition-manager__background-image">
      <transition
        v-if="currentTransition"
        :mode="currentTransition.componentProps.mode"
        :name="currentTransition.componentProps.name"
        :css="currentTransition.componentProps.css"
        @before-enter="beforeTransition"
        @before-leave="beforeTransition"
        @enter="enterHook"
        @leave="leaveHook"
        @after-enter="afterTransition"
        @after-leave="afterTransition"
      >
        <TheBackgroundImageViewer
          :key="routeKey"
          @mounted="onBackgroundImageViewerMounted"
        />
      </transition>
    </div>
    <FullViewportBackgroundElement
      class="the-transition-manager__transition-video"
      :class="{
        'the-transition-manager__transition-video--active': shouldDisplayTransitionVideo,
      }"
      scrollable
    >
      <video
        ref="transitionVideo"
        class="the-transition-manager__transition-video__video"
        type="video/mp4"
        playsinline
        muted
      ></video>
    </FullViewportBackgroundElement>
  </TheBackgroundInteractionManager>
</template>

<script>
import { mapGetters } from 'vuex'
import TransitionFactory from '@/classes/Transitions/TransitionFactory'
import VideoTransition from '@/classes/Transitions/VideoTransition'
import TheBackgroundInteractionManager from '@/components/Gadgets/TheBackgroundInteractionManager'
import TheTour360Viewer from '@/components/Gadgets/TheTour360Viewer'
import TheBackgroundImageViewer from '@/components/Gadgets/TheBackgroundImageViewer'
import FullViewportBackgroundElement from '@/components/Gadgets/FullViewportBackgroundElement'
import Tour360Resource from '@/classes/MediaResources/Tour360Resource'
import ImageResource from '@/classes/MediaResources/ImageResource'

export default {
  name: 'TheTransitionManager',
  components: {
    TheBackgroundInteractionManager,
    TheTour360Viewer,
    TheBackgroundImageViewer,
    FullViewportBackgroundElement,
  },
  data() {
    return {
      currentTransition: null,
      emptyTransition: null,
      shouldCenterScroll: false,
      shouldPlaceTheBackgroundWithTransition: false,
    }
  },
  computed: {
    ...mapGetters({
      getTransition: 'DownloadManager/getTransition',
      getBackground: 'BackgroundTree/getBackground',
    }),
    enterHook() {
      return this.currentTransition.componentProps.css
        ? this.enterCssHook
        : this.enterJsHook
    },
    leaveHook() {
      return this.currentTransition.componentProps.css
        ? this.leaveCssHook
        : this.leaveJsHook
    },
    backgroundResource() {
      return this.getBackground(this.$route.path)
    },
    tour360Url() {
      if (this.backgroundResource instanceof Tour360Resource) {
        return this.backgroundResource.resourceUrl
      }
      return undefined
    },
    backgroundInteractionEnabled() {
      return this.backgroundResource instanceof ImageResource
    },
    routeKey() {
      return this.$route.path
    },
    shouldDisplayTransitionVideo() {
      return this.currentTransition?.shouldDisplayVideo
    },
  },
  watch: {
    async $route(to, from) {
      this.setCurrentTransition(from, to)
      this.setScrollBehavior(from, to)
      this.shouldPlaceTheBackgroundWithTransition =
        this.currentTransition !== this.emptyTransition
    },
  },
  created() {
    this.emptyTransition = TransitionFactory.makeTransition()
    this.currentTransition = this.emptyTransition
    this.$store.dispatch('setTransitionActive', false)
  },
  methods: {
    setCurrentTransition(routeFrom, routeTo) {
      this.currentTransition = routeTo.params.useTransition
        ? this.getTransitionObject(routeFrom, routeTo)
        : this.emptyTransition
    },
    setScrollBehavior(routeFrom, routeTo) {
      const { centerScrollOnRouteEnter } = routeTo.meta || {}

      this.shouldCenterScroll =
        routeTo.name !== routeFrom.name ||
        centerScrollOnRouteEnter ||
        this.currentTransition instanceof VideoTransition
    },
    async placeTheBackground() {
      await this.$refs.backgroundInteractionManager.reset({
        useTransition: this.shouldPlaceTheBackgroundWithTransition,
        center: this.shouldCenterScroll,
      })
    },
    onBackgroundImageViewerMounted() {
      this.$store.dispatch('DownloadManager/downloadTransitionVideos', {
        fromRouteName: this.$route.name,
      })
      this.$store.dispatch('DownloadManager/downloadTransitionVideos', {
        fromRouteName: this.$route.path,
      })
    },
    getTransitionObject(from, to) {
      const transitionParams =
        this.getTransition(from.path, to.path) ||
        this.getTransition(from.name, to.path) ||
        this.getTransition(from.name, to.name) ||
        this.getTransition(from.path, to.name)
      if (!transitionParams) {
        return this.emptyTransition
      }
      return TransitionFactory.makeTransition(transitionParams, {
        videoElement: this.$refs.transitionVideo,
      })
    },
    beforeTransition() {
      this.$store.dispatch(
        'setTransitionActive',
        this.currentTransition.isTransitionActive
      )
    },
    enterCssHook() {
      this.placeTheBackground()
      this.currentTransition.enter()
    },
    leaveCssHook() {
      this.placeTheBackground()
      this.currentTransition.leave()
    },
    // eslint-disable-next-line no-unused-vars
    async enterJsHook(element, done) {
      await this.placeTheBackground()
      await this.currentTransition.enter()
      done()
    },
    // eslint-disable-next-line no-unused-vars
    async leaveJsHook(element, done) {
      await this.placeTheBackground()
      await this.currentTransition.leave()
      done()
    },
    afterTransition() {
      this.$store.dispatch(
        'setTransitionActive',
        this.currentTransition.isTransitionActive
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.the-transition-manager {
  @at-root &__background-image {
    position: relative;
    z-index: 0;
  }

  @at-root &__transition-video {
    position: absolute;
    z-index: 1;

    visibility: hidden;
    @at-root &--active {
      visibility: visible;
    }

    @at-root &__video {
      // CSS Trick to fix black-screen bug in video transitions
      filter: blur(0);
    }
  }

  @at-root &__tour360 {
    position: fixed;
    z-index: 0;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }

  // Transitions
  &::v-deep {
    .fade {
      @at-root #{&}-enter-active,
        #{&}-leave-active {
        transition: opacity 0.3s ease;
      }
      @at-root #{&}-enter {
        opacity: 0;
      }
      @at-root #{&}-leave-to {
        transition-duration: 0s;
        opacity: 0;
      }
    }
    .slide-left-short {
      @at-root #{&}-enter-active,
        #{&}-leave-active {
        transition: all 0.4s ease;
        position: absolute;
      }
      @at-root #{&}-enter {
        transform: translateX(-5px);
        opacity: 0;
      }
      @at-root #{&}-leave-to {
        transform: translateX(-10px);
        opacity: 0;
      }
    }
  }
}
</style>
