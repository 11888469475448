<template>
  <connection-required-offline-mode-wrapper>
    <project-video-top-bar-controls class="video__top-controls" />
    <div
      class="video__wrapper"
      :style="{
        paddingLeft: wrapperPaddingLeft,
        transition: wrapperTransition,
      }"
    >
      <iframe
        :src="
          `https://player.vimeo.com/video/${vimeoVideoId}/?${vimeoSettingsQueryString}`
        "
        frameborder="0"
        :allow="`autoplay; ${enableVideoFullscreen ? 'fullscreen' : ''}`"
        :allowfullscreen="enableVideoFullscreen"
      ></iframe>
    </div>
  </connection-required-offline-mode-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import responsiveMixin from '@/mixins/responsiveMixin'
import onlineStatusMixin from '@/mixins/onlineStatusMixin'
import ConnectionRequiredOfflineModeWrapper from '@/components/ConnectionRequiredOfflineModeWrapper'
import HomeRouteObjectGenerator from '@/classes/RouteObjectGenerators/HomeRouteObjectGenerator'

import ProjectVideoTopBarControls from '@/components/ViewTopBarControls/ProjectVideoTopBarControls'

export default {
  name: 'ProjectVideo',
  components: {
    ConnectionRequiredOfflineModeWrapper,
    ProjectVideoTopBarControls,
  },
  mixins: [responsiveMixin, onlineStatusMixin],
  data() {
    return {
      vimeoVideoSettings: {
        autoplay: true,
        loop: true,
        muted: true,
        transparent: true,
      },
    }
  },
  computed: {
    ...mapGetters({
      menuWidth: 'Layout/menuWidth',
      vimeoVideoId: 'Project/vimeoVideoId',
      allowFullscreenNavigation: 'Preferences/allowFullscreenNavigation',
    }),
    wrapperPaddingLeft() {
      const padding = this.isSmallScreen ? 16 : this.menuWidth + 32
      return `${padding}px`
    },
    wrapperTransition() {
      return this.menuWidth > 0
        ? 'padding 400ms cubic-bezier(.25,.1,.25,1)'
        : 'padding 700ms ease'
    },
    vimeoSettingsQueryString() {
      return Object.keys(this.vimeoVideoSettings)
        .map((key) => `${key}=${this.vimeoVideoSettings[key]}`)
        .join('&')
    },
    enableVideoFullscreen() {
      return !(
        this.allowFullscreenNavigation &&
        this.$fullscreenHandler.supportsFullscreenNavigation
      )
    },
  },
  created() {
    if (!this.vimeoVideoId) {
      const homeRouteObject = HomeRouteObjectGenerator.make()
      this.$router.replace(homeRouteObject)
    }
  },
}
</script>

<style lang="scss" scoped>
.video {
  &__top-controls {
    z-index: 20;
  }
  &__wrapper {
    width: 100vw;
    height: 100vh;
    background: var(--grey-background-color);

    @include enable-gpu-with-css;
    transition: padding 400ms ease-out;
    padding: 32px;

    @include small-screens {
      padding: 16px;
    }

    iframe {
      width: 100%;
      height: 100%;
      @include enable-gpu-with-css;
    }
  }
}
</style>
