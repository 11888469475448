import VideoTransitionParams from '@/classes/TransitionTree/VideoTransitionParams'
import LoggingServiceFactory from '@/classes/LoggingService/LoggingServiceFactory'

export default class VideoTransitionDownloadManager {
  constructor() {
    this._queue = []
    this._activeDownloads = []
    this._maxSimultaneousDownloads = 2
    this.loggingService = new LoggingServiceFactory().makeService()
  }
  updateQueue(videoTransitionParamsList) {
    this._validateVideoTransitionParamsList(videoTransitionParamsList)
    this._queue = this._filterDownloadedVideoTransitionParamsList(
      videoTransitionParamsList
    )
  }
  async startDownloads() {
    await this._downloadNextAvaibaleElements()
  }
  _filterDownloadedVideoTransitionParamsList(videoTransitionParamsList) {
    return videoTransitionParamsList.filter(
      (videoTransitionParams) =>
        !videoTransitionParams.ready && !videoTransitionParams.downloading
    )
  }
  _isNextQueueElementAvailableForDownload() {
    const queueHasElements = this._queue.length > 0
    const simultaneousDownloadsLimitExceeded =
      this._activeDownloads.length >= this._maxSimultaneousDownloads
    return queueHasElements && !simultaneousDownloadsLimitExceeded
  }
  async _downloadNextAvaibaleElements() {
    const nextDownloadsPromises = []
    while (this._isNextQueueElementAvailableForDownload()) {
      nextDownloadsPromises.push(this._downloadNext())
    }
    await Promise.all(nextDownloadsPromises)
  }
  async _downloadNext() {
    const nextVideoTransition = this._queue.shift()
    this._activeDownloads.push(nextVideoTransition)
    try {
      await nextVideoTransition.download()
    } catch (error) {
      this.loggingService.logError(
        `DownloadManager/downloadTransitionVideos: Error downloading transition video. ${error.toString()}`
      )
    }
    this._removeActiveDownload(nextVideoTransition)
    await this._downloadNextAvaibaleElements()
  }
  _removeActiveDownload(videoTransitionElement) {
    const downloadIndex = this._activeDownloads.indexOf(videoTransitionElement)
    if (downloadIndex > -1) {
      this._activeDownloads.splice(downloadIndex, 1)
    }
  }
  _validateVideoTransitionParamsList(videoTransitionParamsList) {
    if (!Array.isArray(videoTransitionParamsList)) {
      throw new Error(
        `DownloadManager/_validateVideoTransitionParamsList: Invalid videoTransitionParamsList ${videoTransitionParamsList}`
      )
    }
    videoTransitionParamsList.forEach((item) => {
      if (!(item instanceof VideoTransitionParams)) {
        throw new Error(
          `DownloadManager/_validateVideoTransitionParamsList: invalid element ${item} found in videoTransitionParamsList.`
        )
      }
    })
  }
}
