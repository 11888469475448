import { render, staticRenderFns } from "./AppDropdownList.vue?vue&type=template&id=3613af56&scoped=true"
import script from "./AppDropdownList.vue?vue&type=script&lang=js"
export * from "./AppDropdownList.vue?vue&type=script&lang=js"
import style0 from "./AppDropdownList.vue?vue&type=style&index=0&id=3613af56&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3613af56",
  null
  
)

export default component.exports