<template>
  <section
    class="sheet-wrapper"
    :class="{
      'sheet-wrapper--popup': isPopup,
      'sheet-wrapper--fit': fitContent,
    }"
  >
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: 'SheetWrapper',
  props: {
    isPopup: {
      type: Boolean,
      default: false,
    },
    fitContent: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.sheet-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: 300px;
  padding: 1rem 0;

  @at-root &--popup {
    padding: 1.5rem 0;
  }
  @at-root &--fit {
    min-width: fit-content;
    padding: 0.75rem 0;
  }
}
</style>
