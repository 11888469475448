<template>
  <AppBaseModal :title="title" :modal-width="455" v-on="$listeners">
    <p class="subject">
      <TextWrapper :text="subject" />
    </p>
    <ContactForm
      :unit-id="unitId"
      :external-subject="externalSubject"
      :target-api-endpoint-name="targetApiEndpointName"
      modal-mode
      @submit="handleSubmit"
    />
  </AppBaseModal>
</template>
<script>
import ContactForm from '@/components/Form/ContactForm'
import TextWrapper from '@/components/TextWrapper'
import Unit from '@/classes/ProjectStructure/Unit/Unit'
import AppBaseModal from '@/components/UIKit/Standard/Atoms/AppBaseModal'
import modalUnitRequestTypes from '@/constants/ui-kit/standard/modal/modal-unit-request-types'
import modalSupportedComponents from '@/constants/ui-kit/standard/modal/modal-supported-components'
import * as contactApiEndpointNames from '@/constants/api/contact-api-endpoint-names'

export default {
  name: 'AppUnitInfoFormModal',
  components: {
    ContactForm,
    TextWrapper,
    AppBaseModal,
  },
  props: {
    unit: {
      type: Unit,
      default: () => undefined,
    },
    requestType: {
      type: String,
      required: true,
      validator: (value) =>
        Object.values(modalUnitRequestTypes).includes(value),
    },
  },
  data() {
    return {
      targetApiEndpointName: contactApiEndpointNames.UNIT,
    }
  },
  computed: {
    title() {
      const titleOptions = {
        [modalUnitRequestTypes.CHECK_PRICE]: this.$t('Consulta precio'),
        [modalUnitRequestTypes.REQUEST_UNIT_INFORMATION]: this.$t(
          'Solicita información'
        ),
      }
      return titleOptions[this.requestType]
    },
    subject() {
      return this.unit?.longIdentifier
    },
    unitId() {
      return this.unit?.id
    },
    externalSubject() {
      const externalSubjectOptions = {
        [modalUnitRequestTypes.CHECK_PRICE]: this.$t('Consulta de precio'),
        [modalUnitRequestTypes.REQUEST_UNIT_INFORMATION]: this.$t(
          'Consulta por unidad específica'
        ),
      }
      return externalSubjectOptions[this.requestType]
    },
  },
  methods: {
    handleSubmit() {
      this.$store.dispatch('ModalLauncher/launchModal', {
        component: modalSupportedComponents.AppConfirmationMessageModal,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.subject {
  font-size: 1rem;
  font-weight: var(--font-weight-medium);
  margin-bottom: 1.75rem;
  color: var(--gray-500);
}
</style>
