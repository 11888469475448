<template>
  <AppSheetUnitSmall
    :title="unitShortIdentifier"
    :subtitle="unitModel"
    :availability-color="unitAvailabilityColor"
    :availability-status="unitDisplayAvailabilityText"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import Unit from '@/classes/ProjectStructure/Unit/Unit'
import AppSheetUnitSmall from '@/components/UIKit/Standard/Organisms/AppSheet/AppSheetUnitSmall'
import UnitAvailabilityStatusTranslationFactory from '@/classes/UnitAvailabilityStatusTranslationFactory'

export default {
  name: 'UnitPanelSmallContent',
  components: {
    AppSheetUnitSmall,
  },
  props: {
    projectElement: {
      type: Unit,
      default: () => undefined,
    },
  },
  computed: {
    ...mapGetters({
      displayAvailability: 'Preferences/displayAvailability',
    }),
    unit() {
      return this.projectElement
    },
    unitShortIdentifier() {
      return this.unit.shortIdentifier
    },
    unitModel() {
      return this.unit.model
    },
    unitAvailabilityColor() {
      return this.displayAvailability ? this.unit.availabilityColor : undefined
    },
    unitDisplayAvailabilityText() {
      return this.displayAvailability
        ? this.$t(
            UnitAvailabilityStatusTranslationFactory.makeTranslationKeyByUnit(
              this.unit
            )
          )
        : undefined
    },
  },
}
</script>
