class AmenitiesGalleryPageRouteObjectGenerator {
  static get ROUTE_NAME() {
    return 'general-amenities-gallery'
  }
  static make(customParams) {
    return {
      name: AmenitiesGalleryPageRouteObjectGenerator.ROUTE_NAME,
      params: {
        ...customParams,
      },
    }
  }
}
export default AmenitiesGalleryPageRouteObjectGenerator
