<template>
  <div class="interactive-map">
    <div ref="map" class="interactive-map__map-container"></div>
    <TheFullViewportContainer>
      <div
        class="interactive-map__controls"
        :style="{ transform: `translateY(${zoomButtonsOffsetY}px)` }"
      >
        <AppButtonIcon
          class="interactive-map__controls__zoom-in"
          :icon="buttonZoomInIcon"
          :button-style="buttonStyle"
          @click.native="zoomIn"
        />
        <AppButtonIcon
          class="interactive-map__controls__zoom-out"
          :icon="buttonZoomOutIcon"
          :button-style="buttonStyle"
          @click.native="zoomOut"
        />
      </div>
    </TheFullViewportContainer>
  </div>
</template>

<script>
import GoogleMapsApiLoader from 'google-maps-api-loader'
import { mapGetters } from 'vuex'
import AppButtonIcon from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonIcon'
import iconNames from '@/constants/ui-kit/standard/icon-names'
import styles from '@/constants/ui-kit/standard/styles'
import TheFullViewportContainer from '@/components/Gadgets/TheFullViewportContainer'

export default {
  name: 'InteractiveMap',
  components: {
    AppButtonIcon,
    TheFullViewportContainer,
  },
  props: {
    zoomButtonsOffsetY: {
      type: Number,
      default: 0,
    },
  },
  data: () => {
    return {
      googleMapApi: null,
      map: null,
      zoom: 16,
      buttonZoomInIcon: iconNames.UIZoomIn,
      buttonZoomOutIcon: iconNames.UIZoomOut,
      buttonStyle: styles.DARK,
    }
  },
  computed: {
    ...mapGetters({
      interactiveMapData: 'Project/locationInteractiveMap',
    }),
  },
  async mounted() {
    this.googleMapApi = await GoogleMapsApiLoader({
      apiKey: this.interactiveMapData.apiKey,
    })
    this.initializeMap()
    this.zoom = this.interactiveMapData.configMap.zoom
  },
  methods: {
    initializeMap() {
      this.map = new this.googleMapApi.maps.Map(
        this.$refs.map,
        this.interactiveMapData.configMap
      )

      const { Marker, InfoWindow, Size, Point } = this.googleMapApi.maps
      const contentString = ` <div class="picture" style="background-image: url(${this.interactiveMapData.marker.backgroundImgUrl});"></div>
                              <p>${this.interactiveMapData.marker.textContent}</p>`

      const infowindow = new InfoWindow({
        content: contentString,
      })

      const marker = new Marker({
        position: this.interactiveMapData.configMap.center,
        marker: this.interactiveMapData.cords,
        map: this.map,
        icon: {
          url: this.interactiveMapData.marker.imgUrl,
          scaledSize: new Size(80, 80),
          origin: new Point(0, 0),
          anchor: new Point(40, 40),
        },
      })

      infowindow.open(this.map, marker)
    },
    zoomIn() {
      if (this.zoom < this.interactiveMapData.configMap.maxZoom) {
        this.zoom++
        this.map.setZoom(this.zoom)
      }
    },
    zoomOut() {
      if (this.zoom > this.interactiveMapData.configMap.minZoom) {
        this.zoom--
        this.map.setZoom(this.zoom)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.interactive-map {
  $google-maps-background-color: #e5e3df;
  background: $google-maps-background-color;

  @at-root #{&}__map-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }

  @at-root #{&}__controls {
    position: fixed;
    right: 20px;
    bottom: 28px;
    z-index: 1;
    width: 40px;
    display: flex;
    flex-wrap: wrap;
    transition: transform 300ms ease-out;

    @include small-screens-horizontal {
      position: absolute;
    }

    @at-root #{&}__zoom-in {
      margin-bottom: 14px;
    }
  }

  @at-root #{&}__map-container::v-deep {
    .gm-style .gm-style-iw-c {
      min-width: 194px !important;
      max-width: 316px !important;
      max-height: none !important;
      border-radius: 0;
      padding: 0 !important;
      box-shadow: none;
      background: transparent;
      overflow: visible;
      pointer-events: none;

      .gm-style-iw-d {
        overflow: visible !important;
      }
      .picture {
        height: 178px;
        width: 100%;
        background-color: var(--brownish-grey);
        background-position: center;
        background-size: cover;

        border-radius: 8px;
        opacity: 0;
        margin-bottom: 8px;
        transition: opacity 200ms ease-in-out;

        @include small-screens {
          display: none;
        }
      }
      p {
        font-size: 0.75rem;
        letter-spacing: 0.05em;
        margin: 0;

        border-radius: 4px;
        background-color: var(--primary-color);
        color: #fff;
        padding: 4px 8px;
        box-shadow: 0px 6px 8px -4px rgba(0, 0, 0, 0.25);
        pointer-events: all;
      }
      .gm-ui-hover-effect {
        display: none !important;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 96px;
        height: 88px;
        transform: translateX(-50%) translateY(100%);
        pointer-events: all;
      }
    }

    @media (hover: hover) {
      .gm-style-iw-c:hover {
        pointer-events: all;

        .picture {
          opacity: 1;
        }
      }
    }
    .gm-style .gm-style-iw-t {
      bottom: 40px !important;

      &::after {
        display: none;
      }
    }
  }
}
</style>
