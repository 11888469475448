import NativeApiBasedFullscreenHandler from '@/classes/FullscreenHandler/NativeApiBasedFullscreenHandler'
import FallbackFullscreenHandler from '@/classes/FullscreenHandler/FallbackFullscreenHandler'

class FullscreenHandlerFactory {
  constructor() {
    const fullscreenMethods = ['fullscreenEnabled', 'webkitFullscreenEnabled']
    this._isFullscreenNativeApiAvailable = fullscreenMethods.some(
      (property) => document[property]
    )
  }
  makeFullscreenHandler(fullscreenElement) {
    const FullscreenHandler = this._isFullscreenNativeApiAvailable
      ? NativeApiBasedFullscreenHandler
      : FallbackFullscreenHandler
    return new FullscreenHandler(fullscreenElement)
  }
}

export default FullscreenHandlerFactory
