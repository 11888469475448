<template>
  <section>
    <VTitle text="AppSheetUnitSmall" />
    <div class="example-component">
      <div class="example-component__controls">
        <VPropControl
          v-for="(propModel, propName) in propModels"
          :key="propName"
          :model="propModel"
          :name="propName"
        />
      </div>
      <div class="example-component__component">
        <VComponentWrapper :width="375" bordered resizable>
          <AppSheetUnitSmall
            :title="propModels.title.value"
            :subtitle="propModels.subtitle.value"
            :availability-status="propModels.availabilityStatus.value"
            :availability-color="propModels.availabilityColor.value"
          />
        </VComponentWrapper>
      </div>
    </div>
  </section>
</template>

<script>
import AppSheetUnitSmall from '@/components/UIKit/Standard/Organisms/AppSheet/AppSheetUnitSmall'
import AppPropModel from '@/components/UIKit/Standard/ViewerSections/classes/AppPropModel'
import VTitle from '@/components/UIKit/Standard/ViewerSections/components/snippets/VTitle'
import VPropControl from '@/components/UIKit/Standard/ViewerSections/components/snippets/VPropControl'
import VComponentWrapper from '@/components/UIKit/Standard/ViewerSections/components/snippets/VComponentWrapper'

export default {
  name: 'AppSheetUnitSmallViewer',
  components: {
    AppSheetUnitSmall,
    VTitle,
    VPropControl,
    VComponentWrapper,
  },
  data() {
    return {
      propModels: {
        title: new AppPropModel({
          type: String,
          defaultValue: '25° A',
          required: true,
        }),
        subtitle: new AppPropModel({
          type: String,
          defaultValue: 'Example model',
        }),
        availabilityStatus: new AppPropModel({
          type: String,
        }),
        availabilityColor: new AppPropModel({
          type: String,
        }),
      },
    }
  },
  created() {
    this.propModels.availabilityStatus.addPreset('Disponible', 'AVAILABLE')
    this.propModels.availabilityStatus.addPreset('Reservado', 'RESERVED')
    this.propModels.availabilityStatus.addPreset('Vendido', 'SOLD')

    this.propModels.availabilityColor.addPreset('#1ac366', 'AVAILABLE')
    this.propModels.availabilityColor.addPreset('#deca6e', 'RESERVED')
    this.propModels.availabilityColor.addPreset('#de7777', 'SOLD')
  },
}
</script>

<style lang="scss" scoped>
.example-component {
  display: flex;
  justify-content: stretch;
  align-items: stretch;

  @at-root &__controls {
    background: #fff;
    padding: 30px;
    border: 1px solid var(--gray-200);
    flex-basis: 300px;
    border-radius: 12px;
  }

  @at-root &__component {
    padding: 0 30px;
  }
}
</style>
