<template>
  <div class="sheet-block-with-share-menu">
    <slot></slot>
    <AppShareMenu
      v-if="shareUrl"
      class="sheet-block-with-share-menu__share-menu"
      :share-url="shareUrl"
      :menu-alignment="shareMenuAlignment"
    />
  </div>
</template>

<script>
import AppShareMenu from '@/components/UIKit/Standard/Molecules/AppShareMenu'
import alignments from '@/constants/ui-kit/standard/alignments'

export default {
  name: 'SheetBlockWithShareMenu',
  components: {
    AppShareMenu,
  },
  props: {
    shareUrl: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      shareMenuAlignment: alignments.ALIGN_RIGHT,
    }
  },
}
</script>

<style lang="scss" scoped>
.sheet-block-with-share-menu {
  position: relative;
  margin-bottom: 1.25rem;
  @at-root #{&}__share-menu {
    position: absolute;
    bottom: -0.75rem;
    right: -0.1875rem;
  }
}
</style>
