<template>
  <section class="container">
    <h1>AppButtonIconRouterLink</h1>
    <table>
      <tr>
        <th></th>
        <th v-for="size in sizes" :key="size">{{ size }}</th>
      </tr>
      <tr v-for="style in styles" :key="style">
        <th>{{ style }}</th>
        <th v-for="size in sizes" :key="style + size">
          <AppButtonIconRouterLink
            :icon="demoIcon"
            :size="size"
            :button-style="style"
            :to="demoRouteObject"
          />
        </th>
      </tr>
    </table>
  </section>
</template>

<script>
import AppButtonIconRouterLink from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonIconRouterLink'
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'
import iconNames from '@/constants/ui-kit/standard/icon-names'

export default {
  name: 'AppButtonIconRouterLinkViewer',
  components: {
    AppButtonIconRouterLink,
  },
  data() {
    return {
      sizes: Object.values(sizes),
      styles: Object.values(styles),
      demoIcon: iconNames.UIMenu,
      demoRouteObject: { name: 'video' },
    }
  },
}
</script>

<style lang="scss" scoped>
.container {
  margin: 80px 50px;
}
h1 {
  font-size: 1.5rem;
  margin: 2.5em 0 1.5em;
  border-bottom: 1px solid black;
}
th {
  font-weight: 400;
  font-size: 0.75rem;
}
th,
td {
  padding: 5px 10px;
}
</style>
