<template>
  <h2 class="title">{{ text }}</h2>
</template>

<script>
export default {
  name: 'VTitle',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 1.375rem;
  color: var(--gray-700);
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--gray-200);
}
</style>
