<template>
  <AppSheetWrapper class="sheet-unit-large">
    <AppSheetRow>
      <header>
        <AppSheetBlockWithShareMenu :share-url="shareUrl">
          <AppSheetPreviewImage :preview-image-url="previewImageUrl" />
        </AppSheetBlockWithShareMenu>
        <AppSheetBlockTitleAndAvailability
          :title="title"
          :subtitle="subtitle"
          :availability-status="availabilityStatus"
          :availability-color="availabilityColor"
        />
      </header>
    </AppSheetRow>
    <AppSheetRow v-if="withUnavailableMessage">
      <AppSheetAvailabilityText
        :availability-text="$t('Esta unidad ya no está disponible')"
      />
    </AppSheetRow>
    <AppSheetRow
      v-if="shouldDisplayPrice"
      divider-top
      divider-bottom
      background
    >
      <AppSheetPrice :price="price" :align="priceAlignment" />
    </AppSheetRow>
    <AppSheetRow v-if="shouldDisplayButtonsBlock">
      <AppSheetBlockButtonGroup>
        <AppButtonText
          v-if="withEmbeddedFormButton"
          :text="embeddedContactFormButtonText"
          :size="buttonSize"
          :button-style="buttonEmbeddedContactFormsStyles"
          @click.native.stop="emitLaunchEmbeddedContactForm"
        />
        <AppButtonText
          v-if="withCheckPriceButton"
          :text="$t('Consultar precio')"
          :size="buttonSize"
          :button-style="buttonCheckPriceStyle"
          @click.native.stop="emitRequestPrice"
        />
        <AppButtonText
          v-if="withQuoterButton"
          :text="$t('Cotizar')"
          :size="buttonSize"
          :button-style="buttonQuoterStyle"
          @click.native.stop="emitLaunchQuoter"
        />
        <AppButtonTextRouterLink
          v-if="tour360To"
          :text="$t('Tour 360º')"
          :size="buttonSize"
          :button-style="buttonTour360Style"
          :to="tour360To"
        />
        <AppButtonTextRouterLink
          v-if="enterTo"
          :text="$t('Ingresar')"
          :size="buttonSize"
          :button-style="buttonEnterStyle"
          :to="enterTo"
        />
      </AppSheetBlockButtonGroup>
    </AppSheetRow>
    <AppSheetRow overflow-scroll divider-top>
      <AppFeatureList
        v-if="shouldDisplayFeatures"
        class="sheet-unit-large__features"
        :title="$t('Instalaciones')"
        :features="features"
      />
      <AppCustomContentBlock
        v-if="customContentBlock && customContentBlock.isValid"
        :custom-content-block="customContentBlock"
      />
    </AppSheetRow>
    <AppSheetRow v-if="showBrochureButton" separator-bottom>
      <AppSheetBlockButtonGroup>
        <AppButtonTextLink
          :href="brochureUrl"
          :download="brochureFileName"
          :text="$t('Descargar Brochure')"
          :size="brochureButtonSize"
          :button-style="brochureButtonStyle"
        />
      </AppSheetBlockButtonGroup>
    </AppSheetRow>
    <AppSheetRow v-if="withDisclaimerButton" separator-bottom>
      <AppSheetBlockButtonGroup>
        <AppButtonText
          :text="$t('Disclaimer')"
          :size="disclaimerButtonSize"
          :button-style="disclaimerButtonStyle"
          @click.native.stop="handleOpenDisclaimer"
        />
      </AppSheetBlockButtonGroup>
    </AppSheetRow>
  </AppSheetWrapper>
</template>

<script>
import AppSheetWrapper from '@/components/UIKit/Standard/Atoms/AppSheetWrapper'
import AppSheetRow from '@/components/UIKit/Standard/Atoms/AppSheetRow'
import AppSheetBlockTitleAndAvailability from '@/components/UIKit/Standard/Molecules/AppSheetBlockTitleAndAvailability'
import AppSheetBlockWithShareMenu from '@/components/UIKit/Standard/Molecules/AppSheetBlockWithShareMenu'
import AppSheetBlockButtonGroup from '@/components/UIKit/Standard/Molecules/AppSheetBlockButtonGroup'
import AppSheetPreviewImage from '@/components/UIKit/Standard/Molecules/AppSheetPreviewImage'
import AppFeatureList from '@/components/UIKit/Standard/Molecules/AppFeatureList'
import AppSheetAvailabilityText from '@/components/UIKit/Standard/Atoms/AppSheetAvailabilityText'
import AppSheetPrice from '@/components/UIKit/Standard/Atoms/AppSheetPrice'
import AppButtonTextRouterLink from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonTextRouterLink'
import AppButtonText from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonText'
import AppButtonTextLink from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonTextLink'
import AppCustomContentBlock from '@/components/UIKit/Standard/Molecules/AppCustomContentBlock'

import alignments from '@/constants/ui-kit/standard/alignments'
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'

import BasePrice from '@/classes/Price/BasePrice'
import Feature from '@/classes/Feature/Feature'
import CustomContentBlock from '@/classes/ProjectStructure/CustomContentBlock'

export default {
  name: 'AppSheetUnitLarge',
  components: {
    AppSheetWrapper,
    AppSheetRow,
    AppSheetBlockTitleAndAvailability,
    AppSheetBlockWithShareMenu,
    AppSheetBlockButtonGroup,
    AppSheetPreviewImage,
    AppSheetPrice,
    AppSheetAvailabilityText,
    AppButtonTextRouterLink,
    AppButtonText,
    AppButtonTextLink,
    AppFeatureList,
    AppCustomContentBlock,
  },
  props: {
    previewImageUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: undefined,
    },
    enterTo: {
      type: Object,
      default: () => undefined,
    },
    tour360To: {
      type: Object,
      default: () => undefined,
    },
    shareUrl: {
      type: String,
      default: undefined,
    },
    price: {
      type: BasePrice,
      default: undefined,
    },
    features: {
      type: Array,
      required: true,
      validator: (value) =>
        value.every((feature) => feature instanceof Feature),
    },
    brochureUrl: {
      type: String,
      default: undefined,
    },
    brochureFileName: {
      type: String,
      default: undefined,
    },
    embeddedContactFormButtonText: {
      type: String,
      default: undefined,
    },
    withDisclaimerButton: {
      type: Boolean,
      default: false,
    },
    availabilityStatus: {
      type: String,
      default: undefined,
    },
    availabilityColor: {
      type: String,
      default: undefined,
    },
    withUnavailableMessage: {
      type: Boolean,
      default: false,
    },
    withCheckPriceButton: {
      type: Boolean,
      default: false,
    },
    withQuoterButton: {
      type: Boolean,
      default: false,
    },
    withEmbeddedFormButton: {
      type: Boolean,
      default: false,
    },
    customContentBlock: {
      type: CustomContentBlock,
      default: undefined,
    },
  },
  data() {
    return {
      priceAlignment: alignments.ALIGN_RIGHT,

      buttonSize: sizes.SM,
      buttonEnterStyle: styles.PRIMARY,

      brochureButtonSize: sizes.XS,
      brochureButtonStyle: styles.EXTRA_LIGHT,

      disclaimerButtonSize: sizes.XS,
      disclaimerButtonStyle: styles.LINK,
    }
  },
  computed: {
    activeButtonsCount() {
      const truthyButtonConditions = [
        this.withCheckPriceButton,
        this.enterTo,
        this.tour360To,
        this.withQuoterButton,
        this.withEmbeddedFormButton,
      ].filter((value) => value)
      return truthyButtonConditions.length
    },
    shouldDisplayButtonsBlock() {
      return this.activeButtonsCount > 0
    },
    hasASingleButton() {
      return this.activeButtonsCount === 1
    },
    buttonTour360Style() {
      return this.hasASingleButton ? styles.PRIMARY : styles.LIGHT
    },
    buttonCheckPriceStyle() {
      return this.hasASingleButton ? styles.PRIMARY : styles.LIGHT
    },
    buttonEmbeddedContactFormsStyles() {
      return this.hasASingleButton ? styles.PRIMARY : styles.LIGHT
    },
    buttonQuoterStyle() {
      return this.hasASingleButton ? styles.PRIMARY : styles.LIGHT
    },
    showBrochureButton() {
      return this.brochureUrl && this.brochureFileName
    },
    shouldDisplayPrice() {
      return this.price
    },
    shouldDisplayFeatures() {
      return this.features.length > 0
    },
  },
  methods: {
    handleOpenDisclaimer() {
      this.$emit('open-disclaimer')
    },
    emitRequestPrice() {
      this.$emit('request-price')
    },
    emitLaunchQuoter() {
      this.$emit('launch-quoter')
    },
    emitLaunchEmbeddedContactForm() {
      this.$emit('launch-embedded-contact-form')
    },
  },
}
</script>

<style lang="scss" scoped>
.sheet-unit-large {
  position: relative;
  height: 100%;
}
</style>
