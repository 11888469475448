<template>
  <section>
    <VTitle text="AppMinimap" />
    <div class="example-component">
      <div class="example-component__controls">
        <VPropControl
          v-for="(propModel, propName) in propModels"
          :key="propName"
          :model="propModel"
          :name="propName"
        />
      </div>
      <div class="example-component__component">
        <AppMinimap
          :lens-width="propModels.lensWidth.value"
          :lens-height="propModels.lensHeight.value"
          :content-zoom="propModels.contentZoom.value"
          :content-position-x="propModels.contentPositionX.value"
          :content-position-y="propModels.contentPositionY.value"
          :background-image-url="propModels.backgroundImageUrl.value"
        />
      </div>
    </div>
  </section>
</template>

<script>
import AppMinimap from '@/components/UIKit/Standard/Molecules/AppMinimap'
import AppPropModel from '@/components/UIKit/Standard/ViewerSections/classes/AppPropModel'
import VTitle from '@/components/UIKit/Standard/ViewerSections/components/snippets/VTitle'
import VPropControl from '@/components/UIKit/Standard/ViewerSections/components/snippets/VPropControl'

export default {
  name: 'AppMinimapViewer',
  components: {
    AppMinimap,
    VTitle,
    VPropControl,
  },
  data() {
    return {
      propModels: {
        lensWidth: new AppPropModel({
          type: Number,
          defaultValue: 800,
          required: true,
        }),
        lensHeight: new AppPropModel({
          type: Number,
          defaultValue: 800,
          required: true,
        }),
        contentZoom: new AppPropModel({
          type: Number,
          defaultValue: 1,
          required: true,
        }),
        contentPositionX: new AppPropModel({
          type: Number,
        }),
        contentPositionY: new AppPropModel({
          type: Number,
        }),
        backgroundImageUrl: new AppPropModel({
          type: String,
          defaultValue:
            'https://aurora.dev.web3d.page/data/buildinglevel/2021/05/swipe_img/c3205c224a564a9084ce042d36047978',
        }),
      },
    }
  },
  created() {
    this.propModels.contentZoom.addPreset(1.25, '1.25')
    this.propModels.contentZoom.addPreset(1.5, '1.5')
    this.propModels.contentZoom.addPreset(2, '2')
    this.propModels.contentZoom.addPreset(2.5, '2.5')
    this.propModels.contentZoom.addPreset(3, '3')
    this.propModels.contentZoom.addPreset(0.5, '0.5')

    this.propModels.lensWidth.addPreset(810, 'iPad (portrait) - 810')
    this.propModels.lensWidth.addPreset(1080, 'iPad (landscape) - 1080')
    this.propModels.lensWidth.addPreset(375, 'iPhone 11 (portrait) - 375')
    this.propModels.lensWidth.addPreset(812, 'iPhone 11 (landscape) - 812')

    this.propModels.lensHeight.addPreset(1080, 'iPad (portrait) - 1080')
    this.propModels.lensHeight.addPreset(810, 'iPad (landscape) - 810')
    this.propModels.lensHeight.addPreset(812, 'iPhone 11 (portrait) - 812')
    this.propModels.lensHeight.addPreset(375, 'iPhone 11 (landscape) - 375')

    this.propModels.contentPositionX.addPreset(-400, '-400')
    this.propModels.contentPositionX.addPreset(-200, '-200')
    this.propModels.contentPositionX.addPreset(-100, '-100')
    this.propModels.contentPositionX.addPreset(-50, '-50')
    this.propModels.contentPositionX.addPreset(0, '0')
    this.propModels.contentPositionX.addPreset(50, '50')
    this.propModels.contentPositionX.addPreset(100, '100')
    this.propModels.contentPositionX.addPreset(200, '200')
    this.propModels.contentPositionX.addPreset(400, '400')

    this.propModels.contentPositionY.addPreset(-400, '-400')
    this.propModels.contentPositionY.addPreset(-200, '-200')
    this.propModels.contentPositionY.addPreset(-100, '-100')
    this.propModels.contentPositionY.addPreset(-50, '-50')
    this.propModels.contentPositionY.addPreset(0, '0')
    this.propModels.contentPositionY.addPreset(50, '50')
    this.propModels.contentPositionY.addPreset(100, '100')
    this.propModels.contentPositionY.addPreset(200, '200')
    this.propModels.contentPositionY.addPreset(400, '400')
  },
}
</script>

<style lang="scss" scoped>
.example-component {
  display: flex;
  justify-content: stretch;
  align-items: stretch;

  @at-root &__controls {
    background: #fff;
    padding: 30px;
    border: 1px solid var(--gray-200);
    flex-basis: 300px;
    border-radius: 12px;
  }

  @at-root &__component {
    padding: 30px;
  }
}
</style>
