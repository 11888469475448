const ReturnEmptyStringMissingMessageHandler = require('./ReturnEmptyStringMissingMessageHandler')
const ReturnKeyStringMissingMessageHandler = require('./ReturnKeyStringMissingMessageHandler')
const LoggingServiceFactory = require('../../classes/LoggingService/LoggingServiceFactory')
module.exports = class MissingMessageHandlerFactory {
  constructor() {
    this.selectedHandler = process.env.VUE_APP_MISSING_TRANSLATION_KEY_BEHAVIOR
    this.availableHandlers = {
      ReturnEmptyStringMissingMessageHandler,
      ReturnKeyStringMissingMessageHandler,
    }
    this.loggingService = new LoggingServiceFactory().makeService()
  }
  makeHandler() {
    const HandlerToUse = this.availableHandlers[this.selectedHandler]
    try {
      return new HandlerToUse(this.loggingService)
    } catch (error) {
      throw new Error(
        `MissingMessageHandlerFactory: There was a problem creating a MissingMessageHandler with key ${this.selectedHandler} from settings: ${error}`
      )
    }
  }

  validateEnvVariables() {
    if (!Object.keys(this.availableHandlers).includes(this.selectedHandler)) {
      throw new Error(
        `MissingMessageHandlerFactory: selected generator "${this.selectedHandler}" does not exist`
      )
    }
  }
}
