import ClusterAdapter from '@/classes/ProjectElementAdapters/ClusterAdapter'
export default class BaseClusterGroupAdapter {
  static adapt(clusterGroupJsonData, parentElement, i18n) {
    if (!Array.isArray(clusterGroupJsonData)) {
      throw new Error(
        `BaseClusterGroupAdapter.adapt(): Invalid clusterGroupJsonData ${clusterGroupJsonData}.`
      )
    }

    clusterGroupJsonData.forEach((elementJsonData) => {
      const adaptedElement = this.ElementAdapter.adapt(elementJsonData, i18n)
      parentElement.addChild(adaptedElement) // Esto tiene que estar antes de crear hijos

      const childCluster = elementJsonData.cluster
      if (childCluster) {
        ClusterAdapter.adapt(childCluster, adaptedElement, i18n)
      }

      this.afterAdaptElement(adaptedElement, i18n)
    })
  }
  static get ElementAdapter() {
    throw new Error(
      `ElementAdapter is not implemented in base class BaseClusterGroupAdapter.`
    )
  }
  // eslint-disable-next-line no-unused-vars
  static afterAdaptElement(adaptedElement, i18n) {}
}
