<template>
  <div v-click-outside="collapse" class="flap-dropdown">
    <AppButtonFlap :icon="flapIcon" with-badge @click.native="toggleCollapse" />
    <transition :name="dropdownListTransitionName">
      <AppDropdownList
        v-if="!collapsed"
        class="flap-dropdown__list"
        :class="[dropdownListPositionClassName]"
        :options="options"
        :selected-option="selectedOption"
        :size="flapDropdownSize"
        :dropdown-style="flapDropdownStyle"
        @option-clicked="setSelectedOption"
      />
    </transition>
  </div>
</template>

<script>
import AppButtonFlap from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonFlap'
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'
import iconNames from '@/constants/ui-kit/standard/icon-names'
import AppDropdownList from '@/components/UIKit/Standard/Atoms/AppDropdownList'
import verticalPositions from '@/constants/ui-kit/standard/vertical-positions'

export default {
  name: 'AppFlapDropdown',
  components: {
    AppButtonFlap,
    AppDropdownList,
  },
  props: {
    listPosition: {
      type: String,
      default: verticalPositions.BOTTOM_POSITION,
      validator: (value) => Object.values(verticalPositions).includes(value),
    },
    options: {
      type: Array,
      required: true,
      validator: function(array) {
        return array.every((item) => !!item.value && !!item.text)
      },
    },
    selectedValue: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      flapIcon: iconNames.UIOptionList,
      collapsed: true,
      flapDropdownStyle: styles.LIGHT,
      flapDropdownSize: sizes.SM,
    }
  },
  computed: {
    selectedOption() {
      return this.options.find((option) => option.value === this.selectedValue)
    },
    dropdownListPositionClassName() {
      return `flap-dropdown__list--${this.listPosition}`
    },
    dropdownListTransitionName() {
      return `flap-dropdown-list-${this.listPosition}`
    },
  },
  watch: {
    collapsed(isCollapsed) {
      if (isCollapsed) {
        this.$emit('collapse')
      } else {
        this.$emit('open')
      }
    },
  },
  methods: {
    toggleCollapse() {
      this.collapsed = !this.collapsed
    },
    collapse() {
      this.collapsed = true
    },
    setSelectedOption(selectedValue) {
      this.collapsed = true
      this.$emit('selectedOptionChange', selectedValue)
    },
  },
}
</script>

<style lang="scss" scoped>
.flap-dropdown {
  position: relative;
  &__list {
    position: absolute;
    z-index: 10;
    min-width: 220px;
    &--#{$bottom-position} {
      left: 100%;
      top: 0;
      margin-left: 7px;
    }
    &--#{$top-position} {
      bottom: 0;
      left: 100%;
      margin-left: 7px;
    }
  }
}

.flap-dropdown-list-#{top-position}-enter-active,
.flap-dropdown-list-#{top-position}-leave-active,
.flap-dropdown-list-#{bottom-position}-enter-active,
.flap-dropdown-list-#{bottom-position}-leave-active {
  transition: transform 300ms cubic-bezier(0.25, 0.1, 0.5, 1),
    opacity 200ms cubic-bezier(0.25, 0.1, 0.5, 1);
}

.flap-dropdown-list-#{top-position}-enter,
.flap-dropdown-list-#{top-position}-leave,
.flap-dropdown-list-#{bottom-position}-enter,
.flap-dropdown-list-#{bottom-position}-leave-to {
  opacity: 0;
}

.flap-dropdown-list-#{top-position}-enter {
  transform: translate3d(0, 10px, 0);
}
.flap-dropdown-list-#{bottom-position}-enter {
  transform: translate3d(0, -10px, 0);
}

.flap-dropdown-list-#{top-position}-leave-active,
.flap-dropdown-list-#{bottom-position}-leave-active {
  transition: opacity 150ms ease-out;
}
</style>
