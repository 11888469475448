import BaseFullscreenHandler from '@/classes/FullscreenHandler/BaseFullscreenHandler'

class FallbackFullscreenHandler extends BaseFullscreenHandler {
  fullscreen() {
    this._setFullscreenStatus(true)
  }
  exit() {
    this._setFullscreenStatus(false)
  }
}

export default FallbackFullscreenHandler
