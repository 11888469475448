<template>
  <section>
    <VTitle text="AppButtonTextWithIcon" />
    <div class="example-component">
      <div class="example-component__controls">
        <VPropControl
          v-for="(propModel, propName) in propModels"
          :key="propName"
          :model="propModel"
          :name="propName"
        />
      </div>
      <div class="example-component__component">
        <AppButtonTextWithIcon
          :text="propModels.text.value"
          :icon="propModels.icon.value"
          :button-style="propModels.buttonStyle.value"
          :size="propModels.size.value"
          :disabled="propModels.disabled.value"
          :reverse="propModels.reverse.value"
          :collapsable="propModels.collapsable.value"
        />
      </div>
    </div>
  </section>
</template>

<script>
import AppButtonTextWithIcon from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonTextWithIcon'
import AppPropModel from '@/components/UIKit/Standard/ViewerSections/classes/AppPropModel'
import VTitle from '@/components/UIKit/Standard/ViewerSections/components/snippets/VTitle'
import VPropControl from '@/components/UIKit/Standard/ViewerSections/components/snippets/VPropControl'
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'
import iconNames from '@/constants/ui-kit/standard/icon-names'

export default {
  name: 'AppButtonTextWithIconViewer',
  components: {
    AppButtonTextWithIcon,
    VTitle,
    VPropControl,
  },
  data() {
    return {
      propModels: {
        text: new AppPropModel({
          type: String,
          defaultValue: 'Button CTA',
        }),
        icon: new AppPropModel({
          type: String,
        }),
        buttonStyle: new AppPropModel({
          type: String,
        }),
        size: new AppPropModel({
          type: String,
        }),
        disabled: new AppPropModel({
          type: Boolean,
          defaultValue: true,
        }),
        reverse: new AppPropModel({
          type: Boolean,
          defaultValue: true,
        }),
        collapsable: new AppPropModel({
          type: Boolean,
          defaultValue: true,
        }),
      },
    }
  },
  created() {
    Object.keys(sizes).forEach((sizeName) => {
      this.propModels.size.addPreset(sizes[sizeName], sizeName)
    })

    Object.keys(styles).forEach((styleName) => {
      this.propModels.buttonStyle.addPreset(styles[styleName], styleName)
    })

    this.propModels.icon.addPreset(iconNames.UIDropdown, iconNames.UIDropdown)
    this.propModels.icon.addPreset(
      iconNames.UIChevronBottom,
      iconNames.UIChevronBottom
    )
    this.propModels.icon.addPreset(iconNames.UIAreas, iconNames.UIAreas)
    this.propModels.icon.addPreset(iconNames.UIFloors, iconNames.UIFloors)
  },
}
</script>

<style lang="scss" scoped>
.example-component {
  display: flex;
  justify-content: stretch;
  align-items: stretch;

  @at-root &__controls {
    background: #fff;
    padding: 30px;
    border: 1px solid var(--gray-200);
    flex-basis: 300px;
    border-radius: 12px;
  }

  @at-root &__component {
    padding: 30px;
  }
}
</style>
