<template>
  <AppSheetUnitLarge
    :preview-image-url="previewImageUrl"
    :title="unitShortIdentifier"
    :subtitle="unitModel"
    :with-unavailable-message="shouldDisplayUnavailableMessage"
    :with-check-price-button="shouldDisplayCheckPriceButton"
    :with-quoter-button="shouldDisplayQuoterButton"
    :with-embedded-form-button="shouldDisplayEmbeddedFormButton"
    :availability-status="unitDisplayAvailabilityText"
    :availability-color="unitAvailabilityColor"
    :enter-to="unitEnterToLink"
    :tour360-to="unitTour360ToLink"
    :features="unitFeatures"
    :price="unitPrice"
    :brochure-file-name="brochureFileName"
    :brochure-url="unitBrochureFileName"
    :with-disclaimer-button="!!disclaimer"
    :share-url="unitShareUrl"
    :embedded-contact-form-button-text="unitEmbeddedContactFormButtonText"
    :custom-content-block="unitCustomContentBlock"
    @open-disclaimer="showDisclaimer"
    @request-price="openUnitInfoRequestPrice"
    @launch-quoter="openUnitQuoter"
    @launch-embedded-contact-form="openUnitEmbeddedContactForm"
  />
</template>

<script>
import AppSheetUnitLarge from '@/components/UIKit/Standard/Organisms/AppSheet/AppSheetUnitLarge'

import responsiveMixin from '@/mixins/responsiveMixin'
import { mapGetters } from 'vuex'
import Unit from '@/classes/ProjectStructure/Unit/Unit'
import Level from '@/classes/ProjectStructure/Level'
import Tower from '@/classes/ProjectStructure/Tower'
import UnitAvailabilityStatusTranslationFactory from '@/classes/UnitAvailabilityStatusTranslationFactory'
import NeighbourhoodSubdivision from '@/classes/ProjectStructure/NeighbourhoodSubdivision'
import sizes from '@/constants/ui-kit/standard/sizes'

import UnitAccessRouterLinks from '@/classes/UnitAccessRouterLinks'
import modalSupportedComponents from '@/constants/ui-kit/standard/modal/modal-supported-components'

export default {
  name: 'UnitPanelLargeContent',
  components: {
    AppSheetUnitLarge,
  },
  mixins: [responsiveMixin],
  props: {
    projectElement: {
      type: Unit,
      default: () => undefined,
    },
    accessLinksEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      shouldStopGesturePropagation: false,
      enterButtonSize: sizes.SM,
    }
  },
  computed: {
    ...mapGetters({
      // FIXME: Consider adding another field with the project name only outside the context of contactData
      contactData: 'Project/contactData',
      disclaimer: 'Project/disclaimer',
      getUnitPrice: 'Topview/getUnitPrice',
      isOfflineModeEnabled: 'Project/isOfflineModeEnabled',
      enableCheckPriceForm: 'Preferences/enableCheckPriceForm',
      displayAvailability: 'Preferences/displayAvailability',
      displayPrice: 'Preferences/displayPrice',
      isQuoterModuleEnabled: 'QuoterModule/isQuoterModuleEnabled',
      canUnitBeQuoted: 'QuoterModule/canUnitBeQuoted',
      isUnitContactEmbeddedFormAvailable:
        'EmbeddedFormsModule/isUnitContactEmbeddedFormAvailable',
      unitEmbeddedContactFormButtonText:
        'EmbeddedFormsModule/unitEmbeddedContactFormButtonText',
      unitEmbeddedContactFormUrl:
        'EmbeddedFormsModule/unitEmbeddedContactFormUrl',
      hideQuoterButton: 'Preferences/hideQuoterButton',
    }),
    unit() {
      return this.projectElement
    },
    previewImageUrl() {
      return this.unit.previewImageUrl
    },
    unitModel() {
      return this.unit.model
    },
    unitShortIdentifier() {
      return this.unit.shortIdentifier
    },
    shouldDisplayUnavailableMessage() {
      return this.displayAvailability && !this.unit.available
    },
    unitAvailabilityColor() {
      return this.displayAvailability ? this.unit.availabilityColor : undefined
    },
    unitDisplayAvailabilityText() {
      return this.displayAvailability
        ? this.$t(
            UnitAvailabilityStatusTranslationFactory.makeTranslationKeyByUnit(
              this.unit
            )
          )
        : undefined
    },
    unitFeatures() {
      return this.unit.features
    },
    shouldDisplayPrice() {
      return (
        !this.isQuoterModuleEnabled &&
        this.displayPrice &&
        !this.shouldDisplayUnavailableMessage
      )
    },
    unitPrice() {
      return this.shouldDisplayPrice ? this.getUnitPrice(this.unit) : undefined
    },
    shouldDisplayCheckPriceButton() {
      if (
        this.shouldDisplayQuoterButton ||
        this.shouldDisplayEmbeddedFormButton
      ) {
        return false
      }
      return (
        this.enableCheckPriceForm &&
        !this.unitPrice &&
        !this.shouldDisplayUnavailableMessage
      )
    },
    shouldDisplayEmbeddedFormButton() {
      if (this.shouldDisplayQuoterButton) {
        return false
      }
      return (
        this.isUnitContactEmbeddedFormAvailable &&
        !this.shouldDisplayUnavailableMessage
      )
    },
    shouldDisplayQuoterButton() {
      if (this.hideQuoterButton) {
        return false
      }

      const canUnitBeQuoted = this.canUnitBeQuoted(this.unit)
      const isUnitExplicitlyUnavailable = this.shouldDisplayUnavailableMessage
      return !isUnitExplicitlyUnavailable && canUnitBeQuoted
    },
    unitBrochureFileName() {
      return this.unit.brochureUrl
    },
    unitAccessRouterLinks() {
      try {
        return new UnitAccessRouterLinks(this.unit, this.$loggingService)
      } catch (error) {
        this.$loggingService.logError(
          `UnitPanelLargeContent.unitAccessRouterLinks: error creating UnitAccessRouterLinks. ${error.toString()}`
        )
        return null
      }
    },
    unitEnterToLink() {
      return this.accessLinksEnabled
        ? this.unitAccessRouterLinks.default
        : undefined
    },
    unitTour360ToLink() {
      return this.accessLinksEnabled
        ? this.unitAccessRouterLinks.tour360
        : undefined
    },
    unitTowerName() {
      if (!this.$projectType.is([this.$projectType.MULTITOWER_TYPE])) {
        return undefined
      }

      const currentLevel = this.unit.parent()
      if (!(currentLevel instanceof Level)) {
        this.$loggingService.logError(
          'UnitPanelLargeContent.unitTowerName(): currentLevel is not an instance of Level class'
        )
        return undefined
      }
      const currentTower = currentLevel.parent()
      if (!(currentTower instanceof Tower)) {
        this.$loggingService.logError(
          'UnitPanelLargeContent.unitTowerName(): currentTower is not an instance of Tower class'
        )
        return undefined
      }

      return currentTower.name
    },
    unitSubdivisionName() {
      if (!this.$projectType.is([this.$projectType.NEIGHBOURHOOD_TYPE])) {
        return undefined
      }

      const currentSubdivision = this.unit.parent()

      if (!(currentSubdivision instanceof NeighbourhoodSubdivision)) {
        this.$loggingService.logError(
          'UnitPanelLargeContent.unitSubdivisionName(): currentSubdivision is not an instance of NeighbourhoodSubdivision class'
        )
        return undefined
      }

      return currentSubdivision.name
    },
    unitLevelName() {
      if (
        !this.$projectType.is([
          this.$projectType.SINGLETOWER_TYPE,
          this.$projectType.MULTITOWER_TYPE,
        ])
      ) {
        return undefined
      }
      const currentLevel = this.unit.parent()

      if (!(currentLevel instanceof Level)) {
        this.$loggingService.logError(
          'UnitPanelLargeContent.unitLevelName(): currentLevel is not an instance of Level class'
        )
        return undefined
      }

      return currentLevel.name
    },
    brochureFileName() {
      return this.$t(this.unit.brochureFileNameLocaleKey, {
        unitId: this.unit.label,
        name: this.unit.name,
        level: this.unitLevelName,
        projectName: this.contactData.projectName,
        tower: this.unitTowerName,
        subdivision: this.unitSubdivisionName,
      })
    },
    unitShareUrl() {
      if (this.isOfflineModeEnabled) {
        return undefined
      }

      try {
        const routerLinks = new UnitAccessRouterLinks(
          this.unit,
          this.$loggingService
        )
        const { href } = this.$router.resolve(routerLinks.default)
        return new URL(href, document.baseURI).href
      } catch (error) {
        this.$loggingService.logError(
          `UnitPanelLargeContent.unitShareUrl: error creating UnitAccessRouterLinks. ${error.toString()}`
        )
      }

      return null
    },
    unitCustomContentBlock() {
      return this.projectElement.customContentBlock
    },
  },
  methods: {
    showDisclaimer() {
      this.$store.dispatch('ModalLauncher/launchModal', {
        component: modalSupportedComponents.AppDisclaimerModal,
      })
    },
    openUnitInfoRequestPrice() {
      this.$store.dispatch('Topview/openUnitInfoRequestForm', {
        unit: this.unit,
        requestPrice: true,
      })
    },
    openUnitQuoter() {
      this.$store.dispatch('QuoterModule/openQuoterModal', this.unit)
    },
    openUnitEmbeddedContactForm() {
      this.$store.dispatch('ModalLauncher/launchModal', {
        component: modalSupportedComponents.AppEmbeddedContactFormModal,
        properties: {
          title: this.unitEmbeddedContactFormButtonText,
          formUrl: this.unitEmbeddedContactFormUrl + this.unit.slug,
        },
      })
    },
  },
}
</script>
