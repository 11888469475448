import { UnitTypes, OfferTypes } from '@/constants/units'

export const filterableUnitSubjectTranslationKeys = [
  {
    subject: 'Comprar un apartamento',
    filterParams: {
      type: UnitTypes.APARTMENT,
      offerType: OfferTypes.FOR_SALE,
    },
  },
  {
    subject: 'Comprar un local',
    filterParams: {
      type: UnitTypes.SHOP,
      offerType: OfferTypes.FOR_SALE,
    },
  },
  {
    subject: 'Comprar una oficina',
    filterParams: {
      type: UnitTypes.OFFICE,
      offerType: OfferTypes.FOR_SALE,
    },
  },
  {
    subject: 'Comprar una casa',
    filterParams: {
      type: UnitTypes.HOUSE,
      offerType: OfferTypes.FOR_SALE,
    },
  },
  {
    subject: 'Alquilar un apartamento',
    filterParams: {
      type: UnitTypes.APARTMENT,
      offerType: OfferTypes.FOR_RENT,
    },
  },
  {
    subject: 'Alquilar un local',
    filterParams: {
      type: UnitTypes.SHOP,
      offerType: OfferTypes.FOR_RENT,
    },
  },
  {
    subject: 'Alquilar una oficina',
    filterParams: {
      type: UnitTypes.OFFICE,
      offerType: OfferTypes.FOR_RENT,
    },
  },
  {
    subject: 'Alquilar una casa',
    filterParams: {
      type: UnitTypes.HOUSE,
      offerType: OfferTypes.FOR_RENT,
    },
  },
]

export const generalSubjectsTranslationKeys = [
  'Conocer más sobre el proyecto',
  'Realizar una cita con un agente de ventas',
  'Otro motivo',
]
