import ProjectType from '@/classes/ProjectType'

let ProjectTypePlugin = {}

ProjectTypePlugin.install = function(Vue, options) {
  const { projectType } = options

  if (ProjectType.exist) {
    throw new Error(
      'ProjectType.plugin: An instance of ProjectType already exists'
    )
  }

  Vue.prototype.$projectType = new ProjectType(projectType)
}

export default ProjectTypePlugin
