import transitionApiModes from '@/constants/transition-manager/transition-api-modes'
import Transition from './Transition'
import VideoTransitionParams from '@/classes/TransitionTree/VideoTransitionParams'

export default class VideoTransition extends Transition {
  constructor(transitionParams, { videoElement }) {
    super(transitionParams)
    if (!(this._transitionParams instanceof VideoTransitionParams)) {
      throw new Error(
        `VideoTransition: Passed transitionParams is not an instance of class VideoTransitionParams`
      )
    }
    if (!videoElement) {
      throw new Error('VideoTransition: invalid video element.')
    }
    this._isVideoPlaying = false
    this._isVideoSourceAvailable = false
    this._transitionComponentProps.mode = transitionApiModes.OUT_IN
    this._transitionComponentProps.css = false

    this._videoElement = videoElement
    const canReplaceSourceSecurely =
      this._videoElement.paused ||
      this._videoElement.readyState >= this._videoElement.HAVE_CURRENT_DATA
    if (canReplaceSourceSecurely) {
      this._setVideoSource()
    } else if (!this._videoElement.paused) {
      this._videoElement.onloadeddata = () => {
        this._videoElement.onloadeddata = null
        this._setVideoSource()
      }
    }
  }
  get shouldDisplayVideo() {
    return this._isVideoPlaying
  }
  async enter() {
    if (this._isVideoSourceAvailable) {
      await this.videoEnds()
    }
    this._isInTransitionPerformed = true
  }
  async leave() {
    if (this._isVideoSourceAvailable) {
      await this.videoStarts()
    }
    this._isOutTransitionPerformed = true
  }
  _setVideoSource() {
    if (this._transitionParams.videoBlobUrl) {
      this._videoElement.src = this._transitionParams.videoBlobUrl
      this._isVideoSourceAvailable = true
    } else {
      this._videoElement.src = ''
      this._isVideoSourceAvailable = false
    }
  }
  videoStarts() {
    return new Promise((resolve) => {
      this._videoElement.play()
      this._isVideoPlaying = true
      this._videoElement.ontimeupdate = () => {
        const defaultTimeThreshold = 0.5
        const durationBasedThreshold =
          this._videoElement.duration / 2 || defaultTimeThreshold
        const timeThreshold = Math.min(
          defaultTimeThreshold,
          durationBasedThreshold
        )
        if (this._videoElement.currentTime > timeThreshold) {
          this._videoElement.ontimeupdate = null
          resolve()
        }
      }
    })
  }
  videoEnds() {
    return new Promise((resolve) => {
      this._videoElement.onended = () => {
        this._videoElement.onended = null
        this._isVideoPlaying = false
        this._isInTransitionPerformed = true
        resolve()
      }
    })
  }
}
