<template>
  <AppSheetWrapper class="app-sheet-contact-additional-details">
    <AppContactAdditionalDataRichText
      :html-content="htmlContent"
      :compact="false"
    />
  </AppSheetWrapper>
</template>

<script>
import AppSheetWrapper from '@/components/UIKit/Standard/Atoms/AppSheetWrapper'
import AppContactAdditionalDataRichText from '@/components/UIKit/Standard/Atoms/AppContactAdditionalDataRichText'

export default {
  name: 'AppSheetContactAdditionalDetails',
  components: {
    AppContactAdditionalDataRichText,
    AppSheetWrapper,
  },
  props: {
    htmlContent: {
      type: String,
      default: undefined,
    },
  },
}
</script>
