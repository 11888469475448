<template>
  <TheInfoPanel
    ref="infoPanel"
    :identifier="panelIdentifier"
    :disable-container-scroll="shouldDisablePanelContainerScroll"
    :display-contact-bar="shouldDisplayContactBar"
    @open="onOpen"
    @closed="onClosed"
    @request-open-form="onRequestOpenForm"
    @width-updated="onWidthUpdated"
  >
    <template #large>
      <component
        :is="largeContentComponent"
        :project-element="selectedSvgElement"
        access-links-enabled
      />
    </template>
    <template #medium>
      <component
        :is="mediumContentComponent"
        :project-element="selectedSvgElement"
        access-links-enabled
      />
    </template>
    <template #small>
      <component
        :is="smallContentComponent"
        :project-element="selectedSvgElement"
        access-links-enabled
      />
    </template>
  </TheInfoPanel>
</template>

<script>
import { mapGetters } from 'vuex'
import responsiveMixin from '@/mixins/responsiveMixin'
import Unit from '@/classes/ProjectStructure/Unit/Unit'
import TheInfoPanel from '@/components/Gadgets/TheInfoPanel'
import PointOfInterestPanelSmallContent from '@/components/PanelContent/panels/PointOfInterestPanel/PointOfInterestPanelSmallContent'
import TowerPanelContent from '@/components/PanelContent/panels/TowerPanelContent'
import NeighbourhoodSubdivisionPanelContent from '@/components/PanelContent/panels/NeighbourhoodSubdivisionPanelContent'
import UnitPanelLargeContentFactory from '@/components/PanelContent/panels/UnitPanel/UnitPanelLargeContentFactory'
import UnitPanelMediumContentFactory from '@/components/PanelContent/panels/UnitPanel/UnitPanelMediumContentFactory'
import ProjectElementTypes from '@/constants/project-structure/project-element-types'

const largeContentComponentsByProjectElementClassName = {
  [ProjectElementTypes.NEIGHBOURHOOD_SUBDIVISION]: undefined,
  [ProjectElementTypes.TOWER]: undefined,
  [ProjectElementTypes.POINT_OF_INTEREST]: undefined,
  [ProjectElementTypes.UNIT]: 'UnitPanelLargeContentFactory',
}

const mediumContentComponentsByProjectElementClassName = {
  [ProjectElementTypes.NEIGHBOURHOOD_SUBDIVISION]: undefined,
  [ProjectElementTypes.TOWER]: undefined,
  [ProjectElementTypes.POINT_OF_INTEREST]: undefined,
  [ProjectElementTypes.UNIT]: undefined,
}

const smallContentComponentsByProjectElementClassName = {
  [ProjectElementTypes.NEIGHBOURHOOD_SUBDIVISION]:
    'NeighbourhoodSubdivisionPanelContent',
  [ProjectElementTypes.TOWER]: 'TowerPanelContent',
  [ProjectElementTypes.POINT_OF_INTEREST]: 'PointOfInterestPanelSmallContent',
  // UnitPanelMediumContentFactory is originally used in project-details as medium content size, but in SVG pages it is used as small content size
  [ProjectElementTypes.UNIT]: 'UnitPanelMediumContentFactory',
}

export default {
  name: 'SelectedSvgElementInfoPanel',
  components: {
    TheInfoPanel,
    PointOfInterestPanelSmallContent,
    TowerPanelContent,
    UnitPanelLargeContentFactory,
    UnitPanelMediumContentFactory,
    NeighbourhoodSubdivisionPanelContent,
  },
  mixins: [responsiveMixin],
  computed: {
    ...mapGetters({
      selectedSvgElement: 'SvgInteractionTracker/selectedSvgElement',
    }),
    projectElementClassName() {
      return this.selectedSvgElement?.projectElementType
    },
    largeContentComponent() {
      return largeContentComponentsByProjectElementClassName[
        this.projectElementClassName
      ]
    },
    mediumContentComponent() {
      return mediumContentComponentsByProjectElementClassName[
        this.projectElementClassName
      ]
    },
    smallContentComponent() {
      return smallContentComponentsByProjectElementClassName[
        this.projectElementClassName
      ]
    },
    panelIdentifier() {
      return this.selectedSvgElement
        ? `${this.projectElementClassName}-${this.selectedSvgElement.id}`
        : ''
    },
    isSelectedElementAUnit() {
      return this.selectedSvgElement instanceof Unit
    },
    shouldDisablePanelContainerScroll() {
      return this.isSelectedElementAUnit && !this.isSmallScreenHorizontal
    },
    shouldDisplayContactBar() {
      return this.isSelectedElementAUnit
    },
  },
  watch: {
    $route() {
      this.resetSelectedElement()
    },
    selectedSvgElement() {
      if (
        this.isSelectedElementAUnit ||
        (this.isSmallScreen && this.selectedSvgElement)
      ) {
        this.$refs.infoPanel.open()
      } else {
        this.$refs.infoPanel.close()
      }
    },
  },
  beforeDestroy() {
    this.resetSelectedElement()
  },
  methods: {
    resetSelectedElement() {
      this.$store.dispatch('SvgInteractionTracker/hideSvgElementPanel')
    },
    open() {
      this.$refs.infoPanel.open()
    },
    close() {
      this.$refs.infoPanel.close()
    },
    onOpen() {
      this.$emit('open')
    },
    onClosed() {
      this.resetSelectedElement()
      this.$emit('closed')
    },
    onWidthUpdated(currentWidth) {
      this.$emit('width-updated', currentWidth)
    },
    onRequestOpenForm() {
      this.$store.dispatch('Topview/openUnitInfoRequestForm', {
        unit: this.selectedSvgElement,
        requestPrice: false,
      })
    },
  },
}
</script>
