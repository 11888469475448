<template>
  <transition
    name="swipe-left-right"
    mode="out-in"
    @before-enter="emitWidthUpdated"
    @before-leave="emitWidthUpdated"
  >
    <TouchManager
      v-if="isOpen"
      :key="identifier"
      tag="section"
      class="sidebar-panel"
      @swipe-left="onSwipeLeft"
    >
      <div ref="panelContainer" class="sidebar-panel__content">
        <AppScrollbarContainer
          :disabled="disableContainerScroll"
          class="sidebar-panel__content__scroll"
          :class="{
            'sidebar-panel__content__scroll--disabled': disableContainerScroll,
          }"
        >
          <div class="sidebar-panel__content__scroll__inner">
            <div class="sidebar-panel__controls">
              <AppButtonIcon
                :icon="closeButtonIcon"
                :button-style="closeButtonStyle"
                :size="closeButtonSize"
                @click.native="onCloseButtonClick"
              />
            </div>
            <div class="sidebar-panel__slot-wrapper">
              <slot></slot>
            </div>
          </div>
        </AppScrollbarContainer>
      </div>
      <div v-if="displayContactBar" class="sidebar-panel__footer">
        <InfoPanelOfflineModeContactBar v-if="isOfflineModeEnabled" />
        <AppSheetContactInformation
          v-else
          :contact-data="contactData"
          :with-contact-form-button="enableContactForms"
          @open-email-form="requestOpenForm"
        />
      </div>
    </TouchManager>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import { ICON_THEMES } from '@/constants/ui/themes'
import TouchManager from '@/components/Gadgets/TouchManager'
import AppButtonIcon from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonIcon'
import AppScrollbarContainer from '@/components/UIKit/Standard/Atoms/AppScrollbarContainer'
import iconNames from '@/constants/ui-kit/standard/icon-names'
import styles from '@/constants/ui-kit/standard/styles'
import sizes from '@/constants/ui-kit/standard/sizes'
import AppSheetContactInformation from '@/components/UIKit/Standard/Molecules/AppSheetContactInformation'
import InfoPanelOfflineModeContactBar from '@/components/Gadgets/TheInfoPanel/InfoPanelOfflineModeContactBar'

export default {
  name: 'SidebarPanel',
  components: {
    TouchManager,
    AppButtonIcon,
    AppScrollbarContainer,
    AppSheetContactInformation,
    InfoPanelOfflineModeContactBar,
  },
  props: {
    iconsTheme: {
      type: String,
      default: ICON_THEMES.DARK,
    },
    identifier: {
      type: String,
      default: 'sidebar-panel',
    },
    disableContainerScroll: {
      type: Boolean,
      default: false,
    },
    displayContactBar: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOpen: false,
      closeButtonStyle: styles.LINK,
      closeButtonSize: sizes.XS,
      closeButtonIcon: iconNames.UIClose,
    }
  },
  computed: {
    ...mapGetters({
      isOfflineModeEnabled: 'Project/isOfflineModeEnabled',
      contactData: 'Project/contactData',
      enableContactForms: 'Preferences/enableContactForms',
    }),
  },
  methods: {
    async open() {
      if (!this.isOpen) {
        this.isOpen = true
      }
    },
    async close() {
      this.isOpen = false
    },
    onCloseButtonClick() {
      this.close()
      this.$emit('closed-by-user')
    },
    onSwipeLeft() {
      this.close()
      this.$emit('closed-by-user')
    },
    async emitWidthUpdated() {
      await this.$nextTick()
      const panelWidth =
        this.isOpen && this.$refs.panelContainer
          ? this.$refs.panelContainer.offsetWidth
          : 0
      this.$emit('width-updated', panelWidth)
    },
    requestOpenForm() {
      this.$emit('request-open-form')
    },
  },
}
</script>

<style lang="scss" scoped>
$preview-image-height: 196px;
$panel-width: 375px;

.sidebar-panel {
  position: fixed;
  width: $panel-width;
  top: 0;
  left: 0;
  background: var(--white);
  @include inner-height;
  z-index: 1;
  display: flex;
  flex-flow: column wrap;

  $panel: &;
  @at-root #{&}__content {
    flex: 1;
    width: 100%;
    overflow: hidden;

    @at-root #{&}__scroll {
      height: 100%;
      overflow: auto;

      #{&}__inner {
        position: relative;
        display: flex;
        flex-direction: column;
      }

      @at-root #{&}--disabled {
        overflow: hidden;
        #{$panel}__content__scroll__inner {
          height: 100%;
        }
        #{$panel}__slot-wrapper {
          overflow: hidden;
        }
      }
    }
  }

  @at-root #{&}__controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 16px;
    margin: 16px 0 0;
    min-height: 40px;
  }
  @at-root #{&}__slot-wrapper {
    flex: 1;
  }
}

.swipe-left-right-enter-active,
.swipe-left-right-leave-active {
  transition: transform 500ms cubic-bezier(0.15, 0, 0, 1);
  transition-delay: 50ms;
}
.swipe-left-right-enter,
.swipe-left-right-leave-to {
  transform: translateX(-100%);
}
.swipe-left-right-leave-to {
  transition: transform 400ms cubic-bezier(0.75, 0, 0.65, 1),
    transform 400ms ease-in-out;
}
</style>
