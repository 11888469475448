import BaseTransitionParams from './BaseTransitionParams'

class AnimationTransitionParams extends BaseTransitionParams {
  constructor({ effect }) {
    super()
    if (!effect) {
      throw new Error(
        'AnimationTransitionParams: Required param "effect" is missing.'
      )
    }
    this._effect = effect
  }
  get effect() {
    return this._effect
  }
  static get TYPE() {
    return 'animation'
  }
}
export default AnimationTransitionParams
