<template>
  <section class="container">
    <h1>AppSubmenuTitle</h1>
    <table>
      <tr>
        <th><AppSubmenuTitle title="Areas" /></th>
      </tr>
      <tr>
        <th><AppSubmenuTitle title="Torres" /></th>
      </tr>
      <tr>
        <th><AppSubmenuTitle title="Some submenu" :icon="testIcon" /></th>
      </tr>
    </table>
  </section>
</template>

<script>
import AppSubmenuTitle from '@/components/UIKit/Standard/Atoms/AppSubmenuTitle'
import iconNames from '@/constants/ui-kit/standard/icon-names'

export default {
  name: 'AppSubmenuTitleViewer',
  components: {
    AppSubmenuTitle,
  },
  data() {
    return {
      testIcon: iconNames.UIHome,
    }
  },
}
</script>

<style lang="scss" scoped>
.container {
  margin: 80px 50px;
}
h1 {
  font-size: 1.5rem;
  margin: 2.5em 0 1.5em;
  border-bottom: 1px solid black;
}
th {
  font-weight: 400;
  font-size: 0.75rem;
}
th,
td {
  padding: 5px 10px;
}
</style>
