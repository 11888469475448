<template>
  <div class="location-panel-small-content">
    <app-sheet-location-small
      :html-content="locationAdditionalDetails.shortRichText"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AppSheetLocationSmall from '@/components/UIKit/Standard/Organisms/AppSheet/AppSheetLocationSmall.vue'

export default {
  name: 'LocationPanelMediumContent',
  components: {
    AppSheetLocationSmall,
  },
  computed: {
    ...mapGetters({
      locationAdditionalDetails: 'Project/locationAdditionalDetails',
    }),
  },
}
</script>
