<template>
  <canvas ref="canvas" width="1920" height="1080"></canvas>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SpriteClip',
  props: {
    imageUrl: {
      type: String,
      default: () => undefined,
    },
    blured: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      spriteImage: document.createElement('img'),
    }
  },
  computed: {
    ...mapGetters({
      getSpriteClipByImageUrl: 'DownloadManager/getSpriteClipByImageUrl',
      areSpritesReady: 'DownloadManager/areSpritesReady',
    }),
    currentSpriteClip() {
      const spriteClip = this.getSpriteClipByImageUrl(this.imageUrl)
      return spriteClip
        ? spriteClip
        : {
            spriteImageBlob: undefined,
            spriteOrder: 0,
          }
    },
    isASpriteAvailableForTheImageUrl() {
      return (
        this.areSpritesReady &&
        this.imageUrl &&
        this.currentSpriteClip.spriteImageBlob !== undefined
      )
    },
  },
  watch: {
    isASpriteAvailableForTheImageUrl() {
      if (this.isASpriteAvailableForTheImageUrl) {
        this.updateSpriteImageSrc()
      }
    },
  },
  mounted() {
    this.spriteImage.addEventListener('load', this.drawSpriteClip)
    if (this.isASpriteAvailableForTheImageUrl) {
      this.updateSpriteImageSrc()
    } else if (this.areSpritesReady) {
      this.$loggingService.logError(
        `SpriteClip: There's no sprite clip available for the image ${this.imageUrl}`
      )
    }
  },
  beforeDestroy() {
    this.spriteImage.removeEventListener('load', this.drawSpriteClip)
  },
  methods: {
    updateSpriteImageSrc() {
      this.spriteImage.src = this.currentSpriteClip.spriteImageBlob
    },
    drawSpriteClip() {
      const canvasContext = this.$refs.canvas.getContext('2d')
      const canvasWidth = this.spriteImage.naturalWidth
      const canvasHeight = (9 / 16) * canvasWidth
      const spriteYPosition = -canvasHeight * this.currentSpriteClip.spriteOrder

      this.$refs.canvas.width = canvasWidth
      this.$refs.canvas.height = canvasHeight
      canvasContext.drawImage(this.spriteImage, 0, spriteYPosition)
      if (this.blured) {
        const blurValue = canvasWidth * 0.005
        canvasContext.filter = `blur(${blurValue}px) grayscale(40%)`
        canvasContext.drawImage(this.$refs.canvas, 0, 0)
      }
    },
  },
}
</script>
