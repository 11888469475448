<template>
  <TheFullViewportContainer class="amenities-gallery">
    <ThePaginationControls
      v-show="!isTour360Gallery"
      :page-routes="collectionPages"
      @unrecognized-route="onUnrecognizedRoute"
    />
    <general-amenities-gallery-top-bar-controls
      class="amenities-gallery__top-bar"
      :label-text="labelText"
      :selected-tour360="selectedTour360"
      :tour360-select-options="tour360SelectOptions"
      :should-tour360-selector-be-shown="shouldTopbarTour360SelectorBeShown"
      :gallery-collections-options="galleryCollectionsItems"
      :selected-gallery-slug="collectionSlug"
      @navigate-to-tour360="navigateToTour360"
      @redirect-to-gallery-collection="onChangeGalleryCollection"
    />
    <AppFlapDropdown
      v-if="showFlapDropdown"
      class="amenities-gallery__gallery-collections-flap-dropdown"
      :options="galleryCollectionsItems"
      :selected-value="collectionSlug"
      :list-position="galleryCollectionFlapDropdownPosition"
      @selectedOptionChange="onChangeGalleryCollection"
    />
    <AppDropdown
      v-if="shouldTour360DropdownBeShown"
      class="amenities-gallery__tour360-dropdown"
      :dropdown-style="tour360DropdownStyle"
      :options="tour360SelectOptions"
      :selected-value="selectedTour360"
      @selectedOptionChange="navigateToTour360"
    />
    <modal-hint-container
      v-if="shouldDisplayModalHint"
      hint-key="HorizontalScrollHint"
    ></modal-hint-container>
  </TheFullViewportContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import ThePaginationControls from '@/components/Gadgets/ThePaginationControls'
import { galleryCollectionsData } from '@/constants/galleries'
import CollectionSlugs from '@/classes/Gallery/CollectionSlugs'
import TheFullViewportContainer from '@/components/Gadgets/TheFullViewportContainer'
import AppFlapDropdown from '@/components/UIKit/Standard/Molecules/AppFlapDropdown'
import verticalPositions from '@/constants/ui-kit/standard/vertical-positions'
import responsiveMixin from '@/mixins/responsiveMixin'
import ModalHintContainer from '@/components/Hint/ModalHintContainer'
import HomeRouteObjectGenerator from '@/classes/RouteObjectGenerators/HomeRouteObjectGenerator'
import AppDropdown from '@/components/UIKit/Standard/Molecules/AppDropdown'
import GeneralAmenitiesGalleryTopBarControls from '@/components/ViewTopBarControls/GeneralAmenitiesGalleryTopBarControls'
import styles from '@/constants/ui-kit/standard/styles'

export default {
  name: 'GeneralAmenitiesGallery',
  components: {
    ThePaginationControls,
    TheFullViewportContainer,
    ModalHintContainer,
    GeneralAmenitiesGalleryTopBarControls,
    AppFlapDropdown,
    AppDropdown,
  },
  mixins: [responsiveMixin],
  props: {
    collectionSlug: {
      type: String,
      required: true,
    },
    resourceId: {
      type: String,
      default: '1',
    },
  },
  data() {
    return {
      isFullscreen: false,
      galleryCollectionsData,
      tour360DropdownStyle: styles.LIGHT,
    }
  },
  computed: {
    ...mapGetters({
      amenitiesGallery: 'Project/amenitiesGallery',
    }),
    currentCollection() {
      return this.amenitiesGallery.collection(this.collectionSlug)
    },
    isTour360Gallery() {
      return this.currentCollection.is(CollectionSlugs.TOUR360)
    },
    tour360SelectOptions() {
      const options = []
      for (
        let position = 1;
        position <= this.currentCollection.size;
        position++
      ) {
        const resourceId = position.toString()
        options.push({
          value: resourceId,
          text: this.currentCollection.getResourceById(resourceId).labelText,
        })
      }
      return options
    },
    shouldTour360SelectorBeShown() {
      return this.isTour360Gallery && this.tour360SelectOptions.length > 1
    },
    shouldTopbarTour360SelectorBeShown() {
      return this.shouldTour360SelectorBeShown && !this.isSmallScreenVertical
    },
    shouldTour360DropdownBeShown() {
      return this.shouldTour360SelectorBeShown && this.isSmallScreenVertical
    },
    labelText() {
      if (this.currentCollection && !this.isTour360Gallery) {
        try {
          return this.currentCollection.getResourceById(this.resourceId)
            .labelText
        } catch (error) {
          this.$loggingService.logError(
            `GeneralAmenitiesGallery: Could not get requested amenities gallery resource ${
              this.resourceId
            }:  ${error.toString()}`
          )
        }
      }
      return ''
    },
    collectionPages() {
      const routerLinks = []
      for (let i = 1; i <= this.currentCollection.size; i++) {
        const resourceId = i.toString()
        routerLinks.push({
          name: this.$route.name,
          params: {
            useTransition: true,
            collectionSlug: this.currentCollection.slug,
            resourceId,
          },
        })
      }
      return routerLinks
    },
    galleryCollectionsItems() {
      return this.amenitiesGallery.availableCollections.map((collection) => {
        const { nameTranslationKey } = this.galleryCollectionsData[
          collection.slug
        ]

        return {
          text: this.$t(nameTranslationKey),
          value: collection.slug,
          disabled: this.$route.params.collectionSlug === collection.slug,
        }
      })
    },
    galleryCollectionFlapDropdownPosition() {
      if (this.isSmallScreenHorizontal) {
        return verticalPositions.TOP_POSITION
      } else {
        return verticalPositions.BOTTOM_POSITION
      }
    },
    showFlapDropdown() {
      return this.isSmallScreen && this.galleryCollectionsItems.length > 1
    },
    shouldDisplayModalHint() {
      return (
        this.isSmallScreenVertical &&
        this.collectionSlug !== CollectionSlugs.TOUR360
      )
    },
    selectedTour360() {
      return `${this.$route.params.resourceId}`
    },
  },
  mounted() {
    if (this.isSmallScreenVertical) {
      this.$store.dispatch('Layout/setTopLayerCurrentHint', 'RotateDeviceHint')
    }
  },
  methods: {
    onUnrecognizedRoute({ alternativeRoute }) {
      const homeRouteObject = HomeRouteObjectGenerator.make()
      const redirectionRoute = alternativeRoute || homeRouteObject
      redirectionRoute.params.useTransition = false
      this.$loggingService.logError(
        `GeneralAmenitiesGallery: The current route '${this.$route.path}' is not recognized in the ThePaginationControls as a valid option. ` +
          `Redirecting to: ${JSON.stringify(redirectionRoute)}.`
      )
      this.$router.replace(redirectionRoute)
    },
    navigateToTour360(selectedId) {
      const routeResourceId = this.$route.params.resourceId
      if (selectedId !== routeResourceId) {
        this.$router.push({
          name: 'general-amenities-gallery',
          params: {
            galleryType: CollectionSlugs.TOUR360,
            resourceId: selectedId,
          },
        })
      }
    },
    onChangeGalleryCollection(collectionSlug) {
      try {
        const collectionRouteObject = {
          name: this.$route.name,
          params: {
            collectionSlug: collectionSlug,
            resourceId: '1',
          },
        }

        this.$router.push(collectionRouteObject)
      } catch (error) {
        this.$loggingService.logError(error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.amenities-gallery {
  @at-root #{&}__top-bar {
    z-index: 1;
    @at-root #{&}__tour360-select {
      @include small-screens {
        max-width: calc(100vw - 195px);
      }
    }
  }

  @at-root &__gallery-collections-flap-dropdown {
    position: fixed;
    top: 50%;
    left: 0;
    margin-top: 4.375rem;
  }

  @at-root &__tour360-dropdown {
    --topbar-height: 72px;
    position: fixed;
    top: var(--topbar-height);
    left: 16px;
    margin-top: 5px;
  }
}
</style>
