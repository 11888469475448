<template>
  <div ref="content" class="pdf-maker" aria-hidden="true">
    <slot></slot>
  </div>
</template>

<script>
import jsPDF from 'jspdf'

export default {
  name: 'PDFMaker',
  data() {
    return {
      document: null,
    }
  },
  methods: {
    async createDocument() {
      return new Promise((resolve) => {
        const content = this.$refs.content
        content.scrollTo(0, 0)
        if (content && content.scrollWidth > 0) {
          const pageAspectRatio = content.scrollHeight / content.scrollWidth
          const pageContentWidth = 800
          const pageContentHeight = pageContentWidth * pageAspectRatio
          const margin = 10

          const pageWidth = pageContentWidth + 2 * margin
          const pageHeight = pageContentHeight + 2 * margin

          const document = new jsPDF({
            orientation: 'p',
            unit: 'px',
            format: [pageHeight, pageWidth],
          })

          document.html(content.innerHTML, {
            x: margin,
            y: margin,
            width: pageContentWidth,
            height: pageContentHeight,
            windowWidth: 800,
            callback: (doc) => {
              this.document = doc
              resolve()
            },
          })
        }
      })
    },
    async print(fileName) {
      await this.createDocument()
      this.document.save(fileName)
    },
  },
}
</script>

<style>
.pdf-maker {
  width: 0;
  height: 0;
  overflow: hidden;
}
</style>
