class VueDependency {
  // eslint-disable-next-line no-unused-vars
  async install(Vue, router) {
    throw new Error('VueDependency: install() not implemented')
  }
  get vueGlobalKeyValue() {
    throw new Error('VueDependency: get vueGlobalKeyValue() not implemented')
  }
  get dependencyName() {
    return this.constructor.name
  }
}
export default VueDependency
