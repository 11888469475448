import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views'
import Masterplan from '@/views/Masterplan'
import Topview from '@/views/Topview'
import Location from '@/views/Location'
import NeighbourhoodSubdivision from '@/views/NeighbourhoodSubdivision'
import PointOfInterest from '@/views/PointOfInterest'

import ProjectDetails from '@/views/ProjectDetails'
import Contact from '@/views/Contact'
import ProjectVideo from '@/views/ProjectVideo'
import Gallery from '@/views/Gallery'
import GeneralTours360Gallery from '@/views/GeneralTours360Gallery'
import ExteriorTour360 from '@/views/ExteriorTour360'
import AmenitiesGallery from '@/views/GeneralAmenitiesGallery'
import AvailabilityView from '@/views/AvailabilityView'

import StandaloneQuoter from '@/views/StandaloneQuoter/'
import UiKit from '@/views/UIKit/'

import HomeRouteObjectGenerator from '@/classes/RouteObjectGenerators/HomeRouteObjectGenerator'

import HistoryNavigationStepCheckersNames from '@/constants/navigation-history/navigation-history-step-checkers-names'

const homeLink = HomeRouteObjectGenerator.make()

Vue.use(VueRouter)

/*
 * Don't change route name without check CAREFULLY the use in components
 * along the project.
 */
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      layout: 'layout-default',
      shadowTop: true,
      shadowBottom: true,
      historyNavigationStepChecker:
        HistoryNavigationStepCheckersNames.GENERAL_NAVIGATION_STEP_CHECKER,
    },
  },
  {
    path: '/masterplan/:stopPointSlug?',
    name: 'masterplan',
    component: Masterplan,
    props: true,
    meta: {
      layout: 'layout-default',
      svgManagerComponent: 'MasterplanInteractiveSvgLayer',
      shadowTop: true,
      enableZoomDependingOnPreference: true,
      historyNavigationStepChecker:
        HistoryNavigationStepCheckersNames.GENERAL_NAVIGATION_STEP_CHECKER,
    },
  },
  {
    path: '/t/:levelId/:descriptiveSlug',
    name: 'topview',
    component: Topview,
    props: true,
    meta: {
      layout: 'layout-default',
      svgManagerComponent: 'TopviewInteractiveSvgLayer',
      shadowTop: true,
      enableZoomDependingOnPreference: true,
      historyNavigationStepChecker:
        HistoryNavigationStepCheckersNames.TOPVIEW_NAVIGATION_STEP_CHECKER,
    },
  },
  {
    path: '/ns/:neighbourhoodSubdivisionId/:descriptiveSlug',
    name: 'neighbourhood-subdivision',
    component: NeighbourhoodSubdivision,
    props: true,
    meta: {
      layout: 'layout-default',
      svgManagerComponent: 'NeighbourhoodSubdivisionInteractiveSvgLayer',
      shadowTop: true,
      enableZoomDependingOnPreference: true,
      historyNavigationStepChecker:
        HistoryNavigationStepCheckersNames.NEIGHBOURHOOD_SECTION_NAVIGATION_STEP_CHECKER,
    },
  },
  {
    path:
      '/u/:unitId/:descriptiveSlug/:unitLevelSlug?/:collectionSlug/:resourceId',
    name: 'projectdetails',
    component: ProjectDetails,
    props: true,
    meta: {
      layout: 'layout-default',
      isBackgroundImageZoomable: true,
      shadowTop: true,
      historyNavigationStepChecker:
        HistoryNavigationStepCheckersNames.GENERAL_NAVIGATION_STEP_CHECKER,
    },
  },
  {
    path: '/i/:pointOfInterestId/:descriptiveSlug/:resourceId',
    name: 'point-of-interest',
    component: PointOfInterest,
    props: true,
    meta: {
      layout: 'layout-default',
      isBackgroundImageZoomable: true,
      shadowTop: true,
      historyNavigationStepChecker:
        HistoryNavigationStepCheckersNames.GENERAL_NAVIGATION_STEP_CHECKER,
    },
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    props: false,
    meta: {
      layout: 'layout-default',
      shadowTop: true,
      historyNavigationStepChecker:
        HistoryNavigationStepCheckersNames.GENERAL_NAVIGATION_STEP_CHECKER,
    },
  },
  {
    path: '/location/:mode?',
    name: 'location',
    component: Location,
    props: true,
    meta: {
      layout: 'layout-default',
      isBackgroundImageZoomable: true,
      historyNavigationStepChecker:
        HistoryNavigationStepCheckersNames.GENERAL_NAVIGATION_STEP_CHECKER,
    },
  },
  {
    path: '/video',
    name: 'video',
    component: ProjectVideo,
    meta: {
      layout: 'layout-default',
      historyNavigationStepChecker:
        HistoryNavigationStepCheckersNames.GENERAL_NAVIGATION_STEP_CHECKER,
    },
  },
  {
    path: '/general-tour360-gallery/:resourceId',
    name: 'general-tour360-gallery',
    component: GeneralTours360Gallery,
    props: true,
    meta: {
      layout: 'layout-default',
      isBackgroundImageZoomable: true,
      shadowTop: true,
      historyNavigationStepChecker:
        HistoryNavigationStepCheckersNames.GENERAL_NAVIGATION_STEP_CHECKER,
    },
  },
  {
    path: '/gallery/:resourceId',
    name: 'gallery',
    component: Gallery,
    props: true,
    meta: {
      layout: 'layout-default',
      isBackgroundImageZoomable: true,
      shadowTop: true,
      historyNavigationStepChecker:
        HistoryNavigationStepCheckersNames.GENERAL_NAVIGATION_STEP_CHECKER,
    },
  },
  {
    path: '/availability/:resourceId',
    name: 'availability',
    component: AvailabilityView,
    props: true,
    meta: {
      layout: 'layout-default',
      svgManagerComponent: 'AvailabilityInteractiveSvgLayer',
      enableZoomDependingOnPreference: true,
      centerScrollOnRouteEnter: true,
      shadowTop: true,
      historyNavigationStepChecker:
        HistoryNavigationStepCheckersNames.GENERAL_NAVIGATION_STEP_CHECKER,
    },
  },
  {
    path: '/exterior/:projectElementId?/:descriptiveSlug?',
    name: 'exterior-tour360',
    component: ExteriorTour360,
    props: true,
    meta: {
      layout: 'layout-default',
      shadowTop: true,
      historyNavigationStepChecker:
        HistoryNavigationStepCheckersNames.GENERAL_NAVIGATION_STEP_CHECKER,
    },
  },
  {
    path: '/amenities/:collectionSlug/:resourceId',
    name: 'general-amenities-gallery',
    component: AmenitiesGallery,
    props: true,
    meta: {
      layout: 'layout-default',
      isBackgroundImageZoomable: true,
      shadowTop: true,
      historyNavigationStepChecker:
        HistoryNavigationStepCheckersNames.GENERAL_NAVIGATION_STEP_CHECKER,
    },
  },
  {
    path: '/quote/:unitId',
    name: 'standalone-quoter',
    component: StandaloneQuoter,
    props: true,
    meta: {
      layout: 'layout-empty',
      historyNavigationStepChecker:
        HistoryNavigationStepCheckersNames.GENERAL_NAVIGATION_STEP_CHECKER,
    },
  },
  {
    path: '/ui-kit/:componentName?/:mockRouteParam?',
    name: 'ui-kit-viewer',
    component: UiKit,
    props: true,
    meta: {
      layout: 'layout-empty',
      disabled: process.env.VUE_APP_UIKIT_VIEW_ENABLED !== 'true',
      historyNavigationStepChecker:
        HistoryNavigationStepCheckersNames.GENERAL_NAVIGATION_STEP_CHECKER,
    },
  },
  {
    path: '*',
    redirect: {
      name: 'home',
    },
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta.disabled) {
    next(homeLink)
    return
  }
  next()
})

export default router
