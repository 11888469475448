export const defaultZoomStep = 1

const zoomDiscreteStepsArray = [defaultZoomStep, 2, 3]

function sortAndValidateSteps() {
  const sortedSteps = zoomDiscreteStepsArray.sort((a, b) => a - b)

  if (!sortedSteps.includes(defaultZoomStep)) {
    throw new Error(
      'zoomDiscreteStepsValidation: defaultZoomStep is not included on sortedSteps array'
    )
  }

  if (sortedSteps.some((step) => typeof step !== 'number')) {
    throw new Error('zoomDiscreteStepsValidation: All steps must be numbers')
  }

  if (sortedSteps[0] !== defaultZoomStep) {
    throw new Error(
      'zoomDiscreteStepsValidation: the first step cannot be different from the defaultZoomStep'
    )
  }

  return sortedSteps
}

export const zoomDiscreteSteps = sortAndValidateSteps()

export const maxZoomValue = 3

export const minZoomValue = 0.5
