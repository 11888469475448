import BaseUnitIdentifierGenerator from './BaseUnitIdentifierGenerator'
import Level from '@/classes/ProjectStructure/Level'
import Unit from '@/classes/ProjectStructure/Unit/Unit'

class SingleTowerUnitIdentifierGenerator extends BaseUnitIdentifierGenerator {
  // eslint-disable-next-line no-unused-vars
  _getTranslationArguments({ unit }) {
    return {
      unitId: unit.label,
      name: unit.name,
      level: unit.parent().name,
    }
  }
  // eslint-disable-next-line no-unused-vars
  _validateGeneratorArguments({ unit }) {
    if (!(unit instanceof Unit)) {
      throw new Error(
        'SingleTowerUnitIdentifierGenerator._validateGeneratorArguments(): passed unit argument is not an instance of Unit class.'
      )
    }
    const level = unit.parent()
    if (!(level instanceof Level)) {
      throw new Error(
        'SingleTowerUnitIdentifierGenerator._validateGeneratorArguments(): Parent of unit is not an instance of Level class.'
      )
    }
  }
  get _localeGroupKey() {
    return 'unitIdentifiers'
  }
}
export default SingleTowerUnitIdentifierGenerator
