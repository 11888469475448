<template>
  <section class="container">
    <h1>AppShareMenuViewer</h1>
    <h2>Left alignment</h2>
    <div class="wrapper">
      <AppShareMenu share-url="https://www.example.com" />
    </div>

    <h2>Right alignment</h2>
    <div class="wrapper">
      <AppShareMenu
        share-url="https://www.example.com"
        :menu-alignment="alignments.ALIGN_RIGHT"
      />
    </div>
  </section>
</template>
<script>
import AppShareMenu from '@/components/UIKit/Standard/Molecules/AppShareMenu'
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'
import alignments from '@/constants/ui-kit/standard/alignments'

export default {
  name: 'ShareMenuViewer',
  components: {
    AppShareMenu,
  },
  data() {
    return {
      sizes: Object.values(sizes),
      styles: Object.values(styles),
      alignments,
    }
  },
}
</script>
<style lang="scss" scoped>
.container {
  margin: 80px 50px;
}
h1 {
  font-size: 1.5rem;
  margin: 2.5em 0 1.5em;
  border-bottom: 1px solid black;
}
h2 {
  font-size: 1rem;
  font-weight: bold;
}
.wrapper {
  display: flex;
  justify-content: center;
  margin: 50px auto;
}
</style>
