class TransitionVideoDownloadError extends Error {
  constructor(videoUrl, error) {
    super(error)
    const requestContent = error.request
      ? `Request: ${JSON.stringify(error.request)}`
      : 'The request could not be made'
    const responseContent = error.response
      ? `Response: ${JSON.stringify(error.response)}`
      : 'No response was received'
    const moreInfoContent = error.toJSON
      ? `More info: ${JSON.stringify(error.toJSON())}`
      : ''

    this.name = 'TransitionVideoDownloadError'
    this.message =
      `Error while downloading transition Video: ` +
      `${error.message}.\n\n` +
      `${requestContent}.\n` +
      `${responseContent}.\n\n` +
      `${moreInfoContent}`
  }
}
export default TransitionVideoDownloadError
