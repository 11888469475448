import legacyIconNames from '@/constants/ui/icons/names.mjs'
import iconNames from '@/constants/ui-kit/standard/icon-names'

export const featureIconMap = {
  METRAJE: {
    legacyIcon: legacyIconNames.FOOTAGE,
    icon: iconNames.FeatureArea,
    shortTextEmpty: false,
  },
  'METRAJE (PIES)': {
    legacyIcon: legacyIconNames.FOOTAGE,
    icon: iconNames.FeatureArea,
    shortTextEmpty: false,
  },
  'AREA INTERNA (METROS)': {
    legacyIcon: legacyIconNames.INTERNAL_AREA,
    icon: iconNames.FeatureAreaInternal,
    shortTextEmpty: false,
  },
  'AREA CONSTRUIDA (METROS)': {
    legacyIcon: legacyIconNames.EXTERNAL_AREA,
    icon: iconNames.FeatureAreaExternal,
    shortTextEmpty: false,
  },
  'AREA PRIVADA (METROS)': {
    legacyIcon: legacyIconNames.INTERNAL_AREA,
    icon: iconNames.FeatureAreaInternal,
    shortTextEmpty: false,
  },
  'AREA BALCONES (METROS)': {
    legacyIcon: legacyIconNames.BALCONY,
    icon: iconNames.FeatureBalcony,
    shortTextEmpty: false,
  },
  'AREA TERRAZAS (METROS)': {
    legacyIcon: legacyIconNames.TERRACE_AMENITIES,
    icon: iconNames.FeatureTerrace,
    shortTextEmpty: false,
  },
  'AREA EXTERNA (METROS)': {
    legacyIcon: legacyIconNames.EXTERNAL_AREA,
    icon: iconNames.FeatureAreaExternal,
    shortTextEmpty: false,
  },
  'AREA TOTAL (METROS)': {
    legacyIcon: legacyIconNames.TOTAL_AREA,
    icon: iconNames.FeatureAreaTotal,
    shortTextEmpty: false,
  },
  'AREA INTERNA (PIES)': {
    legacyIcon: legacyIconNames.INTERNAL_AREA,
    icon: iconNames.FeatureAreaInternal,
    shortTextEmpty: false,
  },
  'AREA EXTERNA (PIES)': {
    legacyIcon: legacyIconNames.EXTERNAL_AREA,
    icon: iconNames.FeatureAreaExternal,
    shortTextEmpty: false,
  },
  'AREA TOTAL (PIES)': {
    legacyIcon: legacyIconNames.TOTAL_AREA,
    icon: iconNames.FeatureAreaTotal,
    shortTextEmpty: false,
  },
  'AREA TOTAL CONSTRUIDA (METROS)': {
    legacyIcon: legacyIconNames.TOTAL_AREA,
    icon: iconNames.FeatureAreaTotal,
    shortTextEmpty: false,
  },
  'AREA TOTAL CONSTRUIDA (PIES)': {
    legacyIcon: legacyIconNames.TOTAL_AREA,
    icon: iconNames.FeatureAreaTotal,
    shortTextEmpty: false,
  },
  'ÁREA DEL LOTE (METROS)': {
    legacyIcon: legacyIconNames.TOTAL_AREA,
    icon: iconNames.FeatureAreaTotal,
    shortTextEmpty: false,
  },
  'ÁREA DEL LOTE (PIES)': {
    legacyIcon: legacyIconNames.TOTAL_AREA,
    icon: iconNames.FeatureAreaTotal,
    shortTextEmpty: false,
  },
  'AREA SEMI CUBIERTA': {
    legacyIcon: legacyIconNames.INTERNAL_AREA,
    icon: iconNames.FeatureAreaInternal,
    shortTextEmpty: false,
  },
  'AREA CUBIERTA': {
    legacyIcon: legacyIconNames.EXTERNAL_AREA,
    icon: iconNames.FeatureAreaExternal,
    shortTextEmpty: false,
  },
  RECAMARA: {
    legacyIcon: legacyIconNames.BEDROOM,
    icon: iconNames.FeatureBedroom,
    shortTextEmpty: false,
  },
  HABITACIÓN: {
    legacyIcon: legacyIconNames.BEDROOM,
    icon: iconNames.FeatureBedroom,
    shortTextEmpty: false,
  },
  DORMITORIO: {
    legacyIcon: legacyIconNames.BEDROOM,
    icon: iconNames.FeatureBedroom,
    shortTextEmpty: false,
  },
  BAÑO: {
    legacyIcon: legacyIconNames.BATHROOM,
    icon: iconNames.FeatureBathroom,
    shortTextEmpty: false,
  },
  LAVANDERÍA: {
    legacyIcon: legacyIconNames.LAUNDRY,
    icon: iconNames.FeatureLaundry,
    shortTextEmpty: true,
  },
  BALCÓN: {
    legacyIcon: legacyIconNames.BALCONY,
    icon: iconNames.FeatureBalcony,
    shortTextEmpty: true,
  },
  PARKING: {
    legacyIcon: legacyIconNames.PARKING,
    icon: iconNames.FeatureParking,
    shortTextEmpty: false,
  },
  'PARKING OPCIONAL': {
    legacyIcon: legacyIconNames.PARKING_OPTIONAL,
    icon: iconNames.FeatureParking,
    shortTextEmpty: false,
  },
  'CUARTO DE SERVICIO': {
    legacyIcon: legacyIconNames.SERVICE_ROOM,
    icon: iconNames.FeatureServiceRoom,
    shortTextEmpty: true,
  },
  COCINA: {
    legacyIcon: legacyIconNames.KITCHEN,
    icon: iconNames.FeatureKitchen,
    shortTextEmpty: false,
  },
  PISCINA: {
    legacyIcon: legacyIconNames.SWIMMING_POOL,
    icon: iconNames.FeatureSwimmingPool,
    shortTextEmpty: true,
  },
  SOLARIUM: {
    legacyIcon: legacyIconNames.SOLARIUM,
    icon: iconNames.FeatureFullSpa,
    shortTextEmpty: true,
  },
  BARBECUE: {
    legacyIcon: legacyIconNames.BARBECUE,
    icon: iconNames.FeatureBarbecue,
    shortTextEmpty: true,
  },
  'SALÓN DE FIESTAS': {
    legacyIcon: legacyIconNames.PARTY_ROOM,
    icon: iconNames.FeaturePartyRoom,
    shortTextEmpty: true,
  },
  'PARQUE DE NIÑOS': {
    legacyIcon: legacyIconNames.KIDS_PARK,
    icon: iconNames.FeatureKidsRoom,
    shortTextEmpty: true,
  },
  'SALÓN DE NIÑOS': {
    legacyIcon: legacyIconNames.KIDS_ROOM,
    icon: iconNames.FeatureKidsRoom,
    shortTextEmpty: true,
  },
  'PET ZONE': {
    legacyIcon: legacyIconNames.PET_ZONE,
    icon: iconNames.FeaturePetZone,
    shortTextEmpty: true,
  },
  'ÁREA VERDE': {
    legacyIcon: legacyIconNames.GREEN_AREA,
    icon: iconNames.FeatureGreenArea,
    shortTextEmpty: true,
  },
  JARDIN: {
    legacyIcon: legacyIconNames.GREEN_AREA,
    icon: iconNames.FeatureGreenArea,
    shortTextEmpty: false,
  },
  'DEN SOCIAL': {
    legacyIcon: legacyIconNames.SOCIAL_DEN,
    icon: iconNames.FeatureSocialDen,
    shortTextEmpty: true,
  },
  'SALA DE MASAJES': {
    legacyIcon: legacyIconNames.MASSAGE_ROOM,
    icon: iconNames.FeatureFullSpa,
    shortTextEmpty: true,
  },
  'LIBRARY TOOLS': {
    legacyIcon: legacyIconNames.LIBRARY_TOOLS,
    icon: iconNames.FeatureLoungeLibrary,
    shortTextEmpty: true,
  },
  COWORKING: {
    legacyIcon: legacyIconNames.COWORKING,
    icon: iconNames.FeaturePrivateOffice,
    shortTextEmpty: true,
  },
  'SALA DE REUNIÓN': {
    legacyIcon: legacyIconNames.CONFERENCE_ROOM,
    icon: iconNames.FeaturePrivateOffice,
    shortTextEmpty: true,
  },
  WIFI: {
    legacyIcon: legacyIconNames.WIFI,
    icon: iconNames.FeatureWiFi,
    shortTextEmpty: true,
  },
  'OFICINA PRIVADA': {
    legacyIcon: legacyIconNames.PRIVATE_OFFICE,
    icon: iconNames.FeaturePrivateOffice,
    shortTextEmpty: true,
  },
  BOX: {
    legacyIcon: legacyIconNames.BOX,
    icon: iconNames.FeaturePrivateOffice,
    shortTextEmpty: true,
  },
  'ÁREA DE RELAJACIÓN': {
    legacyIcon: legacyIconNames.RELAX_AREA,
    icon: iconNames.FeatureLoungeArea,
    shortTextEmpty: true,
  },
  'BAR ROOFTOP': {
    legacyIcon: legacyIconNames.ROOFTOP_BAR,
    icon: iconNames.FeatureBar,
    shortTextEmpty: true,
  },
  BODEGA: {
    legacyIcon: legacyIconNames.ROOFTOP_BAR,
    icon: iconNames.FeatureBodega,
    shortTextEmpty: false,
  },
  GIMNASIO: {
    legacyIcon: legacyIconNames.GYM,
    icon: iconNames.FeatureCrossfit,
    shortTextEmpty: true,
  },
  'SKY LOUNGE': {
    legacyIcon: legacyIconNames.SKY_LOUNGE,
    icon: iconNames.FeatureRestaurant,
    shortTextEmpty: true,
  },
  'YOGA & CROSSFIT': {
    legacyIcon: legacyIconNames.YOGA_AND_CROSSFIT,
    icon: iconNames.FeatureCrossfit,
    shortTextEmpty: true,
  },
  RESTAURANTE: {
    legacyIcon: legacyIconNames.RESTAURANT,
    icon: iconNames.FeatureRestaurant,
    shortTextEmpty: true,
  },
  SUSHI: {
    legacyIcon: legacyIconNames.SUSHI,
    icon: iconNames.FeatureSushi,
    shortTextEmpty: true,
  },
  SAUNA: {
    legacyIcon: legacyIconNames.SAUNA,
    icon: iconNames.FeatureSteamRooms,
    shortTextEmpty: true,
  },
  'ÁREA COMERCIAL': {
    legacyIcon: legacyIconNames.COMMERCIAL_AREA,
    icon: iconNames.FeatureMarket,
    shortTextEmpty: true,
  },
  'SALÓN DE JUEGOS': {
    legacyIcon: legacyIconNames.PLAYROOM,
    icon: iconNames.FeatureKidsRoom,
    shortTextEmpty: true,
  },
  'POKER ROOM': {
    legacyIcon: legacyIconNames.POKER_ROOM,
    icon: iconNames.FeatureCardsRoom,
    shortTextEmpty: true,
  },
  JACUZZI: {
    legacyIcon: legacyIconNames.JACUZZI,
    icon: iconNames.FeatureSteamRooms,
    shortTextEmpty: true,
  },
  'VALET PARKING': {
    legacyIcon: legacyIconNames.VALET_PARKING,
    icon: iconNames.FeatureValetParking,
    shortTextEmpty: true,
  },
  SEGURIDAD: {
    legacyIcon: legacyIconNames.SECURITY,
    icon: iconNames.FeatureSecurity,
    shortTextEmpty: true,
  },
  LOBBY: {
    legacyIcon: legacyIconNames.LOBBY,
    icon: iconNames.FeatureLobby,
    shortTextEmpty: true,
  },
  'PARKING DE VISITAS': {
    legacyIcon: legacyIconNames.VISITOR_PARKING,
    icon: iconNames.FeatureParking,
    shortTextEmpty: true,
  },
  BAR: {
    legacyIcon: legacyIconNames.BAR,
    icon: iconNames.FeatureBar,
    shortTextEmpty: true,
  },
  'OPEN KITCHEN': {
    legacyIcon: legacyIconNames.OPEN_KITCHEN,
    icon: iconNames.FeatureBarbecue,
    shortTextEmpty: true,
  },
  'SALÓN DE BELLEZA': {
    legacyIcon: legacyIconNames.BEAUTY_ROOM,
    icon: iconNames.FeatureFullSpa,
    shortTextEmpty: true,
  },
  'PET SHOP': {
    legacyIcon: legacyIconNames.PET_SHOP,
    icon: iconNames.FeaturePetShop,
    shortTextEmpty: true,
  },
  'FULL SPA': {
    legacyIcon: legacyIconNames.FULL_SPA,
    icon: iconNames.FeatureFullSpa,
    shortTextEmpty: true,
  },
  SERVICIOS: {
    legacyIcon: legacyIconNames.SERVICES,
    icon: iconNames.FeatureLobby,
    shortTextEmpty: true,
  },
  'NUOVO MARKET': {
    legacyIcon: legacyIconNames.NUOVO_MARKET,
    icon: iconNames.FeatureMarket,
    shortTextEmpty: true,
  },
  'INFINITY POOL': {
    legacyIcon: legacyIconNames.INFINITY_POOL,
    icon: iconNames.FeatureSwimmingPool,
    shortTextEmpty: true,
  },
  'POOLSIDE CABANAS': {
    legacyIcon: legacyIconNames.POOLSIDE_CABINS,
    icon: iconNames.FeatureSwimmingPool,
    shortTextEmpty: true,
  },
  'ARMANI CASA FLAG STORE': {
    legacyIcon: legacyIconNames.ARMANI_CASA_FLAG_STORE,
    icon: iconNames.FeatureArmaniCasa,
    shortTextEmpty: true,
  },
  'OPEN TERRACE': {
    legacyIcon: legacyIconNames.OPEN_TERRACE,
    icon: iconNames.FeatureTerrace,
    shortTextEmpty: true,
  },
  'LOUNGE AREA': {
    legacyIcon: legacyIconNames.LOUNGE_AREA,
    icon: iconNames.FeatureLoungeArea,
    shortTextEmpty: true,
  },
  'LAP POOL': {
    legacyIcon: legacyIconNames.LAP_POOL,
    icon: iconNames.FeatureSwimmingPool,
    shortTextEmpty: true,
  },
  TERRAZA: {
    legacyIcon: legacyIconNames.TERRACE_AMENITIES,
    icon: iconNames.FeatureTerrace,
    shortTextEmpty: true,
  },
  'PLAZA COMERCIAL': {
    legacyIcon: legacyIconNames.COMMERCIAL_AREA,
    icon: iconNames.FeatureMarket,
    shortTextEmpty: true,
  },
  MINI_GOLF: {
    legacyIcon: legacyIconNames.MINI_GOLF,
    icon: iconNames.FeatureMinigolf,
    shortTextEmpty: true,
  },
  CINEMA: {
    legacyIcon: legacyIconNames.CINEMA,
    icon: iconNames.FeatureCinema,
    shortTextEmpty: true,
  },
  CROSSFIT: {
    legacyIcon: legacyIconNames.CROSSFIT,
    icon: iconNames.FeatureCrossfit,
    shortTextEmpty: true,
  },
  'LOUNGE LIBRARY': {
    legacyIcon: legacyIconNames.LOUNGE_LIBRARY,
    icon: iconNames.FeatureLoungeArea,
    shortTextEmpty: true,
  },
  'CARDS ROOM': {
    legacyIcon: legacyIconNames.CARDS_ROOM,
    icon: iconNames.FeatureCardsRoom,
    shortTextEmpty: true,
  },
  'SKY GARDEN': {
    legacyIcon: legacyIconNames.SKY_GARDEN,
    icon: iconNames.FeatureGreenArea,
    shortTextEmpty: true,
  },
  'YOGA STUDIO': {
    legacyIcon: legacyIconNames.YOGA_STUDIO,
    icon: iconNames.FeatureYoga,
    shortTextEmpty: true,
  },
  'SKY GYM': {
    legacyIcon: legacyIconNames.SKY_GYM,
    icon: iconNames.FeatureCrossfit,
    shortTextEmpty: true,
  },
  'THOUGHT SHARING LAB': {
    legacyIcon: legacyIconNames.THOUGHT_SHARING_LAB,
    icon: iconNames.FeatureSocialDen,
    shortTextEmpty: true,
  },
  'FULL ATHLETIC FACILITY': {
    legacyIcon: legacyIconNames.FULL_ATHLETIC_FACILITY,
    icon: iconNames.FeatureCrossfit,
    shortTextEmpty: true,
  },
  'PANORAMIC SPA': {
    legacyIcon: legacyIconNames.PANORAMIC_SPA,
    icon: iconNames.FeatureFullSpa,
    shortTextEmpty: true,
  },
  'OPEN GARDEN': {
    legacyIcon: legacyIconNames.OPEN_GARDEN,
    icon: iconNames.FeatureGreenArea,
    shortTextEmpty: true,
  },
  'PETS PARK': {
    legacyIcon: legacyIconNames.PETS_PARK,
    icon: iconNames.FeaturePetZone,
    shortTextEmpty: true,
  },
  'KIDS PLAYGROUND': {
    legacyIcon: legacyIconNames.KIDS_PLAYGROUND,
    icon: iconNames.FeatureKidsRoom,
    shortTextEmpty: true,
  },
  'ENCLOSED CINEMA': {
    legacyIcon: legacyIconNames.ENCLOSED_CINEMA,
    icon: iconNames.FeatureCinema,
    shortTextEmpty: true,
  },
  'PANORAMIC INFINITY POOL': {
    legacyIcon: legacyIconNames.PANORAMIC_INFINITY_POOL,
    icon: iconNames.FeatureSwimmingPool,
    shortTextEmpty: true,
  },
  'POOL SIDE JUICE BAR': {
    legacyIcon: legacyIconNames.POOL_SIDE_JUICE_BAR,
    icon: iconNames.FeatureBar,
    shortTextEmpty: true,
  },
  'OBSERVATION DECK': {
    legacyIcon: legacyIconNames.OBSERVATION_DECK,
    icon: iconNames.FeatureObservationDeck,
    shortTextEmpty: true,
  },
  'SOCIAL OUTDOOR KITCHEN': {
    legacyIcon: legacyIconNames.SOCIAL_OUTDOOR_KITCHEN,
    icon: iconNames.FeatureBarbecue,
    shortTextEmpty: true,
  },
  'OUTDOOR THEATER': {
    legacyIcon: legacyIconNames.OUTDOOR_THEATER,
    icon: iconNames.FeatureOutdoorTheater,
    shortTextEmpty: true,
  },
  'PANORAMIC LOUNGE': {
    legacyIcon: legacyIconNames.PANORAMIC_LOUNGE,
    icon: iconNames.FeatureLoungeArea,
    shortTextEmpty: true,
  },
  '1/2 BASKETBALL COURT': {
    legacyIcon: legacyIconNames.BASKETBALL_COURT_1_2,
    icon: iconNames.FeatureBasketballCourt,
    shortTextEmpty: true,
  },
  YOGA: {
    legacyIcon: legacyIconNames.YOGA,
    icon: iconNames.FeatureYoga,
    shortTextEmpty: true,
  },
  'CUARTOS DE VAPOR': {
    legacyIcon: legacyIconNames.STEAM_ROOMS,
    icon: iconNames.FeatureSteamRooms,
    shortTextEmpty: true,
  },
  'CANCHA DE TENIS': {
    legacyIcon: legacyIconNames.TENNIS_COURT,
    icon: iconNames.FeatureTennisCourt,
    shortTextEmpty: true,
  },
  'CANCHA MULTIUSO': {
    legacyIcon: legacyIconNames.MULTIPURPOSE_COURT,
    icon: iconNames.FeatureMultipurposeCourt,
    shortTextEmpty: true,
  },
  SPA: {
    legacyIcon: legacyIconNames.SPA,
    icon: iconNames.FeatureFullSpa,
    shortTextEmpty: true,
  },
  'POWDER ROOM': {
    legacyIcon: legacyIconNames.POWDER_ROOM,
    icon: iconNames.FeatureFullSpa,
    shortTextEmpty: false,
  },
  'CUARTO DE ESTUDIO': {
    legacyIcon: legacyIconNames.STUDY_ROOM,
    icon: iconNames.FeatureLoungeLibrary,
    shortTextEmpty: true,
  },
  'SALA DE BANQUETES': {
    legacyIcon: legacyIconNames.BANQUET_DINING_SPACE,
    icon: iconNames.FeatureRestaurant,
    shortTextEmpty: true,
  },
  'CUARTO MULTIUSO': {
    legacyIcon: legacyIconNames.MULTI_FUNCTION_ROOM,
    icon: iconNames.FeatureLoungeArea,
    shortTextEmpty: true,
  },
  'SALA FAMILIAR': {
    legacyIcon: legacyIconNames.MULTI_FUNCTION_ROOM,
    icon: iconNames.FeatureLoungeArea,
    shortTextEmpty: true,
  },
  'SAUNA & BAÑO DE VAPOR': {
    legacyIcon: legacyIconNames.SAUNA_STEAMBATH,
    icon: iconNames.FeatureSteamRooms,
    shortTextEmpty: true,
  },
  'HABITACIÓN FAMILIAR': {
    legacyIcon: legacyIconNames.FAMILY_ROOM,
    icon: iconNames.FeatureBedroom,
    shortTextEmpty: true,
  },
  'CANCHA DE PADEL': {
    icon: iconNames.FeaturePadelCourt,
    shortTextEmpty: true,
  },
  'FLEX ROOM': {
    icon: iconNames.FeatureLoungeArea,
    shortTextEmpty: false,
  },
  'SMART LOCKERS': {
    icon: iconNames.FeaturePadlock,
    shortTextEmpty: true,
  },
  'CONFERENCE SEATING CAPACITY': {
    icon: iconNames.FeatureChair,
    shortTextEmpty: false,
  },
  'THEATER SEATING CAPACITY': {
    icon: iconNames.FeatureChair,
    shortTextEmpty: false,
  },
  'SCHOOLROOM SEATING CAPACITY': {
    icon: iconNames.FeatureChair,
    shortTextEmpty: false,
  },
  'U-SHAPE SEATING CAPACITY': {
    icon: iconNames.FeatureChair,
    shortTextEmpty: false,
  },
  'RECEPTION CAPACITY': {
    icon: iconNames.FeaturePerson,
    shortTextEmpty: false,
  },
  'BANQUET CAPACITY': {
    icon: iconNames.FeaturePerson,
    shortTextEmpty: false,
  },
  WORKSTATIONS: {
    icon: iconNames.FeatureBox,
    shortTextEmpty: false,
  },
  DESKS: {
    icon: iconNames.FeatureBox,
    shortTextEmpty: false,
  },
  'DRAWING TABLES': {
    icon: iconNames.FeatureStudyRoom,
    shortTextEmpty: false,
  },
  'MEETING ROOMS': {
    icon: iconNames.FeatureCoworking,
    shortTextEmpty: false,
  },
  'PRIVATE TERRACE': {
    icon: iconNames.FeatureTerrace,
    shortTextEmpty: true,
  },
  BOARDROOM: {
    icon: iconNames.FeatureSocialDen,
    shortTextEmpty: true,
  },
  'CITY VIEW': {
    icon: iconNames.FeatureBalcony,
    shortTextEmpty: true,
  },
  'MOUNTAIN VIEW': {
    icon: iconNames.FeatureObservationDeck,
    shortTextEmpty: true,
  },
  'OCEAN VIEW': {
    icon: iconNames.FeatureObservationDeck,
    shortTextEmpty: true,
  },
  'LAKE VIEW': {
    icon: iconNames.FeatureObservationDeck,
    shortTextEmpty: true,
  },
  'RIVER VIEW': {
    icon: iconNames.FeatureObservationDeck,
    shortTextEmpty: true,
  },
  'COUNTRY VIEW': {
    icon: iconNames.FeatureObservationDeck,
    shortTextEmpty: true,
  },
  'OUTDOOR VIEW': {
    icon: iconNames.FeatureObservationDeck,
    shortTextEmpty: true,
  },
  'BRIDE ROOM': {
    icon: iconNames.FeatureFullSpa,
    shortTextEmpty: true,
  },
  'DRESSING ROOM': {
    icon: iconNames.FeatureFullSpa,
    shortTextEmpty: true,
  },
  'GROOM ROOM': {
    icon: iconNames.FeatureFullSpa,
    shortTextEmpty: true,
  },
  'MAKEUP ROOM': {
    icon: iconNames.FeatureFullSpa,
    shortTextEmpty: true,
  },
  GRILL: {
    icon: iconNames.FeatureBarbecue,
    shortTextEmpty: true,
  },
  CHURRASQUERA: {
    icon: iconNames.FeatureBarbecue,
    shortTextEmpty: true,
  },
  'SPORT BAR': {
    icon: iconNames.FeatureBar,
    shortTextEmpty: true,
  },
  HOA: {
    icon: iconNames.FeatureHOA,
    shortTextEmpty: true,
  },
  'RECREATION AREA': {
    icon: iconNames.FeatureGreenArea,
    shortTextEmpty: true,
  },
  'FIRE PITS': {
    icon: iconNames.FeatureFirePits,
    shortTextEmpty: true,
  },
  'PET ZONE + GROOMING AREA': {
    icon: iconNames.FeaturePetZone,
    shortTextEmpty: true,
  },
  'KIDS CLUB': {
    icon: iconNames.FeatureKidsClub,
    shortTextEmpty: true,
  },
  POOL: {
    icon: iconNames.FeatureSwimmingPool,
    shortTextEmpty: true,
  },
  'GAME ZONE': {
    icon: iconNames.FeatureGameZone,
    shortTextEmpty: true,
  },
  GYM: {
    icon: iconNames.FeatureCrossfit,
    shortTextEmpty: true,
  },
  'BOWLING ALLEY ARCADE': {
    icon: iconNames.FeatureBowlingAlleyArcade,
    shortTextEmpty: true,
  },
  'OUTDOOR KITCHEN': {
    icon: iconNames.FeatureBarbecue,
    shortTextEmpty: true,
  },
  'MODERN BUSINESS LOUNGE': {
    icon: iconNames.FeatureLoungeArea,
    shortTextEmpty: true,
  },
  'OUTDOOR YOGA DECK': {
    icon: iconNames.FeatureYoga,
    shortTextEmpty: true,
  },
  'GYM FACILITIES': {
    icon: iconNames.FeatureCrossfit,
    shortTextEmpty: true,
  },
  'SOCIAL KITCHEN': {
    icon: iconNames.FeatureSocialKitchen,
    shortTextEmpty: true,
  },
  'CAR LOBBY': {
    icon: iconNames.FeatureCarLobby,
    shortTextEmpty: true,
  },
  'MURO ESCALAR': {
    icon: iconNames.FeatureMuroEscalar,
    shortTextEmpty: true,
  },
  BICICLETEROS: {
    icon: iconNames.FeatureBicicletero,
    shortTextEmpty: true,
  },
  'ZONA WELLNESS': {
    icon: iconNames.FeatureZonaWellness,
    shortTextEmpty: true,
  },
  'PET SPA': {
    icon: iconNames.FeaturePetSpa,
    shortTextEmpty: true,
  },
  TEPPANYAKI: {
    icon: iconNames.FeatureTeppanyaki,
    shortTextEmpty: true,
  },
  'DIMENSIONS (L*W*H) (FEET)': {
    icon: iconNames.FeatureAreaTotal,
    shortTextEmpty: false,
  },
  'DIMENSIONS (L*W*H) (METERS)': {
    icon: iconNames.FeatureAreaTotal,
    shortTextEmpty: false,
  },
  PERGOLA: {
    icon: iconNames.FeaturePergola,
    shortTextEmpty: false,
  },
  'WALKING CLOSET': {
    icon: iconNames.FeatureWalkingCloset,
    shortTextEmpty: true,
  },
}
