<template>
  <div v-if="isNavigationRequired" class="pagination-controls">
    <AppButtonIconRouterLink
      :to="prevItemLocation"
      :icon="buttonPrevIcon"
      :style="prevButtonStyle"
      :button-style="buttonStyle"
      class="pagination-controls__control pagination-controls__control--prev"
      :class="{
        'pagination-controls__control--moved': isPrevButtonMoved,
      }"
      @click.native="hideTextHintTooltip"
    />
    <TextHintTooltip
      ref="leftTextHintTooltip"
      show-after-mount
      :position="rightArrowTooltipPosition"
      :delay-to-show="delayToShowHint"
      :delay-to-hide="delayToHideHint"
      :hint-text="nextItemHint"
      :style="nextButtonStyle"
      class="pagination-controls__control pagination-controls__control--next"
      :class="{
        'pagination-controls__control--moved': isNextButtonMoved,
      }"
    >
      <AppButtonIconRouterLink
        :to="nextItemLocation"
        :icon="buttonNextIcon"
        :button-style="buttonStyle"
        @click.native="hideTextHintTooltip"
      />
    </TextHintTooltip>
  </div>
</template>

<script>
import AppButtonIconRouterLink from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonIconRouterLink'
import iconNames from '@/constants/ui-kit/standard/icon-names'
import styles from '@/constants/ui-kit/standard/styles'
import TextHintTooltip from '@/components/Hint/TextHintTooltip'
import { textHintTooltipPositions } from '@/constants/hint'

export default {
  name: 'ThePaginationControls',
  components: {
    AppButtonIconRouterLink,
    TextHintTooltip,
  },
  props: {
    pageRoutes: {
      type: Array,
      required: true,
    },
    prevButtonOffset: {
      type: Number,
      default: 0,
    },
    nextButtonOffset: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      buttonPrevIcon: iconNames.UIChevronLeft,
      buttonNextIcon: iconNames.UIChevronRight,
      buttonStyle: styles.DARK,
      rightArrowTooltipPosition: textHintTooltipPositions.LEFT_TOP,
      currentResource: 0,
      delayToShowHint: 3000,
      delayToHideHint: 7000,
    }
  },
  computed: {
    resolvedPageRoutes() {
      return this.pageRoutes
        .map((itemRoute) => {
          try {
            return this.$router.resolve(itemRoute)
          } catch (e) {
            this.$loggingService.logError(
              `ThePaginationControls.resolvedPageRoutes: Error trying to resolve route: ${JSON.stringify(
                itemRoute
              )}. It will be omitted from the pagination. ${e}`
            )
            return null
          }
        })
        .filter((resolvedRoute) => {
          if (resolvedRoute === null) {
            return false
          } else {
            const isRouteMatched = resolvedRoute.resolved.matched.length
            if (!isRouteMatched) {
              this.$loggingService.logWarning(
                `ThePaginationControls.resolvedPageRoutes: Route doesn't match any valid route: ${JSON.stringify(
                  resolvedRoute.normalizedTo
                )}. It was omitted from the pagination.`
              )
            }
            return isRouteMatched
          }
        })
    },
    isNavigationRequired() {
      return this.resolvedPageRoutes.length > 1
    },
    currentItemIndex() {
      return this.resolvedPageRoutes.findIndex(
        (pageRoute) => pageRoute.resolved.path === this.$route.path
      )
    },
    nextItemLocation() {
      if (this.isNavigationRequired) {
        let nextItemIndex = this.currentItemIndex + 1
        if (nextItemIndex >= this.resolvedPageRoutes.length) {
          nextItemIndex = 0
        }
        return this.resolvedPageRoutes[nextItemIndex].location
      }
      return null
    },
    prevItemLocation() {
      if (this.isNavigationRequired) {
        let prevItemIndex = this.currentItemIndex - 1
        if (prevItemIndex < 0) {
          prevItemIndex = this.resolvedPageRoutes.length - 1
        }
        return this.resolvedPageRoutes[prevItemIndex].location
      }
      return null
    },
    nextItemHint() {
      return this.nextItemLocation.params.hintText
    },
    isCurrentRouteRecognized() {
      return this.currentItemIndex !== -1
    },
    prevButtonStyle() {
      return `--offset: ${this.prevButtonOffset}px;`
    },
    nextButtonStyle() {
      return `--offset: -${this.nextButtonOffset}px;`
    },
    isPrevButtonMoved() {
      return this.prevButtonOffset !== 0
    },
    isNextButtonMoved() {
      return this.nextButtonOffset !== 0
    },
  },
  watch: {
    currentItemIndex() {
      this.onRouteUpdated()
    },
  },
  mounted() {
    this.checkIsCurrentRouteRecognized()
    this.onRouteUpdated()
  },
  updated() {
    this.checkIsCurrentRouteRecognized()
  },
  methods: {
    hideTextHintTooltip() {
      this.$refs.leftTextHintTooltip.hide()
    },
    checkIsCurrentRouteRecognized() {
      if (!this.isCurrentRouteRecognized) {
        let firstValidLocationCopy = this.resolvedPageRoutes[0]
          ? JSON.parse(JSON.stringify(this.resolvedPageRoutes[0].location))
          : undefined
        this.$emit('unrecognized-route', {
          alternativeRoute: firstValidLocationCopy,
        })
      }
    },
    onRouteUpdated() {
      if (this.isCurrentRouteRecognized) {
        this.$emit('route-updated', { routeIndex: this.currentItemIndex })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.pagination-controls {
  @at-root #{&}__control {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 600ms ease-in-out;

    @at-root #{&}--moved {
      --offset: 0;
      transition: transform 600ms cubic-bezier(0.15, 0, 0, 1);
      transform: translateY(-50%) translateX(var(--offset));
    }

    @at-root #{&}--next {
      right: 16px;
    }

    @at-root #{&}--prev {
      left: 16px;
    }
  }
}
</style>
