const DownloadManagerMutations = {
  storeSpriteClip(
    state,
    { correspondingImageUrl, spriteImageBlob, spriteOrder }
  ) {
    if (state.imageUrlToSpriteClipMap[correspondingImageUrl] === undefined) {
      const spriteClip = {
        spriteImageBlob,
        spriteOrder,
      }
      state.imageUrlToSpriteClipMap[correspondingImageUrl] = spriteClip
    } else {
      throw new Error(
        `DownloadManager/storeSpriteClip: The sprite clip data for the image URL '${correspondingImageUrl}' is already registered`
      )
    }
  },
  storeSpritesReady(state, value) {
    state.spritesReady = value
  },
  storeVideoTransitionDownloadManager(state, value) {
    state.videoTransitionDownloadManager = value
  },
}
export default DownloadManagerMutations
