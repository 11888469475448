import BaseMenuItem from '@/classes/Navigation/MenuItem/BaseMenuItem'

export default class Menu {
  constructor(name, icon) {
    this._name = name
    this._icon = icon
    this._children = []
  }
  addChild(child) {
    if (child instanceof Menu) {
      this.children.push(child)
    } else if (child instanceof BaseMenuItem) {
      if (child.order) {
        this._addChildWithOrder(child)
      } else {
        this.children.push(child)
      }
    } else {
      throw new Error(
        'menu.addChild: Child passed is not an instance of class Menu or BaseMenuItem'
      )
    }
  }
  match(route) {
    return this._children.some((child) => child.match(route))
  }
  _addChildWithOrder(child) {
    if (!(child instanceof BaseMenuItem)) {
      throw new Error(
        'Menu._addChildWithOrder() should be used only with BaseMenuItem instances'
      )
    }

    const correspondingMenuItemIndex = this.children.findIndex(
      (existingMenuItem) => existingMenuItem.order > child.order
    )
    if (correspondingMenuItemIndex < 0) {
      this.children.push(child)
    } else {
      this.children.splice(correspondingMenuItemIndex, 0, child)
    }
  }
  get name() {
    return this._name
  }
  get nameTranslationKey() {
    return this._name
  }
  get icon() {
    return this._icon
  }
  get children() {
    return this._children
  }
}
