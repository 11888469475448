<template>
  <TheFullViewportContainer class="project-details">
    <UnitVimeoVideoPlayer
      v-if="showUnitVimeoVideoPlayer"
      :vimeo-video-url="currentUnitVimeoVideoUrl"
      :left-offset="leftSidebarWidth"
      :right-offset="rightLevelsMenuWidth"
    />
    <ThePaginationControls
      v-show="!isTour360Gallery"
      :page-routes="collectionPages"
      :prev-button-offset="leftSidebarWidth"
      :next-button-offset="rightLevelsMenuWidth"
      @unrecognized-route="onUnrecognizedRoute"
    />
    <project-details-top-bar-controls
      :show-info-panel-button="showInfoPanelButton"
      :show-unit-levels-menu-button="showUnitLevelsMenuButton"
      :label-text="labelText"
      :gallery-collections-options="galleryCollectionsItems"
      :selected-gallery-slug="collectionSlug"
      :offset="rightLevelsMenuWidth"
      :menu-offset="leftSidebarWidth"
      @show-details="showDetails"
      @open-levels-menu="openLevelsMenu"
      @back-to-topview="backToTopview"
      @restricted-fullscreen-change="onFullscreenChange"
      @redirect-to-gallery-collection="onChangeGalleryCollection"
    />
    <TheInfoPanel
      ref="infoPanel"
      class="project-details__info-panel"
      :keep-open-in-small-screens-vertical="true"
      :hidden="isFullscreenUICleanModeEnabled"
      :disable-container-scroll="shouldDisablePanelContainerScroll"
      display-contact-bar
      @width-updated="updateLeftSidebarWidth"
      @open="unitInfoPanelOpen"
      @closed="unitInfoPanelClosed"
      @request-open-form="onRequestOpenForm"
    >
      <template #large>
        <UnitPanelLargeContentFactory :project-element="unitObject" />
      </template>
      <template #medium>
        <UnitPanelMediumContentFactory :project-element="unitObject" />
      </template>
      <template #small>
        <UnitPanelSmallContentFactory :project-element="unitObject" />
      </template>
    </TheInfoPanel>
    <modal-hint-container
      v-if="shouldDisplayModalHint"
      hint-key="HorizontalScrollHint"
    ></modal-hint-container>
    <AppFlapDropdown
      v-if="isSmallScreen"
      class="project-details__gallery-collections-flap-dropdown"
      :class="{
        'project-details__gallery-collections-flap-dropdown--open': isFlapDropdownOpen,
      }"
      :options="galleryCollectionsItems"
      :selected-value="collectionSlug"
      :list-position="galleryCollectionFlapDropdownPosition"
      @selectedOptionChange="onChangeGalleryCollection"
      @collapse="isFlapDropdownOpen = false"
      @open="isFlapDropdownOpen = true"
    />
    <AppLevelsMenu
      v-if="showUnitLevelsMenu"
      ref="levelsMenu"
      class="project-details__floor-menu"
      :level-menu-items="unitLevelsLinks"
      :selected-link="selectedUnitLevelLink"
      @width-updated="updateRightOffset"
      @visibility-change="unitLevelsMenuVisibilityChanged"
    />
    <transition name="modal" mode="out-in">
      <TheFullscreenVrArViewer
        v-if="showVrArModal"
        :vr-ar-url="currentVrArUrl"
        @go-to-main-collection="onGoToDefaultCollection"
      />
    </transition>
  </TheFullViewportContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import responsiveMixin from '@/mixins/responsiveMixin'
import { galleryCollectionsData } from '@/constants/galleries'
import verticalPositions from '@/constants/ui-kit/standard/vertical-positions'
import TheFullViewportContainer from '@/components/Gadgets/TheFullViewportContainer'
import ThePaginationControls from '@/components/Gadgets/ThePaginationControls'
import UnitVimeoVideoPlayer from '@/components/Gadgets/UnitVimeoVideoPlayer'
import TheInfoPanel from '@/components/Gadgets/TheInfoPanel'
import UnitPanelLargeContentFactory from '@/components/PanelContent/panels/UnitPanel/UnitPanelLargeContentFactory'
import CollectionSlugs from '@/classes/Gallery/CollectionSlugs'
import ModalHintContainer from '@/components/Hint/ModalHintContainer'
import LevelRouteObjectGenerator from '@/classes/RouteObjectGenerators/LevelRouteObjectGenerator'
import UnitRouteObjectGenerator from '@/classes/RouteObjectGenerators/UnitRouteObjectGenerator'
import AppLevelsMenu from '@/components/UIKit/Standard/Molecules/AppLevelsMenu'
import AppFlapDropdown from '@/components/UIKit/Standard/Molecules/AppFlapDropdown'
import SlugGenerator from '@/classes/SlugGenerator'
import HomeRouteObjectGenerator from '@/classes/RouteObjectGenerators/HomeRouteObjectGenerator'
import ProjectDetailsTopBarControls from '@/components/ViewTopBarControls/ProjectDetailsTopBarControls'
import UnitPanelMediumContentFactory from '@/components/PanelContent/panels/UnitPanel/UnitPanelMediumContentFactory'
import UnitPanelSmallContentFactory from '@/components/PanelContent/panels/UnitPanel/UnitPanelSmallContentFactory'
import TheFullscreenVrArViewer from '@/components/Gadgets/TheFullscreenVrArViewer'

export default {
  name: 'ProjectDetails',
  components: {
    TheFullViewportContainer,
    ThePaginationControls,
    ModalHintContainer,
    TheInfoPanel,
    UnitPanelLargeContentFactory,
    UnitPanelMediumContentFactory,
    UnitPanelSmallContentFactory,
    AppLevelsMenu,
    AppFlapDropdown,
    ProjectDetailsTopBarControls,
    UnitVimeoVideoPlayer,
    TheFullscreenVrArViewer,
  },
  mixins: [responsiveMixin],
  beforeRouteUpdate(to, _, next) {
    next(true)
    const {
      unitId,
      descriptiveSlug,
      collectionSlug,
      resourceId,
      unitLevelSlug,
    } = to.params
    this.checkDataAndRedirectIfInvalid(
      unitId,
      descriptiveSlug,
      collectionSlug,
      resourceId,
      unitLevelSlug
    )
  },
  layout: 'main-layout',
  props: {
    descriptiveSlug: {
      type: String,
      default: () => undefined,
    },
    unitId: {
      type: String,
      default: () => undefined,
    },
    collectionSlug: {
      type: String,
      default: () => undefined,
    },
    resourceId: {
      type: String,
      default: () => '1',
    },
    unitLevelSlug: {
      type: String,
      default: () => undefined,
    },
  },
  data() {
    return {
      isInfoPanelButtonShown: false,
      isUnitLevelsMenuButtonShown: false,
      leftSidebarWidth: 0,
      openInfoPanelAfterFullscreen: false,
      galleryCollectionsData,
      rightLevelsMenuWidth: 0,
      isFullscreenUICleanModeEnabled: false,
      isFlapDropdownOpen: false,
    }
  },
  computed: {
    ...mapGetters({
      findUnitById: 'Topview/findUnitById',
      findLevelByChildUnitId: 'Topview/findLevelByChildUnitId',
      findTowerByChildLevelId: 'Topview/findTowerByChildLevelId',
      isOfflineModeEnabled: 'Project/isOfflineModeEnabled',
      unitDetailsMenuData: 'Layout/unitDetailsMenuData',
    }),
    unitLevelsLinks() {
      const currentCollection = this.collectionSlug
      return this.unitObject.unitLevels.map((unitLevel) => {
        const collectionParams = unitLevel.gallery.includes(currentCollection)
          ? {
              collectionSlug: currentCollection,
              resourceId: '1',
            }
          : {}
        return {
          displayName: unitLevel.displayName,
          routeObject: UnitRouteObjectGenerator.make(this.unitObject, {
            unitLevelSlug: unitLevel.slug,
            ...collectionParams,
          }),
        }
      })
    },
    selectedUnitLevelLink() {
      return this.unitLevelsLinks.find(
        (link) =>
          link.routeObject.params.unitLevelSlug ===
          this.$route.params.unitLevelSlug
      )
    },
    labelText() {
      return this.$projectType.switcher({
        [this.$projectType.MULTITOWER_TYPE]: () => {
          return this.$t('floorOptionsBar.currentFloorAndTower', {
            tower: this.towerData.name,
            level: this.levelData.name,
          })
        },
        [this.$projectType.SINGLETOWER_TYPE]: () => {
          return this.$t('floorOptionsBar.currentFloor', {
            level: this.levelData.name,
          })
        },
        [this.$projectType.NEIGHBOURHOOD_TYPE]: () => {
          return this.$t('floorOptionsBar.currentUnit', {
            unit: this.unitObject.name,
          })
        },
      })
    },
    unitObject() {
      return this.findUnitById(this.unitId)
    },
    currentUnitLevelObject() {
      const currentUnitLevelSlug = this.$route.params.unitLevelSlug

      if (currentUnitLevelSlug) {
        return this.unitObject.getUnitLevelBySlug(currentUnitLevelSlug)
      }

      return undefined
    },
    currentGallery() {
      return this.currentUnitLevelObject
        ? this.currentUnitLevelObject.gallery
        : this.unitObject.gallery
    },
    currentCollection() {
      return CollectionSlugs.isValid(this.collectionSlug)
        ? this.currentGallery.collection(this.collectionSlug)
        : this.currentGallery.defaultCollection
    },
    isTour360Gallery() {
      return this.currentCollection.is(CollectionSlugs.TOUR360)
    },
    collectionPages() {
      const routerLinks = []
      for (let i = 0; i < this.currentCollection.size; i++) {
        const minimumResourceId = 1
        const resourceId = (minimumResourceId + i).toString()
        const hintText = this.currentCollection.resources[i]?.hintText
        const routerObject = UnitRouteObjectGenerator.make(this.unitObject, {
          useTransition: true,
          collectionSlug: this.currentCollection.slug,
          unitLevelSlug: this.currentUnitLevelObject?.slug,
          resourceId,
          hintText,
        })
        routerLinks.push(routerObject)
      }
      return routerLinks
    },
    levelData() {
      return this.findLevelByChildUnitId(this.unitObject.id)
    },
    towerData() {
      return this.findTowerByChildLevelId(this.levelData.id)
    },
    galleryCollectionsItems() {
      return this.currentGallery.availableCollections.map((collection) => {
        const { nameTranslationKey, iconName } = this.galleryCollectionsData[
          collection.slug
        ]

        return {
          icon: iconName,
          text: this.$t(nameTranslationKey),
          value: collection.slug,
          disabled: this.$route.params.collectionSlug === collection.slug,
        }
      })
    },
    galleryCollectionFlapDropdownPosition() {
      if (this.isSmallScreenHorizontal) {
        return verticalPositions.TOP_POSITION
      } else {
        return verticalPositions.BOTTOM_POSITION
      }
    },
    showFlapDropdown() {
      return (
        this.isSmallScreenHorizontal && this.galleryCollectionsItems.length > 1
      )
    },
    shouldDisablePanelContainerScroll() {
      return !this.isSmallScreenHorizontal
    },
    shouldDisplayModalHint() {
      return (
        this.isSmallScreenVertical &&
        this.collectionSlug !== CollectionSlugs.TOUR360
      )
    },
    showInfoPanelButton() {
      return this.isInfoPanelButtonShown && !this.isSmallScreenVertical
    },
    showUnitLevelsMenuButton() {
      return (
        this.isUnitLevelsMenuButtonShown &&
        this.$projectType.is([this.$projectType.NEIGHBOURHOOD_TYPE])
      )
    },
    showUnitLevelsMenu() {
      return (
        this.unitObject.unitLevels.length > 1 &&
        this.$projectType.is([this.$projectType.NEIGHBOURHOOD_TYPE])
      )
    },
    currentUnitVimeoVideoUrl() {
      if (this.currentCollection.slug !== CollectionSlugs.VIDEO) {
        return undefined
      }

      const resourceNumber = Number(this.resourceId)

      return this.currentCollection.resources[resourceNumber - 1].vimeoVideoUrl
    },
    currentVrArUrl() {
      if (this.currentCollection.slug !== CollectionSlugs.VRAR) {
        return undefined
      }

      return this.currentCollection.resources[0].vrArUrl
    },
    showUnitVimeoVideoPlayer() {
      return !!this.currentUnitVimeoVideoUrl
    },
    showVrArModal() {
      return !!this.currentVrArUrl
    },
  },
  created() {
    const {
      unitId,
      descriptiveSlug,
      collectionSlug,
      resourceId,
      unitLevelSlug,
    } = this

    this.checkDataAndRedirectIfInvalid(
      unitId,
      descriptiveSlug,
      collectionSlug,
      resourceId,
      unitLevelSlug
    )
  },
  mounted() {
    if (this.isSmallScreenVertical) {
      this.$store.dispatch('Layout/setTopLayerCurrentHint', 'RotateDeviceHint')
    }
    if (!this.currentCollection?.hasResourcesWithAnimation) {
      this.showDetails()
    }
    this.openLevelsMenu()
  },
  methods: {
    showDetails() {
      this.$refs.infoPanel.open()
    },
    backToTopview() {
      const levelRoute = LevelRouteObjectGenerator.make(this.levelData)
      this.$router.push(levelRoute)
    },
    updateLeftSidebarWidth(infoPanelWidth) {
      this.leftSidebarWidth = infoPanelWidth
    },
    unitInfoPanelOpen() {
      this.isInfoPanelButtonShown = false
    },
    unitInfoPanelClosed() {
      this.isInfoPanelButtonShown = true
    },
    isDataValid(
      unitId,
      descriptiveSlug,
      collectionSlug,
      resourceId,
      unitLevelSlug
    ) {
      const selectedUnit = this.findUnitById(unitId)
      const selectedUnitSlug = SlugGenerator.make(selectedUnit)
      if (selectedUnit === undefined || selectedUnitSlug !== descriptiveSlug) {
        return false
      }

      const defaultUnitGalleryCollection = selectedUnit.gallery.collection(
        collectionSlug
      )

      const selectedDetailGalleryCollection = unitLevelSlug
        ? selectedUnit
            .getUnitLevelBySlug(unitLevelSlug)
            .gallery.collection(collectionSlug)
        : defaultUnitGalleryCollection

      const resourceIdNumber = Number(resourceId) || 1

      return (
        selectedDetailGalleryCollection !== undefined &&
        resourceIdNumber <= selectedDetailGalleryCollection.size
      )
    },
    checkDataAndRedirectIfInvalid(
      unitId,
      descriptiveSlug,
      collectionSlug,
      resourceId,
      unitLevelSlug
    ) {
      if (
        !this.isDataValid(
          unitId,
          descriptiveSlug,
          collectionSlug,
          resourceId,
          unitLevelSlug
        )
      ) {
        this.$loggingService.logError(
          `Error in ProjectDetails: Invalid route params. Received (unitId: ${unitId}, descriptiveSlug: ${descriptiveSlug}, collectionSlug: ${collectionSlug}, resourceId: ${resourceId}, unitLevelSlug: ${unitLevelSlug})`
        )
        const homeRouteObject = HomeRouteObjectGenerator.make()
        this.$router.replace(homeRouteObject)
      }
    },
    onUnrecognizedRoute({ alternativeRoute }) {
      const homeRouteObject = HomeRouteObjectGenerator.make()
      const redirectionRoute = alternativeRoute || homeRouteObject
      redirectionRoute.params.useTransition = false
      this.$loggingService.logError(
        `GeneralAmenitiesGallery: The current route '${this.$route.path}' is not recognized in the ThePaginationControls as a valid option. ` +
          `Redirecting to: ${JSON.stringify(redirectionRoute)}.`
      )
      this.$router.replace(redirectionRoute)
    },
    onFullscreenChange({ isFullscreen }) {
      this.isFullscreenUICleanModeEnabled = isFullscreen

      if (isFullscreen) {
        this.closeLevelsMenu()
      } else {
        this.openLevelsMenu()
      }
    },
    onRequestOpenForm() {
      this.$store.dispatch('Topview/openUnitInfoRequestForm', {
        unit: this.unitObject,
        requestPrice: false,
      })
    },
    openLevelsMenu() {
      this.isUnitLevelsMenuButtonShown = false
      this.$refs.levelsMenu?.show()
    },
    closeLevelsMenu() {
      this.$refs.levelsMenu?.hide()
      if (this.isSmallScreenVertical) {
        this.$refs.infoPanel.open()
      }
    },
    unitLevelsMenuVisibilityChanged(isVisible) {
      if (isVisible) {
        this.isUnitLevelsMenuButtonShown = false
      } else {
        this.isUnitLevelsMenuButtonShown = true
      }
    },
    updateRightOffset(value) {
      this.rightLevelsMenuWidth = value
    },
    onGoToDefaultCollection() {
      const defaultCollectionSlug = this.currentGallery?.defaultCollection?.slug
      this.onChangeGalleryCollection(defaultCollectionSlug)
    },
    onChangeGalleryCollection(collectionSlug) {
      try {
        const collectionRouteObject = UnitRouteObjectGenerator.make(
          this.unitObject,
          {
            collectionSlug,
            resourceId: '1',
            unitLevelSlug: this.currentUnitLevelObject?.slug,
          }
        )

        this.$router.push(collectionRouteObject)
      } catch (error) {
        this.$loggingService.logError(error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.project-details {
  position: relative;
  z-index: 320;
  @at-root &__gallery-collections-flap-dropdown {
    position: fixed;
    top: 50%;
    left: 0;
    margin-top: 4.375rem;
    @at-root &--open {
      z-index: 3;
    }
  }

  @at-root &__info-panel {
    position: relative;
    z-index: 2;
  }

  @at-root &__floor-menu {
    pointer-events: all;
  }
}
</style>
