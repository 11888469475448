const BaseMissingMessageHandler = require('./BaseMissingMessageHandler')

module.exports = class ReturnKeyStringMissingMessageHandler extends BaseMissingMessageHandler {
  // eslint-disable-next-line no-unused-vars
  handleMissingMessage(locale, key, vm, values) {
    this.loggingService.logError(
      `Missing i18n message for locale ${locale} key ${key}. Returning translation key`
    )
    return `Missing message "${key}"`
  }
}
