class BaseMediaResource {
  constructor(galleryResourceData) {
    const { resourceUrl, labelText, hintText } = galleryResourceData
    if (
      this._isResourceUrlValid(resourceUrl) &&
      this._isLabelTextValid(labelText)
    ) {
      this._resourceUrl = resourceUrl
      this._labelText = labelText
      this._hintText = hintText
    } else {
      throw new Error(
        `BaseMediaResource: Invalid galleryResourceData: ${JSON.stringify(
          galleryResourceData
        )}`
      )
    }
  }
  _isResourceUrlValid(resourceUrl) {
    return typeof resourceUrl === 'string' && resourceUrl.trim() !== ''
  }
  _isLabelTextValid(labelText) {
    return typeof labelText === 'string'
  }
  _isHintTextValid(hintText) {
    return typeof hintText === 'string' || hintText === undefined
  }
  get resourceUrl() {
    return this._resourceUrl
  }
  get labelText() {
    return this._labelText
  }
  get hintText() {
    return this._hintText
  }
}
export default BaseMediaResource
