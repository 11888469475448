<template>
  <div class="feature-list">
    <h3 v-if="title" class="feature-list__title">{{ title }}</h3>
    <TouchManager
      class="feature-list__list"
      @touch-move="onTouchMove"
      @touch-start="onTouchStart"
    >
      <AppScrollbarContainer
        ref="scrollableArea"
        class="feature-list__scrollbar-container"
      >
        <ul>
          <li v-for="feature in features" :key="feature.id">
            <AppFeatureListItem :text="feature.longText" :icon="feature.icon" />
          </li>
        </ul>
      </AppScrollbarContainer>
    </TouchManager>
  </div>
</template>

<script>
import AppScrollbarContainer from '@/components/UIKit/Standard/Atoms/AppScrollbarContainer'
import AppFeatureListItem from '@/components/UIKit/Standard/Atoms/AppFeatureListItem'
import Feature from '@/classes/Feature/Feature'
import TouchManager from '@/components/Gadgets/TouchManager'

export default {
  name: 'AppFeatureList',
  components: {
    AppFeatureListItem,
    AppScrollbarContainer,
    TouchManager,
  },
  props: {
    title: {
      type: String,
      default: () => undefined,
    },
    features: {
      type: Array,
      required: true,
      validator: (value) =>
        value.every((feature) => feature instanceof Feature),
    },
  },
  methods: {
    onTouchStart() {
      this.shouldStopGesturePropagation =
        this.$refs.scrollableArea.$el.scrollTop > 0
    },
    onTouchMove(touch) {
      if (this.shouldStopGesturePropagation) {
        touch.event.stopPropagation()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.feature-list {
  display: flex;
  flex-direction: column;

  @at-root &__title {
    font-size: 0.875rem;
    margin-bottom: 0.75em;
    font-weight: var(--font-weight-medium);
  }

  @at-root &__list {
    flex: 1;
    position: relative;
    overflow: hidden;

    &::after,
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 0.8125rem;
      position: absolute;
      z-index: 2;
      top: 0;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
    }

    &::after {
      top: unset;
      bottom: 0;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  @at-root &__scrollbar-container {
    height: 100%;
    overflow: auto;
  }

  ul,
  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    border-bottom: 1px solid var(--gray-100);
    &:last-child {
      border-bottom: 0;
    }
  }
}
</style>
