import ProjectElementTypes from '@/constants/project-structure/project-element-types'
import { AvailabilityStatuses, OfferTypes } from '@/constants/units'
import CollectionSlugs from '@/classes/Gallery/CollectionSlugs'
import BasePrice from '@/classes/Price/BasePrice'
import SalePrice from '@/classes/Price/SalePrice'
import Feature from '@/classes/Feature/Feature'
import ImageResource from '@/classes/MediaResources/ImageResource'
import BaseProjectElement from '@/classes/ProjectStructure/BaseProjectElement'
import BaseProjectRootElement from '@/classes/ProjectStructure/BaseProjectRootElement'
import UnitLevel from '@/classes/ProjectStructure/UnitLevel/UnitLevel'
import CustomContentBlock from '@/classes/ProjectStructure/CustomContentBlock'

export default class Unit extends BaseProjectElement {
  constructor({
    slug,
    label,
    svgTargetElementId,
    name,
    model,
    id,
    use3dPlantAsUnitPreviewImg,
  }) {
    super({ id, svgTargetElementId })
    this._slug = slug
    this._label = label
    this._name = name
    this._model = model
    this._features = []
    this._price = undefined
    this._pricesForQuotes = {}
    this._brochureUrl = ''
    // FIXME: Availability should default to undefined
    this._availability = {}
    this._unitLevels = []
    this._offerType = OfferTypes.FOR_SALE
    this._use3dPlantAsUnitPreviewImg = use3dPlantAsUnitPreviewImg
    this._customContentBlock = undefined
  }

  addFeature(feature) {
    if (feature instanceof Feature) {
      this._features.push(feature)
    } else {
      throw new Error(
        'Unit.addFeature(): feature passed is not an instance of class Feature'
      )
    }
  }

  setPrice(price) {
    if (price instanceof BasePrice || price === null) {
      this._price = price
    } else {
      throw new Error(
        `Tried to set invalid price on Unit ${this.name}: ${price}`
      )
    }
  }

  addQuotePrice(price, paymentMethodId) {
    if (!(price instanceof SalePrice)) {
      throw new Error(
        `addQuotePrice(): Tried to set invalid quote price on Unit ${this.name}: ${price}`
      )
    }
    if (typeof paymentMethodId !== 'number' || isNaN(paymentMethodId)) {
      throw new Error(
        `addQuotePrice(): Invalid paymentMethodId ${paymentMethodId} for unit ${this.name}`
      )
    }
    if (this._pricesForQuotes[paymentMethodId]) {
      throw new Error(
        `addQuotePrice(): Price for payment method ${paymentMethodId} is already set to unit ${this.name}.`
      )
    }

    this._pricesForQuotes[paymentMethodId] = price
  }

  setAvailability(status, color) {
    this._availability.color = color
    // FIXME: consider validating this with the value of the constant and not with the key
    if (AvailabilityStatuses[status] !== undefined) {
      this._availability.status = status
    } else {
      this._availability.status = undefined
      throw new Error(
        `Unit.setAvailability: Availability Status "${status}" is not recognized`
      )
    }
  }

  setCustomContentBlock(rawCustomContentBlock) {
    this._customContentBlock = new CustomContentBlock(rawCustomContentBlock)
  }

  setOfferType(offerType) {
    const validOfferTypes = Object.values(OfferTypes)
    if (validOfferTypes.includes(offerType)) {
      this._offerType = offerType
    } else {
      throw new Error(
        `Unit.setOfferType: Offer type "${offerType}" is not recognized`
      )
    }
  }

  setBrochureUrl(brochureUrl) {
    if (typeof brochureUrl !== 'string' || brochureUrl.trim() === '') {
      throw new Error(
        `Unit.setBrochureUrl(): brochureUrl (${brochureUrl}) is not a valid string`
      )
    }
    this._brochureUrl = brochureUrl
  }

  setLongIdentifier(identifier) {
    if (typeof identifier !== 'string' || identifier.trim() === '') {
      throw new Error(
        `Unit.setLongIdentifier(): identifier (${identifier}) is not a valid string`
      )
    }
    this._longIdentifier = identifier
  }

  setShortIdentifier(identifier) {
    if (typeof identifier !== 'string' || identifier.trim() === '') {
      throw new Error(
        `Unit.setShortIdentifier(): identifier (${identifier}) is not a valid string`
      )
    }
    this._shortIdentifier = identifier
  }

  createAddUnitLevel({ offset, displayName, unitLevelSlug }) {
    if (this.getUnitLevelByOffset(offset)) {
      throw new Error(
        `Unit.createAddUnitLevel(): offset with value: ${offset} is already defined`
      )
    }

    if (this.getUnitLevelBySlug(unitLevelSlug)) {
      throw new Error(
        `Unit.createAddUnitLevel(): unitLevel with value: ${unitLevelSlug} is already defined`
      )
    }

    const unitLevel = new UnitLevel({
      offset,
      displayName,
      slug: unitLevelSlug,
      defaultCollectionSlug: this._defaultGallerySlug,
    })
    const correspondingUnitLevelIndex = this._unitLevels.findIndex(
      (existingUnitLevel) => existingUnitLevel.offset > offset
    )
    if (correspondingUnitLevelIndex < 0) {
      this._unitLevels.push(unitLevel)
    } else {
      this._unitLevels.splice(correspondingUnitLevelIndex, 0, unitLevel)
    }
    return unitLevel
  }

  getUnitLevelByOffset(offset) {
    return this._unitLevels.find((unitLevel) => unitLevel.offset === offset)
  }

  getUnitLevelBySlug(slug) {
    return this._unitLevels.find((unitLevel) => unitLevel.slug === slug)
  }

  getQuotePrice(paymentMethodId) {
    return this._pricesForQuotes[paymentMethodId]
  }

  getUniqueElementIdThroughoutProject() {
    let parentElement = this.parent()
    let uniqueElementId = this.slug
    while (!(parentElement instanceof BaseProjectRootElement)) {
      uniqueElementId = `${parentElement.slug}__${uniqueElementId}`
      parentElement = parentElement.parent()
    }
    return uniqueElementId
  }

  toString() {
    return JSON.stringify({
      slug: this.slug,
      label: this.label,
      name: this.name,
      model: this.model,
    })
  }
  get slug() {
    return this._slug
  }
  get label() {
    return this._label
  }
  get name() {
    return this._name
  }
  get model() {
    return this._model
  }
  get isAmenities() {
    return false
  }
  get features() {
    return this._features
  }
  get mainFeatures() {
    return this._features.filter((feature) => feature.showInSmallPopup === true)
  }
  get details() {
    return this._details
  }
  get gallery() {
    return this.getUnitLevelByOffset(0).gallery
  }
  get previewImageUrl() {
    let resource =
      this.gallery.collection(CollectionSlugs.GALLERY).getResource(0) ||
      this.gallery.collection(CollectionSlugs.VIEWS).getResource(0) ||
      this.gallery.collection(CollectionSlugs.PLANS3D).getResource(0)
    if (this._use3dPlantAsUnitPreviewImg) {
      resource = this.gallery.collection(CollectionSlugs.PLANS3D).getResource(0)
    }
    if (!resource || !(resource instanceof ImageResource)) {
      throw new Error(
        `Unit.previewImageUrl: Unit doesn't have a valid image resource. Unit: ${this.toString()}.`
      )
    }
    return resource.imgUrl
  }
  get price() {
    return this._price
  }
  get availabilityColor() {
    return this._availability.color
  }
  get availabilityStatus() {
    return this._availability.status
  }
  get available() {
    return this._availability.status === AvailabilityStatuses.AVAILABLE
  }
  get offerType() {
    return this._offerType
  }
  get brochureUrl() {
    return this._brochureUrl
  }
  get brochureFileNameLocaleKey() {
    throw new Error(
      'brochureFileNameLocaleKey() is not implemented on base class Unit'
    )
  }
  get type() {
    throw new Error('type() is not implemented on base class Unit')
  }
  get longIdentifier() {
    return this._longIdentifier
  }
  get shortIdentifier() {
    return this._shortIdentifier
  }
  get projectElementType() {
    return ProjectElementTypes.UNIT
  }
  get _validChildElementTypes() {
    return []
  }
  get _defaultGallerySlug() {
    return CollectionSlugs.PLANS3D
  }
  get unitLevels() {
    return this._unitLevels
  }
  get mainUnitLevelSlug() {
    return this.getUnitLevelByOffset(0).slug
  }
  get hasPricesForQuotes() {
    return Object.keys(this._pricesForQuotes).length > 0
  }
  get customContentBlock() {
    return this._customContentBlock
  }
}
