<template>
  <div class="full-viewport-container">
    <div class="full-viewport-container__elements">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheFullViewportContainer',
}
</script>

<style scoped lang="scss">
.full-viewport-container {
  pointer-events: none;
  position: relative;
  z-index: 320;
  width: 100vw;
  @include inner-height;
  @at-root #{&}__elements {
    pointer-events: all;
  }
}
</style>
