import sassConstants from '@/constants/sass'

export default {
  PRIMARY: sassConstants.primary,
  SECONDARY: sassConstants.secondary,
  DARK: sassConstants.dark,
  LIGHT: sassConstants.light,
  EXTRA_LIGHT: sassConstants.extraLight,
  PRIMARY_LINK: sassConstants.primaryLink,
  LINK: sassConstants.link,
}
