import BaseProjectElement from '@/classes/ProjectStructure/BaseProjectElement'

class IdProjectElementMap {
  constructor(supportedType) {
    this._idElementMap = {}
    this._listedValues = []
    this._supportedType = supportedType
  }
  add(element) {
    if (!(element instanceof BaseProjectElement)) {
      throw new Error(`IdProjectElementMap.add(): Invalid element: ${element}.`)
    } else if (element.projectElementType !== this._supportedType) {
      throw new Error(
        `IdProjectElementMap.add(): The supported element type is ${this._supportedType}. Received element of type ${element.projectElementType}.`
      )
    } else if (this._idElementMap[element.id]) {
      throw new Error(
        `IdProjectElementMap.add(): Element with id ${element.id} is already set.`
      )
    } else {
      this._idElementMap[element.id] = element
      this._listedValues.push(element)
    }
  }
  get(elementId) {
    return this._idElementMap[elementId]
  }
  get values() {
    return this._listedValues
  }
}

export default IdProjectElementMap
