<template>
  <AppSheetProjectElement
    :title="tower.name"
    :enter-to="towerRouteObject"
    :exterior-tour360-to="exteriorTour360RouteObject"
  />
</template>

<script>
import AppSheetProjectElement from '@/components/UIKit/Standard/Organisms/AppSheet/AppSheetProjectElement'
import Tower from '@/classes/ProjectStructure/Tower'
import TowerRouteObjectGenerator from '@/classes/RouteObjectGenerators/TowerRouteObjectGenerator'
import ExteriorTour360RouteObjectGenerator from '@/classes/RouteObjectGenerators/ExteriorTour360RouteObjectGenerator'

export default {
  name: 'TowerPopupContent',
  components: {
    AppSheetProjectElement,
  },
  props: {
    projectElement: {
      type: Tower,
      required: true,
    },
  },
  computed: {
    tower() {
      return this.projectElement
    },
    hasExteriorTour360() {
      return !!this.tower.exteriorTour360Url
    },
    towerRouteObject() {
      const params = {
        useTransition: true,
        hideUIControlsDuringTransition: true,
      }
      return TowerRouteObjectGenerator.make(this.tower, params)
    },
    exteriorTour360RouteObject() {
      return this.hasExteriorTour360
        ? ExteriorTour360RouteObjectGenerator.make(this.tower)
        : undefined
    },
  },
}
</script>
