import UnitGalleryAdapter from './UnitGalleryAdapter'

export default class UnitLevelAdapter {
  static adapt(unit, unitLevelJsonData, viewsGalleryJsonData, i18n) {
    const { offset, displayName, unitLevelSlug } = unitLevelJsonData
    const adaptedLevel = unit.createAddUnitLevel({
      offset,
      displayName,
      unitLevelSlug,
    })

    UnitGalleryAdapter.adapt(
      adaptedLevel,
      unitLevelJsonData,
      viewsGalleryJsonData,
      i18n
    )
  }
}
