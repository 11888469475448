import ICON_NAMES from '@/constants/ui/icons/names.mjs'
import RotateDeviceHint from '@/classes/Hint/RotateDeviceHint'
import HorizontalScrollHint from '@/classes/Hint/HorizontalScrollHint'
import CollectionSlugs from '@/classes/Gallery/CollectionSlugs'
import UnitRouteObjectGenerator from '@/classes/RouteObjectGenerators/UnitRouteObjectGenerator'

const unitDetailsMenuData = {
  items: [
    {
      id: 0,
      nameTranslationKey: 'Galería',
      nameId: CollectionSlugs.GALLERY,
      icon: ICON_NAMES.IMAGES_GALLERY,
      routeName: UnitRouteObjectGenerator.ROUTE_NAME,
      showDependingOnFeatureFlags: false,
      featureFlag: undefined,
    },
    {
      id: 1,
      nameTranslationKey: 'Vistas',
      nameId: CollectionSlugs.VIEWS,
      icon: ICON_NAMES.EYE_SQUARE,
      routeName: UnitRouteObjectGenerator.ROUTE_NAME,
      showDependingOnFeatureFlags: false,
      featureFlag: undefined,
    },
    {
      id: 2,
      nameTranslationKey: 'Planta 3D',
      nameId: CollectionSlugs.PLANS3D,
      icon: ICON_NAMES.MAP,
      routeName: UnitRouteObjectGenerator.ROUTE_NAME,
      showDependingOnFeatureFlags: false,
      featureFlag: undefined,
    },
    {
      id: 3,
      nameTranslationKey: 'Planos',
      nameId: CollectionSlugs.PLANS,
      icon: ICON_NAMES.MAP,
      routeName: UnitRouteObjectGenerator.ROUTE_NAME,
      showDependingOnFeatureFlags: false,
      featureFlag: undefined,
    },
    {
      id: 4,
      nameTranslationKey: 'Recorrido',
      nameId: CollectionSlugs.TOUR360,
      icon: ICON_NAMES.TOUR_360,
      routeName: UnitRouteObjectGenerator.ROUTE_NAME,
      showDependingOnFeatureFlags: false,
      featureFlag: undefined,
    },
  ],
}

export default () => ({
  menuData: undefined,
  menuWidth: 0,
  unitDetailsMenuData,
  topLayerCurrentHint: null,
  hints: {
    RotateDeviceHint: new RotateDeviceHint(),
    HorizontalScrollHint: new HorizontalScrollHint(),
  },
})
