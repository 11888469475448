<template>
  <AppMenuWrapper class="menu-main">
    <AppMenuRow v-if="logoImageUrl">
      <AppMenuLogo :logo-image-url="logoImageUrl" />
    </AppMenuRow>
    <AppMenuRow grow>
      <AppMenuItemsList
        :items="items"
        :limited-connection="limitedConnection"
        @request-menu="requestMenu"
      />
    </AppMenuRow>
    <AppMenuFooter
      :facebook-url="facebookUrl"
      :instagram-url="instagramUrl"
      :whatsapp-url="whatsappUrl"
      :developer-website-link="developerWebsiteLink"
      :developer-brochure-url="developerBrochureUrl"
      :with-copyright="withCopyright"
      :copyright-with-link="copyrightWithLink"
    />
  </AppMenuWrapper>
</template>

<script>
import AppMenuWrapper from '@/components/UIKit/Standard/Atoms/AppMenuWrapper'
import AppMenuRow from '@/components/UIKit/Standard/Atoms/AppMenuRow'
import AppMenuItemsList from '@/components/UIKit/Standard/Molecules/AppMenuItemsList'
import AppMenuLogo from '@/components/UIKit/Standard/Molecules/AppMenuLogo'
import AppMenuFooter from '@/components/UIKit/Standard/Molecules/AppMenuFooter'
import BaseMenuItem from '@/classes/Navigation/MenuItem/BaseMenuItem'
import Menu from '@/classes/Navigation/Menu'
import iconNames from '@/constants/ui-kit/standard/icon-names'
import styles from '@/constants/ui-kit/standard/styles'
import sizes from '@/constants/ui-kit/standard/sizes'

export default {
  name: 'AppMenuMain',
  components: {
    AppMenuWrapper,
    AppMenuRow,
    AppMenuItemsList,
    AppMenuLogo,
    AppMenuFooter,
  },
  props: {
    items: {
      type: Array,
      required: true,
      validator: (items) => {
        return items.every(
          (item) => item instanceof BaseMenuItem || item instanceof Menu
        )
      },
    },
    logoImageUrl: {
      type: String,
      default: () => undefined,
    },
    facebookUrl: {
      type: String,
      default: () => undefined,
    },
    instagramUrl: {
      type: String,
      default: () => undefined,
    },
    whatsappUrl: {
      type: String,
      default: () => undefined,
    },
    developerWebsiteLink: {
      type: Object,
      default: () => undefined,
      validator: (value) => {
        return !!value.url && !!value.displayText
      },
    },
    developerBrochureUrl: {
      type: String,
      default: undefined,
    },
    withCopyright: {
      type: Boolean,
      default: false,
    },
    copyrightWithLink: {
      type: Boolean,
      default: false,
    },
    limitedConnection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      closeButtonStyle: styles.LINK,
      closeButtonSize: sizes.XS,
      closeButtonIcon: iconNames.UIClose,
    }
  },
  methods: {
    requestMenu(item) {
      this.$emit('request-menu', item)
    },
  },
}
</script>
