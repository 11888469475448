<template>
  <AppSheetContentAmenities
    :title="unitName"
    :features="unitFeaures"
    :enter-to="unitAccessRouterLinks.default"
    :tour360-to="unitAccessRouterLinks.tour360"
  />
</template>

<script>
import AppSheetContentAmenities from '@/components/UIKit/Standard/Organisms/AppSheet/AppSheetAmenities'
import Unit from '@/classes/ProjectStructure/Unit/Unit'
import UnitAccessRouterLinks from '@/classes/UnitAccessRouterLinks'

export default {
  name: 'AmenitiesUnitPopupContent',
  components: {
    AppSheetContentAmenities,
  },
  props: {
    unit: {
      type: Unit,
      required: true,
    },
  },
  computed: {
    unitName() {
      return this.unit.name
    },
    unitFeaures() {
      return this.unit.features
    },
    unitAccessRouterLinks() {
      try {
        return new UnitAccessRouterLinks(this.unit, this.$loggingService)
      } catch (error) {
        this.$loggingService.logError(
          `UnitEnterButtons.unitAccessRouterLinks: error creating UnitAccessRouterLinks. ${error.toString()}`
        )
        return null
      }
    },
  },
}
</script>
