<template>
  <div class="minimap">
    <div class="minimap__content" :class="contentClass">
      <AppImage
        v-if="backgroundImageUrl"
        class="minimap__content__image"
        :src="backgroundImageUrl"
      />
    </div>
    <div class="minimap__lens" :class="lensClass" :style="lensStyle"></div>
  </div>
</template>

<script>
import AppImage from '@/components/UIKit/Standard/Atoms/AppImage'

export default {
  name: 'AppMinimap',
  components: {
    AppImage,
  },
  props: {
    lensWidth: {
      type: Number,
      required: true,
      validator: (value) => value > 0,
    },
    lensHeight: {
      type: Number,
      required: true,
      validator: (value) => value > 0,
    },
    contentZoom: {
      type: Number,
      required: true,
      validator: (value) => value > 0,
    },
    contentPositionX: {
      type: Number,
      default: 0,
    },
    contentPositionY: {
      type: Number,
      default: 0,
    },
    backgroundImageUrl: {
      type: String,
      default: () => undefined,
    },
  },
  data() {
    return {}
  },
  computed: {
    lensScale() {
      return 1 / this.contentZoom
    },
    lensAspectRatio() {
      return this.lensWidth / this.lensHeight
    },
    lensBorderRadius() {
      return this.lensScale > 1 ? 12 : 12 * this.lensScale
    },
    lensStyle() {
      const cssVarSizeValue = this.lensScale * 100
      const cssVarOffsetXValue =
        (-this.contentPositionX / this.lensWidth) * 100 * this.contentZoom
      const cssVarOffsetYValue =
        (-this.contentPositionY / this.lensHeight) * 100 * this.contentZoom

      return {
        '--size': `${cssVarSizeValue}%`,
        '--offset-x': `${cssVarOffsetXValue}%`,
        '--offset-y': `${cssVarOffsetYValue}%`,
        'aspect-ratio': this.lensAspectRatio,
        'border-radius': `${this.lensBorderRadius}px`,
      }
    },
    lensClass() {
      return this.lensAspectRatio > 1
        ? `minimap__lens--horizontal`
        : `minimap__lens--vertical`
    },
    contentClass() {
      return {
        'minimap__content--with-background': !!this.backgroundImageUrl,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@mixin pseudo-layer {
  &::after {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    @content;
  }
}

.minimap {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);

  @at-root &__content {
    aspect-ratio: 16 / 9;
    height: 90px;

    @include pseudo-layer {
      // Content veil
      background: #fff;
      opacity: 0.7;
    }
  }

  @at-root &__lens {
    --size: 100%;
    --offset-x: 0%;
    --offset-y: 0%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(
      calc(-50% + var(--offset-x)),
      calc(-50% + var(--offset-y))
    );

    overflow: hidden;
    border: 1.5px solid var(--gray-100);
    border-radius: 12px;

    @at-root &--horizontal {
      width: var(--size);
    }

    @at-root &--vertical {
      height: var(--size);
    }

    @include pseudo-layer {
      // Lens background
      background: var(--primary-color);
      opacity: 0.5;
    }
  }

  @include pseudo-layer {
    // Minimap border
    border-radius: 12px;
    border: 1.5px solid var(--gray-100);
  }
}
</style>
