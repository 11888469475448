import PriceFactory from '@/classes/Price/PriceFactory'
import ProjectRootElementFactory from '@/classes/ProjectStructure/ProjectRootElementFactory'
import ClusterAdapter from '@/classes/ProjectElementAdapters/ClusterAdapter'
import modalSupportedComponents from '@/constants/ui-kit/standard/modal/modal-supported-components'
import modalUnitRequestTypes from '@/constants/ui-kit/standard/modal/modal-unit-request-types'
import { OfferTypes } from '@/constants/units'

export default {
  async adapterData({ commit }, { projectData, i18n }) {
    const projectStructureData = projectData.projectStructure
    const projectRootElement = new ProjectRootElementFactory().makeProjectRootElement(
      projectStructureData
    )

    ClusterAdapter.adapt(
      projectData.projectStructure.cluster,
      projectRootElement,
      i18n
    )
    commit('storeProject', projectRootElement)
  },
  adapterAvailabilityData({ getters }, data) {
    const unitsAvailabilityAndPriceData = data.unitUpdates

    unitsAvailabilityAndPriceData.forEach((availabilityAndPriceData) => {
      const unit = getters.findUnitById(availabilityAndPriceData.unitId)
      if (unit) {
        if (unit.isAmenities) {
          this.$loggingService.logError(
            `Topview/adapterAvailabilityData: Attempted to set availability on Amenities! ${JSON.stringify(
              availabilityAndPriceData
            )}.`
          )
        } else {
          const customContentBlock = availabilityAndPriceData.customContentBlock

          if (customContentBlock) {
            unit.setCustomContentBlock(customContentBlock)
          }

          const price = availabilityAndPriceData.price
            ? new PriceFactory().makePrice(
                availabilityAndPriceData.price,
                availabilityAndPriceData.offerType
              )
            : null
          unit.setPrice(price)
          unit.setAvailability(
            availabilityAndPriceData.availabilityStatus,
            availabilityAndPriceData.availabilityColor
          )
          unit.setOfferType(availabilityAndPriceData.offerType)

          availabilityAndPriceData.quoterPriceList?.forEach(
            (quoterPriceData) => {
              quoterPriceData.price.amount = parseFloat(
                quoterPriceData.price.amount
              )
              const quoterPrice = new PriceFactory().makePrice(
                quoterPriceData.price,
                OfferTypes.FOR_SALE
              )
              unit.addQuotePrice(quoterPrice, quoterPriceData.quoterOptionId)
            }
          )
        }
      } else {
        this.$loggingService.logError(
          `Topview/adapterAvailabilityData: Unit ${availabilityAndPriceData.unitId} does not exists.`
        )
      }
    })
  },
  setSelectedSvgElement({ commit }, projectElement) {
    commit('updateSelectedSvgElement', projectElement)
  },
  setUnitInfoRequestFormDetails({ commit }, unitDetails) {
    commit('updateUnitInfoRequestFormDetails', unitDetails)
  },
  async openUnitInfoRequestForm({ dispatch }, payload) {
    let titleTranslationKey = 'Solicita información'
    if (payload.requestPrice) {
      titleTranslationKey = 'Consulta precio'
    }
    const { unit, requestPrice } = payload

    await dispatch('setUnitInfoRequestFormDetails', {
      titleTranslationKey,
      unit,
      requestPrice: payload.requestPrice,
    })

    dispatch(
      'ModalLauncher/launchModal',
      {
        component: modalSupportedComponents.AppUnitInfoFormModal,
        closeCallback: function() {
          dispatch('Topview/setUnitInfoRequestFormDetails', undefined, {
            root: true,
          })
        },
        properties: {
          unit,
          requestType: requestPrice
            ? modalUnitRequestTypes.CHECK_PRICE
            : modalUnitRequestTypes.REQUEST_UNIT_INFORMATION,
        },
      },
      { root: true }
    )
  },
  async openLocationInfoRequestForm({ dispatch }) {
    const titleTranslationKey = 'Solicita información'
    await dispatch('setUnitInfoRequestFormDetails', {
      titleTranslationKey,
      unit: undefined,
      requestPrice: false,
    })

    dispatch(
      'ModalLauncher/launchModal',
      {
        component: modalSupportedComponents.AppLocationInfoFormModal,
        closeCallback: function() {
          dispatch('Topview/setUnitInfoRequestFormDetails', undefined, {
            root: true,
          })
        },
      },
      { root: true }
    )
  },
}
