<template>
  <div
    v-if="isZoomEnabledByPreference"
    class="zoom-controls"
    :class="{ 'zoom-controls--moved': isMoved }"
    :style="offsetValue"
  >
    <transition name="zoom-controls__minimap-fade-transition">
      <AppMinimap
        v-if="shouldDisplayMinimap"
        class="zoom-controls__minimap"
        :lens-width="lensWidth"
        :lens-height="lensHeight"
        :content-zoom="contentZoom"
        :content-position-x="contentPositionX"
        :content-position-y="contentPositionY"
        :background-image-url="backgroundImageUrl"
      />
    </transition>
    <AppZoomControlsButtons
      class="zoom-controls__buttons"
      :is-zoom-out-enabled="!isFirstZoomStep"
      :is-zoom-in-enabled="!isLastZoomStep"
      @zoom-in="zoomInStep"
      @zoom-out="zoomOutStep"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AppMinimap from '@/components/UIKit/Standard/Molecules/AppMinimap'
import AppZoomControlsButtons from '@/components/UIKit/Standard/Molecules/AppZoomControlsButtons.vue'
import ImageResource from '@/classes/MediaResources/ImageResource'
import { defaultZoomStep } from '@/constants/zoom-tracker'

export default {
  name: 'TheZoomControls',
  components: {
    AppMinimap,
    AppZoomControlsButtons,
  },
  props: {
    withBackgroundImage: {
      type: Boolean,
      default: false,
    },
    leftOffset: {
      type: Number,
      default: 0,
    },
    rightOffset: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      lensWidth: null,
      lensHeight: null,
      contentHeight: null,
    }
  },
  computed: {
    ...mapGetters({
      imageZoom: 'ZoomTracker/imageZoom',
      imagePosition: 'ZoomTracker/imagePosition',
      getBackground: 'BackgroundTree/getBackground',
      isLastZoomStep: 'ZoomTracker/isLastZoomStep',
      isFirstZoomStep: 'ZoomTracker/isFirstZoomStep',
      enableInteractiveBackgroundZoom:
        'Preferences/enableInteractiveBackgroundZoom',
    }),
    isMoved() {
      return this.leftOffset !== 0 || this.rightOffset !== 0
    },
    offsetValue() {
      return `--right-offset: ${this.rightOffset}px; --left-offset: ${this.leftOffset}px;`
    },
    isZoomEnabledByPreference() {
      return (
        this.$route.meta.enableZoomDependingOnPreference &&
        this.enableInteractiveBackgroundZoom
      )
    },
    shouldDisplayMinimap() {
      const isLensSizeDefined = this.lensWidth && this.lensHeight
      return isLensSizeDefined && this.imageZoom > defaultZoomStep
    },
    contentZoom() {
      return this.imageZoom
    },
    contentPositionX() {
      const contentOverflowScaling = this.lensHeight / this.contentHeight
      return this.imagePosition.x * contentOverflowScaling
    },
    contentPositionY() {
      const contentOverflowHeight = this.contentHeight - this.lensHeight
      const contentOverflowScaling = this.lensHeight / this.contentHeight
      return (
        this.imagePosition.y * contentOverflowScaling +
        contentOverflowHeight / 2
      )
    },
    backgroundImageUrl() {
      if (!this.withBackgroundImage) {
        return undefined
      }
      const backgroundResource = this.getBackground(this.$route.path)
      if (backgroundResource instanceof ImageResource) {
        return backgroundResource.resourceUrl
      }
      return undefined
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateViewportDependantValues)
    this.updateViewportDependantValues()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateViewportDependantValues)
  },
  methods: {
    ...mapActions({
      zoomInStep: 'ZoomTracker/zoomInStep',
      zoomOutStep: 'ZoomTracker/zoomOutStep',
    }),
    updateViewportDependantValues() {
      this.lensWidth = window.innerWidth
      this.lensHeight = window.innerHeight
      this.contentHeight = document.body.offsetHeight
    },
  },
}
</script>

<style lang="scss" scoped>
.zoom-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;

  position: fixed;
  bottom: 22px;
  left: 16px;
  right: 16px;
  transition: margin 600ms cubic-bezier(0.15, 0, 0, 1);

  @at-root #{&}--moved {
    --right-offset: 0;
    --left-offset: 0;
    margin-right: var(--right-offset);
    margin-left: var(--left-offset);
  }

  @at-root &__buttons {
    margin-left: auto;
  }

  pointer-events: none;

  @at-root &__minimap-fade-transition {
    @at-root &-enter-active,
      &-leave-active {
      transition: opacity 250ms ease-in-out;
    }
    @at-root &-enter,
      &-leave-to {
      opacity: 0;
    }
  }
}
</style>
