<template>
  <dl class="quoter-user-info">
    <dt>{{ $t('Información del cliente') }}</dt>
    <dd>{{ userInfo.name }}</dd>
    <dd>{{ userInfo.phoneNumber }}</dd>
    <dd>{{ userInfo.email }}</dd>
    <template v-if="userInfo.sellerEmail">
      <dt>{{ $t('Información del vendedor') }}</dt>
      <dd>{{ userInfo.sellerEmail }}</dd>
    </template>
  </dl>
</template>

<script>
export default {
  name: 'AppQuoterUserInfo',
  props: {
    userInfo: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.quoter-user-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.3125rem;
  margin: 1rem 0;

  dt {
    font-size: 0.875rem;
    font-weight: var(--font-weight-medium);
    color: var(--gray-700);
  }
  dd {
    font-size: 0.875rem;
    margin: 0;
    color: var(--gray-500);
  }
  dd + dt {
    margin-top: 0.75rem;
  }
}
</style>
