import TopBarControl from '@/classes/TopBarControls/TopBarControl'
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'
import TopBarDropdown from '@/components/Gadgets/TheTopBar/controls/TopBarDropdown'
export default class TopBarDropdownGenerator {
  static create({
    selectedValue,
    options,
    show,
    projectTypesSupported,
    eventHandler,
  }) {
    return new TopBarControl({
      component: TopBarDropdown,
      show,
      projectTypesSupported,
      eventName: 'selectedOptionChange',
      eventHandler,
      props: {
        selectedValue,
        options,
        size: sizes.SM,
        dropdownStyle: styles.LIGHT,
      },
    })
  }
}
