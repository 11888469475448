import TopBarControl from '@/classes/TopBarControls/TopBarControl'
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'
import TopBarButtonText from '@/components/Gadgets/TheTopBar/controls/TopBarButtonText'

export default class TopBarButtonGenerator {
  static create({
    buttonText,
    labelText,
    buttonStyle,
    show,
    projectTypesSupported,
    eventHandler,
  }) {
    return new TopBarControl({
      component: TopBarButtonText,
      show,
      projectTypesSupported,
      eventName: 'button-click',
      eventHandler,
      props: {
        buttonText,
        labelText,
        size: sizes.SM,
        buttonStyle: buttonStyle || styles.LIGHT,
      },
    })
  }
}
