<template>
  <section class="container">
    <h1>Feature Icons</h1>
    <table>
      <tr>
        <th>Icon name</th>
        <th v-for="size in sizes" :key="size">{{ size }}</th>
        <th>Feature Id</th>
      </tr>
      <tr v-for="feature in features" :key="feature.featureId">
        <th>{{ feature.icon }}</th>
        <td v-for="size in sizes" :key="feature.featureId + size">
          <AppIcon :icon="feature.icon" :size="size" />
        </td>
        <td>{{ feature.featureId }}</td>
      </tr>
    </table>

    <h1>Other Icons</h1>
    <table>
      <tr>
        <th>Icon name</th>
        <th v-for="size in sizes" :key="size">{{ size }}</th>
      </tr>
      <tr v-for="iconName in otherIcons" :key="iconName">
        <th>{{ iconName }}</th>
        <td v-for="size in sizes" :key="iconName + size">
          <AppIcon :icon="iconName" :size="size" />
        </td>
      </tr>
    </table>
  </section>
</template>

<script>
import AppIcon from '@/components/UIKit/Standard/Atoms/AppIcon'
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'
import iconNames from '@/constants/ui-kit/standard/icon-names'
import { featureIconMap } from '@/constants/features/feature-icon-map'

export default {
  name: 'IconsViewer',
  components: {
    AppIcon,
  },
  data() {
    return {
      sizes: Object.values(sizes),
      styles: Object.values(styles),
      demoIconSize: sizes.XL,
      iconNamesArray: Object.values(iconNames),
    }
  },
  computed: {
    features() {
      return Object.keys(featureIconMap)
        .map((featureId) => ({
          featureId,
          ...featureIconMap[featureId],
        }))
        .sort((a, b) => a.icon.localeCompare(b.icon))
    },
    featureIcons() {
      return this.features.map((feature) => feature.icon)
    },
    otherIcons() {
      return this.iconNamesArray.filter(
        (iconName) => !this.featureIcons.includes(iconName)
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  margin: 80px 50px;
}
h1 {
  font-size: 1.5rem;
  margin: 2.5em 0 1.5em;
  border-bottom: 1px solid black;
}
td,
th {
  font-weight: 400;
  font-size: 0.75rem;
  padding: 15px 20px;
}
.legacy .header {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
  box-shadow: 0 8px 6px -8px rgba(0, 0, 0, 0.25);
}
.legacy svg {
  fill: var(--black);
  stroke: var(--black);
}
.legacy .icon {
  display: inline-block;
}
.legacy tr:hover {
  background-color: var(--gray-300);
  .icon {
    background: #fff;
    box-shadow: 0 1px 6px -1px rgba(0, 0, 0, 0.25);
  }
}
</style>
