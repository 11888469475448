<template>
  <transition
    :name="hintTransition"
    @after-enter="isHintTotallyVisible = true"
    @before-leave="isHintTotallyVisible = false"
  >
    <div v-if="isHintShown" class="modal-hint-container__wrapper">
      <div class="modal-hint-container">
        <component :is="hintComponent"></component>
        <AppButtonIcon
          :icon="closeButtonIcon"
          :size="closeButtonSize"
          :button-style="closeButtonStyle"
          class="modal-hint-container__close-button"
          @click.native="closeHint"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import AppButtonIcon from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonIcon'
import HorizontalScrollHint from './HorizontalScrollHint'
import Hint from '@/classes/Hint/Hint'
import responsiveMixin from '@/mixins/responsiveMixin'
import iconNames from '@/constants/ui-kit/standard/icon-names'
import styles from '@/constants/ui-kit/standard/styles'
import sizes from '@/constants/ui-kit/standard/sizes'

const HINT_TIMEOUT_MS = 5000

export default {
  name: 'ModalHintContainer',
  components: {
    AppButtonIcon,
    HorizontalScrollHint,
  },
  mixins: [responsiveMixin],
  props: {
    hintKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isHintEnabled: false,
      isHintTotallyVisible: false,
      hintCloseTimeout: null,
      closeButtonStyle: styles.LINK,
      closeButtonSize: sizes.XS,
      closeButtonIcon: iconNames.UIClose,
    }
  },
  computed: {
    ...mapGetters({
      topLayerCurrentHint: 'Layout/topLayerCurrentHint',
      getHint: 'Layout/getHint',
    }),
    currentHint() {
      const hint = this.getHint(this.hintKey)
      if (hint instanceof Hint) {
        hint._incrementShowCount()
        return hint
      }
      return null
    },
    isTopLayerHintShown() {
      return (
        this.topLayerCurrentHint && this.topLayerCurrentHint.shouldBeShown()
      )
    },
    isHintShown() {
      const conditionsToShowHint =
        this.isHintEnabled &&
        this.currentHint &&
        this.currentHint.shouldBeShown()
      const conditionsNotToShowHint =
        this.isTopLayerHintShown || this.transitionActive
      return conditionsToShowHint && !conditionsNotToShowHint
    },
    hintTransition() {
      return this.currentHint ? this.currentHint.transition : ''
    },
    hintComponent() {
      return this.currentHint ? this.currentHint.component : ''
    },
  },
  watch: {
    isHintShown() {
      if (this.isHintShown) {
        this.beginHintBehavior()
      } else {
        this.endHintBehavior()
      }
    },
  },
  mounted() {
    if (!this.currentHint) {
      this.$loggingService.logError(
        `ModalHintContainer: hintKey ${this.hintKey} does not match a valid hint in store.`
      )
    } else {
      this.isHintEnabled = true
    }
  },
  beforeDestroy() {
    this.endHintBehavior()
  },
  methods: {
    closeHint() {
      if (this.isHintTotallyVisible) {
        this.isHintEnabled = false
      }
    },
    beginHintBehavior() {
      const closeHintUserEvent = this.isSmallScreen ? 'touchstart' : 'click'
      document.body.addEventListener(closeHintUserEvent, this.closeHint)
      this.hintCloseTimeout = setTimeout(() => {
        this.closeHint()
      }, HINT_TIMEOUT_MS)
    },
    endHintBehavior() {
      document.body.removeEventListener('click', this.closeHint)
      document.body.removeEventListener('touchstart', this.closeHint)
      if (this.hintCloseTimeout) {
        clearTimeout(this.hintCloseTimeout)
        this.hintCloseTimeout = null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-hint-container {
  position: relative;
  width: calc(100% - 128px);
  max-width: 250px;
  background-color: rgba(21, 21, 21, 0.9);
  padding: 20px 12px;

  @at-root #{&}__close-button {
    pointer-events: all;
    position: absolute;
    top: 6px;
    right: 6px;
  }

  @at-root #{&}__wrapper {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.hint-fade-enter-active,
.hint-fade-leave-active {
  transition: opacity 300ms ease-in-out;
}
.hint-fade-enter-active {
  transition-delay: 300ms;
}
.hint-fade-enter,
.hint-fade-leave-to {
  opacity: 0;
}
</style>
