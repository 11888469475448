<template>
  <section>
    <VTitle text="AppSheetProjectElementViewer" />
    <div class="example-component">
      <div class="example-component__controls">
        <VPropControl
          v-for="(propModel, propName) in propModels"
          :key="propName"
          :model="propModel"
          :name="propName"
        />
      </div>
      <div class="example-component__component">
        <VComponentWrapper :width="375" bordered>
          <AppSheetProjectElement
            :title="propModels.title.value"
            :subtitle="propModels.subtitle.value"
            :text-alignment="propModels.textAlignment.value"
            :enter-to="propModels.enterTo.value"
            :exterior-tour360-to="propModels.exteriorTour360To.value"
          />
        </VComponentWrapper>
      </div>
    </div>
  </section>
</template>

<script>
import AppSheetProjectElement from '@/components/UIKit/Standard/Organisms/AppSheet/AppSheetProjectElement'
import AppPropModel from '@/components/UIKit/Standard/ViewerSections/classes/AppPropModel'
import VTitle from '@/components/UIKit/Standard/ViewerSections/components/snippets/VTitle'
import VPropControl from '@/components/UIKit/Standard/ViewerSections/components/snippets/VPropControl'
import VComponentWrapper from '@/components/UIKit/Standard/ViewerSections/components/snippets/VComponentWrapper'
import alignments from '@/constants/ui-kit/standard/alignments'

export default {
  name: 'AppSheetProjectElementViewer',
  components: {
    AppSheetProjectElement,
    VTitle,
    VPropControl,
    VComponentWrapper,
  },
  data() {
    return {
      propModels: {
        title: new AppPropModel({
          type: String,
          defaultValue: 'The Sava Collection',
          required: true,
        }),
        subtitle: new AppPropModel({
          type: String,
          defaultValue: '110 to 121',
        }),
        textAlignment: new AppPropModel({
          type: String,
        }),
        enterTo: new AppPropModel({
          type: Object,
          defaultValue: {},
        }),
        exteriorTour360To: new AppPropModel({
          type: Object,
          defaultValue: {},
        }),
      },
    }
  },
  created() {
    Object.keys(alignments).forEach((alignment) => {
      this.propModels.textAlignment.addPreset(alignments[alignment], alignment)
    })
  },
}
</script>

<style lang="scss" scoped>
.example-component {
  display: flex;
  justify-content: stretch;
  align-items: stretch;

  @at-root &__controls {
    background: #fff;
    padding: 30px;
    border: 1px solid var(--gray-200);
    flex-basis: 300px;
    border-radius: 12px;
  }

  @at-root &__component {
    padding: 0 30px;
  }
}
</style>
