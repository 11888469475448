<template>
  <AppBaseModal
    :title="title"
    :modal-width="800"
    expand-content
    v-on="$listeners"
  >
    <AppQuoteWizard :unit="unitToQuote" />
  </AppBaseModal>
</template>

<script>
import AppBaseModal from '@/components/UIKit/Standard/Atoms/AppBaseModal'
import AppQuoteWizard from '@/components/UIKit/Standard/Organisms/AppQuoteWizard/AppQuoteWizard'
import { mapGetters } from 'vuex'

export default {
  name: 'AppQuoterModal',
  components: {
    AppBaseModal,
    AppQuoteWizard,
  },
  data() {
    return {
      title: this.$t('Cotizar unidad'),
    }
  },
  computed: {
    ...mapGetters({
      unitToQuote: 'QuoterModule/unitToQuote',
      paymentMethods: 'QuoterModule/paymentMethods',
    }),
  },
}
</script>
