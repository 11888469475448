import TowerAdapter from '@/classes/ProjectElementAdapters/TowerAdapter'
import BaseClusterGroupAdapter from '@/classes/ProjectElementAdapters/BaseClusterGroupAdapter'

export default class TowersClusterGroupAdapter extends BaseClusterGroupAdapter {
  static get ElementAdapter() {
    return TowerAdapter
  }
  // eslint-disable-next-line no-unused-vars
  static afterAdaptElement(towerElement, i18n) {
    towerElement.sortLevels()
  }
}
