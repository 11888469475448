<template>
  <div class="readability-shadow">
    <slot></slot>
    <transition-group name="readability-shadow__transition">
      <div
        v-show="top"
        key="top"
        class="readability-shadow__shadow readability-shadow__shadow--top"
      ></div>
      <div
        v-show="bottom"
        key="bottom"
        class="readability-shadow__shadow readability-shadow__shadow--bottom"
      ></div>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: 'ReadabilityShadow',
  props: {
    top: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.readability-shadow {
  @at-root &__shadow {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 5;
    height: 100px;
    pointer-events: none;

    @at-root &--top {
      display: block;
      top: 0;
      background: linear-gradient(
        180deg,
        rgba(16, 24, 40, 0.45) 0%,
        rgba(16, 24, 40, 0) 100%
      );
    }
    @at-root &--bottom {
      display: block;
      bottom: 0;
      background: linear-gradient(
        0deg,
        rgba(16, 24, 40, 0.45) 0%,
        rgba(16, 24, 40, 0) 100%
      );
    }
  }

  @at-root &__transition {
    @at-root &-enter-active,
      &-leave-active {
      transition: opacity 300ms ease-out;
    }
    @at-root &-enter,
      &-leave-to {
      opacity: 0;
    }
  }
}
</style>
