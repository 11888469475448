import Feature from './Feature'

class BooleanValueFeature extends Feature {
  constructor(featureData, i18n) {
    super(featureData, i18n)
    if (typeof featureData.value === 'boolean') {
      this._value = featureData.value
    } else {
      throw new Error(`Value ${featureData.value} is not a boolean`)
    }
  }
}
export default BooleanValueFeature
