<template>
  <AppSheetProjectElementLarge
    :title="tower.name"
    :enter-to="towerRouteObject"
    :exterior-tour360-to="exteriorTour360RouteObject"
    :logo-image-url="logoUrl"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import Tower from '@/classes/ProjectStructure/Tower'
import AppSheetProjectElementLarge from '@/components/UIKit/Standard/Organisms/AppSheet/AppSheetProjectElementLarge'
import responsiveMixin from '@/mixins/responsiveMixin'
import TowerRouteObjectGenerator from '@/classes/RouteObjectGenerators/TowerRouteObjectGenerator'
import ExteriorTour360RouteObjectGenerator from '@/classes/RouteObjectGenerators/ExteriorTour360RouteObjectGenerator'

export default {
  name: 'TowerPanelContent',
  components: {
    AppSheetProjectElementLarge,
  },
  mixins: [responsiveMixin],
  props: {
    projectElement: {
      type: Tower,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      logoData: 'Project/logo',
    }),
    tower() {
      return this.projectElement
    },
    shouldDisplayLogo() {
      return (
        this.isSmallScreenHorizontal &&
        (this.tower.logo || this.logoData.display)
      )
    },
    logoUrl() {
      return this.shouldDisplayLogo
        ? this.tower.logo || this.logoData.imgUrl
        : undefined
    },
    haveExteriorTour360() {
      return !!this.tower.exteriorTour360Url
    },
    towerRouteObject() {
      const params = {
        useTransition: true,
        hideUIControlsDuringTransition: true,
      }
      return TowerRouteObjectGenerator.make(this.tower, params)
    },
    exteriorTour360RouteObject() {
      return this.haveExteriorTour360
        ? ExteriorTour360RouteObjectGenerator.make(this.tower)
        : undefined
    },
  },
}
</script>
