<template>
  <transition name="swipe-bottom-up" mode="out-in" @before-enter="updateHeight">
    <TouchManager
      v-if="isOpen"
      :key="identifier"
      tag="section"
      class="sliding-up-panel"
      :style="panelStyles"
      :class="panelClasses"
      @drag-up="enlargeToMaximum"
      @flick-up="enlarge"
      @swipe-down="onSwipeDown"
      @touch-move="onTouchMove"
      @touch-start="onTouchStart"
      @touch-end="onTouchEnd"
      @click.native="enlarge"
    >
      <div ref="panelContainer" class="sliding-up-panel__content">
        <AppScrollbarContainer
          ref="panelScrollableArea"
          :disabled="isScrollDisabled"
          class="sliding-up-panel__content__scroll"
          :class="{
            'sliding-up-panel__content__scroll--disabled': isScrollDisabled,
          }"
        >
          <div
            class="sliding-up-panel__content__scroll__inner sliding-up-panel__wrapper"
          >
            <div v-if="!isInPreviewMode" class="sliding-up-panel__controls">
              <AppButtonIcon
                :icon="closeButtonIcon"
                :button-style="closeButtonStyle"
                :size="closeButtonSize"
                @click.stop.native="reduceToMinimum"
              />
            </div>
            <div ref="content" class="sliding-up-panel__content__block">
              <transition name="sliding-up-panel__content__slot-transition">
                <div
                  :key="contentMode"
                  class="sliding-up-panel__content__scroll__inner"
                >
                  <slot :name="contentMode"></slot>
                </div>
              </transition>
            </div>
          </div>
        </AppScrollbarContainer>
      </div>
      <div v-if="displayContactBar" ref="footer">
        <InfoPanelOfflineModeContactBar v-if="isOfflineModeEnabled" />
        <AppSheetContactInformation
          v-else
          :contact-data="contactData"
          :with-contact-form-button="enableContactForms"
          @open-email-form="requestOpenForm"
        />
      </div>
    </TouchManager>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import TouchManager from '@/components/Gadgets/TouchManager'
import AppButtonIcon from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonIcon'
import AppScrollbarContainer from '@/components/UIKit/Standard/Atoms/AppScrollbarContainer'
import iconNames from '@/constants/ui-kit/standard/icon-names'
import styles from '@/constants/ui-kit/standard/styles'
import sizes from '@/constants/ui-kit/standard/sizes'
import AppSheetContactInformation from '@/components/UIKit/Standard/Molecules/AppSheetContactInformation'
import InfoPanelOfflineModeContactBar from '@/components/Gadgets/TheInfoPanel/InfoPanelOfflineModeContactBar'

const slidingUpPanelSlotNames = {
  MINIMIZED: 'minimized',
  COLLAPSED: 'collapsed',
  EXPANDED: 'expanded',
}

export default {
  name: 'SlidingUpPanel',
  components: {
    AppButtonIcon,
    AppScrollbarContainer,
    TouchManager,
    AppSheetContactInformation,
    InfoPanelOfflineModeContactBar,
  },
  props: {
    identifier: {
      type: String,
      default: 'sliding-up-panel-panel',
    },
    keepOpen: {
      type: Boolean,
      default: false,
    },
    disableContainerScroll: {
      type: Boolean,
      default: false,
    },
    displayContactBar: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOpen: false,
      closeButtonStyle: styles.LINK,
      closeButtonSize: sizes.XS,
      closeButtonIcon: iconNames.UIClose,
      contentSlots: [
        slidingUpPanelSlotNames.MINIMIZED,
        slidingUpPanelSlotNames.COLLAPSED,
        slidingUpPanelSlotNames.EXPANDED,
      ],
      currentSlot: 0,
      contentHeight: 0,
      footerHeight: 0,
      touchYOffset: 0,
      dragging: false,
      canReduceOnSwipeDown: true,
    }
  },
  computed: {
    ...mapGetters({
      isOfflineModeEnabled: 'Project/isOfflineModeEnabled',
      contactData: 'Project/contactData',
      enableContactForms: 'Preferences/enableContactForms',
    }),
    availableContentSlots() {
      return this.contentSlots.filter((slotName) => {
        return !!this.$slots[slotName]
      })
    },
    contentMode() {
      return this.availableContentSlots[this.currentSlot]
    },
    shouldFooterBeShown() {
      return this.contentMode !== slidingUpPanelSlotNames.MINIMIZED
    },
    isScrollDisabled() {
      return (
        this.disableContainerScroll ||
        this.contentMode !== slidingUpPanelSlotNames.EXPANDED
      )
    },

    isInPreviewMode() {
      return this.contentMode !== slidingUpPanelSlotNames.EXPANDED
    },
    panelHeight() {
      const panelHeight = this.shouldFooterBeShown
        ? this.footerHeight + this.contentHeight
        : this.contentHeight
      return Math.max(panelHeight - this.touchYOffset, 0)
    },
    panelClasses() {
      return [
        `sliding-up-panel--${this.contentMode}`,
        { 'sliding-up-panel--dragging': this.dragging },
      ]
    },
    panelStyles() {
      return {
        '--panel-height': `${this.panelHeight}px`,
        '--content-height': `${this.contentHeight}px`,
      }
    },
  },
  watch: {
    currentSlot() {
      this.updateHeight()
    },
  },
  methods: {
    async open() {
      this.isOpen = true
    },
    async close() {
      this.isOpen = false
      await this.$nextTick()
      this.$emit('height-updated', 0)
    },
    enlarge() {
      this.currentSlot++
      if (this.currentSlot >= this.availableContentSlots.length) {
        this.currentSlot = this.availableContentSlots.length - 1
      }
    },
    enlargeToMaximum() {
      this.currentSlot = this.availableContentSlots.length - 1
    },
    async reduce() {
      this.currentSlot--
      if (this.currentSlot < 0) {
        this.currentSlot = 0
        if (!this.keepOpen) {
          this.close()
          this.$emit('closed-by-user')
        }
      }
    },
    async reduceToMinimum() {
      this.currentSlot = 0
    },
    updateContentHeight() {
      if (this.$refs.content) {
        this.contentHeight = this.$refs.content.scrollHeight
      }
    },
    updateFooterHeight() {
      this.footerHeight = this.$refs.footer ? this.$refs.footer.scrollHeight : 0
    },
    async updateHeight() {
      await this.$nextTick()
      this.updateContentHeight()
      this.updateFooterHeight()
      this.emitHeightUpdated()
    },
    onTouchMove(touch) {
      this.dragging = true
      this.touchYOffset = touch.distanceY
      const shouldPreventReloadGesture =
        this.canReduceOnSwipeDown && touch.directionY === 'down'
      if (shouldPreventReloadGesture) {
        touch.event.preventDefault()
      }
    },
    onTouchStart() {
      this.canReduceOnSwipeDown =
        this.disableContainerScroll ||
        this.$refs.panelScrollableArea.$el.scrollTop === 0
    },
    async onTouchEnd() {
      this.dragging = false
      await this.$nextTick()
      this.touchYOffset = 0
    },
    onSwipeUp() {
      this.enlargeToMaximum()
    },
    onSwipeDown() {
      if (this.canReduceOnSwipeDown) {
        if (this.contentMode === slidingUpPanelSlotNames.EXPANDED) {
          this.reduceToMinimum()
        } else {
          this.reduce()
        }
      }
    },
    emitHeightUpdated() {
      if (this.contentMode !== slidingUpPanelSlotNames.EXPANDED) {
        this.$emit('height-updated', this.panelHeight)
      }
    },
    requestOpenForm() {
      this.$emit('request-open-form')
    },
  },
}
</script>

<style lang="scss" scoped>
.sliding-up-panel {
  --panel-height: 0;
  --content-height: 0;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  transition: height 300ms ease-out;
  z-index: 1;

  @at-root #{&}__wrapper {
    display: flex;
    flex-direction: column;
  }

  @at-root #{&}--collapsed,
    #{&}--minimized,
    #{&}--dragging {
    height: var(--panel-height);
  }

  $panel: &;
  @at-root #{&}--minimized,
    #{&}--collapsed {
    #{$panel}__content {
      transition: min-height 300ms ease-out;
      min-height: var(--content-height);
    }
  }

  @at-root #{&}--expanded {
    @include inner-height;

    #{$panel}__content__scroll--disabled {
      #{$panel}__content__scroll__inner {
        height: 100%;
      }
      #{$panel}__content__block {
        overflow: hidden;
      }
    }

    #{$panel}__content__block {
      flex: 1;
    }

    #{$panel}__content {
      border-radius: 0;
    }
  }

  @at-root #{&}--dragging {
    transition: none;
  }

  @at-root #{&}__content {
    flex: 1;
    overflow: hidden;
    background: var(--white);
    border-radius: 12px 12px 0px 0px;

    @at-root #{&}__scroll {
      position: relative;
      height: 100%;
      overflow: auto;

      @at-root #{&}--disabled {
        overflow: hidden;
      }
    }

    @at-root #{&}__slot-transition {
      @at-root #{&}-enter-active,
        #{&}-leave-active {
        transition: opacity 400ms ease-in-out;
      }
      @at-root #{&}-leave-active {
        position: absolute !important;
        width: 100%;
      }
      @at-root #{&}-enter,
        #{&}-leave-to {
        opacity: 0;
      }
    }
  }

  @at-root #{&}__controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 16px;
    margin: 16px 0 0;
    min-height: 40px;
  }
}

.swipe-bottom-up-enter-active,
.swipe-bottom-up-leave-active {
  transition: transform 500ms cubic-bezier(0.15, 0, 0, 1);
  transition-delay: 50ms;
}
.swipe-bottom-up-enter,
.swipe-bottom-up-leave-to {
  transform: translateY(100%);
}
.swipe-bottom-up-leave-to {
  transition: transform 400ms cubic-bezier(0.75, 0, 0.65, 1),
    transform 400ms ease-in-out;
}
</style>
