import MasterplanRouteObjectGenerator from '@/classes/RouteObjectGenerators/MasterplanRouteObjectGenerator'
import NeighbourhoodSubdivisionRouteObjectGenerator from '@/classes/RouteObjectGenerators/NeighbourhoodSubdivisionRouteObjectGenerator'
import LevelRouteObjectGenerator from '@/classes/RouteObjectGenerators/LevelRouteObjectGenerator'
import UnitRouteObjectGenerator from '@/classes/RouteObjectGenerators/UnitRouteObjectGenerator'
import PointOfInterestRouteObjectGenerator from '@/classes/RouteObjectGenerators/PointOfInterestRouteObjectGenerator'
import GalleryPageRouteObjectGenerator from '@/classes/RouteObjectGenerators/GalleryPageRouteObjectGenerator'
import AmenitiesGalleryPageRouteObjectGenerator from '@/classes/RouteObjectGenerators/AmenitiesGalleryPageRouteObjectGenerator'
import AvailabilityViewRouteObjectGenerator from '@/classes/RouteObjectGenerators/AvailabilityViewRouteObjectGenerator'

export const DEFAULT_TRANSITIONS = [
  {
    from: MasterplanRouteObjectGenerator.ROUTE_NAME,
    to: MasterplanRouteObjectGenerator.ROUTE_NAME,
    transitionType: 'animation',
    transitionParams: {
      effect: 'fade',
    },
  },
  {
    from: MasterplanRouteObjectGenerator.ROUTE_NAME,
    to: LevelRouteObjectGenerator.ROUTE_NAME,
    transitionType: 'animation',
    transitionParams: {
      effect: 'fade',
    },
  },
  {
    from: LevelRouteObjectGenerator.ROUTE_NAME,
    to: LevelRouteObjectGenerator.ROUTE_NAME,
    transitionType: 'animation',
    transitionParams: {
      effect: 'fade',
    },
  },
  {
    from: UnitRouteObjectGenerator.ROUTE_NAME,
    to: LevelRouteObjectGenerator.ROUTE_NAME,
    transitionType: 'animation',
    transitionParams: {
      effect: 'fade',
    },
  },
  {
    from: UnitRouteObjectGenerator.ROUTE_NAME,
    to: NeighbourhoodSubdivisionRouteObjectGenerator.ROUTE_NAME,
    transitionType: 'animation',
    transitionParams: {
      effect: 'fade',
    },
  },
  {
    from: MasterplanRouteObjectGenerator.ROUTE_NAME,
    to: NeighbourhoodSubdivisionRouteObjectGenerator.ROUTE_NAME,
    transitionType: 'animation',
    transitionParams: {
      effect: 'fade',
    },
  },
  {
    from: NeighbourhoodSubdivisionRouteObjectGenerator.ROUTE_NAME,
    to: NeighbourhoodSubdivisionRouteObjectGenerator.ROUTE_NAME,
    transitionType: 'animation',
    transitionParams: {
      effect: 'fade',
    },
  },
  {
    from: UnitRouteObjectGenerator.ROUTE_NAME,
    to: UnitRouteObjectGenerator.ROUTE_NAME,
    transitionType: 'animation',
    transitionParams: {
      effect: 'slide-left-short',
    },
  },
  {
    from: GalleryPageRouteObjectGenerator.ROUTE_NAME,
    to: GalleryPageRouteObjectGenerator.ROUTE_NAME,
    transitionType: 'animation',
    transitionParams: {
      effect: 'slide-left-short',
    },
  },
  {
    from: AvailabilityViewRouteObjectGenerator.ROUTE_NAME,
    to: AvailabilityViewRouteObjectGenerator.ROUTE_NAME,
    transitionType: 'animation',
    transitionParams: {
      effect: 'fade',
    },
  },
  {
    from: AmenitiesGalleryPageRouteObjectGenerator.ROUTE_NAME,
    to: AmenitiesGalleryPageRouteObjectGenerator.ROUTE_NAME,
    transitionType: 'animation',
    transitionParams: {
      effect: 'slide-left-short',
    },
  },
  {
    from: PointOfInterestRouteObjectGenerator.ROUTE_NAME,
    to: PointOfInterestRouteObjectGenerator.ROUTE_NAME,
    transitionType: 'animation',
    transitionParams: {
      effect: 'slide-left-short',
    },
  },
]
