import { AvailabilityStatuses, OfferTypes } from '@/constants/units'
import { availabilityStatusesTranslationKeys } from '@/constants/availability-translations'
import Unit from '@/classes/ProjectStructure/Unit/Unit'

export default class UnitAvailabilityStatusTranslationFactory {
  static _validateUnitData(unit) {
    if (!(unit instanceof Unit)) {
      throw new Error(
        `UnitAvailabilityStatusTranslationFactory._validateUnitData: ${unit} is not an instance of Unit class`
      )
    }

    if (!unit.offerType) {
      throw new Error(
        `UnitAvailabilityStatusTranslationFactory._validateUnitData: ${unit} does not have an offerType`
      )
    }

    if (!Object.values(OfferTypes).includes(unit.offerType)) {
      throw new Error(
        `UnitAvailabilityStatusTranslationFactory._validateUnitData: ${unit} offerType passed is not supported`
      )
    }

    if (!unit.availabilityStatus) {
      throw new Error(
        `UnitAvailabilityStatusTranslationFactory._validateUnitData: ${unit} does not have an availabilityStatus`
      )
    }

    if (
      !Object.values(AvailabilityStatuses).includes(unit.availabilityStatus)
    ) {
      throw new Error(
        `UnitAvailabilityStatusTranslationFactory._validateUnitData: ${unit} availabilityStatus passed is not supported`
      )
    }
  }

  static makeTranslationKeyByUnit(unit) {
    this._validateUnitData(unit)

    const translationKeysForRent = {
      [AvailabilityStatuses.RESERVED]:
        availabilityStatusesTranslationKeys.RESERVED,
      [AvailabilityStatuses.SOLD]: availabilityStatusesTranslationKeys.SOLD,
      [AvailabilityStatuses.RENTED]: availabilityStatusesTranslationKeys.RENTED,
      [AvailabilityStatuses.AVAILABLE]:
        availabilityStatusesTranslationKeys.AVAILABLE_FOR_RENT,
    }

    const translationKeysForSale = {
      [AvailabilityStatuses.RESERVED]:
        availabilityStatusesTranslationKeys.RESERVED,
      [AvailabilityStatuses.SOLD]: availabilityStatusesTranslationKeys.SOLD,
      [AvailabilityStatuses.RENTED]: availabilityStatusesTranslationKeys.RENTED,
      [AvailabilityStatuses.AVAILABLE]:
        availabilityStatusesTranslationKeys.AVAILABLE_FOR_SALE,
    }

    let translationKey = undefined

    if (unit.offerType === OfferTypes.FOR_RENT) {
      translationKey = translationKeysForRent[unit.availabilityStatus]
    }

    if (unit.offerType === OfferTypes.FOR_SALE) {
      translationKey = translationKeysForSale[unit.availabilityStatus]
    }

    if (translationKey === undefined) {
      throw new Error(
        `UnitAvailabilityStatusTranslationFactory.makeTranslationKeyByUnit: Key ${unit.offerType}/${unit.availabilityStatus} is not found in the translations structure`
      )
    }

    return translationKey
  }
}
