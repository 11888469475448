import * as preferenceNames from '@/constants/preferences/preference-names'
import preferencesDependency from '@/constants/preferences/preferences-dependency'
import LoggingServiceFactory from '@/classes/LoggingService/LoggingServiceFactory'

class Preferences {
  constructor({ defaultPreferences }) {
    if (!this._validatePreferencesIsAnOject(defaultPreferences)) {
      throw new Error(
        `Preferences.constructor: Invalid defaultPreferences (${defaultPreferences}).`
      )
    }

    const missingPreferences = this._findMissingPreferences(defaultPreferences)
    if (missingPreferences.length > 0) {
      throw new Error(
        `Preferences.constructor: Missing required preferences in defaultPreferences: ${missingPreferences}.`
      )
    }

    const unexpectedPreferences = this._findUnexpectedPreferences(
      defaultPreferences
    )
    if (unexpectedPreferences.length > 0) {
      throw new Error(
        `Preferences.constructor: Unexpected preferences found in defaultPreferences: ${unexpectedPreferences}.`
      )
    }

    const preferencesWithDependencyError = this._findPreferencesWithDependencyErrors(
      defaultPreferences
    )
    if (preferencesWithDependencyError.length > 0) {
      throw new Error(
        `Preferences.constructor: There are defaultPreferences with dependency errors: ${preferencesWithDependencyError}.`
      )
    }

    this.loggingService = new LoggingServiceFactory().makeService()
    this._defaultPreferences = defaultPreferences
    this._preferences = { ...this._defaultPreferences }
    this._preferencesHandler = {
      // eslint-disable-next-line no-unused-vars
      set: (object, prop, value) => {
        throw new Error(`Preferences.config: ${prop} is a read-only property.`)
      },
    }
  }
  setPreferences(preferences) {
    if (!this._validatePreferencesIsAnOject(preferences)) {
      throw new Error(
        `Preferences.setPreferences: Invalid preferences (${preferences}).`
      )
    }

    const missingPreferences = this._findMissingPreferences(preferences)
    if (missingPreferences.length > 0) {
      this.loggingService.logError(
        `Preferences.setPreferences: Missing required preferences: ${missingPreferences}. ` +
          'Default values will be used instead.'
      )
    }

    const unexpectedPreferences = this._findUnexpectedPreferences(preferences)
    if (unexpectedPreferences.length > 0) {
      this.loggingService.logError(
        `Preferences.setPreferences: Unexpected preferences: ${unexpectedPreferences}. ` +
          'Maybe default preferences are outdated?'
      )
    }

    const fixedPreferences = {}
    const preferencesWithDependencyError = this._findPreferencesWithDependencyErrors(
      preferences
    )
    preferencesWithDependencyError.forEach(
      (preferenceWithDependencyErrorName) => {
        const requiredPreferences =
          preferencesDependency[preferenceWithDependencyErrorName]
        const requiredPreferencesSettings = requiredPreferences.map(
          (preferenceDependencyName) => {
            return {
              [preferenceDependencyName]: preferences[preferenceDependencyName],
            }
          }
        )
        this.loggingService.logError(
          `Preferences.setPreferences: The preference ${preferenceWithDependencyErrorName} was turned off ` +
            `because some of the preferences it depends on are set off: ${JSON.stringify(
              requiredPreferencesSettings
            )}.`
        )

        fixedPreferences[preferenceWithDependencyErrorName] = false
      }
    )

    this._preferences = {
      ...this._defaultPreferences,
      ...preferences,
      ...fixedPreferences,
    }
  }
  _validatePreferencesIsAnOject(preferences) {
    return preferences !== null && typeof preferences === 'object'
  }
  _findMissingPreferences(preferences) {
    const preferenceNamesValues = Object.values(preferenceNames)
    const receivedPreferences = Object.keys(preferences)
    const missingPreferences = preferenceNamesValues.filter(
      (preferenceName) => !receivedPreferences.includes(preferenceName)
    )
    return missingPreferences
  }
  _findUnexpectedPreferences(preferences) {
    const preferenceNamesValues = Object.values(preferenceNames)
    const receivedPreferences = Object.keys(preferences)
    const unexpectedPreferences = receivedPreferences.filter(
      (preferenceName) => !preferenceNamesValues.includes(preferenceName)
    )
    return unexpectedPreferences
  }
  _findPreferencesWithDependencyErrors(preferences) {
    const preferencesWithDependency = Object.keys(preferencesDependency)
    const activePreferencesWithDependency = preferencesWithDependency.filter(
      (preferenceName) => preferences[preferenceName] === true
    )
    const preferencesWithDependencyError = []

    activePreferencesWithDependency.forEach((preferenceName) => {
      const requiredPreferences = preferencesDependency[preferenceName]
      const requiredPreferencesAreAlsoActive = requiredPreferences.every(
        (requiredPreferenceName) => preferences[requiredPreferenceName] === true
      )
      if (!requiredPreferencesAreAlsoActive) {
        preferencesWithDependencyError.push(preferenceName)
      }
    })

    return preferencesWithDependencyError
  }
  get config() {
    return new Proxy(this._preferences, this._preferencesHandler)
  }
}

export default Preferences
