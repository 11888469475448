import ProjectElementTypes from '@/constants/project-structure/project-element-types'
import BaseProjectRootElement from '@/classes/ProjectStructure/BaseProjectRootElement'

export default class NeighbourhoodProject extends BaseProjectRootElement {
  get _validChildElementTypes() {
    return [
      ProjectElementTypes.NEIGHBOURHOOD_SUBDIVISION,
      ProjectElementTypes.POINT_OF_INTEREST,
    ]
  }
}
