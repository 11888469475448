import ImageResource from '@/classes/MediaResources/ImageResource'
import Tour360Resource from '@/classes/MediaResources/Tour360Resource'
import BackgroundTree from '@/classes/BackgroundTree'
import router from '@/router'
import locationModes from '@/constants/location/modes'
import UnitRouteObjectGenerator from '@/classes/RouteObjectGenerators/UnitRouteObjectGenerator'
import LevelRouteObjectGenerator from '@/classes/RouteObjectGenerators/LevelRouteObjectGenerator'
import NeighbourhoodSubdivisionRouteObjectGenerator from '@/classes/RouteObjectGenerators/NeighbourhoodSubdivisionRouteObjectGenerator'
import PointOfInterestRouteObjectGenerator from '@/classes/RouteObjectGenerators/PointOfInterestRouteObjectGenerator'
import GalleryPageRouteObjectGenerator from '@/classes/RouteObjectGenerators/GalleryPageRouteObjectGenerator'
import AvailabilityViewRouteObjectGenerator from '@/classes/RouteObjectGenerators/AvailabilityViewRouteObjectGenerator'
import GeneralTour360GalleryRouteObjectGenerator from '@/classes/RouteObjectGenerators/GeneralTour360GalleryRouteObjectGenerator'
import ExteriorTour360RouteObjectGenerator from '@/classes/RouteObjectGenerators/ExteriorTour360RouteObjectGenerator'
import MasterplanRouteObjectGenerator from '@/classes/RouteObjectGenerators/MasterplanRouteObjectGenerator'
import stopPointSlugs from '@/constants/stop-point-slugs'
import CollectionSlugs from '@/classes/Gallery/CollectionSlugs'

const BackgroundTreeActions = {
  async adapterData({ dispatch }, { projectData }) {
    dispatch('createBackgroundTree', projectData.backgroundTree)
    dispatch('addBackgroundsBasedOnJson', projectData)
  },
  createBackgroundTree({ commit }) {
    const backgroundTree = new BackgroundTree(router)
    commit('storeBackgroundTree', backgroundTree)
  },
  addMainSpin360Backgrounds({ commit }, spin360Data) {
    const MASTERPLAN_ROUTE_OBJECTS = {
      [stopPointSlugs.MASTERPLAN_0]: MasterplanRouteObjectGenerator.make(),
      [stopPointSlugs.MASTERPLAN_120]: MasterplanRouteObjectGenerator.make({
        stopPointSlug: stopPointSlugs.MASTERPLAN_120,
      }),
      [stopPointSlugs.MASTERPLAN_240]: MasterplanRouteObjectGenerator.make({
        stopPointSlug: stopPointSlugs.MASTERPLAN_240,
      }),
    }

    spin360Data.stopPoints.forEach((stopPoint) => {
      const routeObject = MASTERPLAN_ROUTE_OBJECTS[stopPoint.stopPointSlug]

      const resource = new ImageResource({
        resourceUrl: stopPoint.backgroundImgUrl,
        labelText: '',
      })

      if (stopPoint.backgroundSvgUrl) {
        resource.setSvgLayer(stopPoint.backgroundSvgUrl)
      }
      commit('addBackgroundTreeItem', {
        routeObject,
        resource,
      })
    })
  },
  async addBackgroundsBasedOnJson({ commit, dispatch }, projectData) {
    const { projectStructure, backgroundTree } = projectData

    dispatch('addMainSpin360Backgrounds', projectStructure.spin360)

    Object.entries(backgroundTree).forEach(([routeName, backgroundData]) => {
      const routeObject = {
        name: routeName,
      }
      const resource = new ImageResource({
        resourceUrl: backgroundData.imgUrl,
        labelText: '',
      })
      if (backgroundData.svgUrl) {
        resource.setSvgLayer(backgroundData.svgUrl)
      }
      commit('addBackgroundTreeItem', {
        routeObject,
        resource,
      })
    })
  },
  addBackgroundsBasedOnStore({ dispatch }) {
    dispatch('addNeighbourhoodSubdivisionBackgrounds')
    dispatch('addLevelBackgrounds')
    dispatch('addUnitBackgrounds')
    dispatch('addPointsOfInterestBackgrounds')
    dispatch('addGalleryBackgrounds')
    dispatch('addGeneralTour360GalleryBackgrounds')
    dispatch('addAvailabilityGalleryBackgrounds')
    dispatch('addAmenitiesGalleryBackgrounds')
    dispatch('addLocationBackground')
    dispatch('addMainExteriorTour360')
    dispatch('addProjectElementsExteriorTour360s')
  },
  addMainExteriorTour360({ dispatch, rootGetters }) {
    const projectRootElement = rootGetters['Topview/project']
    if (projectRootElement.exteriorTour360Url) {
      dispatch('addExteriorTour360', projectRootElement)
    }
  },
  addProjectElementsExteriorTour360s({ dispatch, rootGetters }) {
    const towers = rootGetters['Topview/towers']
    const neighbourhoodSubdivisions =
      rootGetters['Topview/neighbourhoodSubdivisions']
    const pointsOfInterest = rootGetters['Topview/pointsOfInterest']
    const elementsWithExteriorTour360 = [
      ...towers,
      ...neighbourhoodSubdivisions,
      ...pointsOfInterest,
    ].filter((projectElement) => !!projectElement.exteriorTour360Url)

    elementsWithExteriorTour360.forEach((projectElement) => {
      dispatch('addExteriorTour360', projectElement)
    })
  },
  addExteriorTour360({ commit }, projectElement) {
    const routeObject = ExteriorTour360RouteObjectGenerator.make(projectElement)
    const resource = new Tour360Resource({
      resourceUrl: projectElement.exteriorTour360Url,
      labelText: '',
    })
    commit('addBackgroundTreeItem', {
      routeObject,
      resource,
    })
  },
  addNeighbourhoodSubdivisionBackgrounds({ commit, rootGetters }) {
    const neighbourhoodSubdivisions =
      rootGetters['Topview/neighbourhoodSubdivisions']
    neighbourhoodSubdivisions.forEach((neighbourhoodSubdivision) => {
      const routeObject = NeighbourhoodSubdivisionRouteObjectGenerator.make(
        neighbourhoodSubdivision
      )
      const resource = new ImageResource({
        resourceUrl: neighbourhoodSubdivision.image,
        labelText: '',
      })
      if (neighbourhoodSubdivision.svg) {
        resource.setSvgLayer(neighbourhoodSubdivision.svg)
      }
      commit('addBackgroundTreeItem', {
        routeObject,
        resource,
      })
    })
  },
  addLevelBackgrounds({ commit, rootGetters }) {
    const levels = rootGetters['Topview/levels']
    levels.forEach((level) => {
      const routeObject = LevelRouteObjectGenerator.make(level)
      const resource = new ImageResource({
        resourceUrl: level.image,
        labelText: '',
      })
      if (level.svg) {
        resource.setSvgLayer(level.svg)
      }
      commit('addBackgroundTreeItem', {
        routeObject,
        resource,
      })
    })
  },
  addUnitBackgrounds({ dispatch, rootGetters }) {
    const units = rootGetters['Topview/units']
    units.forEach((unit) => {
      dispatch('addUnitLevelsBackgrounds', unit)
    })
  },
  addUnitLevelsBackgrounds({ commit }, unit) {
    unit.unitLevels.forEach((unitLevel) => {
      unitLevel.gallery.availableCollections.forEach((collection) => {
        collection.resources.forEach((resource, resourceIndex) => {
          const resourceId = `${resourceIndex + 1}`
          const routeObject = UnitRouteObjectGenerator.make(unit, {
            unitLevelSlug: unitLevel.slug,
            collectionSlug: collection.slug,
            resourceId,
          })
          commit('addBackgroundTreeItem', {
            routeObject,
            resource,
          })
        })
      })
    })
  },
  addPointsOfInterestBackgrounds({ commit, rootGetters }) {
    const pointsOfInterest = rootGetters['Topview/pointsOfInterest']
    pointsOfInterest.forEach((pointOfInterest) => {
      if (pointOfInterest.gallery) {
        const galleryResources =
          pointOfInterest.gallery.defaultCollection.resources
        galleryResources.forEach((resource, resourceIndex) => {
          const resourceId = `${resourceIndex + 1}`
          const routeObject = PointOfInterestRouteObjectGenerator.make(
            pointOfInterest,
            {
              resourceId,
            }
          )
          commit('addBackgroundTreeItem', {
            routeObject,
            resource,
          })
        })
      }
    })
  },
  addGalleryBackgrounds({ commit, rootGetters }) {
    const gallery = rootGetters['Project/gallery']
    gallery.availableCollections.forEach((collection) => {
      collection.resources.forEach((resource, index) => {
        const resourceId = `${index + 1}`
        const routeObject = GalleryPageRouteObjectGenerator.make({ resourceId })
        commit('addBackgroundTreeItem', {
          routeObject,
          resource,
        })
      })
    })
  },
  addGeneralTour360GalleryBackgrounds({ commit, rootGetters }) {
    const generalTour360Gallery = rootGetters['Project/generalTour360Gallery']

    const tour360Collection = generalTour360Gallery.collection(
      CollectionSlugs.TOUR360
    )
    tour360Collection.resources.forEach((tour360Resource, index) => {
      const resourceId = `${index + 1}`
      const routeObject = GeneralTour360GalleryRouteObjectGenerator.make({
        resourceId,
      })

      commit('addBackgroundTreeItem', {
        routeObject,
        resource: tour360Resource,
      })
    })
  },
  addAvailabilityGalleryBackgrounds({ commit, rootGetters }) {
    const availabilityGallery =
      rootGetters['AvailabilityGalleryModule/availabilityGallery']
    availabilityGallery.availableCollections.forEach((collection) => {
      collection.resources.forEach((resource, index) => {
        const resourceId = `${index + 1}`
        const routeObject = AvailabilityViewRouteObjectGenerator.make({
          resourceId,
        })
        commit('addBackgroundTreeItem', {
          routeObject,
          resource,
        })
      })
    })
  },
  addAmenitiesGalleryBackgrounds({ commit, rootGetters }) {
    const amenitiesGallery = rootGetters['Project/amenitiesGallery']
    amenitiesGallery.availableCollections.forEach((collection) => {
      collection.resources.forEach((resource, index) => {
        const resourceId = `${index + 1}`
        const routeObject = {
          name: 'general-amenities-gallery',
          params: {
            resourceId: resourceId,
            collectionSlug: collection.slug,
          },
        }
        commit('addBackgroundTreeItem', {
          routeObject,
          resource,
        })
      })
    })
  },
  addLocationBackground({ commit, rootGetters }) {
    const locationCustomMapUrl = rootGetters['Project/locationCustomMapImgUrl']
    if (locationCustomMapUrl) {
      Object.values(locationModes).forEach((mode) => {
        const routeObject = {
          name: 'location',
          params: {
            mode,
          },
        }
        const resource = new ImageResource({
          resourceUrl: locationCustomMapUrl,
          labelText: '',
        })
        commit('addBackgroundTreeItem', {
          routeObject,
          resource,
        })
      })
    }
  },
}

export default BackgroundTreeActions
