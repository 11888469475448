<template>
  <div>
    <sliding-up-panel
      ref="slidingUpPanel"
      :keep-open="keepOpenInSmallScreensVertical"
      :identifier="identifier"
      :disable-container-scroll="disableContainerScroll"
      :display-contact-bar="displayContactBar"
      @request-open-form="requestOpenForm"
      @height-updated="heightUpdated"
      @closed-by-user="panelClosedByUser"
    >
      <template #expanded>
        <slot name="large"></slot>
      </template>
      <template #collapsed>
        <slot name="medium"></slot>
      </template>
      <template #minimized>
        <slot name="small"></slot>
      </template>
    </sliding-up-panel>
    <sidebar-panel
      ref="sidebarPanel"
      :identifier="identifier"
      :disable-container-scroll="disableContainerScroll"
      :display-contact-bar="displayContactBar"
      @request-open-form="requestOpenForm"
      @width-updated="widthUpdated"
      @closed-by-user="panelClosedByUser"
    >
      <slot :name="sidebarPanelContentSlotName"></slot>
    </sidebar-panel>
  </div>
</template>

<script>
import responsiveMixin from '@/mixins/responsiveMixin'
import SidebarPanel from './SidebarPanel'
import SlidingUpPanel from './SlidingUpPanel'

export default {
  name: 'TheInfoPanel',
  components: {
    SidebarPanel,
    SlidingUpPanel,
  },
  mixins: [responsiveMixin],
  props: {
    keepOpenInSmallScreensVertical: {
      type: Boolean,
      default: false,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    identifier: {
      type: String,
      default: 'panel',
    },
    disableContainerScroll: {
      type: Boolean,
      default: false,
    },
    displayContactBar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isPanelOpenByUser: false,
      sidebarPanelContentSlotName: 'large',
    }
  },
  computed: {
    currentPanel() {
      return this.isSmallScreenVertical
        ? this.$refs.slidingUpPanel
        : this.$refs.sidebarPanel
    },
  },
  watch: {
    isSmallScreenVertical() {
      this.switchPanels()
    },
    hidden() {
      if (this.hidden) {
        this.closePanels()
      } else {
        this.openCurrentPanelIfNeeded()
      }
    },
    identifier() {
      this.updateSidebarPanelContentSlotName()
    },
  },
  mounted() {
    this.updateSidebarPanelContentSlotName()
    this.openCurrentPanelIfNeeded()
  },
  methods: {
    open() {
      this.isPanelOpenByUser = true
      this.$emit('open')
      this.currentPanel.open()
    },
    close() {
      this.isPanelOpenByUser = false
      this.$emit('closed')
      this.currentPanel.close()
    },
    switchPanels() {
      this.closePanels()
      this.openCurrentPanelIfNeeded()
    },
    closePanels() {
      this.$refs.sidebarPanel.close()
      this.$refs.slidingUpPanel.close()
    },
    openCurrentPanelIfNeeded() {
      const isPanelNotHidden = !this.hidden
      const shouldPanelBeAlwaysOpen =
        this.isSmallScreenVertical && this.keepOpenInSmallScreensVertical
      const shouldPanelOpen = shouldPanelBeAlwaysOpen || this.isPanelOpenByUser

      if (isPanelNotHidden && shouldPanelOpen) {
        this.currentPanel.open()
      } else {
        this.$emit('closed')
      }
    },
    panelClosedByUser() {
      this.isPanelOpenByUser = false
      this.$emit('closed')
    },
    widthUpdated(value) {
      this.$emit('width-updated', value)
    },
    heightUpdated(value) {
      this.$emit('height-updated', value)
    },
    requestOpenForm() {
      this.$emit('request-open-form')
    },
    updateSidebarPanelContentSlotName() {
      const slotNamesSortedByPriority = ['large', 'medium', 'small']
      const nonEmptySlotNames = Object.keys(this.$slots).filter(
        (slotName) => this.$slots[slotName] !== undefined
      )
      const firstNonEmptySlot = slotNamesSortedByPriority.find((slotName) =>
        nonEmptySlotNames.includes(slotName)
      )
      this.sidebarPanelContentSlotName = firstNonEmptySlot
    },
  },
}
</script>
