class GeneralTour360GalleryRouteObjectGenerator {
  static get ROUTE_NAME() {
    return 'general-tour360-gallery'
  }
  static make(customParams) {
    const overridableParams = {
      resourceId: '1',
    }
    return {
      name: GeneralTour360GalleryRouteObjectGenerator.ROUTE_NAME,
      params: {
        ...overridableParams,
        ...customParams,
      },
    }
  }
}
export default GeneralTour360GalleryRouteObjectGenerator
