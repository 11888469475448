<template>
  <button class="flap">
    <AppIcon class="flap__icon" :size="flapIconSize" :icon="icon" />
    <span v-if="withBadge" class="flap__badge"></span>
  </button>
</template>
<script>
import AppIcon from '@/components/UIKit/Standard/Atoms/AppIcon'
import sizes from '@/constants/ui-kit/standard/sizes'
import iconNames from '@/constants/ui-kit/standard/icon-names'

export default {
  name: 'AppButtonFlap',
  components: {
    AppIcon,
  },
  props: {
    icon: {
      type: String,
      required: true,
      validator: (value) => Object.values(iconNames).includes(value),
    },
    withBadge: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      flapIconSize: sizes.LG,
    }
  },
}
</script>
<style lang="scss">
@import 'app-button.mixin';
.flap-icon {
  @include button-styles;
  @include button-icon-sizes;
  @include button-alternatives;
  @include noselect;
}

.flap {
  position: relative;
  width: fit-content;
  cursor: pointer;
  padding: 7px 14px 7px 20px;
  border-radius: 0 20px 20px 0;
  background-color: #fff;
  border: 1px solid var(--gray-300);
  box-shadow: 0px 1px 2px var(--primary-color-25);
  @include noselect;

  @at-root #{&}__icon {
    transition: stroke 200ms ease-out;
    fill: var(--gray-700);
    stroke: var(--gray-700);
  }

  @at-root #{&}__badge {
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: #fd0d1b;
  }

  @at-root #{&}:hover {
    background-color: var(--gray-50);
  }
}
</style>
