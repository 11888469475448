<template>
  <TopBarButtonIcon
    :button-icon="fullscreenIcon"
    :size="fullscreenButtonSize"
    :button-style="fullscreenButtonStyle"
    @button-click="toggleFullscreen"
  />
</template>

<script>
import TopBarButtonIcon from '@/components/Gadgets/TheTopBar/controls/TopBarButtonIcon'
import iconNames from '@/constants/ui-kit/standard/icon-names'
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'

export default {
  name: 'TheTopBarFullscreenButton',
  components: {
    TopBarButtonIcon,
  },
  data() {
    return {
      fullscreenIconEnter: iconNames.UIMaximize,
      fullscreenIconExit: iconNames.UIMinimize,
      fullscreenButtonSize: sizes.SM,
      fullscreenButtonStyle: styles.DARK,
    }
  },
  computed: {
    fullscreenIcon() {
      return this.$fullscreenHandler.isFullscreen
        ? this.fullscreenIconExit
        : this.fullscreenIconEnter
    },
  },
  methods: {
    toggleFullscreen() {
      this.$fullscreenHandler.toggleFullscreen()
    },
  },
}
</script>
