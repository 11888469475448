<template>
  <section class="container">
    <h1>AppSocialNetworkButtons</h1>
    <div class="wrapper">
      <AppSocialNetworkButtons
        :facebook-url="facebookUrl"
        :instagram-url="instagramUrl"
        :whatsapp-url="whatsappUrl"
      />
    </div>
  </section>
</template>
<script>
import AppSocialNetworkButtons from '@/components/UIKit/Standard/Molecules/AppSocialNetworkButtons'

export default {
  name: 'AppSheetContactInformationViewer',
  components: {
    AppSocialNetworkButtons,
  },
  data() {
    return {
      facebookUrl: 'https://www.facebook.com',
      instagramUrl: 'https://www.instagram.com',
      whatsappUrl: 'https://www.whatsapp.com',
    }
  },
}
</script>
<style lang="scss" scoped>
.container {
  margin: 80px 50px;
  .wrapper {
    margin: 50px;
  }
}
h1 {
  font-size: 1.5rem;
  margin: 2.5em 0 1.5em;
  border-bottom: 1px solid black;
}
</style>
