<template>
  <div class="tooltip-element" :class="placementClassName">
    <div class="tooltip-element__inner">
      <div class="tooltip-element__arrow" :style="arrowPositionStyle"></div>
      <span>{{ text }}</span>
    </div>
  </div>
</template>
<script>
import tooltipPlacements from '@/constants/ui-kit/standard/tooltip-placements'

export default {
  name: 'AppTooltipElement',
  props: {
    text: {
      type: String,
      required: true,
      validator: (value) => value.trim() !== '',
    },
    placement: {
      type: String,
      default: tooltipPlacements.TOP,
      validator: (value) => Object.values(tooltipPlacements).includes(value),
    },
    arrowPositionX: {
      type: Number,
      default: () => undefined,
    },
  },
  data() {
    return {}
  },
  computed: {
    placementClassName() {
      return `tooltip-element--${this.placement}`
    },
    arrowPositionStyle() {
      if (this.arrowPositionX == undefined) {
        return undefined
      }
      return {
        '--arrow-position-x': `${this.arrowPositionX}px`,
      }
    },
  },
  methods: {},
}
</script>
<style lang="scss" scoped>
.tooltip-element {
  --arrow-position-x: 9999px;
  display: inline-block;

  @at-root &__inner {
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: var(--gray-900);
    padding: 0.5rem 20px;
    border-radius: 0.5rem;
    color: #fff;
  }

  @at-root &__arrow {
    position: absolute;
  }

  $tooltip: &;
  @at-root &--#{$placement-top},
    &--#{$placement-top-start},
    &--#{$placement-top-end} {
    #{$tooltip}__arrow {
      border-top: 12px solid var(--gray-900);
      border-right: 12px solid transparent;
      border-left: 12px solid transparent;
      bottom: 0;
      transform: translate(-50%, 50%);
    }
  }

  @at-root &--#{$placement-bottom},
    &--#{$placement-bottom-start},
    &--#{$placement-bottom-end} {
    #{$tooltip}__arrow {
      border-bottom: 12px solid var(--gray-900);
      border-right: 12px solid transparent;
      border-left: 12px solid transparent;
      top: 0;
      transform: translate(-50%, -50%);
    }
  }

  @at-root &--#{$placement-top},
    &--#{$placement-bottom} {
    #{$tooltip}__arrow {
      left: 50%;
    }
  }

  @at-root &--#{$placement-top-start},
    &--#{$placement-bottom-start} {
    #{$tooltip}__arrow {
      left: min(calc(100% - 18px), var(--arrow-position-x));
    }
  }

  @at-root &--#{$placement-top-end},
    &--#{$placement-bottom-end} {
    #{$tooltip}__arrow {
      right: min(calc(100% - 18px), var(--arrow-position-x));
    }
  }

  @at-root &--#{$placement-top-end} {
    #{$tooltip}__arrow {
      transform: translate(50%, 50%);
    }
  }

  @at-root &--#{$placement-bottom-end} {
    #{$tooltip}__arrow {
      transform: translate(50%, -50%);
    }
  }

  @at-root &--#{$placement-right} {
    #{$tooltip}__arrow {
      border-right: 12px solid var(--gray-900);
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%);
    }
  }

  @at-root &--#{$placement-left} {
    #{$tooltip}__arrow {
      border-left: 12px solid var(--gray-900);
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      top: 50%;
      right: 0;
      transform: translate(50%, -50%);
    }
  }
}
</style>
