export default {
  updateSelectedSvgElement(state, svgElement) {
    state.selectedSvgElement = svgElement
  },
  updateSvgElementToPreview(state, svgElement) {
    state.svgElementToPreview = svgElement
  },
  updateHidePreviewDelay(state, delay) {
    state.hidePreviewDelay = delay
  },
  updateSvgWidth(state, width) {
    state.svgWidth = width
  },
  updateSvgHeight(state, height) {
    state.svgHeight = height
  },
  updatePreviewPositionX(state, positionX) {
    state.previewPositionX = positionX
  },
  updatePreviewPositionY(state, positionY) {
    state.previewPositionY = positionY
  },
}
