<template>
  <table class="quoter-result-installments-table">
    <thead>
      <tr>
        <td>{{ $t('Pago') }}</td>
        <td>{{ $t('Fecha') }}</td>
        <td>{{ $t('Monto') }}</td>
      </tr>
    </thead>
    <tr
      v-for="installment in quoteInstallmentsTableData"
      :key="installment.number"
    >
      <td>{{ installment.number }}</td>
      <td>{{ installment.date }}</td>
      <td>{{ installment.amount }}</td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'AppQuoterResultInstallmentsTable',
  props: {
    quoteInstallmentsTableData: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.quoter-result-installments-table {
  width: 100%;
  margin-bottom: 0.625rem;

  td,
  tr {
    font-size: 0.875rem;
    color: var(--gray-700);
  }

  tr {
    border-bottom: 1px solid var(--gray-100);
  }

  td {
    padding: 0.625rem 0.3125rem;
    text-align: center;
  }

  td:last-child {
    text-align: right;
  }

  td:first-child {
    text-align: left;
  }

  table thead tr td {
    font-weight: var(--font-weight-medium);
    text-align: center;
  }

  tr:nth-child(odd):not(:only-child) td {
    background-color: var(--gray-100);
  }
}
</style>
