<template>
  <div
    ref="wrapper"
    class="tooltip__wrapper"
    :class="{ 'tooltip__wrapper--fixed': fixed }"
  >
    <slot></slot>
    <AppTooltipElement
      class="tooltip__element"
      :class="placementClassName"
      :text="text"
      :placement="placement"
      :arrow-position-x="arrowPositionX"
    />
  </div>
</template>

<script>
import AppTooltipElement from '@/components/UIKit/Standard/Atoms/AppTooltipElement'
import tooltipPlacements from '@/constants/ui-kit/standard/tooltip-placements'

export default {
  name: 'Tooltip',
  components: {
    AppTooltipElement,
  },
  props: {
    text: {
      type: String,
      required: true,
      validator: (value) => value.trim() !== '',
    },
    placement: {
      type: String,
      default: tooltipPlacements.TOP,
      validator: (value) => Object.values(tooltipPlacements).includes(value),
    },
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      arrowPositionX: 0,
    }
  },
  computed: {
    placementClassName() {
      return `tooltip__element--${this.placement}`
    },
  },
  mounted() {
    this.arrowPositionX = this.$refs.wrapper.offsetWidth / 2
  },
}
</script>

<style lang="scss" scoped>
.tooltip {
  $tooltip: &;

  @at-root &__wrapper {
    position: relative;
    display: inline-block;

    &--fixed,
    &:hover {
      #{$tooltip}__element {
        visibility: visible;
        opacity: 1;
        transition: opacity 150ms ease-out;
      }
    }
  }

  @at-root &__element {
    position: absolute;
    visibility: hidden;
    opacity: 0;

    @include small-screens {
      display: none;
    }

    @at-root &--#{$placement-top} {
      transform: translate(-50%, -100%);
      top: -9px;
      left: 50%;
    }

    @at-root &--#{$placement-top-start} {
      transform: translate(0, -100%);
      top: -9px;
      left: 0;
    }

    @at-root &--#{$placement-top-end} {
      transform: translate(0, -100%);
      top: -9px;
      right: 0;
    }

    @at-root &--#{$placement-right} {
      transform: translate(100%, -50%);
      top: 50%;
      right: -9px;
    }

    @at-root &--#{$placement-bottom} {
      transform: translate(-50%, 100%);
      bottom: -9px;
      left: 50%;
    }

    @at-root &--#{$placement-bottom-start} {
      transform: translate(0, 100%);
      bottom: -9px;
      left: 0;
    }

    @at-root &--#{$placement-bottom-end} {
      transform: translate(0, 100%);
      bottom: -9px;
      right: 0;
    }

    @at-root &--#{$placement-left} {
      transform: translate(-100%, -50%);
      top: 50%;
      left: -9px;
    }
  }
}
</style>
