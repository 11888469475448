<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="iconSize"
    :height="iconSize"
    viewBox="0 0 25 25"
    role="presentation"
    class="icon"
  >
    <component :is="icon" :style="`stroke-width: ${strokeWidth};`" />
  </svg>
</template>

<script>
import sizes from '@/constants/ui-kit/standard/sizes'
import iconNames from '@/constants/ui-kit/standard/icon-names'
import icons from '@/constants/ui-kit/standard/icons'

export default {
  name: 'AppIcon',
  components: icons,
  props: {
    icon: {
      type: String,
      required: true,
      validator: (value) => Object.values(iconNames).includes(value),
    },
    size: {
      type: String,
      default: sizes.SM,
      validator: (value) => Object.values(sizes).includes(value),
    },
  },
  computed: {
    iconSize() {
      const sizeNameValueMap = {
        [sizes.XS]: 16,
        [sizes.SM]: 18,
        [sizes.MD]: 20,
        [sizes.LG]: 22,
        [sizes.XL]: 27,
      }
      return sizeNameValueMap[this.size]
    },
    strokeWidth() {
      const baseSize = 25
      const inverseProportion = baseSize / this.iconSize
      const baseSizeNameValueMap = {
        [sizes.XS]: 1.5,
        [sizes.SM]: 1.5,
        [sizes.MD]: 1.5,
        [sizes.LG]: 1.5,
        [sizes.XL]: 2,
      }
      const effectiveSizeNameValueMap = {
        [sizes.XS]: inverseProportion * baseSizeNameValueMap[sizes.XS],
        [sizes.SM]: inverseProportion * baseSizeNameValueMap[sizes.SM],
        [sizes.MD]: inverseProportion * baseSizeNameValueMap[sizes.MD],
        [sizes.LG]: inverseProportion * baseSizeNameValueMap[sizes.LG],
        [sizes.XL]: inverseProportion * baseSizeNameValueMap[sizes.XL],
      }
      return effectiveSizeNameValueMap[this.size]
    },
  },
}
</script>

<style lang="scss" scoped>
.icon {
  fill: var(--gray-600);
  stroke: var(--gray-600);
  &::v-deep {
    .stroke {
      stroke: inherit;
    }
    .no-stroke {
      stroke: transparent;
    }
    .fill {
      fill: inherit;
    }
    .no-fill {
      fill: transparent;
    }
  }
}
</style>
