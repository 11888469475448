<template>
  <button
    class="button-text"
    :class="[styleClassName, sizeClassName]"
    :disabled="disabled"
  >
    <span class="button-text__text">{{ text }}</span>
  </button>
</template>

<script>
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'

export default {
  name: 'AppButtonText',
  props: {
    text: {
      type: String,
      required: true,
    },
    buttonStyle: {
      type: String,
      default: styles.PRIMARY,
      validator: (value) => Object.values(styles).includes(value),
    },
    size: {
      type: String,
      default: sizes.SM,
      validator: (value) => Object.values(sizes).includes(value),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    styleClassName() {
      return `button-text--${this.buttonStyle}`
    },
    sizeClassName() {
      return `button-text--${this.size}`
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'app-button.mixin';
.button-text {
  @include button-styles;
  @include button-text-sizes;
  @include button-alternatives;
  @include noselect;
}
</style>
