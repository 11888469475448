<template>
  <AppSheetUnitMediumAmenities
    :title="unitShortIdentifier"
    :subtitle="subtitleText"
    :enter-to="unitEnterToLink"
    :tour360-to="unitTour360ToLink"
    :features="unitFeatures"
  />
</template>

<script>
import Unit from '@/classes/ProjectStructure/Unit/Unit'
import AppSheetUnitMediumAmenities from '@/components/UIKit/Standard/Organisms/AppSheet/AppSheetUnitMediumAmenities'
import UnitAccessRouterLinks from '@/classes/UnitAccessRouterLinks'
import { mapGetters } from 'vuex'

export default {
  name: 'UnitPanelMediumContentAmenities',
  components: {
    AppSheetUnitMediumAmenities,
  },
  props: {
    projectElement: {
      type: Unit,
      default: () => undefined,
    },
    accessLinksEnabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      useTypologyForAmenitiesDescription:
        'Preferences/useTypologyForAmenitiesDescription',
    }),
    subtitleText() {
      if (this.useTypologyForAmenitiesDescription) {
        return this.unit.model
      } else {
        return this.$t('Amenities')
      }
    },
    unit() {
      return this.projectElement
    },
    unitShortIdentifier() {
      return this.unit.shortIdentifier
    },
    unitFeatures() {
      return this.unit.features
    },
    unitAccessRouterLinks() {
      try {
        return new UnitAccessRouterLinks(this.unit, this.$loggingService)
      } catch (error) {
        this.$loggingService.logError(
          `UnitPanelLargeContent.unitAccessRouterLinks: error creating UnitAccessRouterLinks. ${error.toString()}`
        )
        return null
      }
    },
    unitEnterToLink() {
      return this.accessLinksEnabled
        ? this.unitAccessRouterLinks.default
        : undefined
    },
    unitTour360ToLink() {
      return this.accessLinksEnabled
        ? this.unitAccessRouterLinks.tour360
        : undefined
    },
  },
}
</script>
