class AnimationTween {
  constructor(targetElement, finalValuesOfAnimatedAttributes, duration) {
    this.targetElement = targetElement
    this.duration = duration
    this.startTime = 0

    this.finalValues = finalValuesOfAnimatedAttributes
    this.originalValues = {}
    this.distanceValues = {}
    for (let attribute in this.finalValues) {
      this.originalValues[attribute] = this.targetElement[attribute]
      this.distanceValues[attribute] =
        this.finalValues[attribute] - this.originalValues[attribute]
    }
  }

  play() {
    this.startTime = Date.now()
    return new Promise((resolve) => this._step(resolve))
  }

  _step(resolveAnimationPromise) {
    const timeProgress = this._getTimeProgress()
    if (timeProgress <= 1) {
      this._updateValues(timeProgress)
      requestAnimationFrame(() => {
        this._step(resolveAnimationPromise)
      })
    } else {
      this._updateValues(1)
      resolveAnimationPromise()
    }
  }

  _getTimeProgress() {
    const deltaTime = Date.now() - this.startTime
    return deltaTime / this.duration
  }

  _updateValues(timeProgress) {
    for (let attribute in this.distanceValues) {
      this.targetElement[attribute] =
        this.originalValues[attribute] +
        this.distanceValues[attribute] * this._timingFunction(timeProgress)
    }
  }

  _timingFunction(timeProgress) {
    // linear
    return timeProgress
  }
}

class BezierBlendAnimationTween extends AnimationTween {
  _timingFunction(timeProgress) {
    // bezierBlend
    return timeProgress * timeProgress * (3.0 - 2.0 * timeProgress)
  }
}

class ParametricBlendAnimationTween extends AnimationTween {
  _timingFunction(timeProgress) {
    // parametricBlend
    const sqt = timeProgress * timeProgress
    return sqt / (2.0 * (sqt - timeProgress) + 1.0)
  }
}

export {
  BezierBlendAnimationTween,
  ParametricBlendAnimationTween,
  AnimationTween,
}
export default AnimationTween
