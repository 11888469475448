class GalleryPageRouteObjectGenerator {
  static get ROUTE_NAME() {
    return 'gallery'
  }
  static make(customParams) {
    const overridableParams = {
      resourceId: '1',
    }
    return {
      name: GalleryPageRouteObjectGenerator.ROUTE_NAME,
      params: {
        ...overridableParams,
        ...customParams,
      },
    }
  }
}
export default GalleryPageRouteObjectGenerator
