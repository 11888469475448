<template>
  <div id="home">
    <intro-top-bar-controls class="home__top-bar" />
    <transition
      v-if="isIntroVimeoVideoAvailable"
      name="fade"
      @after-leave="redirectToMasterplan"
    >
      <BackgroundVimeoVideoDisplayer
        v-show="showIntroVimeoVideo"
        class="home__vimeo-video-background"
      />
    </transition>
    <section class="bg-green_park">
      <img
        v-if="homeLogo.display"
        class="home__logo"
        :src="homeLogo.imgUrl"
        :style="homeLogo.style"
      />
      <AppButtonText
        class="home__enter-button"
        :style="enterButton.style"
        :text="enterButton.title"
        :button-style="enterButtonStyle"
        :size="enterButtonSize"
        @click.native="handleEnterClick"
      />
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AppButtonText from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonText'
import BackgroundVimeoVideoDisplayer from '@/components/Gadgets/BackgroundVimeoVideoDisplayer'
import IntroTopBarControls from '@/components/ViewTopBarControls/IntroTopBarControls'
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'
import MasterplanRouteObjectGenerator from '@/classes/RouteObjectGenerators/MasterplanRouteObjectGenerator'

export default {
  name: 'Home',
  components: {
    AppButtonText,
    IntroTopBarControls,
    BackgroundVimeoVideoDisplayer,
  },
  data() {
    return {
      videoData: String,
      enterButtonSize: sizes.LG,
      enterButtonStyle: styles.LIGHT,
      showIntroVimeoVideo: true,
    }
  },
  computed: {
    ...mapGetters({
      enterButton: 'Project/enterButton',
      homeLogo: 'Project/homeLogo',
      isIntroVimeoVideoAvailable: 'Project/isIntroVimeoVideoAvailable',
    }),
  },
  methods: {
    handleEnterClick() {
      if (this.isIntroVimeoVideoAvailable) {
        this.showIntroVimeoVideo = false
      } else {
        this.redirectToMasterplan()
      }
    },
    redirectToMasterplan() {
      const masterplanRouteObject = MasterplanRouteObjectGenerator.make({
        useTransition: true,
        hideUIControlsDuringTransition: true,
      })
      this.$router.push(masterplanRouteObject)
    },
  },
}
</script>

<style lang="scss" scoped>
.home {
  @at-root &__top-bar {
    z-index: 5;
  }
  @at-root &__logo {
    pointer-events: none;
    z-index: 10;
  }
  @at-root &__enter-button {
    z-index: 10;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
