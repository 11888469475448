<template>
  <article class="unit-card-body">
    <slot></slot>
  </article>
</template>

<script>
export default {
  name: 'AppCardBody',
}
</script>

<style lang="scss" scoped>
.unit-card-body {
  padding: 12px;
}
</style>
