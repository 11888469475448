<template>
  <component :is="unitPopupContentComponent" :unit="unit"></component>
</template>

<script>
import { UnitTypes } from '@/constants/units'
import Unit from '@/classes/ProjectStructure/Unit/Unit'
import AmenitiesUnitPopupContent from './AmenitiesUnitPopupContent'
import UnitPopupContent from './UnitPopupContent'

export default {
  name: 'UnitPopupContentFactory',
  components: {
    AmenitiesUnitPopupContent,
    UnitPopupContent,
  },
  props: {
    projectElement: {
      type: Unit,
      required: true,
    },
  },
  computed: {
    unit() {
      return this.projectElement
    },
    unitPopupContentComponent() {
      const popupContentsByUnitType = {
        [UnitTypes.AMENITIES]: AmenitiesUnitPopupContent,
        [UnitTypes.APARTMENT]: UnitPopupContent,
        [UnitTypes.OFFICE]: UnitPopupContent,
        [UnitTypes.SHOP]: UnitPopupContent,
        [UnitTypes.HOUSE]: UnitPopupContent,
      }
      return popupContentsByUnitType[this.unit.type]
    },
  },
}
</script>
