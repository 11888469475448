import BasePrice from '@/classes/Price/BasePrice'

class PriceFormatter {
  constructor(locale) {
    if (typeof locale !== 'string' || locale.trim() === '') {
      throw new Error(`PriceFormatter.format(): invalid locale ${locale}`)
    }
    this._locale = locale
  }
  get locale() {
    return this._locale
  }
  format(price) {
    if (!(price instanceof BasePrice)) {
      throw new Error(
        `PriceFormatter.format(): price ${price} is not an instance of class Price`
      )
    }
    return new Intl.NumberFormat(this._locale, {
      style: 'currency',
      currency: price.currencyCode,
      currencyDisplay: 'code',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price.amount)
  }
}
export default PriceFormatter
