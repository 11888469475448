<template>
  <TheTopBar :controls="controls" />
</template>

<script>
import TheTopBar from '@/components/Gadgets/TheTopBar'
import TopBarControlsSuite from '@/classes/TopBarControls/TopBarControlsSuite'

export default {
  name: 'ContactTopBarControls',
  components: {
    TheTopBar,
  },
  computed: {
    controls() {
      return new TopBarControlsSuite({})
    },
  },
}
</script>
