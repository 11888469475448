<template>
  <AppMenuWrapper class="menu">
    <AppMenuRow class="menu__top">
      <div class="menu__controls">
        <AppButtonIcon
          :icon="closeButtonIcon"
          :button-style="closeButtonStyle"
          :size="closeButtonSize"
          @click.native="$emit('close')"
        />
        <AppButtonTextWithIcon
          v-if="previousMenu"
          :icon="backButtonIcon"
          :text="getItemText(previousMenu)"
          :button-style="backButtonStyle"
          :size="backButtonSize"
          reverse
          @click.native="back"
        />
      </div>
    </AppMenuRow>
    <transition-group class="menu__transition" :name="transitionName" tag="div">
      <AppMenuMain
        v-if="isMainMenu"
        :key="currentMenu.name"
        class="menu__layout"
        :items="currentMenu.children"
        :logo-image-url="logoImageUrl"
        :facebook-url="facebookUrl"
        :instagram-url="instagramUrl"
        :whatsapp-url="whatsappUrl"
        :developer-website-link="developerWebsiteLink"
        :developer-brochure-url="developerBrochureUrl"
        :with-copyright="withCopyright"
        :copyright-with-link="copyrightWithLink"
        :limited-connection="limitedConnection"
        @request-menu="openMenu"
      />
      <AppMenuSubmenu
        v-else
        :key="currentMenu.name"
        :items="currentMenu.children"
        :submenu-title="getItemText(currentMenu)"
        :submenu-icon="currentMenu.icon"
        :limited-connection="limitedConnection"
        @request-menu="openMenu"
      />
    </transition-group>
  </AppMenuWrapper>
</template>

<script>
import AppMenuWrapper from '@/components/UIKit/Standard/Atoms/AppMenuWrapper'
import AppMenuRow from '@/components/UIKit/Standard/Atoms/AppMenuRow'
import AppButtonIcon from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonIcon'
import AppButtonTextWithIcon from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonTextWithIcon'
import AppMenuMain from '@/components/UIKit/Standard/Organisms/AppMenuMain'
import AppMenuSubmenu from '@/components/UIKit/Standard/Organisms/AppMenuSubmenu'
import Menu from '@/classes/Navigation/Menu'
import iconNames from '@/constants/ui-kit/standard/icon-names'
import styles from '@/constants/ui-kit/standard/styles'
import sizes from '@/constants/ui-kit/standard/sizes'

export default {
  name: 'AppMenu',
  components: {
    AppMenuWrapper,
    AppMenuRow,
    AppButtonIcon,
    AppButtonTextWithIcon,
    AppMenuMain,
    AppMenuSubmenu,
  },
  props: {
    menu: {
      type: Menu,
      required: true,
    },
    logoImageUrl: {
      type: String,
      default: () => undefined,
    },
    facebookUrl: {
      type: String,
      default: () => undefined,
    },
    instagramUrl: {
      type: String,
      default: () => undefined,
    },
    whatsappUrl: {
      type: String,
      default: () => undefined,
    },
    developerWebsiteLink: {
      type: Object,
      default: () => undefined,
      validator: (value) => {
        return !!value.url && !!value.displayText
      },
    },
    developerBrochureUrl: {
      type: String,
      default: undefined,
    },
    withCopyright: {
      type: Boolean,
      default: false,
    },
    copyrightWithLink: {
      type: Boolean,
      default: false,
    },
    limitedConnection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      closeButtonStyle: styles.LINK,
      closeButtonSize: sizes.XS,
      closeButtonIcon: iconNames.UIClose,
      backButtonStyle: styles.LIGHT,
      backButtonSize: sizes.XS,
      backButtonIcon: iconNames.UIBack,
      transitionNameIn: 'menu__transition--in',
      transitionNameOut: 'menu__transition--out',
      transitionName: this.transitionNameIn,
      menuHistory: [],
      currentMenu: undefined,
    }
  },
  computed: {
    isMainMenu() {
      return this.currentMenu === this.menu
    },
    previousMenu() {
      if (this.menuHistory.length === 0) {
        return undefined
      }
      return this.menuHistory[this.menuHistory.length - 1]
    },
  },
  created() {
    this.currentMenu = this.menu
  },
  methods: {
    openMenu(menu) {
      if (!(menu instanceof Menu)) {
        throw new Error(`AppMenu.openMenu(): Invalid menu ${menu}.`)
      }
      if (this.currentMenu) {
        this.menuHistory.push(this.currentMenu)
      }
      this.currentMenu = menu
      this.transitionName = this.transitionNameIn
    },
    back() {
      if (this.menuHistory.length > 0) {
        this.currentMenu = this.menuHistory.pop()
        this.transitionName = this.transitionNameOut
      }
    },
    getItemText(item) {
      if (item === this.menu) {
        return this.$t('Menú principal')
      } else {
        return item.nameTranslationKey
          ? this.$t(item.nameTranslationKey)
          : item.name
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.menu {
  min-height: 100%;
  padding-bottom: 0.9375rem;

  @at-root &__top {
    background: #fff;
    padding-top: 0.9375rem;
    position: sticky;
    top: 0;
    z-index: 2;
  }

  @at-root &__controls {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  @at-root &__layout {
    flex: 1;
  }

  @at-root &__transition {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;

    @at-root &--in {
      @at-root &-enter-active,
        &-leave-active {
        transition: opacity 200ms ease-out, transform 200ms ease-out;
        pointer-events: none;
      }
      @at-root &-enter {
        opacity: 0;
        transform: translateX(150px);
      }
      @at-root &-enter-to {
        transition-delay: 100ms;
      }
      @at-root &-leave,
        &-leave-to {
        position: absolute;
        inset: 0;
        opacity: 0;
        transform: translateX(-150px);
      }
    }
    @at-root &--out {
      @at-root &-enter-active,
        &-leave-active {
        transition: opacity 200ms ease-out, transform 200ms ease-out;
        pointer-events: none;
      }
      @at-root &-enter {
        opacity: 0;
        transform: translateX(-150px);
      }
      @at-root &-enter-to {
        transition-delay: 100ms;
      }
      @at-root &-leave-to {
        position: absolute;
        width: 100%;
        opacity: 0;
        transform: translateX(150px);
      }
    }
  }
}
</style>
