export default {
  setModalComponent(state, component) {
    state.modalComponent = component
  },
  setModalProperties(state, properties) {
    state.modalProperties = properties || {}
  },
  setModalCloseCallback(state, callback) {
    state.modalCloseCallback = callback
  },
  launchModal(state) {
    state.isModalLaunched = true
  },
  setModalStateToInitialValues(state) {
    state.isModalLaunched = false
    state.modalProperties = {}
    state.modalComponent = undefined
    state.modalCloseCallback = undefined
  },
}
