import ProjectElementTypes from '@/constants/project-structure/project-element-types'
import BaseProjectElement from '@/classes/ProjectStructure/BaseProjectElement'
import EnterTransitionVideos from '@/classes/EnterTransitionVideos'

export default class NeighbourhoodSubdivision extends BaseProjectElement {
  constructor({
    id,
    order,
    svgTargetElementId,
    name,
    slug,
    exteriorTour360Url,
    image,
    svg,
  }) {
    super({ id, svgTargetElementId })
    this._order = order
    this._name = name
    this._slug = slug
    this._image = image
    this._svg = svg
    this._exteriorTour360Url = exteriorTour360Url
    this._enterTransitionVideos = new EnterTransitionVideos()
    this.initSvgData()
  }
  get order() {
    return this._order
  }
  get name() {
    return this._name
  }
  get image() {
    return this._image
  }
  get svg() {
    return this._svg
  }
  get slug() {
    return this._slug
  }
  get enterTransitionVideos() {
    return this._enterTransitionVideos
  }
  get exteriorTour360Url() {
    return this._exteriorTour360Url
  }
  get projectElementType() {
    return ProjectElementTypes.NEIGHBOURHOOD_SUBDIVISION
  }
  get _validChildElementTypes() {
    return [ProjectElementTypes.UNIT, ProjectElementTypes.POINT_OF_INTEREST]
  }
}
