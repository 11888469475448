<template>
  <div
    class="menu-row"
    :class="{
      'menu-row--divider--top': dividerTop,
      'menu-row--divider--bottom': dividerBottom,
      'menu-row--grow': grow,
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'AppMenuRow',
  props: {
    dividerTop: {
      type: Boolean,
      value: false,
    },
    dividerBottom: {
      type: Boolean,
      value: false,
    },
    grow: {
      type: Boolean,
      value: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.menu-row {
  padding: 0 1.25rem;

  @at-root &--divider--top {
    padding-top: 1rem;
    border-top: 1px solid var(--gray-100);
  }
  @at-root &--divider--bottom:not(:last-child) {
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--gray-100);
  }

  @at-root &--divider--bottom + &,
    &--separator--bottom + & {
    padding-top: unset;
    border-top: unset;
    &::before {
      content: unset;
    }
  }

  @at-root &--grow {
    flex-grow: 1;
  }
}
</style>
