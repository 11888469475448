var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.backgroundImage)?_c('div',{staticClass:"the-background-image-viewer"},[_c('SpriteClip',{staticClass:"the-background-image-viewer__layer",attrs:{"image-url":_vm.backgroundImageUrl,"blured":true}}),_c('img',{ref:"backgroundImageElement",staticClass:"the-background-image-viewer__layer",class:{
      'the-background-image-viewer__layer--ready': _vm.isBackgroundImageReady,
      'the-background-image-viewer__layer--fade-in-on-ready': _vm.useBackgroundImageFadeEffectOnReady,
    },attrs:{"src":_vm.backgroundImageUrl},on:{"load":_vm.updateBackgroundImageReady}}),(_vm.animationLayerVideoUrl)?_c('video',{staticClass:"the-background-image-viewer__layer",class:{
      'the-background-image-viewer__layer--ready': _vm.isAnimationVideoReady,
      'the-background-image-viewer__layer--fade-in-on-ready': _vm.useBackgroundImageFadeEffectOnReady,
    },attrs:{"src":_vm.animationLayerVideoUrl,"muted":"","autoplay":"","loop":""},domProps:{"muted":true},on:{"play":function($event){_vm.isAnimationVideoReady = true}}}):_vm._e(),(_vm.svgLayerUrl)?_c('svg-layer-factory',{directives:[{name:"show",rawName:"v-show",value:(_vm.isBackgroundImageReady),expression:"isBackgroundImageReady"}],attrs:{"svg-url":_vm.svgLayerUrl}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }