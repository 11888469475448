import { render, staticRenderFns } from "./AppImage.vue?vue&type=template&id=275cf368&scoped=true"
import script from "./AppImage.vue?vue&type=script&lang=js"
export * from "./AppImage.vue?vue&type=script&lang=js"
import style0 from "./AppImage.vue?vue&type=style&index=0&id=275cf368&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "275cf368",
  null
  
)

export default component.exports