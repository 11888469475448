<template>
  <section class="container">
    <h1>AppBadge</h1>
    <table>
      <tr>
        <th><AppBadge text="Disponible" color="#039855" /></th>
        <th><AppBadge text="Reservado" color="#DC6803" /></th>
        <th><AppBadge text="Vendido" color="#D92D20" /></th>
      </tr>
    </table>
  </section>
</template>

<script>
import AppBadge from '@/components/UIKit/Standard/Atoms/AppBadge'

export default {
  name: 'BadgeViewer',
  components: {
    AppBadge,
  },
}
</script>

<style lang="scss" scoped>
.container {
  margin: 80px 50px;
}
h1 {
  font-size: 1.5rem;
  margin: 2.5em 0 1.5em;
  border-bottom: 1px solid black;
}
th {
  font-weight: 400;
  font-size: 0.75rem;
}
th,
td {
  padding: 5px 10px;
}
</style>
