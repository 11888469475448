<template>
  <SvgInteractionsListener :svg-url="svgUrl" :svg-data="svgData" />
</template>
<script>
import { mapGetters } from 'vuex'
import SvgInteractionsListener from '@/components/Gadgets/SvgInteractionsListener'

export default {
  name: 'MasterplanInteractiveSvgLayer',
  components: {
    SvgInteractionsListener,
  },
  props: {
    svgUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      projectSvgData: 'Topview/projectSvgData',
    }),
    svgData() {
      return this.projectSvgData
    },
  },
}
</script>
