<template>
  <TopBarControlWrapper>
    <AppButtonText
      :text="buttonText"
      :button-style="buttonStyle"
      :size="size"
      @click.native="$emit('button-click')"
    />
  </TopBarControlWrapper>
</template>

<script>
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'
import TopBarControlWrapper from '@/components/Gadgets/TheTopBar/snippets/TopBarControlWrapper'
import AppButtonText from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonText'

export default {
  name: 'TopBarButtonText',
  components: {
    AppButtonText,
    TopBarControlWrapper,
  },
  props: {
    buttonText: {
      type: String,
      default: '',
    },
    buttonStyle: {
      type: String,
      default: styles.DARK,
      validator: (value) => Object.values(styles).includes(value),
    },
    size: {
      type: String,
      default: sizes.SM,
      validator: (value) => Object.values(sizes).includes(value),
    },
  },
}
</script>
