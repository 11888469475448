export default {
  isQuoterModuleEnabled: (_state, _getters, _rootState, rootGetters) => {
    return rootGetters['Preferences/enableUnitPriceQuotation']
  },
  paymentMethods: (state, getters) => {
    if (getters.isQuoterModuleEnabled && state.paymentMethods.length > 0) {
      return state.paymentMethods
    }
    return null
  },
  getUnitAvailablePaymentMethods: (_state, getters) => (unit) => {
    return getters.paymentMethods.filter(
      (paymentMethod) => !!getters.getUnitQuotePrice(unit, paymentMethod.id)
    )
  },
  getUnitQuotePrice: (_state, _getters, _rootState, rootGetters) => (
    unit,
    paymentMethodId
  ) => {
    const pricesFeatureFlagEnabled =
      rootGetters['FeatureFlags/pricesFeatureFlagEnabled']
    if (pricesFeatureFlagEnabled) {
      return unit.getQuotePrice(paymentMethodId)
    }
    return null
  },
  canUnitBeQuoted: (_state, getters) => (unit) => {
    if (getters.isQuoterModuleEnabled) {
      const isThereAnyPaymentMethodAvailable = !!getters.paymentMethods
      return unit.hasPricesForQuotes && isThereAnyPaymentMethodAvailable
    }
    return false
  },
  unitToQuote: (state) => state.unitToQuote,
}
