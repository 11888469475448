import * as preferenceNames from '@/constants/preferences/preference-names'

export default {
  [preferenceNames.ENABLE_CHECK_PRICE_FORMS]: [
    preferenceNames.ENABLE_CONTACT_FORMS,
  ],
  [preferenceNames.ENABLE_UNIT_PRICE_QUOTATION]: [
    preferenceNames.DISPLAY_PRICE,
    preferenceNames.ENABLE_CONTACT_FORMS,
  ],
}
