import { defaultZoomStep } from '@/constants/zoom-tracker'

export default () => ({
  imageZoom: defaultZoomStep,
  imagePositionX: 0,
  imagePositionY: 0,

  gestureDragActive: false,
  gesturePinchOrSpreadActive: false,
})
