import { featureIconMap } from '@/constants/features/feature-icon-map'
import iconComponents from '@/constants/ui-kit/standard/icons'

class Feature {
  constructor({ id, value, valueType, showInSmallPopup }, i18n) {
    this._id = id
    this._value = value
    this._valueType = valueType
    this._showInSmallPopup = showInSmallPopup
    this._shortTextLocaleKey = `features.${this._id}.shortText`
    this._longTextLocaleKey = `features.${this._id}.longText`

    const { icon, shortTextEmpty } = this._getMappedFeatureData(id)
    this._validateIconIsValid(icon)
    this._validateShortTextEmptyIsValid(shortTextEmpty)
    this._validateI18nIsValid(i18n)
    this._validateTranslationsExist(i18n)

    this._icon = icon
    this._shortTextEmpty = shortTextEmpty
    this._i18n = i18n
  }
  _getMappedFeatureData(id) {
    if (typeof featureIconMap[id] !== 'object') {
      throw new Error(
        `Feature Id ${id} is not mapped or value is not of type object`
      )
    }
    return {
      icon: featureIconMap[id].icon,
      shortTextEmpty: featureIconMap[id].shortTextEmpty,
    }
  }
  _validateIconIsValid(icon) {
    if (typeof icon !== 'string' || icon.trim() === '') {
      throw new Error(`Icon ${icon} is not a valid string`)
    }
    if (!iconComponents[icon]) {
      throw new Error(
        `Icon ${icon} is not defined in the IconName => Component map`
      )
    }
  }
  _validateShortTextEmptyIsValid(shortTextEmpty) {
    if (typeof shortTextEmpty !== 'boolean') {
      throw new Error(`shortTextEmpty ${shortTextEmpty} is not a valid boolean`)
    }
  }
  _validateI18nIsValid(i18n) {
    if (
      typeof i18n !== 'object' ||
      typeof i18n.t !== 'function' ||
      typeof i18n.te !== 'function'
    ) {
      throw new Error(
        'i18n passed is not an object or it does not have t functions'
      )
    }
  }
  _validateTranslationsExist(i18n) {
    if (!i18n.te(this._shortTextLocaleKey)) {
      throw new Error(
        `shortText translation is missing (${this._shortTextLocaleKey})`
      )
    }
    if (!i18n.te(this._longTextLocaleKey)) {
      throw new Error(
        `longText translation is missing (${this._longTextLocaleKey})`
      )
    }
  }
  get id() {
    return this._id
  }
  get value() {
    return this._value
  }
  get valueType() {
    return this._valueType
  }
  get showInSmallPopup() {
    return this._showInSmallPopup
  }
  get icon() {
    return this._icon
  }
  get shortTextEmpty() {
    return this._shortTextEmpty
  }
  get shortText() {
    if (this._shortTextEmpty) {
      return ''
    } else {
      return this._i18n.t(this._shortTextLocaleKey, { n: this._value })
    }
  }
  get longText() {
    return this._i18n.t(this._longTextLocaleKey, { n: this._value })
  }
}
export default Feature
