import BasePrice from './BasePrice'
import PriceError from '@/classes/Exceptions/PriceError'
import rentPaymentPeriods from '@/constants/price/rent-payment-periods'

class RentalPrice extends BasePrice {
  constructor({ amount, currencyCode, rentPaymentPeriod }) {
    super({ amount, currencyCode, rentPaymentPeriod })
    this._className = 'RentalPrice'
    this._rentPaymentPeriod = rentPaymentPeriod

    if (!Object.values(rentPaymentPeriods).includes(this._rentPaymentPeriod)) {
      throw new PriceError(
        `Invalid rentPaymentPeriod: ${this._rentPaymentPeriod}.`,
        this
      )
    }
  }
  get rentPaymentPeriod() {
    return this._rentPaymentPeriod
  }
  toJSON() {
    const { amount, currencyCode, rentPaymentPeriod } = this
    return {
      amount,
      currencyCode,
      rentPaymentPeriod,
    }
  }
}
export default RentalPrice
