import { UnitTypes } from '@/constants/units'
import ApartmentUnit from '@/classes/ProjectStructure/Unit/ApartmentUnit'
import AmenitiesUnit from '@/classes/ProjectStructure/Unit/AmenitiesUnit'
import OfficeUnit from '@/classes/ProjectStructure/Unit/OfficeUnit'
import ShopUnit from '@/classes/ProjectStructure/Unit/ShopUnit'
import HouseUnit from '@/classes/ProjectStructure/Unit/HouseUnit'

export default class UnitFactory {
  constructor() {
    this.availableUnitTypes = {
      [UnitTypes.AMENITIES]: AmenitiesUnit,
      [UnitTypes.APARTMENT]: ApartmentUnit,
      [UnitTypes.OFFICE]: OfficeUnit,
      [UnitTypes.SHOP]: ShopUnit,
      [UnitTypes.HOUSE]: HouseUnit,
    }
  }
  makeUnit(unitData, unitType) {
    const UnitClassToUse = this.availableUnitTypes[unitType]
    try {
      return new UnitClassToUse(unitData)
    } catch (error) {
      throw new Error(
        `UnitFactory: There was a problem creating Unit ${UnitClassToUse} of unitType ${unitType}: ${error}`
      )
    }
  }
}
