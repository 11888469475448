<template>
  <section class="container">
    <h1>AppSheetContactInformation</h1>
    <div class="wrapper">
      <AppSheetContactInformation :contact-data="contactData" />
    </div>
  </section>
</template>
<script>
import AppSheetContactInformation from '@/components/UIKit/Standard/Molecules/AppSheetContactInformation'
import { mapGetters } from 'vuex'

export default {
  name: 'AppSheetContactInformationViewer',
  components: {
    AppSheetContactInformation,
  },
  computed: {
    ...mapGetters({
      contactData: 'Project/contactData',
    }),
  },
}
</script>
<style lang="scss" scoped>
.container {
  margin: 80px 50px;
  .wrapper {
    margin: 50px;
  }
}
h1 {
  font-size: 1.5rem;
  margin: 2.5em 0 1.5em;
  border-bottom: 1px solid black;
}
</style>
