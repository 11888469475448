<template>
  <div class="icon-group">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'AppIconGroup',
}
</script>
<style lang="scss" scoped>
.icon-group {
  display: flex;
  border-radius: 12px;
  width: fit-content;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid var(--gray-300);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @include noselect;
}
</style>
