import VueDependency from '@/classes/VueDependency'
import Vuex from 'vuex'
import storeOptions from '@/store'

class VuexStoreVueDependency extends VueDependency {
  constructor({ projectData, availabilityData, i18n, isOfflineModeEnabled }) {
    super()
    this._store = null
    this._projectData = projectData
    this._availabilityData = availabilityData
    this._i18n = i18n
    this._isOfflineModeEnabled = isOfflineModeEnabled
  }
  // eslint-disable-next-line no-unused-vars
  async install(Vue, router) {
    this.loggingService = Vue.prototype.$loggingService
    Vue.use(Vuex)

    const store = new Vuex.Store(storeOptions)
    store.$loggingService = this.loggingService
    /* Acá rompemos encapsulamiento, pero estamos siguiendo el patrón de plugins de Vue
       Probablemente sea mejor diseño immplementar este efecto con un plugin de Vuex
       https://graff3d.atlassian.net/browse/W3D-2482
     */
    this._store = store

    await this._populateStore()
  }
  get vueGlobalKeyValue() {
    return {
      key: 'store',
      value: this._store,
    }
  }
  get store() {
    return this._store
  }
  async _populateStore() {
    if (this._projectData) {
      try {
        await this._store.dispatch('adapterProjectJsonData', {
          projectData: this._projectData,
          i18n: this._i18n,
          isOfflineModeEnabled: this._isOfflineModeEnabled,
        })
      } catch (err) {
        this.loggingService.logError(
          `There was a problem adapting projectData in store: ${err.toString()}`
        )
      }
    }
    if (this._availabilityData) {
      try {
        await this._store.dispatch(
          'Topview/adapterAvailabilityData',
          this._availabilityData
        )
        await this._store.dispatch(
          'QuoterModule/adapterData',
          this._availabilityData
        )
      } catch (err) {
        this.loggingService.logError(
          `There was a problem adapting availabilityData in store: ${err.toString()}`
        )
      }
    }
  }
}
export default VuexStoreVueDependency
