import VueDependency from '@/classes/VueDependency'
import * as Sentry from '@sentry/vue'
import LoggingServiceFactory from '@/classes/LoggingService/LoggingServiceFactory'

class SentryVueDependency extends VueDependency {
  // eslint-disable-next-line no-unused-vars
  async install(Vue, router) {
    if (process.env.VUE_APP_SENTRY_DSN !== '') {
      Sentry.init({
        Vue,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        environment: process.env.VUE_APP_ENV,
        integrations: [
          Sentry.browserTracingIntegration({ router }),
          Sentry.replayIntegration(),
        ],
        tracesSampleRate: Number.parseFloat(
          process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE
        ),
        tracingOptions: {
          trackComponents: true,
        },
        denyUrls: [/^chrome-extension:\/\//i],
        // Vue specific
        logErrors: process.env.VUE_APP_SENTRY_LOG_ERRORS === 'true',
        attachProps: true,
      })
    } else {
      const loggingService = new LoggingServiceFactory().makeService()
      loggingService.logInfo('Sentry was not configured')
    }
  }
  get vueGlobalKeyValue() {
    return null
  }
}
export default SentryVueDependency
