<template>
  <div>
    <button @click="openOverlay">Open Overlay</button>
    <AppOverlay v-if="isOverlayOpen" />
    <button
      v-if="isOverlayOpen"
      class="close-overlay-button"
      @click="closeOverlay"
    >
      Close Overlay
    </button>
  </div>
</template>

<script>
import AppOverlay from '@/components/UIKit/Standard/Atoms/AppOverlay'

export default {
  name: 'AppOverlayViewer',
  components: {
    AppOverlay,
  },
  data() {
    return {
      isOverlayOpen: false,
    }
  },
  methods: {
    openOverlay() {
      this.isOverlayOpen = true
    },
    closeOverlay() {
      this.isOverlayOpen = false
    },
  },
}
</script>
<style lang="scss" scoped>
.close-overlay-button {
  position: fixed;
  top: 1rem;
  right: 1rem;
}
</style>
