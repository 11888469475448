import NeighbourhoodSubdivision from '@/classes/ProjectStructure/NeighbourhoodSubdivision'
import SlugGenerator from '@/classes/SlugGenerator'

class NeighbourhoodSubdivisionRouteObjectGenerator {
  static get ROUTE_NAME() {
    return 'neighbourhood-subdivision'
  }
  static make(neighbourhoodSubdivision, customParams) {
    if (!(neighbourhoodSubdivision instanceof NeighbourhoodSubdivision)) {
      throw new Error(
        'NeighbourhoodSubdivisionRouteObjectGenerator.make(): passed neighbourhoodSubdivision argument is not an instance of NeighbourhoodSubdivision class.'
      )
    }
    return {
      name: NeighbourhoodSubdivisionRouteObjectGenerator.ROUTE_NAME,
      params: {
        ...customParams,
        descriptiveSlug: SlugGenerator.make(neighbourhoodSubdivision),
        neighbourhoodSubdivisionId: neighbourhoodSubdivision.id,
      },
    }
  }
}
export default NeighbourhoodSubdivisionRouteObjectGenerator
