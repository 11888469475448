<template>
  <div class="contact__wrapper">
    <ContactTopBarControls class="contact__top-controls" />
    <div v-if="!isSmallScreen" class="contact__empty-section">
      <div
        v-if="isContactPageEmbeddedFormAvailable"
        class="contact__empty-section__embedded-form-wrapper"
        :style="{ height: mainIframeFormWrapperHeight }"
      >
        <AppScrollbarContainer
          class="contact__empty-section__embedded-form-wrapper__scroll-bar"
        >
          <IframeFormWrapper
            ref="mainIframeFormWrapper"
            :form-url="contactPageFormUrl"
            @resize="handleMainIframeResize"
          />
        </AppScrollbarContainer>
      </div>
    </div>
    <AppScrollbarContainer class="contact">
      <div
        class="contact__panel"
        :class="{ 'contact__panel--with-form': enableContactForms }"
      >
        <div class="contact__panel__block contact__panel__block--info">
          <h1>{{ $t('Contacto') }}</h1>
          <div class="info">
            <ul>
              <li v-if="contactData.isWhatsappContactDataAvailable">
                <a :href="contactData.whatsAppUrl">
                  <AppIcon :icon="iconWhatsapp" :size="contactIconSize" />
                  {{
                    $t('contact.whatsAppLinkText', {
                      whatsAppNumber: contactData.whatsAppText,
                    })
                  }}
                </a>
              </li>
              <li v-if="contactData.phoneUrl">
                <a :href="contactData.phoneUrl">
                  <AppIcon :icon="iconPhone" :size="contactIconSize" />
                  {{ contactData.phoneText }}
                </a>
              </li>
              <li v-if="contactData.emailUrl">
                <a :href="contactData.emailUrl">
                  <AppIcon :icon="iconEmail" :size="contactIconSize" />
                  {{ contactData.emailText }}
                </a>
              </li>
              <li v-if="developerWebsiteLink">
                <a :href="developerWebsiteLink.url" target="_blank">
                  <AppIcon :icon="iconWebsite" :size="contactIconSize" />
                  {{ developerWebsiteLink.displayText }}
                </a>
              </li>
            </ul>
            <app-sheet-contact-additional-details
              :html-content="contactData.contactHtmlAdditionalDetails"
            />
          </div>
        </div>
        <div
          v-if="isSmallScreen && isContactPageEmbeddedFormAvailable"
          class="contact__panel__block contact__panel__block--embedded-form"
        >
          <IframeFormWrapper :form-url="contactPageFormUrl" />
        </div>
        <div
          v-if="enableContactForms"
          class="contact__panel__block contact__panel__block--form"
        >
          <h2>{{ $t('¿Tienes alguna consulta?') }}</h2>
          <ContactForm
            class="form"
            @showPrivacyPolicy="showPrivacyPolicy"
            @submit="showConfirmationMessage"
          />
        </div>
        <div class="contact__panel__block contact__panel__block--about">
          <div class="contact__panel__block__footer">
            <h3>{{ contactData.projectName }}</h3>
            <p>{{ contactData.projectDescription }}</p>
            <p>
              <router-link :to="{ name: 'location' }">{{
                $t('Ver mapa')
              }}</router-link>
            </p>
          </div>
          <div
            v-if="contactData.isDeveloperInfoAvailable"
            class="contact__panel__block__footer"
          >
            <h3>{{ contactData.developerName }}</h3>
            <p>{{ contactData.developerDescription }}</p>
            <p>
              <a :href="contactData.developerMapUrl" target="_blank">{{
                $t('Ver mapa')
              }}</a>
            </p>
          </div>
          <div v-if="shouldDisplaySocialLinks">
            <ul class="contact__panel__block__footer__social">
              <li
                v-for="(socialLink, index) in activeSocialLinksData"
                :key="index"
              >
                <AppButtonIconExternalLink
                  class="social-link"
                  :href="socialLink.href"
                  :icon="socialLink.icon"
                  :button-style="socialIconStyle"
                  :size="socialIconSize"
                />
              </li>
            </ul>
          </div>
        </div>
        <div
          v-if="!enableContactForms"
          class="contact__panel__block contact__panel__block--privacy-policy"
        >
          <AppButtonText
            class="contact__privacy-policy-button"
            :text="$t('Política de privacidad')"
            :size="privacyPolicyButtonSize"
            :button-style="privacyPolicyButtonStyle"
            @click.native="showPrivacyPolicy"
          />
        </div>
      </div>
    </AppScrollbarContainer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ContactForm from '@/components/Form/ContactForm'
import AppScrollbarContainer from '@/components/UIKit/Standard/Atoms/AppScrollbarContainer'
import responsiveMixin from '@/mixins/responsiveMixin'
import AppButtonIconExternalLink from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonIconExternalLink'
import AppIcon from '@/components/UIKit/Standard/Atoms/AppIcon'
import ContactTopBarControls from '@/components/ViewTopBarControls/ContactTopBarControls'
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'
import iconNames from '@/constants/ui-kit/standard/icon-names'
import AppButtonText from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonText'
import AppSheetContactAdditionalDetails from '@/components/UIKit/Standard/Organisms/AppSheet/AppSheetContactAdditionalDetails.vue'
import modalSupportedComponents from '@/constants/ui-kit/standard/modal/modal-supported-components'
import IframeFormWrapper from '@/components/Gadgets/IframeFormWrapper'

export default {
  name: 'Contact',
  components: {
    AppScrollbarContainer,
    ContactForm,
    AppButtonIconExternalLink,
    AppIcon,
    ContactTopBarControls,
    AppButtonText,
    AppSheetContactAdditionalDetails,
    IframeFormWrapper,
  },
  mixins: [responsiveMixin],
  data: () => {
    return {
      preventFormSubmit: false,
      serverError: '',
      modalContent: null,
      modalTheme: '',
      modalText: '',
      socialIconSize: sizes.XS,
      socialIconStyle: styles.LINK,
      contactIconSize: sizes.XS,
      iconWhatsapp: iconNames.UIWhatsapp,
      iconInstagram: iconNames.UIInstagram,
      iconFacebook: iconNames.UIFacebook,
      iconPhone: iconNames.UIPhone,
      iconEmail: iconNames.UIEmail,
      iconWebsite: iconNames.UIWebsite,
      privacyPolicyButtonSize: sizes.XS,
      privacyPolicyButtonStyle: styles.EXTRA_LIGHT,
      mainIframeFormWrapperHeight: 'auto',
    }
  },
  computed: {
    ...mapGetters({
      contactData: 'Project/contactData',
      developerWebsiteLink: 'Project/developerWebsiteLink',
      enableContactForms: 'Preferences/enableContactForms',
      isContactPageEmbeddedFormAvailable:
        'EmbeddedFormsModule/isContactPageEmbeddedFormAvailable',
      contactPageFormUrl: 'EmbeddedFormsModule/contactPageFormUrl',
    }),
    activeSocialLinksData() {
      const socialLinksData = [
        {
          icon: this.iconFacebook,
          href: this.contactData.facebookUrl,
        },
        {
          icon: this.iconInstagram,
          href: this.contactData.instagramUrl,
        },
        {
          icon: this.iconWhatsapp,
          href: this.contactData.whatsAppUrl,
        },
      ]
      return socialLinksData.filter((item) => !!item.href)
    },
    shouldDisplaySocialLinks() {
      return !!this.activeSocialLinksData.length
    },
  },
  methods: {
    showPrivacyPolicy() {
      this.$store.dispatch('ModalLauncher/launchModal', {
        component: modalSupportedComponents.AppPrivacyPolicyModal,
      })
    },
    showConfirmationMessage() {
      this.$store.dispatch('ModalLauncher/launchModal', {
        component: modalSupportedComponents.AppConfirmationMessageModal,
      })
    },
    handleMainIframeResize(height) {
      this.mainIframeFormWrapperHeight = height + 10 + 'px'
    },
  },
}
</script>

<style lang="scss" scoped>
.contact__wrapper {
  position: relative;
  z-index: 10;
  height: 100vh;
  display: flex;
}

.contact__empty-section {
  flex-grow: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__embedded-form-wrapper {
    max-height: 75%;
    max-width: 95%;
    width: 740px;

    &__scroll-bar {
      height: 100%;
    }
  }
}

.contact {
  max-width: 550px;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  color: var(--gray-700);
  overflow: auto;

  &__top-controls {
    z-index: 20;
  }
  .contact__panel {
    padding: 60px 0 0 0;

    .contact__panel__block {
      padding: 0 65px 28px;
      border-bottom: 1px solid var(--gray-100);
      margin-bottom: 30px;

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 46px;
      }

      @at-root #{&}--form {
        padding-bottom: 64px;
      }

      @at-root #{&}--about {
        padding-right: 53px;
      }

      .contact__panel__block__footer {
        margin-bottom: 1.625rem;
        font-size: 1rem;
        line-height: 1.75;
        color: var(--gray-500);

        &:last-child {
          margin: 0;
        }

        a {
          color: inherit;
          font-weight: var(--font-weight-medium);
          text-decoration: underline;
        }

        h3 {
          font-size: 0.875rem;
          font-weight: var(--font-weight-medium);
          margin-bottom: 1em;
        }

        p {
          font-size: 0.875rem;
          margin: 0;
        }
      }

      .contact__panel__block__footer__social {
        display: flex;
        list-style-type: none;
        margin: 0;
        padding: 0;

        .social-link {
          margin-right: 16px;
        }
      }
    }

    h1 {
      font-size: 1.125rem;
      font-weight: var(--font-weight-medium);
      color: var(--primary-color);
      margin-bottom: 2rem;
    }
    h2 {
      font-size: 1rem;
      font-weight: var(--font-weight-medium);
      margin-bottom: 2rem;
    }
    .info {
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
          font-size: 0.875rem;
          margin-bottom: 1.25rem;
          a {
            display: flex;
            align-items: center;
            color: var(--gray-700);
            gap: 1rem;
            svg {
              fill: var(--gray-700);
              stroke: var(--gray-700);
            }
          }
          img {
            width: 20px;
            margin-right: 8px;
          }
          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }

  @at-root &__privacy-policy-button {
    width: 100%;
  }
}

@include small-screens {
  .contact {
    max-width: none;
    .contact__panel {
      .contact__panel__block {
        @at-root #{&}--about {
          .contact__panel__block__footer {
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}

@include small-screens-vertical {
  .contact {
    .contact__panel {
      .contact__panel__block {
        padding: 0 29px 48px 35px;
        margin-bottom: 49px;

        @at-root #{&}--about {
          padding-right: 29px;
          line-height: 22px;
        }
      }

      .contact__panel__block:first-child {
        padding-top: 44px;
        padding-bottom: 28px;
        margin-bottom: 30px;
      }

      h1 {
        margin-bottom: 25px;
      }

      .info {
        ul {
          li {
            margin-bottom: 18px;
          }
        }
      }
    }
  }
}

@include small-screens-horizontal {
  .contact {
    .contact__panel {
      display: flex;
      flex-direction: column;
      padding-top: 0;
      height: 100vh;

      &--with-form {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: max-content;
        gap: 0px 0px;
        grid-template-areas: 'contact__panel__block--info contact__panel__block--form contact__panel__block--embedded-form contact__panel__block--about';
      }

      .contact__panel__block {
        padding: 70px 30px 28px;
        margin: 0;
        @at-root #{&}--info {
          grid-column: 1/2;
          grid-row: 1;
        }

        @at-root #{&}--form {
          border-left: 1px solid var(--gray-100);
          grid-column: 2/2;
          grid-row: 1/3;
          padding-top: 78px;
          padding-bottom: 25px;
          border-bottom: none;
        }

        @at-root #{&}--about {
          grid-column: 1/2;
          grid-row: 2;
          padding-top: 30px;
        }
        @at-root #{&}--embedded-form {
          grid-column: 1/2;
          grid-row: 3;
          padding-top: 30px;
        }
      }
    }
  }
}
</style>
