import { zoomDiscreteSteps, defaultZoomStep } from '@/constants/zoom-tracker'

export default {
  imagePosition: (state) => {
    return {
      x: state.imagePositionX,
      y: state.imagePositionY,
    }
  },
  imageZoom: (state) => {
    return state.imageZoom
  },
  isLastZoomStep: (state) => {
    const lastIndexOfZoomSteps = zoomDiscreteSteps.length - 1
    return state.imageZoom >= zoomDiscreteSteps[lastIndexOfZoomSteps]
  },
  isFirstZoomStep: (state) => {
    return state.imageZoom <= defaultZoomStep
  },
  nextZoomDiscreteStep: (state) => {
    return zoomDiscreteSteps.find((step) => step > state.imageZoom)
  },
  previousZoomDiscreteStep: (state) => {
    const zoomDiscreteStepsCopy = zoomDiscreteSteps.slice()

    return zoomDiscreteStepsCopy
      .reverse()
      .find((step) => step < state.imageZoom)
  },
  gestureDragActive: (state) => state.gestureDragActive,
  gesturePinchOrSpreadActive: (state) => state.gesturePinchOrSpreadActive,
  isAnyGestureActive: (getters) =>
    getters.gestureDragActive || getters.gesturePinchOrSpreadActive,
}
