class LoggingService {
  get logError() {
    return () => {}
  }
  get logWarning() {
    return () => {}
  }
  get logInfo() {
    return () => {}
  }
  // eslint-disable-next-line no-unused-vars
  get logMessage() {
    return () => {
      throw new Error(
        'logMessage() is not implemented in base class LoggingService'
      )
    }
  }
}
module.exports = LoggingService
