<template>
  <AppSheetMinimalProjectElement
    :title="title"
    :text-alignment="textAlignment"
  />
</template>

<script>
import AppSheetMinimalProjectElement from '@/components/UIKit/Standard/Organisms/AppSheet/AppSheetMinimalProjectElement'
import BaseProjectElement from '@/classes/ProjectStructure/BaseProjectElement'
import alignments from '@/constants/ui-kit/standard/alignments'

export default {
  name: 'MinimalModePopupContent',
  components: {
    AppSheetMinimalProjectElement,
  },
  props: {
    projectElement: {
      type: BaseProjectElement,
      required: true,
    },
  },
  data() {
    return {
      textAlignment: alignments.ALIGN_LEFT,
    }
  },
  computed: {
    title() {
      return this.projectElement.name || this.projectElement.title
    },
  },
}
</script>
