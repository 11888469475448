<template>
  <AppSheetProjectElementLarge
    :title="pointOfInterest.title"
    :subtitle="pointOfInterest.shortText"
    :logo-image-url="logoUrl"
    :preview-image-url="previewImageUrl"
    :enter-to="pointOfInterestEnterToLink"
    :exterior-tour360-to="pointOfInterestTour360ToLink"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import responsiveMixin from '@/mixins/responsiveMixin'
import PointOfInterest from '@/classes/ProjectStructure/PointOfInterest'
import AppSheetProjectElementLarge from '@/components/UIKit/Standard/Organisms/AppSheet/AppSheetProjectElementLarge'
import PointOfInterestRouteObjectGenerator from '@/classes/RouteObjectGenerators/PointOfInterestRouteObjectGenerator'
import ExteriorTour360RouteObjectGenerator from '@/classes/RouteObjectGenerators/ExteriorTour360RouteObjectGenerator'

export default {
  name: 'PointOfInterestPanelSmallContent',
  components: {
    AppSheetProjectElementLarge,
  },
  mixins: [responsiveMixin],
  props: {
    projectElement: {
      type: PointOfInterest,
      required: true,
    },
    accessLinksEnabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      logoData: 'Project/logo',
    }),
    pointOfInterest() {
      return this.projectElement
    },
    shouldDisplayLogo() {
      return this.isSmallScreenHorizontal && this.logoData.display
    },
    logoUrl() {
      return this.shouldDisplayLogo ? this.logoData.imgUrl : undefined
    },
    previewImageUrl() {
      return this.isSmallScreenHorizontal
        ? this.pointOfInterest.previewImageUrl
        : undefined
    },
    hasGallery() {
      return !!this.pointOfInterest.gallery
    },
    hasExteriorTour360() {
      return !!this.pointOfInterest.exteriorTour360Url
    },
    pointOfInterestRouteObject() {
      return this.hasGallery
        ? PointOfInterestRouteObjectGenerator.make(this.pointOfInterest)
        : undefined
    },
    exteriorTour360RouteObject() {
      return this.hasExteriorTour360
        ? ExteriorTour360RouteObjectGenerator.make(this.pointOfInterest)
        : undefined
    },
    pointOfInterestEnterToLink() {
      return this.accessLinksEnabled
        ? this.pointOfInterestRouteObject
        : undefined
    },
    pointOfInterestTour360ToLink() {
      return this.accessLinksEnabled
        ? this.exteriorTour360RouteObject
        : undefined
    },
  },
}
</script>
