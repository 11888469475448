<template>
  <TheTopBar
    :controls="controls"
    :offset="offset"
    :menu-offset="menuOffset"
    with-fullscreen-button
    v-on="$listeners"
  >
    <template #pointer-events-disabled-relative-slot>
      <TopBarLabel v-if="!!labelText" align="left">
        {{ labelText }}
      </TopBarLabel>
      <AppButtonList
        v-if="!isSmallScreen"
        class="gellery-menu"
        :buttons-data="galleryCollectionsOptions"
        :selected-button-value="selectedGallerySlug"
        @select-button-value="(e) => $emit('redirect-to-gallery-collection', e)"
      />
    </template>
  </TheTopBar>
</template>

<script>
import TheTopBar from '@/components/Gadgets/TheTopBar'
import TopBarControlsSuite from '@/classes/TopBarControls/TopBarControlsSuite'
import TopBarButtonIconGenerator from '@/classes/TopBarControls/TopBarButtonIconGenerator'
import TopBarButtonGenerator from '@/classes/TopBarControls/TopBarButtonGenerator'
import TopBarLabel from '@/components/Gadgets/TheTopBar/snippets/TopBarLabel'
import AppButtonList from '@/components/UIKit/Standard/Molecules/AppButtonList'
import responsiveMixin from '@/mixins/responsiveMixin'
import iconNames from '@/constants/ui-kit/standard/icon-names'

export default {
  name: 'ProjectDetailsTopBarControls',
  components: {
    TheTopBar,
    TopBarLabel,
    AppButtonList,
  },
  mixins: [responsiveMixin],
  props: {
    labelText: {
      type: String,
      default: undefined,
    },
    showInfoPanelButton: {
      type: Boolean,
      default: undefined,
    },
    showUnitLevelsMenuButton: {
      type: Boolean,
      default: undefined,
    },
    galleryCollectionsOptions: {
      type: Array,
      default: () => [],
    },
    selectedGallerySlug: {
      type: String,
      required: true,
    },
    offset: {
      type: Number,
      default: 0,
    },
    menuOffset: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    controls() {
      return new TopBarControlsSuite({
        rightControls: [
          TopBarButtonGenerator.create({
            buttonText: this.$t('Ver detalles'),
            show: () => this.showInfoPanelButton && !this.isSmallScreen,
            eventHandler: () => this.$emit('show-details'),
          }),

          TopBarButtonGenerator.create({
            buttonText: this.$t('Ver pisos'),
            show: () => this.showUnitLevelsMenuButton && !this.isSmallScreen,
            eventHandler: () => this.$emit('open-levels-menu'),
          }),

          TopBarButtonGenerator.create({
            buttonText: this.$t('Cambiar planta'),
            projectTypesSupported: [
              this.$projectType.MULTITOWER_TYPE,
              this.$projectType.SINGLETOWER_TYPE,
            ],
            show: () => !this.isSmallScreen,
            eventHandler: () => this.$emit('back-to-topview'),
          }),
          TopBarButtonIconGenerator.create({
            buttonIcon: iconNames.UIFloors,
            projectTypesSupported: [
              this.$projectType.MULTITOWER_TYPE,
              this.$projectType.SINGLETOWER_TYPE,
            ],
            show: () => this.isSmallScreen,
            eventHandler: () => this.$emit('back-to-topview'),
          }),
          TopBarButtonIconGenerator.create({
            buttonIcon: iconNames.UIFloors,
            show: () => this.showUnitLevelsMenuButton && this.isSmallScreen,
            eventHandler: () => this.$emit('open-levels-menu'),
          }),
          TopBarButtonIconGenerator.create({
            buttonIcon: iconNames.UIViewDetails,
            show: () => this.showInfoPanelButton && this.isSmallScreen,
            eventHandler: () => this.$emit('show-details'),
          }),
        ],
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.gellery-menu {
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: all;
}
</style>
