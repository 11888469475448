export default class QuoterDateTools {
  static getYear(dateObject) {
    return dateObject.getUTCFullYear()
  }

  static getMonth(dateObject) {
    return dateObject.getUTCMonth()
  }

  static convertDateObjectToIsoStringDate(dateObject) {
    return dateObject.toISOString()
  }

  static convertIsoStringDateToDateObject(isoStringDate) {
    return new Date(isoStringDate)
  }

  static isIsoStringDateValid(isoStringDate) {
    return !isNaN(
      QuoterDateTools.convertIsoStringDateToDateObject(isoStringDate)
    )
  }

  static isDateObjectValid(isoDateObject) {
    return isoDateObject instanceof Date && !isNaN(isoDateObject)
  }

  static get shortDateFormatOptions() {
    const toLocaleDateStringOptions = {
      timeZone: 'UTC',
      day: undefined,
    }
    return toLocaleDateStringOptions
  }

  static get fullDateFormatOptions() {
    const toLocaleDateStringOptions = {
      timeZone: 'UTC',
    }
    return toLocaleDateStringOptions
  }
}
