<template>
  <div
    ref="svgWrapper"
    class="the-interactive-svg"
    :class="{
      'the-interactive-svg--disabled': disabled,
    }"
    v-html="svgInnerHtml"
  ></div>
</template>
<script>
import httpClient from '@/constants/api/http-client'
import { mapGetters, mapActions } from 'vuex'
import responsiveMixin from '@/mixins/responsiveMixin'

export default {
  name: 'TheInteractiveSvg',
  mixins: [responsiveMixin],
  props: {
    svgUrl: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    interactiveElementIds: {
      type: Array,
      default: () => undefined,
    },
    coloredElements: {
      type: Array,
      default: () => undefined,
      validator: (value) => {
        return value.every((element) => {
          return (
            typeof element === 'object' &&
            element !== null &&
            typeof element.elementId === 'string' &&
            element.elementId.trim() !== '' &&
            typeof element.color === 'string' &&
            element.color.trim() !== ''
          )
        })
      },
    },
  },
  data() {
    return {
      svgElement: null,
      svgInnerHtml: '',
      activeElementId: null,
      activeElementBoundingRect: null,
      enterElementDelay: 150,
      enterElementTimer: null,
    }
  },
  computed: {
    ...mapGetters({
      svgColor: 'Project/svgColor',
    }),
  },
  mounted() {
    this.loadSvg()
  },
  methods: {
    ...mapActions({
      initializeAndStoreSvgPriceTags:
        'AvailabilityGalleryModule/initializeAndStoreSvgPriceTags',
    }),
    async loadSvg() {
      try {
        const response = await httpClient.get(this.svgUrl)
        const tempSvgWrapper = document.createElement('div')
        tempSvgWrapper.innerHTML = response.data
        const svgElement = tempSvgWrapper.querySelector('svg')
        this.svgInnerHtml = svgElement.outerHTML
        await this.$nextTick()
        this.initializeSvg()
      } catch (error) {
        this.$loggingService.logError(`SVG inject fail. ${error.toString()}`)
      }
    },
    initializeSvg() {
      if (this.$refs.svgWrapper) {
        this.svgElement = this.$refs.svgWrapper.querySelector('svg')
        if (this.svgElement) {
          this.validateAndFixSvg()
          this.loadSvgPriceTags()
          this.initializeInteractiveElements()
          this.fillColoredElements()
        } else {
          this.$loggingService.logError(
            `InteractiveSvg.initializeSvg(): SVG element not found in DOM. SVG: ${this.svgUrl}.`
          )
        }
      }
    },
    loadSvgPriceTags() {
      if (this.$refs.svgWrapper) {
        const svgElement = this.$refs.svgWrapper.querySelector('svg')

        const {
          width: svgElementWidth,
          height: svgElementHeight,
        } = svgElement.getBBox()

        if (svgElementWidth === 0 || svgElementHeight === 0) {
          setTimeout(() => this.loadSvgPriceTags(), 100)
        } else {
          this.initializeAndStoreSvgPriceTags(svgElement)
        }
      }
    },
    initializeInteractiveElements() {
      this.interactiveElementIds.forEach((elementId) => {
        const element = this.svgElement.getElementById(elementId)
        if (element) {
          element.classList.add('the-interactive-svg__interactive-element')
          element.style.fill = this.svgColor
          element.addEventListener('click', this.onClickElement, false)
          if (!this.isSmallScreen) {
            element.addEventListener('mouseenter', this.onEnterElement, false)
            element.addEventListener('mousemove', this.onMouseMove, false)
            element.addEventListener('mouseleave', this.onLeaveElement, false)
          }
        }
      })
    },
    fillColoredElements() {
      this.coloredElements.forEach((elementData) => {
        const { elementId, color } = elementData
        const element = this.svgElement.getElementById(elementId)
        if (element) {
          element.style.fill = color
        }
      })
    },
    validateAndFixSvg() {
      const preserveAspectRatioValue = this.svgElement.getAttribute(
        'preserveAspectRatio'
      )
      if (!preserveAspectRatioValue) {
        this.svgElement.setAttribute('preserveAspectRatio', 'xMidYMid slice')
        this.$loggingService.logWarning(
          `InteractiveSvg.setSvgPreserveAspectRatio(): The SVG was fixed because it did not have the preserveAspectRatio attribute. SVG: ${this.svgUrl}.`
        )
      }

      const title = this.svgElement.querySelector('title')
      if (title) {
        title.remove()
        this.$loggingService.logWarning(
          `InteractiveSvg.removeSvgTitle(): The SVG was fixed because it had a title element. SVG: ${this.svgUrl}.`
        )
      }
    },
    isCursorPositionInsideIntersectionArea(mousePosition) {
      const { x, y } = mousePosition
      const isInsideHorizontalArea =
        x <= this.activeElementBoundingRect?.right &&
        x >= this.activeElementBoundingRect?.left
      const isInsideVerticalArea =
        y <= this.activeElementBoundingRect?.bottom &&
        y >= this.activeElementBoundingRect?.top

      return isInsideHorizontalArea && isInsideVerticalArea
    },
    setActiveClassTo(elementId) {
      try {
        const element = this.svgElement.getElementById(elementId)
        element.classList.add(
          'the-interactive-svg__interactive-element--active'
        )
      } catch (error) {
        this.$loggingService.logError(
          `InteractiveSvg.setActiveClassTo(): Error trying to set active class. ${error.toString()}`
        )
      }
    },
    removeActiveClasses() {
      if (this.svgElement) {
        let allActiveElements = this.svgElement.querySelectorAll(
          '.the-interactive-svg__interactive-element--active'
        )
        allActiveElements.forEach((activeElement) =>
          activeElement.classList.remove(
            'the-interactive-svg__interactive-element--active'
          )
        )
      }
    },
    calculateCenterOfVisiblePortion(elementBoundingRect) {
      const topVisibleLimit = Math.max(elementBoundingRect.top, 0)
      const bottomVisibleLimit = Math.min(
        elementBoundingRect.bottom,
        window.innerHeight
      )

      const leftVisibleLimit = Math.max(elementBoundingRect.left, 0)
      const rightVisibleLimit = Math.min(
        elementBoundingRect.right,
        window.innerWidth
      )

      return {
        x: (leftVisibleLimit + rightVisibleLimit) / 2,
        y: (topVisibleLimit + bottomVisibleLimit) / 2,
      }
    },
    onEnterElementAndWait(currentElement) {
      const elementId = currentElement.id || this.activeElementId
      const elementBoundingRect = currentElement.getBoundingClientRect()
      const elementCenter = this.calculateCenterOfVisiblePortion(
        elementBoundingRect
      )
      const elementSize = {
        width: elementBoundingRect.width,
        height: elementBoundingRect.height,
      }

      this.activeElementBoundingRect = elementBoundingRect
      this.activate(elementId)
      this.$emit('enter-element', {
        elementId,
        elementCenter,
        elementSize,
      })
    },
    setEnterElementTimer(currentElement, mousePosition) {
      clearTimeout(this.enterElementTimer)
      if (
        this.activeElementId &&
        this.isCursorPositionInsideIntersectionArea(mousePosition)
      ) {
        this.enterElementTimer = setTimeout(() => {
          this.onEnterElementAndWait(currentElement)
        }, this.enterElementDelay)
      } else {
        this.onEnterElementAndWait(currentElement)
      }
    },
    onEnterElement(event) {
      const currentElement = event.target
      const mousePosition = { x: event.x, y: event.y }
      this.setEnterElementTimer(currentElement, mousePosition)
    },
    onMouseMove(event) {
      const currentElement = event.target
      const mousePosition = { x: event.x, y: event.y }
      this.setEnterElementTimer(currentElement, mousePosition)
    },
    onLeaveElement() {
      this.clear()
      this.$emit('leave-element')
    },
    onClickElement(event) {
      const currentElement = event.target
      const elementId = currentElement.id || this.activeElementId
      this.activate(elementId)
      this.$emit('select-element', { elementId })
    },
    clear() {
      this.activeElementId = null
      this.removeActiveClasses()
    },
    activate(elementId) {
      this.clear()
      this.setActiveClassTo(elementId)
      this.activeElementId = elementId
      clearTimeout(this.enterElementTimer)
    },
  },
}
</script>
<style lang="scss" scoped>
.the-interactive-svg {
  @at-root #{&}--disabled {
    pointer-events: none;
  }

  @at-root #{&}::v-deep {
    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      * {
        pointer-events: none;
      }
      .the-interactive-svg__interactive-element {
        opacity: 0;
        cursor: pointer;
        transition: opacity 150ms ease-in-out;
        pointer-events: inherit;
        @at-root #{&}--active {
          opacity: 0.42;
        }
      }
      @include horizontal-scroll-enabled {
        width: auto;
      }
    }
  }
}
</style>
