import { FEATURES } from '@/constants/feature-flags'

export default {
  enabledFeatureFlags: (state) =>
    state.enabledFeatures === null ? [] : state.enabledFeatures,
  isFeatureFlagsLoaded: (state) => state.enabledFeatures !== null,
  isFeatureFlagEnabled: (state, getters) => (featureName) =>
    getters.enabledFeatureFlags.includes(featureName),
  pricesFeatureFlagEnabled: (state, getters) =>
    getters.enabledFeatureFlags.includes(FEATURES.PRICES),
}
