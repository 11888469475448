<template>
  <section>
    <VTitle text="AppMenu" />
    <div class="example-component">
      <div class="example-component__controls">
        <VPropControl
          v-for="(propModel, propName) in propModels"
          :key="propName"
          :model="propModel"
          :name="propName"
        />
      </div>
      <div class="example-component__component">
        <VComponentWrapper :height="600" :width="350" bordered resizable>
          <AppMenu
            :menu="propModels.menu.value"
            :logo-image-url="propModels.logoImageUrl.value"
            :facebook-url="propModels.facebookUrl.value"
            :instagram-url="propModels.instagramUrl.value"
            :whatsapp-url="propModels.whatsappUrl.value"
            :developer-website-link="propModels.developerWebsiteLink.value"
            :with-copyright="propModels.withCopyright.value"
            :copyright-with-link="propModels.copyrightWithLink.value"
            :limited-connection="propModels.limitedConnection.value"
          />
        </VComponentWrapper>
      </div>
    </div>
  </section>
</template>

<script>
import AppMenu from '@/components/UIKit/Standard/Organisms/AppMenu'
import AppPropModel from '@/components/UIKit/Standard/ViewerSections/classes/AppPropModel'
import VTitle from '@/components/UIKit/Standard/ViewerSections/components/snippets/VTitle'
import VPropControl from '@/components/UIKit/Standard/ViewerSections/components/snippets/VPropControl'
import VComponentWrapper from '@/components/UIKit/Standard/ViewerSections/components/snippets/VComponentWrapper'
import LinkMenuOption from '@/classes/Navigation/MenuOption/LinkMenuOption'
import Menu from '@/classes/Navigation/Menu'
import LinkMenuItem from '@/classes/Navigation/MenuItem/LinkMenuItem'
import iconNames from '@/constants/ui-kit/standard/icon-names'

export default {
  name: 'AppMenuViewer',
  components: {
    AppMenu,
    VTitle,
    VPropControl,
    VComponentWrapper,
  },
  data() {
    return {
      propModels: {
        menu: new AppPropModel({
          type: Menu,
          defaultValue: new Menu('Main menu'),
          required: true,
        }),
        logoImageUrl: new AppPropModel({
          type: String,
          defaultValue:
            '/resources/project-contents/singletower/data/project/2020/07/menu_logo_img/c09465d68f6e4461997de824a88adce0',
        }),
        facebookUrl: new AppPropModel({
          type: String,
          defaultValue: '#',
        }),
        instagramUrl: new AppPropModel({
          type: String,
          defaultValue: '#',
        }),
        whatsappUrl: new AppPropModel({
          type: String,
          defaultValue: '#',
        }),
        developerWebsiteLink: new AppPropModel({
          type: Object,
          defaultValue: {
            url: '#',
            displayText: 'Masterise Homes',
          },
        }),
        withCopyright: new AppPropModel({
          type: Boolean,
          defaultValue: true,
        }),
        copyrightWithLink: new AppPropModel({
          type: Boolean,
          defaultValue: true,
        }),
        limitedConnection: new AppPropModel({
          type: Boolean,
          defaultValue: true,
        }),
      },
    }
  },
  created() {
    const menuOptions = [
      new LinkMenuOption({
        name: 'Option 1',
        routeName: 'ui-kit-viewer',
        routeParams: {
          componentName: 'Menu',
          mockRouteParam: 'option-1',
        },
        exact: false,
        order: 1,
        icon: iconNames.UIIntro,
      }),
      new LinkMenuOption({
        name: 'Option 2',
        routeName: 'ui-kit-viewer',
        routeParams: {
          componentName: 'Menu',
          mockRouteParam: 'option-2',
        },
        exact: false,
        order: 2,
        showHintDependingOnInternetConnection: true,
        icon: iconNames.UIHome,
      }),
      new LinkMenuOption({
        name: 'Option 3',
        routeName: 'ui-kit-viewer',
        routeParams: {
          componentName: 'Menu',
          mockRouteParam: 'option-3',
        },
        exact: false,
        order: 3,
        icon: iconNames.UIAmenities,
      }),
    ]

    const submenuOptions = [
      new LinkMenuOption({
        name: 'Submenu Item 1',
        routeName: 'ui-kit-viewer',
        routeParams: {
          componentName: 'Menu',
          mockRouteParam: 'submenu-item-1',
        },
        exact: false,
        order: 1,
      }),
      new LinkMenuOption({
        name: 'Submenu Item 2',
        routeName: 'ui-kit-viewer',
        routeParams: {
          componentName: 'Menu',
          mockRouteParam: 'submenu-item-2',
        },
        exact: false,
        order: 2,
      }),
      new LinkMenuOption({
        name: 'Submenu Item 3',
        routeName: 'ui-kit-viewer',
        routeParams: {
          componentName: 'Menu',
          mockRouteParam: 'submenu-item-3',
        },
        exact: false,
        order: 3,
      }),
    ]

    const menu = this.propModels.menu.value
    menuOptions.forEach((option) => menu.addChild(new LinkMenuItem(option)))

    const submenu = new Menu('Option 4', iconNames.UIAreas)
    submenuOptions.forEach((option) =>
      submenu.addChild(new LinkMenuItem(option))
    )

    menu.addChild(submenu)
  },
}
</script>

<style lang="scss" scoped>
.example-component {
  display: flex;
  justify-content: stretch;
  align-items: stretch;

  @at-root &__controls {
    background: #fff;
    padding: 30px;
    border: 1px solid var(--gray-200);
    flex-basis: 300px;
    border-radius: 12px;
  }

  @at-root &__component {
    padding: 0 30px;
  }
}
</style>
