<template>
  <div class="app-submenu-title">
    <AppIcon class="app-submenu-title__icon" :icon="icon" :size="iconSize" />
    <h4 class="app-submenu-title__text">
      {{ title }}
    </h4>
  </div>
</template>
<script>
import AppIcon from '@/components/UIKit/Standard/Atoms/AppIcon'
import iconNames from '@/constants/ui-kit/standard/icon-names'
import sizes from '@/constants/ui-kit/standard/sizes'

export default {
  name: 'AppSubmenuTitle',
  components: {
    AppIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      validator: (value) => Object.values(iconNames).includes(value),
      default: iconNames.UIAreas,
    },
  },
  data() {
    return {
      iconSize: sizes.SM,
    }
  },
}
</script>
<style lang="scss" scoped>
.app-submenu-title {
  display: flex;
  align-items: center;
  gap: 1.625rem;
  padding: 0.5625rem 0;
  @at-root #{&}__icon {
    stroke: var(--primary-color);
    fill: var(--primary-color);
  }
  @at-root #{&}__text {
    font-size: 1rem;
    font-weight: var(--font-weight-medium);
    line-height: 1.5;
    margin: 0;
    color: var(--primary-color);
  }
}
</style>
