<template>
  <section>
    <VTitle text="AppMenuItem" />
    <div class="example-component">
      <div class="example-component__controls">
        <VPropControl
          v-for="(propModel, propName) in propModels"
          :key="propName"
          :model="propModel"
          :name="propName"
        />
      </div>
      <div class="example-component__component">
        <AppMenuItemsList :items="propModels.items.value" />
      </div>
    </div>
  </section>
</template>

<script>
import AppMenuItemsList from '@/components/UIKit/Standard/Molecules/AppMenuItemsList'
import AppPropModel from '@/components/UIKit/Standard/ViewerSections/classes/AppPropModel'
import VTitle from '@/components/UIKit/Standard/ViewerSections/components/snippets/VTitle'
import VPropControl from '@/components/UIKit/Standard/ViewerSections/components/snippets/VPropControl'
import LinkMenuItem from '@/classes/Navigation/MenuItem/LinkMenuItem'
import LinkMenuOption from '@/classes/Navigation/MenuOption/LinkMenuOption'

import { mapGetters } from 'vuex'

export default {
  name: 'AppButtonTextWithIconViewer',
  components: {
    AppMenuItemsList,
    VTitle,
    VPropControl,
  },
  data() {
    return {
      mtSubmenuOptions: [
        new LinkMenuOption({
          name: 'Torre 1',
          routeName: 'topview',
          routeParams: {
            levelId: 1,
            descriptiveSlug: 't1',
          },
          exact: false,
          order: 1,
        }),
        new LinkMenuOption({
          name: 'Torre 2',
          routeName: 'topview',
          routeParams: {
            levelId: 2,
            descriptiveSlug: 't2',
          },
          exact: false,
          order: 2,
        }),
        new LinkMenuOption({
          name: 'Torre 3',
          routeName: 'topview',
          routeParams: {
            levelId: 3,
            descriptiveSlug: 't3',
          },
          exact: false,
          order: 3,
        }),
      ],
      nbhSubmenuOptions: [
        new LinkMenuOption({
          name: 'Sava collection 1 to 9',
          routeName: 'neighbourhood-subdivision',
          routeParams: {
            neighbourhoodSubdivisionId: 1,
            descriptiveSlug: 's1t9',
          },
          exact: false,
          order: 1,
        }),
        new LinkMenuOption({
          name: 'Sava collection 10 to 15',
          routeName: 'neighbourhood-subdivision',
          routeParams: {
            neighbourhoodSubdivisionId: 1,
            descriptiveSlug: 's10t15',
          },
          exact: false,
          order: 2,
        }),
        new LinkMenuOption({
          name: 'Sava collection 16 to 25',
          routeName: 'neighbourhood-subdivision',
          routeParams: {
            neighbourhoodSubdivisionId: 1,
            descriptiveSlug: 's16t25',
          },
          exact: false,
          order: 3,
        }),
        new LinkMenuOption({
          name: 'Garden of Childhood dreams',
          routeName: 'neighbourhood-subdivision',
          routeParams: {
            neighbourhoodSubdivisionId: 4,
            descriptiveSlug: 'garden',
          },
          exact: false,
          order: 4,
        }),
      ],
      propModels: {
        items: new AppPropModel({
          type: Array,
          defaultValue: [],
          required: true,
        }),
      },
    }
  },
  computed: {
    ...mapGetters({
      menuData: 'Layout/menuData',
    }),
  },
  mounted() {
    this.propModels.items.addPreset(
      this.mtSubmenuOptions.map((i) => {
        if (i instanceof LinkMenuOption) {
          new LinkMenuItem(i)
        }
      }),
      'Multitower submenu'
    )
    this.propModels.items.addPreset(
      this.nbhSubmenuOptions.map((i) => {
        if (i instanceof LinkMenuOption) {
          new LinkMenuItem(i)
        }
      }),
      'Neighbourhood submenu'
    )
    this.propModels.items.addPreset(
      this.menuData.children,
      'Current Project Menu'
    )
  },
}
</script>

<style lang="scss" scoped>
.example-component {
  display: flex;
  justify-content: stretch;
  align-items: stretch;

  @at-root &__controls {
    background: #fff;
    padding: 30px;
    border: 1px solid var(--gray-200);
    flex-basis: 300px;
    border-radius: 12px;
  }

  @at-root &__component {
    padding: 30px;
    width: 340px;
  }
}
</style>
