<template>
  <div id="availability-view-container">
    <TheFullViewportContainer>
      <AvailabilityViewTopBarControls
        :label-text="labelText"
        :selected-payment-method-id="selectedPaymentMethodId"
        :payment-method-select-options="paymentMethodSelectOptions"
        :menu-offset="leftSidebarWidth"
        :is-display-prices-on-availability-view-enabled="
          shouldDisplayPaymentMethodsSelector
        "
        @change-payment-method-for-unit-price-display="
          onChangePaymentMethodForUnitPriceDisplay
        "
      />
      <AppDropdown
        v-if="isSmallScreen && shouldDisplayPaymentMethodsSelector"
        class="availability-view__tour360-dropdown"
        :dropdown-style="paymentMethodsDropdownStyle"
        :options="paymentMethodSelectOptions"
        :selected-value="selectedPaymentMethodId"
        @selectedOptionChange="onChangePaymentMethodForUnitPriceDisplay"
      />
      <ThePaginationControls
        :page-routes="collectionPages"
        :prev-button-offset="leftSidebarWidth"
        @unrecognized-route="onUnrecognizedRoute"
      />
      <ProjectElementPopupDisplayer
        class="availability-view__popup-displayer"
        minimal-content-mode
      />
      <TheZoomControls with-background-image />
      <SelectedSvgElementInfoPanel
        ref="infoPanel"
        class="availability-view__info-panel"
        @width-updated="updateLeftSidebarWidth"
      />
    </TheFullViewportContainer>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import responsiveMixin from '@/mixins/responsiveMixin'
import ThePaginationControls from '@/components/Gadgets/ThePaginationControls'
import SelectedSvgElementInfoPanel from '@/components/ComposedInfoPanels/SelectedSvgElementInfoPanel'
import TheFullViewportContainer from '@/components/Gadgets/TheFullViewportContainer'
import ProjectElementPopupDisplayer from '@/components/Gadgets/ProjectElementPopupDisplayer'
import TheZoomControls from '@/components/Gadgets/TheZoomControls'
import AvailabilityViewTopBarControls from '@/components/ViewTopBarControls/AvailabilityViewTopBarControls'
import HomeRouteObjectGenerator from '@/classes/RouteObjectGenerators/HomeRouteObjectGenerator'
import AppDropdown from '@/components/UIKit/Standard/Molecules/AppDropdown'
import styles from '@/constants/ui-kit/standard/styles'

export default {
  name: 'AvailabilityView',
  components: {
    TheFullViewportContainer,
    ProjectElementPopupDisplayer,
    ThePaginationControls,
    SelectedSvgElementInfoPanel,
    TheZoomControls,
    AvailabilityViewTopBarControls,
    AppDropdown,
  },
  mixins: [responsiveMixin],
  props: {
    resourceId: {
      type: String,
      default: '1',
    },
  },
  data() {
    return {
      leftSidebarWidth: 0,
      paymentMethodsDropdownStyle: styles.LIGHT,
    }
  },
  computed: {
    ...mapGetters({
      availabilityGallery: 'AvailabilityGalleryModule/availabilityGallery',
      imageZoom: 'ZoomTracker/imageZoom',
      paymentMethods: 'QuoterModule/paymentMethods',
      selectedPaymentMethodId:
        'AvailabilityGalleryModule/selectedPaymentMethodId',
      isDisplayPricesOnAvailabilityViewEnabled:
        'Preferences/enablePricesOnAvailabilityView',
    }),
    currentCollection() {
      return this.availabilityGallery.defaultCollection
    },
    labelText() {
      if (this.currentCollection) {
        try {
          return this.currentCollection.getResourceById(this.resourceId)
            .labelText
        } catch (e) {
          this.$loggingService.logError(
            `AvailabilityView: Could not get requested gallery resource ${
              this.resourceId
            }:  ${e.toString()}`
          )
        }
      }
      return ''
    },
    collectionPages() {
      const routerLinks = []
      for (let i = 1; i <= this.currentCollection.size; i++) {
        const resourceId = i.toString()
        routerLinks.push({
          name: this.$route.name,
          params: {
            useTransition: true,
            collectionSlug: this.currentCollection.slug,
            resourceId,
          },
        })
      }
      return routerLinks
    },
    paymentMethodSelectOptions() {
      return this.paymentMethods?.map((paymentMethod) => ({
        value: `${paymentMethod.id}`,
        text: paymentMethod.name,
      }))
    },
    shouldDisplayPricesOnView() {
      return (
        !!this.paymentMethods &&
        this.paymentMethods.length > 0 &&
        this.isDisplayPricesOnAvailabilityViewEnabled
      )
    },
    shouldDisplayPaymentMethodsSelector() {
      return this.shouldDisplayPricesOnView && this.paymentMethods.length > 1
    },
  },
  watch: {
    isSmallScreen() {
      if (!this.isSmallScreen && this.$refs.infoPanel) {
        this.$refs.infoPanel.close()
      }
    },
    imageZoom() {
      this.$refs.infoPanel?.close()
    },
  },
  mounted() {
    if (this.isSmallScreenVertical) {
      this.$store.dispatch('Layout/setTopLayerCurrentHint', 'RotateDeviceHint')
    }

    if (this.shouldDisplayPricesOnView) {
      this.onChangePaymentMethodForUnitPriceDisplay(
        this.paymentMethodSelectOptions[0]?.value
      )
    }
  },
  methods: {
    updateLeftSidebarWidth(infoPanelWidth) {
      this.leftSidebarWidth = infoPanelWidth
    },
    onUnrecognizedRoute({ alternativeRoute }) {
      const homeRouteObject = HomeRouteObjectGenerator.make()
      const redirectionRoute = alternativeRoute || homeRouteObject
      redirectionRoute.params.useTransition = false
      this.$loggingService.logError(
        `AvailabilityView: The current route '${this.$route.path}' is not recognized in the ThePaginationControls as a valid option. ` +
          `Redirecting to: ${JSON.stringify(redirectionRoute)}.`
      )
      this.$router.replace(redirectionRoute)
    },
    onChangePaymentMethodForUnitPriceDisplay(paymentMethodId) {
      this.$store.dispatch(
        'AvailabilityGalleryModule/changeSelectedPaymentMethodId',
        paymentMethodId
      )
    },
  },
}
</script>
<style lang="scss" scoped>
#availability-view-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 320;
  pointer-events: none;
}

.availability-view {
  &__popup-displayer {
    pointer-events: all;
  }
  &__info-panel {
    position: relative;
    z-index: 20;
  }

  @at-root &__tour360-dropdown {
    --topbar-height: 72px;
    position: fixed;
    top: var(--topbar-height);
    left: 16px;
    margin-top: 5px;
  }
}
</style>
