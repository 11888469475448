class JsonUrlPathGenerator {
  getJsonUrlPath(jsonFileName, location) {
    const urlPath = this.urlPath(location) /* gap to be filled by subclass */
    return urlPath + jsonFileName
  }
}

class ReviewAppJsonUrlPathGenerator extends JsonUrlPathGenerator {
  constructor() {
    super()

    this._usableJsonFolderNames = ['singletower', 'multitower', 'neighbourhood']
  }

  urlPath(location) {
    const params = new Proxy(new URLSearchParams(location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    })

    const projectTypeToReview = params.projectType

    if (
      !projectTypeToReview ||
      !this._usableJsonFolderNames.includes(projectTypeToReview)
    ) {
      throw new Error(
        `ReviewAppJsonUrlPathGenerator.urlPath: Invalid project-type query param "${projectTypeToReview}". Valid values are ${this._usableJsonFolderNames}.`
      )
    }

    return `/usableJsons/${projectTypeToReview}/`
  }
}
class BucketJsonUrlPathGenerator extends JsonUrlPathGenerator {
  urlPath(location) {
    const host = location.host
    return `/data/Project/${host}/`
  }
}

class MockedJsonUrlPathGenerator extends JsonUrlPathGenerator {
  // eslint-disable-next-line no-unused-vars
  urlPath(location) {
    return '/resources/currentJson/'
  }
}

class ElectronJsonUrlPathGenerator extends JsonUrlPathGenerator {
  // eslint-disable-next-line no-unused-vars
  urlPath(location) {
    return '/data/'
  }
}

class JsonUrlPathGeneratorFactory {
  constructor() {
    this.selectedGenerator = process.env.VUE_APP_JSON_URL_PATH_GENERATOR
    this.availableGenerators = {
      ReviewAppJsonUrlPathGenerator,
      BucketJsonUrlPathGenerator,
      MockedJsonUrlPathGenerator,
      ElectronJsonUrlPathGenerator,
    }
  }
  makeGenerator() {
    return new this.availableGenerators[this.selectedGenerator]()
  }
}

export {
  BucketJsonUrlPathGenerator,
  MockedJsonUrlPathGenerator,
  ElectronJsonUrlPathGenerator,
  ReviewAppJsonUrlPathGenerator,
}
export default JsonUrlPathGeneratorFactory
