class BaseFullscreenHandler {
  constructor(fullscreenElement) {
    this._fullscreenElement = fullscreenElement
    this._isFullscreen = false
  }
  get supportsFullscreenNavigation() {
    return false
  }
  get isFullscreen() {
    return this._isFullscreen
  }
  toggleFullscreen() {
    if (this._isFullscreen) {
      return this.exit()
    } else {
      return this.fullscreen()
    }
  }
  fullscreen() {
    throw new Error(
      'fullscreen() is not implemented in base class BaseFullscreenHandler.'
    )
  }
  exit() {
    throw new Error(
      'exit() is not implemented in base class BaseFullscreenHandler.'
    )
  }
  _setFullscreenStatus(isFullscreen) {
    this._isFullscreen = isFullscreen
    this._updateFullscreenElementClass()
  }
  _updateFullscreenElementClass() {
    if (this._isFullscreen) {
      this._fullscreenElement.classList.add('fullscreen')
    } else {
      this._fullscreenElement.classList.remove('fullscreen')
    }
  }
}

export default BaseFullscreenHandler
