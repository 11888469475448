<template>
  <div class="app-button-list">
    <AppButtonTextWithIcon
      v-for="button in buttonsData"
      :key="button.value"
      :text="button.text"
      :icon="button.icon"
      :size="buttonSize"
      :button-style="buttonStyle"
      :disabled="isButtonSelected(button.value)"
      :collapsable="!isButtonSelected(button.value)"
      @click.native="onButtonClick(button.value)"
    />
  </div>
</template>

<script>
import AppButtonTextWithIcon from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonTextWithIcon'
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'

export default {
  name: 'AppButtonList',
  components: {
    AppButtonTextWithIcon,
  },
  props: {
    buttonsData: {
      type: Array,
      required: true,
      validator: function(array) {
        return array.every((item) => !!item.value && !!item.icon && !!item.text)
      },
    },
    selectedButtonValue: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      buttonStyle: styles.SECONDARY,
      buttonSize: sizes.XS,
    }
  },
  methods: {
    onButtonClick(value) {
      this.$emit('select-button-value', value)
    },
    isButtonSelected(value) {
      return this.selectedButtonValue === value
    },
  },
}
</script>

<style lang="scss" scoped>
.app-button-list {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;
}
</style>
