import stopPointSlugs from '@/constants/stop-point-slugs'

class MasterplanRouteObjectGenerator {
  static get ROUTE_NAME() {
    return 'masterplan'
  }
  static make(customParams) {
    const overridableParams = {
      stopPointSlug: stopPointSlugs.MASTERPLAN_0,
    }
    return {
      name: this.ROUTE_NAME,
      params: {
        ...overridableParams,
        ...customParams,
      },
    }
  }
}
export default MasterplanRouteObjectGenerator
