export default {
  menuData: (state) => state.menuData,
  menuWidth: (state) => state.menuWidth,
  unitDetailsMenuData: (state, getters, rootState, rootGetters) => {
    // TODO: Consider refactoring the unit details menu just like the master menu
    return state.unitDetailsMenuData.items.filter((elem) => {
      const isFeatureFlagEnabled =
        rootGetters['FeatureFlags/isFeatureFlagEnabled']
      return (
        !elem.showDependingOnFeatureFlags ||
        isFeatureFlagEnabled(elem.featureFlag)
      )
    })
  },
  topLayerCurrentHint: (state) => state.topLayerCurrentHint,
  getHint: (state) => (hintKey) => state.hints[hintKey],
}
