<template>
  <div
    class="app-rich-text"
    :class="{ 'app-rich-text--compact': compact }"
    v-html="htmlContent"
  ></div>
</template>

<script>
export default {
  name: 'AppContactAdditionalDataRichText',
  props: {
    htmlContent: {
      type: String,
      required: true,
    },
    compact: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.app-rich-text::v-deep {
  font-size: 0.875rem;
  font-weight: $font-weight-regular;
  color: var(--gray-500);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--gray-700);
    font-weight: $font-weight-medium;
    font-size: 1rem;
    margin: 0 0 1.375em;
  }
  p,
  td {
    margin: 1.375em 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
  b {
    font-weight: $font-weight-bold;
  }
  table {
    width: 100%;
    margin-top: 2.5em;
    border-top: 1px solid var(--gray-100);
  }
  tr:first-child td {
    padding-top: 1em;
  }
  td:last-child {
    text-align: right;
  }

  img {
    margin-right: 8px;
  }

  h1 {
    font-size: 1.4rem;
    font-weight: var(--font-weight-medium);
    color: var(--primary-color);
    margin-bottom: 2rem;
  }
  h2 {
    font-size: 1.25rem;
    font-weight: var(--font-weight-medium);
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  ul {
    background-color: #ffffff;
    color: var(--gray-700);
    overflow: auto;
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      font-size: 0.875rem;
      margin-bottom: 1.25rem;
      a {
        display: flex;
        align-items: center;
        color: var(--gray-700);
        gap: 1rem;
        svg {
          fill: var(--white);
          stroke: var(--gray-700);
        }
      }
      img {
        width: 20px;
        margin-right: 8px;
      }
      &:last-child {
        margin: 0;
      }
    }
  }
}
.rich-text--compact::v-deep {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.875rem;
  }
}
</style>
