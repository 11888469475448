<template>
  <div class="no-internet-connection-error-container">
    <div class="no-internet-connection-error-container__content">
      <img
        class="no-internet-connection-error-container__content__icon"
        :src="require('@/assets/images/icons/no-connection.svg')"
        :alt="$t('No hay conexión a internet')"
      />
      <div class="no-internet-connection-error-container__content__message">
        <h2
          class="no-internet-connection-error-container__content__message__title"
        >
          {{ $t('No hay conexión a internet') }}
        </h2>
        <div
          class="no-internet-connection-error-container__content__message__description"
        >
          <p>
            {{
              $t(
                'Para visualizar correctamente esta sección, necesitas tener acceso a internet'
              )
            }}
          </p>
          <p>
            {{ $t('Mientras tanto, puedes seguir navegando nuestro sitio.') }}
          </p>
        </div>
        <div
          class="no-internet-connection-error-container__content__message__action"
        >
          <AppButtonTextRouterLink
            class="button"
            :to="homeLink"
            :text="$t('Seguir Navegando')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppButtonTextRouterLink from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonTextRouterLink'
import HomeRouteObjectGenerator from '@/classes/RouteObjectGenerators/HomeRouteObjectGenerator'

export default {
  name: 'NoInternetConnection',
  components: {
    AppButtonTextRouterLink,
  },
  data() {
    return {
      homeLink: HomeRouteObjectGenerator.make(),
    }
  },
}
</script>

<style lang="scss" scoped>
.no-internet-connection-error-container {
  @include inner-height;
  display: flex;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: var(--grey-background-color);
  @at-root #{&}__main {
    overflow: hidden;
    flex: 1;
    height: 100%;
  }
  @at-root #{&}__content {
    margin: 154px auto;
    padding: 20px 16px;
    max-width: 816px;
    display: flex;
    @at-root #{&}__icon {
      width: 100px;
      height: fit-content;
    }
    @at-root #{&}__message {
      flex: 1;
      align-items: center;
      margin-top: 9px;
      margin-left: 32px;
      @at-root #{&}__title {
        font-size: 1.5rem;
        line-height: 1.2;
        font-weight: $font-weight-bold;
        color: var(--brownish-grey);
        margin-bottom: 10px;
      }
      @at-root #{&}__description {
        font-size: 1rem;
        padding-bottom: 24px;
        line-height: 1.5;
        font-weight: $font-weight-regular;
        color: var(--gray);
        p {
          margin: 0;
        }
      }
      @at-root #{&}__action {
        width: max-content;
      }
    }
  }
}
</style>
