<template>
  <button
    class="button-text-with-icon"
    :class="classNames"
    :style="style"
    :disabled="disabled"
  >
    <span v-if="text" class="button-text-with-icon__text">
      <span ref="textInner" class="button-text-with-icon__text__inner">
        {{ text }}
      </span>
    </span>
    <span v-if="icon" class="button-text-with-icon__icon-wrapper">
      <AppIcon class="button-text-with-icon__icon" :size="size" :icon="icon" />
    </span>
  </button>
</template>

<script>
import AppIcon from '@/components/UIKit/Standard/Atoms/AppIcon'
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'
import iconNames from '@/constants/ui-kit/standard/icon-names'

export default {
  name: 'AppButtonTextWithIcon',
  components: {
    AppIcon,
  },
  props: {
    icon: {
      type: String,
      validator: (value) => Object.values(iconNames).includes(value),
    },
    text: {
      type: String,
    },
    buttonStyle: {
      type: String,
      default: styles.DARK,
      validator: (value) => Object.values(styles).includes(value),
    },
    size: {
      type: String,
      default: sizes.SM,
      validator: (value) => Object.values(sizes).includes(value),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    collapsable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      textWidth: 0,
      expanded: false,
      expandHintTimer: null,
      expandHintDuration: 4000,
    }
  },
  computed: {
    hasText() {
      return !!this.text
    },
    hasIcon() {
      return !!this.icon
    },
    isTextOnly() {
      return this.hasText && !this.hasIcon
    },
    isIconOnly() {
      return this.hasIcon && !this.hasText
    },
    isCollapsable() {
      return this.collapsable && this.hasText && this.hasIcon
    },
    styleClassName() {
      return `button-text-with-icon--${this.buttonStyle}`
    },
    sizeClassName() {
      return `button-text-with-icon--${this.size}`
    },
    classNames() {
      return [
        this.styleClassName,
        this.sizeClassName,
        {
          'button-text-with-icon--reverse': this.reverse,
          'button-text-with-icon--text-only': this.isTextOnly,
          'button-text-with-icon--icon-only': this.isIconOnly,
          'button-text-with-icon--collapsable': this.isCollapsable,
          'button-text-with-icon--expanded':
            this.isCollapsable && this.expanded,
        },
      ]
    },
    style() {
      return {
        '--js-calculated-text-width': `${this.textWidth}px`,
      }
    },
  },
  mounted() {
    this.updateTextWidth()
  },
  updated() {
    this.updateTextWidth()
  },
  beforeDestroy() {
    clearTimeout(this.expandHintTimer)
  },
  methods: {
    updateTextWidth() {
      this.textWidth = this.$refs.textInner?.offsetWidth || 0
    },
    hint() {
      if (this.isCollapsable) {
        this.expanded = true
        this.expandHintTimer = setTimeout(() => {
          this.expanded = false
        }, this.expandHintDuration)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'app-button.mixin';
.button-text-with-icon {
  @include button-styles;
  @include button-text-with-icon-sizes;
  @include button-alternatives;
  @include noselect;
}
</style>
