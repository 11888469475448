<template>
  <AppCardContainer :to="unitEnterToLink">
    <AppCardCover :preview-image-url="unit.previewImageUrl">
      <AppCardIndicator
        v-if="shouldDisplayTour360Indicator"
        :icon="tour360Icon"
        :text="$t('Tour 360º')"
      />
    </AppCardCover>
    <AppCardBody>
      <AppCardBadges
        :type-badge-text="typeBadgeText"
        :availability-badge-text="availabilityBadgeText"
        :availability-badge-color="unit.availabilityColor"
      />
      <AppCardTitle :text="unit.name" />
      <AppCardDescription :text="unit.model" />
      <AppCardPrice v-if="shouldDisplayPrice" :price="unit.price" />
      <AppCardFeatureList
        v-if="shouldDisplayFeatures"
        :features="unit.mainFeatures"
      />
    </AppCardBody>
  </AppCardContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import AppCardContainer from '@/components/UIKit/Standard/Atoms/AppCardContainer'
import AppCardBody from '@/components/UIKit/Standard/Atoms/AppCardBody'
import AppCardBadges from '@/components/UIKit/Standard/Atoms/AppCardBadges'
import AppCardTitle from '@/components/UIKit/Standard/Atoms/AppCardTitle'
import AppCardDescription from '@/components/UIKit/Standard/Atoms/AppCardDescription'
import AppCardPrice from '@/components/UIKit/Standard/Atoms/AppCardPrice'
import AppCardFeatureList from '@/components/UIKit/Standard/Atoms/AppCardFeatureList'
import AppCardCover from '@/components/UIKit/Standard/Molecules/AppCardCover'
import AppCardIndicator from '@/components/UIKit/Standard/Molecules/AppCardIndicator'
import Unit from '@/classes/ProjectStructure/Unit/Unit'
import UnitAccessRouterLinks from '@/classes/UnitAccessRouterLinks'
import unitTypesTranslationKeys from '@/constants/units/unit-types-translation-keys'
import iconNames from '@/constants/ui-kit/standard/icon-names'
import UnitAvailabilityStatusTranslationFactory from '@/classes/UnitAvailabilityStatusTranslationFactory'

export default {
  name: 'AppUnitCard',
  components: {
    AppCardContainer,
    AppCardBody,
    AppCardCover,
    AppCardIndicator,
    AppCardBadges,
    AppCardTitle,
    AppCardDescription,
    AppCardPrice,
    AppCardFeatureList,
  },
  props: {
    unit: {
      type: Unit,
      required: true,
    },
    withLink: {
      type: Boolean,
      default: false,
    },
    disableTour360Icon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tour360Icon: iconNames.UICube3D,
    }
  },
  computed: {
    ...mapGetters({
      getUnitPrice: 'Topview/getUnitPrice',
      displayAvailability: 'Preferences/displayAvailability',
      displayPrice: 'Preferences/displayPrice',
      isQuoterModuleEnabled: 'QuoterModule/isQuoterModuleEnabled',
    }),
    isUnitAvailable() {
      return this.unit.available
    },
    unitPrice() {
      return this.isUnitAvailable ? this.getUnitPrice(this.unit) : undefined
    },
    unitAccessRouterLinks() {
      try {
        return new UnitAccessRouterLinks(this.unit, this.$loggingService)
      } catch (error) {
        this.$loggingService.logError(
          `AppUnitCard.unitAccessRouterLinks: error creating UnitAccessRouterLinks. ${error.toString()}`
        )
        return null
      }
    },
    unitEnterToLink() {
      return this.withLink ? this.unitAccessRouterLinks.default : undefined
    },
    availabilityBadgeText() {
      return this.displayAvailability
        ? this.$t(
            UnitAvailabilityStatusTranslationFactory.makeTranslationKeyByUnit(
              this.unit
            )
          )
        : undefined
    },
    availabilityBadgeColor() {
      return this.displayAvailability ? this.unit.availabilityColor : undefined
    },
    typeBadgeText() {
      return unitTypesTranslationKeys[this.unit.type]
    },
    shouldDisplayTour360Indicator() {
      return !!this.unitAccessRouterLinks.tour360 && !this.disableTour360Icon
    },
    shouldDisplayFeatures() {
      return this.unit.mainFeatures.length > 0
    },
    shouldDisplayPrice() {
      return (
        !this.isQuoterModuleEnabled && this.displayPrice && !!this.unitPrice
      )
    },
  },
}
</script>
