<template>
  <div class="top-bar" :style="menuIconWidthComputedStyle">
    <TopBarResizableBlock :offset="offset" :menu-offset="computedMenuOffset">
      <section class="top-bar__main">
        <TheTopBarBackButton />
        <div class="top-bar__nav">
          <TopBarControlsGroup
            v-if="shouldDisplayNavigationButtons"
            :controls="controls.leftControls"
          />
          <TopBarControlsGroup
            v-if="shouldDisplayNavigationButtons"
            :controls="controls.rightControls"
          />
        </div>
        <TheTopBarFullscreenButton v-if="shouldDisplayFullscreenButton" />
      </section>
      <div class="top-bar__extension">
        <slot name="pointer-events-disabled-relative-slot"></slot>
      </div>
    </TopBarResizableBlock>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TheTopBarBackButton from '@/components/Gadgets/TheTopBar/snippets/TheTopBarBackButton'
import TheTopBarFullscreenButton from '@/components/Gadgets/TheTopBar/snippets/TheTopBarFullscreenButton'
import TopBarControlsSuite from '@/classes/TopBarControls/TopBarControlsSuite'
import TopBarControlsGroup from '@/components/Gadgets/TheTopBar/snippets/TopBarControlsGroup'
import TopBarResizableBlock from '@/components/Gadgets/TheTopBar/snippets/TopBarResizableBlock'

export default {
  name: 'TheTopBar',
  components: {
    TheTopBarBackButton,
    TheTopBarFullscreenButton,
    TopBarControlsGroup,
    TopBarResizableBlock,
  },
  props: {
    withFullscreenButton: {
      type: Boolean,
      default: false,
    },
    controls: {
      type: TopBarControlsSuite,
      required: true,
    },
    offset: {
      type: Number,
      default: 0,
    },
    menuOffset: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      isOfflineModeEnabled: 'Project/isOfflineModeEnabled',
      allowFullscreenNavigation: 'Preferences/allowFullscreenNavigation',
    }),
    isFullscreenNavigationAllowed() {
      return (
        this.allowFullscreenNavigation &&
        this.$fullscreenHandler.supportsFullscreenNavigation
      )
    },
    shouldDisplayFullscreenButton() {
      if (this.isOfflineModeEnabled) {
        return false
      } else if (this.isFullscreenNavigationAllowed) {
        return true
      } else {
        return this.withFullscreenButton
      }
    },
    fullscreenChangeExtraEventName() {
      return this.isFullscreenNavigationAllowed
        ? 'navigable-fullscreen-change'
        : 'restricted-fullscreen-change'
    },
    isFullscreen() {
      return this.$fullscreenHandler.isFullscreen
    },
    shouldDisplayNavigationButtons() {
      return this.isFullscreenNavigationAllowed || !this.isFullscreen
    },
    menuIconWidthComputedStyle() {
      const MENU_ICON_WIDTH_IN_PX = 56
      const menuIconWidthNumber =
        this.menuOffset > 0 ? 0 : MENU_ICON_WIDTH_IN_PX

      return `--menu-icon-width: ${menuIconWidthNumber}px`
    },
    computedMenuOffset() {
      const shouldDisplayBackButton = !!this.$navigationHistoryManager.lastRoute

      return shouldDisplayBackButton ? this.menuOffset : 0
    },
  },
  watch: {
    isFullscreen(value) {
      const fullscreenEventData = { isFullscreen: value }
      this.$emit('fullscreen-change', fullscreenEventData)
      this.$emit(this.fullscreenChangeExtraEventName, fullscreenEventData)
    },
  },
  beforeDestroy() {
    if (!this.isFullscreenNavigationAllowed) {
      this.$fullscreenHandler.exit()
    }
  },
}
</script>

<style lang="scss" scoped>
.top-bar {
  --menu-icon-width: 0;
  position: fixed;
  top: 0;
  right: 0;
  padding: 16px;
  width: 100%;
  pointer-events: none;

  @at-root &__main {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: stretch;
    gap: 16px;
    min-height: 2.5rem;
    transition: margin-left 600ms cubic-bezier(0.15, 0, 0, 1);
    margin-left: calc(var(--menu-icon-width) + var(--menu-offset));
  }

  @at-root &__extension {
    position: relative;
    z-index: 0;
    margin-top: 1.25rem;
    &:empty {
      display: none;
    }
  }

  @at-root &__nav {
    position: relative;
    display: flex;
    flex: 1;
    justify-content: space-between;
  }
}
</style>
