import BaseMenuOption from '@/classes/Navigation/MenuOption/BaseMenuOption'

export default class DownloableMenuOption extends BaseMenuOption {
  constructor(rawData) {
    super(rawData)

    this._downloadUrl = null
    this._fileNameTranslationKey = rawData.fileNameTranslationKey
    this._downloadFileNameParams = {}
  }

  addFileNameParam({ key, value }) {
    this._downloadFileNameParams[key] = value
  }

  get downloadUrl() {
    return this._downloadUrl
  }

  set downloadUrl(downloadUrl) {
    this._downloadUrl = downloadUrl
  }

  get downloadFileNameParams() {
    return this._downloadFileNameParams
  }

  get fileNameTranslationKey() {
    return this._fileNameTranslationKey
  }
}
