<template>
  <div
    class="full-viewport-background-element"
    :class="{ 'full-viewport-background-element--scrollable': scrollable }"
  >
    <div class="full-viewport-background-element__inner">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FullViewportBackgroundElement',
  props: {
    scrollable: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.full-viewport-background-element {
  position: relative;
  overflow: hidden;
  pointer-events: none;
  @include tm-media-background;

  @at-root &__inner {
    position: relative;
    aspect-ratio: $default-content-aspect-ratio;
    width: 100%;
    transform: translateY(calc((100vh - 100%) / 2));

    @media screen and (max-aspect-ratio: $default-content-aspect-ratio) {
      width: auto;
      height: 100%;
      transform: translateX(calc((100vw - 100%) / 2));
    }

    & > * {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  @at-root &--scrollable &__inner {
    @include horizontal-scroll-enabled {
      transform: none;
    }
  }
}
</style>
