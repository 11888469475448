<template>
  <p class="unit-card-indicator">
    <AppIcon class="unit-card-indicator__icon" :icon="icon" :size="iconSize" />
    <span class="unit-card-indicator__text">{{ text }}</span>
  </p>
</template>

<script>
import AppIcon from '@/components/UIKit/Standard/Atoms/AppIcon'
import sizes from '@/constants/ui-kit/standard/sizes'
import iconNames from '@/constants/ui-kit/standard/icon-names'

export default {
  name: 'AppCardIndicator',
  components: {
    AppIcon,
  },
  props: {
    icon: {
      type: String,
      required: true,
      validator: (value) => Object.values(iconNames).includes(value),
    },
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      iconSize: sizes.XS,
    }
  },
}
</script>

<style lang="scss" scoped>
.unit-card-indicator {
  margin: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 6px;

  @at-root &__icon {
    fill: var(--white);
    stroke: var(--white);
  }

  @at-root &__text {
    color: var(--white);
    font-size: 0.75rem;
    font-weight: var(--font-weight-medium);
  }
}
</style>
