class ShareLinkGenerator {
  constructor(urlToShare) {
    this.urlToShare = urlToShare
    this.encodedUrlToShare = encodeURIComponent(this.urlToShare)
  }
  getHref() {
    return this.urlToShare
  }
}

class FacebookShareLinkGenerator extends ShareLinkGenerator {
  getHref() {
    return `https://www.facebook.com/sharer/sharer.php?u=${this.encodedUrlToShare}`
  }
}

class WhatsappShareLinkGenerator extends ShareLinkGenerator {
  getHref() {
    return `https://wa.me/?text=${this.encodedUrlToShare}`
  }
}

export { FacebookShareLinkGenerator, WhatsappShareLinkGenerator }
export default ShareLinkGenerator
