import BaseTransitionParams from './BaseTransitionParams'
import TransitionVideoDownloadError from '@/classes/Exceptions/TransitionVideoDownloadError'
import httpClient from '@/constants/api/http-client'

class VideoTransitionParams extends BaseTransitionParams {
  constructor({ url }) {
    super()
    if (!url) {
      throw new Error('VideoTransitionParams: Required param "url" is missing.')
    }

    this._url = url
    this._videoBlobUrl = null
    this._ready = false
    this._downloading = false
  }
  download() {
    if (this._ready) {
      return
    }

    this._downloading = true
    return httpClient({
      url: this._url,
      method: 'GET',
      responseType: 'blob',
    })
      .then((response) => {
        this._videoBlobUrl = window.URL.createObjectURL(
          new Blob([response.data], { type: 'video/mp4' })
        )
        this._ready = true
      })
      .catch((error) => {
        throw new TransitionVideoDownloadError(this._url, error)
      })
      .finally(() => {
        this._downloading = false
      })
  }
  get ready() {
    return this._ready
  }
  get downloading() {
    return this._downloading
  }
  get videoBlobUrl() {
    return this._videoBlobUrl
  }
  static get TYPE() {
    return 'video'
  }
}
export default VideoTransitionParams
