<template>
  <div>
    <button @click="openLevelsmenu">Open LevelsMenu from Ref</button>
    <AppLevelsMenu
      ref="levelsMenu"
      :level-menu-items="levelMenuItems"
      @width-updated="widthUpdated"
    />
  </div>
</template>

<script>
import AppLevelsMenu from '@/components/UIKit/Standard/Molecules/AppLevelsMenu'

export default {
  name: 'LevelsMenuViewer',
  components: {
    AppLevelsMenu,
  },
  data() {
    return {
      levelMenuItems: [
        {
          displayName: '25°',
          routeObject: {
            name: 'ui-kit-viewer',
            params: { componentName: 'LevelsMenu', mockRouteParam: '25' },
          },
        },
        {
          displayName: '24°',
          routeObject: {
            name: 'ui-kit-viewer',
            params: { componentName: 'LevelsMenu', mockRouteParam: '24' },
          },
        },
        {
          displayName: '23°',
          routeObject: {
            name: 'ui-kit-viewer',
            params: { componentName: 'LevelsMenu', mockRouteParam: '23' },
          },
        },
        {
          displayName: '22°',
          routeObject: {
            name: 'ui-kit-viewer',
            params: { componentName: 'LevelsMenu', mockRouteParam: '22' },
          },
        },
        {
          displayName: '21°',
          routeObject: {
            name: 'ui-kit-viewer',
            params: { componentName: 'LevelsMenu', mockRouteParam: '21' },
          },
        },
        {
          displayName: '20°',
          routeObject: {
            name: 'ui-kit-viewer',
            params: { componentName: 'LevelsMenu', mockRouteParam: '20' },
          },
        },
        {
          displayName: '19°',
          routeObject: {
            name: 'ui-kit-viewer',
            params: { componentName: 'LevelsMenu', mockRouteParam: '19' },
          },
        },
        {
          displayName: '18°',
          routeObject: {
            name: 'ui-kit-viewer',
            params: { componentName: 'LevelsMenu', mockRouteParam: '18' },
          },
        },
        {
          displayName: '17°',
          routeObject: {
            name: 'ui-kit-viewer',
            params: { componentName: 'LevelsMenu', mockRouteParam: '17' },
          },
        },
        {
          displayName: '16°',
          routeObject: {
            name: 'ui-kit-viewer',
            params: { componentName: 'LevelsMenu', mockRouteParam: '16' },
          },
        },
        {
          displayName: '15°',
          routeObject: {
            name: 'ui-kit-viewer',
            params: { componentName: 'LevelsMenu', mockRouteParam: '15' },
          },
        },
        {
          displayName: '14°',
          routeObject: {
            name: 'ui-kit-viewer',
            params: { componentName: 'LevelsMenu', mockRouteParam: '14' },
          },
        },
        {
          displayName: '13°',
          routeObject: {
            name: 'ui-kit-viewer',
            params: { componentName: 'LevelsMenu', mockRouteParam: '13' },
          },
        },
        {
          displayName: '12°',
          routeObject: {
            name: 'ui-kit-viewer',
            params: { componentName: 'LevelsMenu', mockRouteParam: '12' },
          },
        },
        {
          displayName: '11°',
          routeObject: {
            name: 'ui-kit-viewer',
            params: { componentName: 'LevelsMenu', mockRouteParam: '11' },
          },
        },
        {
          displayName: '10°',
          routeObject: {
            name: 'ui-kit-viewer',
            params: { componentName: 'LevelsMenu', mockRouteParam: '10' },
          },
        },
        {
          displayName: '9°',
          routeObject: {
            name: 'ui-kit-viewer',
            params: { componentName: 'LevelsMenu', mockRouteParam: '9' },
          },
        },
        {
          displayName: '8°',
          routeObject: {
            name: 'ui-kit-viewer',
            params: { componentName: 'LevelsMenu', mockRouteParam: '8' },
          },
        },
        {
          displayName: '7°',
          routeObject: {
            name: 'ui-kit-viewer',
            params: { componentName: 'LevelsMenu', mockRouteParam: '7' },
          },
        },
        {
          displayName: '6°',
          routeObject: {
            name: 'ui-kit-viewer',
            params: { componentName: 'LevelsMenu', mockRouteParam: '6' },
          },
        },
        {
          displayName: '5°',
          routeObject: {
            name: 'ui-kit-viewer',
            params: { componentName: 'LevelsMenu', mockRouteParam: '5' },
          },
        },
        {
          displayName: '4°',
          routeObject: {
            name: 'ui-kit-viewer',
            params: { componentName: 'LevelsMenu', mockRouteParam: '4' },
          },
        },
        {
          displayName: '3°',
          routeObject: {
            name: 'ui-kit-viewer',
            params: { componentName: 'LevelsMenu', mockRouteParam: '3' },
          },
        },
        {
          displayName: '2°',
          routeObject: {
            name: 'ui-kit-viewer',
            params: { componentName: 'LevelsMenu', mockRouteParam: '2' },
          },
        },
        {
          displayName: '1°',
          routeObject: {
            name: 'ui-kit-viewer',
            params: { componentName: 'LevelsMenu', mockRouteParam: '1' },
          },
        },
        {
          displayName: 'PB',
          routeObject: {
            name: 'ui-kit-viewer',
            params: { componentName: 'LevelsMenu', mockRouteParam: '0' },
          },
        },
      ],
    }
  },
  methods: {
    openLevelsmenu() {
      this.$refs.levelsMenu.show()
    },
    widthUpdated(e) {
      console.log('>>>', e)
    },
  },
}
</script>

<style lang="scss" scoped></style>
