import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      transitionActive: 'transitionActive',
    }),
    UIControlsShown() {
      const { hideUIControlsDuringTransition } = this.$route.params
      if (hideUIControlsDuringTransition !== undefined) {
        if (this.transitionActive && hideUIControlsDuringTransition) {
          return false
        }
      }
      return true
    },
  },
}
