<template>
  <p class="unit-card-description">{{ text }}</p>
</template>

<script>
export default {
  name: 'AppCardDescription',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.unit-card-description {
  font-size: 0.875rem;
  font-weight: var(--font-weight-regular);
  line-height: 1.5;
  color: var(--gray-600);
  margin: 0;
}
</style>
