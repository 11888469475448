import Tower from '@/classes/ProjectStructure/Tower'
import Level from '@/classes/ProjectStructure/Level'
import ProjectElementTypes from '@/constants/project-structure/project-element-types'

export default {
  project: (state) => state.project,
  projectHasExteriorTour360Url: (state) => !!state.project.exteriorTour360Url,
  singleTower: (state) => {
    const idTowerMap = state.project.getDescendantsOfType(
      ProjectElementTypes.TOWER
    )
    return idTowerMap.values[0]
  },
  towers: (state) => {
    const descendantsGroup = state.project?.getDescendantsOfType(
      ProjectElementTypes.TOWER
    )
    return descendantsGroup?.values || []
  },
  levels: (state) => {
    const descendantsGroup = state.project?.getDescendantsOfType(
      ProjectElementTypes.LEVEL
    )
    return descendantsGroup?.values || []
  },
  units: (state) => {
    const descendantsGroup = state.project?.getDescendantsOfType(
      ProjectElementTypes.UNIT
    )
    return descendantsGroup?.values || []
  },
  neighbourhoodSubdivisions: (state) => {
    const descendantsGroup = state.project?.getDescendantsOfType(
      ProjectElementTypes.NEIGHBOURHOOD_SUBDIVISION
    )
    return descendantsGroup?.values || []
  },
  pointsOfInterest: (state) => {
    const descendantsGroup = state.project?.getDescendantsOfType(
      ProjectElementTypes.POINT_OF_INTEREST
    )
    return descendantsGroup?.values || []
  },
  projectSvgData: (state) => state.project.svgData,
  findTowerById: (state) => (towerId) => {
    const idTowerMap = state.project.getDescendantsOfType(
      ProjectElementTypes.TOWER
    )
    return idTowerMap.get(towerId)
  },
  findNeighbourhoodSubdivisionById: (state) => (neighbourhoodSubdivisionId) => {
    const idNeighbourhoodSubdivisionMap = state.project.getDescendantsOfType(
      ProjectElementTypes.NEIGHBOURHOOD_SUBDIVISION
    )
    return idNeighbourhoodSubdivisionMap.get(neighbourhoodSubdivisionId)
  },
  findLevelById: (state) => (levelId) => {
    const idLevelMap = state.project.getDescendantsOfType(
      ProjectElementTypes.LEVEL
    )
    return idLevelMap.get(levelId)
  },
  findUnitById: (state) => (unitId) => {
    const idUnitMap = state.project.getDescendantsOfType(
      ProjectElementTypes.UNIT
    )
    return idUnitMap.get(unitId)
  },
  findPointOfInterestById: (state) => (pointOfInterestId) => {
    const idPointOfInterestMap = state.project.getDescendantsOfType(
      ProjectElementTypes.POINT_OF_INTEREST
    )
    return idPointOfInterestMap.get(pointOfInterestId)
  },
  findTowerByChildLevelId: (_state, getters) => (levelId) => {
    const parentElement = getters.findLevelById(levelId)?.parent()
    return parentElement instanceof Tower ? parentElement : undefined
  },
  findLevelByChildUnitId: (_state, getters) => (unitId) => {
    const parentElement = getters.findUnitById(unitId)?.parent()
    return parentElement instanceof Level ? parentElement : undefined
  },
  selectedSvgElement: (state) => {
    return state.selectedSvgElement
  },
  unitInfoRequestFormDetails: (state) => {
    return state.unitInfoRequestFormDetails
  },
  getUnitPrice: (_state, _getters, _rootState, rootGetters) => (unit) => {
    const pricesFeatureFlagEnabled =
      rootGetters['FeatureFlags/pricesFeatureFlagEnabled']
    if (pricesFeatureFlagEnabled && unit.price) {
      return unit.price
    }
    return null
  },
}
