<template>
  <AppCard class="app-popup-wrapper">
    <section>
      <slot></slot>
    </section>
  </AppCard>
</template>

<script>
import AppCard from '@/components/UIKit/Standard/Atoms/AppCard'

export default {
  name: 'AppPopupWrapper',
  components: {
    AppCard,
  },
}
</script>

<style lang="scss" scoped>
.app-popup-wrapper {
  position: fixed;
  z-index: 15;
  will-change: transform;
}
</style>
