<template>
  <div class="standalone-quoter">
    <h1>{{ $t('Cotizar unidad') }}</h1>
    <AppQuoteWizard v-if="unitObject" :unit="unitObject" />
    <p v-else class="error">
      {{ $t('La unidad seleccionada no puede ser cotizada.') }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AppQuoteWizard from '@/components/UIKit/Standard/Organisms/AppQuoteWizard/AppQuoteWizard'

export default {
  name: 'StandaloneQuoter',
  components: {
    AppQuoteWizard,
  },
  props: {
    unitId: {
      type: String,
      default: () => undefined,
    },
  },
  computed: {
    ...mapGetters({
      findUnitById: 'Topview/findUnitById',
      canUnitBeQuoted: 'QuoterModule/canUnitBeQuoted',
    }),
    unitObject() {
      const unitToQuote = this.findUnitById(this.unitId)
      return this.canUnitBeQuoted(unitToQuote) ? unitToQuote : undefined
    },
  },
}
</script>

<style lang="scss" scoped>
.standalone-quoter {
  h1 {
    font-size: 1.125rem;
    font-weight: var(--font-weight-medium);
    color: var(--gray-700);
    background-color: #fff;
    padding: 1.25rem;
    margin: 0;
    border-bottom: 1px solid var(--gray-100);
    position: sticky;
    top: 0;
    z-index: 2;
  }
  .error {
    font-size: 0.875rem;
    font-weight: var(--font-weight-regular);
    text-align: center;
    color: var(--gray-700);
    margin: 3rem 0;
    padding: 0 1.25rem;
  }
}
</style>
