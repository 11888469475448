import LoggingServiceFactory from '@/classes/LoggingService/LoggingServiceFactory'
import iconNames from '@/constants/ui-kit/standard/icon-names'
import DOMPurify from 'dompurify'

export default class CustomContentBlock {
  constructor({ icon, title, html }) {
    this._loggingService = new LoggingServiceFactory().makeService()
    this._isValid = true

    if (typeof title === 'string' && title.trim().length) {
      this._title = title
    } else {
      this._isValid = false
      this._loggingService.logError(`
        CustomContentBlock: invalid title 
      `)
    }

    if (this._isIconNameValid(icon)) {
      this._icon = icon
    } else {
      this._isValid = false
      this._loggingService.logError(`
        CustomContentBlock (${title}): Icon name "${icon}" is not supported.
      `)
    }

    if (typeof html === 'string' && html.trim().length) {
      this._html = this._sanitizeHtmlContent(html)
    } else {
      this._loggingService.logError(`
        CustomContentBlock (${title}): Does not have html content
      `)
    }
  }

  _isIconNameValid(iconName) {
    return Object.values(iconNames).includes(iconName)
  }

  _sanitizeHtmlContent(html) {
    console.log(html)
    return DOMPurify.sanitize(html, {
      ALLOWED_TAGS: ['b', 'i', 'em', 's', 'strong', 'p', 'br', 'span'],
    })
  }

  get title() {
    return this._title
  }
  get icon() {
    return this._icon
  }
  get html() {
    return this._html
  }
  get isValid() {
    return this._isValid
  }
}
