import CommonProxy from '@/classes/utilities/CommonProxy'
import stopPointSlugs from '@/constants/stop-point-slugs'

export default class EnterTransitionVideos {
  constructor() {
    this._enterTransitionVideos = []
  }
  add(stopPointSlug, videoUrl) {
    if (!Object.values(stopPointSlugs).includes(stopPointSlug)) {
      throw new Error(
        `EnterTransitionVideos.add(): Invalid stopPointSlug ${stopPointSlug}.`
      )
    }
    if (this._isThereAlreadyAVideoForTheStopPointSlug(stopPointSlug)) {
      throw new Error(
        `EnterTransitionVideos.add(): Transition video for stopPointSlug ${stopPointSlug} already exists.`
      )
    }
    if (!(typeof videoUrl === 'string') || videoUrl.trim() === '') {
      throw new Error(
        `EnterTransitionVideos.add(): Invalid videoUrl ${videoUrl}.`
      )
    }
    this._enterTransitionVideos.push(
      CommonProxy.readOnly({ url: videoUrl, stopPointSlug })
    )
  }
  _isThereAlreadyAVideoForTheStopPointSlug(stopPointSlug) {
    return this._enterTransitionVideos.some(
      (item) => item.stopPointSlug === stopPointSlug
    )
  }
  get list() {
    return this._enterTransitionVideos
  }
}
