import NavigationHistoryStepCheckersNames from '@/constants/navigation-history/navigation-history-step-checkers-names'
import GeneralNavigationHistoryStepChecker from './GeneralNavigationHistoryStepChecker'
import TopviewNavigationHistoryStepChecker from './TopviewNavigationHistoryStepChecker'
import NeighbourhoodSectionNavigationHistoryStepChecker from './NeighbourhoodSectionNavigationHistoryStepChecker'

export default class NavigationHistoryStepCheckerFactory {
  constructor() {
    this.navigationStepsCheckers = {
      [NavigationHistoryStepCheckersNames.GENERAL_NAVIGATION_STEP_CHECKER]: GeneralNavigationHistoryStepChecker,
      [NavigationHistoryStepCheckersNames.TOPVIEW_NAVIGATION_STEP_CHECKER]: TopviewNavigationHistoryStepChecker,
      [NavigationHistoryStepCheckersNames.NEIGHBOURHOOD_SECTION_NAVIGATION_STEP_CHECKER]: NeighbourhoodSectionNavigationHistoryStepChecker,
    }
  }

  _isCheckerNameSupported(checkerName) {
    const supportedCheckersNames = Object.keys(this.navigationStepsCheckers)

    return supportedCheckersNames.includes(checkerName)
  }

  makeChecker(checkerName, projectRootElement) {
    if (!this._isCheckerNameSupported(checkerName)) {
      throw new Error(
        'NavigationHistoryStepCheckerFactory: CheckerName passed is not supported'
      )
    }

    const selectedCheckerClass = this.navigationStepsCheckers[checkerName]

    return new selectedCheckerClass(projectRootElement)
  }
}
