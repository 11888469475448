<template>
  <TheFullViewportContainer class="gallery">
    <ThePaginationControls
      :page-routes="collectionPages"
      @unrecognized-route="onUnrecognizedRoute"
    />
    <gallery-top-bar-controls :label-text="labelText" />
    <modal-hint-container
      v-if="shouldDisplayModalHint"
      hint-key="HorizontalScrollHint"
    ></modal-hint-container>
  </TheFullViewportContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import ThePaginationControls from '@/components/Gadgets/ThePaginationControls'
import responsiveMixin from '@/mixins/responsiveMixin'
import TheFullViewportContainer from '@/components/Gadgets/TheFullViewportContainer'
import ModalHintContainer from '@/components/Hint/ModalHintContainer'
import HomeRouteObjectGenerator from '@/classes/RouteObjectGenerators/HomeRouteObjectGenerator'
import GalleryTopBarControls from '@/components/ViewTopBarControls/GalleryTopBarControls'

export default {
  name: 'Gallery',
  components: {
    ThePaginationControls,
    TheFullViewportContainer,
    ModalHintContainer,
    GalleryTopBarControls,
  },
  mixins: [responsiveMixin],
  props: {
    resourceId: {
      type: String,
      default: '1',
    },
  },
  computed: {
    ...mapGetters({
      gallery: 'Project/gallery',
    }),
    currentCollection() {
      return this.gallery.defaultCollection
    },
    labelText() {
      if (this.currentCollection) {
        try {
          return this.currentCollection.getResourceById(this.resourceId)
            .labelText
        } catch (e) {
          this.$loggingService.logError(
            `Gallery: Could not get requested gallery resource ${
              this.resourceId
            }:  ${e.toString()}`
          )
        }
      }
      return ''
    },
    collectionPages() {
      const routerLinks = []
      for (let i = 1; i <= this.currentCollection.size; i++) {
        const resourceId = i.toString()
        routerLinks.push({
          name: this.$route.name,
          params: {
            useTransition: true,
            collectionSlug: this.currentCollection.slug,
            resourceId,
          },
        })
      }
      return routerLinks
    },
    shouldDisplayModalHint() {
      return this.isSmallScreenVertical
    },
  },
  mounted() {
    if (this.isSmallScreenVertical) {
      this.$store.dispatch('Layout/setTopLayerCurrentHint', 'RotateDeviceHint')
    }
  },
  methods: {
    onUnrecognizedRoute({ alternativeRoute }) {
      const homeRouteObject = HomeRouteObjectGenerator.make()
      const redirectionRoute = alternativeRoute || homeRouteObject
      redirectionRoute.params.useTransition = false
      this.$loggingService.logError(
        `Gallery: The current route '${this.$route.path}' is not recognized in the ThePaginationControls as a valid option. ` +
          `Redirecting to: ${JSON.stringify(redirectionRoute)}.`
      )
      this.$router.replace(redirectionRoute)
    },
  },
}
</script>

<style lang="scss" scoped>
.gallery {
  position: relative;
  z-index: 320;
}
</style>
