<template>
  <AppSheetUnit
    :title="unitName"
    :features="unitFeatures"
    :enter-to="unitAccessRouterLinks.default"
    :tour360-to="unitAccessRouterLinks.tour360"
    :availability-status="unitDisplayAvailabilityText"
    :availability-color="unitAvailabilityColor"
    :price="unitPrice"
    :with-embedded-form-button="shouldDisplayEmbeddedFormButton"
    :with-unavailable-message="shouldDisplayUnavailableMessage"
    :with-check-price-button="shouldDisplayCheckPriceButton"
    :with-quoter-button="shouldDisplayQuoterButton"
    :embedded-contact-form-button-text="unitEmbeddedContactFormButtonText"
    @request-price="openUnitInfoRequestPrice"
    @launch-quoter="openUnitQuoter"
    @launch-embedded-contact-form="openUnitEmbeddedContactForm"
  />
</template>

<script>
import AppSheetUnit from '@/components/UIKit/Standard/Organisms/AppSheet/AppSheetUnit'
import UnitAvailabilityStatusTranslationFactory from '@/classes/UnitAvailabilityStatusTranslationFactory'
import Unit from '@/classes/ProjectStructure/Unit/Unit'
import UnitAccessRouterLinks from '@/classes/UnitAccessRouterLinks'
import { mapGetters } from 'vuex'

import modalSupportedComponents from '@/constants/ui-kit/standard/modal/modal-supported-components'

export default {
  name: 'UnitPopupContent',
  components: {
    AppSheetUnit,
  },
  props: {
    unit: {
      type: Unit,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getUnitPrice: 'Topview/getUnitPrice',
      enableCheckPriceForm: 'Preferences/enableCheckPriceForm',
      displayAvailability: 'Preferences/displayAvailability',
      displayPrice: 'Preferences/displayPrice',
      isQuoterModuleEnabled: 'QuoterModule/isQuoterModuleEnabled',
      canUnitBeQuoted: 'QuoterModule/canUnitBeQuoted',
      isUnitContactEmbeddedFormAvailable:
        'EmbeddedFormsModule/isUnitContactEmbeddedFormAvailable',
      unitEmbeddedContactFormButtonText:
        'EmbeddedFormsModule/unitEmbeddedContactFormButtonText',
      unitEmbeddedContactFormUrl:
        'EmbeddedFormsModule/unitEmbeddedContactFormUrl',
      hideQuoterButton: 'Preferences/hideQuoterButton',
    }),
    unitName() {
      return this.unit.name
    },
    unitFeatures() {
      return this.unit.features
    },
    unitAccessRouterLinks() {
      try {
        return new UnitAccessRouterLinks(this.unit, this.$loggingService)
      } catch (error) {
        this.$loggingService.logError(
          `UnitEnterButtons.unitAccessRouterLinks: error creating UnitAccessRouterLinks. ${error.toString()}`
        )
        return null
      }
    },
    unitAvailabilityColor() {
      return this.displayAvailability ? this.unit.availabilityColor : undefined
    },
    unitDisplayAvailabilityText() {
      return this.displayAvailability
        ? this.$t(
            UnitAvailabilityStatusTranslationFactory.makeTranslationKeyByUnit(
              this.unit
            )
          )
        : undefined
    },
    shouldDisplayUnavailableMessage() {
      return this.displayAvailability && !this.unit.available
    },
    shouldDisplayPrice() {
      return (
        !this.isQuoterModuleEnabled &&
        this.displayPrice &&
        !this.shouldDisplayUnavailableMessage
      )
    },
    unitPrice() {
      return this.shouldDisplayPrice ? this.getUnitPrice(this.unit) : undefined
    },
    shouldDisplayCheckPriceButton() {
      if (
        this.shouldDisplayQuoterButton ||
        this.shouldDisplayEmbeddedFormButton
      ) {
        return false
      }
      return (
        this.enableCheckPriceForm &&
        !this.unitPrice &&
        !this.shouldDisplayUnavailableMessage
      )
    },
    shouldDisplayEmbeddedFormButton() {
      if (this.shouldDisplayQuoterButton) {
        return false
      }
      return (
        this.isUnitContactEmbeddedFormAvailable &&
        !this.shouldDisplayUnavailableMessage
      )
    },
    shouldDisplayQuoterButton() {
      if (this.hideQuoterButton) {
        return false
      }
      const canUnitBeQuoted = this.canUnitBeQuoted(this.unit)
      const isUnitExplicitlyUnavailable = this.shouldDisplayUnavailableMessage
      return !isUnitExplicitlyUnavailable && canUnitBeQuoted
    },
  },
  methods: {
    openUnitInfoRequestPrice() {
      this.$store.dispatch('Topview/openUnitInfoRequestForm', {
        unit: this.unit,
        requestPrice: true,
      })
    },
    openUnitQuoter() {
      this.$store.dispatch('QuoterModule/openQuoterModal', this.unit)
    },
    openUnitEmbeddedContactForm() {
      this.$store.dispatch('ModalLauncher/launchModal', {
        component: modalSupportedComponents.AppEmbeddedContactFormModal,
        properties: {
          title: this.unitEmbeddedContactFormButtonText,
          formUrl: this.unitEmbeddedContactFormUrl + this.unit.slug,
        },
      })
    },
  },
}
</script>
