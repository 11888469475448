<template>
  <section>
    <VTitle text="AppSheetUnitLarge" />
    <div class="example-component">
      <div class="example-component__controls">
        <VPropControl
          v-for="(propModel, propName) in propModels"
          :key="propName"
          :model="propModel"
          :name="propName"
        />
      </div>
      <div class="example-component__component">
        <VComponentWrapper :width="375" :height="640" bordered resizable>
          <AppSheetProjectElementLarge
            :title="propModels.title.value"
            :subtitle="propModels.subtitle.value"
            :logo-image-url="propModels.logoImageUrl.value"
            :enter-to="propModels.enterTo.value"
            :exterior-tour360-to="propModels.exteriorTour360To.value"
          />
        </VComponentWrapper>
      </div>
    </div>
  </section>
</template>

<script>
import AppSheetProjectElementLarge from '@/components/UIKit/Standard/Organisms/AppSheet/AppSheetProjectElementLarge'
import AppPropModel from '@/components/UIKit/Standard/ViewerSections/classes/AppPropModel'
import VTitle from '@/components/UIKit/Standard/ViewerSections/components/snippets/VTitle'
import VPropControl from '@/components/UIKit/Standard/ViewerSections/components/snippets/VPropControl'
import VComponentWrapper from '@/components/UIKit/Standard/ViewerSections/components/snippets/VComponentWrapper'

export default {
  name: 'AppSheetProjectElementLargeViewer',
  components: {
    AppSheetProjectElementLarge,
    VTitle,
    VPropControl,
    VComponentWrapper,
  },
  data() {
    return {
      propModels: {
        title: new AppPropModel({
          type: String,
          defaultValue: 'The Sava Collection',
          required: true,
        }),
        subtitle: new AppPropModel({
          type: String,
          defaultValue: 'Vel eu adipiscing suspendisse molestie.',
        }),
        logoImageUrl: new AppPropModel({
          type: String,
        }),
        enterTo: new AppPropModel({
          type: Object,
          defaultValue: {},
        }),
        exteriorTour360To: new AppPropModel({
          type: Object,
          defaultValue: {},
        }),
      },
    }
  },
  created() {
    this.propModels.logoImageUrl.addPreset(
      '/resources/project-contents/neighbourhood/data/project/2022/03/menu_logo_img/db673c2e7d074d279e10d1097b621917',
      'Nbh logo'
    )
    this.propModels.logoImageUrl.addPreset(
      '/resources/project-contents/singletower/data/project/2020/07/menu_logo_img/c09465d68f6e4461997de824a88adce0',
      'ST Logo'
    )
  },
}
</script>

<style lang="scss" scoped>
.example-component {
  display: flex;
  justify-content: stretch;
  align-items: stretch;

  @at-root &__controls {
    background: #fff;
    padding: 30px;
    border: 1px solid var(--gray-200);
    flex-basis: 300px;
    border-radius: 12px;
  }

  @at-root &__component {
    padding: 0 30px;
  }
}
</style>
