import BaseMenuItem from '@/classes/Navigation/MenuItem/BaseMenuItem'
import DownloableMenuOption from '@/classes/Navigation/MenuOption/DownloableMenuOption'

export default class DownloableMenuItem extends BaseMenuItem {
  constructor(option) {
    if (!(option instanceof DownloableMenuOption)) {
      throw new Error(
        'DownloableMenuItem.constructor: Option passed is not an instance of class DownloableMenuOption'
      )
    }

    super(option)

    this._downloadUrl = option.downloadUrl
    this._downloadFileNameParams = option.downloadFileNameParams
    this._fileNameTranslationKey = option.fileNameTranslationKey
  }

  get downloadUrl() {
    return this._downloadUrl
  }

  get downloadFileParams() {
    return this._downloadFileNameParams
  }

  get fileNameTranslationKey() {
    return this._fileNameTranslationKey
  }
}
