class BaseApiEndpoints {
  constructor() {
    this._className = 'BaseApiEndpoints'
    this._endopointUrls = {}
  }
  getEndpointUrl(endpointName) {
    if (!Object.keys(this._endopointUrls).includes(endpointName)) {
      throw new Error(
        `${this._className}.getEndpointUrl(): Endpoint name "${endpointName}" is not recognized.`
      )
    }
    return this._endopointUrls[endpointName]
  }
}
export default BaseApiEndpoints
