<template>
  <TheFullViewportContainer class="general-tour360-gallery">
    <GeneralTour360GalleryTopBarControls
      class="general-tour360-gallery__top-bar"
      :selected-tour360="selectedTour360"
      :tour360-select-options="tour360SelectOptions"
      :should-tour360-selector-be-shown="shouldTopbarTour360SelectorBeShown"
      @navigate-to-tour360="navigateToTour360"
    />
    <AppDropdown
      v-if="shouldTour360DropdownBeShown"
      class="general-tour360-gallery__tour360-dropdown"
      :dropdown-style="tour360DropdownStyle"
      :options="tour360SelectOptions"
      :selected-value="selectedTour360"
      @selectedOptionChange="navigateToTour360"
    />
  </TheFullViewportContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import TheFullViewportContainer from '@/components/Gadgets/TheFullViewportContainer'
import AppDropdown from '@/components/UIKit/Standard/Molecules/AppDropdown'
import styles from '@/constants/ui-kit/standard/styles'
import responsiveMixin from '@/mixins/responsiveMixin'
import GeneralTour360GalleryTopBarControls from '@/components/ViewTopBarControls/GeneralTour360GalleryTopBarControls'

export default {
  name: 'GeneralTour360Gallery',
  components: {
    TheFullViewportContainer,
    AppDropdown,
    GeneralTour360GalleryTopBarControls,
  },
  mixins: [responsiveMixin],
  props: {
    resourceId: {
      type: String,
      default: '1',
    },
  },
  data() {
    return {
      tour360DropdownStyle: styles.LIGHT,
    }
  },
  computed: {
    ...mapGetters({
      generalTour360Gallery: 'Project/generalTour360Gallery',
    }),
    currentCollection() {
      return this.generalTour360Gallery.defaultCollection
    },
    tour360SelectOptions() {
      const options = []
      for (
        let position = 1;
        position <= this.currentCollection.size;
        position++
      ) {
        const resourceId = position.toString()
        options.push({
          value: resourceId,
          text: this.currentCollection.getResourceById(resourceId).labelText,
        })
      }
      return options
    },
    selectedTour360() {
      return `${this.$route.params.resourceId}`
    },
    shouldTour360SelectorBeShown() {
      return this.tour360SelectOptions.length > 1
    },
    shouldTopbarTour360SelectorBeShown() {
      return this.shouldTour360SelectorBeShown && !this.isSmallScreenVertical
    },
    shouldTour360DropdownBeShown() {
      return this.shouldTour360SelectorBeShown && this.isSmallScreenVertical
    },
    isResourceIdValid() {
      return (
        this.resourceId > 0 && this.resourceId <= this.currentCollection.size
      )
    },
  },
  mounted() {
    this.validateResourceId()
  },
  updated() {
    this.validateResourceId()
  },
  methods: {
    validateResourceId() {
      if (!this.isResourceIdValid) {
        this.$router.push({
          name: 'general-tour360-gallery',
          params: {
            resourceId: 1,
          },
        })
      }
    },
    navigateToTour360(selectedId) {
      const routeResourceId = this.$route.params.resourceId
      if (selectedId !== routeResourceId) {
        this.$router.push({
          name: 'general-tour360-gallery',
          params: {
            resourceId: selectedId,
          },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.general-tour360-gallery {
  @at-root #{&}__top-bar {
    z-index: 1;
    @at-root #{&}__tour360-select {
      @include small-screens {
        max-width: calc(100vw - 195px);
      }
    }
  }

  @at-root &__tour360-dropdown {
    --topbar-height: 72px;
    position: fixed;
    top: var(--topbar-height);
    left: 16px;
    margin-top: 5px;
  }
}
</style>
