<template>
  <div class="the-fullscreen-vr-ar-viewer">
    <AppButtonTextWithIcon
      v-if="!isSmallScreen"
      class="the-fullscreen-vr-ar-viewer__close-button"
      :text="$t('Salir de VR/AR')"
      :icon="backButtonIcon"
      :size="buttonSize"
      :button-style="buttonStyle"
      reverse
      @click.native="$emit('go-to-main-collection')"
    />
    <iframe :src="vrArUrl" frameborder="0" />
    <div v-if="isSmallScreen" class="the-fullscreen-vr-ar-viewer__mobile-panel">
      <AppButtonTextWithIcon
        :text="$t('Salir de VR/AR')"
        :icon="backButtonIcon"
        :size="mobileButtonSize"
        :button-style="mobileButtonStyle"
        reverse
        @click.native="$emit('go-to-main-collection')"
      />
    </div>
  </div>
</template>
<script>
import AppButtonTextWithIcon from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonTextWithIcon'
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'
import responsiveMixin from '@/mixins/responsiveMixin'
import iconNames from '@/constants/ui-kit/standard/icon-names'

export default {
  name: 'TheFullscreenVrArViewer',
  components: {
    AppButtonTextWithIcon,
  },
  mixins: [responsiveMixin],
  props: {
    vrArUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      buttonStyle: styles.DARK,
      buttonSize: sizes.XS,
      mobileButtonStyle: styles.PRIMARY_LINK,
      mobileButtonSize: sizes.XS,
      backButtonIcon: iconNames.UIBack,
    }
  },
}
</script>
<style lang="scss" scoped>
.the-fullscreen-vr-ar-viewer {
  position: fixed;
  height: 100dvh;
  width: 100%;
  background: #222;
  z-index: 400;
  display: flex;
  flex-direction: column;

  iframe {
    width: 100%;
    height: 100%;
    flex: 1;
  }

  &__mobile-panel {
    padding: 0.5rem;
    background: #fff;
    display: flex;
    justify-content: flex-start;
  }

  &__close-button {
    position: absolute;
    top: 1.25rem;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
