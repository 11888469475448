<template>
  <p class="unit-card-price">{{ displayPrice }}</p>
</template>

<script>
import BasePrice from '@/classes/Price/BasePrice'
import RentalPrice from '@/classes/Price/RentalPrice'
import rentPaymentPeriodsTranslationKeys from '@/constants/price/rent-payment-periods-translation-keys'

export default {
  name: 'AppCardPrice',
  props: {
    price: {
      type: BasePrice,
      required: true,
    },
  },
  computed: {
    displayPrice() {
      try {
        const formattedPrice = this.$i18n.priceFormatter.format(this.price)
        if (this.price instanceof RentalPrice) {
          const priceTranslationKey =
            rentPaymentPeriodsTranslationKeys[this.price.rentPaymentPeriod]

          return this.$i18n.t(priceTranslationKey, {
            price: formattedPrice,
          })
        } else {
          return formattedPrice
        }
      } catch (error) {
        this.$loggingService.logError(
          `AppCardPrice.displayPrice: Malformatted Price ${this.price}. Error: ${error}.`
        )
        return null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.unit-card-price {
  font-size: 1rem;
  font-weight: var(--font-weight-medium);
  line-height: 1.5;
  color: var(--gray-700);
  margin: 0;
}
</style>
