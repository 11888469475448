class HomeRouteObjectGenerator {
  static get ROUTE_NAME() {
    return 'home'
  }
  static make(customParams) {
    return {
      name: HomeRouteObjectGenerator.ROUTE_NAME,
      params: {
        ...customParams,
      },
    }
  }
}
export default HomeRouteObjectGenerator
