<template>
  <router-link
    class="button-text-router-link"
    :class="[styleClassName, sizeClassName]"
    :to="to"
  >
    <span class="button-text-router-link__text">{{ text }}</span>
  </router-link>
</template>

<script>
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'

export default {
  name: 'AppButtonTextRouterLink',
  props: {
    text: {
      type: String,
      required: true,
    },
    buttonStyle: {
      type: String,
      default: styles.PRIMARY,
      validator: (value) => Object.values(styles).includes(value),
    },
    size: {
      type: String,
      default: sizes.SM,
      validator: (value) => Object.values(sizes).includes(value),
    },
    to: {
      type: [Object, String],
      required: true,
    },
  },
  computed: {
    styleClassName() {
      return `button-text-router-link--${this.buttonStyle}`
    },
    sizeClassName() {
      return `button-text-router-link--${this.size}`
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'app-button.mixin';
.button-text-router-link {
  @include button-styles;
  @include button-text-sizes;
  @include button-alternatives;
  @include noselect;
}
</style>
