import CollectionSlugs from './CollectionSlugs'
import ImageResource from '../MediaResources/ImageResource'
import Tour360Resource from '../MediaResources/Tour360Resource'
import VideoResource from '../MediaResources/VideoResource'
import VrArResource from '../MediaResources/VrArResource'

class GalleryMediaResourceFactory {
  constructor() {
    this._resourceClasses = {
      [CollectionSlugs.GALLERY]: ImageResource,
      [CollectionSlugs.PLANS3D]: ImageResource,
      [CollectionSlugs.PLANS]: ImageResource,
      [CollectionSlugs.VIEWS]: ImageResource,
      [CollectionSlugs.TOUR360]: Tour360Resource,
      [CollectionSlugs.VIDEO]: VideoResource,
      [CollectionSlugs.VRAR]: VrArResource,
    }
  }
  makeResource(collectionSlug, resourceData) {
    const ResourceClass = this._resourceClasses[collectionSlug]
    if (ResourceClass === undefined) {
      throw new Error(
        `GalleryMediaResourceFactory.makeResource(): missing resource class for collection '${collectionSlug}'. ` +
          'Maybe GalleryMediaResourceFactory._resourceClasses is outdated?'
      )
    }
    try {
      return new ResourceClass(resourceData)
    } catch (error) {
      throw new Error(
        `GalleryMediaResourceFactory: There was a problem creating a resource with collectionSlug '${collectionSlug}'. ${error}`
      )
    }
  }
}
export default GalleryMediaResourceFactory
