import { FEATURES } from '@/constants/feature-flags'
import { MENU_REQUIRED_PARAMS } from '@/constants/navigation/menu-constants'
import ProjectConstants from '@/constants/project-structure/project-constants'
const { PROJECT_TYPES } = ProjectConstants

export default class MenuOption {
  constructor({
    name,
    nameTranslationKey,
    nameId,
    icon,
    order,
    showHintDependingOnInternetConnection,
    availableOnTheseProjectTypes,
    availableInOfflineMode,
    availableIfTheseFeatureFlagsExist,
    allowedByParams,
    customAdapter,
  }) {
    this._name = name
    this._nameTranslationKey = nameTranslationKey
    this._icon = icon
    this._order = order
    this._nameId = nameId
    this._showHintDependingOnInternetConnection = showHintDependingOnInternetConnection

    this._availableInOfflineMode = availableInOfflineMode

    if (
      availableOnTheseProjectTypes &&
      !this._areRequiredProjectTypesSupported(availableOnTheseProjectTypes)
    ) {
      throw new Error(
        `BaseMenuOption.constructor: Option ${nameId} have an invalid required project types array`
      )
    }

    if (
      availableIfTheseFeatureFlagsExist &&
      !this._areRequiredFeatureFlagsSupported(availableIfTheseFeatureFlagsExist)
    ) {
      throw new Error(
        `BaseMenuOption.constructor: Option ${nameId} have an invalid required feature flags array`
      )
    }

    if (allowedByParams && !this._areRequiredParamsSupported(allowedByParams)) {
      throw new Error(
        `BaseMenuOption.constructor: Option ${nameId} have an invalid required params array`
      )
    }

    if (customAdapter && !this._isCustomAdapterSupported(customAdapter)) {
      throw new Error(
        `BaseMenuOption.constructor: Option ${nameId} have an invalid customAdapter function`
      )
    }

    this._availableOnTheseProjectTypes = availableOnTheseProjectTypes
    this._availableIfTheseFeatureFlagsExist = availableIfTheseFeatureFlagsExist
    this._allowedByParams = allowedByParams
    this._customAdapter = customAdapter
  }

  _isCustomAdapterSupported(customAdapter) {
    return typeof customAdapter === 'function'
  }
  _areRequiredProjectTypesSupported(requiredProjectTypes) {
    const supportedProjectTypes = Object.values(PROJECT_TYPES)
    if (Array.isArray(requiredProjectTypes)) {
      for (const requiredType of requiredProjectTypes) {
        if (!supportedProjectTypes.includes(requiredType)) {
          return false
        }
      }

      return true
    } else {
      return false
    }
  }
  _areRequiredFeatureFlagsSupported(requiredFeatureFlags) {
    const supportedFeatureFlags = Object.values(FEATURES)
    if (Array.isArray(requiredFeatureFlags)) {
      for (const requiredFeatureFlag of requiredFeatureFlags) {
        if (!supportedFeatureFlags.includes(requiredFeatureFlag)) {
          return false
        }
      }

      return true
    } else {
      return false
    }
  }
  _areRequiredParamsSupported(requiredParams) {
    const supportedParams = Object.values(MENU_REQUIRED_PARAMS)
    if (Array.isArray(requiredParams)) {
      for (const requiredParam of requiredParams) {
        if (!supportedParams.includes(requiredParam)) {
          return false
        }
      }

      return true
    } else {
      return false
    }
  }

  get name() {
    return this._name
  }
  get nameTranslationKey() {
    return this._nameTranslationKey
  }
  get icon() {
    return this._icon
  }
  get nameId() {
    return this._nameId
  }
  get order() {
    return this._order
  }
  get showHintDependingOnInternetConnection() {
    return this._showHintDependingOnInternetConnection
  }
  get availableOnTheseProjectTypes() {
    return this._availableOnTheseProjectTypes
  }
  get availableInOfflineMode() {
    return this._availableInOfflineMode
  }
  get availableIfTheseFeatureFlagsExist() {
    return this._availableIfTheseFeatureFlagsExist
  }
  get allowedByParams() {
    return this._allowedByParams
  }
  get customAdapter() {
    return this._customAdapter
  }
}
