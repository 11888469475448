class Hint {
  constructor({ component, transition, maxShowCount, initialShowCount = 0 }) {
    this._component = component
    this._transition = transition
    this._maxShowCount = maxShowCount
    this._showCount = initialShowCount
  }
  get component() {
    return this._component
  }
  get transition() {
    return this._transition
  }
  get maxShowCount() {
    return this._maxShowCount
  }
  get showCount() {
    return this._showCount
  }
  shouldBeShown() {
    return this._showCount <= this.maxShowCount
  }
  _incrementShowCount() {
    this._showCount++
  }
}
export default Hint
