<template>
  <TheTopBar
    :controls="controls"
    :menu-offset="menuOffset"
    with-fullscreen-button
    v-on="$listeners"
  />
</template>

<script>
import TheTopBar from '@/components/Gadgets/TheTopBar'
import TopBarControlsSuite from '@/classes/TopBarControls/TopBarControlsSuite'
import TopBarButtonIconGenerator from '@/classes/TopBarControls/TopBarButtonIconGenerator'
import TopBarButtonGenerator from '@/classes/TopBarControls/TopBarButtonGenerator'
import responsiveMixin from '@/mixins/responsiveMixin'
import iconNames from '@/constants/ui-kit/standard/icon-names'

export default {
  name: 'LocationTopBarControls',
  components: {
    TheTopBar,
  },
  mixins: [responsiveMixin],
  props: {
    shouldDetailsButtonBeShown: {
      type: Boolean,
      default: undefined,
    },
    shouldMapModeButtonBeShown: {
      type: Boolean,
      default: undefined,
    },
    projectHasExteriorTour360Url: {
      type: Boolean,
      default: undefined,
    },
    modeButtonText: {
      type: String,
      default: undefined,
    },
    menuOffset: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    controls() {
      return new TopBarControlsSuite({
        rightControls: [
          TopBarButtonGenerator.create({
            buttonText: this.$t('Ver detalles'),
            show: () => this.shouldDetailsButtonBeShown && !this.isSmallScreen,
            eventHandler: () => this.$emit('show-details'),
          }),

          TopBarButtonGenerator.create({
            buttonText: this.$t('Vista aérea'),
            show: () =>
              this.projectHasExteriorTour360Url && !this.isSmallScreen,
            eventHandler: () => this.$emit('navigate-to-aerial-view'),
          }),

          TopBarButtonGenerator.create({
            buttonText: this.modeButtonText,
            show: () => this.shouldMapModeButtonBeShown && !this.isSmallScreen,
            eventHandler: () => this.$emit('switch-map-mode'),
          }),

          TopBarButtonIconGenerator.create({
            buttonIcon: iconNames.UIViewDetails,
            show: () => this.shouldDetailsButtonBeShown && this.isSmallScreen,
            eventHandler: () => this.$emit('show-details'),
          }),

          TopBarButtonIconGenerator.create({
            buttonIcon: iconNames.UIAerialView,
            show: () => this.projectHasExteriorTour360Url && this.isSmallScreen,
            eventHandler: () => this.$emit('navigate-to-aerial-view'),
          }),

          TopBarButtonIconGenerator.create({
            buttonIcon: iconNames.UIInteractiveMap,
            show: () => this.shouldMapModeButtonBeShown && this.isSmallScreen,
            eventHandler: () => this.$emit('switch-map-mode'),
          }),
        ],
      })
    },
  },
}
</script>
