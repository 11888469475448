<template>
  <div v-show="UIControlsShown" id="topview-container">
    <topview-top-bar-controls
      :is-levels-menu-open="isLevelsMenuOpen"
      :label-text="levelIndicatorText"
      :offset="levelsMenuWidth"
      :menu-offset="leftSidebarWidth"
      @open-levels-menu="openLevelsMenu"
    />
    <selected-svg-element-info-panel
      ref="infoPanel"
      class="topview__unit-info-panel"
      @open="infoPanelOpen"
      @closed="infoPanelClosed"
      @width-updated="updateLeftSidebarWidth"
    />
    <AppLevelsMenu
      ref="levelsMenu"
      class="topview__levels-menu"
      :level-menu-items="levelsLinks"
      @visibility-change="levelsMenuVisibilityChanged"
      @width-updated="updateLevelsMenuWidth"
    />
    <TheZoomControls with-background-image :right-offset="levelsMenuWidth" />
    <ProjectElementPopupDisplayer class="topview__popup-displayer" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import responsiveMixin from '@/mixins/responsiveMixin'
import hideUIControlsInTransition from '@/mixins/hideUIControlsInTransition'
import AppLevelsMenu from '@/components/UIKit/Standard/Molecules/AppLevelsMenu.vue'
import SelectedSvgElementInfoPanel from '@/components/ComposedInfoPanels/SelectedSvgElementInfoPanel'
import LevelRouteObjectGenerator from '@/classes/RouteObjectGenerators/LevelRouteObjectGenerator'
import HomeRouteObjectGenerator from '@/classes/RouteObjectGenerators/HomeRouteObjectGenerator'
import TheZoomControls from '@/components/Gadgets/TheZoomControls'
import TopviewTopBarControls from '@/components/ViewTopBarControls/TopviewTopBarControls'
import ProjectElementPopupDisplayer from '@/components/Gadgets/ProjectElementPopupDisplayer'

import SlugGenerator from '@/classes/SlugGenerator'

export default {
  name: 'Topview',
  components: {
    AppLevelsMenu,
    SelectedSvgElementInfoPanel,
    TopviewTopBarControls,
    TheZoomControls,
    ProjectElementPopupDisplayer,
  },
  mixins: [responsiveMixin, hideUIControlsInTransition],
  props: {
    levelId: {
      type: String,
      default: () => undefined,
    },
  },
  data() {
    return {
      isLevelsMenuOpen: true,
      isInfoPanelOpen: false,
      levelsMenuWidth: 0,
      leftSidebarWidth: 0,
    }
  },
  computed: {
    ...mapGetters({
      findLevelById: 'Topview/findLevelById',
      findTowerByChildLevelId: 'Topview/findTowerByChildLevelId',
      imageZoom: 'ZoomTracker/imageZoom',
    }),
    currentLevel() {
      return this.findLevelById(this.levelId)
    },
    currentTower() {
      return this.findTowerByChildLevelId(this.currentLevel.id)
    },
    levelSelected() {
      return this.currentLevel.name
    },
    levelsLinks() {
      return this.currentTower.levels.map((level) => ({
        displayName: level.name,
        routeObject: LevelRouteObjectGenerator.make(level, {
          useTransition: true,
        }),
      }))
    },
    levelIndicatorText() {
      const casesObject = {}

      casesObject[this.$projectType.MULTITOWER_TYPE] = () => {
        return this.$t('floorOptionsBar.currentFloorAndTower', {
          tower: this.currentTower.name,
          level: this.levelSelected,
        })
      }
      casesObject[this.$projectType.SINGLETOWER_TYPE] = () => {
        return this.$t('floorOptionsBar.currentFloor', {
          level: this.levelSelected,
        })
      }

      return this.$projectType.switcher(casesObject)
    },
  },
  watch: {
    $route() {
      this.redirectOnInvalidLevel()
      this.validateLevelSlug()
    },
    isSmallScreenVertical() {
      if (this.isSmallScreenVertical && this.isInfoPanelOpen) {
        this.$refs.levelsMenu.hide()
      }
    },
    UIControlsShown(value) {
      if (value) {
        this.openLevelsMenu()
      } else {
        this.$refs.levelsMenu?.hide()
      }
    },
    imageZoom() {
      this.$refs.infoPanel?.close()
    },
  },
  mounted() {
    this.redirectOnInvalidLevel()
    this.validateLevelSlug()
    if (this.UIControlsShown) {
      this.openLevelsMenu()
    }

    if (this.isSmallScreenVertical) {
      this.$store.dispatch('Layout/setTopLayerCurrentHint', 'RotateDeviceHint')
    }
  },
  methods: {
    validateLevelSlug() {
      const correctLevelSlug = SlugGenerator.make(this.currentLevel)
      const currentParams = this.$route.params

      if (correctLevelSlug !== currentParams.descriptiveSlug) {
        const levelRouteObject = LevelRouteObjectGenerator.make(
          this.currentLevel
        )
        this.$router.replace(levelRouteObject)
      }
    },
    updateLeftSidebarWidth(infoPanelWidth) {
      this.leftSidebarWidth = infoPanelWidth
    },
    openLevelsMenu() {
      this.$refs.levelsMenu.show()
      if (this.isSmallScreenVertical) {
        this.$refs.infoPanel.close()
      }
    },
    levelsMenuVisibilityChanged(visible) {
      this.isLevelsMenuOpen = visible
    },
    infoPanelOpen() {
      this.isInfoPanelOpen = true
      if (this.isSmallScreenVertical) {
        this.$refs.levelsMenu.hide()
      }
    },
    infoPanelClosed() {
      this.isInfoPanelOpen = false
    },
    redirectOnInvalidLevel() {
      if (this.currentLevel === undefined) {
        this.$loggingService.logError(
          `Error in Topview: route has invalid levelId ${this.levelId}`
        )
        const homeRouteObject = HomeRouteObjectGenerator.make()
        this.$router.replace(homeRouteObject)
      }
    },
    updateLevelsMenuWidth(value) {
      this.levelsMenuWidth = value
    },
  },
}
</script>

<style lang="scss" scoped>
#topview-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 305;
  pointer-events: none;
}

.topview__levels-options,
.topview__levels-menu,
.topview__unit-info-panel,
.topview__popup-displayer {
  pointer-events: all;
}

.topview__unit-info-panel {
  position: relative;
  z-index: 20;
}
</style>
