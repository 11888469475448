import TransitionTreeNode from './TransitionTreeNode'
import BaseTransitionParams from './BaseTransitionParams'
import { DEFAULT_TRANSITIONS } from '@/constants/transition-tree/default-transitions'
import TransitionParamsFactory from '@/classes/TransitionTree/TransitionParamsFactory'

class TransitionTree {
  constructor() {
    this._routeTransitionNodeMap = {}

    DEFAULT_TRANSITIONS.forEach(
      ({ from, to, transitionType, transitionParams }) => {
        const transitionParamsObject = TransitionParamsFactory.make(
          transitionType,
          transitionParams
        )
        this.add(transitionParamsObject, from, to)
      }
    )
  }
  add(transitionParams, originRouteNameOrPath, destinationRouteNameOrPath) {
    try {
      this._validateTransitionParams(transitionParams)

      const transitionTreeNode =
        this._getNode(originRouteNameOrPath) ||
        this._createNode(originRouteNameOrPath)

      transitionTreeNode.add(transitionParams, destinationRouteNameOrPath)
    } catch (error) {
      throw new Error(
        `TransitionTree.add(): Error adding element from ${originRouteNameOrPath} to ${destinationRouteNameOrPath}. ${error.toString()}`
      )
    }
  }
  from(routeNameOrPath) {
    return this._getNode(routeNameOrPath)
  }
  _getNode(routeNameOrPath) {
    return this._routeTransitionNodeMap[routeNameOrPath]
  }
  _createNode(routeNameOrPath) {
    this._validateRouteNameOrPath(routeNameOrPath)
    this._validateIfElementExists(routeNameOrPath)

    const newTransitionNode = new TransitionTreeNode()
    this._routeTransitionNodeMap[routeNameOrPath] = newTransitionNode
    return newTransitionNode
  }
  _validateIfElementExists(routeNameOrPath) {
    if (this._getNode(routeNameOrPath) instanceof TransitionTreeNode) {
      throw new Error(
        `TransitionTree._validateIfElementExists(): The transition tree node to the "${routeNameOrPath}" route already exists.`
      )
    }
  }
  _validateRouteNameOrPath(routeNameOrPath) {
    if (typeof routeNameOrPath !== 'string' || routeNameOrPath.trim() === '') {
      throw new Error(
        `TransitionTree._validateRouteNameOrPath(): routeNameOrPath ${routeNameOrPath} is not a valid string.`
      )
    }
  }
  _validateTransitionParams(transitionParams) {
    if (!(transitionParams instanceof BaseTransitionParams)) {
      throw new Error(
        `TransitionTree._validateTransitionParams(): Passed transitionParams is not an instance of class BaseTransitionParams`
      )
    }
  }
}
export default TransitionTree
