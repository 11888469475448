<template>
  <TheScrollManager
    ref="scrollManager"
    :scroll-enabled="enabled"
    class="the-background-interaction-manager__scroll-manager"
  >
    <TheZoomManager
      ref="zoomManager"
      :scroll-position-x="zoomScrollPositionX"
      :scroll-position-y="zoomScrollPositionY"
      :enabled="zoomEnabled"
      @zoom-started="onZoomStarted"
      @zoom-reset="onZoomReset"
    >
      <slot></slot>
    </TheZoomManager>
  </TheScrollManager>
</template>

<script>
import TheScrollManager from '@/components/Gadgets/TheScrollManager'
import TheZoomManager from '@/components/Gadgets/TheZoomManager'
import { mapGetters } from 'vuex'

export default {
  name: 'TheBackgroundInteractionManager',
  components: {
    TheScrollManager,
    TheZoomManager,
  },
  props: {
    enabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      scrollManager: null,
      zoomManager: null,
      zoomScrollPositionX: 0,
      zoomScrollPositionY: 0,
    }
  },
  computed: {
    ...mapGetters({
      enableInteractiveBackgroundZoom:
        'Preferences/enableInteractiveBackgroundZoom',
      isFirstZoomStep: 'ZoomTracker/isFirstZoomStep',
    }),
    isZoomEnabledByPreference() {
      return (
        this.$route.meta.enableZoomDependingOnPreference &&
        this.enableInteractiveBackgroundZoom
      )
    },
    isZoomEnabledByRouteConfig() {
      return this.$route.meta.isBackgroundImageZoomable
    },
    zoomEnabled() {
      return (
        this.enabled &&
        (this.isZoomEnabledByPreference || this.isZoomEnabledByRouteConfig)
      )
    },
  },
  mounted() {
    this.scrollManager = this.$refs.scrollManager
    this.zoomManager = this.$refs.zoomManager
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    async reset({ useTransition, center }) {
      await this.zoomManager.reset({ useTransition, center })
      if (center) {
        await this.scrollManager.reset({ useTransition })
      }
    },
    async onResize() {
      if (!this.isFirstZoomStep) {
        this.zoomManager.applyPositionLimits()
      }
      window.scrollTo(0, 0)
      await this.$nextTick()
      this.scrollManager.reset({ useTransition: false })
    },
    onZoomStarted() {
      const scrollManagerBoundingClientRect = this.scrollManager.$el.getBoundingClientRect()

      this.zoomScrollPositionX = this.scrollManager.getScrollPosition()
      this.zoomScrollPositionY = scrollManagerBoundingClientRect.top
    },
    onZoomReset(zoomFinalPosition) {
      this.scrollManager.setScrollPosition(zoomFinalPosition)
    },
  },
}
</script>

<style lang="scss" scoped>
.the-background-interaction-manager__scroll-manager {
  background-color: var(--grey-background-color);
}
</style>
