<template>
  <div class="dropdown-placeholder" aria-hidden="true">
    <AppButtonTextWithIcon
      :text="largestOptionText"
      :size="size"
      :icon="icon"
      disabled
    />
  </div>
</template>

<script>
import AppButtonTextWithIcon from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonTextWithIcon'
import sizes from '@/constants/ui-kit/standard/sizes'
import iconNames from '@/constants/ui-kit/standard/icon-names'

export default {
  name: 'AppDropdownPlaceholder',
  components: {
    AppButtonTextWithIcon,
  },
  props: {
    size: {
      type: String,
      default: sizes.SM,
      validator: (value) => Object.values(sizes).includes(value),
    },
    icon: {
      type: String,
      default: iconNames.UIDropdown,
      validator: (value) => Object.values(iconNames).includes(value),
    },
    options: {
      type: Array,
      required: true,
      validator: function(array) {
        return array.every((item) => !!item.value && !!item.text)
      },
    },
  },
  computed: {
    largestOptionText() {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')

      let largestOption = undefined
      let largestOptionWidth = 0
      this.options.forEach((option) => {
        const textMeasurement = context.measureText(option.text)
        if (textMeasurement.width > largestOptionWidth) {
          largestOptionWidth = textMeasurement.width
          largestOption = option
        }
      })

      return largestOption.text
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-placeholder {
  height: 0;
  overflow: hidden;
  visibility: hidden;
}
</style>
