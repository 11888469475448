import modalSupportedComponents from '@/constants/ui-kit/standard/modal/modal-supported-components'

export default {
  launchModal(
    { commit, dispatch, getters },
    { component, properties, closeCallback }
  ) {
    if (!Object.values(modalSupportedComponents).includes(component)) {
      throw new Error(
        'ModalLauncher.launchModal(): Component passed is not supported'
      )
    }

    const { isModalLaunched } = getters
    if (isModalLaunched) {
      dispatch('closeModal')
    }

    commit('setModalProperties', properties)
    commit('setModalComponent', component)
    commit('setModalCloseCallback', closeCallback)
    commit('launchModal')
  },
  closeModal({ commit, getters }) {
    const { modalCloseCallback } = getters
    if (modalCloseCallback) {
      modalCloseCallback()
    }
    commit('setModalStateToInitialValues')
  },
}
