<template>
  <transition :name="hintTransition">
    <component :is="hintComponent" v-if="isHintShown" @hintClose="closeHint" />
  </transition>
</template>

<script>
import RotateDeviceHint from './RotateDeviceHint'
import { mapGetters } from 'vuex'

export default {
  name: 'HintContainer',
  components: {
    RotateDeviceHint,
  },
  computed: {
    ...mapGetters({
      topLayerCurrentHint: 'Layout/topLayerCurrentHint',
      transitionActive: 'transitionActive',
    }),
    isHintShown() {
      return this.topLayerCurrentHint && !this.transitionActive
        ? this.topLayerCurrentHint.shouldBeShown()
        : false
    },
    hintTransition() {
      return this.topLayerCurrentHint ? this.topLayerCurrentHint.transition : ''
    },
    hintComponent() {
      return this.topLayerCurrentHint ? this.topLayerCurrentHint.component : ''
    },
  },
  methods: {
    closeHint() {
      this.$store.dispatch('Layout/setTopLayerCurrentHint', null)
    },
  },
}
</script>

<style lang="scss" scoped>
.hint-fade-enter-active,
.hint-fade-leave-active {
  transition: opacity 300ms ease-in-out;
}
.hint-fade-enter-active {
  transition-delay: 300ms;
}
.hint-fade-enter,
.hint-fade-leave-to {
  opacity: 0;
}
</style>
