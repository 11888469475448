<template>
  <div class="sheet-block-button-group">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'AppSheetBlockButtonGroup',
}
</script>

<style lang="scss" scoped>
.sheet-block-button-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
  row-gap: 0.8125rem;

  &:empty {
    display: none;
  }

  > *:only-child,
  > *:nth-child(3) {
    grid-column: 1 / span 2;
  }
}
</style>
