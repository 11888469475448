import Level from '@/classes/ProjectStructure/Level'
import SlugGenerator from '@/classes/SlugGenerator'

class LevelRouteObjectGenerator {
  static get ROUTE_NAME() {
    return 'topview'
  }
  static make(level, customParams) {
    if (!(level instanceof Level)) {
      throw new Error(
        'LevelRouteObjectGenerator.make(): passed level argument is not an instance of Level class.'
      )
    }
    return {
      name: LevelRouteObjectGenerator.ROUTE_NAME,
      params: {
        ...customParams,
        descriptiveSlug: SlugGenerator.make(level),
        levelId: level.id,
      },
    }
  }
}
export default LevelRouteObjectGenerator
