<template>
  <AppMenuWrapper class="menu-submenu">
    <AppMenuRow v-if="submenuTitle">
      <AppSubmenuTitle :title="submenuTitle" :icon="submenuIcon" />
    </AppMenuRow>
    <AppMenuRow grow>
      <AppMenuItemsList
        :items="items"
        :limited-connection="limitedConnection"
        @request-menu="requestMenu"
      />
    </AppMenuRow>
  </AppMenuWrapper>
</template>

<script>
import AppMenuWrapper from '@/components/UIKit/Standard/Atoms/AppMenuWrapper'
import AppMenuRow from '@/components/UIKit/Standard/Atoms/AppMenuRow'
import AppSubmenuTitle from '@/components/UIKit/Standard/Atoms/AppSubmenuTitle'
import AppMenuItemsList from '@/components/UIKit/Standard/Molecules/AppMenuItemsList'
import BaseMenuItem from '@/classes/Navigation/MenuItem/BaseMenuItem'
import Menu from '@/classes/Navigation/Menu'
import iconNames from '@/constants/ui-kit/standard/icon-names'
import styles from '@/constants/ui-kit/standard/styles'
import sizes from '@/constants/ui-kit/standard/sizes'

export default {
  name: 'AppMenuSubmenu',
  components: {
    AppMenuWrapper,
    AppMenuRow,
    AppSubmenuTitle,
    AppMenuItemsList,
  },
  props: {
    items: {
      type: Array,
      required: true,
      validator: (items) => {
        return items.every(
          (item) => item instanceof BaseMenuItem || item instanceof Menu
        )
      },
    },
    submenuTitle: {
      type: String,
      default: () => undefined,
    },
    submenuIcon: {
      type: String,
      validator: (value) => Object.values(iconNames).includes(value),
      default: iconNames.UIAreas,
    },
    limitedConnection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      closeButtonStyle: styles.LINK,
      closeButtonSize: sizes.XS,
      closeButtonIcon: iconNames.UIClose,
    }
  },
  methods: {
    requestMenu(item) {
      this.$emit('request-menu', item)
    },
  },
}
</script>

<style lang="scss" scoped>
.menu-submenu {
  padding-top: 0.875rem;
}
</style>
