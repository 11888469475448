<template>
  <AppBaseModal :title="title" :modal-width="800" v-on="$listeners">
    <AppQuoteConfirmationMessage :unit-identifier="unitIdentifier" />
  </AppBaseModal>
</template>

<script>
import AppBaseModal from '@/components/UIKit/Standard/Atoms/AppBaseModal'
import AppQuoteConfirmationMessage from '@/components/UIKit/Standard/Atoms/AppQuoteConfirmationMessage'

export default {
  name: 'AppQuoterConfirmationModal',
  components: {
    AppBaseModal,
    AppQuoteConfirmationMessage,
  },
  props: {
    unitIdentifier: {
      type: String,
      default: () => undefined,
    },
  },
  data() {
    return {
      title: this.$t('¡Cotización enviada exitosamente!'),
    }
  },
}
</script>
