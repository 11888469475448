export default {
  storeMasterMenu(state, masterMenu) {
    state.menuData = masterMenu
  },
  setLocationDefaultMode(state, value) {
    state.menuData.setLocationDefaultMode(value)
  },
  setAmenitiesGalleryDefaultCollectionSlug(state, collectionSlug) {
    state.menuData.setAmenitiesGalleryDefaultCollectionSlug(collectionSlug)
  },
  setMenuWidth(state, width) {
    state.menuWidth = width
  },
  setTopLayerCurrentHint(state, hint) {
    state.topLayerCurrentHint = hint
  },
}
