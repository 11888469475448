<template>
  <div class="box">
    <AppScrollbarContainer class="box__content">
      <div class="box__content__body">
        <h5 class="text-uppercase">{{ $t('Disclaimer') }}</h5>
        <AppLegalText :html-content="disclaimer" />
      </div>
    </AppScrollbarContainer>
  </div>
</template>

<script>
import AppScrollbarContainer from '@/components/UIKit/Standard/Atoms/AppScrollbarContainer'
import AppLegalText from '@/components/UIKit/Standard/Atoms/AppLegalText'
import { mapGetters } from 'vuex'

export default {
  name: 'DisclaimerBox',
  components: { AppScrollbarContainer, AppLegalText },
  computed: {
    ...mapGetters({
      disclaimer: 'Project/disclaimer',
    }),
  },
}
</script>

<style lang="scss" scoped>
h5 {
  font-size: 0.875rem;
  margin: 0 0 2.5em 0;
  font-weight: var(--font-weight-regular);
  text-transform: uppercase;
}
.box {
  background-color: #f6f6f6;
  border: solid 1px var(--gray-100);
  position: relative;
  .box__content {
    overflow: auto;
    max-height: 174px;
    .box__content__body {
      padding: 1.875rem 20px;
    }
  }
}
</style>
