<template>
  <table class="quoter-result-table">
    <tr v-for="quoteItem in quoteTableData" :key="quoteItem.fieldName">
      <td>{{ quoteItem.fieldName }}</td>
      <td>{{ quoteItem.value }}</td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'AppQuoterResultTable',
  props: {
    quoteTableData: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.quoter-result-table {
  width: 100%;
  margin-bottom: 0.625rem;

  td,
  tr {
    font-size: 0.875rem;
    color: var(--gray-700);
  }

  tr {
    border-bottom: 1px solid var(--gray-100);
  }

  td {
    padding: 0.625rem 0.3125rem;
    text-align: center;
  }

  td:last-child {
    text-align: right;
  }

  td:first-child {
    text-align: left;
  }
}
</style>
