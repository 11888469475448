<template>
  <AppSheetWrapper class="sheet-unit-large">
    <AppSheetRow>
      <AppSheetBlockTitleAndAvailability
        :title="title"
        :subtitle="subtitle"
        :availability-status="availabilityStatus"
        :availability-color="availabilityColor"
      />
    </AppSheetRow>
  </AppSheetWrapper>
</template>

<script>
import AppSheetWrapper from '@/components/UIKit/Standard/Atoms/AppSheetWrapper'
import AppSheetRow from '@/components/UIKit/Standard/Atoms/AppSheetRow'
import AppSheetBlockTitleAndAvailability from '@/components/UIKit/Standard/Molecules/AppSheetBlockTitleAndAvailability'

export default {
  name: 'AppSheetUnitSmall',
  components: {
    AppSheetWrapper,
    AppSheetRow,
    AppSheetBlockTitleAndAvailability,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: undefined,
    },
    availabilityStatus: {
      type: String,
      default: undefined,
    },
    availabilityColor: {
      type: String,
      default: undefined,
    },
  },
}
</script>
