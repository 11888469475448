const LoggingService = require('./LoggingService')

class LogOnlyErrorsLoggingService extends LoggingService {
  get logError() {
    return console.error
  }
  get logWarning() {
    return () => {}
  }
  get logInfo() {
    return () => {}
  }
  get logMessage() {
    return () => {}
  }
}
module.exports = LogOnlyErrorsLoggingService
