import FontLoadError from '@/classes/Exceptions/FontLoadError'

export default class AppWebFontsLoader {
  constructor(fontFamily) {
    this.fontFamily = fontFamily
  }

  addGoogleFonts(googleFontsEmbedUrl) {
    const googleFontsLink = document.createElement('link')
    googleFontsLink.rel = 'stylesheet'
    googleFontsLink.async = 'true'
    googleFontsLink.href = googleFontsEmbedUrl

    document.head.appendChild(googleFontsLink)

    return new Promise((resolve, reject) => {
      googleFontsLink.onload = resolve
      googleFontsLink.onerror = (error) => {
        reject(new FontLoadError(this.fontFamily, googleFontsEmbedUrl, error))
      }
    })
  }

  async addLocalWebFontVariant(webFontUrl, fontFaceOptions) {
    const webFontCSSFormattedUrl = `url(${webFontUrl})`
    try {
      const font = new FontFace(
        this.fontFamily,
        webFontCSSFormattedUrl,
        fontFaceOptions
      )
      document.fonts.add(font)
      await font.load()
    } catch (error) {
      throw new FontLoadError(this.fontFamily, webFontUrl, error)
    }
  }
}
