import BaseUnitQuoteFileNameGenerator from './BaseUnitQuoteFileNameGenerator'
import Level from '@/classes/ProjectStructure/Level'
import Unit from '@/classes/ProjectStructure/Unit/Unit'

class SingleTowerUnitQuoteFileNameGenerator extends BaseUnitQuoteFileNameGenerator {
  _getTranslationArguments(unit) {
    return {
      unitId: unit.label,
      name: unit.name,
      level: unit.parent().name,
    }
  }
  _validateUnit(unit) {
    if (!(unit instanceof Unit)) {
      throw new Error(
        'SingleTowerUnitQuoteFileNameGenerator._validateUnit(): passed unit argument is not an instance of Unit class.'
      )
    }
    const level = unit.parent()
    if (!(level instanceof Level)) {
      throw new Error(
        'SingleTowerUnitQuoteFileNameGenerator._validateUnit(): Parent of unit is not an instance of Level class.'
      )
    }
  }
  get _localeGroupKey() {
    return 'singleTowerUnitQuoteFileNames'
  }
}
export default SingleTowerUnitQuoteFileNameGenerator
