<template>
  <section>
    <VTitle text="AppTooltip" />
    <div class="example-component">
      <div class="example-component__controls">
        <VPropControl
          v-for="(propModel, propName) in propModels"
          :key="propName"
          :model="propModel"
          :name="propName"
        />
      </div>
      <div class="example-component__component">
        <AppTooltip
          :text="propModels.text.value"
          :placement="propModels.placement.value"
          :fixed="propModels.fixed.value"
        >
          <p class="example-target">Tooltip target</p>
        </AppTooltip>
      </div>
    </div>
  </section>
</template>

<script>
import AppTooltip from '@/components/UIKit/Standard/Molecules/AppTooltip'
import AppPropModel from '@/components/UIKit/Standard/ViewerSections/classes/AppPropModel'
import VTitle from '@/components/UIKit/Standard/ViewerSections/components/snippets/VTitle'
import VPropControl from '@/components/UIKit/Standard/ViewerSections/components/snippets/VPropControl'
import tooltipPlacements from '@/constants/ui-kit/standard/tooltip-placements'

export default {
  name: 'AppTooltipViewer',
  components: {
    AppTooltip,
    VTitle,
    VPropControl,
  },
  data() {
    return {
      propModels: {
        text: new AppPropModel({
          type: String,
          defaultValue: 'Tooltip text',
          required: true,
        }),
        placement: new AppPropModel({
          type: String,
        }),
        fixed: new AppPropModel({
          type: Boolean,
          defaultValue: true,
        }),
      },
    }
  },
  created() {
    Object.keys(tooltipPlacements).forEach((placement) => {
      this.propModels.placement.addPreset(
        tooltipPlacements[placement],
        placement
      )
    })
  },
}
</script>

<style lang="scss" scoped>
.example-component {
  display: flex;
  justify-content: stretch;
  align-items: stretch;

  @at-root &__controls {
    background: #fff;
    padding: 30px;
    border: 1px solid var(--gray-200);
    flex-basis: 300px;
    border-radius: 12px;
  }

  @at-root &__component {
    padding: 30px;
    flex: 1;
  }
}

.example-target {
  border: 1px solid var(--gray-700);
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
}
</style>
