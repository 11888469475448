import BaseNavigationHistoryStepChecker from './BaseNavigationHistoryStepChecker'
import ProjectElementTypes from '@/constants/project-structure/project-element-types'
import Tower from '@/classes/ProjectStructure/Tower'

export default class TopviewNavigationHistoryStepChecker extends BaseNavigationHistoryStepChecker {
  checkNavigationHistoryStep(to, from) {
    if (to.name !== from.name) {
      return true
    }

    const levelsMap = this._projectRootElement.getDescendantsOfType(
      ProjectElementTypes.LEVEL
    )

    const toLevel = levelsMap.get(to.params.levelId)
    const fromLevel = levelsMap.get(from.params.levelId)

    const toTower = toLevel?.parent()
    const fromTower = fromLevel?.parent()

    if (!(toTower instanceof Tower)) {
      throw new Error(
        'TopviewNavigationHistoryStepChecker.checkNavigationHistoryStep: toTower is not instance of Tower'
      )
    }
    if (!(fromTower instanceof Tower)) {
      throw new Error(
        'TopviewNavigationHistoryStepChecker.checkNavigationHistoryStep: fromTower is not instance of Tower'
      )
    }

    return toTower.id !== fromTower.id
  }
}
