export default class BaseNavigationHistoryStepChecker {
  constructor(projectRootElementInstance) {
    this._projectRootElement = projectRootElementInstance
  }

  // eslint-disable-next-line
  checkNavigationHistoryStep(to, from) {
    throw new Error(
      'BaseNavigationHistoryStepChecker: method checkNavigationHistoryStep is not defined'
    )
  }
}
