import AppConfirmationMessageModal from '@/components/UIKit/Standard/Organisms/AppModal/Modals/AppConfirmationMessageModal'
import AppDisclaimerModal from '@/components/UIKit/Standard/Organisms/AppModal/Modals/AppDisclaimerModal'
import AppLocationInfoFormModal from '@/components/UIKit/Standard/Organisms/AppModal/Modals/AppLocationInfoFormModal'
import AppPrivacyPolicyModal from '@/components/UIKit/Standard/Organisms/AppModal/Modals/AppPrivacyPolicyModal'
import AppUnitInfoFormModal from '@/components/UIKit/Standard/Organisms/AppModal/Modals/AppUnitInfoFormModal'
import AppQuoterModal from '@/components/UIKit/Standard/Organisms/AppModal/Modals/AppQuoterModal'
import AppQuoterConfirmationModal from '@/components/UIKit/Standard/Organisms/AppModal/Modals/AppQuoterConfirmationModal'
import AppEmbeddedContactFormModal from '@/components/UIKit/Standard/Organisms/AppModal/Modals/AppEmbeddedContactFormModal'

export default {
  AppConfirmationMessageModal,
  AppDisclaimerModal,
  AppLocationInfoFormModal,
  AppPrivacyPolicyModal,
  AppUnitInfoFormModal,
  AppQuoterModal,
  AppQuoterConfirmationModal,
  AppEmbeddedContactFormModal,
}
