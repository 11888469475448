import ProjectElementTypes from '@/constants/project-structure/project-element-types'
import BaseProjectElement from '@/classes/ProjectStructure/BaseProjectElement'
import IdProjectElementMap from '@/classes/IdProjectElementMap'

export default class BaseProjectRootElement extends BaseProjectElement {
  constructor({
    id = '',
    svgTargetElementId = '',
    exteriorTour360Url = undefined,
  } = {}) {
    super({ id, svgTargetElementId })
    this._descendants = {}
    Object.values(ProjectElementTypes).forEach((elementType) => {
      this._descendants[elementType] = new IdProjectElementMap(elementType)
    })
    this._exteriorTour360Url = exteriorTour360Url
    this.initSvgData()
  }
  get exteriorTour360Url() {
    return this._exteriorTour360Url
  }
  get projectElementType() {
    return ProjectElementTypes.ROOT
  }
  // eslint-disable-next-line no-unused-vars
  setRoot(rootElement) {
    throw new Error(
      'BaseProjectRootElement.setRoot(): root element cannot have a root element.'
    )
  }
  // eslint-disable-next-line no-unused-vars
  setParent(parentElement) {
    throw new Error(
      'BaseProjectRootElement.setParent(): root element cannot have a parent element.'
    )
  }
  addDescendant(projectElement) {
    if (!(projectElement instanceof BaseProjectElement)) {
      throw new Error(
        'BaseProjectRootElement.addDescendant(): Invalid projectElement passed!'
      )
    }
    const projectElementType = projectElement.projectElementType
    if (!this._descendants[projectElementType]) {
      throw new Error(
        `BaseProjectRootElement.addDescendant(): projectElementType ${projectElementType} is not mapped.`
      )
    }
    this._descendants[projectElementType].add(projectElement)
  }
  getDescendantsOfType(projectElementType) {
    const descendantsGroup = this._descendants[projectElementType]
    if (descendantsGroup) {
      return descendantsGroup
    } else {
      throw new Error(
        `BaseProjectRootElement.getDescendantsOfType(): projectElementType ${projectElementType} is not mapped.`
      )
    }
  }
  root() {
    return this
  }
}
