<template>
  <div class="unit-card-feature-list">
    <ul class="unit-card-feature-list__list">
      <li
        v-for="feature in features"
        :key="feature.id"
        class="unit-card-feature-list__list-item"
      >
        <span>{{ feature.longText }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import Feature from '@/classes/Feature/Feature'

export default {
  name: 'AppCardFeatureList',
  props: {
    features: {
      type: Array,
      required: true,
      validator: (value) =>
        value.every((feature) => feature instanceof Feature),
    },
  },
}
</script>

<style lang="scss" scoped>
.unit-card-feature-list {
  --bullet-width: 1.25rem;
  overflow: hidden;

  @at-root &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0.5625rem 0 0 calc(var(--bullet-width) * -1);
    padding: 0;
    color: var(--gray-400);
    list-style: none;
  }

  @at-root &__list-item {
    font-size: 0.875rem;
    font-weight: var(--font-weight-regular);
    line-height: 1.5;
    color: var(--gray-600);

    &::before {
      content: '•';
      display: inline-block;
      width: var(--bullet-width);
      text-align: center;
    }

    span {
      display: inline-block;
    }
  }
}
</style>
