import { MENU_REQUIRED_PARAMS } from '@/constants/navigation/menu-constants'
import TowerRouteObjectGenerator from '@/classes/RouteObjectGenerators/TowerRouteObjectGenerator'
import HomeRouteObjectGenerator from '@/classes/RouteObjectGenerators/HomeRouteObjectGenerator'
import MasterplanRouteObjectGenerator from '@/classes/RouteObjectGenerators/MasterplanRouteObjectGenerator'
import AvailabilityViewRouteObjectGenerator from '@/classes/RouteObjectGenerators/AvailabilityViewRouteObjectGenerator'
import ProjectConstants from '@/constants/project-structure/project-constants'

import LinkMenuOption from '@/classes/Navigation/MenuOption/LinkMenuOption'
import DownloableMenuOption from '@/classes/Navigation/MenuOption/DownloableMenuOption'
import MenuAdapters from '@/classes/Navigation/MenuAdapters'
import iconNames from '@/constants/ui-kit/standard/icon-names'
import stopPointSlugs from '@/constants/stop-point-slugs'

const { PROJECT_TYPES } = ProjectConstants

export default class MenuSettings {
  static MasterMenuOptions = [
    new LinkMenuOption({
      nameTranslationKey: 'menu.intro',
      nameId: 'intro',
      icon: iconNames.UIIntro,
      routeName: HomeRouteObjectGenerator.ROUTE_NAME,
      routeParams: {},
      exact: true,
      showHintDependingOnInternetConnection: false,
      availableInOfflineMode: true,
    }),
    new LinkMenuOption({
      nameTranslationKey: 'menu.masterplan',
      nameId: 'building',
      icon: iconNames.UIHome,
      routeName: MasterplanRouteObjectGenerator.ROUTE_NAME,
      routeParams: { stopPointSlug: stopPointSlugs.MASTERPLAN_0 },
      exact: false,
      showHintDependingOnInternetConnection: false,
      availableOnTheseProjectTypes: [PROJECT_TYPES.SINGLETOWER_TYPE],
      availableInOfflineMode: true,
    }),
    new LinkMenuOption({
      nameTranslationKey: 'menu.home',
      nameId: 'building',
      icon: iconNames.UIHome,
      routeName: MasterplanRouteObjectGenerator.ROUTE_NAME,
      routeParams: { stopPointSlug: stopPointSlugs.MASTERPLAN_0 },
      exact: false,
      showHintDependingOnInternetConnection: false,
      availableInOfflineMode: true,
      availableOnTheseProjectTypes: [PROJECT_TYPES.MULTITOWER_TYPE],
    }),
    new LinkMenuOption({
      nameTranslationKey: 'menu.project',
      nameId: 'building',
      icon: iconNames.UIHome,
      routeName: MasterplanRouteObjectGenerator.ROUTE_NAME,
      routeParams: { stopPointSlug: stopPointSlugs.MASTERPLAN_0 },
      exact: false,
      showHintDependingOnInternetConnection: false,
      availableInOfflineMode: true,
      availableOnTheseProjectTypes: [PROJECT_TYPES.NEIGHBOURHOOD_TYPE],
    }),
    new LinkMenuOption({
      nameTranslationKey: 'menu.neighbourhoodSubdivisions',
      nameId: 'subdivisions',
      icon: iconNames.UIAreas,
      showHintDependingOnInternetConnection: false,
      availableInOfflineMode: true,
      availableOnTheseProjectTypes: [PROJECT_TYPES.NEIGHBOURHOOD_TYPE],
      customAdapter: MenuAdapters.adaptNBHSubdivisionsSubmenu,
    }),
    new LinkMenuOption({
      nameTranslationKey: 'menu.towers',
      nameId: 'towers',
      icon: iconNames.UIAreas,
      showHintDependingOnInternetConnection: false,
      availableInOfflineMode: true,
      availableOnTheseProjectTypes: [PROJECT_TYPES.MULTITOWER_TYPE],
      customAdapter: MenuAdapters.adaptTowersSubmenu,
    }),
    new LinkMenuOption({
      nameTranslationKey: 'menu.topview',
      nameId: 'levels',
      icon: iconNames.UIFloors,
      routeName: TowerRouteObjectGenerator.ROUTE_NAME,
      routeParams: {},
      exact: false,
      showHintDependingOnInternetConnection: false,
      availableInOfflineMode: true,
      availableOnTheseProjectTypes: [PROJECT_TYPES.SINGLETOWER_TYPE],
      customAdapter: MenuAdapters.adaptLevelsItem,
    }),
    new LinkMenuOption({
      nameTranslationKey: 'menu.availability',
      nameId: 'availability',
      icon: iconNames.UIViewDetails,
      routeName: AvailabilityViewRouteObjectGenerator.ROUTE_NAME,
      routeParams: { resourceId: 1 },
      exact: false,
      showHintDependingOnInternetConnection: false,
      availableInOfflineMode: true,
      allowedByParams: [MENU_REQUIRED_PARAMS.IS_AVAILABILITY_VIEW_ENABLED],
    }),
    new LinkMenuOption({
      nameTranslationKey: 'menu.amenities',
      nameId: 'amenities',
      icon: iconNames.UIAmenities,
      routeName: 'general-amenities-gallery',
      routeParams: {},
      exact: false,
      showHintDependingOnInternetConnection: false,
      availableInOfflineMode: true,
      allowedByParams: [
        MENU_REQUIRED_PARAMS.IS_GENERAL_AMENITIES_GALLERY_AVAILABLE,
      ],
      customAdapter: MenuAdapters.adaptAmenitiesItem,
    }),
    new LinkMenuOption({
      nameTranslationKey: 'menu.tours',
      nameId: 'general-tour360',
      icon: iconNames.LegacyTour360,
      routeName: 'general-tour360-gallery',
      routeParams: { resourceId: 1 },
      exact: false,
      showHintDependingOnInternetConnection: false,
      availableInOfflineMode: true,
      allowedByParams: [
        MENU_REQUIRED_PARAMS.IS_GENERAL_TOUR360_GALLERY_ENABLED,
      ],
    }),
    new LinkMenuOption({
      nameTranslationKey: 'menu.video',
      nameId: 'video',
      icon: iconNames.UIVideo,
      routeName: 'video',
      routeParams: {},
      exact: true,
      showHintDependingOnInternetConnection: true,
      availableInOfflineMode: true,
      allowedByParams: [MENU_REQUIRED_PARAMS.IS_VIDEO_AVAILABLE],
    }),
    new DownloableMenuOption({
      nameTranslationKey: 'menu.brochure',
      nameId: 'brochure',
      icon: iconNames.UIDownload,
      showHintDependingOnInternetConnection: true,
      availableInOfflineMode: true,
      fileNameTranslationKey: 'projectBrochureFileName',
      allowedByParams: [MENU_REQUIRED_PARAMS.IS_PROJECT_BROCHURE_URL_AVAILABLE],
      customAdapter: MenuAdapters.adaptDownloableProjectBrochure,
    }),
    new LinkMenuOption({
      nameTranslationKey: 'menu.location',
      nameId: 'location',
      icon: iconNames.UILocation,
      routeName: 'location',
      routeParams: {},
      exact: false,
      showHintDependingOnInternetConnection: true,
      availableInOfflineMode: true,
      customAdapter: MenuAdapters.adaptLocationItem,
    }),
    new LinkMenuOption({
      nameTranslationKey: 'menu.contact',
      nameId: 'contact',
      icon: iconNames.UIPhone,
      routeName: 'contact',
      routeParams: {},
      exact: true,
      showHintDependingOnInternetConnection: false,
      availableInOfflineMode: false,
    }),
  ]
}
