<template>
  <AppSheetUnitSmall :title="unitShortIdentifier" :subtitle="subtitleText" />
</template>

<script>
import Unit from '@/classes/ProjectStructure/Unit/Unit'
import AppSheetUnitSmall from '@/components/UIKit/Standard/Organisms/AppSheet/AppSheetUnitSmall'
import { mapGetters } from 'vuex'

export default {
  name: 'UnitPanelSmallContentAmenities',
  components: {
    AppSheetUnitSmall,
  },
  props: {
    projectElement: {
      type: Unit,
      default: () => undefined,
    },
  },
  computed: {
    ...mapGetters({
      useTypologyForAmenitiesDescription:
        'Preferences/useTypologyForAmenitiesDescription',
    }),
    subtitleText() {
      if (this.useTypologyForAmenitiesDescription) {
        return this.unit.model
      } else {
        return this.$t('Amenities')
      }
    },
    unit() {
      return this.projectElement
    },
    unitShortIdentifier() {
      return this.unit.shortIdentifier
    },
  },
}
</script>
