import Unit from '@/classes/ProjectStructure/Unit/Unit'
import SlugGenerator from '@/classes/SlugGenerator'

class UnitRouteObjectGenerator {
  static get ROUTE_NAME() {
    return 'projectdetails'
  }
  static make(unit, customParams) {
    if (!(unit instanceof Unit)) {
      throw new Error(
        'UnitRouteObjectGenerator.make(): passed unit argument is not an instance of Unit class.'
      )
    }
    if (unit.unitLevels.length === 0) {
      throw new Error('UnitRouteObjectGenerator.make(): Unit has no level.')
    } else if (unit.unitLevels.length === 1) {
      return this._makeForSingleLevelUnits(unit, customParams)
    } else {
      return this._makeForMultiLevelUnits(unit, customParams)
    }
  }
  static _makeForSingleLevelUnits(unit, customParams) {
    const overridableParams = {
      ...this._getGalleryParams(unit.gallery),
    }
    return {
      name: this.ROUTE_NAME,
      params: {
        ...overridableParams,
        ...customParams,
        unitLevelSlug: undefined,
        descriptiveSlug: SlugGenerator.make(unit),
        unitId: unit.id,
      },
    }
  }
  static _makeForMultiLevelUnits(unit, customParams) {
    const unitLevelSlug = customParams?.unitLevelSlug || unit.mainUnitLevelSlug
    const unitLevel = unit.getUnitLevelBySlug(unitLevelSlug)
    if (!unitLevel) {
      throw new Error(
        `UnitRouteObjectGenerator._makeForMultiLevelUnits(): Invalid unitLevelSlug ${unitLevelSlug}.`
      )
    }
    const overridableParams = {
      ...this._getGalleryParams(unitLevel.gallery),
      unitLevelSlug: unitLevel.slug,
    }
    return {
      name: this.ROUTE_NAME,
      params: {
        ...overridableParams,
        ...customParams,
        descriptiveSlug: SlugGenerator.make(unit),
        unitId: unit.id,
      },
    }
  }
  static _getGalleryParams(gallery) {
    if (!gallery.firstAvailableCollection) {
      throw new Error(
        'UnitRouteObjectGenerator._getGalleryParams(): Gallery does not have any collection.'
      )
    }
    const preferredCollection =
      gallery.firstAvailableCollectionWithAnimation ||
      gallery.firstAvailableCollection
    const animatedResourceIndex = preferredCollection.resources.indexOf(
      preferredCollection.firstResourceWithAnimation
    )
    const resourceId =
      animatedResourceIndex >= 0 ? `${animatedResourceIndex + 1}` : '1'
    return {
      collectionSlug: preferredCollection.slug,
      resourceId,
    }
  }
}
export default UnitRouteObjectGenerator
