module.exports = class BaseMissingMessageHandler {
  constructor(loggingService) {
    this.loggingService = loggingService
  }
  // eslint-disable-next-line no-unused-vars
  handleMissingMessage(locale, key, vm, values) {
    throw Error(
      'BaseMissingMessageHandler: handleMissingMessage not implemented'
    )
  }
}
