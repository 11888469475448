import sassConstants from '@/constants/sass'

const {
  mediaBreakpointSm,
  mediaBreakpointHorizontalScrollAspectRatio,
  mediaBreakpointSmallScreenAspectRatio,
} = sassConstants

export default {
  data() {
    return {
      isSmallScreen: false,
      isSmallScreenVertical: false,
      isSmallScreenHorizontal: false,
      screenSizeRequiresScrollbar: false,
      smallScreensMediaQuery: null,
      smallScreensVerticalMediaQuery: null,
      smallScreensHorizontalMediaQuery: null,
      scrollbarRequiredMediaQuery: null,
    }
  },
  methods: {
    /**
     * Set mediaQuery match for mobile in data
     */
    setMediaQueryData() {
      this.smallScreensMediaQuery = window.matchMedia(
        `(max-width: ${mediaBreakpointSm}), (max-height: ${mediaBreakpointSm})`
      )
      this.smallScreensVerticalMediaQuery = window.matchMedia(
        `(max-width: ${mediaBreakpointSm}) and (max-aspect-ratio: ${mediaBreakpointSmallScreenAspectRatio})`
      )
      this.smallScreensHorizontalMediaQuery = window.matchMedia(
        `(max-height: ${mediaBreakpointSm}) and (min-aspect-ratio: ${mediaBreakpointSmallScreenAspectRatio})`
      )
      this.scrollbarRequiredMediaQuery = window.matchMedia(
        `(max-aspect-ratio: ${mediaBreakpointHorizontalScrollAspectRatio})`
      )
    },

    /**
     * Update data for mobile based on Media Query matches
     */
    updateMediaQueryResult() {
      this.isSmallScreen = this.smallScreensMediaQuery.matches
      this.isSmallScreenVertical = this.smallScreensVerticalMediaQuery.matches
      this.isSmallScreenHorizontal = this.smallScreensHorizontalMediaQuery.matches
      this.screenSizeRequiresScrollbar = this.scrollbarRequiredMediaQuery.matches
    },

    /**
     * Attach event listeners for change in Media Query.
     * addListener() method is used for backwards compatibility purposes.
     * Older browsers (i.e. Safari < 14) will use addListener() instead of addEventListener().
     */
    attachMediaQueriesListeners() {
      try {
        this.smallScreensMediaQuery.addEventListener(
          'change',
          this.updateMediaQueryResult
        )

        this.smallScreensVerticalMediaQuery.addEventListener(
          'change',
          this.updateMediaQueryResult
        )

        this.smallScreensHorizontalMediaQuery.addEventListener(
          'change',
          this.updateMediaQueryResult
        )

        this.scrollbarRequiredMediaQuery.addEventListener(
          'change',
          this.updateMediaQueryResult
        )
      } catch {
        try {
          this.smallScreensMediaQuery.addListener(this.updateMediaQueryResult)

          this.smallScreensVerticalMediaQuery.addListener(
            this.updateMediaQueryResult
          )

          this.smallScreensHorizontalMediaQuery.addListener(
            this.updateMediaQueryResult
          )

          this.scrollbarRequiredMediaQuery.addListener(
            this.updateMediaQueryResult
          )
        } catch (err) {
          this.$loggingService.logError(
            'There was a problem creating listener for change event in MediaQueryList'
          )
          this.$loggingService.logError(err)
        }
      }
    },

    /**
     * Remove event listeners for change in Media Query.
     * addListener() method is used for backwards compatibility purposes.
     * Older browsers (i.e. Safari < 14) will use addListener() instead of addEventListener().
     */
    removeMediaQueriesListeners() {
      try {
        this.smallScreensMediaQuery.removeEventListener(
          'change',
          this.updateMediaQueryResult
        )

        this.smallScreensVerticalMediaQuery.removeEventListener(
          'change',
          this.updateMediaQueryResult
        )

        this.smallScreensHorizontalMediaQuery.removeEventListener(
          'change',
          this.updateMediaQueryResult
        )

        this.scrollbarRequiredMediaQuery.removeEventListener(
          'change',
          this.updateMediaQueryResult
        )
      } catch {
        try {
          this.smallScreensMediaQuery.removeListener(
            this.updateMediaQueryResult
          )

          this.smallScreensVerticalMediaQuery.removeListener(
            this.updateMediaQueryResult
          )

          this.smallScreensHorizontalMediaQuery.removeListener(
            this.updateMediaQueryResult
          )

          this.scrollbarRequiredMediaQuery.removeListener(
            this.updateMediaQueryResult
          )
        } catch (err) {
          this.$loggingService.logError(
            'There was a problem removing listener for change event in MediaQueryList'
          )
          this.$loggingService.logError(err)
        }
      }
    },
  },
  created() {
    this.setMediaQueryData()
    this.attachMediaQueriesListeners()
    this.updateMediaQueryResult()
  },
  beforeDestroy() {
    this.removeMediaQueriesListeners()
  },
}
