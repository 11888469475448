<template>
  <div
    ref="pictureContainer"
    class="picture"
    :class="[imageContainClassName, coverModeClassName]"
  >
    <img v-if="sourceToShow" :src="sourceToShow.src" />
    <div
      v-else-if="isLoading && withSkeleton"
      class="picture__skeleton-box"
    ></div>
  </div>
</template>
<script>
export default {
  name: 'AppImage',
  props: {
    src: {
      type: String,
      required: true,
    },
    contain: {
      type: Boolean,
      default: false,
    },
    withSkeleton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMainSourceLoading: true,
      img: undefined,
      orientations: {
        LANDSCAPE: 'landscape',
        PORTRAIT: 'portrait',
      },
    }
  },
  computed: {
    imageContainClassName() {
      return this.contain ? 'contain' : ''
    },
    imageRatio() {
      if (this.sourceToShow) {
        return this.getRatio(this.sourceToShow.width, this.sourceToShow.height)
      }
      return undefined
    },
    containerRatio() {
      const container = this.$refs.pictureContainer

      if (this.container) {
        return this.getRatio(container.clientWidth, container.clientHeight)
      }
      return undefined
    },
    orientation() {
      const container = this.$refs.pictureContainer
      if (container) {
        return container.clientWidth > container.clientHeight
          ? this.orientations.LANDSCAPE
          : this.orientations.PORTRAIT
      }
      return undefined
    },
    coverModeClassName() {
      if (this.sourceToShow) {
        if (this.orientation === this.orientations.PORTRAIT) {
          return this.imageRatio > this.containerRatio
            ? 'picture--covered-in-width'
            : 'picture--covered-in-height'
        } else {
          return this.imageRatio > this.containerRatio
            ? 'picture--covered-in-height'
            : 'picture--covered-in-width'
        }
      } else {
        return ''
      }
    },
    isLoading() {
      return this.isMainSourceLoading
    },
    sourceToShow() {
      if (this.img && !this.isMainSourceLoading) {
        return this.img
      } else {
        return undefined
      }
    },
  },
  created() {
    this.img = new Image()
    this.img.src = this.src

    this.img.onload = () => {
      this.isMainSourceLoading = false
    }
  },
  methods: {
    getRatio(width, height) {
      if (!width || !height) {
        return
      }

      return Math.max(width, height) / Math.min(width, height)
    },
  },
}
</script>
<style lang="scss" scoped>
.picture {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    opacity: 0;
    animation: fade-in 1s forwards;
  }

  @at-root #{&}--covered-in-width {
    img {
      width: 100%;
    }
    &.contain {
      img {
        width: auto;
        height: 100%;
      }
    }
  }
  @at-root #{&}--covered-in-height {
    img {
      height: 100%;
    }
    &.contain {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  @at-root #{&}__skeleton-box {
    width: 100%;
    height: 100%;
    background-color: var(--gray-200);
    position: relative;
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0)
      );
      animation: shimmer 2s infinite;
      content: '';
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

@keyframes fade-in {
  100% {
    opacity: 1;
  }
}
</style>
