<template>
  <div id="masterplan-container">
    <TheFullViewportContainer>
      <transition-group name="masterplan__ui-fade" tag="div">
        <masterplan-top-bar-controls
          v-show="UIControlsShown"
          key="topbar"
          :project-has-exterior-tour360-url="projectHasExteriorTour360Url"
          :is-gallery-available="isGalleryAvailable"
          :includes-floors-button-in-mobile="enableInteractiveBackgroundZoom"
          @navigate-to-aerial-view="navigateToAerialView"
          @navigate-to-single-tower-topview="navigateToSingleTowerTopview"
          @navigate-to-gallery="navigateToGallery"
        />
        <ThePaginationControls
          v-show="UIControlsShown"
          key="masterplan-nav"
          :page-routes="spinPositions"
          :prev-button-offset="leftSidebarWidth"
          @route-updated="onRouteUpdated"
          @unrecognized-route="onUnrecognizedRoute"
        />
        <div
          v-if="shouldSingleTowerTopviewButtonBeShown"
          v-show="UIControlsShown"
          key="singleTowerTopviewButton"
          class="masterplan__buttons-wrapper"
        >
          <AppButtonText
            class="clickable masterplan__button"
            :text="$t('Ver Plantas')"
            :button-style="singleTowerTopviewButtonStyle"
            :size="singleTowerTopviewButtonSize"
            @click.native="navigateToSingleTowerTopview"
          />
        </div>
        <div v-show="UIControlsShown" key="zoomControls">
          <TheZoomControls with-background-image />
        </div>
      </transition-group>
      <selected-svg-element-info-panel
        ref="infoPanel"
        class="masterplan__info-panel"
        @width-updated="updateLeftSidebarWidth"
      />
      <ProjectElementPopupDisplayer class="masterplan__popup-displayer" />
    </TheFullViewportContainer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import hideUIControlsInTransition from '@/mixins/hideUIControlsInTransition'
import ThePaginationControls from '@/components/Gadgets/ThePaginationControls'
import TheFullViewportContainer from '@/components/Gadgets/TheFullViewportContainer'
import responsiveMixin from '@/mixins/responsiveMixin'
import SelectedSvgElementInfoPanel from '@/components/ComposedInfoPanels/SelectedSvgElementInfoPanel'
import GalleryPageRouteObjectGenerator from '@/classes/RouteObjectGenerators/GalleryPageRouteObjectGenerator'
import HomeRouteObjectGenerator from '@/classes/RouteObjectGenerators/HomeRouteObjectGenerator'
import TowerRouteObjectGenerator from '@/classes/RouteObjectGenerators/TowerRouteObjectGenerator'
import ExteriorTour360RouteObjectGenerator from '@/classes/RouteObjectGenerators/ExteriorTour360RouteObjectGenerator'
import MasterplanRouteObjectGenerator from '@/classes/RouteObjectGenerators/MasterplanRouteObjectGenerator'
import MasterplanTopBarControls from '@/components/ViewTopBarControls/MasterplanTopBarControls'
import AppButtonText from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonText'
import TheZoomControls from '@/components/Gadgets/TheZoomControls'
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'
import ProjectElementPopupDisplayer from '@/components/Gadgets/ProjectElementPopupDisplayer'
import stopPointSlugs from '@/constants/stop-point-slugs'

export default {
  name: 'Masterplan',
  components: {
    TheFullViewportContainer,
    ThePaginationControls,
    SelectedSvgElementInfoPanel,
    MasterplanTopBarControls,
    AppButtonText,
    TheZoomControls,
    ProjectElementPopupDisplayer,
  },
  mixins: [hideUIControlsInTransition, responsiveMixin],
  data() {
    return {
      spinPositions: [
        MasterplanRouteObjectGenerator.make({
          useTransition: true,
          hintText: this.$t('Giro 360'),
        }),
        MasterplanRouteObjectGenerator.make({
          useTransition: true,
          hintText: this.$t('Giro 360'),
          stopPointSlug: stopPointSlugs.MASTERPLAN_120,
        }),
        MasterplanRouteObjectGenerator.make({
          useTransition: true,
          hintText: this.$t('Giro 360'),
          stopPointSlug: stopPointSlugs.MASTERPLAN_240,
        }),
      ],
      currentSpinPositionIndex: 0,
      leftSidebarWidth: 0,
      singleTowerTopviewButtonSize: sizes.LG,
      singleTowerTopviewButtonStyle: styles.LIGHT,
    }
  },
  computed: {
    ...mapGetters({
      isGalleryAvailable: 'Project/isGalleryAvailable',
      singleTower: 'Topview/singleTower',
      projectHasExteriorTour360Url: 'Topview/projectHasExteriorTour360Url',
      project: 'Topview/project',
      imageZoom: 'ZoomTracker/imageZoom',
      enableInteractiveBackgroundZoom:
        'Preferences/enableInteractiveBackgroundZoom',
    }),
    shouldSingleTowerTopviewButtonBeShown() {
      return (
        this.isSmallScreen &&
        this.$projectType.is([this.$projectType.SINGLETOWER_TYPE]) &&
        !this.enableInteractiveBackgroundZoom
      )
    },
  },
  watch: {
    isSmallScreen() {
      if (!this.isSmallScreen && this.$refs.infoPanel) {
        this.$refs.infoPanel.close()
      }
    },
    imageZoom() {
      this.$refs.infoPanel?.close()
    },
  },
  mounted() {
    if (this.isSmallScreenVertical) {
      this.$store.dispatch('Layout/setTopLayerCurrentHint', 'RotateDeviceHint')
    }
  },
  methods: {
    onUnrecognizedRoute({ alternativeRoute }) {
      const homeRouteObject = HomeRouteObjectGenerator.make()
      const redirectionRoute = alternativeRoute || homeRouteObject
      redirectionRoute.params.useTransition = false
      this.$loggingService.logError(
        `Masterplan: The current route '${this.$route.path}' is not recognized in the ThePaginationControls as a valid option. ` +
          `Redirecting to: ${JSON.stringify(redirectionRoute)}.`
      )
      this.$router.replace(redirectionRoute)
    },
    onRouteUpdated({ routeIndex }) {
      this.currentSpinPositionIndex = routeIndex
    },
    updateLeftSidebarWidth(infoPanelWidth) {
      this.leftSidebarWidth = infoPanelWidth
    },
    navigateToGallery() {
      const galleryPageRouteObject = GalleryPageRouteObjectGenerator.make()
      this.$router.push(galleryPageRouteObject)
    },
    navigateToAerialView() {
      if (this.projectHasExteriorTour360Url) {
        const projectExteriorTour360RouteObject = ExteriorTour360RouteObjectGenerator.make(
          this.project
        )
        this.$router.push(projectExteriorTour360RouteObject)
      } else {
        this.$loggingService.logError(
          `Masterplan: navigateToAerialView() is not available in projects without exteriorTour360Url`
        )
      }
    },
    navigateToSingleTowerTopview() {
      if (this.$projectType.is([this.$projectType.SINGLETOWER_TYPE])) {
        const topviewRouteObject = TowerRouteObjectGenerator.make(
          this.singleTower,
          {
            useTransition: true,
            hideUIControlsDuringTransition: true,
          }
        )
        this.$router.push(topviewRouteObject)
      } else {
        this.$loggingService.logError(
          `Masterplan: navigateToSingleTowerTopview() is not available in projects of type ${this.$projectType.name}`
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#masterplan-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 320;
  pointer-events: none;
}

.masterplan__ui-fade {
  @at-root #{&}-enter-active {
    transition: opacity 300ms ease-in-out;
  }

  @at-root #{&}-enter,
    #{&}-leave-to {
    opacity: 0;
  }
}

.masterplan__info-panel {
  position: relative;
  z-index: 20;
}

.masterplan__buttons-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 7%;
  z-index: 10;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include small-screens-horizontal {
    position: absolute;
  }
}

.masterplan__popup-displayer {
  pointer-events: all;
}
</style>
