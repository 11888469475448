import BaseProjectElement from '@/classes/ProjectStructure/BaseProjectElement'

export default class SvgTargetElementIdMap {
  constructor() {
    this._svgTargetElementIdMap = {}
    this._listedElements = []
    this._listedIds = []
  }
  add(element, { uniqueElementIdThroughoutProject } = {}) {
    const elementIdToAssign =
      uniqueElementIdThroughoutProject || element.svgTargetElementId

    if (!this._isElementValid(element)) {
      throw new Error(
        `SvgTargetIdElementMap.add(): Invalid element: ${element}.`
      )
    } else if (this.getElement(elementIdToAssign)) {
      throw new Error(
        `SvgTargetIdElementMap.add(): Element with svgTargetElementId ${elementIdToAssign} is already set.`
      )
    } else {
      this._svgTargetElementIdMap[elementIdToAssign] = element
      this._listedElements.push(element)
      this._listedIds.push(elementIdToAssign)
    }
  }
  getElement(svgTargetElementId) {
    return this._svgTargetElementIdMap[svgTargetElementId]
  }
  _isElementValid(element) {
    return element instanceof BaseProjectElement
  }
  get elements() {
    return this._listedElements
  }
  get ids() {
    return this._listedIds
  }
}
