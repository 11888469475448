<template>
  <div class="unit-card-cover">
    <div class="unit-card-cover__image">
      <ImagePlaceholder class="unit-card-cover__image__placeholder" />
      <SpriteClip
        class="unit-card-cover__image__preview"
        :image-url="previewImageUrl"
      />
      <AppImage
        class="unit-card-cover__image__picture"
        :src="previewImageUrl"
      />
    </div>
    <div class="unit-card-cover__info">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import ImagePlaceholder from '@/components/Gadgets/ImagePlaceholder'
import SpriteClip from '@/components/Gadgets/SpriteClip'
import AppImage from '@/components/UIKit/Standard/Atoms/AppImage'

export default {
  name: 'AppCardCover',
  components: {
    ImagePlaceholder,
    SpriteClip,
    AppImage,
  },
  props: {
    previewImageUrl: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.unit-card-cover {
  position: relative;
  height: 150px;
  min-width: 267px;
  overflow: hidden;
  background: var(--gray);

  @at-root &__image {
    position: relative;
    transform: translate(-10%, calc(75px - 50%));

    @at-root &__picture,
      &__preview {
      position: absolute;
      width: 100%;
      top: 0;
    }
    @at-root &__preview {
      display: block;
      object-fit: cover;
    }
    @at-root &__placeholder {
      width: 100%;
    }
  }

  @at-root &__info {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 12px;
    background: linear-gradient(
      180deg,
      rgba(16, 24, 40, 0) 0%,
      rgba(16, 24, 40, 0.7) 100%
    );

    &:empty {
      display: none;
    }
  }
}
</style>
