<template>
  <div class="feature-list-plain">
    <ul class="feature-list-plain__list">
      <li v-for="feature in features" :key="feature.id">
        <AppFeatureListItem
          :text="feature.longText"
          :icon="feature.icon"
          plain
        />
      </li>
    </ul>
  </div>
</template>

<script>
import AppFeatureListItem from '@/components/UIKit/Standard/Atoms/AppFeatureListItem'
import Feature from '@/classes/Feature/Feature'

export default {
  name: 'AppFeatureListPlain',
  components: {
    AppFeatureListItem,
  },
  props: {
    features: {
      type: Array,
      required: true,
      validator: (value) =>
        value.every((feature) => feature instanceof Feature),
    },
  },
}
</script>

<style lang="scss" scoped>
.feature-list-plain {
  display: flex;
  justify-content: center;
  @at-root &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
    max-width: 250px;
  }

  ul,
  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
}
</style>
