<template>
  <section class="container">
    <h1>AppFeatureListItem</h1>
    <table>
      <tr>
        <th></th>
        <td><AppFeatureListItem :text="demoText" :icon="demoIcon" /></td>
      </tr>
      <tr>
        <th>:small</th>
        <td><AppFeatureListItem :text="demoText" :icon="demoIcon" small /></td>
      </tr>
      <tr>
        <th>:plain</th>
        <td><AppFeatureListItem :text="demoText" :icon="demoIcon" plain /></td>
      </tr>
    </table>

    <h1>FeatureList</h1>
    <div class="feature-lists">
      <AppFeatureList
        class="feature-list"
        title="Instalaciones"
        :features="demoFeatureList"
      />
      <AppFeatureList
        class="feature-list feature-list--with-scroll"
        title="Instalaciones"
        :features="demoFeatureList"
      />
    </div>
    <h1>AppFeatureListPlain</h1>
    <div class="feature-list-plain">
      <AppFeatureListPlain class="feature-list" :features="demoFeatureList" />
    </div>
    <h1>AppFeatureListSmall</h1>
    <div class="feature-list-small">
      <AppFeatureListSmall class="feature-list" :features="demoFeatureList" />
    </div>
  </section>
</template>

<script>
import FeatureFactory from '@/classes/Feature/FeatureFactory'
import AppFeatureListItem from '@/components/UIKit/Standard/Atoms/AppFeatureListItem'
import AppFeatureList from '@/components/UIKit/Standard/Molecules/AppFeatureList'
import AppFeatureListPlain from '@/components/UIKit/Standard/Molecules/AppFeatureListPlain'
import AppFeatureListSmall from '@/components/UIKit/Standard/Molecules/AppFeatureListSmall'
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'
import iconNames from '@/constants/ui-kit/standard/icon-names'

export default {
  name: 'FeatureListViewer',
  components: {
    AppFeatureListItem,
    AppFeatureList,
    AppFeatureListPlain,
    AppFeatureListSmall,
  },
  data() {
    return {
      sizes: Object.values(sizes),
      styles: Object.values(styles),
      demoText: 'Some feature',
      demoIcon: iconNames.FeatureArmaniCasa,
      demoFeatureList: [],
    }
  },
  created() {
    const demoFeatureData = [
      {
        id: 'METRAJE',
        value: 106,
        valueType: 'Integer',
        showInSmallPopup: true,
        position: 1,
      },
      {
        id: 'RECAMARA',
        value: 3,
        valueType: 'Integer',
        showInSmallPopup: true,
        position: 2,
      },
      {
        id: 'PARKING',
        value: 1,
        valueType: 'Integer',
        showInSmallPopup: true,
        position: 6,
      },
      {
        id: 'PARKING OPCIONAL',
        value: 1,
        valueType: 'Integer',
        showInSmallPopup: false,
        position: 7,
      },
      {
        id: 'BAR ROOFTOP',
        value: true,
        valueType: 'Boolean',
        showInSmallPopup: false,
        position: 1,
      },
      {
        id: 'GIMNASIO',
        value: true,
        valueType: 'Boolean',
        showInSmallPopup: false,
        position: 2,
      },
      {
        id: 'SKY LOUNGE',
        value: true,
        valueType: 'Boolean',
        showInSmallPopup: false,
        position: 3,
      },
      {
        id: 'YOGA & CROSSFIT',
        value: true,
        valueType: 'Boolean',
        showInSmallPopup: false,
        position: 4,
      },
      {
        id: 'DEN SOCIAL',
        value: true,
        valueType: 'Boolean',
        showInSmallPopup: false,
        position: 5,
      },
    ]
    const featureFactory = new FeatureFactory()

    this.demoFeatureList = demoFeatureData.map((featureData) =>
      featureFactory.makeFeature(featureData, this.$i18n)
    )
  },
}
</script>

<style lang="scss" scoped>
.container {
  margin: 80px 50px;
}
h1 {
  font-size: 1.5rem;
  margin: 2.5em 0 1.5em;
  border-bottom: 1px solid black;
}
.feature-lists {
  display: flex;
}
.feature-list {
  flex: 1;

  &--with-scroll {
    height: 300px;
  }
}
.feature-list-plain,
.feature-list-small {
  max-width: 300px;
}
td,
th {
  padding: 10px;
  border: 1px solid var(--gray-300);
  font-weight: var(--font-weight-normal);
}
</style>
