<template>
  <TheTopBar :controls="controls" with-fullscreen-button></TheTopBar>
</template>

<script>
import TheTopBar from '@/components/Gadgets/TheTopBar'
import TopBarControlsSuite from '@/classes/TopBarControls/TopBarControlsSuite'
import TopBarDropdownGenerator from '@/classes/TopBarControls/TopBarDropdownGenerator'
import responsiveMixin from '@/mixins/responsiveMixin'

export default {
  name: 'GeneralAmenitiesGalleryTopBarControls',
  components: {
    TheTopBar,
  },
  mixins: [responsiveMixin],
  props: {
    selectedTour360: {
      type: String,
      default: undefined,
    },
    tour360SelectOptions: {
      type: Array,
      default: () => [],
    },
    shouldTour360SelectorBeShown: {
      type: Boolean,
      default: undefined,
    },
  },
  computed: {
    controls() {
      return new TopBarControlsSuite({
        rightControls: [
          TopBarDropdownGenerator.create({
            selectedValue: this.selectedTour360,
            options: this.tour360SelectOptions,
            show: () => this.shouldTour360SelectorBeShown,
            eventHandler: (e) => this.$emit('navigate-to-tour360', e),
          }),
        ],
      })
    },
  },
}
</script>
