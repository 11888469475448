import Level from '@/classes/ProjectStructure/Level'

export default class LevelAdapter {
  static adapt(levelJsonData) {
    const {
      levelId,
      displayName,
      levelSlug,
      order,
      swipeImgUrl,
      swipeSvgUrl,
    } = levelJsonData
    return new Level({
      id: `${levelId}`,
      name: displayName,
      slug: levelSlug,
      order: order,
      image: swipeImgUrl,
      svg: swipeSvgUrl,
    })
  }
}
