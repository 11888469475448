import PriceError from '@/classes/Exceptions/PriceError'

class BasePrice {
  // eslint-disable-next-line no-unused-vars
  constructor({ amount, currencyCode, rentPaymentPeriod }) {
    this._className = 'BasePrice'
    this._amount = amount
    this._currencyCode = currencyCode

    if (typeof this._amount !== 'number' || Number.isNaN(this._amount)) {
      throw new PriceError(`Invalid amount ${this._amount}`, this)
    }
    if (
      typeof this._currencyCode !== 'string' ||
      this._currencyCode.length !== 3
    ) {
      throw new PriceError(`Invalid currencyCode ${this._currencyCode}`, this)
    }
  }
  get amount() {
    return this._amount
  }
  get currencyCode() {
    return this._currencyCode
  }
  get rentPaymentPeriod() {
    throw new PriceError(
      'rentPaymentPeriod is not implemented in base class BasePrice.',
      this
    )
  }
  toJSON() {
    const { amount, currencyCode } = this
    return {
      amount,
      currencyCode,
    }
  }
  toString() {
    return `${this._className} ${JSON.stringify(this)}`
  }
}
export default BasePrice
