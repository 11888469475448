import {
  DISPLAY_AVAILABILITY,
  DISPLAY_PRICE,
  ENABLE_CHECK_PRICE_FORMS,
  ALLOW_FULLSCREEN_NAVIGATION,
  ENABLE_CONTACT_FORMS,
  ENABLE_UNIT_PRICE_QUOTATION,
} from '@/constants/preferences/preference-names'

export default [
  DISPLAY_AVAILABILITY,
  DISPLAY_PRICE,
  ENABLE_CHECK_PRICE_FORMS,
  ALLOW_FULLSCREEN_NAVIGATION,
  ENABLE_CONTACT_FORMS,
  ENABLE_UNIT_PRICE_QUOTATION,
]
