export default class AppPropModel {
  constructor({ type, defaultValue, required = false }) {
    this._acceptedPropTypes = []
    const typeOrTypes = type
    if (Array.isArray(typeOrTypes)) {
      typeOrTypes.forEach((type) => {
        this._addAcceptedPropType(type)
      })
    } else {
      this._addAcceptedPropType(typeOrTypes)
    }

    this._isRequired = !!required
    this._presets = []
    this._value = defaultValue
    this._enabled = this._isRequired

    if (this._value !== undefined) {
      this.addPreset(this._value, 'Default')
    } else if (this._isRequired) {
      throw new Error(`AppPropModel: Required props must have a default value.`)
    }
  }
  _addAcceptedPropType(type) {
    if (typeof type !== 'function') {
      throw new Error(
        `AppPropModel._addAcceptedPropType(): Invalid type ${type}.`
      )
    }
    this._acceptedPropTypes.push(type.name)
  }
  addPreset(presetValue, presetLabel = '') {
    if (!this._acceptedPropTypes.includes(presetValue.constructor.name)) {
      throw new Error(
        `AppPropModel.addPreset(): Invalid presetValue ${presetValue} for a prop of type ${this._acceptedPropTypes}`
      )
    }
    this._presets.push({
      value: presetValue,
      label: presetLabel,
    })

    if (this._value === undefined) {
      this._value = presetValue
    }
  }
  enable() {
    this._enabled = true
  }
  disable() {
    if (this._isRequired) {
      throw new Error(
        `AppPropModel.disable(): Required props cannot be disabled.`
      )
    }
    this._enabled = false
  }
  update(newValue) {
    if (!this._acceptedPropTypes.includes(newValue.constructor.name)) {
      throw new Error(
        `AppPropModel.value(): Invalid value ${newValue} for a prop of type ${this._acceptedPropTypes}`
      )
    }
    this._value = newValue
  }
  updateDefault(newValue) {
    this.update(newValue)
    this._presets[0].value = newValue
  }
  set value(newValue) {
    this.update(newValue)
  }
  get value() {
    return this._enabled ? this._value : undefined
  }
  get presets() {
    return this._presets
  }
  get type() {
    return this._acceptedPropTypes.length > 1
      ? `${this._acceptedPropTypes}`
      : this._acceptedPropTypes[0]
  }
  get enabled() {
    return this._enabled
  }
  get required() {
    return this._isRequired
  }
}
