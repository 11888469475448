<template>
  <AppSheetWrapper class="app-sheet-location-large">
    <AppSheetRow v-if="logoImageUrl">
      <AppSheetBlockWithShareMenu :share-url="shareUrl">
        <AppSheetPreviewLogo :logo-image-url="logoImageUrl" />
      </AppSheetBlockWithShareMenu>
    </AppSheetRow>
    <AppSheetRow>
      <AppRichText :html-content="htmlContent" />
    </AppSheetRow>
  </AppSheetWrapper>
</template>

<script>
import AppSheetWrapper from '@/components/UIKit/Standard/Atoms/AppSheetWrapper'
import AppSheetRow from '@/components/UIKit/Standard/Atoms/AppSheetRow'
import AppSheetBlockWithShareMenu from '@/components/UIKit/Standard/Molecules/AppSheetBlockWithShareMenu'
import AppSheetPreviewLogo from '@/components/UIKit/Standard/Molecules/AppSheetPreviewLogo'
import AppRichText from '@/components/UIKit/Standard/Atoms/AppRichText'
import alignments from '@/constants/ui-kit/standard/alignments'

export default {
  name: 'AppSheetLocationLarge',
  components: {
    AppSheetWrapper,
    AppSheetRow,
    AppSheetBlockWithShareMenu,
    AppSheetPreviewLogo,
    AppRichText,
  },
  props: {
    logoImageUrl: {
      type: String,
      default: undefined,
    },
    htmlContent: {
      type: String,
      default: undefined,
    },
    shareUrl: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      shareMenuAlignment: alignments.ALIGN_RIGHT,
    }
  },
}
</script>
