import { render, staticRenderFns } from "./ButtonTextViewer.vue?vue&type=template&id=fee5e666&scoped=true"
import script from "./ButtonTextViewer.vue?vue&type=script&lang=js"
export * from "./ButtonTextViewer.vue?vue&type=script&lang=js"
import style0 from "./ButtonTextViewer.vue?vue&type=style&index=0&id=fee5e666&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fee5e666",
  null
  
)

export default component.exports