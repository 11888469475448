<template>
  <div v-if="backgroundImage" class="the-background-image-viewer">
    <SpriteClip
      class="the-background-image-viewer__layer"
      :image-url="backgroundImageUrl"
      :blured="true"
    />
    <img
      ref="backgroundImageElement"
      class="the-background-image-viewer__layer"
      :class="{
        'the-background-image-viewer__layer--ready': isBackgroundImageReady,
        'the-background-image-viewer__layer--fade-in-on-ready': useBackgroundImageFadeEffectOnReady,
      }"
      :src="backgroundImageUrl"
      @load="updateBackgroundImageReady"
    />
    <video
      v-if="animationLayerVideoUrl"
      class="the-background-image-viewer__layer"
      :class="{
        'the-background-image-viewer__layer--ready': isAnimationVideoReady,
        'the-background-image-viewer__layer--fade-in-on-ready': useBackgroundImageFadeEffectOnReady,
      }"
      :src="animationLayerVideoUrl"
      muted
      autoplay
      loop
      @play="isAnimationVideoReady = true"
    ></video>
    <svg-layer-factory
      v-if="svgLayerUrl"
      v-show="isBackgroundImageReady"
      :svg-url="svgLayerUrl"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SpriteClip from '@/components/Gadgets/SpriteClip'
import SvgLayerFactory from '@/components/SvgLayers/SvgLayerFactory'
import ImageResource from '@/classes/MediaResources/ImageResource'

export default {
  name: 'TheBackgroundImageViewer',
  components: {
    SpriteClip,
    SvgLayerFactory,
  },
  data() {
    return {
      isBackgroundImageReady: false,
      useBackgroundImageFadeEffectOnReady: false,
      isAnimationVideoReady: false,
    }
  },
  computed: {
    ...mapGetters({
      getBackground: 'BackgroundTree/getBackground',
    }),
    backgroundImage() {
      const backgroundResource = this.getBackground(this.$route.path)
      if (backgroundResource instanceof ImageResource) {
        return backgroundResource
      }
      return null
    },
    backgroundImageUrl() {
      return this.backgroundImage?.resourceUrl
    },
    svgLayerUrl() {
      return this.backgroundImage?.svgLayerUrl
    },
    animationLayerVideoUrl() {
      return this.backgroundImage?.animationLayerVideoUrl
    },
  },
  async mounted() {
    this.updateBackgroundImageReady()
    if (!this.isBackgroundImageReady) {
      this.useBackgroundImageFadeEffectOnReady = true
    }
    await this.$nextTick()
    this.$emit('mounted')
  },
  methods: {
    updateBackgroundImageReady() {
      this.isBackgroundImageReady = this.$refs.backgroundImageElement
        ? this.$refs.backgroundImageElement.complete
        : false
    },
  },
}
</script>

<style lang="scss" scoped>
.the-background-image-viewer {
  @at-root #{&}__layer {
    position: absolute;
    @include tm-media-background;
    @at-root #{&}--fade-in-on-ready {
      opacity: 0;
      &.the-background-image-viewer__layer--ready {
        transition: opacity 600ms ease-out;
        opacity: 1;
      }
    }
  }
}
</style>
