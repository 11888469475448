<template>
  <div class="quoter">
    <AppDropdown
      :size="dropdownSize"
      :dropdown-style="dropdownStyle"
      :options="quoteOptions"
      :selected-value="selectedPaymentOptionId"
      class="quoter__dropdown"
      @selectedOptionChange="changeSelectedOption"
    />
    <transition name="fade-switch" mode="out-in">
      <div
        v-if="shouldDisplayData"
        :key="selectedPaymentOptionId"
        class="quoter__data"
      >
        <div class="quoter__data__section quoter__data__section--summary">
          <AppQuoterResultTable :quote-table-data="filteredQuoteTableData" />
        </div>
        <div
          v-if="quoteInstallmentsTableData"
          class="quoter__data__section quoter__data__section--installments"
        >
          <h3
            class="installments-trigger"
            :class="{ 'installments-trigger--open': shouldDisplayInstallments }"
            @click="shouldDisplayInstallments = !shouldDisplayInstallments"
          >
            <span>{{ $t('Ver detalle de cuotas') }}</span>
            <AppIcon
              class="button-text-with-icon__icon"
              :size="iconSize"
              :icon="iconToggle"
            />
          </h3>
          <transition name="fade">
            <AppQuoterResultInstallmentsTable
              v-if="shouldDisplayInstallments"
              :quote-installments-table-data="quoteInstallmentsTableData"
            />
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AppDropdown from '@/components/UIKit/Standard/Molecules/AppDropdown'
import AppIcon from '@/components/UIKit/Standard/Atoms/AppIcon'
import AppQuoterResultTable from '@/components/UIKit/Standard/Atoms/AppQuoterResultTable'
import AppQuoterResultInstallmentsTable from '@/components/UIKit/Standard/Atoms/AppQuoterResultInstallmentsTable'
import Unit from '@/classes/ProjectStructure/Unit/Unit'
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'
import iconNames from '@/constants/ui-kit/standard/icon-names'
import QuoterDateTools from '@/classes/Quoter/QuoterDateTools'
import PriceFactory from '@/classes/Price/PriceFactory'
import { OfferTypes } from '@/constants/units'

export default {
  name: 'AppQuoter',
  components: {
    AppIcon,
    AppDropdown,
    AppQuoterResultTable,
    AppQuoterResultInstallmentsTable,
  },
  props: {
    unit: {
      type: Unit,
      required: true,
    },
  },
  data() {
    const defaultOptionValue = 'default'
    const defaultOptionText = this.$t('Seleccionar')

    return {
      dropdownSize: sizes.SM,
      dropdownStyle: styles.LIGHT,
      iconSize: sizes.SM,
      iconToggle: iconNames.UIDropdown,
      selectedPaymentOptionId: defaultOptionValue,
      defaultOption: {
        text: defaultOptionText,
        value: defaultOptionValue,
        disabled: true,
      },
      shouldDisplayInstallments: false,
      paymentFieldText: this.$t('Pago'),
      dateFieldText: this.$t('Fecha'),
      amountFieldText: this.$t('Monto'),
    }
  },
  computed: {
    ...mapGetters({
      getUnitAvailablePaymentMethods:
        'QuoterModule/getUnitAvailablePaymentMethods',
      getUnitQuotePrice: 'QuoterModule/getUnitQuotePrice',
    }),
    paymentMethods() {
      return this.getUnitAvailablePaymentMethods(this.unit)
    },
    quoteOptions() {
      const options = this.paymentMethods.map((paymentMethod) => {
        return {
          text: paymentMethod.name,
          value: paymentMethod.id,
        }
      })
      return [this.defaultOption, ...options]
    },
    shouldDisplayData() {
      return this.selectedPaymentOptionId !== this.defaultOption.value
    },
    selectedPaymentMethod() {
      return this.getPaymentMethod(this.selectedPaymentOptionId)
    },
    unitPrice() {
      return this.getUnitQuotePrice(this.unit, this.selectedPaymentOptionId)
    },
    quoteResult() {
      if (this.selectedPaymentMethod && this.unitPrice) {
        return this.selectedPaymentMethod.quote(this.unitPrice.amount)
      }
      return undefined
    },
    quoteFormattedQuoteDate() {
      return this.getFormattedFullDate(this.quoteResult?.quoteDate)
    },
    quoteFormattedTotalPriceAmount() {
      return this.getFormattedPriceFromAmount(this.quoteResult.priceAmount)
    },
    quoteFormattedInitialPaymentAmount() {
      return this.getFormattedPriceFromAmount(
        this.quoteResult?.initialPaymentAmount
      )
    },
    quoteFormattedFinalPaymentAmount() {
      return this.getFormattedPriceFromAmount(
        this.quoteResult?.finalPaymentAmount
      )
    },
    quoteFormattedBookingPriceAmount() {
      return this.getFormattedPrice(this.quoteResult.bookingPrice)
    },
    quoteFormattedDeferredAmount() {
      return this.getFormattedPriceFromAmount(this.quoteResult?.deferredAmount)
    },
    quoteFormattedMonthlyPaymentAmount() {
      return this.getFormattedPriceFromAmount(
        this.quoteResult?.monthlyPaymentAmount
      )
    },
    quoteInstallmentsTableData() {
      return this.quoteResult?.monthlyPayments?.map((payment, index) => {
        const formatedDate = this.getFormattedShortDate(payment.date)
        const formattedAmount = this.getFormattedPriceFromAmount(payment.amount)
        return {
          number: index + 1,
          date: formatedDate,
          amount: formattedAmount,
        }
      })
    },
    quoteFirstInstallmentDate() {
      return this.quoteInstallmentsTableData
        ? this.quoteInstallmentsTableData[0]?.date
        : undefined
    },
    quoteLastInstallmentDate() {
      return this.quoteInstallmentsTableData
        ? this.quoteInstallmentsTableData[
            this.quoteInstallmentsTableData.length - 1
          ]?.date
        : undefined
    },
    quoteTableData() {
      return this.quoteResult
        ? [
            {
              _apiFieldName: 'creation_date',
              fieldName: this.$t('Fecha de emisión'),
              value: this.quoteFormattedQuoteDate,
            },
            {
              _apiFieldName: 'total_price',
              fieldName: this.$t('Precio final a pagar'),
              value: this.quoteFormattedTotalPriceAmount,
            },
            {
              _apiFieldName: 'initial_payment_price',
              fieldName: this.$t('Enganche'),
              value: this.quoteFormattedInitialPaymentAmount,
            },
            {
              _apiFieldName: 'final_payment_price',
              fieldName: this.$t('Entrega'),
              value: this.quoteFormattedFinalPaymentAmount,
            },
            {
              _apiFieldName: 'booking_price',
              fieldName: this.$t('Monto reserva'),
              value: this.quoteFormattedBookingPriceAmount,
            },
            {
              _apiFieldName: 'deferred_price',
              fieldName: this.$t('Diferido'),
              value: this.quoteFormattedDeferredAmount,
            },
            {
              _apiFieldName: 'installments_count',
              fieldName: this.$t('Cantidad de cuotas mensuales'),
              value: this.quoteResult?.monthlyPaymentsCount,
            },
            {
              _apiFieldName: 'installment_price',
              fieldName: this.$t('Valor de la cuota mensual'),
              value: this.quoteFormattedMonthlyPaymentAmount,
            },
            {
              _apiFieldName: 'first_installment_date',
              fieldName: this.$t('Fecha primera cuota'),
              value: this.quoteFirstInstallmentDate,
            },
            {
              _apiFieldName: 'last_installment_date',
              fieldName: this.$t('Fecha última cuota'),
              value: this.quoteLastInstallmentDate,
            },
          ]
        : undefined
    },
    filteredQuoteTableData() {
      return this.quoteTableData.filter(
        (quoteItem) => quoteItem.value != undefined
      )
    },
    dataToSend() {
      const payment_option_name = this.selectedPaymentMethod.name
      const budget_result = this.filteredQuoteTableData?.map((quoteItem) => ({
        field_name: quoteItem._apiFieldName,
        readable_field_name: quoteItem.fieldName,
        value: quoteItem.value,
      }))
      const installments = this.quoteInstallmentsTableData?.map(
        (installmentItem) => ({
          payment_number: {
            readable_field_name: this.paymentFieldText,
            value: installmentItem.number,
          },
          date: {
            readable_field_name: this.dateFieldText,
            value: installmentItem.date,
          },
          amount: {
            readable_field_name: this.amountFieldText,
            value: installmentItem.amount,
          },
        })
      )
      return {
        payment_option_name,
        budget_result,
        installments,
      }
    },
  },
  mounted() {
    if (this.quoteOptions.length === 2) {
      this.changeSelectedOption(this.quoteOptions[1].value)
    }
  },
  methods: {
    async changeSelectedOption(newValue) {
      this.selectedPaymentOptionId = newValue
      await this.$nextTick()
      this.$emit('quote-ready', {
        paymentMethodName: this.selectedPaymentMethod.name,
        quoteTableData: this.filteredQuoteTableData,
        quoteInstallmentsTableData: this.quoteInstallmentsTableData,
        apiRequestData: this.dataToSend,
      })
    },
    getPaymentMethod(methodIdentifier) {
      return this.paymentMethods.find(
        (paymentMethod) => paymentMethod.id === methodIdentifier
      )
    },
    getFormattedShortDate(date) {
      return this.$i18n.dateFormatter.format(
        date,
        QuoterDateTools.shortDateFormatOptions
      )
    },
    getFormattedFullDate(date) {
      return this.$i18n.dateFormatter.format(
        date,
        QuoterDateTools.fullDateFormatOptions
      )
    },
    getFormattedPrice(price) {
      return this.$i18n.priceFormatter.format(price)
    },
    getFormattedPriceFromAmount(priceAmount) {
      if (!priceAmount) {
        return undefined
      } else {
        const price = new PriceFactory().makePrice(
          {
            amount: priceAmount,
            currencyCode: this.unitPrice.currencyCode,
          },
          OfferTypes.FOR_SALE
        )
        return this.getFormattedPrice(price)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.quoter {
  &__dropdown {
    max-width: unset;
    margin: 0 -0.3125rem;
  }

  &__data {
    display: flex;
    flex-direction: column;
    margin-top: 0.9375rem;
    gap: 0.9375rem;

    &__section {
      border-radius: 8px;
      border: 1px solid var(--gray-100);
      background: var(--gray-50);
      padding: 0.3125rem;
    }
  }
}
.installments-trigger {
  font-size: 0.875rem;
  padding: 0.9375rem 0.3125rem;
  margin: 0;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 300ms ease-out;
  user-select: none;

  &:active {
    background-color: var(--gray-100);
    transition: none;
  }

  &--open {
    .icon {
      transform: scaleY(-1);
    }
  }
}

.fade {
  &-enter-active {
    transition: filter 400ms ease-out;
  }
  &-enter {
    filter: opacity(0);
  }
}
.fade-switch {
  &-enter-active {
    transition: filter 600ms ease-in-out;
    transition-delay: 50ms;
  }
  &-enter {
    filter: opacity(0);
  }
}
</style>
