import VueI18n from 'vue-i18n'
import I18nError from '@/classes/Exceptions/I18nError'
import LocaleLoader from '@/i18n/LocaleLoader'
import PriceFormatter from '@/i18n/PriceFormatter'
import DateFormatter from '@/i18n/DateFormatter'
import Formatter from './Formatter'
import MissingMessageHandlerFactory from './MissingMessageHandlers/MissingMessageHandlerFactory'
import MissingInterpolatedValuesHandlerFactory from './MissingInterpolatedValuesHandler/MissingInterpolatedValuesHandlerFactory'
import LoggingServiceFactory from '@/classes/LoggingService/LoggingServiceFactory'

class I18nManager {
  constructor({ localeCode, defaultLocaleCode }) {
    this._localeCode = localeCode
    this._defaultLocaleCode = defaultLocaleCode
    this._localeLoader = new LocaleLoader()
    this._library = null
    this.loggingService = new LoggingServiceFactory().makeService()
  }
  setup() {
    try {
      this._loadLocaleAndInitializeLibrary(this._localeCode)
    } catch (error) {
      this.loggingService.logError(
        `An error ocurred while setting I18n up with locale ${this._localeCode}: ${error}. Falling back to default locale ${this._defaultLocaleCode}`
      )
      try {
        this._loadLocaleAndInitializeLibrary(this._defaultLocaleCode)
      } catch (error) {
        this.loggingService.logError(
          `Fatal error: Cannot set up I18n with the default locale ${this._defaultLocaleCode}: ${error}`
        )
      }
    }
  }
  _loadLocaleAndInitializeLibrary(localeCode) {
    this._localeLoader.load(localeCode)
    this._initializeLibrary(localeCode, this._localeLoader.localesMessages)
  }
  _initializeLibrary(localeCode, localesMessages) {
    try {
      const missingInterpolatedValuesHandler = new MissingInterpolatedValuesHandlerFactory(
        this.loggingService
      ).makeHandler()

      const missingMessageHandler = new MissingMessageHandlerFactory().makeHandler()

      this._library = new VueI18n({
        locale: localeCode,
        formatter: new Formatter({
          locale: localeCode,
          missingInterpolatedValuesHandler,
        }),
        missing: missingMessageHandler.handleMissingMessage.bind(
          missingMessageHandler
        ),
        messages: localesMessages,
      })
      this.setLocale(localeCode)
    } catch (error) {
      throw new I18nError(
        `Error initializing vue-i18n library: ${error.toString()}.`
      )
    }
  }
  get library() {
    return this._library
  }
  setLocale(localeCode) {
    if (!this._localeLoader.localeIsLoaded(localeCode)) {
      throw new I18nError(`Locale ${localeCode} was not loaded`)
    }
    this._library.locale = localeCode
    this._library.priceFormatter = new PriceFormatter(localeCode)
    this._library.dateFormatter = new DateFormatter(localeCode)
  }
}
export default I18nManager
