<template>
  <article class="unit-card-container">
    <router-link v-if="to" :to="to" class="unit-card-container__link">
      <slot></slot>
    </router-link>
    <slot v-else></slot>
  </article>
</template>

<script>
export default {
  name: 'AppCardContainer',
  props: {
    to: {
      type: [Object, String],
      default: () => undefined,
    },
  },
}
</script>

<style lang="scss" scoped>
.unit-card-container {
  overflow: hidden;
  border-radius: 8px;
  background-color: #fff;
  @at-root &__link {
    text-decoration: none;
  }
}
</style>
