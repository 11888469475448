<template>
  <AppSheetWrapper class="sheet-point-of-interest-large">
    <AppSheetRow>
      <header>
        <AppSheetBlockWithShareMenu :share-url="shareUrl">
          <AppSheetPreviewImage :preview-image-url="previewImageUrl" />
        </AppSheetBlockWithShareMenu>
        <AppSheetTitle :title="title" />
      </header>
    </AppSheetRow>
    <AppSheetRow v-if="!!tour360To">
      <AppSheetBlockButtonGroup>
        <AppButtonTextRouterLink
          v-if="tour360To"
          :text="$t('Tour 360º')"
          :size="buttonSize"
          :button-style="buttonTour360Style"
          :to="tour360To"
        />
      </AppSheetBlockButtonGroup>
    </AppSheetRow>
    <AppSheetRow v-if="!!longRichText">
      <AppRichText :html-content="longRichText" />
    </AppSheetRow>
  </AppSheetWrapper>
</template>

<script>
import AppSheetWrapper from '@/components/UIKit/Standard/Atoms/AppSheetWrapper'
import AppSheetRow from '@/components/UIKit/Standard/Atoms/AppSheetRow'
import AppSheetTitle from '@/components/UIKit/Standard/Atoms/AppSheetTitle'
import AppSheetBlockWithShareMenu from '@/components/UIKit/Standard/Molecules/AppSheetBlockWithShareMenu'
import AppSheetBlockButtonGroup from '@/components/UIKit/Standard/Molecules/AppSheetBlockButtonGroup'
import AppSheetPreviewImage from '@/components/UIKit/Standard/Molecules/AppSheetPreviewImage'
import AppButtonTextRouterLink from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonTextRouterLink'
import AppRichText from '@/components/UIKit/Standard/Atoms/AppRichText'

import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'

export default {
  name: 'AppSheetPointOfInterestLarge',
  components: {
    AppSheetWrapper,
    AppSheetRow,
    AppSheetTitle,
    AppSheetBlockWithShareMenu,
    AppSheetBlockButtonGroup,
    AppSheetPreviewImage,
    AppButtonTextRouterLink,
    AppRichText,
  },
  props: {
    previewImageUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    longRichText: {
      type: String,
      default: undefined,
    },
    tour360To: {
      type: Object,
      default: () => undefined,
    },
    shareUrl: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      buttonSize: sizes.SM,
      buttonTour360Style: styles.PRIMARY,
    }
  },
}
</script>

<style lang="scss" scoped>
.sheet-point-of-interest-large {
  position: relative;
  height: 100%;
}
</style>
