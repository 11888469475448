const RaiseErrorOnMissingInterpolatedValuesHandler = require('./RaiseErrorOnMissingInterpolatedValuesHandler')
const ShowConsoleErrorOnMissingInterpolatedValuesHandler = require('./ShowConsoleErrorOnMissingInterpolatedValuesHandler')

module.exports = class MissingInterpolatedValuesHandlerFactory {
  constructor() {
    this.selectedHandler =
      process.env.VUE_APP_MISSING_INTERPOLATED_VALUES_BEHAVIOR
    this.availableHandlers = {
      RaiseErrorOnMissingInterpolatedValuesHandler,
      ShowConsoleErrorOnMissingInterpolatedValuesHandler,
    }
  }
  makeHandler() {
    const HandlerToUse = this.availableHandlers[this.selectedHandler]
    try {
      return new HandlerToUse()
    } catch (error) {
      throw new Error(
        `MissingInterpolatedValuesHandlerFactory: There was a problem creating a MissingInterpolatedValuesHandler with key ${this.selectedHandler} from settings: ${error}`
      )
    }
  }

  validateEnvVariables() {
    if (!Object.keys(this.availableHandlers).includes(this.selectedHandler)) {
      throw new Error(
        `MissingInterpolatedValuesHandlerFactory: selected generator "${this.selectedHandler}" does not exist`
      )
    }
  }
}
