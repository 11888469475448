const BaseMissingMessageHandler = require('./BaseMissingMessageHandler')

module.exports = class ReturnEmptyStringMissingMessageHandler extends BaseMissingMessageHandler {
  // eslint-disable-next-line no-unused-vars
  handleMissingMessage(locale, key, vm, values) {
    this.loggingService.logError(
      `Missing i18n message for locale ${locale} key ${key}. Returning empty string`
    )
    return ''
  }
}
