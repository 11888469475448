<template>
  <section>
    <VTitle text="AppSheetUnitMedium" />
    <div class="example-component">
      <div class="example-component__controls">
        <VPropControl
          v-for="(propModel, propName) in propModels"
          :key="propName"
          :model="propModel"
          :name="propName"
        />
      </div>
      <div class="example-component__component">
        <VComponentWrapper :width="375" bordered resizable>
          <AppSheetUnitMedium
            :title="propModels.title.value"
            :features="propModels.features.value"
            :enter-to="propModels.enterTo.value"
            :tour360-to="propModels.tour360To.value"
            :price="propModels.price.value"
            :availability-status="propModels.availabilityStatus.value"
            :availability-color="propModels.availabilityColor.value"
            :with-unavailable-message="propModels.withUnavailableMessage.value"
            :with-check-price-button="propModels.withCheckPriceButton.value"
            :with-quoter-button="propModels.withQuoterButton.value"
          />
        </VComponentWrapper>
      </div>
    </div>
  </section>
</template>

<script>
import AppSheetUnitMedium from '@/components/UIKit/Standard/Organisms/AppSheet/AppSheetUnitMedium'
import BasePrice from '@/classes/Price/BasePrice'
import FeatureFactory from '@/classes/Feature/FeatureFactory'
import AppPropModel from '@/components/UIKit/Standard/ViewerSections/classes/AppPropModel'
import VTitle from '@/components/UIKit/Standard/ViewerSections/components/snippets/VTitle'
import VPropControl from '@/components/UIKit/Standard/ViewerSections/components/snippets/VPropControl'
import VComponentWrapper from '@/components/UIKit/Standard/ViewerSections/components/snippets/VComponentWrapper'

export default {
  name: 'AppSheetUnitMediumViewer',
  components: {
    AppSheetUnitMedium,
    VTitle,
    VPropControl,
    VComponentWrapper,
  },
  data() {
    return {
      propModels: {
        title: new AppPropModel({
          type: String,
          defaultValue: '25° A',
          required: true,
        }),
        features: new AppPropModel({
          type: Array,
          defaultValue: [],
          required: true,
        }),
        enterTo: new AppPropModel({
          type: Object,
          defaultValue: {},
        }),
        tour360To: new AppPropModel({
          type: Object,
          defaultValue: {},
        }),
        price: new AppPropModel({
          type: BasePrice,
        }),
        availabilityStatus: new AppPropModel({
          type: String,
        }),
        availabilityColor: new AppPropModel({
          type: String,
        }),
        withUnavailableMessage: new AppPropModel({
          type: Boolean,
          defaultValue: true,
        }),
        withCheckPriceButton: new AppPropModel({
          type: Boolean,
          defaultValue: true,
        }),
        withQuoterButton: new AppPropModel({
          type: Boolean,
          defaultValue: true,
        }),
      },
    }
  },
  created() {
    const demoFeaturesData = [
      {
        id: 'METRAJE',
        value: 106,
        valueType: 'Integer',
        showInSmallPopup: true,
        position: 1,
      },
      {
        id: 'RECAMARA',
        value: 3,
        valueType: 'Integer',
        showInSmallPopup: true,
        position: 2,
      },
      {
        id: 'PARKING',
        value: 1,
        valueType: 'Integer',
        showInSmallPopup: true,
        position: 6,
      },
      {
        id: 'PARKING OPCIONAL',
        value: 1,
        valueType: 'Integer',
        showInSmallPopup: false,
        position: 7,
      },
      {
        id: 'BAR ROOFTOP',
        value: true,
        valueType: 'Boolean',
        showInSmallPopup: false,
        position: 1,
      },
      {
        id: 'GIMNASIO',
        value: true,
        valueType: 'Boolean',
        showInSmallPopup: false,
        position: 2,
      },
      {
        id: 'SKY LOUNGE',
        value: true,
        valueType: 'Boolean',
        showInSmallPopup: false,
        position: 3,
      },
      {
        id: 'YOGA & CROSSFIT',
        value: true,
        valueType: 'Boolean',
        showInSmallPopup: false,
        position: 4,
      },
      {
        id: 'DEN SOCIAL',
        value: true,
        valueType: 'Boolean',
        showInSmallPopup: false,
        position: 5,
      },
      {
        id: 'SAUNA',
        value: true,
        valueType: 'Boolean',
        showInSmallPopup: false,
        position: 10,
      },
      {
        id: 'ÁREA COMERCIAL',
        value: true,
        valueType: 'Boolean',
        showInSmallPopup: false,
        position: 10,
      },
      {
        id: 'SALÓN DE JUEGOS',
        value: true,
        valueType: 'Boolean',
        showInSmallPopup: false,
        position: 10,
      },
      {
        id: 'POKER ROOM',
        value: true,
        valueType: 'Boolean',
        showInSmallPopup: false,
        position: 10,
      },
      {
        id: 'JACUZZI',
        value: true,
        valueType: 'Boolean',
        showInSmallPopup: false,
        position: 10,
      },
      {
        id: 'VALET PARKING',
        value: true,
        valueType: 'Boolean',
        showInSmallPopup: false,
        position: 10,
      },
      {
        id: 'SEGURIDAD',
        value: true,
        valueType: 'Boolean',
        showInSmallPopup: false,
        position: 10,
      },
      {
        id: 'LOBBY',
        value: true,
        valueType: 'Boolean',
        showInSmallPopup: false,
        position: 10,
      },
      {
        id: 'BAR',
        value: true,
        valueType: 'Boolean',
        showInSmallPopup: false,
        position: 10,
      },
      {
        id: 'OPEN KITCHEN',
        value: true,
        valueType: 'Boolean',
        showInSmallPopup: false,
        position: 10,
      },
      {
        id: 'SALÓN DE BELLEZA',
        value: true,
        valueType: 'Boolean',
        showInSmallPopup: false,
        position: 10,
      },
      {
        id: 'PET SHOP',
        value: true,
        valueType: 'Boolean',
        showInSmallPopup: false,
        position: 10,
      },
      {
        id: 'FULL SPA',
        value: true,
        valueType: 'Boolean',
        showInSmallPopup: false,
        position: 10,
      },
    ]
    const featureFactory = new FeatureFactory()
    const features = demoFeaturesData.map((featureData) =>
      featureFactory.makeFeature(featureData, this.$i18n)
    )

    this.propModels.features.updateDefault(features)
    this.propModels.features.addPreset(features.slice(0, 2), 'Two features')
    this.propModels.features.addPreset(features.slice(0, 1), 'Single features')
    this.propModels.features.addPreset([], 'Empty')

    this.propModels.price.addPreset(
      new BasePrice({
        amount: 196000,
        currencyCode: 'USD',
      }),
      'USD'
    )
    this.propModels.price.addPreset(
      new BasePrice({
        amount: 2000000,
        currencyCode: 'VND',
      }),
      'VND'
    )

    this.propModels.availabilityStatus.addPreset('Disponible', 'AVAILABLE')
    this.propModels.availabilityStatus.addPreset('Reservado', 'RESERVED')
    this.propModels.availabilityStatus.addPreset('Vendido', 'SOLD')

    this.propModels.availabilityColor.addPreset('#1ac366', 'AVAILABLE')
    this.propModels.availabilityColor.addPreset('#deca6e', 'RESERVED')
    this.propModels.availabilityColor.addPreset('#de7777', 'SOLD')
  },
}
</script>

<style lang="scss" scoped>
.example-component {
  display: flex;
  justify-content: stretch;
  align-items: stretch;

  @at-root &__controls {
    background: #fff;
    padding: 30px;
    border: 1px solid var(--gray-200);
    flex-basis: 300px;
    border-radius: 12px;
  }

  @at-root &__component {
    padding: 0 30px;
  }
}
</style>
