import FeatureFactory from '@/classes/Feature/FeatureFactory'

export default class UnitFeatureAdapter {
  static adapt(unit, unitFeatureJsonData, i18n) {
    const { id, value, valueType, showInSmallPopup } = unitFeatureJsonData
    const adaptedFeature = new FeatureFactory().makeFeature(
      {
        id,
        value,
        valueType,
        showInSmallPopup,
      },
      i18n
    )
    unit.addFeature(adaptedFeature)
  }
}
