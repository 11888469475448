<template>
  <section>
    <VTitle text="AppQuoterPDFContent" />
    <div class="example-component">
      <div class="example-component__controls">
        <VPropControl
          v-for="(propModel, propName) in propModels"
          :key="propName"
          :model="propModel"
          :name="propName"
        />
      </div>
      <div class="example-component__component">
        <AppQuoterPDFContent
          :key="propModels.unit.value.id"
          :unit="propModels.unit.value"
          :user-info="propModels.userInfo.value"
          :quote-data="propModels.quoteData.value"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import AppQuoterPDFContent from '@/components/UIKit/Standard/Organisms/AppQuoteWizard/AppQuoterPDFContent'
import AppPropModel from '@/components/UIKit/Standard/ViewerSections/classes/AppPropModel'
import VTitle from '@/components/UIKit/Standard/ViewerSections/components/snippets/VTitle'
import VPropControl from '@/components/UIKit/Standard/ViewerSections/components/snippets/VPropControl'
import ApartmentUnit from '@/classes/ProjectStructure/Unit/ApartmentUnit'
import OfficeUnit from '@/classes/ProjectStructure/Unit/OfficeUnit'
import ShopUnit from '@/classes/ProjectStructure/Unit/ShopUnit'
import HouseUnit from '@/classes/ProjectStructure/Unit/HouseUnit'

const acceptedUnitTypes = [ApartmentUnit, OfficeUnit, ShopUnit, HouseUnit]

export default {
  name: 'AppQuoterPDFContentViewer',
  components: {
    AppQuoterPDFContent,
    VTitle,
    VPropControl,
  },
  data() {
    return {
      propModels: {
        unit: new AppPropModel({
          type: acceptedUnitTypes,
          required: true,
          defaultValue: new ApartmentUnit({
            slug: 'test-apartment-unit',
            label: 'apartment Test',
            svgTargetElementId: 'a1',
            name: 'apartment Test',
            model: 'Model B',
            id: '1',
          }),
        }),
        userInfo: new AppPropModel({
          type: Object,
          required: true,
          defaultValue: {},
        }),
        quoteData: new AppPropModel({
          type: Object,
          required: true,
          defaultValue: {},
        }),
      },
    }
  },
  computed: {
    ...mapGetters({
      units: 'Topview/units',
      getUnitPrice: 'Topview/getUnitPrice',
    }),
    validUnits() {
      return this.units.filter((unit) => {
        return (
          acceptedUnitTypes.some((UnitType) => unit instanceof UnitType) &&
          unit.available &&
          this.getUnitPrice(unit)
        )
      })
    },
  },
  created() {
    this.propModels.unit.updateDefault(this.validUnits[0])
    this.validUnits.forEach((unit) => {
      this.propModels.unit.addPreset(unit, unit.name)
    })

    this.propModels.userInfo.updateDefault({
      name: 'Gi',
      phoneNumber: '123456',
      email: 'gi@web3d.app',
      sellerEmail: '',
    })
    this.propModels.userInfo.addPreset(
      {
        name: 'Gi',
        phoneNumber: '123456',
        email: 'gi@web3d.app',
        sellerEmail: 'seller@web3d.app',
      },
      'With seller email'
    )

    this.propModels.quoteData.updateDefault({
      paymentMethodName: 'Contado',
      quoteTableData: [
        {
          _apiFieldName: 'creation_date',
          fieldName: 'Fecha de emisión',
          value: '07/20/2023',
        },
        {
          _apiFieldName: 'total_price',
          fieldName: 'Precio final a pagar',
          value: 'USD 442,000',
        },
        {
          _apiFieldName: 'booking_price',
          fieldName: 'Monto reserva',
          value: 'USD 800',
        },
      ],
      apiRequestData: {
        payment_option_name: 'Contado',
        budget_result: [
          {
            field_name: 'creation_date',
            readable_field_name: 'Fecha de emisión',
            value: '07/20/2023',
          },
          {
            field_name: 'total_price',
            readable_field_name: 'Precio final a pagar',
            value: 'USD 442,000',
          },
          {
            field_name: 'booking_price',
            readable_field_name: 'Monto reserva',
            value: 'USD 800',
          },
        ],
      },
    })
    this.propModels.quoteData.addPreset(
      {
        paymentMethodName: '20% enganche, 10% diferido, 70% entrega',
        quoteTableData: [
          {
            _apiFieldName: 'creation_date',
            fieldName: 'Fecha de emisión',
            value: '07/20/2023',
          },
          {
            _apiFieldName: 'total_price',
            fieldName: 'Precio final a pagar',
            value: 'USD 598,000',
          },
          {
            _apiFieldName: 'initial_payment_price',
            fieldName: 'Enganche',
            value: 'USD 119,600',
          },
          {
            _apiFieldName: 'final_payment_price',
            fieldName: 'Entrega',
            value: 'USD 418,600',
          },
          {
            _apiFieldName: 'booking_price',
            fieldName: 'Monto reserva',
            value: 'USD 0',
          },
          {
            _apiFieldName: 'deferred_price',
            fieldName: 'Diferido',
            value: 'USD 59,800',
          },
          {
            _apiFieldName: 'interest_rate_description',
            fieldName: 'Tasa de interés anual',
            value: '15% Anual',
          },
          {
            _apiFieldName: 'installments_count',
            fieldName: 'Cantidad de cuotas mensuales',
            value: 5,
          },
          {
            _apiFieldName: 'installment_price',
            fieldName: 'Valor de la cuota mensual',
            value: 'USD 11,960',
          },
          {
            _apiFieldName: 'first_installment_date',
            fieldName: 'Fecha primera cuota',
            value: '08/2023',
          },
          {
            _apiFieldName: 'last_installment_date',
            fieldName: 'Fecha última cuota',
            value: '12/2023',
          },
        ],
        quoteInstallmentsTableData: [
          { number: 1, date: '08/2023', amount: 'USD 11,960' },
          { number: 2, date: '09/2023', amount: 'USD 11,960' },
          { number: 3, date: '10/2023', amount: 'USD 11,960' },
          { number: 4, date: '11/2023', amount: 'USD 11,960' },
          { number: 5, date: '12/2023', amount: 'USD 11,960' },
        ],
        apiRequestData: {
          payment_option_name: '20% enganche, 10% diferido, 70% entrega',
          budget_result: [
            {
              field_name: 'creation_date',
              readable_field_name: 'Fecha de emisión',
              value: '07/20/2023',
            },
            {
              field_name: 'total_price',
              readable_field_name: 'Precio final a pagar',
              value: 'USD 598,000',
            },
            {
              field_name: 'initial_payment_price',
              readable_field_name: 'Enganche',
              value: 'USD 119,600',
            },
            {
              field_name: 'final_payment_price',
              readable_field_name: 'Entrega',
              value: 'USD 418,600',
            },
            {
              field_name: 'booking_price',
              readable_field_name: 'Monto reserva',
              value: 'USD 0',
            },
            {
              field_name: 'deferred_price',
              readable_field_name: 'Diferido',
              value: 'USD 59,800',
            },
            {
              field_name: 'interest_rate_description',
              readable_field_name: 'Tasa de interés anual',
              value: '15% Anual',
            },
            {
              field_name: 'installments_count',
              readable_field_name: 'Cantidad de cuotas mensuales',
              value: 5,
            },
            {
              field_name: 'installment_price',
              readable_field_name: 'Valor de la cuota mensual',
              value: 'USD 11,960',
            },
            {
              field_name: 'first_installment_date',
              readable_field_name: 'Fecha primera cuota',
              value: '08/2023',
            },
            {
              field_name: 'last_installment_date',
              readable_field_name: 'Fecha última cuota',
              value: '12/2023',
            },
          ],
          installments: [
            {
              payment_number: { readable_field_name: 'Pago', value: 1 },
              date: { readable_field_name: 'Fecha', value: '08/2023' },
              amount: { readable_field_name: 'Monto', value: 'USD 11,960' },
            },
            {
              payment_number: { readable_field_name: 'Pago', value: 2 },
              date: { readable_field_name: 'Fecha', value: '09/2023' },
              amount: { readable_field_name: 'Monto', value: 'USD 11,960' },
            },
            {
              payment_number: { readable_field_name: 'Pago', value: 3 },
              date: { readable_field_name: 'Fecha', value: '10/2023' },
              amount: { readable_field_name: 'Monto', value: 'USD 11,960' },
            },
            {
              payment_number: { readable_field_name: 'Pago', value: 4 },
              date: { readable_field_name: 'Fecha', value: '11/2023' },
              amount: { readable_field_name: 'Monto', value: 'USD 11,960' },
            },
            {
              payment_number: { readable_field_name: 'Pago', value: 5 },
              date: { readable_field_name: 'Fecha', value: '12/2023' },
              amount: { readable_field_name: 'Monto', value: 'USD 11,960' },
            },
          ],
        },
      },
      '20% enganche, 10% diferido, 70% entrega'
    )
    this.propModels.quoteData.addPreset(
      {
        paymentMethodName: '30% enganche, 40% diferido, 30% entrega',
        quoteTableData: [
          {
            _apiFieldName: 'creation_date',
            fieldName: 'Fecha de emisión',
            value: '07/20/2023',
          },
          {
            _apiFieldName: 'total_price',
            fieldName: 'Precio final a pagar',
            value: 'USD 624,000',
          },
          {
            _apiFieldName: 'initial_payment_price',
            fieldName: 'Enganche',
            value: 'USD 123,600',
          },
          {
            _apiFieldName: 'final_payment_price',
            fieldName: 'Entrega',
            value: 'USD 249,600',
          },
          {
            _apiFieldName: 'booking_price',
            fieldName: 'Monto reserva',
            value: 'USD 1,200',
          },
          {
            _apiFieldName: 'deferred_price',
            fieldName: 'Diferido',
            value: 'USD 249,600',
          },
          {
            _apiFieldName: 'interest_rate_description',
            fieldName: 'Tasa de interés anual',
            value: '16% Anual',
          },
          {
            _apiFieldName: 'installments_count',
            fieldName: 'Cantidad de cuotas mensuales',
            value: 17,
          },
          {
            _apiFieldName: 'installment_price',
            fieldName: 'Valor de la cuota mensual',
            value: 'USD 14,682',
          },
          {
            _apiFieldName: 'first_installment_date',
            fieldName: 'Fecha primera cuota',
            value: '08/2023',
          },
          {
            _apiFieldName: 'last_installment_date',
            fieldName: 'Fecha última cuota',
            value: '12/2024',
          },
        ],
        quoteInstallmentsTableData: [
          { number: 1, date: '08/2023', amount: 'USD 14,682' },
          { number: 2, date: '09/2023', amount: 'USD 14,682' },
          { number: 3, date: '10/2023', amount: 'USD 14,682' },
          { number: 4, date: '11/2023', amount: 'USD 14,682' },
          { number: 5, date: '12/2023', amount: 'USD 14,682' },
          { number: 6, date: '01/2024', amount: 'USD 14,682' },
          { number: 7, date: '02/2024', amount: 'USD 14,682' },
          { number: 8, date: '03/2024', amount: 'USD 14,682' },
          { number: 9, date: '04/2024', amount: 'USD 14,682' },
          { number: 10, date: '05/2024', amount: 'USD 14,682' },
          { number: 11, date: '06/2024', amount: 'USD 14,682' },
          { number: 12, date: '07/2024', amount: 'USD 14,682' },
          { number: 13, date: '08/2024', amount: 'USD 14,682' },
          { number: 14, date: '09/2024', amount: 'USD 14,682' },
          { number: 15, date: '10/2024', amount: 'USD 14,682' },
          { number: 16, date: '11/2024', amount: 'USD 14,682' },
          { number: 17, date: '12/2024', amount: 'USD 14,682' },
        ],
        apiRequestData: {
          payment_option_name: '30% enganche, 40% diferido, 30% entrega',
          budget_result: [
            {
              field_name: 'creation_date',
              readable_field_name: 'Fecha de emisión',
              value: '07/20/2023',
            },
            {
              field_name: 'total_price',
              readable_field_name: 'Precio final a pagar',
              value: 'USD 624,000',
            },
            {
              field_name: 'initial_payment_price',
              readable_field_name: 'Enganche',
              value: 'USD 123,600',
            },
            {
              field_name: 'final_payment_price',
              readable_field_name: 'Entrega',
              value: 'USD 249,600',
            },
            {
              field_name: 'booking_price',
              readable_field_name: 'Monto reserva',
              value: 'USD 1,200',
            },
            {
              field_name: 'deferred_price',
              readable_field_name: 'Diferido',
              value: 'USD 249,600',
            },
            {
              field_name: 'interest_rate_description',
              readable_field_name: 'Tasa de interés anual',
              value: '16% Anual',
            },
            {
              field_name: 'installments_count',
              readable_field_name: 'Cantidad de cuotas mensuales',
              value: 17,
            },
            {
              field_name: 'installment_price',
              readable_field_name: 'Valor de la cuota mensual',
              value: 'USD 14,682',
            },
            {
              field_name: 'first_installment_date',
              readable_field_name: 'Fecha primera cuota',
              value: '08/2023',
            },
            {
              field_name: 'last_installment_date',
              readable_field_name: 'Fecha última cuota',
              value: '12/2024',
            },
          ],
          installments: [
            {
              payment_number: { readable_field_name: 'Pago', value: 1 },
              date: { readable_field_name: 'Fecha', value: '08/2023' },
              amount: { readable_field_name: 'Monto', value: 'USD 14,682' },
            },
            {
              payment_number: { readable_field_name: 'Pago', value: 2 },
              date: { readable_field_name: 'Fecha', value: '09/2023' },
              amount: { readable_field_name: 'Monto', value: 'USD 14,682' },
            },
            {
              payment_number: { readable_field_name: 'Pago', value: 3 },
              date: { readable_field_name: 'Fecha', value: '10/2023' },
              amount: { readable_field_name: 'Monto', value: 'USD 14,682' },
            },
            {
              payment_number: { readable_field_name: 'Pago', value: 4 },
              date: { readable_field_name: 'Fecha', value: '11/2023' },
              amount: { readable_field_name: 'Monto', value: 'USD 14,682' },
            },
            {
              payment_number: { readable_field_name: 'Pago', value: 5 },
              date: { readable_field_name: 'Fecha', value: '12/2023' },
              amount: { readable_field_name: 'Monto', value: 'USD 14,682' },
            },
            {
              payment_number: { readable_field_name: 'Pago', value: 6 },
              date: { readable_field_name: 'Fecha', value: '01/2024' },
              amount: { readable_field_name: 'Monto', value: 'USD 14,682' },
            },
            {
              payment_number: { readable_field_name: 'Pago', value: 7 },
              date: { readable_field_name: 'Fecha', value: '02/2024' },
              amount: { readable_field_name: 'Monto', value: 'USD 14,682' },
            },
            {
              payment_number: { readable_field_name: 'Pago', value: 8 },
              date: { readable_field_name: 'Fecha', value: '03/2024' },
              amount: { readable_field_name: 'Monto', value: 'USD 14,682' },
            },
            {
              payment_number: { readable_field_name: 'Pago', value: 9 },
              date: { readable_field_name: 'Fecha', value: '04/2024' },
              amount: { readable_field_name: 'Monto', value: 'USD 14,682' },
            },
            {
              payment_number: { readable_field_name: 'Pago', value: 10 },
              date: { readable_field_name: 'Fecha', value: '05/2024' },
              amount: { readable_field_name: 'Monto', value: 'USD 14,682' },
            },
            {
              payment_number: { readable_field_name: 'Pago', value: 11 },
              date: { readable_field_name: 'Fecha', value: '06/2024' },
              amount: { readable_field_name: 'Monto', value: 'USD 14,682' },
            },
            {
              payment_number: { readable_field_name: 'Pago', value: 12 },
              date: { readable_field_name: 'Fecha', value: '07/2024' },
              amount: { readable_field_name: 'Monto', value: 'USD 14,682' },
            },
            {
              payment_number: { readable_field_name: 'Pago', value: 13 },
              date: { readable_field_name: 'Fecha', value: '08/2024' },
              amount: { readable_field_name: 'Monto', value: 'USD 14,682' },
            },
            {
              payment_number: { readable_field_name: 'Pago', value: 14 },
              date: { readable_field_name: 'Fecha', value: '09/2024' },
              amount: { readable_field_name: 'Monto', value: 'USD 14,682' },
            },
            {
              payment_number: { readable_field_name: 'Pago', value: 15 },
              date: { readable_field_name: 'Fecha', value: '10/2024' },
              amount: { readable_field_name: 'Monto', value: 'USD 14,682' },
            },
            {
              payment_number: { readable_field_name: 'Pago', value: 16 },
              date: { readable_field_name: 'Fecha', value: '11/2024' },
              amount: { readable_field_name: 'Monto', value: 'USD 14,682' },
            },
            {
              payment_number: { readable_field_name: 'Pago', value: 17 },
              date: { readable_field_name: 'Fecha', value: '12/2024' },
              amount: { readable_field_name: 'Monto', value: 'USD 14,682' },
            },
          ],
        },
      },
      '30% enganche, 40% diferido, 30% entrega'
    )
    this.propModels.quoteData.addPreset(
      {
        paymentMethodName: '5% enganche, 15% diferido, 80% entrega',
        quoteTableData: [
          {
            _apiFieldName: 'creation_date',
            fieldName: 'Fecha de emisión',
            value: '07/20/2023',
          },
          {
            _apiFieldName: 'total_price',
            fieldName: 'Precio final a pagar',
            value: 'USD 572,000',
          },
          {
            _apiFieldName: 'initial_payment_price',
            fieldName: 'Enganche',
            value: 'USD 27,800',
          },
          {
            _apiFieldName: 'final_payment_price',
            fieldName: 'Entrega',
            value: 'USD 457,600',
          },
          {
            _apiFieldName: 'booking_price',
            fieldName: 'Monto reserva',
            value: 'USD 800',
          },
          {
            _apiFieldName: 'deferred_price',
            fieldName: 'Diferido',
            value: 'USD 85,800',
          },
          {
            _apiFieldName: 'interest_rate_description',
            fieldName: 'Tasa de interés anual',
            value: '10% Anual',
          },
          {
            _apiFieldName: 'installments_count',
            fieldName: 'Cantidad de cuotas mensuales',
            value: 1,
          },
          {
            _apiFieldName: 'installment_price',
            fieldName: 'Valor de la cuota mensual',
            value: 'USD 85,800',
          },
          {
            _apiFieldName: 'first_installment_date',
            fieldName: 'Fecha primera cuota',
            value: '08/2023',
          },
          {
            _apiFieldName: 'last_installment_date',
            fieldName: 'Fecha última cuota',
            value: '08/2023',
          },
        ],
        quoteInstallmentsTableData: [
          { number: 1, date: '08/2023', amount: 'USD 85,800' },
        ],
        apiRequestData: {
          payment_option_name: '5% enganche, 15% diferido, 80% entrega',
          budget_result: [
            {
              field_name: 'creation_date',
              readable_field_name: 'Fecha de emisión',
              value: '07/20/2023',
            },
            {
              field_name: 'total_price',
              readable_field_name: 'Precio final a pagar',
              value: 'USD 572,000',
            },
            {
              field_name: 'initial_payment_price',
              readable_field_name: 'Enganche',
              value: 'USD 27,800',
            },
            {
              field_name: 'final_payment_price',
              readable_field_name: 'Entrega',
              value: 'USD 457,600',
            },
            {
              field_name: 'booking_price',
              readable_field_name: 'Monto reserva',
              value: 'USD 800',
            },
            {
              field_name: 'deferred_price',
              readable_field_name: 'Diferido',
              value: 'USD 85,800',
            },
            {
              field_name: 'interest_rate_description',
              readable_field_name: 'Tasa de interés anual',
              value: '10% Anual',
            },
            {
              field_name: 'installments_count',
              readable_field_name: 'Cantidad de cuotas mensuales',
              value: 1,
            },
            {
              field_name: 'installment_price',
              readable_field_name: 'Valor de la cuota mensual',
              value: 'USD 85,800',
            },
            {
              field_name: 'first_installment_date',
              readable_field_name: 'Fecha primera cuota',
              value: '08/2023',
            },
            {
              field_name: 'last_installment_date',
              readable_field_name: 'Fecha última cuota',
              value: '08/2023',
            },
          ],
          installments: [
            {
              payment_number: { readable_field_name: 'Pago', value: 1 },
              date: { readable_field_name: 'Fecha', value: '08/2023' },
              amount: { readable_field_name: 'Monto', value: 'USD 85,800' },
            },
          ],
        },
      },
      '5% enganche, 15% diferido, 80% entrega'
    )
  },
}
</script>

<style lang="scss" scoped>
.example-component {
  display: flex;
  justify-content: stretch;
  align-items: stretch;

  @at-root &__controls {
    background: #fff;
    padding: 30px;
    border: 1px solid var(--gray-200);
    flex-basis: 300px;
    border-radius: 12px;
  }

  @at-root &__component {
    flex: 1;
    padding: 0 30px;
  }
}
</style>
