export default class ColorHandler {
  constructor(rgbColorHexString) {
    if (!this._isValidRgbColorHexString(rgbColorHexString)) {
      throw new Error(`ColorHandler.constructor(): Expected a valid string hex color (lenght=4 or length=7).
      Color provided was: ${rgbColorHexString}`)
    }

    this._rgbColorHexString = rgbColorHexString
    this._rgbColorObject = this._rgbColorHexStringToObject(
      this._rgbColorHexString
    )
  }

  _isValidRgbColorHexString(rgbColorHexString) {
    return (
      typeof rgbColorHexString === 'string' &&
      !(rgbColorHexString.match(/^#(?:[0-9a-f]{3}){1,2}$/i) === null)
    )
  }

  _isValidAlphaValue(alphaValue) {
    return (
      typeof alphaValue === 'number' &&
      !isNaN(alphaValue) &&
      alphaValue >= 0 &&
      alphaValue <= 1
    )
  }

  _rgbColorHexStringToObject(rgbColorHexString) {
    let hexRr, hexGg, hexBb
    if (rgbColorHexString.length === 4) {
      hexRr = rgbColorHexString[1].repeat(2)
      hexGg = rgbColorHexString[2].repeat(2)
      hexBb = rgbColorHexString[3].repeat(2)
    } else if (rgbColorHexString.length === 7) {
      hexRr = rgbColorHexString.slice(1, 3)
      hexGg = rgbColorHexString.slice(3, 5)
      hexBb = rgbColorHexString.slice(5, 7)
    }

    let r = parseInt(hexRr, 16)
    let g = parseInt(hexGg, 16)
    let b = parseInt(hexBb, 16)

    return { r, g, b }
  }

  makeCssRgbaColorString(alpha) {
    if (!this._isValidAlphaValue(alpha)) {
      throw new Error(
        `ColorHandler.makeCssRgbaColorString(): Invalid alpha ${alpha}.`
      )
    }
    return `rgba(${this.r},${this.g},${this.b},${alpha})`
  }

  toString() {
    return this._rgbColorHexString
  }

  get rgbColorHexString() {
    return this._rgbColorHexString
  }
  get rgbColorObject() {
    return this._rgbColorObject
  }
  get r() {
    return this._rgbColorObject.r
  }
  get g() {
    return this._rgbColorObject.g
  }
  get b() {
    return this._rgbColorObject.b
  }
}
