class AvailabilityViewRouteObjectGenerator {
  static get ROUTE_NAME() {
    return 'availability'
  }
  static make(customParams) {
    const overridableParams = {
      resourceId: '1',
    }
    return {
      name: AvailabilityViewRouteObjectGenerator.ROUTE_NAME,
      params: {
        ...overridableParams,
        ...customParams,
      },
    }
  }
}
export default AvailabilityViewRouteObjectGenerator
