import BaseProjectRootElement from '@/classes/ProjectStructure/BaseProjectRootElement'
import NavigationHistoryStepCheckerFactory from '@/classes/NavigationHistory/NavigationStepCheckers/NavigationHistoryStepCheckerFactory'
import NavigationHistory from '@/classes/NavigationHistory'

export default class NavigationHistoryManager {
  constructor() {
    this._projectRootElement = undefined
    this._router = undefined
    this._navigationHistory = new NavigationHistory()
  }

  get _isInitialized() {
    return this._navigationHistory !== undefined && this._router !== undefined
  }

  get lastRoute() {
    return this._navigationHistory.getLastNavigationStep()
  }

  _createNavigationChecker(to) {
    const checkerName = to.meta.historyNavigationStepChecker

    if (!checkerName) {
      throw new Error(
        'NavigationHistoryManager.checkNavigationHistoryStep: to route does not have a checker.'
      )
    }

    return new NavigationHistoryStepCheckerFactory().makeChecker(
      checkerName,
      this._projectRootElement
    )
  }

  handleHistoryStep(from, to) {
    if (!this._isInitialized) {
      throw new Error(
        'NavigationHistoryManager.handleHistoryStep(): NavigationHistoryManager is not initialized.'
      )
    }

    if (to.params.itsANavigationHistoryStepBack) {
      return
    }

    const stepCheckerForThisCase = this._createNavigationChecker(to)
    const isNavigationHistoryStepSaveable = stepCheckerForThisCase.checkNavigationHistoryStep(
      from,
      to
    )

    if (isNavigationHistoryStepSaveable) {
      this._navigationHistory.pushNavigationStep(from)
    }
  }

  stepBack() {
    if (!this._isInitialized) {
      throw new Error(
        'NavigationHistoryManager.stepBack(): NavigationHistoryManager is not initialized.'
      )
    }

    const stepBackRoute = this._navigationHistory.popNavigationStep()
    if (!stepBackRoute) {
      throw new Error(
        `NavigationHistoryManager.stepBack(): The navigation history is empty.`
      )
    }

    stepBackRoute.params.useTransition = false
    stepBackRoute.params.itsANavigationHistoryStepBack = true
    this._router.push(stepBackRoute)
  }

  init(router, projectRootElement) {
    if (this._isInitialized) {
      throw new Error(
        'NavigationHistoryManager.init(): NavigationHistoryManager is already initialized.'
      )
    }

    if (!(projectRootElement instanceof BaseProjectRootElement)) {
      throw new Error(
        'NavigationHistoryManager.init(): projectRootElement passed is not an instance of BaseProjectRootElement.'
      )
    }

    this._projectRootElement = projectRootElement
    this._router = router
  }
}
