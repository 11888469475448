import Tower from '@/classes/ProjectStructure/Tower'
import LevelRouteObjectGenerator from './LevelRouteObjectGenerator'

class TowerRouteObjectGenerator {
  static get ROUTE_NAME() {
    return LevelRouteObjectGenerator.ROUTE_NAME
  }
  static make(tower, customParams) {
    if (!(tower instanceof Tower)) {
      throw new Error(
        'TowerRouteObjectGenerator.make(): passed tower argument is not an instance of Tower class.'
      )
    }
    if (!tower.levels.length) {
      throw new Error('TowerRouteObjectGenerator.make(): Tower has not levels.')
    }
    return LevelRouteObjectGenerator.make(tower.topLevel, customParams)
  }
}
export default TowerRouteObjectGenerator
