<template>
  <p class="sheet-availability-text">
    {{ availabilityText }}
  </p>
</template>

<script>
export default {
  name: 'SheetAvailabilityText',
  props: {
    availabilityText: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.sheet-availability-text {
  font-size: 0.875rem;
  font-style: italic;
  text-align: center;
  color: var(--gray-500);
  margin: 0.5625rem 0;
}
</style>
