export default {
  contactPageFormUrl: (state) => state.contactPageFormUrl,
  unitEmbeddedContactFormButtonText: (state) =>
    state.unitContactFormData.buttonText,
  unitEmbeddedContactFormUrl: (state) => state.unitContactFormData.formUrl,
  isContactPageEmbeddedFormAvailable: (state) => !!state.contactPageFormUrl,
  isUnitContactEmbeddedFormAvailable: (state) =>
    !!state.unitContactFormData.buttonText &&
    !!state.unitContactFormData.formUrl,
}
