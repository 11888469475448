import UnitFeatureAdapter from './UnitFeatureAdapter'
import UnitLevelAdapter from './UnitLevelAdapter'
import UnitFactory from '@/classes/ProjectStructure/Unit/UnitFactory'

export default class UnitAdapter {
  static adapt(unitJsonData, i18n) {
    const {
      unitId,
      svgTargetElementId,
      unitSlug,
      unitLabel,
      displayName,
      typology,
      unitType,
      features,
      downloadableBrochureUrl,
      unitLevels,
      viewsGallery,
      use3dPlantAsUnitPreviewImg,
    } = unitJsonData

    const adaptedUnit = new UnitFactory().makeUnit(
      {
        id: `${unitId}`,
        name: displayName,
        slug: unitSlug,
        svgTargetElementId,
        label: unitLabel,
        model: typology,
        use3dPlantAsUnitPreviewImg: use3dPlantAsUnitPreviewImg,
      },
      unitType
    )

    this.adaptUnitFeatures(adaptedUnit, features, i18n)
    this.adaptUnitLevels(adaptedUnit, unitLevels, viewsGallery, i18n)
    this.adaptUnitBrochureUrl(adaptedUnit, downloadableBrochureUrl)

    return adaptedUnit
  }
  static adaptUnitFeatures(unit, unitFeaturesJsonData, i18n) {
    unitFeaturesJsonData.forEach((featureJsonData) => {
      UnitFeatureAdapter.adapt(unit, featureJsonData, i18n)
    })
  }
  static adaptUnitLevels(unit, unitLevelsJsonData, viewsGalleryJsonData, i18n) {
    if (unitLevelsJsonData.length > 1) {
      const unitLevelsDisplayNamesIsValid = (unitLevelJsonData) =>
        typeof unitLevelJsonData.displayName === 'string' &&
        unitLevelJsonData.displayName.trim().length
      if (!unitLevelsJsonData.every(unitLevelsDisplayNamesIsValid)) {
        throw new Error(
          `UnitAdapter.adaptUnitLevels(): unitLevelsJsonData contains unit levels that are not defining valid displayNames: ${{
            unitLevelsJsonData,
          }}`
        )
      }
    }

    unitLevelsJsonData.forEach((unitLevelJsonData) => {
      UnitLevelAdapter.adapt(
        unit,
        unitLevelJsonData,
        viewsGalleryJsonData,
        i18n
      )
    })
  }
  static adaptUnitBrochureUrl(unit, downloadableBrochureUrl) {
    if (downloadableBrochureUrl) {
      unit.setBrochureUrl(downloadableBrochureUrl)
    }
  }
}
