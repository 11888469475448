import PriceFactory from '@/classes/Price/PriceFactory'
import PriceFormatter from '@/i18n/PriceFormatter'
import { OfferTypes } from '@/constants/units'

export default class SvgPriceTag {
  constructor({ unit, svgElement, backgroundElement, currentLocale }) {
    this._unit = unit
    this._svgElement = svgElement
    this._backgroundElement = backgroundElement
    this._textElement = null

    this._priceFormatter = new PriceFormatter(currentLocale)

    this._hide()
  }

  _setTextElementPositionAttributes(backgroundElement, textElement) {
    const { width, height, x, y } = backgroundElement.getBBox()

    const positionX = x + width / 2
    const positionY = y + height / 2

    textElement.setAttribute('x', positionX)
    textElement.setAttribute('y', positionY)
    textElement.setAttribute('dominant-baseline', 'middle')
    textElement.setAttribute('text-anchor', 'middle')
  }
  _fixTextElementFontSize(backgroundElement, textElement) {
    const {
      width: backgroundWidth,
      height: backgroundHeight,
    } = backgroundElement.getBBox()

    const { width: textWidth, height: textHeight } = textElement.getBBox()

    if (backgroundWidth < textWidth || backgroundHeight < textHeight) {
      const scaleX = backgroundWidth / textWidth
      const scaleY = backgroundHeight / textHeight

      const scale = Math.min(scaleX, scaleY)

      const currentFontSize = parseFloat(
        window.getComputedStyle(textElement).fontSize
      )

      const newFontSize = currentFontSize * scale

      textElement.setAttribute('font-size', newFontSize)
    }
  }
  _showElements() {
    this._backgroundElement.style.opacity = 1
    if (this._textElement) {
      this._textElement.style.opacity = 1
    }
  }
  _resetTextElement() {
    if (this._textElement) {
      this._textElement.remove()
    }
  }
  _displayPriceOnSvg(priceText) {
    const textElement = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'text'
    )

    textElement.textContent = priceText
    this._setTextElementPositionAttributes(this._backgroundElement, textElement)
    this._svgElement.appendChild(textElement)
    this._fixTextElementFontSize(this._backgroundElement, textElement)
    this._showElements()

    this._textElement = textElement
  }

  _hide() {
    this._backgroundElement.style.opacity = 0
    if (this._textElement) {
      this._textElement.style.opacity = 0
    }
  }

  _calculatePriceOfPaymentMethod(paymentMethodId) {
    const priceForPaymentMethod = this._unit.getQuotePrice(paymentMethodId)

    if (priceForPaymentMethod) {
      const price = new PriceFactory().makePrice(
        {
          amount: priceForPaymentMethod.amount,
          currencyCode: priceForPaymentMethod.currencyCode,
        },
        OfferTypes.FOR_SALE
      )

      return this._priceFormatter.format(price)
    } else {
      return undefined
    }
  }

  _calculateGenericUnitPrice() {
    const unitPrice = this._unit.price

    if (unitPrice) {
      const price = new PriceFactory().makePrice(
        {
          amount: unitPrice.amount,
          currencyCode: unitPrice.currencyCode,
        },
        OfferTypes.FOR_SALE
      )

      return this._priceFormatter.format(price)
    } else {
      return undefined
    }
  }

  checkVisibilityByPaymentMethod(paymentMethodId) {
    const paymentMethodPrice = this._calculatePriceOfPaymentMethod(
      paymentMethodId
    )
    const isUnitAvailable = this._unit.available

    if (isUnitAvailable && paymentMethodPrice) {
      this._resetTextElement()
      this._displayPriceOnSvg(paymentMethodPrice)
    } else {
      this._hide()
    }
  }
  checkVisibilityByUnitPrice() {
    const unitPrice = this._calculateGenericUnitPrice()
    const isUnitAvailable = this._unit.available

    if (isUnitAvailable && unitPrice) {
      this._resetTextElement()
      this._displayPriceOnSvg(unitPrice)
    } else {
      this._hide()
    }
  }
}
