<template>
  <div class="top-bar__label" :class="[`top-bar__label--align-${align}`]">
    <span class="top-bar__label__text">
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'TopBarLabel',
  props: {
    align: {
      type: String,
      default: 'center',
      validator: (value) => {
        return ['left', 'center', 'right'].indexOf(value) !== -1
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.top-bar__label {
  @at-root #{&}--align-left {
    text-align: left;
  }

  @at-root #{&}--align-center {
    text-align: center;
  }

  @at-root #{&}--align-right {
    text-align: right;
  }

  @at-root #{&}__text {
    position: relative;
    display: inline-block;
    font-size: 0.75rem;
    color: #fff;
    padding: 4px 18px;
    line-height: 1.172;
    border-radius: 2px;
    white-space: nowrap;
    overflow: hidden;

    &::before {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
      z-index: -1;
      background: var(--gray-700);
      opacity: 0.7;
    }
  }
}
</style>
