<template>
  <div
    class="feature-list-item"
    :class="{
      'feature-list-item--small': small && !plain,
      'feature-list-item--plain': plain,
    }"
  >
    <AppIcon
      v-if="!plain"
      class="feature-list-item__icon"
      :icon="icon"
      :size="iconSize"
    />
    <TextWrapper class="feature-list-item__text" :text="text" />
  </div>
</template>

<script>
import AppIcon from '@/components/UIKit/Standard/Atoms/AppIcon'
import TextWrapper from '@/components/TextWrapper'
import sizes from '@/constants/ui-kit/standard/sizes'
import iconNames from '@/constants/ui-kit/standard/icon-names'

export default {
  name: 'AppFeatureListItem',
  components: {
    AppIcon,
    TextWrapper,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
      validator: (value) => Object.values(iconNames).includes(value),
    },
    plain: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconSize: sizes.SM,
    }
  },
}
</script>

<style lang="scss" scoped>
.feature-list-item {
  $text: #{&}__text;
  $icon: #{&}__icon;

  display: flex;
  align-items: center;
  padding: 0.8125rem 0;
  gap: 20px;

  @at-root #{$text} {
    font-size: 0.875rem;
    line-height: 1.125;
  }

  @at-root #{$icon} {
    display: block;
    stroke: var(--primary-color);
    fill: var(--primary-color);
  }

  @at-root &--small {
    padding: 0;
    gap: 10px;
  }

  @at-root &--plain {
    padding: 0;

    #{$text} {
      color: var(--gray-500);
      &::before {
        content: '• ';
      }
    }
  }
}
</style>
