<template>
  <AppBaseModal :title="title" :modal-width="455" v-on="$listeners">
    <ContactForm
      :external-subject="externalSubject"
      :target-api-endpoint-name="targetApiEndpointName"
      modal-mode
      @submit="handleSubmit"
    />
  </AppBaseModal>
</template>
<script>
import ContactForm from '@/components/Form/ContactForm'
import AppBaseModal from '@/components/UIKit/Standard/Atoms/AppBaseModal'
import modalSupportedComponents from '@/constants/ui-kit/standard/modal/modal-supported-components'
import * as contactApiEndpointNames from '@/constants/api/contact-api-endpoint-names'

export default {
  name: 'AppLocationInfoFormModal',
  components: {
    ContactForm,
    AppBaseModal,
  },
  data() {
    return {
      targetApiEndpointName: contactApiEndpointNames.GENERAL,
    }
  },
  computed: {
    title() {
      return this.$t('Solicita información')
    },
    externalSubject() {
      return this.$t('Consulta desde página de Ubicación')
    },
  },
  methods: {
    handleSubmit() {
      this.$store.dispatch('ModalLauncher/launchModal', {
        component: modalSupportedComponents.AppConfirmationMessageModal,
      })
    },
  },
}
</script>
