class DateFormatter {
  constructor(locale) {
    if (typeof locale !== 'string' || locale.trim() === '') {
      throw new Error(`DateFormatter.format(): invalid locale ${locale}`)
    }
    this._locale = locale
  }
  get locale() {
    return this._locale
  }
  format(date, customOptions) {
    if (!(date instanceof Date) || isNaN(date)) {
      throw new Error(
        `DateFormatter.format(): date ${date} is not an instance of class Date`
      )
    }
    const defaultOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }
    return date.toLocaleDateString(this._locale, {
      ...defaultOptions,
      ...customOptions,
    })
  }
}
export default DateFormatter
