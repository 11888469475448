import Unit from './Unit'
import { UnitTypes, BrochureFileNameLocaleKeys } from '@/constants/units'
import ProjectType from '@/classes/ProjectType'
export default class ShopUnit extends Unit {
  get brochureFileNameLocaleKey() {
    const projectType = new ProjectType()
    return projectType.switcher({
      [projectType.SINGLETOWER_TYPE]:
        BrochureFileNameLocaleKeys.SINGLETOWER_SHOP,
      [projectType.MULTITOWER_TYPE]: BrochureFileNameLocaleKeys.MULTITOWER_SHOP,
      [projectType.NEIGHBOURHOOD_TYPE]:
        BrochureFileNameLocaleKeys.NEIGHBOURHOOD_SHOP,
    })
  }
  get type() {
    return UnitTypes.SHOP
  }
}
