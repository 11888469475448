import Tower from '@/classes/ProjectStructure/Tower'

export default class TowerAdapter {
  // eslint-disable-next-line no-unused-vars
  static adapt(towerJsonData, i18n) {
    const {
      towerId,
      order,
      svgTargetElementId,
      towerSlug,
      towerLogo,
      displayName,
      enterTransitionVideos,
      exteriorTour360,
    } = towerJsonData

    const adaptedTower = new Tower({
      id: `${towerId}`,
      order,
      svgTargetElementId,
      name: displayName,
      slug: towerSlug,
      logo: towerLogo,
      exteriorTour360Url: exteriorTour360?.tour360Url,
    })

    enterTransitionVideos.forEach((enterTransitionVideo) => {
      adaptedTower.enterTransitionVideos.add(
        enterTransitionVideo.stopPointSlug,
        enterTransitionVideo.videoUrl
      )
    })

    return adaptedTower
  }
}
