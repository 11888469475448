<template>
  <div
    class="app-menu-item"
    :class="{ 'app-menu-item--highlighted': highlighted }"
  >
    <AppIcon
      v-if="icon"
      class="app-menu-item__icon"
      :size="iconSize"
      :icon="icon"
    />
    <div class="app-menu-item__text">
      {{ text }}
      <div
        v-if="withConnectionWarning"
        class="app-menu-item__text__connection-warning"
      >
        {{ $t('Acceso limitado sin internet') }}
      </div>
    </div>
    <div v-if="withSubmenu" class="app-menu-item__submenu-indicator">
      <AppIcon
        class="app-menu-item__submenu-indicator__icon"
        :size="iconSize"
        :icon="submenuIcon"
      />
    </div>
  </div>
</template>
<script>
import AppIcon from '@/components/UIKit/Standard/Atoms/AppIcon'
import iconNames from '@/constants/ui-kit/standard/icon-names'
import sizes from '@/constants/ui-kit/standard/sizes'

export default {
  name: 'AppMenuItem',
  components: {
    AppIcon,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: undefined,
      validator: (value) => Object.values(iconNames).includes(value),
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
    withSubmenu: {
      type: Boolean,
      default: false,
    },
    withConnectionWarning: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconSize: sizes.SM,
      submenuIcon: iconNames.UIChevronRight,
    }
  },
}
</script>
<style lang="scss" scoped>
.app-menu-item {
  width: 100%;
  gap: 1.5625rem;
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 0.5px solid var(--gray-100);
  cursor: pointer;

  $menu-item: &;

  @at-root #{$menu-item}__icon {
    stroke: var(--primary-color);
    fill: var(--primary-color);
  }
  @at-root #{$menu-item}__text {
    flex: 1;
    font-size: 1rem;
    font-weight: var(--font-weight-medium);
    margin: 0;
    color: var(--gray-700);
    cursor: pointer;

    @at-root &__connection-warning {
      font-size: 0.75rem;
      font-weight: var(--font-weight-regular);
    }
  }
  @at-root #{$menu-item}__submenu-indicator {
    @at-root #{&}__icon {
      stroke: var(--gray-700);
      fill: var(--gray-700);
    }
  }
  @at-root #{$menu-item}--highlighted,
    #{$menu-item}:hover {
    border-bottom: 0.5px solid var(--primary-color);
    #{$menu-item}__text {
      color: var(--primary-color);
    }
    #{$menu-item}__submenu-indicator {
      &__icon {
        stroke: var(--primary-color);
        fill: var(--primary-color);
      }
    }
  }
}
</style>
