import { UnitTypes } from '@/constants/units'

class BaseUnitIdentifierGenerator {
  constructor(i18n) {
    this._validateI18nIsValid(i18n)
    this._i18n = i18n
  }
  makeLongIdentifier({ unit }) {
    this._validateGeneratorArguments({ unit })
    const translationKey = this._getLongIdentifierLocaleKey(unit.type)
    const translationArguments = this._getTranslationArguments({ unit })
    return this._i18n.t(translationKey, translationArguments)
  }
  makeShortIdentifier({ unit }) {
    this._validateGeneratorArguments({ unit })
    const translationKey = this._getShortIdentifierLocaleKey(unit.type)
    const translationArguments = this._getTranslationArguments({ unit })
    return this._i18n.t(translationKey, translationArguments)
  }
  _getLongIdentifierLocaleKey(unitType) {
    const localeKey = this._longIdentifierLocaleKeys[unitType]
    if (localeKey === undefined) {
      throw new Error(
        `${this._className}: there's no long identifier locale key for unitType ${unitType}.`
      )
    }
    return localeKey
  }
  _getShortIdentifierLocaleKey(unitType) {
    const localeKey = this._shortIdentifierLocaleKeys[unitType]
    if (localeKey === undefined) {
      throw new Error(
        `${this._className}: there's no short identifier locale key for unitType ${unitType}.`
      )
    }
    return localeKey
  }
  // eslint-disable-next-line no-unused-vars
  _getTranslationArguments({ unit }) {
    throw new Error(
      '_getTranslationArguments is not implemented in base class BaseUnitIdentifierGenerator.'
    )
  }
  // eslint-disable-next-line no-unused-vars
  _validateGeneratorArguments({ unit }) {
    throw new Error(
      '_validateGeneratorArguments is not implemented in base class BaseUnitIdentifierGenerator.'
    )
  }
  _validateI18nIsValid(i18n) {
    if (
      typeof i18n !== 'object' ||
      typeof i18n.t !== 'function' ||
      typeof i18n.te !== 'function'
    ) {
      throw new Error(
        `${this._className}: i18n passed is not an object or it does not have t functions`
      )
    }
  }
  get _shortIdentifierLocaleKeys() {
    return {
      [UnitTypes.APARTMENT]: `${this._localeGroupKey}.apartmentShortIdentifier`,
      [UnitTypes.AMENITIES]: `${this._localeGroupKey}.amenitiesShortIdentifier`,
      [UnitTypes.OFFICE]: `${this._localeGroupKey}.officeShortIdentifier`,
      [UnitTypes.SHOP]: `${this._localeGroupKey}.shopShortIdentifier`,
      [UnitTypes.HOUSE]: `${this._localeGroupKey}.houseShortIdentifier`,
    }
  }
  get _longIdentifierLocaleKeys() {
    return {
      [UnitTypes.APARTMENT]: `${this._localeGroupKey}.apartmentLongIdentifier`,
      [UnitTypes.AMENITIES]: `${this._localeGroupKey}.amenitiesLongIdentifier`,
      [UnitTypes.OFFICE]: `${this._localeGroupKey}.officeLongIdentifier`,
      [UnitTypes.SHOP]: `${this._localeGroupKey}.shopLongIdentifier`,
      [UnitTypes.HOUSE]: `${this._localeGroupKey}.houseLongIdentifier`,
    }
  }
  get _localeGroupKey() {
    throw new Error(
      '_localeGroupKey is not implemented in base class BaseUnitIdentifierGenerator.'
    )
  }
  get _className() {
    return this.constructor.name
  }
}
export default BaseUnitIdentifierGenerator
