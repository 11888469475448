<template>
  <div class="menu-logo">
    <AppImage class="menu-logo__image" :src="logoImageUrl" contain />
  </div>
</template>

<script>
import AppImage from '@/components/UIKit/Standard/Atoms/AppImage'

export default {
  name: 'MenuLogo',
  components: {
    AppImage,
  },
  props: {
    logoImageUrl: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.menu-logo {
  padding: 0;
  @at-root &__image {
    width: 100%;
    height: 100px;
  }
}
</style>
