import UnitAdapter from '@/classes/ProjectElementAdapters/UnitAdapter'
import UnitIdentifierGeneratorFactory from '@/classes/UnitIdentifierGenerators/UnitIdentifierGeneratorFactory'
import Unit from '@/classes/ProjectStructure/Unit/Unit'
import BaseClusterGroupAdapter from '@/classes/ProjectElementAdapters/BaseClusterGroupAdapter'

export default class UnitsClusterGroupAdapter extends BaseClusterGroupAdapter {
  static get ElementAdapter() {
    return UnitAdapter
  }
  // eslint-disable-next-line no-unused-vars
  static afterAdaptElement(unitElement, i18n) {
    if (!(unitElement instanceof Unit)) {
      throw new Error(
        'UnitsClusterGroupAdapter.afterAdaptElement(): passed unitElement argument is not an instance of Unit class.'
      )
    }
    const unitIdentifierGenerator = UnitIdentifierGeneratorFactory.make(i18n)
    unitElement.setLongIdentifier(
      unitIdentifierGenerator.makeLongIdentifier({ unit: unitElement })
    )
    unitElement.setShortIdentifier(
      unitIdentifierGenerator.makeShortIdentifier({ unit: unitElement })
    )
  }
}
