<template>
  <section class="container">
    <h1>AppButtonFlapViewer</h1>
    <h2>With Badge</h2>
    <div class="wrapper">
      <AppButtonFlap :icon="demoIcon" with-badge />
    </div>

    <h2>Without Badge</h2>
    <div class="wrapper">
      <AppButtonFlap :icon="demoIcon" />
    </div>
  </section>
</template>
<script>
import AppButtonFlap from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonFlap'
import iconNames from '@/constants/ui-kit/standard/icon-names'

export default {
  name: 'FlapViewer',
  components: {
    AppButtonFlap,
  },
  data() {
    return {
      demoIcon: iconNames.UIMenu,
    }
  },
}
</script>
<style lang="scss" scoped>
.container {
  margin: 80px 50px;
  .wrapper {
    margin: 50px;
  }
}
h1 {
  font-size: 1.5rem;
  margin: 2.5em 0 1.5em;
  border-bottom: 1px solid black;
}
</style>
