<template>
  <div
    class="component-wrapper"
    :class="{
      'component-wrapper--resizable': resizable,
      'component-wrapper--bordered': bordered,
    }"
    :style="style"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'VComponentWrapper',
  props: {
    width: {
      type: Number,
      default: () => undefined,
    },
    height: {
      type: Number,
      default: () => undefined,
    },
    resizable: {
      type: Boolean,
      default: false,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    style() {
      const width = this.width ? `${this.width}px` : undefined
      const height = this.height ? `${this.height}px` : undefined

      let resize = 'none'
      if (this.resizable) {
        if (width !== undefined && height !== undefined) {
          resize = 'both'
        } else if (width !== undefined) {
          resize = 'horizontal'
        } else if (height !== undefined) {
          resize = 'vertical'
        }
      }

      return {
        width,
        height,
        resize,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.component-wrapper {
  @at-root &--resizable {
    overflow: auto;
  }
  @at-root &--bordered {
    border: 1px solid var(--gray-300);
  }
}
</style>
