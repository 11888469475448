<template>
  <ul class="menu-item-list">
    <li v-for="item in items" :key="item.nameId" class="menu-item-list__item">
      <a
        v-if="isDownloableMenuItem(item)"
        class="menu-item-list__item__link"
        :href="item.downloadUrl"
        :download="getDownloableItemFileName(item)"
      >
        <AppMenuItem :icon="item.icon" :text="getItemText(item)" />
      </a>
      <router-link
        v-else-if="!item.children"
        v-slot="{ href, isActive, isExactActive }"
        :to="item.routeObject"
      >
        <a class="menu-item-list__item__link" :href="href">
          <AppMenuItem
            :icon="item.icon"
            :text="getItemText(item)"
            :highlighted="isHighlighted(item, isActive, isExactActive)"
            :with-connection-warning="isConnectionWarningRequired(item)"
          />
        </a>
      </router-link>
      <a
        v-else
        class="menu-item-list__item__link"
        href="#"
        @click.prevent="$emit('request-menu', item)"
      >
        <AppMenuItem
          :icon="item.icon"
          :text="getItemText(item)"
          :highlighted="isHighlighted(item)"
          :with-connection-warning="isConnectionWarningRequired(item)"
          with-submenu
        />
      </a>
    </li>
  </ul>
</template>
<script>
import AppMenuItem from '@/components/UIKit/Standard/Atoms/AppMenuItem.vue'
import BaseMenuItem from '@/classes/Navigation/MenuItem/BaseMenuItem'
import Menu from '@/classes/Navigation/Menu'
import DownloableMenuItem from '@/classes/Navigation/MenuItem/DownloableMenuItem'

export default {
  name: 'AppMenuItemsList',
  components: {
    AppMenuItem,
  },
  props: {
    items: {
      type: Array,
      required: true,
      validator: (items) => {
        return items.every(
          (item) => item instanceof BaseMenuItem || item instanceof Menu
        )
      },
    },
    limitedConnection: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isDownloableMenuItem(item) {
      return item instanceof DownloableMenuItem
    },
    isHighlighted(item, isActive, isExactActive) {
      return (
        isExactActive || (isActive && !item.exact) || item.match(this.$route)
      )
    },
    isConnectionWarningRequired(item) {
      return (
        this.limitedConnection && item.showHintDependingOnInternetConnection
      )
    },
    getItemText(item) {
      return item.nameTranslationKey
        ? this.$t(item.nameTranslationKey)
        : item.name
    },
    getDownloableItemFileName(item) {
      return this.$t(item.fileNameTranslationKey, item.downloadFileParams)
    },
  },
}
</script>

<style lang="scss" scoped>
.menu-item-list {
  list-style: none;
  padding: 0;
  margin: 0;

  @at-root &__item {
    @at-root &__link {
      text-decoration: none;
    }
  }
}
</style>
