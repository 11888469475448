import ProjectElementTypes from '@/constants/project-structure/project-element-types'
import NeighbourhoodSubdivisionRouteObjectGenerator from './NeighbourhoodSubdivisionRouteObjectGenerator'
import UnitRouteObjectGenerator from './UnitRouteObjectGenerator'
import LevelRouteObjectGenerator from './LevelRouteObjectGenerator'
import TowerRouteObjectGenerator from './TowerRouteObjectGenerator'

export default class RouteObjectGeneratorFactory {
  static make(projectElementType) {
    const routeObjectGenerators = {
      [ProjectElementTypes.NEIGHBOURHOOD_SUBDIVISION]: NeighbourhoodSubdivisionRouteObjectGenerator,
      [ProjectElementTypes.UNIT]: UnitRouteObjectGenerator,
      [ProjectElementTypes.LEVEL]: LevelRouteObjectGenerator,
      [ProjectElementTypes.TOWER]: TowerRouteObjectGenerator,
    }

    const RouteObjectGenerator = routeObjectGenerators[projectElementType]
    if (!RouteObjectGenerator) {
      throw new Error(
        `RouteObjectGeneratorFactory: passed projectElementType ${projectElementType} is not mapped.`
      )
    }
    return RouteObjectGenerator
  }
}
