import LoggingServiceFactory from '@/classes/LoggingService/LoggingServiceFactory'

const loggingService = {}

loggingService.install = function(Vue) {
  const LoggingServiceFactoryInstance = new LoggingServiceFactory()

  Vue.prototype.$loggingService = LoggingServiceFactoryInstance.makeService()
}

export default loggingService
