import VueDependencyError from '@/classes/Exceptions/VueDependencyError'

class VueDependenciesRegistry {
  constructor(Vue, router) {
    this._Vue = Vue
    this._router = router
    this._vueGlobals = {}
  }

  _registerVueGlobal(vueGlobalKeyValue) {
    const { key, value } = vueGlobalKeyValue
    if (typeof key !== 'string' || key === 'undefined') {
      throw new Error(`Invalid VueGlobal key ${key}`)
    }
    if (this._vueGlobals[key] === undefined) {
      this._vueGlobals[key] = value
    } else {
      throw new Error(`Key ${key} already registered in _vueGlobals`)
    }
  }
  async register(dependency) {
    try {
      await dependency.install(this.Vue, this.router)
      if (dependency.vueGlobalKeyValue !== null) {
        this._registerVueGlobal(dependency.vueGlobalKeyValue)
      }
    } catch (error) {
      throw new VueDependencyError(
        'Error while registering VueDependency',
        dependency.dependencyName,
        error
      )
    }
  }
  get vueGlobals() {
    return { ...this._vueGlobals }
  }
  get Vue() {
    return this._Vue
  }
  get router() {
    return this._router
  }
}
export default VueDependenciesRegistry
