import transitionApiModes from '@/constants/transition-manager/transition-api-modes'
import Transition from './Transition'
import AnimationTransitionParams from '@/classes/TransitionTree/AnimationTransitionParams'

export default class AnimationTransition extends Transition {
  constructor(transitionParams) {
    super(transitionParams)
    if (!(this._transitionParams instanceof AnimationTransitionParams)) {
      throw new Error(
        `AnimationTransition: Passed transitionParams is not an instance of class AnimationTransitionParams`
      )
    }
    const modeByNameMap = {
      fade: transitionApiModes.IN_OUT,
      'slide-left-short': transitionApiModes.DEFAULT,
    }
    this._transitionComponentProps.name = this._transitionParams.effect
    this._transitionComponentProps.mode =
      modeByNameMap[this._transitionComponentProps.name]
    this._transitionComponentProps.css = true
  }
  enter() {
    this._isInTransitionPerformed = true
  }
  leave() {
    this._isOutTransitionPerformed = true
  }
}
