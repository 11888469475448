export default {
  adapterData({ commit }, { projectData }) {
    try {
      const embeddedFormsData = projectData.project.embeddedForms

      if (embeddedFormsData) {
        commit('storeContactPageFormUrl', embeddedFormsData.contactPageFormUrl)
        commit(
          'storeUnitContactFormData',
          embeddedFormsData.unitContactFormData
        )
      }
    } catch (err) {
      throw new Error(`In EmbeddedFormsModule/adaptedData: ${err.toString()}`)
    }
  },
}
