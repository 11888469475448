import * as Sentry from '@sentry/vue'
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'
import JsonResourcesProvider from '@/init/JsonResourcesProvider'
import initVue from '@/init/initVue'
import LoggingServiceFactory from '@/classes/LoggingService/LoggingServiceFactory'

new JsonResourcesProvider()
  .getProjectAndAvailabilityData()
  .then((jsonsData) => {
    initVue(jsonsData)
  })
  .catch((error) => {
    if (process.env.VUE_APP_SENTRY_DSN !== '') {
      Sentry.init({
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [
          new CaptureConsoleIntegration({
            levels: ['error'],
          }),
        ],
        tracesSampleRate: Number.parseFloat(
          process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE
        ),
      })
    }

    const loggingService = new LoggingServiceFactory().makeService()
    loggingService.logError(
      `Fatal error while initializing Vue: ${error.toString()}`
    )
  })
