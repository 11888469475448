<template>
  <AppSheetWrapper class="popup-content" is-popup>
    <AppSheetRow>
      <AppSheetTitle
        :title="title"
        :subtitle="subtitle"
        :align="textAlignment"
      />
    </AppSheetRow>
    <AppSheetRow v-if="shouldDisplayButtonsBlock">
      <AppSheetBlockButtonGroup>
        <AppButtonTextRouterLink
          v-if="exteriorTour360To"
          class="popup-content__access-buttons__button"
          :text="$t('Tour exterior')"
          :size="buttonSize"
          :button-style="buttonExteriorTour360Style"
          :to="exteriorTour360To"
        />
        <AppButtonTextRouterLink
          v-if="enterTo"
          class="popup-content__access-buttons__button"
          :text="$t('Ingresar')"
          :size="buttonSize"
          :button-style="buttonEnterStyle"
          :to="enterTo"
        />
      </AppSheetBlockButtonGroup>
    </AppSheetRow>
  </AppSheetWrapper>
</template>

<script>
import AppSheetWrapper from '@/components/UIKit/Standard/Atoms/AppSheetWrapper'
import AppSheetRow from '@/components/UIKit/Standard/Atoms/AppSheetRow'
import AppSheetBlockButtonGroup from '@/components/UIKit/Standard/Molecules/AppSheetBlockButtonGroup'
import AppSheetTitle from '@/components/UIKit/Standard/Atoms/AppSheetTitle'
import AppButtonTextRouterLink from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonTextRouterLink'
import alignments from '@/constants/ui-kit/standard/alignments'
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'

export default {
  name: 'AppSheetProjectElement',
  components: {
    AppSheetWrapper,
    AppSheetRow,
    AppSheetBlockButtonGroup,
    AppSheetTitle,
    AppButtonTextRouterLink,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: () => undefined,
    },
    enterTo: {
      type: Object,
      default: () => undefined,
    },
    exteriorTour360To: {
      type: Object,
      default: () => undefined,
    },
    textAlignment: {
      type: String,
      default: alignments.ALIGN_CENTER,
    },
  },
  data() {
    return {
      buttonSize: sizes.XS,
      buttonEnterStyle: styles.PRIMARY,
    }
  },
  computed: {
    activeButtonsCount() {
      const truthyButtonConditions = [
        this.exteriorTour360To,
        this.enterTo,
      ].filter((value) => value)
      return truthyButtonConditions.length
    },
    shouldDisplayButtonsBlock() {
      return this.activeButtonsCount > 0
    },
    hasASingleButton() {
      return this.activeButtonsCount === 1
    },
    buttonExteriorTour360Style() {
      return this.hasASingleButton ? styles.PRIMARY : styles.LIGHT
    },
  },
}
</script>
