<template>
  <section class="container">
    <h1>AppFlapDropdownViewer (Bottom)</h1>
    <div class="wrapper">
      <AppFlapDropdown
        :options="options"
        :selected-value="selectedOption"
        @selectedOptionChange="changeSelectedOption"
      />
    </div>

    <h1>AppFlapDropdownViewer (Top)</h1>
    <div class="wrapper">
      <AppFlapDropdown
        :options="options"
        :list-position="topPosition"
        :selected-value="selectedOption"
        @selectedOptionChange="changeSelectedOption"
      />
    </div>
  </section>
</template>
<script>
import AppFlapDropdown from '@/components/UIKit/Standard/Molecules/AppFlapDropdown'
import verticalPositions from '@/constants/ui-kit/standard/vertical-positions'

export default {
  name: 'FlapDropdownViewer',
  components: {
    AppFlapDropdown,
  },
  data() {
    return {
      options: [
        { text: 'option 1', value: '1' },
        { text: 'option 2', value: '2' },
        { text: 'option 3', value: '3' },
        { text: 'option 4', value: '4' },
        { text: 'option disabled', value: '5', disabled: true },
      ],
      selectedOption: '1',
      topPosition: verticalPositions.TOP_POSITION,
    }
  },
  methods: {
    changeSelectedOption(newValue) {
      this.selectedOption = newValue
    },
  },
}
</script>
<style lang="scss" scoped>
.container {
  margin: 80px 50px;
  .wrapper {
    margin: 50px;
  }
}
h1 {
  font-size: 1.5rem;
  margin: 2.5em 0 1.5em;
  border-bottom: 1px solid black;
}
th {
  font-weight: 400;
  font-size: 0.75rem;
}
th,
td {
  padding: 5px 10px;
}
</style>
