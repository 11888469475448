import LevelsClusterGroupAdapter from './LevelsClusterGroupAdapter'
import TowersClusterGroupAdapter from './TowersClusterGroupAdapter'
import UnitsClusterGroupAdapter from './UnitsClusterGroupAdapter'
import PointsOfInterestClusterGroupAdapter from './PointsOfInterestClusterGroupAdapter'
import NeighbourhoodSubdivisionClusterGroupAdapter from './NeighbourhoodSubdivisionClusterGroupAdapter'
import ProjectClusterGroupTypes from '@/constants/project-structure/project-cluster-group-types'

export default class ClusterGroupAdapterFactory {
  constructor() {
    this.availableClusterGroupTypes = {
      [ProjectClusterGroupTypes.NEIGHBOURHOOD_SUBDIVISIONS]: NeighbourhoodSubdivisionClusterGroupAdapter,
      [ProjectClusterGroupTypes.TOWERS]: TowersClusterGroupAdapter,
      [ProjectClusterGroupTypes.LEVELS]: LevelsClusterGroupAdapter,
      [ProjectClusterGroupTypes.UNITS]: UnitsClusterGroupAdapter,
      [ProjectClusterGroupTypes.POINTS_OF_INTEREST]: PointsOfInterestClusterGroupAdapter,
    }
  }
  makeClusterGroupAdapter(clusterGroupType) {
    const ClusterGroupAdapterToUse = this.availableClusterGroupTypes[
      clusterGroupType
    ]
    if (!ClusterGroupAdapterToUse) {
      throw new Error(
        `ClusterGroupAdapterFactory: Invalid clusterGroupType ${clusterGroupType}`
      )
    }
    return ClusterGroupAdapterToUse
  }
}
