export default {
  setImageZoom({ commit }, newZoomValue) {
    commit('updateImageZoom', newZoomValue)
  },
  setImagePosition({ commit }, { positionX, positionY }) {
    commit('updateImagePosition', {
      positionX,
      positionY,
    })
  },
  setGestureDragActiveStatus({ commit }, isGestureDragActiveValue) {
    commit('updateGestureDragActive', isGestureDragActiveValue)
  },
  setGesturePinchOrSpreadActiveStatus(
    { commit },
    isGesturePinchOrSpreadActiveValue
  ) {
    commit(
      'updateGesturePinchOrSpreadActive',
      isGesturePinchOrSpreadActiveValue
    )
  },
  zoomInStep({ dispatch, getters }) {
    if (getters.nextZoomDiscreteStep) {
      dispatch('setImageZoom', getters.nextZoomDiscreteStep)
    }
  },
  zoomOutStep({ dispatch, getters }) {
    if (getters.previousZoomDiscreteStep) {
      dispatch('setImageZoom', getters.previousZoomDiscreteStep)
    }
  },
}
