import { render, staticRenderFns } from "./AppCard.vue?vue&type=template&id=1bfde856&scoped=true"
var script = {}
import style0 from "./AppCard.vue?vue&type=style&index=0&id=1bfde856&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bfde856",
  null
  
)

export default component.exports