import Preferences from '@/classes/Preferences'
import defaultPreferences from '@/constants/preferences/default-preferences'
import offlineModeDefaultPreferences from '@/constants/preferences/offline-mode-default-preferences'
import offlineModeDisabledPreferences from '@/constants/preferences/offline-mode-disabled-preferences'

export default {
  async adapterData({ dispatch }, { projectData, isOfflineModeEnabled }) {
    try {
      if (!projectData.project.preferences) {
        this.$loggingService.logError(
          'Preferences/adapterData: preferences is missing in project JSON.'
        )
        dispatch('adaptPreferencesWithDefaultValuesOnly', {
          defaultPreferences: isOfflineModeEnabled
            ? offlineModeDefaultPreferences
            : defaultPreferences,
        })
      } else if (isOfflineModeEnabled) {
        dispatch('adaptPreferencesInOfflineMode', {
          projectDataPreferences: projectData.project.preferences,
        })
      } else {
        dispatch('adaptPreferences', {
          projectDataPreferences: projectData.project.preferences,
        })
      }
    } catch (error) {
      this.$loggingService.logError(
        `Preferences/adapterData: There was a problem trying to set preferences. ${error}`
      )
    }
  },
  adaptPreferencesWithDefaultValuesOnly({ commit }, { defaultPreferences }) {
    const preferences = new Preferences({
      defaultPreferences,
    })
    commit('storePreferences', preferences)
  },
  adaptPreferences({ commit }, { projectDataPreferences }) {
    const preferences = new Preferences({
      defaultPreferences,
    })
    preferences.setPreferences(projectDataPreferences)
    commit('storePreferences', preferences)
  },
  adaptPreferencesInOfflineMode({ commit }, { projectDataPreferences }) {
    const preferences = new Preferences({
      defaultPreferences: offlineModeDefaultPreferences,
    })
    const adaptedDataPreferences = { ...projectDataPreferences }
    Object.keys(projectDataPreferences).forEach((preferenceName) => {
      const isPreferenceDisabled = offlineModeDisabledPreferences.includes(
        preferenceName
      )
      const preferenceValue = projectDataPreferences[preferenceName]
      const defaultPreferenceValue =
        offlineModeDefaultPreferences[preferenceName]
      if (isPreferenceDisabled && preferenceValue !== defaultPreferenceValue) {
        this.$loggingService.logError(
          `Preferences/adapterData: '${preferenceName}' can not be ${preferenceValue} in offline mode. ` +
            `Using the default value instead: ${defaultPreferenceValue}.`
        )
        adaptedDataPreferences[preferenceName] = defaultPreferenceValue
      }
    })
    preferences.setPreferences(adaptedDataPreferences)
    commit('storePreferences', preferences)
  },
}
