<template>
  <component
    :is="unitPanelContentComponent"
    :project-element="unit"
    :access-links-enabled="accessLinksEnabled"
  ></component>
</template>

<script>
import { UnitTypes } from '@/constants/units'
import Unit from '@/classes/ProjectStructure/Unit/Unit'
import UnitPanelMediumContentAmenities from '@/components/PanelContent/panels/UnitPanel/UnitPanelMediumContentAmenities'
import UnitPanelMediumContent from '@/components/PanelContent/panels/UnitPanel/UnitPanelMediumContent'

export default {
  name: 'UnitPanelMediumContentFactory',
  components: {
    UnitPanelMediumContent,
  },
  props: {
    projectElement: {
      type: Unit,
      default: () => undefined,
    },
    accessLinksEnabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    unit() {
      return this.projectElement
    },
    unitPanelContentComponent() {
      const panelContentsByUnitType = {
        [UnitTypes.AMENITIES]: UnitPanelMediumContentAmenities,
        [UnitTypes.APARTMENT]: UnitPanelMediumContent,
        [UnitTypes.OFFICE]: UnitPanelMediumContent,
        [UnitTypes.SHOP]: UnitPanelMediumContent,
        [UnitTypes.HOUSE]: UnitPanelMediumContent,
      }
      return panelContentsByUnitType[this.unit.type]
    },
  },
}
</script>
