import BaseNavigationHistoryStepChecker from './BaseNavigationHistoryStepChecker'

export default class NeighbourhoodSectionNavigationHistoryStepChecker extends BaseNavigationHistoryStepChecker {
  checkNavigationHistoryStep(to, from) {
    return (
      to.name !== from.name ||
      to.params.neighbourhoodSubdivisionId !==
        from.params.neighbourhoodSubdivisionId
    )
  }
}
