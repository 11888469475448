import Gallery from '@/classes/Gallery'

export default class UnitLevel {
  constructor({ offset, displayName, slug, defaultCollectionSlug }) {
    if (typeof offset !== 'number' || Number.isNaN(offset)) {
      throw new Error(
        `UnitLevel.constructor(): offset is not a number, passed value: ${offset}`
      )
    }
    if (typeof displayName !== 'string') {
      throw new Error(
        `UnitLevel.constructor(): displayName is not a string, passed value: ${displayName}`
      )
    }
    if (typeof slug !== 'string') {
      throw new Error(
        `UnitLevel.constructor(): slug is not a string, passed value: ${slug}`
      )
    }
    this._offset = offset
    this._displayName = displayName
    this._slug = slug
    this._gallery = new Gallery(defaultCollectionSlug)
  }

  setGalleryCollection({ collectionSlug, resources }) {
    const galleryCollection = this._gallery.collection(collectionSlug)
    resources.forEach(({ resourceUrl, labelText = '', hintText = '' }) => {
      galleryCollection.createResource({
        resourceUrl,
        labelText,
        hintText,
      })
    })
  }

  toString() {
    return JSON.stringify({
      offset: this.offset,
      displayName: this.displayName,
      slug: this.slug,
    })
  }

  get offset() {
    return this._offset
  }
  get displayName() {
    return this._displayName
  }
  get slug() {
    return this._slug
  }
  get gallery() {
    return this._gallery
  }
}
