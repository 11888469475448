import CollectionSlugs from './CollectionSlugs'
import GalleryMediaResourceFactory from './GalleryMediaResourceFactory'

class GalleryCollection {
  constructor(slug) {
    if (!CollectionSlugs.isValid(slug)) {
      throw new Error(`GalleryCollection: Invalid slug (${slug}).`)
    }
    this._slug = slug
    this._resources = []
    this._galleryResourceFactory = new GalleryMediaResourceFactory()
  }
  createResource(resourceData) {
    try {
      const newResource = this._galleryResourceFactory.makeResource(
        this.slug,
        resourceData
      )
      this._resources.push(newResource)
      return newResource
    } catch (error) {
      throw new Error(
        `GalleryCollection.createResource(): error creating gallery resource. ${error}`
      )
    }
  }
  getResourceById(resourceId) {
    if (typeof resourceId !== 'string' || resourceId.trim() === '') {
      throw new Error(
        `GalleryCollection.getResourceById(): resourceId ${resourceId} is not a valid string.`
      )
    }
    const resourceIndex = parseInt(resourceId, 10) - 1
    try {
      return this.getResource(resourceIndex)
    } catch (error) {
      throw new Error(
        `GalleryCollection.getResourceById(): There was a problem getting a resource with id '${resourceId}'. ${error}`
      )
    }
  }
  getResource(index) {
    if (typeof index !== 'number' || Number.isNaN(index)) {
      throw new Error(
        `GalleryCollection.getResource(): index ${index} is not a valid number.`
      )
    }
    if (index < 0) {
      throw new Error(
        `GalleryCollection.getResource(): index ${index} is not valid. Index must be a positive integer.`
      )
    }
    if (index >= this._resources.length) {
      throw new Error(
        `GalleryCollection.getResource(): index ${index} is out of bounds.`
      )
    }
    return this._resources[index]
  }
  is(collectionSlug) {
    return this._slug === collectionSlug
  }
  get firstResourceWithAnimation() {
    return this._resources.find((resource) => !!resource.animationLayerVideoUrl)
  }
  get hasResourcesWithAnimation() {
    return !!this.firstResourceWithAnimation
  }
  get size() {
    return this._resources.length
  }
  get slug() {
    return this._slug
  }
  get resources() {
    return [...this._resources]
  }
}
export default GalleryCollection
