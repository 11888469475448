<template>
  <TheTopBar :controls="controls" :menu-offset="menuOffset">
    <template #pointer-events-disabled-relative-slot>
      <TopBarLabel v-if="!!labelText" align="left">
        {{ labelText }}
      </TopBarLabel>
    </template>
  </TheTopBar>
</template>

<script>
import TheTopBar from '@/components/Gadgets/TheTopBar'
import TopBarControlsSuite from '@/classes/TopBarControls/TopBarControlsSuite'
import TopBarLabel from '@/components/Gadgets/TheTopBar/snippets/TopBarLabel'

export default {
  name: 'NeighbourhoodSubdivisionTopBarControls',
  components: {
    TheTopBar,
    TopBarLabel,
  },
  props: {
    labelText: {
      type: String,
      default: undefined,
    },
    menuOffset: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    controls() {
      return new TopBarControlsSuite({})
    },
  },
}
</script>
