import ClusterGroupAdapterFactory from './ClusterGroupAdapterFactory'

export default class ClusterAdapter {
  static adapt(clusterJsonData, parentElement, i18n) {
    if (typeof clusterJsonData !== 'object') {
      throw new Error(
        `ClusterAdapter.adapt(): Invalid clusterJsonData ${clusterJsonData}.`
      )
    }
    const clusterGroupTypes = Object.keys(clusterJsonData)
    clusterGroupTypes.forEach((clusterGroupType) => {
      const ClusterGroupAdapter = new ClusterGroupAdapterFactory().makeClusterGroupAdapter(
        clusterGroupType
      )
      const clusterGroupJsonData = clusterJsonData[clusterGroupType]
      ClusterGroupAdapter.adapt(clusterGroupJsonData, parentElement, i18n)
    })
  }
}
