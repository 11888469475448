<template>
  <TopBarControlWrapper>
    <AppButtonIcon
      :icon="buttonIcon"
      :button-style="buttonStyle"
      :size="size"
      @click.native="$emit('button-click')"
    />
  </TopBarControlWrapper>
</template>

<script>
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'
import iconNames from '@/constants/ui-kit/standard/icon-names'
import TopBarControlWrapper from '@/components/Gadgets/TheTopBar/snippets/TopBarControlWrapper'
import AppButtonIcon from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonIcon'

export default {
  name: 'TopBarButtonIcon',
  components: {
    AppButtonIcon,
    TopBarControlWrapper,
  },
  props: {
    buttonIcon: {
      type: String,
      required: true,
      validator: (value) => Object.values(iconNames).includes(value),
    },
    buttonStyle: {
      type: String,
      default: styles.DARK,
      validator: (value) => Object.values(styles).includes(value),
    },
    size: {
      type: String,
      default: sizes.SM,
      validator: (value) => Object.values(sizes).includes(value),
    },
  },
}
</script>
