import transitionApiModes from '@/constants/transition-manager/transition-api-modes'
import CommonProxy from '@/classes/utilities/CommonProxy'

export default class Transition {
  constructor(transitionParams) {
    this._transitionParams = transitionParams
    this._transitionComponentProps = {
      name: 'none',
      css: true,
      mode: transitionApiModes.DEFAULT,
    }
    this._isInTransitionPerformed = false
    this._isOutTransitionPerformed = false
  }
  get componentProps() {
    return CommonProxy.readOnly(this._transitionComponentProps)
  }
  get shouldDisplayVideo() {
    return false
  }
  get isTransitionActive() {
    return !(this._isInTransitionPerformed && this._isOutTransitionPerformed)
  }
  enter() {}
  leave() {}
}
