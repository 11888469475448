<template>
  <div
    ref="scrollContainer"
    class="scroll-manager"
    :class="{
      'scroll-manager--scrolling': scrolling,
      'scroll-manager--enabled': scrollEnabled,
    }"
  >
    <!-- FIXME: Maybe a better method than this placeholder should be used (W3D-201: https://graff3d.atlassian.net/browse/W3D-201)-->
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1920"
      height="1080"
      class="scroll-manager__placeholder"
    >
      <rect x="2" y="2" width="1916" height="1076" style="fill:transparent" />
    </svg>
    <slot></slot>
  </div>
</template>

<script>
import PanScrollManager from '@/classes/PanScrollManager'
import responsiveMixin from '@/mixins/responsiveMixin'

export default {
  name: 'TheScrollManager',
  mixins: [responsiveMixin],
  props: {
    scrollEnabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      panScrollManager: null,
      scrolling: false,
    }
  },
  mounted() {
    this.panScrollManager = new PanScrollManager({
      container: this.$refs.scrollContainer,
    })
    this.panScrollManager.scrollToCenter()
  },
  methods: {
    async reset({ useTransition }) {
      if (!this.screenSizeRequiresScrollbar) {
        this.panScrollManager.resetScrollPosition()
      }
      this.panScrollManager.update()
      await this.center({ useTransition: !!useTransition })
    },
    async center({ useTransition }) {
      const useSmoothBehavior = useTransition
      this.scrolling = true
      await this.panScrollManager.scrollToCenter(useSmoothBehavior)
      this.scrolling = false
    },
    getScrollPosition() {
      return this.$refs.scrollContainer.scrollLeft
    },
    setScrollPosition(scrollPosition) {
      this.panScrollManager.setScrollLeft(scrollPosition)
    },
  },
}
</script>

<style lang="scss" scoped>
.scroll-manager {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  @at-root #{&}--enabled {
    overflow-y: hidden;
    overflow-x: auto;
  }
  @at-root #{&}--scrolling {
    @include enable-gpu-with-css;
  }
  @at-root #{&}__placeholder {
    position: absolute;
    width: 100vw;
    height: 100vh;
    @include tm-media-background;
  }
}
</style>
