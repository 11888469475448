<template>
  <div class="exterior-tour360">
    <exterior-tour360-top-bar-controls class="exterior-tour360__top-controls" />
  </div>
</template>

<script>
import ExteriorTour360TopBarControls from '@/components/ViewTopBarControls/ExteriorTour360TopBarControls'

export default {
  name: 'ExteriorTour360',
  components: {
    ExteriorTour360TopBarControls,
  },
}
</script>

<style lang="scss" scoped>
.exterior-tour360 {
  &__top-controls {
    z-index: 20;
  }
}
</style>
