class CommonProxy {
  static readOnly(object) {
    const handler = {
      set: (object, prop, value) => {
        throw new Error(
          `Error trying set '${value}' value of ${prop}: ${prop} is a read-only property. Object: ${JSON.stringify(
            object
          )}.`
        )
      },
    }
    return new Proxy(object, handler)
  }
}

export default CommonProxy
