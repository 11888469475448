<template>
  <div class="ui-kit">
    <div class="ui-kit__components-menu">
      <h1>UI Kit: Standard</h1>
      <div
        v-for="viewerComponentsGroup in viewerComponents"
        :key="viewerComponentsGroup.category"
      >
        <h2>{{ viewerComponentsGroup.category }}</h2>
        <ul>
          <li
            v-for="(viewerComponent,
            viewerComponentName) in viewerComponentsGroup.components"
            :key="viewerComponentName"
          >
            <router-link
              class="ui-kit__components-menu__link"
              :to="{
                name: 'ui-kit-viewer',
                params: { componentName: viewerComponentName },
              }"
            >
              {{ viewerComponentName }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div
      class="ui-kit__content"
      :class="[`ui-kit__content--${currentBackgroundTheme}`]"
    >
      <component :is="componentName" />
      <div class="ui-kit__color-menu">
        <ul>
          <li
            v-for="backgroundTheme in backgroundThemes"
            :key="backgroundTheme"
          >
            <button
              :class="[
                backgroundTheme,
                { current: currentBackgroundTheme === backgroundTheme },
              ]"
              @click="currentBackgroundTheme = backgroundTheme"
            >
              {{ backgroundTheme }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import viewerComponents from '@/constants/ui-kit/standard/viewer-components'

const allViewerComponents = viewerComponents.reduce(
  (allViewers, viewerCategory) => ({
    ...allViewers,
    ...viewerCategory.components,
  }),
  {}
)

export default {
  name: 'UiKitView',
  components: allViewerComponents,
  props: {
    componentName: {
      type: String,
      default: () => undefined,
    },
  },
  data() {
    return {
      viewerComponents,
      backgroundThemes: ['white', 'gray', 'black'],
      currentBackgroundTheme: 'white',
    }
  },
}
</script>

<style lang="scss" scoped>
.ui-kit {
  --web3d-color: #7db942;

  display: flex;
  @at-root &__content {
    flex: 1;
    height: 100vh;
    overflow: auto;
    position: relative;
    padding: 1.5rem 30px;

    @at-root &--white {
      color: var(--gray-900);
      background-color: #fff;
    }
    @at-root &--gray {
      color: var(--gray-900);
      background-color: var(--gray-200);
    }
    @at-root &--black {
      color: var(--gray-50);
      background-color: var(--gray-900);
    }
  }

  @at-root &__color-menu {
    position: fixed;
    right: 100px;
    bottom: 20px;
    padding: 10px;
    background: #fff;
    border: 1px solid var(--gray-200);
    border-radius: 12px;

    ul,
    li {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    ul {
      display: flex;
      gap: 5px;
    }

    button {
      text-indent: -9999px;
      display: block;
      width: 20px;
      height: 20px;
      border: 1px solid var(--gray-600);

      &.white {
        background-color: #fff;
      }
      &.gray {
        background-color: var(--gray-200);
      }
      &.black {
        background-color: var(--gray-900);
      }
      &.current {
        border: 3px solid var(--web3d-color);
      }
    }
  }

  @at-root &__components-menu {
    color: var(--gray-900);
    width: 270px;
    height: 100vh;
    overflow: auto;
    border-right: 1px solid var(--gray-200);
    padding: 1.5rem 20px;

    h1 {
      font-weight: var(--font-weight-medium);
      font-size: 1.125rem;
      margin-bottom: 2em;
    }

    h2 {
      font-weight: var(--font-weight-medium);
      font-size: 1rem;
      margin: 1.5em 0 0.5em;
    }

    ul,
    li {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @at-root &__link {
      color: var(--gray-600);
      font-size: 0.875rem;

      &.router-link-active {
        font-weight: var(--font-weight-bold);
        text-decoration: none;
        color: var(--web3d-color);
      }
    }
  }
}
</style>
