<template>
  <iframe
    class="iframe-form-wrapper"
    :src="iframeSrc"
    :style="iframeHeightComputedStyle"
    frameborder="0"
    :scrolling="iframeHeight ? 'no' : 'yes'"
  ></iframe>
</template>
<script>
export default {
  name: 'IframeFormWrapper',
  props: {
    formUrl: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      iframeHeight: null,
    }
  },
  computed: {
    iframeSrc() {
      return this.formUrl
    },
    iframeHeightComputedStyle() {
      if (this.iframeHeight) {
        return `--iframe-height: ${this.iframeHeight}px`
      }

      return ''
    },
  },
  mounted() {
    window.addEventListener('message', this.handleIframeHeightResizing)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleIframeHeightResizing)
  },
  methods: {
    handleIframeHeightResizing(event) {
      if (
        event.data.eventName === 'IFRAME_FORM_RESIZING' &&
        event.data.height
      ) {
        this.iframeHeight = event.data.height + 32
        this.$emit('resize', this.iframeHeight)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.iframe-form-wrapper {
  --iframe-height: 400px;
  width: 100%;
  overflow-y: auto;
  height: var(--iframe-height);
}
</style>
