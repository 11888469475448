<template>
  <div>
    <AppTooltip
      v-if="shouldDisplayButton"
      :text="tooltipText"
      :placement="tooltipPlacement"
    >
      <TopBarButtonIcon
        :button-icon="backButtonIcon"
        :size="backButtonSize"
        :button-style="backButtonStyle"
        @button-click="navigationHistoryBack"
      />
    </AppTooltip>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AppTooltip from '@/components/UIKit/Standard/Molecules/AppTooltip'
import TopBarButtonIcon from '@/components/Gadgets/TheTopBar/controls/TopBarButtonIcon'
import iconNames from '@/constants/ui-kit/standard/icon-names'
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'
import tooltipPlacements from '@/constants/ui-kit/standard/tooltip-placements'
import PageNameGenerator from '@/classes/PageNaming/PageNameGenerator'

export default {
  name: 'TheTopBarBackButton',
  components: {
    AppTooltip,
    TopBarButtonIcon,
  },
  data() {
    return {
      backButtonIcon: iconNames.UIBack,
      backButtonSize: sizes.SM,
      backButtonStyle: styles.DARK,
      tooltipPlacement: tooltipPlacements.BOTTOM_START,
      pageNameGenerator: null,
    }
  },
  computed: {
    ...mapGetters({
      menuData: 'Layout/menuData',
      project: 'Topview/project',
    }),
    lastNavigationHistoryStepPageName() {
      return this.pageNameGenerator?.makePageName(
        this.$navigationHistoryManager.lastRoute
      )
    },
    tooltipText() {
      return this.$t('_backTo', {
        pageName: this.lastNavigationHistoryStepPageName,
      })
    },
    shouldDisplayButton() {
      return !!this.$navigationHistoryManager.lastRoute
    },
  },
  created() {
    this.pageNameGenerator = new PageNameGenerator(
      this.$i18n,
      this.menuData,
      this.project
    )
  },
  methods: {
    navigationHistoryBack() {
      this.$navigationHistoryManager.stepBack()
    },
  },
}
</script>
