function clickEventHandler(event, el, binding) {
  const isElementClicked = el.contains(event.target)
  if (!isElementClicked) {
    binding.value()
  }
}

export default {
  priority: 700,
  bind(el, binding) {
    el['clickOutsideEvent'] = (event) => {
      clickEventHandler(event, el, binding)
    }
    document.body.addEventListener('mousedown', el.clickOutsideEvent)
    document.body.addEventListener('touchstart', el.clickOutsideEvent)
  },

  unbind(el) {
    document.body.removeEventListener('mousedown', el.clickOutsideEvent)
    document.body.removeEventListener('touchstart', el.clickOutsideEvent)
  },
}
