<template>
  <connection-required-offline-mode-wrapper class="location">
    <transition name="location__interactive-map__transition--fade">
      <interactive-map
        v-show="isInteractiveMapModeActive"
        :zoom-buttons-offset-y="zoomButtonsOffsetY"
      ></interactive-map>
    </transition>
    <location-top-bar-controls
      :should-details-button-be-shown="shouldDetailsButtonBeShown"
      :should-map-mode-button-be-shown="shouldMapModeButtonBeShown"
      :project-has-exterior-tour360-url="projectHasExteriorTour360Url"
      :mode-button-text="modeButtonText"
      :menu-offset="leftSidebarWidth"
      @show-details="showDetails"
      @switch-map-mode="switchMode"
      @restricted-fullscreen-change="onFullscreenChange"
      @navigate-to-aerial-view="navigateToAerialView"
    />
    <TheInfoPanel
      v-if="isLocationAdditionalDetailsAvailable"
      ref="locationDetails"
      class="location__info-panel"
      :keep-open-in-small-screens-vertical="true"
      :hidden="isFullscreenUICleanModeEnabled"
      display-contact-bar
      @width-updated="updateLeftSidebarWidth"
      @height-updated="panelHeightUpdated"
      @open="unitInfoPanelOpen"
      @closed="unitInfoPanelClosed"
      @request-open-form="onRequestOpenForm"
    >
      <template #large>
        <location-panel-large-content />
      </template>
      <template #small>
        <location-panel-small-content />
      </template>
    </TheInfoPanel>
  </connection-required-offline-mode-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import locationModes from '@/constants/location/modes'
import onlineStatusMixin from '@/mixins/onlineStatusMixin'
import responsiveMixin from '@/mixins/responsiveMixin'
import ConnectionRequiredOfflineModeWrapper from '@/components/ConnectionRequiredOfflineModeWrapper'
import InteractiveMap from '@/components/InteractiveMap'
import TheInfoPanel from '@/components/Gadgets/TheInfoPanel'
import LocationPanelLargeContent from '@/components/PanelContent/panels/LocationPanel/LocationPanelLargeContent'
import LocationPanelSmallContent from '@/components/PanelContent/panels/LocationPanel/LocationPanelSmallContent'

import LocationTopBarControls from '@/components/ViewTopBarControls/LocationTopBarControls'
import ExteriorTour360RouteObjectGenerator from '@/classes/RouteObjectGenerators/ExteriorTour360RouteObjectGenerator'

export default {
  name: 'Location',
  components: {
    InteractiveMap,
    ConnectionRequiredOfflineModeWrapper,
    TheInfoPanel,
    LocationPanelLargeContent,
    LocationPanelSmallContent,
    LocationTopBarControls,
  },
  mixins: [onlineStatusMixin, responsiveMixin],
  props: {
    mode: {
      type: String,
      default: () => undefined,
    },
  },
  data() {
    return {
      isInfoPanelOpen: false,
      shouldOpenInfoPanelOnExitFullscreen: false,
      zoomButtonsOffsetY: 0,
      isFullscreenUICleanModeEnabled: false,
      leftSidebarWidth: 0,
    }
  },
  computed: {
    ...mapGetters({
      isLocationCustomMapAvailable: 'Project/isLocationCustomMapAvailable',
      isLocationAdditionalDetailsAvailable:
        'Project/isLocationAdditionalDetailsAvailable',
      projectHasExteriorTour360Url: 'Topview/projectHasExteriorTour360Url',
      project: 'Topview/project',
    }),
    isInteractiveMapModeActive() {
      const isInteractiveMapTheOnlyMode = !this.isLocationCustomMapAvailable
      return (
        isInteractiveMapTheOnlyMode ||
        this.mode === locationModes.INTERACTIVE_MAP
      )
    },
    defaultMode() {
      return this.isLocationCustomMapAvailable
        ? locationModes.CUSTOM_MAP
        : undefined
    },
    modeButtonText() {
      return this.isInteractiveMapModeActive
        ? this.$t('Mapa de ubicación')
        : this.$t('Mapa interactivo')
    },
    shouldDetailsButtonBeShown() {
      return (
        !this.isInfoPanelOpen &&
        !this.isSmallScreenVertical &&
        this.isLocationAdditionalDetailsAvailable
      )
    },
    shouldMapModeButtonBeShown() {
      return this.isLocationCustomMapAvailable
    },
  },
  methods: {
    updateLeftSidebarWidth(infoPanelWidth) {
      this.leftSidebarWidth = infoPanelWidth
    },
    panelHeightUpdated(value) {
      this.zoomButtonsOffsetY = -value
    },
    switchMode() {
      const locationMode = this.isInteractiveMapModeActive
        ? locationModes.CUSTOM_MAP
        : locationModes.INTERACTIVE_MAP
      this.$router.push({ params: { mode: locationMode } })
    },
    showDetails() {
      if (this.$refs.locationDetails) {
        this.$refs.locationDetails.open()
      }
    },
    validateRouteMode(route) {
      if (this.isLocationCustomMapAvailable) {
        return this.validateModeWhenCustomMapAvailable(route)
      } else {
        return this.validateModeWhenCustomMapNotAvailable(route)
      }
    },
    validateModeWhenCustomMapAvailable(route) {
      const mode = route.params?.mode
      const isCurrentModeValid = Object.values(locationModes).includes(mode)
      if (isCurrentModeValid) {
        return true
      } else {
        this.$loggingService.logError(
          `Location.validateRouteMode: Invalid route: ${mode} is not a valid mode. ` +
            `Route params: ${JSON.stringify(route.params)}.`
        )
        return false
      }
    },
    validateModeWhenCustomMapNotAvailable(route) {
      const mode = route.params?.mode
      const isCurrentModeValid = mode === undefined
      if (isCurrentModeValid) {
        return true
      } else {
        this.$loggingService.logError(
          `Location.validateRouteMode: Invalid route: multi-mode is disabled. ` +
            `Route params: ${JSON.stringify(route.params)}.`
        )
        return false
      }
    },
    unitInfoPanelOpen() {
      this.isInfoPanelOpen = true
    },
    unitInfoPanelClosed() {
      this.isInfoPanelOpen = false
    },
    onFullscreenChange({ isFullscreen }) {
      this.isFullscreenUICleanModeEnabled = isFullscreen
      if (this.$refs.locationDetails) {
        if (isFullscreen) {
          this.shouldOpenInfoPanelOnExitFullscreen = this.isInfoPanelOpen
          this.$refs.locationDetails.close()
        } else {
          if (
            this.shouldOpenInfoPanelOnExitFullscreen ||
            this.isSmallScreenVertical
          ) {
            this.$refs.locationDetails.open()
          }
        }
      }
    },
    onRequestOpenForm() {
      this.$store.dispatch('Topview/openLocationInfoRequestForm')
    },
    navigateToAerialView() {
      if (this.projectHasExteriorTour360Url) {
        const projectExteriorTour360RouteObject = ExteriorTour360RouteObjectGenerator.make(
          this.project
        )
        this.$router.push(projectExteriorTour360RouteObject)
      } else {
        this.$loggingService.logError(
          `Location: navigateToInteractiveMap() is not available in projects without exteriorTour360Url`
        )
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    next(this.validateRouteMode(to))
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.validateRouteMode(to)) {
        vm.$router.replace({ params: { mode: vm.defaultMode } })
      }
    })
  },
}
</script>

<style lang="scss" scoped>
.location {
  position: relative;
  z-index: 320;

  @at-root #{&}__interactive-map {
    @at-root #{&}__transition--fade {
      @at-root #{&}-enter-active,
        #{&}-leave-active {
        transition: opacity 200ms ease-out;
      }
      @at-root #{&}-enter,
        #{&}-leave-to {
        opacity: 0;
      }
    }
  }
}
</style>
