<template>
  <AppBaseModal :title="title" :modal-width="960" v-on="$listeners">
    <AppScrollbarContainer class="embedded-contact-form-modal">
      <IframeFormWrapper :form-url="formUrl" />
    </AppScrollbarContainer>
  </AppBaseModal>
</template>

<script>
import AppScrollbarContainer from '@/components/UIKit/Standard/Atoms/AppScrollbarContainer'
import AppBaseModal from '@/components/UIKit/Standard/Atoms/AppBaseModal'
import IframeFormWrapper from '@/components/Gadgets/IframeFormWrapper.vue'

export default {
  name: 'AppEmbedddedContactFormModal',
  components: {
    AppBaseModal,
    AppScrollbarContainer,
    IframeFormWrapper,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    formUrl: {
      type: String,
      default: undefined,
    },
  },
}
</script>

<style lang="scss" scoped>
.embedded-contact-form-modal {
  max-height: 70vh;
  overflow: auto;

  @include small-screens {
    max-height: none;
  }
}
</style>
