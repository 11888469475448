<template>
  <AppSheetWrapper class="sheet-unit-medium">
    <AppSheetRow>
      <header>
        <AppSheetTitle
          :title="title"
          :subtitle="subtitle"
          :align="titleAlignment"
        />
      </header>
    </AppSheetRow>
    <AppSheetRow v-if="shouldDisplayFeatures" separator-top separator-bottom>
      <AppFeatureListPlain :features="features" />
    </AppSheetRow>
    <AppSheetRow v-if="shouldDisplayButtonsBlock">
      <AppSheetBlockButtonGroup>
        <AppButtonTextRouterLink
          v-if="tour360To"
          :text="$t('Tour 360º')"
          :size="buttonSize"
          :button-style="buttonTour360Style"
          :to="tour360To"
        />
        <AppButtonTextRouterLink
          v-if="enterTo"
          :text="$t('Ingresar')"
          :size="buttonSize"
          :button-style="buttonEnterStyle"
          :to="enterTo"
        />
      </AppSheetBlockButtonGroup>
    </AppSheetRow>
  </AppSheetWrapper>
</template>

<script>
import AppSheetWrapper from '@/components/UIKit/Standard/Atoms/AppSheetWrapper'
import AppSheetRow from '@/components/UIKit/Standard/Atoms/AppSheetRow'
import AppSheetBlockButtonGroup from '@/components/UIKit/Standard/Molecules/AppSheetBlockButtonGroup'
import AppSheetTitle from '@/components/UIKit/Standard/Atoms/AppSheetTitle'
import AppButtonTextRouterLink from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonTextRouterLink'
import alignments from '@/constants/ui-kit/standard/alignments'
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'
import AppFeatureListPlain from '@/components/UIKit/Standard/Molecules/AppFeatureListPlain'
import Feature from '@/classes/Feature/Feature'

export default {
  name: 'AppSheetUnitMediumAmenities',
  components: {
    AppSheetWrapper,
    AppSheetRow,
    AppSheetBlockButtonGroup,
    AppSheetTitle,
    AppButtonTextRouterLink,
    AppFeatureListPlain,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: () => undefined,
    },
    enterTo: {
      type: Object,
      default: () => undefined,
    },
    tour360To: {
      type: Object,
      default: () => undefined,
    },
    features: {
      type: Array,
      required: true,
      validator: (value) =>
        value.every((feature) => feature instanceof Feature),
    },
  },
  data() {
    return {
      titleAlignment: alignments.ALIGN_LEFT,
      buttonSize: sizes.XS,
      buttonEnterStyle: styles.PRIMARY,
    }
  },
  computed: {
    activeButtonsCount() {
      const truthyButtonConditions = [this.enterTo, this.tour360To].filter(
        (value) => value
      )
      return truthyButtonConditions.length
    },
    shouldDisplayButtonsBlock() {
      return this.activeButtonsCount > 0
    },
    hasASingleButton() {
      return this.activeButtonsCount === 1
    },
    buttonTour360Style() {
      return this.hasASingleButton ? styles.PRIMARY : styles.LIGHT
    },
    shouldDisplayFeatures() {
      return this.features.length > 0
    },
  },
}
</script>
