import NavigationHistoryManager from '@/classes/NavigationHistory/NavigationHistoryManager'

let NavigationHistoryManagerPlugin = {}

NavigationHistoryManagerPlugin.install = function(Vue) {
  Vue.prototype.$navigationHistoryManager = Vue.observable(
    new NavigationHistoryManager()
  )
}

export default NavigationHistoryManagerPlugin
