<template>
  <div class="preview-image">
    <SpriteClip
      class="preview-image__image__preview"
      :image-url="previewImageUrl"
    />
    <AppImage class="preview-image__image__picture" :src="previewImageUrl" />
  </div>
</template>

<script>
import SpriteClip from '@/components/Gadgets/SpriteClip'
import AppImage from '@/components/UIKit/Standard/Atoms/AppImage'

export default {
  name: 'AppSheetPreviewImage',
  components: {
    SpriteClip,
    AppImage,
  },
  props: {
    previewImageUrl: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.preview-image {
  position: relative;
  aspect-ratio: 322 / 147;
  overflow: hidden;
  background: var(--gray);
  border-radius: 8px;

  @at-root #{&}__image {
    @at-root #{&}__picture {
      width: 100%;
      height: 100%;
    }
    @at-root #{&}__preview {
      position: absolute;
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  // Hack to fix border-radius clip bug in Safari
  transform: translateZ(0);
}
</style>
