<template>
  <TheTopBar :controls="controls" />
</template>

<script>
import TheTopBar from '@/components/Gadgets/TheTopBar'
import TopBarControlsSuite from '@/classes/TopBarControls/TopBarControlsSuite'
import TopBarButtonIconGenerator from '@/classes/TopBarControls/TopBarButtonIconGenerator'
import TopBarButtonGenerator from '@/classes/TopBarControls/TopBarButtonGenerator'
import responsiveMixin from '@/mixins/responsiveMixin'
import iconNames from '@/constants/ui-kit/standard/icon-names'

export default {
  name: 'MasterplanTopBarControls',
  components: {
    TheTopBar,
  },
  mixins: [responsiveMixin],
  props: {
    projectHasExteriorTour360Url: {
      type: Boolean,
      default: undefined,
    },
    isGalleryAvailable: {
      type: Boolean,
      default: undefined,
    },
    includesFloorsButtonInMobile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    controls() {
      return new TopBarControlsSuite({
        rightControls: [
          TopBarButtonGenerator.create({
            buttonText: this.$t('Vista aérea'),
            show: () =>
              this.projectHasExteriorTour360Url && !this.isSmallScreen,
            eventHandler: () => this.$emit('navigate-to-aerial-view'),
          }),
          TopBarButtonGenerator.create({
            buttonText: this.$t('Ver Plantas'),
            projectTypesSupported: [this.$projectType.SINGLETOWER_TYPE],
            show: () => !this.isSmallScreen,
            eventHandler: () => this.$emit('navigate-to-single-tower-topview'),
          }),
          TopBarButtonIconGenerator.create({
            buttonIcon: iconNames.UIFloors,
            projectTypesSupported: [this.$projectType.SINGLETOWER_TYPE],
            show: () => this.isSmallScreen && this.includesFloorsButtonInMobile,
            eventHandler: () => this.$emit('navigate-to-single-tower-topview'),
          }),
          TopBarButtonGenerator.create({
            buttonText: this.$t('Galería'),
            show: () => this.isGalleryAvailable && !this.isSmallScreen,
            eventHandler: () => this.$emit('navigate-to-gallery'),
          }),

          TopBarButtonIconGenerator.create({
            buttonIcon: iconNames.UIAerialView,
            show: () => this.projectHasExteriorTour360Url && this.isSmallScreen,
            eventHandler: () => this.$emit('navigate-to-aerial-view'),
          }),
          TopBarButtonIconGenerator.create({
            buttonIcon: iconNames.UIGallery,
            show: () => this.isGalleryAvailable && this.isSmallScreen,
            eventHandler: () => this.$emit('navigate-to-gallery'),
          }),
          TopBarButtonIconGenerator.create({
            buttonIcon: iconNames.UIFloors,
            projectTypesSupported: [], // Temporary disabled in all project types
            show: () => this.isSmallScreen,
            eventHandler: () => this.$emit('navigate-to-single-tower-topview'),
          }),
        ],
      })
    },
  },
}
</script>
