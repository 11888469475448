export default {
  FOOTAGE: 'FeatureArea',
  BEDROOM: 'FeatureBedroom',
  BATHROOM: 'FeatureBathroom',
  LAUNDRY: 'FeatureLaundry',
  BALCONY: 'FeatureBalcony',
  PARKING: 'FeatureParking',
  SERVICE_ROOM: 'FeatureServiceRoom',
  KITCHEN: 'kitchenIcon',

  PARKING_OPTIONAL: 'FeatureParkingAlt1',
  TERRACE_APARTMENT: 'terraceApartmentIcon',
  TERRACE_AMENITIES: 'terraceAmenitiesIcon',
  SERVICE_BATH: 'serviceBathIcon',
  SWIMMING_POOL: 'FeatureSwimmingPool',
  GYM: 'FeatureGym',
  SOLARIUM: 'solariumIcon',
  BARBECUE: 'FeatureBarbecue',
  PARTY_ROOM: 'partyRoomIcon',
  KIDS_PARK: 'kidsParkIcon',
  KIDS_ROOM: 'kidsRoomIcon',
  PET_ZONE: 'petZoneIcon',
  GREEN_AREA: 'FeatureGreenArea',
  SOCIAL_DEN: 'socialDenIcon',
  MASSAGE_ROOM: 'massageRoomIcon',
  LIBRARY_TOOLS: 'libraryToolsIcon',
  COWORKING: 'coworkingIcon',
  CONFERENCE_ROOM: 'conferenceRoomIcon',
  WIFI: 'wifiIcon',
  PRIVATE_OFFICE: 'privateOfficeIcon',
  BOX: 'boxIcon',
  RELAX_AREA: 'relaxAreaIcon',
  ROOFTOP_BAR: 'rooftopBarIcon',
  SKY_LOUNGE: 'skyLoungeIcon',
  YOGA_AND_CROSSFIT: 'yogaAndCrossfitIcon',

  RESTAURANT: 'restaurantIcon',
  SUSHI: 'sushiIcon',
  SAUNA: 'saunaIcon',
  COMMERCIAL_AREA: 'FeatureCommercialArea',
  PLAYROOM: 'playroomIcon',
  POKER_ROOM: 'pokerRoomIcon',
  JACUZZI: 'jacuzziIcon',
  VALET_PARKING: 'valetParkingIcon',
  SECURITY: 'securityIcon',
  LOBBY: 'lobbyIcon',
  VISITOR_PARKING: 'FeatureParkingAlt2',
  BAR: 'barIcon',

  OPEN_KITCHEN: 'openKitchenIcon',
  BEAUTY_ROOM: 'beautyRoomIcon',
  PET_SHOP: 'petShopIcon',
  FULL_SPA: 'fullSpaIcon',
  SERVICES: 'servicesIcon',
  NUOVO_MARKET: 'nuovoMarketIcon',
  INFINITY_POOL: 'infinityPoolIcon',
  POOLSIDE_CABINS: 'poolsideCabinsIcon',
  ARMANI_CASA_FLAG_STORE: 'FeatureArmaniCasa',
  OPEN_TERRACE: 'openTerraceIcon',
  LOUNGE_AREA: 'loungeAreaIcon',
  LAP_POOL: 'lapPoolIcon',

  MINI_GOLF: 'miniGolfIcon',
  CINEMA: 'cinemaIcon',
  CROSSFIT: 'crossfitIcon',

  LOUNGE_LIBRARY: 'loungeLibrary',
  CARDS_ROOM: 'cardsRoom',
  SKY_GARDEN: 'skyGarden',
  YOGA_STUDIO: 'yogaStudio',
  SKY_GYM: 'skyGym',

  THOUGHT_SHARING_LAB: 'thoughtSharingLab',
  FULL_ATHLETIC_FACILITY: 'fullAthleticFacility',
  PANORAMIC_SPA: 'panoramicSpa',
  OPEN_GARDEN: 'openGarden',
  PETS_PARK: 'petsPark',
  KIDS_PLAYGROUND: 'kidsPlayground',
  ENCLOSED_CINEMA: 'enclosedCinema',
  PANORAMIC_INFINITY_POOL: 'panoramicInfinityPool',
  POOL_SIDE_JUICE_BAR: 'poolSideJuiceBar',
  OBSERVATION_DECK: 'observationDeck',
  SOCIAL_OUTDOOR_KITCHEN: 'socialOutdoorKitchen',
  OUTDOOR_THEATER: 'outdoorTheater',
  PANORAMIC_LOUNGE: 'panoramicLounge',
  BASKETBALL_COURT_1_2: 'basketballCourt1/2',

  INTERNAL_AREA: 'FeatureAreaInternal',
  EXTERNAL_AREA: 'FeatureAreaExternal',
  TOTAL_AREA: 'FeatureAreaTotal',

  YOGA: 'yogaIcon',
  STEAM_ROOMS: 'steamRoomsIcon',
  TENNIS_COURT: 'tennisCourtIcon',
  MULTIPURPOSE_COURT: 'multipurposeCourtIcon',
  SPA: 'spaIcon',

  POWDER_ROOM: 'powderRoomIcon',

  STUDY_ROOM: 'studyRoom',
  BANQUET_DINING_SPACE: 'banquetDiningSpace',
  MULTI_FUNCTION_ROOM: 'multiFunctionRoom',
  SAUNA_STEAMBATH: 'saunaSteambath',
  FAMILY_ROOM: 'familyRoom',

  // Local icons (not related to characteristics)
  BARS: 'UIMenu',
  CLOSE: 'UIClose',
  PLUS: 'UIZoomIn',
  MINUS: 'UIZoomOut',
  UP_ARROW: 'UIChevronTop', // Se debería usar una variante rotada de UIDropdown
  DOWN_ARROW: 'UIChevronBottom', // Se debería usar el UIDropdown
  LEFT_ARROW_FA: 'UIChevronLeft',
  RIGHT_ARROW_FA: 'UIChevronRight',
  IMAGES_GALLERY: 'UIGallery',
  EYE_SQUARE: 'eyeSquare',
  MAP: 'map',
  TOUR_360: 'LegacyTour360',
  FULLSCREEN: 'UIMaximize',
  EXIT_FULLSCREEN: 'UIMinimize',
  LEVELS: 'UIFloors',
  INTERACTIVE_MAP: 'UIAerialView',

  SHARE: 'UIShare',
  COPY_LINK: 'UILink',
  FACEBOOK: 'UIFacebook',
  INSTAGRAM: 'UIInstagram',
  WHATSAPP: 'UIWhatsapp',
  ENVELOPE: 'UIEmail',
  PHONE: 'UIPhone',

  HOME: 'UIHome',
}
