import GalleryCollection from './GalleryCollection'
import CollectionSlugs from './CollectionSlugs'

class Gallery {
  constructor(defaultCollectionSlug = CollectionSlugs.GALLERY) {
    if (!CollectionSlugs.isValid(defaultCollectionSlug)) {
      throw new Error(
        `Gallery: Invalid defaultCollectionSlug (${defaultCollectionSlug}).`
      )
    }
    this._defaultCollectionSlug = defaultCollectionSlug
    this._collections = {}
    CollectionSlugs.all.forEach((slug) => {
      this._collections[slug] = new GalleryCollection(slug)
    })
  }
  get availableCollections() {
    return CollectionSlugs.all
      .map((slug) => this._collections[slug])
      .filter((collection) => collection.size > 0)
  }
  get defaultCollection() {
    return this._collections[this._defaultCollectionSlug]
  }
  get firstAvailableCollection() {
    if (this.includes(this.defaultCollection.slug)) {
      return this.defaultCollection
    } else {
      return this.availableCollections[0]
    }
  }
  get collectionsWithAnimation() {
    return this.availableCollections.filter((collection) => {
      return !!collection.hasResourcesWithAnimation
    })
  }
  get firstAvailableCollectionWithAnimation() {
    return this.collectionsWithAnimation[0]
  }
  collection(collectionSlug) {
    if (!CollectionSlugs.isValid(collectionSlug)) {
      throw new Error(
        `Gallery.collection(): Invalid collectionSlug (${collectionSlug}).`
      )
    }
    return this._collections[collectionSlug]
  }
  includes(collectionSlug) {
    return this.availableCollections
      .map((collection) => collection.slug)
      .includes(collectionSlug)
  }
}
export default Gallery
