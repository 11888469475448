<template>
  <div v-show="UIControlsShown" id="neighbourhood-subdivision-container">
    <neighbourhood-subdivision-top-bar-controls
      :label-text="indicatorText"
      :menu-offset="leftSidebarWidth"
    />
    <selected-svg-element-info-panel
      ref="infoPanel"
      class="neighbourhood-subdivision__unit-info-panel"
      @width-updated="updateLeftSidebarWidth"
    />
    <TheZoomControls with-background-image />
    <ProjectElementPopupDisplayer
      class="neighbourhood-subdivision__popup-displayer"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import responsiveMixin from '@/mixins/responsiveMixin'
import hideUIControlsInTransition from '@/mixins/hideUIControlsInTransition'
import SelectedSvgElementInfoPanel from '@/components/ComposedInfoPanels/SelectedSvgElementInfoPanel'
import HomeRouteObjectGenerator from '@/classes/RouteObjectGenerators/HomeRouteObjectGenerator'
import NeighbourhoodSubdivisionRouteObjectGenerator from '@/classes/RouteObjectGenerators/NeighbourhoodSubdivisionRouteObjectGenerator'
import TheZoomControls from '@/components/Gadgets/TheZoomControls'
import NeighbourhoodSubdivisionTopBarControls from '@/components/ViewTopBarControls/NeighbourhoodSubdivisionTopBarControls'
import ProjectElementPopupDisplayer from '@/components/Gadgets/ProjectElementPopupDisplayer'

import SlugGenerator from '@/classes/SlugGenerator'

export default {
  name: 'NeighbourhoodSubdivision',
  components: {
    SelectedSvgElementInfoPanel,
    NeighbourhoodSubdivisionTopBarControls,
    TheZoomControls,
    ProjectElementPopupDisplayer,
  },
  mixins: [responsiveMixin, hideUIControlsInTransition],
  props: {
    neighbourhoodSubdivisionId: {
      type: String,
      default: () => undefined,
    },
  },
  data() {
    return {
      leftSidebarWidth: 0,
    }
  },
  computed: {
    ...mapGetters({
      findNeighbourhoodSubdivisionById:
        'Topview/findNeighbourhoodSubdivisionById',
      imageZoom: 'ZoomTracker/imageZoom',
    }),
    currentNeighbourhoodSubdivision() {
      return this.findNeighbourhoodSubdivisionById(
        this.neighbourhoodSubdivisionId
      )
    },
    indicatorText() {
      return this.$t('floorOptionsBar.currentNeighbourhoodSubdivision', {
        neighbourhoodSubdivision: this.currentNeighbourhoodSubdivision?.name,
      })
    },
  },
  watch: {
    $route() {
      this.redirectOnInvalidNeighbourhoodSubdivision()
      this.validateLevelSlug()
    },
    imageZoom() {
      this.$refs.infoPanel?.close()
    },
  },
  mounted() {
    this.redirectOnInvalidNeighbourhoodSubdivision()
    this.validateLevelSlug()

    if (this.isSmallScreenVertical) {
      this.$store.dispatch('Layout/setTopLayerCurrentHint', 'RotateDeviceHint')
    }
  },
  methods: {
    validateLevelSlug() {
      const correctNeighbourhoodSubdivisionSlug = SlugGenerator.make(
        this.currentNeighbourhoodSubdivision
      )
      const currentParams = this.$route.params

      if (
        correctNeighbourhoodSubdivisionSlug !== currentParams.descriptiveSlug
      ) {
        const neighbourhoodSubdivisionRouteObject = NeighbourhoodSubdivisionRouteObjectGenerator.make(
          this.currentNeighbourhoodSubdivision
        )
        this.$router.replace(neighbourhoodSubdivisionRouteObject)
      }
    },
    updateLeftSidebarWidth(infoPanelWidth) {
      this.leftSidebarWidth = infoPanelWidth
    },
    redirectOnInvalidNeighbourhoodSubdivision() {
      if (this.currentNeighbourhoodSubdivision === undefined) {
        this.$loggingService.logError(
          `Error in NeighbourhoodSubdivision: route has invalid neighbourhoodSubdivisionId ${this.neighbourhoodSubdivisionId}`
        )
        const homeRouteObject = HomeRouteObjectGenerator.make()
        this.$router.replace(homeRouteObject)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#neighbourhood-subdivision-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 305;
  pointer-events: none;
}

.neighbourhood-subdivision__unit-info-panel {
  pointer-events: all;
  position: relative;
  z-index: 20;
}

.neighbourhood-subdivision__popup-displayer {
  pointer-events: all;
}
</style>
