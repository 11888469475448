<template>
  <div class="app-custom-content-block">
    <h5 class="app-custom-content-block__title">
      {{ customContentBlock.title }}
    </h5>
    <div class="app-custom-content-block__editable-content">
      <AppIcon
        class="app-custom-content-block__editable-content__icon"
        :icon="customContentBlock.icon"
        :size="iconSize"
      />
      <div
        class="app-custom-content-block__editable-content__html"
        v-html="customContentBlock.html"
      ></div>
    </div>
  </div>
</template>

<script>
import AppIcon from '@/components/UIKit/Standard/Atoms/AppIcon.vue'
import CustomContentBlock from '@/classes/ProjectStructure/CustomContentBlock'
import sizes from '@/constants/ui-kit/standard/sizes'

export default {
  name: 'AppCustomContentBlock',
  components: {
    AppIcon,
  },
  props: {
    customContentBlock: {
      type: CustomContentBlock,
      required: true,
    },
  },
  data() {
    return {
      iconSize: sizes.SM,
    }
  },
}
</script>

<style lang="scss" scoped>
.app-custom-content-block {
  display: flex;
  flex-direction: column;

  @at-root &__title {
    font-size: 0.875rem;
    margin-bottom: 1.5625em;
    margin-top: 2.25em;
    font-weight: var(--font-weight-medium);
  }

  @at-root &__editable-content {
    display: flex;
    align-items: flex-start;
    gap: 20px;

    &__icon {
      display: block;
      stroke: var(--primary-color);
      fill: var(--primary-color);
    }
    &__html {
      flex: 1;
      font-size: 0.875rem;
      padding-right: 1.25rem;
    }
  }
}
</style>
