<template>
  <app-sheet-location-large
    :html-content="locationAdditionalDetails.longRichText"
    :logo-image-url="locationAdditionalDetails.logoImgUrl"
    :share-url="currentUrl"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import AppSheetLocationLarge from '@/components/UIKit/Standard/Organisms/AppSheet/AppSheetLocationLarge.vue'
export default {
  name: 'LocationPanelLargeContent',
  components: {
    AppSheetLocationLarge,
  },
  computed: {
    ...mapGetters({
      locationAdditionalDetails: 'Project/locationAdditionalDetails',
      isOfflineModeEnabled: 'Project/isOfflineModeEnabled',
    }),
    currentUrl() {
      return this.isOfflineModeEnabled ? undefined : window.location.href
    },
  },
}
</script>
