import Gallery from '@/classes/Gallery/'
import SvgTargetElementIdMap from '@/classes/SvgTargetElementIdMap'
import SvgPriceTag from '@/classes/SvgPriceTag'

export default {
  adapterData({ dispatch }, { projectData }) {
    try {
      dispatch('adapterAvailabilityGallery', projectData.projectStructure)
      dispatch('adapterUniqueSvgElementIdOfUnitsForAvailabilityDisplay')
    } catch (err) {
      throw new Error(
        `In AvailabilityGalleryModule/adapterData: ${err.toString()}`
      )
    }
  },
  adapterAvailabilityGallery({ commit }, projectStructureData) {
    const availabilityGalleryInstance = new Gallery()

    try {
      projectStructureData.availabilityGallery?.forEach((imageData) => {
        const { labelText, imgUrl, svgUrl } = imageData
        const resourceData = {
          resourceUrl: imgUrl,
          labelText,
        }

        const newGalleryResource = availabilityGalleryInstance.defaultCollection.createResource(
          resourceData
        )

        newGalleryResource.setSvgLayer(svgUrl)
      })
    } catch (err) {
      this.$loggingService.logError(
        `AvailabilityGalleryModule/adapterAvailabilityGallery: cannot push image to gallery: ${err.toString()}`
      )
    }

    commit('storeAvailabilityGallery', availabilityGalleryInstance)
  },
  adapterUniqueSvgElementIdOfUnitsForAvailabilityDisplay({
    commit,
    rootGetters,
  }) {
    const svgTargetElementIdMap = new SvgTargetElementIdMap()
    const units = rootGetters['Topview/units']

    units.forEach((unit) => {
      svgTargetElementIdMap.add(unit, {
        uniqueElementIdThroughoutProject: unit.getUniqueElementIdThroughoutProject(),
      })
    })

    commit('storeAvailabilityGallerySvgData', svgTargetElementIdMap)
  },
  initializeAndStoreSvgPriceTags(
    { rootGetters, getters, commit, dispatch },
    svgElement
  ) {
    const priceTags = svgElement.querySelectorAll('[id$="-price"]')

    const svgMapData = getters['availabilityGallerySvgData']

    const currentLocale = rootGetters['Project/localeCodeBCP47']

    priceTags.forEach((backgroundElement) => {
      const [svgElementId] = backgroundElement.id.split('-price')
      const unit = svgMapData.getElement(svgElementId)

      const tag = new SvgPriceTag({
        unit,
        svgElement,
        backgroundElement,
        currentLocale,
      })

      commit('storeSvgPriceTag', tag)
    })

    dispatch('checkSelectedPaymentMethodTagsVisibility')
  },
  changeSelectedPaymentMethodId({ commit, dispatch }, paymentMethodId) {
    commit('storePaymentMethodId', paymentMethodId)
    dispatch('checkSelectedPaymentMethodTagsVisibility')
  },
  checkSelectedPaymentMethodTagsVisibility({ getters, rootGetters }) {
    const svgPriceTags = getters['priceTags']
    const paymentMethodId = getters['selectedPaymentMethodId']
    const isDisplayPricesOnAvailabilityViewEnabled =
      rootGetters['Preferences/enablePricesOnAvailabilityView']
    const isUnitPriceQuotationEnabled =
      rootGetters['Preferences/enableUnitPriceQuotation']

    svgPriceTags.forEach((priceTag) => {
      if (isDisplayPricesOnAvailabilityViewEnabled) {
        if (isUnitPriceQuotationEnabled) {
          priceTag.checkVisibilityByPaymentMethod(paymentMethodId)
        } else {
          priceTag.checkVisibilityByUnitPrice()
        }
      }
    })
  },
}
