export default {
  data() {
    return {
      isOnline: window.navigator.onLine,
    }
  },
  methods: {
    setIsOnline() {
      this.isOnline = true
    },
    setIsOffline() {
      this.isOnline = false
    },
    /**
     * Attach event listeners to detect change in Online status (connectivity).
     */
    attachListeners() {
      window.addEventListener('online', this.setIsOnline)
      window.addEventListener('offline', this.setIsOffline)
    },

    /**
     * Remove registered event listeners
     */
    removeListeners() {
      window.removeEventListener('online', this.setIsOnline)
      window.removeEventListener('offline', this.setIsOffline)
    },
  },
  mounted() {
    this.attachListeners()
  },
  beforeDestroy() {
    this.removeListeners()
  },
}
