<template>
  <AppSheetWrapper class="sheet-unit-medium">
    <AppSheetRow>
      <header>
        <AppSheetBlockTitleAndAvailability
          :title="title"
          :availability-status="availabilityStatus"
          :availability-color="availabilityColor"
        />
      </header>
    </AppSheetRow>
    <AppSheetRow v-if="withUnavailableMessage">
      <AppSheetAvailabilityText
        :availability-text="$t('Esta unidad ya no está disponible')"
      />
    </AppSheetRow>
    <AppSheetRow
      v-if="shouldDisplayPrice"
      divider-top
      divider-bottom
      background
    >
      <AppSheetPrice :price="price" :align="priceAlignment" />
    </AppSheetRow>
    <AppSheetRow v-if="shouldDisplayFeatures" separator-top separator-bottom>
      <AppFeatureListSmall :features="features" />
    </AppSheetRow>
    <AppSheetRow v-if="shouldDisplayButtonsBlock">
      <AppSheetBlockButtonGroup>
        <AppButtonText
          v-if="withEmbeddedFormButton"
          :text="embeddedContactFormButtonText"
          :size="buttonSize"
          :button-style="buttonEmbeddedContactFormsStyles"
          @click.native.stop="emitLaunchEmbeddedContactForm"
        />
        <AppButtonText
          v-if="withCheckPriceButton"
          :text="$t('Consultar precio')"
          :size="buttonSize"
          :button-style="buttonCheckPriceStyle"
          @click.native.stop="emitRequestPrice"
        />
        <AppButtonText
          v-if="withQuoterButton"
          :text="$t('Cotizar')"
          :size="buttonSize"
          :button-style="buttonQuoterStyle"
          @click.native.stop="emitLaunchQuoter"
        />
        <AppButtonTextRouterLink
          v-if="tour360To"
          :text="$t('Tour 360º')"
          :size="buttonSize"
          :button-style="buttonTour360Style"
          :to="tour360To"
        />
        <AppButtonTextRouterLink
          v-if="enterTo"
          :text="$t('Ingresar')"
          :size="buttonSize"
          :button-style="buttonEnterStyle"
          :to="enterTo"
        />
      </AppSheetBlockButtonGroup>
    </AppSheetRow>
  </AppSheetWrapper>
</template>

<script>
import AppSheetWrapper from '@/components/UIKit/Standard/Atoms/AppSheetWrapper'
import AppSheetRow from '@/components/UIKit/Standard/Atoms/AppSheetRow'
import AppSheetBlockTitleAndAvailability from '@/components/UIKit/Standard/Molecules/AppSheetBlockTitleAndAvailability'
import AppSheetBlockButtonGroup from '@/components/UIKit/Standard/Molecules/AppSheetBlockButtonGroup'
import AppSheetAvailabilityText from '@/components/UIKit/Standard/Atoms/AppSheetAvailabilityText'
import AppButtonTextRouterLink from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonTextRouterLink'
import AppButtonText from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonText'
import alignments from '@/constants/ui-kit/standard/alignments'
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'
import AppSheetPrice from '@/components/UIKit/Standard/Atoms/AppSheetPrice'
import AppFeatureListSmall from '@/components/UIKit/Standard/Molecules/AppFeatureListSmall'
import Feature from '@/classes/Feature/Feature'
import BasePrice from '@/classes/Price/BasePrice'

export default {
  name: 'AppSheetUnitMedium',
  components: {
    AppSheetWrapper,
    AppSheetRow,
    AppSheetBlockTitleAndAvailability,
    AppSheetBlockButtonGroup,
    AppSheetAvailabilityText,
    AppSheetPrice,
    AppButtonTextRouterLink,
    AppButtonText,
    AppFeatureListSmall,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    enterTo: {
      type: Object,
      default: () => undefined,
    },
    tour360To: {
      type: Object,
      default: () => undefined,
    },
    features: {
      type: Array,
      required: true,
      validator: (value) =>
        value.every((feature) => feature instanceof Feature),
    },
    availabilityStatus: {
      type: String,
      default: undefined,
    },
    availabilityColor: {
      type: String,
      default: undefined,
    },
    price: {
      type: BasePrice,
      default: undefined,
    },
    embeddedContactFormButtonText: {
      type: String,
      default: undefined,
    },
    withUnavailableMessage: {
      type: Boolean,
      default: false,
    },
    withCheckPriceButton: {
      type: Boolean,
      default: false,
    },
    withQuoterButton: {
      type: Boolean,
      default: false,
    },
    withEmbeddedFormButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      titleAlignment: alignments.ALIGN_LEFT,
      priceAlignment: alignments.ALIGN_CENTER,

      buttonSize: sizes.XS,
      buttonEnterStyle: styles.PRIMARY,
    }
  },
  computed: {
    shouldDisplayPrice() {
      return this.price && !this.unavailable
    },
    shouldDisplayFeatures() {
      return this.features.length > 0
    },
    activeButtonsCount() {
      const truthyButtonConditions = [
        this.withCheckPriceButton,
        this.enterTo,
        this.tour360To,
        this.withEmbeddedFormButton,
      ].filter((value) => value)
      return truthyButtonConditions.length
    },
    shouldDisplayButtonsBlock() {
      return this.activeButtonsCount > 0
    },
    hasASingleButton() {
      return this.activeButtonsCount === 1
    },
    buttonTour360Style() {
      return this.hasASingleButton ? styles.PRIMARY : styles.LIGHT
    },
    buttonCheckPriceStyle() {
      return this.hasASingleButton ? styles.PRIMARY : styles.LIGHT
    },
    buttonEmbeddedContactFormsStyles() {
      return this.hasASingleButton ? styles.PRIMARY : styles.LIGHT
    },
    buttonQuoterStyle() {
      return this.hasASingleButton ? styles.PRIMARY : styles.LIGHT
    },
    showAvailabilityBadge() {
      return !!this.availabilityColor && !!this.availabilityStatus
    },
  },
  methods: {
    emitRequestPrice() {
      this.$emit('request-price')
    },
    emitLaunchQuoter() {
      this.$emit('launch-quoter')
    },
    emitLaunchEmbeddedContactForm() {
      this.$emit('launch-embedded-contact-form')
    },
  },
}
</script>
