<template>
  <AppSheetProjectElement
    :title="pointOfInterest.title"
    :subtitle="pointOfInterest.shortText"
    :text-alignment="textAlignment"
    :enter-to="pointOfInterestRouteObject"
    :exterior-tour360-to="exteriorTour360RouteObject"
  />
</template>

<script>
import AppSheetProjectElement from '@/components/UIKit/Standard/Organisms/AppSheet/AppSheetProjectElement'
import PointOfInterest from '@/classes/ProjectStructure/PointOfInterest'
import alignments from '@/constants/ui-kit/standard/alignments'
import ExteriorTour360RouteObjectGenerator from '@/classes/RouteObjectGenerators/ExteriorTour360RouteObjectGenerator'
import PointOfInterestRouteObjectGenerator from '@/classes/RouteObjectGenerators/PointOfInterestRouteObjectGenerator'

export default {
  name: 'PointOfInterestPopupContent',
  components: {
    AppSheetProjectElement,
  },
  props: {
    projectElement: {
      type: PointOfInterest,
      required: true,
    },
  },
  data() {
    return {
      textAlignment: alignments.ALIGN_LEFT,
    }
  },
  computed: {
    pointOfInterest() {
      return this.projectElement
    },
    hasExteriorTour360() {
      return !!this.pointOfInterest.exteriorTour360Url
    },
    hasGallery() {
      return !!this.pointOfInterest.gallery
    },
    pointOfInterestRouteObject() {
      return this.hasGallery
        ? PointOfInterestRouteObjectGenerator.make(this.pointOfInterest)
        : undefined
    },
    exteriorTour360RouteObject() {
      return this.hasExteriorTour360
        ? ExteriorTour360RouteObjectGenerator.make(this.pointOfInterest)
        : undefined
    },
  },
}
</script>
