<template>
  <validation-provider
    v-slot="{ errors }"
    ref="validationProvider"
    :mode="validationMode"
    tag="div"
    :rules="rules"
    class="input-styled-field"
    :class="type"
  >
    <div class="input-wrapper" :class="{ active, errors: !!errors.length }">
      <textarea
        v-if="type === 'textarea'"
        ref="area"
        class="field area"
        :name="name"
        :value="value"
        :placeholder="placeholder"
        :maxlength="maxlength"
        rows="1"
        @input="updateValue"
        @focus="focused = true"
        @blur="focused = false"
      >
      </textarea>
      <label v-else-if="type === 'checkbox'">
        <input
          class="field"
          :type="type"
          :name="name"
          :value="value"
          @input="updateValue"
          @focus="focused = true"
          @blur="focused = false"
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <g v-if="value" fill-rule="evenodd">
            <rect x="0.5" y="0.5" width="16" height="16" rx="1.5" />
            <path
              d="M3 9.084L6.849 12.94 14.239 5.557 13.178 4.511 6.849 10.833 4.046 8.03z"
            />
          </g>
          <rect v-else x="0.5" y="0.5" width="16" height="16" rx="1.5" />
        </svg>
        <!-- See end of file: slot styles do not get applied when styles are scoped and validation-provider is used -->
        <span class="base-input__slot">
          <slot></slot>
        </span>
      </label>
      <div v-else-if="type === 'select'" class="select">
        <select
          class="field"
          :class="{ placeholder: !value }"
          :name="name"
          :value="value"
          @change="updateValue"
          @focus="focused = true"
          @blur="focused = false"
        >
          <option disabled selected value="">{{ placeholder }}</option>
          <option v-for="option in options" :key="option" :value="option">{{
            option
          }}</option>
        </select>
        <div class="select-icon" aria-hidden="true">
          <AppIcon
            class="select-icon__img"
            :icon="selectIcon"
            :size="selectIconSize"
          />
        </div>
      </div>
      <input
        v-else
        class="field"
        :type="type"
        :name="name"
        :value="value"
        :placeholder="placeholder"
        @input="updateValue"
        @focus="focused = true"
        @blur="focused = false"
      />
      <p v-if="focused && !!errors.length" class="error-message">
        <text-wrapper :text="errors[0]"></text-wrapper>
      </p>
    </div>
    <p v-if="fieldInfo !== ''" class="field-info">{{ fieldInfo }}</p>
  </validation-provider>
</template>

<script>
import AppIcon from '@/components/UIKit/Standard/Atoms/AppIcon'
import { ValidationProvider, extend as validationExtend } from 'vee-validate'
import { required, email, max } from 'vee-validate/dist/rules'
import TextWrapper from '@/components/TextWrapper'
import sizes from '@/constants/ui-kit/standard/sizes'
import iconNames from '@/constants/ui-kit/standard/icon-names'

export default {
  name: 'BaseInput',
  components: {
    ValidationProvider,
    AppIcon,
    TextWrapper,
  },
  props: {
    value: {
      type: [String, Boolean],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    fieldInfo: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    maxlength: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      focused: false,
      selectIcon: iconNames.UIDropdown,
      selectIconSize: sizes.SM,
    }
  },
  computed: {
    active() {
      return this.focused || this.value
    },
  },
  watch: {
    value() {
      this.updateTextAreaSize()
    },
  },
  created() {
    validationExtend('required', {
      ...required,
      message: this.$t('form.validations.required'),
    })
    validationExtend('require-accept', {
      ...required,
      message: this.$t('form.validations.requireAcceptPrivacyPolicy'),
    })
    validationExtend('email', {
      ...email,
      validate: function(value) {
        const emailIsValid = email.validate(value)
        const emailHasAllASCIICharacters = /^[\040-\176]+$/.test(value)
        return emailIsValid && emailHasAllASCIICharacters
      },
      message: this.$t('form.validations.email'),
    })
    validationExtend('max', {
      ...max,
      params: ['length'],
      message: this.$t('form.validations.maxLength'),
    })
  },
  methods: {
    updateValue(e) {
      const value = this.type === 'checkbox' ? e.target.checked : e.target.value
      this.$emit('input', value)
      this.updateTextAreaSize()
    },
    updateTextAreaSize() {
      if (this.type === 'textarea') {
        this.$refs.area.style.height = `1em`
        if (this.value !== '') {
          this.$refs.area.style.height = `${this.$refs.area.scrollHeight + 2}px`
        }
      }
    },
    hasErrors() {
      return !!this.$refs.validationProvider.errors.length
    },
    validationMode({ errors }) {
      if (errors.length || this.type !== 'email') {
        return {
          on: ['input', 'change'],
        }
      }
      return {
        on: ['change'],
      }
    },
    focus() {
      const inputElement = this.$refs.validationProvider.$el.querySelector(
        'input,textarea,select,label'
      )
      inputElement.focus()
    },
  },
}
</script>
<style lang="scss" scoped>
.input-styled-field {
  margin-bottom: 1.625rem;

  &:last-child {
    margin: 0;
  }

  .field-info {
    text-align: right;
    font-size: 0.75rem;
    line-height: 1;
    color: var(--gray-500);
    padding: 0.4375rem 0;
  }
}

.input-wrapper {
  border-bottom: 1px solid var(--gray-100);
  position: relative;
  transition: border-color 200ms ease-out;

  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    width: 0;
    background-color: var(--gray-100);
    transition: all 200ms ease-out;
    bottom: -2px;
  }

  &.active {
    &::after {
      width: 100%;
      background-color: var(--primary-color);
    }
    &.errors {
      &::after {
        background-color: var(--form-error-color);
      }
    }
  }

  .field {
    display: block;
    width: 100%;
    font-size: 0.875rem;
    font-family: var(--font-family);
    font-weight: var(--font-weight-medium);
    color: var(--gray-700);
    padding: 0.375rem 0 0.25em;
    border: 0;

    &::placeholder,
    &.placeholder {
      color: var(--gray-500);
      font-weight: var(--font-weight-regular);
      opacity: 1;
    }

    &:focus {
      outline: 0;
    }

    &:focus::placeholder {
      color: var(--gray-300);
    }
  }
  .area {
    height: 2.5em;
    min-height: 2.5em;
    resize: none;
    transition: margin 200ms ease-out;

    &:not(:placeholder-shown) {
      margin-bottom: 0.5em;
    }
  }

  &.errors {
    border-color: var(--form-error-color);
    .field {
      color: var(--form-error-color);
      box-shadow: none;
      &::placeholder {
        color: var(--form-error-color);
      }
    }
  }

  .error-message {
    color: var(--form-error-color);
    font-size: 0.75rem;
    line-height: 1.125;
    padding: 0.4375rem 0;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 2;
    animation: error 100ms ease-in-out;
  }

  @keyframes error {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }
}

.checkbox {
  .input-wrapper {
    border: 0;
    &::after {
      display: none;
    }

    label {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: 0.75rem;
      font-size: 0.875rem;
      font-weight: var(--font-weight-regular);
      color: var(--gray-500);

      svg {
        fill: transparent;
        stroke: var(--gray-700);
        transition: all 200ms ease-out;
      }

      .base-input__slot {
        user-select: none;
        flex: 1;

        &::v-deep a {
          color: inherit;
          text-decoration: underline;
        }
      }
    }
    &.active,
    &:hover {
      label svg {
        stroke: var(--primary-color);
      }
    }

    &.active {
      label svg path {
        fill: var(--primary-color);
      }
    }

    input {
      position: absolute;
      left: -9999px;
    }

    &.errors {
      label svg {
        stroke: var(--form-error-color);
      }
    }
  }
}

.select {
  position: relative;

  .field {
    -webkit-appearance: none;
    background-color: white;
  }

  option {
    font-family: var(--font-family);
    color: var(--black);
  }

  .select-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: #fff;
    width: 25px;
    height: 100%;
    right: 0;
    top: 0;
    pointer-events: none;
    padding-top: 0.3em;
  }
}
</style>
