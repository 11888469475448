<template>
  <AppSheetWrapper class="popup-content" is-popup fit-content>
    <AppSheetRow>
      <AppSheetTitle :title="title" />
    </AppSheetRow>
  </AppSheetWrapper>
</template>

<script>
import AppSheetWrapper from '@/components/UIKit/Standard/Atoms/AppSheetWrapper'
import AppSheetRow from '@/components/UIKit/Standard/Atoms/AppSheetRow'
import AppSheetTitle from '@/components/UIKit/Standard/Atoms/AppSheetTitle'

export default {
  name: 'AppSheetMinimalProjectElement',
  components: {
    AppSheetWrapper,
    AppSheetRow,
    AppSheetTitle,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>
