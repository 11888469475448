const LogOnlyErrorsLoggingService = require('./LogOnlyErrorsLoggingService')
const LogAllLoggingService = require('./LogAllLoggingService')

module.exports = class LoggerServiceProvider {
  constructor() {
    this.providedService = process.env.VUE_APP_LOGGING_SERVICE
    this.availableServices = {
      LogOnlyErrorsLoggingService,
      LogAllLoggingService,
    }
  }
  makeService() {
    const ServiceToUse = this.availableServices[this.providedService]
    try {
      return new ServiceToUse()
    } catch (error) {
      throw new Error(
        `LoggingServiceFactory: There was a problem creating a LoggingService with key ${this.providedService} from settings: ${error}`
      )
    }
  }

  validateEnvVariables() {
    if (!Object.keys(this.availableServices).includes(this.providedService)) {
      throw new Error(
        `LoggingServiceFactory: selected generator "${this.providedService}" does not exist`
      )
    }
  }
}
