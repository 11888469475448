import VueDependency from '@/classes/VueDependency'
import I18nManager from '@/i18n/I18nManager'
import VueI18n from 'vue-i18n'

class I18nVueDependency extends VueDependency {
  constructor(localeCode, defaultLocaleCode) {
    super()
    this.localeCode = localeCode
    this.defaultLocaleCode = defaultLocaleCode
  }
  // eslint-disable-next-line no-unused-vars
  async install(Vue, router) {
    Vue.use(VueI18n)
    this.i18nManager = new I18nManager({
      localeCode: this.localeCode,
      defaultLocaleCode: this.defaultLocaleCode,
    })
    this.i18nManager.setup()
  }
  get vueGlobalKeyValue() {
    return {
      key: 'i18n',
      value: this.library,
    }
  }
  get library() {
    return this.i18nManager.library
  }
}
export default I18nVueDependency
