<template>
  <div class="unit-card-badges">
    <p v-if="shouldDisplayTypeBadge" class="unit-card-badges__badge">
      {{ typeBadgeText }}
    </p>
    <p
      v-if="shouldDisplayAvailabilityBadge"
      class="unit-card-badges__badge"
      :style="availabilityBadgeStyle"
    >
      {{ availabilityBadgeText }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'AppCardBadges',
  props: {
    typeBadgeText: {
      type: String,
      default: () => undefined,
    },
    availabilityBadgeText: {
      type: String,
      default: () => undefined,
    },
    availabilityBadgeColor: {
      type: String,
      default: () => undefined,
    },
  },
  computed: {
    shouldDisplayTypeBadge() {
      return !!this.typeBadgeText
    },
    shouldDisplayAvailabilityBadge() {
      return !!this.availabilityBadgeText && !!this.availabilityBadgeColor
    },
    availabilityBadgeStyle() {
      return { color: this.availabilityBadgeColor }
    },
  },
}
</script>

<style lang="scss" scoped>
.unit-card-badges {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;

  @at-root &__badge {
    font-size: 0.75rem;
    font-weight: var(--font-weight-regular);
    line-height: 1.5;
    color: var(--gray-400);
    margin: 0;

    &::before {
      content: '-';
      padding: 0 0.375rem;
    }
    &:last-child::before {
      content: none;
    }
  }
}
</style>
