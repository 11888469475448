import ProjectElementTypes from '@/constants/project-structure/project-element-types'
import BaseProjectElement from '@/classes/ProjectStructure/BaseProjectElement'
import EnterTransitionVideos from '@/classes/EnterTransitionVideos'

export default class Tower extends BaseProjectElement {
  constructor({
    id,
    order,
    name,
    slug,
    svgTargetElementId,
    logo,
    exteriorTour360Url,
  }) {
    super({ id, svgTargetElementId })
    this._order = order
    this._name = name
    this._slug = slug
    this._logo = logo
    this._exteriorTour360Url = exteriorTour360Url
    this._enterTransitionVideos = new EnterTransitionVideos()
  }
  sortLevels() {
    this.levels.sort((levelA, levelB) => {
      return levelB.order - levelA.order
    })
  }
  get order() {
    return this._order
  }
  get name() {
    return this._name
  }
  get slug() {
    return this._slug
  }
  get logo() {
    return this._logo
  }
  get enterTransitionVideos() {
    return this._enterTransitionVideos
  }
  get exteriorTour360Url() {
    return this._exteriorTour360Url
  }
  get levels() {
    return this.childrenOfType(ProjectElementTypes.LEVEL)
  }
  get topLevel() {
    return this.levels[0]
  }
  get projectElementType() {
    return ProjectElementTypes.TOWER
  }
  get _validChildElementTypes() {
    return [ProjectElementTypes.LEVEL]
  }
}
