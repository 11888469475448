import PointOfInterest from '@/classes/ProjectStructure/PointOfInterest'
import SlugGenerator from '@/classes/SlugGenerator'

class PointOfInterestRouteObjectGenerator {
  static get ROUTE_NAME() {
    return 'point-of-interest'
  }
  static make(pointOfInterest, customParams) {
    if (!(pointOfInterest instanceof PointOfInterest)) {
      throw new Error(
        'PointOfInterestRouteObjectGenerator.make(): passed pointOfInterest argument is not an instance of PointOfInterest class.'
      )
    }
    if (!pointOfInterest.gallery) {
      throw new Error(
        'PointOfInterestRouteObjectGenerator.make(): the passed pointOfInterest does not have a gallery.'
      )
    }

    const overridableParams = {
      resourceId: '1',
    }
    return {
      name: PointOfInterestRouteObjectGenerator.ROUTE_NAME,
      params: {
        ...overridableParams,
        ...customParams,
        descriptiveSlug: SlugGenerator.make(pointOfInterest),
        pointOfInterestId: pointOfInterest.id,
      },
    }
  }
}
export default PointOfInterestRouteObjectGenerator
