<template>
  <transition name="menu-slide">
    <div v-show="isOpen" ref="levelsMenu" class="levels-menu">
      <AppButtonIcon
        :icon="closeIcon"
        :size="closeIconSize"
        :button-style="closeIconStyle"
        @click.native="hide"
      />
      <AppScrollbarContainer class="levels-menu__links">
        <AppButtonTextRouterLink
          v-for="level in levelMenuItems"
          :key="level.displayName"
          :text="level.displayName"
          :to="level.routeObject"
          :button-style="setButtonStyle(level.routeObject)"
          class="link-item"
        />
      </AppScrollbarContainer>
    </div>
  </transition>
</template>

<script>
import AppButtonIcon from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonIcon'
import AppScrollbarContainer from '@/components/UIKit/Standard/Atoms/AppScrollbarContainer'
import AppButtonTextRouterLink from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonTextRouterLink'

import styles from '@/constants/ui-kit/standard/styles'
import sizes from '@/constants/ui-kit/standard/sizes'
import iconNames from '@/constants/ui-kit/standard/icon-names'

export default {
  name: 'AppLevelsMenu',
  components: {
    AppScrollbarContainer,
    AppButtonIcon,
    AppButtonTextRouterLink,
  },
  props: {
    startCollapsed: {
      type: Boolean,
      default: false,
    },
    levelMenuItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      closeIcon: iconNames.UIClose,
      closeIconStyle: styles.DARK,
      closeIconSize: sizes.XS,

      isOpen: false,
    }
  },
  mounted() {
    this.updateWidth()
  },
  updated() {
    this.updateWidth()
  },
  methods: {
    show() {
      this.isOpen = true
      this.$emit('visibility-change', true)
    },
    hide() {
      this.isOpen = false
      this.$emit('visibility-change', false)
    },
    updateWidth() {
      const levelsMenuWidth = this.isOpen
        ? this.$refs.levelsMenu.clientWidth
        : 0
      this.$emit('width-updated', levelsMenuWidth)
    },
    setButtonStyle(link) {
      const resolvedLink = this.$router.resolve(link)
      const isCurrentlySelected = this.$route.path === resolvedLink.route.path
      return isCurrentlySelected ? styles.EXTRA_LIGHT : styles.DARK
    },
  },
}
</script>

<style lang="scss" scoped>
.levels-menu {
  position: fixed;
  top: 0;
  right: 0;
  min-width: 85px;
  @include inner-height;
  background: var(--gray-700);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  gap: 1.5rem;
  @at-root #{&}__links {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    padding: 8px;
    .link-item {
      display: block;
      font-weight: 400;
    }
  }
  @include small-screens-vertical {
    min-width: 95px;
  }
}

.menu-slide-enter-active,
.menu-slide-leave-active {
  transition: transform 400ms cubic-bezier(0.15, 0, 0, 1);
}
.menu-slide-enter,
.menu-slide-leave-to {
  transform: translate3d(100%, 0, 0);
}
.menu-slide-leave-to {
  transition: transform 400ms ease;
}
</style>
