const LoggingService = require('./LoggingService')

class LogAllLoggingService extends LoggingService {
  get logError() {
    return console.error
  }
  get logWarning() {
    return console.warn
  }
  get logInfo() {
    return console.info
  }
  get logMessage() {
    return console.log
  }
}
module.exports = LogAllLoggingService
