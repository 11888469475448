import ProjectConstants from '@/constants/project-structure/project-constants'
import SingleTowerProject from './SingleTowerProject'
import MultitowerProject from './MultitowerProject'
import NeighbourhoodProject from './NeighbourhoodProject'

export default class ProjectRootElementFactory {
  constructor() {
    const {
      MULTITOWER_TYPE,
      SINGLETOWER_TYPE,
      NEIGHBOURHOOD_TYPE,
    } = ProjectConstants.PROJECT_TYPES

    this.availableProjectTypes = {
      [SINGLETOWER_TYPE]: SingleTowerProject,
      [MULTITOWER_TYPE]: MultitowerProject,
      [NEIGHBOURHOOD_TYPE]: NeighbourhoodProject,
    }
  }
  makeProjectRootElement(projectStructureData) {
    const projectType = projectStructureData?.type
    const ProjectRootElementToUse = this.availableProjectTypes[projectType]
    if (!ProjectRootElementToUse) {
      throw new Error(
        `ProjectRootElementFactory: Invalid projectType ${projectType}`
      )
    }
    try {
      const exteriorTour360Url =
        projectStructureData.exteriorTour360?.tour360Url
      return new ProjectRootElementToUse({ exteriorTour360Url })
    } catch (error) {
      throw new Error(
        `ProjectRootElementFactory: There was a problem creating ProjectRootElement of unitType ${projectType}: ${error}`
      )
    }
  }
}
