<template>
  <div class="quoter-pdf-content">
    <div>
      <div class="quoter-pdf-content__article">
        <h2>{{ $t('Cotización de unidad') }}</h2>
        <div class="layout">
          <div class="column">
            <AppUnitCard :unit="unit" disable-tour360-icon />
            <div class="info">
              <AppQuoterUserInfo :user-info="userInfo" />
            </div>
          </div>
          <div class="column quote">
            <section class="frame">
              <h3>
                <span>{{ $t('Esquema de pago:') }} </span
                >{{ paymentMethodName }}
              </h3>
              <AppQuoterResultTable :quote-table-data="quoteTableData" />
            </section>
            <section v-if="shouldDisplayInstallments" class="frame">
              <h3>{{ $t('Detalle de cuotas') }}</h3>
              <AppQuoterResultInstallmentsTable
                :quote-installments-table-data="quoteInstallmentsTableData"
              />
            </section>
          </div>
        </div>
      </div>
      <img
        v-if="logoImageUrl"
        class="quoter-pdf-content__image"
        :src="logoImageUrl"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AppQuoterUserInfo from '@/components/UIKit/Standard/Atoms/AppQuoterUserInfo'
import AppQuoterResultTable from '@/components/UIKit/Standard/Atoms/AppQuoterResultTable'
import AppQuoterResultInstallmentsTable from '@/components/UIKit/Standard/Atoms/AppQuoterResultInstallmentsTable'
import AppUnitCard from '@/components/UIKit/Standard/Organisms/AppUnitCard'
import Unit from '@/classes/ProjectStructure/Unit/Unit'

export default {
  name: 'AppQuoterPDFContent',
  components: {
    AppQuoterUserInfo,
    AppQuoterResultTable,
    AppQuoterResultInstallmentsTable,
    AppUnitCard,
  },
  props: {
    unit: {
      type: Unit,
      required: true,
    },
    userInfo: {
      type: Object,
      required: true,
    },
    quoteData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      logoData: 'Project/logo',
    }),
    logoImageUrl() {
      return this.logoData.display ? this.logoData.imgUrl : undefined
    },
    quoteTableData() {
      return this.quoteData.quoteTableData
    },
    quoteInstallmentsTableData() {
      return this.quoteData.quoteInstallmentsTableData
    },
    shouldDisplayInstallments() {
      return !!this.quoteInstallmentsTableData
    },
    paymentMethodName() {
      return this.quoteData.paymentMethodName
    },
  },
}
</script>

<style lang="scss" scoped>
.quoter-pdf-content {
  &__article {
    border-radius: 8px;

    width: 800px;
    min-height: 800px;
    margin: 0 auto;
    background-color: var(--gray-50);
    padding: 60px 30px 30px;

    h2 {
      font-size: 1.125rem;
      color: var(--gray-700);
      padding-bottom: 1rem;
      margin-bottom: 1.5rem;
      border-bottom: 1px solid var(--gray-200);
    }

    h3 {
      font-size: 0.875rem;
      margin: 1.5rem 0;
      line-height: 1.5;

      span {
        display: block;
        font-weight: var(--font-weight-regular);
        color: var(--gray-500);
      }
    }
  }
  &__image {
    display: block;
    height: 75px;
    width: auto;
    margin: 40px auto 0;
  }
}
h2,
.info {
  margin: 0 0.5rem;
}
.layout {
  display: flex;
  gap: 12px;

  .column {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 40%;
  }

  .column.quote {
    flex: 1;
    width: 60%;
  }
}
.frame {
  border-radius: 8px;
  border: 1px solid var(--gray-100);
  background-color: var(--white);
  padding: 12px;
}
</style>
