<template>
  <component :is="tag">
    {{ beforeEndingWordGroup }}
    <span class="word-group">{{ endingWordGroup }}</span>
  </component>
</template>

<script>
export default {
  name: 'TextWrapper',
  props: {
    text: {
      type: String,
      required: true,
    },
    tag: {
      type: String,
      default: 'span',
    },
    endingWordGroupSize: {
      type: Number,
      default: 2,
      validator: (value) => value >= 1,
    },
  },
  computed: {
    beforeEndingWordGroup() {
      const words = this.text.trim().split(' ')
      words.splice(-this.endingWordGroupSize)
      return words.join(' ')
    },
    endingWordGroup() {
      const words = this.text.trim().split(' ')
      return words.splice(-this.endingWordGroupSize).join(' ')
    },
  },
}
</script>

<style lang="scss" scoped>
.word-group {
  display: inline-block;
}
</style>
