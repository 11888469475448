var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui-kit"},[_c('div',{staticClass:"ui-kit__components-menu"},[_c('h1',[_vm._v("UI Kit: Standard")]),_vm._l((_vm.viewerComponents),function(viewerComponentsGroup){return _c('div',{key:viewerComponentsGroup.category},[_c('h2',[_vm._v(_vm._s(viewerComponentsGroup.category))]),_c('ul',_vm._l((viewerComponentsGroup.components),function(viewerComponent,viewerComponentName){return _c('li',{key:viewerComponentName},[_c('router-link',{staticClass:"ui-kit__components-menu__link",attrs:{"to":{
              name: 'ui-kit-viewer',
              params: { componentName: viewerComponentName },
            }}},[_vm._v(" "+_vm._s(viewerComponentName)+" ")])],1)}),0)])})],2),_c('div',{staticClass:"ui-kit__content",class:[`ui-kit__content--${_vm.currentBackgroundTheme}`]},[_c(_vm.componentName,{tag:"component"}),_c('div',{staticClass:"ui-kit__color-menu"},[_c('ul',_vm._l((_vm.backgroundThemes),function(backgroundTheme){return _c('li',{key:backgroundTheme},[_c('button',{class:[
              backgroundTheme,
              { current: _vm.currentBackgroundTheme === backgroundTheme },
            ],on:{"click":function($event){_vm.currentBackgroundTheme = backgroundTheme}}},[_vm._v(" "+_vm._s(backgroundTheme)+" ")])])}),0)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }