import VueDependency from '@/classes/VueDependency'
import VueGtag from 'vue-gtag'

class GoogleAnalyticsVueDependency extends VueDependency {
  async install(Vue, router) {
    Vue.use(VueGtag, { bootstrap: false }, router)
  }
  get vueGlobalKeyValue() {
    return null
  }
}
export default GoogleAnalyticsVueDependency
