<template>
  <div v-click-outside="collapse" class="dropdown">
    <AppDropdownPlaceholder :options="options" :size="size" />
    <AppButtonTextWithIcon
      class="dropdown__button"
      :text="selectedOption.text"
      :size="size"
      :button-style="dropdownStyle"
      :icon="downIcon"
      :disabled="disabled"
      @click.native="toggleCollapse"
    />
    <transition name="dropdown-list">
      <AppDropdownList
        v-if="!collapsed"
        class="dropdown__list"
        :options="options"
        :selected-option="selectedOption"
        :size="size"
        :dropdown-style="dropdownListStyle"
        @option-clicked="setSelectedOption"
      />
    </transition>
  </div>
</template>

<script>
import AppButtonTextWithIcon from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonTextWithIcon'
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'
import iconNames from '@/constants/ui-kit/standard/icon-names'
import AppDropdownList from '@/components/UIKit/Standard/Atoms/AppDropdownList'
import AppDropdownPlaceholder from '@/components/UIKit/Standard/Molecules/AppDropdownPlaceholder'

export default {
  name: 'AppDropdown',
  components: {
    AppButtonTextWithIcon,
    AppDropdownList,
    AppDropdownPlaceholder,
  },
  props: {
    dropdownStyle: {
      type: String,
      default: styles.DARK,
      validator: (value) => Object.values(styles).includes(value),
    },
    size: {
      type: String,
      default: sizes.SM,
      validator: (value) => Object.values(sizes).includes(value),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: true,
      validator: function(array) {
        return array.every((item) => !!item.value && !!item.text)
      },
    },
    selectedValue: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      downIcon: iconNames.UIDropdown,
      dropdownListStyle: styles.LIGHT,
      collapsed: true,
    }
  },
  computed: {
    selectedOption() {
      return this.options.find((option) => option.value === this.selectedValue)
    },
  },
  methods: {
    toggleCollapse() {
      this.collapsed = !this.collapsed
    },
    collapse() {
      this.collapsed = true
    },
    setSelectedOption(selectedValue) {
      this.collapsed = true
      this.$emit('selectedOptionChange', selectedValue)
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown {
  max-width: min(220px, calc(100vw - 4.25rem));
  position: relative;

  @at-root &__button {
    width: 100%;
  }
  @at-root &__list {
    position: absolute;
    margin-top: 5px;
    z-index: 10;
    width: calc(100% + 2.25rem);
  }
}

.dropdown-list-enter-active,
.dropdown-list-leave-active {
  transition: transform 300ms cubic-bezier(0.25, 0.1, 0.5, 1),
    opacity 200ms cubic-bezier(0.25, 0.1, 0.5, 1);
}
.dropdown-list-enter,
.dropdown-list-leave-to {
  opacity: 0;
}
.dropdown-list-enter {
  transform: translate3d(0, -10px, 0);
}
.dropdown-list-leave-active {
  transition: opacity 150ms ease-out;
}
</style>
