<template>
  <FullViewportBackgroundElement class="background-vimeo-video-displayer">
    <iframe :key="iframeKey" :src="vimeoIframeSource" frameborder="0"></iframe>
  </FullViewportBackgroundElement>
</template>

<script>
import { mapGetters } from 'vuex'
import FullViewportBackgroundElement from '@/components/Gadgets/FullViewportBackgroundElement'

export default {
  name: 'BackgroundVimeoVideoDisplayer',
  components: {
    FullViewportBackgroundElement,
  },
  data() {
    return {
      vimeoVideoSettings: {
        background: true,
        dnt: true,
      },
      iframeKey: 'default',
    }
  },
  computed: {
    ...mapGetters({
      introVimeoVideoId: 'Project/introVimeoVideoId',
    }),
    vimeoSettingsQueryString() {
      return Object.keys(this.vimeoVideoSettings)
        .map((key) => `${key}=${this.vimeoVideoSettings[key]}`)
        .join('&')
    },
    vimeoIframeSource() {
      return `https://player.vimeo.com/video/${this.introVimeoVideoId}/?${this.vimeoSettingsQueryString}`
    },
  },
  mounted() {
    document.addEventListener('visibilitychange', this.reloadIframe)
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.reloadIframe)
  },
  methods: {
    reloadIframe() {
      /*
        Due to an iPhone bug is neccesary to reload the iframe when tab switches
        Bug is on Safari & Chrome in iPhone
        https://graff3d.atlassian.net/browse/W3D-4593
      */
      const isUnsupportedDevice = /(iPhone)/i.test(navigator.userAgent)
      if (document.visibilityState === 'visible' && isUnsupportedDevice) {
        this.iframeKey = `reloaded-iframe-key-${Date.now()}`
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.background-vimeo-video-displayer {
  position: fixed;
  background: var(--gray-900);
}
</style>
