import httpClient from '@/constants/api/http-client'
import JsonUrlPathGeneratorFactory from '@/classes/JsonUrlPathGenerator'
import LoggingServiceFactory from '@/classes/LoggingService/LoggingServiceFactory'

class JsonResourcesProvider {
  constructor() {
    this.loggingService = new LoggingServiceFactory().makeService()
  }
  async getProjectAndAvailabilityData() {
    const jsonUrlPathGenerator = new JsonUrlPathGeneratorFactory().makeGenerator()
    const projectData = await this._fetchProjectData(jsonUrlPathGenerator)
    let availabilityData = undefined
    if (projectData) {
      availabilityData = await this._fetchAvailabilityData(jsonUrlPathGenerator)
    }
    return { projectData, availabilityData }
  }
  async _fetchProjectData(jsonUrlPathGenerator) {
    let projectData = undefined
    try {
      const projectJsonUrl = jsonUrlPathGenerator.getJsonUrlPath(
        'project.json',
        window.location
      )
      projectData = (await httpClient(projectJsonUrl)).data
    } catch (err) {
      projectData = null
      this.loggingService.logError(
        `There was a problem downloading project.json: ${err.toString()}`
      )
    }
    return projectData
  }
  async _fetchAvailabilityData(jsonUrlPathGenerator) {
    let availabilityData = undefined
    try {
      const availabilityJsonUrl = jsonUrlPathGenerator.getJsonUrlPath(
        'availability.json',
        window.location
      )
      availabilityData = (await httpClient(availabilityJsonUrl)).data
    } catch (err) {
      availabilityData = null
      this.loggingService.logError(
        `Error while fetching availabilityJSON: ${err.toString()}`
      )
    }
    return availabilityData
  }
}
export default JsonResourcesProvider
