import ProjectElementTypes from '@/constants/project-structure/project-element-types'
import BaseProjectElement from '@/classes/ProjectStructure/BaseProjectElement'
import Gallery from '@/classes/Gallery'
export default class PointOfInterest extends BaseProjectElement {
  constructor({
    id,
    svgTargetElementId,
    title,
    shortText,
    gallery,
    longRichText,
    exteriorTour360Url,
    pointOfInterestSlug,
  }) {
    super({ id, svgTargetElementId })
    this._title = title
    this._shortText = shortText

    if (gallery instanceof Gallery) {
      if (
        typeof pointOfInterestSlug !== 'string' ||
        pointOfInterestSlug.trim === ''
      ) {
        throw new Error(
          `PointOfInterest.constructor(): pointOfInterestSlug passed is invalid for a POI with gallery`
        )
      }

      if (typeof longRichText !== 'string' || longRichText.trim === '') {
        throw new Error(
          `PointOfInterest.constructor(): longRichText passed is invalid for a POI with gallery`
        )
      }
    }
    this._gallery = gallery
    this._longRichText = longRichText
    this._exteriorTour360Url = exteriorTour360Url
    this._slug = pointOfInterestSlug
  }

  get title() {
    return this._title
  }
  get shortText() {
    return this._shortText
  }
  get longRichText() {
    return this._longRichText
  }
  get exteriorTour360Url() {
    return this._exteriorTour360Url
  }
  get slug() {
    return this._slug
  }
  get gallery() {
    return this._gallery
  }
  get previewImageUrl() {
    if (this.gallery) {
      const resource = this.gallery.defaultCollection.getResource(0)

      return resource.imgUrl
    }

    return undefined
  }
  get projectElementType() {
    return ProjectElementTypes.POINT_OF_INTEREST
  }
  get _validChildElementTypes() {
    return []
  }
}
