import BaseUnitQuoteFileNameGenerator from './BaseUnitQuoteFileNameGenerator'
import NeighbourhoodSubdivision from '@/classes/ProjectStructure/NeighbourhoodSubdivision'
import Unit from '@/classes/ProjectStructure/Unit/Unit'

class NeighbourhoodUnitQuoteFileNameGenerator extends BaseUnitQuoteFileNameGenerator {
  _getTranslationArguments(unit) {
    const subdivision = unit.parent()
    return {
      unitId: unit.label,
      subdivision: subdivision.name,
      name: unit.name,
    }
  }
  _validateUnit(unit) {
    if (!(unit instanceof Unit)) {
      throw new Error(
        'NeighbourhoodUnitQuoteFileNameGenerator._validateUnit(): passed unit argument is not an instance of Unit class.'
      )
    }
    const subdivision = unit.parent()
    if (!(subdivision instanceof NeighbourhoodSubdivision)) {
      throw new Error(
        'NeighbourhoodUnitQuoteFileNameGenerator._validateUnit(): Parent of unit is not an instance of NeighbourhoodSubdivision class.'
      )
    }
  }
  get _localeGroupKey() {
    return 'neighbourhoodUnitQuoteFileNames'
  }
}

export default NeighbourhoodUnitQuoteFileNameGenerator
