<template>
  <AppSheetWrapper class="sheet-content-unit" is-popup>
    <AppSheetRow v-if="shouldDisplayAvailabilityBadge">
      <AppBadge
        class="sheet-content-unit__availability"
        :text="availabilityStatus"
        :color="availabilityColor"
      />
    </AppSheetRow>
    <AppSheetRow>
      <AppSheetTitle :title="title" :align="titleAlignment" />
    </AppSheetRow>
    <AppSheetRow v-if="withUnavailableMessage">
      <AppSheetAvailabilityText
        :availability-text="$t('Esta unidad ya no está disponible')"
      />
    </AppSheetRow>
    <AppSheetRow
      v-if="shouldDisplayPrice"
      divider-top
      divider-bottom
      background
    >
      <AppSheetPrice :price="price" :align="priceAlignment" />
    </AppSheetRow>
    <AppSheetRow v-if="shouldDisplayFeatures" separator-top separator-bottom>
      <AppFeatureListSmall :features="features" />
    </AppSheetRow>
    <AppSheetRow>
      <AppSheetBlockButtonGroup>
        <AppButtonText
          v-if="withEmbeddedFormButton"
          :text="embeddedContactFormButtonText"
          :size="buttonSize"
          :button-style="buttonExteriorTour360Style"
          @click.native.stop="emitLaunchEmbeddedContactForm"
        />
        <AppButtonText
          v-if="withCheckPriceButton"
          :text="$t('Consultar precio')"
          :size="buttonSize"
          :button-style="buttonExteriorTour360Style"
          @click.native.stop="emitRequestPrice"
        />
        <AppButtonText
          v-if="withQuoterButton"
          :text="$t('Cotizar')"
          :size="buttonSize"
          :button-style="buttonExteriorTour360Style"
          @click.native.stop="emitLaunchQuoter"
        />
        <AppButtonTextRouterLink
          v-if="tour360To"
          :text="$t('Tour 360º')"
          :size="buttonSize"
          :button-style="buttonExteriorTour360Style"
          :to="tour360To"
        />
        <AppButtonTextRouterLink
          v-if="enterTo"
          :text="$t('Ingresar')"
          :size="buttonSize"
          :button-style="buttonEnterStyle"
          :to="enterTo"
        />
      </AppSheetBlockButtonGroup>
    </AppSheetRow>
  </AppSheetWrapper>
</template>

<script>
import AppSheetWrapper from '@/components/UIKit/Standard/Atoms/AppSheetWrapper'
import AppSheetRow from '@/components/UIKit/Standard/Atoms/AppSheetRow'
import AppSheetBlockButtonGroup from '@/components/UIKit/Standard/Molecules/AppSheetBlockButtonGroup'
import AppSheetAvailabilityText from '@/components/UIKit/Standard/Atoms/AppSheetAvailabilityText'
import AppSheetTitle from '@/components/UIKit/Standard/Atoms/AppSheetTitle'
import AppButtonTextRouterLink from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonTextRouterLink'
import AppButtonText from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonText'
import alignments from '@/constants/ui-kit/standard/alignments'
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'
import AppBadge from '@/components/UIKit/Standard/Atoms/AppBadge'
import AppSheetPrice from '@/components/UIKit/Standard/Atoms/AppSheetPrice'
import AppFeatureListSmall from '@/components/UIKit/Standard/Molecules/AppFeatureListSmall'
import Feature from '@/classes/Feature/Feature'
import BasePrice from '@/classes/Price/BasePrice'

export default {
  name: 'AppSheetUnit',
  components: {
    AppSheetWrapper,
    AppSheetRow,
    AppSheetBlockButtonGroup,
    AppSheetAvailabilityText,
    AppSheetTitle,
    AppBadge,
    AppSheetPrice,
    AppButtonTextRouterLink,
    AppButtonText,
    AppFeatureListSmall,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    enterTo: {
      type: Object,
      required: true,
    },
    tour360To: {
      type: Object,
      default: () => undefined,
    },
    features: {
      type: Array,
      required: true,
      validator: (value) =>
        value.every((feature) => feature instanceof Feature),
    },
    availabilityStatus: {
      type: String,
      default: () => undefined,
    },
    availabilityColor: {
      type: String,
      default: () => undefined,
    },
    price: {
      type: BasePrice,
      default: () => undefined,
    },
    embeddedContactFormButtonText: {
      type: String,
      default: undefined,
    },
    withUnavailableMessage: {
      type: Boolean,
      default: false,
    },
    withCheckPriceButton: {
      type: Boolean,
      default: false,
    },
    withQuoterButton: {
      type: Boolean,
      default: false,
    },
    withEmbeddedFormButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      titleAlignment: alignments.ALIGN_CENTER,
      priceAlignment: alignments.ALIGN_CENTER,
      buttonSize: sizes.XS,
      buttonEnterStyle: styles.PRIMARY,
      buttonExteriorTour360Style: styles.LIGHT,
    }
  },
  computed: {
    shouldDisplayPrice() {
      return this.price && !this.unavailable
    },
    shouldDisplayAvailabilityBadge() {
      return !!this.availabilityColor && !!this.availabilityStatus
    },
    shouldDisplayFeatures() {
      return this.features.length > 0
    },
  },
  methods: {
    emitRequestPrice() {
      this.$emit('request-price')
    },
    emitLaunchQuoter() {
      this.$emit('launch-quoter')
    },
    emitLaunchEmbeddedContactForm() {
      this.$emit('launch-embedded-contact-form')
    },
  },
}
</script>

<style lang="scss" scoped>
.sheet-content-unit {
  position: relative;
  @at-root &__availability {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
}
</style>
