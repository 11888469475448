<template>
  <span class="-badge" :style="`background-color: ${backgroundColor};`">
    <span
      class="-badge__bullet"
      :style="`background-color: ${mainColor};`"
    ></span>
    <span class="-badge__text" :style="`color: ${mainColor};`">{{ text }}</span>
  </span>
</template>

<script>
import ColorPallete from '@/classes/ColorPallete'

export default {
  name: 'AppBadge',
  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      colorPallete: null,
    }
  },
  computed: {
    mainColor() {
      return this.colorPallete?.regular
    },
    backgroundColor() {
      return this.colorPallete?.variant('25')
    },
  },
  created() {
    this.colorPallete = new ColorPallete(this.color)
  },
}
</script>

<style lang="scss" scoped>
.-badge {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  font-size: 0.625rem;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  line-height: 1;
  padding: 0.375rem 5px;
  border-radius: 0.6875rem;

  @at-root &__bullet {
    display: block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    margin: 0 2px;
  }

  @at-root &__text {
    display: block;
    margin: 0 4px;
  }
}
</style>
