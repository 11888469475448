<template>
  <AppBaseModal :modal-width="455" v-on="$listeners">
    <AppConfirmationMessage />
  </AppBaseModal>
</template>

<script>
import AppBaseModal from '@/components/UIKit/Standard/Atoms/AppBaseModal'
import AppConfirmationMessage from '@/components/UIKit/Standard/Atoms/AppConfirmationMessage'

export default {
  name: 'AppConfirmationMessageModal',
  components: {
    AppConfirmationMessage,
    AppBaseModal,
  },
}
</script>
