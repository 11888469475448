import BaseApiEndpoints from './BaseApiEndpoints'
import * as contactApiEndpointNames from '@/constants/api/contact-api-endpoint-names'

class ContactApiEndpoints extends BaseApiEndpoints {
  constructor() {
    super()
    this._className = 'ContactApiEndpoints'
    this._endopointUrls = {
      [contactApiEndpointNames.GENERAL]: '/api/contact/general/',
      [contactApiEndpointNames.UNIT]: '/api/contact/unit/',
      [contactApiEndpointNames.UNIT_PRICE_QUOTATION_FIRST_STEP]:
        '/api/contact/unit/with-payment-options/first-step/',
      [contactApiEndpointNames.UNIT_PRICE_QUOTATION_SECOND_STEP]:
        '/api/contact/unit/with-payment-options/second-step/',
    }
  }
}
export default ContactApiEndpoints
