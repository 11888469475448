<template>
  <div class="project-element-popup-displayer">
    <transition name="popup">
      <AppPopupWrapper
        v-if="showPopup"
        ref="popupElement"
        :key="svgElementToPreview.id"
        :style="{
          top: `${centeredAndCompensatedPopupPositionY}px`,
          left: `${centeredAndCompensatedPopupPositionX}px`,
        }"
        @mouseenter.native="onEnterPopup"
        @mouseleave.native="onLeavePopup"
        @hook:mounted="calculatePopupPosition"
      >
        <ProjectElementPopupContentFactory
          :project-element="svgElementToPreview"
          :minimal-content-mode="minimalContentMode"
        />
      </AppPopupWrapper>
    </transition>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import AppPopupWrapper from '@/components/UIKit/Standard/Atoms/AppPopupWrapper'
import ProjectElementPopupContentFactory from '@/components/PopupContent/ProjectElementPopupContentFactory'

export default {
  name: 'ProjectElementPopupDisplayer',
  props: {
    minimalContentMode: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AppPopupWrapper,
    ProjectElementPopupContentFactory,
  },
  data() {
    return {
      centeredAndCompensatedPopupPositionX: 0,
      centeredAndCompensatedPopupPositionY: 0,

      hidePopupDelay: null,
    }
  },
  computed: {
    ...mapGetters({
      svgElementToPreview: 'SvgInteractionTracker/svgElementToPreview',
      popupPositionX: 'SvgInteractionTracker/previewPositionX',
      popupPositionY: 'SvgInteractionTracker/previewPositionY',
      svgHeight: 'SvgInteractionTracker/svgHeight',
      zoomTrackerAnyGestureActive: 'ZoomTracker/isAnyGestureActive',
    }),
    showPopup() {
      return (
        this.svgElementToPreview !== undefined &&
        this.popupPositionX !== undefined &&
        this.popupPositionY !== undefined
      )
    },
  },
  watch: {
    zoomTrackerAnyGestureActive(isGestureActive) {
      if (isGestureActive) {
        this.hideSvgElementPopup()
      }
    },
  },
  methods: {
    ...mapActions({
      clearHideSvgElementPopupDelay:
        'SvgInteractionTracker/clearHideSvgElementPopupDelay',
      setHideSvgElementPopupDelay:
        'SvgInteractionTracker/setHideSvgElementPopupDelay',
      hideSvgElementPopup: 'SvgInteractionTracker/hideSvgElementPopup',
    }),
    onEnterPopup() {
      this.clearHideSvgElementPopupDelay()
    },
    onLeavePopup() {
      this.setHideSvgElementPopupDelay()
    },
    resetOverflowCompensation() {
      this.overflowCompensationX = 0
      this.overflowCompensationY = 0
    },
    calculateMinimalContentModeDisplacement() {
      const popupHeight = this.$refs.popupElement.$el.clientHeight
      return -(this.svgHeight + popupHeight / 3)
    },
    calculateDisplacementAccordingToVerticalScreenRegion() {
      const displacementToBottom = 80
      const displacementToTop = -80

      if (this.minimalContentMode) {
        return this.calculateMinimalContentModeDisplacement()
      } else {
        return this.popupPositionY < window.innerHeight / 2
          ? displacementToBottom
          : displacementToTop
      }
    },
    calculateCenteredPopupBoundingClientRect() {
      if (this.$refs.popupElement) {
        const popupWidth = this.$refs.popupElement.$el.clientWidth
        const popupHeight = this.$refs.popupElement.$el.clientHeight

        const displacementAccordingToVerticalScreenRegion = this.calculateDisplacementAccordingToVerticalScreenRegion()

        const displacedPopupPositionY =
          this.popupPositionY + displacementAccordingToVerticalScreenRegion

        return {
          top: displacedPopupPositionY - popupHeight / 2,
          bottom: displacedPopupPositionY + popupHeight / 2,
          left: this.popupPositionX - popupWidth / 2,
          right: this.popupPositionX + popupWidth / 2,
        }
      }
    },
    calculateOverflowCompensation(boundingRect) {
      let compensationX = 0
      let compensationY = 0

      if (this.$refs.popupElement) {
        const minMargin = 10
        const marginTop = minMargin
        const marginRight = window.innerWidth - minMargin
        const marginBottom = window.innerHeight - minMargin
        const marginLeft = minMargin

        if (boundingRect.left < marginLeft) {
          compensationX = Math.abs(boundingRect.left - marginLeft)
        } else if (boundingRect.right > marginRight) {
          compensationX = -Math.abs(boundingRect.right - marginRight)
        }

        if (boundingRect.top < marginTop) {
          compensationY = Math.abs(boundingRect.top - marginTop)
        } else if (boundingRect.bottom > marginBottom) {
          compensationY = -Math.abs(boundingRect.bottom - marginBottom)
        }
      }

      return { compensationX, compensationY }
    },
    calculatePopupPosition() {
      const centeredPopupBoundingClientRect = this.calculateCenteredPopupBoundingClientRect()
      const overflowCompensation = this.calculateOverflowCompensation(
        centeredPopupBoundingClientRect
      )

      this.centeredAndCompensatedPopupPositionX =
        centeredPopupBoundingClientRect.left +
        overflowCompensation.compensationX

      this.centeredAndCompensatedPopupPositionY =
        centeredPopupBoundingClientRect.top + overflowCompensation.compensationY
    },
  },
}
</script>

<style lang="scss">
.popup-enter-active,
.popup-leave-active {
  transition: transform 300ms cubic-bezier(0.25, 0.1, 0.5, 1),
    opacity 200ms cubic-bezier(0.25, 0.1, 0.5, 1);
}
.popup-enter,
.popup-leave-to {
  opacity: 0;
}
.popup-enter {
  transform: translate3d(0, 10px, 0);
}
.popup-leave-active {
  transition: opacity 150ms ease-out;
}
</style>
