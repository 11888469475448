<template>
  <TheInteractiveSvg
    ref="interactiveSvg"
    :svg-url="svgUrl"
    :interactive-element-ids="svgTargetElementIds"
    :colored-elements="markerColors"
    :disabled="zoomTrackerAnyGestureActive"
    @enter-element="showElementPreview"
    @leave-element="hideElementPreview"
    @select-element="showElement"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TheInteractiveSvg from '@/components/Gadgets/TheInteractiveSvg'
import SvgTargetElementIdMap from '@/classes/SvgTargetElementIdMap'
import responsiveMixin from '@/mixins/responsiveMixin'

export default {
  name: 'SvgInteractionsListener',
  components: {
    TheInteractiveSvg,
  },
  mixins: [responsiveMixin],
  props: {
    svgUrl: {
      type: String,
      required: true,
    },
    svgData: {
      type: SvgTargetElementIdMap,
      required: true,
    },
    useUniqueElementIdThroughoutProject: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      displayAvailability: 'Preferences/displayAvailability',
      primaryColor: 'Project/primaryColor',
      selectedSvgElement: 'SvgInteractionTracker/selectedSvgElement',
      svgElementToPreview: 'SvgInteractionTracker/svgElementToPreview',
      zoomTrackerAnyGestureActive: 'ZoomTracker/isAnyGestureActive',
    }),
    svgTargetElementIds() {
      return this.svgData.ids
    },
    markerColors() {
      return this.svgData.elements.map((element) => {
        const markerColor = this.getMarkerColor(element)

        const elementId = this.useUniqueElementIdThroughoutProject
          ? element.getUniqueElementIdThroughoutProject()
          : element.svgTargetElementId

        return {
          elementId: `${elementId}-marker`,
          color: markerColor,
        }
      })
    },
  },
  watch: {
    selectedSvgElement() {
      if (!this.selectedSvgElement && this.$refs.interactiveSvg) {
        this.$refs.interactiveSvg.clear()
      }
    },
    svgElementToPreview() {
      if (!this.svgElementToPreview && this.$refs.interactiveSvg) {
        this.$refs.interactiveSvg.clear()
      }
    },
  },
  beforeDestroy() {
    this.hideSvgElementPopup()
  },
  methods: {
    ...mapActions({
      launchSvgPopup: 'SvgInteractionTracker/launchSvgPopup',
      launchSvgPanel: 'SvgInteractionTracker/launchSvgPanel',
      setHideSvgElementPopupDelay:
        'SvgInteractionTracker/setHideSvgElementPopupDelay',
      hideSvgElementPopup: 'SvgInteractionTracker/hideSvgElementPopup',
    }),
    getMarkerColor(element) {
      if (this.displayAvailability) {
        return element?.availabilityColor || this.primaryColor
      }
      return this.primaryColor
    },
    showElement({ elementId }) {
      const selectedSvgElement = this.svgData.getElement(elementId)
      this.launchSvgPanel(selectedSvgElement)
    },
    showElementPreview({ elementId, elementCenter, elementSize }) {
      if (!this.isSmallScreen && !this.zoomTrackerAnyGestureActive) {
        const svgElement = this.svgData.getElement(elementId)
        const positionX = elementCenter.x
        const positionY = elementCenter.y
        const svgWidth = elementSize.width
        const svgHeight = elementSize.height

        this.launchSvgPopup({
          svgElement,
          positionX,
          positionY,
          svgWidth,
          svgHeight,
        })
      }
    },
    hideElementPreview() {
      const interactiveSvg = this.$refs.interactiveSvg

      if (interactiveSvg && this.svgElementToPreview) {
        const elementId = this.useUniqueElementIdThroughoutProject
          ? this.svgElementToPreview.getUniqueElementIdThroughoutProject()
          : this.svgElementToPreview.svgTargetElementId

        interactiveSvg.activate(elementId)
      }

      this.setHideSvgElementPopupDelay()
    },
  },
}
</script>
