<template>
  <div class="menu-wrapper">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'AppMenuWrapper',
}
</script>

<style lang="scss" scoped>
.menu-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
</style>
