import Vue from 'vue'
import router from '@/router'
import App from '@/App'

import VuexStoreVueDependency from '@/classes/VueDependency/VuexStoreVueDependency'
import GoogleAnalyticsVueDependency from '@/classes/VueDependency/GoogleAnalyticsVueDependency'
import I18nVueDependency from '@/classes/VueDependency/I18nVueDependency'
import SentryVueDependency from '@/classes/VueDependency/SentryVueDependency'
import FacebookPixelVueDependency from '@/classes/VueDependency/FacebookPixelVueDependency'
import VueDependenciesRegistry from '@/classes/VueDependency/VueDependenciesRegistry'
import LoggingServicePlugin from '@/plugins/logging-service'
import ProjectTypePlugin from '@/plugins/project-type'
import FullscreenHandlerPlugin from '@/plugins/fullscreen-handler'
import NavigationHistoryManagerPlugin from '@/plugins/navigation-history-manager'
import clickOutsideDirective from '@/directives/clickOutside'

export default async function initVue(jsonsData) {
  const vueDependenciesRegistry = new VueDependenciesRegistry(Vue, router)

  // For observability reasons, the Sentry dependency must be registered first:
  await vueDependenciesRegistry.register(new SentryVueDependency())

  Vue.config.productionTip = false

  const { projectData, availabilityData } = jsonsData
  const isProjectDataAvailable = !!projectData
  let isProjectUnderConstruction = undefined
  const defaultLocaleCode = process.env.VUE_APP_LOCALE
  let projectLocaleCode = defaultLocaleCode
  if (isProjectDataAvailable) {
    isProjectUnderConstruction = projectData.project.underConstruction
    projectLocaleCode = projectData.project.localeCodeBCP47
  }

  const { type: projectType } = projectData.projectStructure
  Vue.use(ProjectTypePlugin, { projectType })
  Vue.use(LoggingServicePlugin)
  Vue.use(FullscreenHandlerPlugin)
  Vue.use(NavigationHistoryManagerPlugin)

  const i18nVueDependency = new I18nVueDependency(
    projectLocaleCode,
    defaultLocaleCode
  )
  await vueDependenciesRegistry.register(i18nVueDependency)
  await vueDependenciesRegistry.register(
    new VuexStoreVueDependency({
      projectData,
      availabilityData,
      i18n: i18nVueDependency.library,
      isOfflineModeEnabled: process.env.VUE_APP_OFFLINE_MODE === 'true',
    })
  )
  await vueDependenciesRegistry.register(new GoogleAnalyticsVueDependency())
  await vueDependenciesRegistry.register(new FacebookPixelVueDependency())

  Vue.directive('click-outside', clickOutsideDirective)

  new Vue({
    ...vueDependenciesRegistry.vueGlobals,
    router,
    render: (h) =>
      h(App, {
        props: {
          isProjectDataAvailable,
          isProjectUnderConstruction,
        },
      }),
  }).$mount('#app')
}
