import BaseUnitIdentifierGenerator from './BaseUnitIdentifierGenerator'
import NeighbourhoodSubdivision from '@/classes/ProjectStructure/NeighbourhoodSubdivision'
import Unit from '@/classes/ProjectStructure/Unit/Unit'

class NeighbourhoodUnitIdentifierGenerator extends BaseUnitIdentifierGenerator {
  _getTranslationArguments({ unit }) {
    const subdivision = unit.parent()
    return {
      unitId: unit.label,
      subdivision: subdivision.name,
      name: unit.name,
    }
  }
  _validateGeneratorArguments({ unit }) {
    if (!(unit instanceof Unit)) {
      throw new Error(
        'NeighbourhoodUnitIdentifierGenerator._validateGeneratorArguments(): passed unit argument is not an instance of Unit class.'
      )
    }
    const subdivision = unit.parent()
    if (!(subdivision instanceof NeighbourhoodSubdivision)) {
      throw new Error(
        'NeighbourhoodUnitIdentifierGenerator._validateGeneratorArguments(): Parent of unit is not an instance of NeighbourhoodSubdivision class.'
      )
    }
  }
  get _localeGroupKey() {
    return 'neighbourhoodUnitIdentifiers'
  }
}

export default NeighbourhoodUnitIdentifierGenerator
