<template>
  <component
    :is="svgManagerComponent"
    v-if="svgManagerComponent"
    :svg-url="svgUrl"
  />
</template>

<script>
import TopviewInteractiveSvgLayer from './TopviewInteractiveSvgLayer'
import MasterplanInteractiveSvgLayer from './MasterplanInteractiveSvgLayer'
import NeighbourhoodSubdivisionInteractiveSvgLayer from './NeighbourhoodSubdivisionInteractiveSvgLayer'
import AvailabilityInteractiveSvgLayer from './AvailabilityInteractiveSvgLayer'

export default {
  name: 'SvgLayerFactory',
  components: {
    TopviewInteractiveSvgLayer,
    MasterplanInteractiveSvgLayer,
    NeighbourhoodSubdivisionInteractiveSvgLayer,
    AvailabilityInteractiveSvgLayer,
  },
  props: {
    svgUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    svgManagerComponent() {
      return this.$route.meta?.svgManagerComponent
    },
  },
}
</script>

<style></style>
