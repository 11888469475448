import VueDependency from '@/classes/VueDependency'
import FacebookPixel from 'vue-analytics-facebook-pixel'

class FacebookPixelVueDependency extends VueDependency {
  async install(Vue, router) {
    Vue.use(new FacebookPixel(), {
      router,
      automaticFiringOfPageViewEventsEnabled: true,
      debug: process.env.VUE_APP_PIXEL_DEBUG === 'true',
    })
  }
  get vueGlobalKeyValue() {
    return null
  }
}
export default FacebookPixelVueDependency
