<template>
  <TheTopBar
    :controls="controls"
    with-fullscreen-button
    :menu-offset="menuOffset"
  >
    <template #pointer-events-disabled-relative-slot>
      <TopBarLabel v-if="!!labelText" align="right">
        {{ labelText }}
      </TopBarLabel>
    </template>
  </TheTopBar>
</template>

<script>
import TheTopBar from '@/components/Gadgets/TheTopBar'
import TopBarControlsSuite from '@/classes/TopBarControls/TopBarControlsSuite'
import TopBarLabel from '@/components/Gadgets/TheTopBar/snippets/TopBarLabel'
import TopBarDropdownGenerator from '@/classes/TopBarControls/TopBarDropdownGenerator'
import responsiveMixin from '@/mixins/responsiveMixin'

export default {
  name: 'GalleryTopBarControls',
  components: {
    TheTopBar,
    TopBarLabel,
  },
  mixins: [responsiveMixin],
  props: {
    labelText: {
      type: String,
      default: undefined,
    },
    selectedPaymentMethodId: {
      type: String,
      default: undefined,
    },
    paymentMethodSelectOptions: {
      type: Array,
      default: () => [],
    },
    isDisplayPricesOnAvailabilityViewEnabled: {
      type: Boolean,
      default: false,
    },
    menuOffset: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    controls() {
      return new TopBarControlsSuite({
        rightControls: [
          TopBarDropdownGenerator.create({
            selectedValue: this.selectedPaymentMethodId,
            options: this.paymentMethodSelectOptions,
            show: () =>
              !this.isSmallScreen &&
              this.isDisplayPricesOnAvailabilityViewEnabled,
            eventHandler: (e) =>
              this.$emit('change-payment-method-for-unit-price-display', e),
          }),
        ],
      })
    },
  },
}
</script>
