<template>
  <div v-if="isThereAnyDataToShow" class="app-sheet-contact-information">
    <span class="app-sheet-contact-information__text">
      {{ $t('Solicitar información') }}
    </span>
    <div class="app-sheet-contact-information__buttons">
      <AppButtonIcon
        v-if="withContactFormButton"
        :icon="emailIcon"
        :size="buttonSize"
        :button-style="buttonStyle"
        @click.native.stop="$emit('open-email-form')"
      />
      <AppButtonIconExternalLink
        v-if="contactData.phoneUrl"
        :href="contactData.phoneUrl"
        :icon="phoneIcon"
        :size="buttonSize"
        :button-style="buttonStyle"
      />
      <AppButtonIconExternalLink
        v-if="contactData.whatsAppUrl"
        :href="contactData.whatsAppUrl"
        :icon="whatsappIcon"
        :size="buttonSize"
        :button-style="buttonStyle"
      />
    </div>
  </div>
</template>
<script>
import AppButtonIcon from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonIcon'
import AppButtonIconExternalLink from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonIconExternalLink'
import iconNames from '@/constants/ui-kit/standard/icon-names'
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'

import ContactData from '@/classes/ContactData'

export default {
  name: 'AppSheetContactInformation',
  components: {
    AppButtonIcon,
    AppButtonIconExternalLink,
  },
  props: {
    contactData: {
      type: ContactData,
      required: true,
    },
    withContactFormButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emailIcon: iconNames.UIEmail,
      phoneIcon: iconNames.UIPhone,
      whatsappIcon: iconNames.UIWhatsapp,
      buttonStyle: styles.EXTRA_LIGHT,
      buttonSize: sizes.XS,
    }
  },
  computed: {
    isThereAnyDataToShow() {
      return (
        this.withContactFormButton ||
        !!this.contactData.phoneUrl ||
        !!this.contactData.whatsAppUrl
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.app-sheet-contact-information {
  display: flex;
  gap: 2.375rem;
  justify-content: space-between;
  background-color: var(--gray-50);
  padding: 0.625rem 1.25rem;

  @at-root #{&}__text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: var(--font-weight-medium);
    font-size: 0.875rem;
    color: var(--gray-700);
  }
  @at-root #{&}__buttons {
    display: flex;
    gap: 1.25rem;
  }
}
</style>
