const DownloadManagerGetters = {
  transitionTree: (state) => state.transitionTree,
  videoTransitionDownloadManager: (state) =>
    state.videoTransitionDownloadManager,
  getTransition: (_, getters) => (from, to) =>
    getters.transitionTree.from(from)?.to(to),
  getSpriteClipByImageUrl: (state) => (imageUrl) =>
    state.imageUrlToSpriteClipMap[imageUrl],
  areSpritesReady: (state) => state.spritesReady,
}
export default DownloadManagerGetters
