export const AvailabilityStatuses = {
  AVAILABLE: 'AVAILABLE',
  RESERVED: 'RESERVED',
  SOLD: 'SOLD',
  RENTED: 'RENTED',
}

export const UnitTypes = {
  APARTMENT: 'APARTMENT',
  AMENITIES: 'AMENITIES',
  OFFICE: 'OFFICE',
  SHOP: 'SHOP',
  HOUSE: 'HOUSE',
}

export const OfferTypes = {
  FOR_SALE: 'FOR SALE',
  FOR_RENT: 'FOR RENT',
}

export const BrochureFileNameLocaleKeys = {
  SINGLETOWER_APARTMENT:
    'singleTowerUnitBrochureFileNames.apartmentBrochureFileName',
  SINGLETOWER_AMENITIES:
    'singleTowerUnitBrochureFileNames.amenitiesBrochureFileName',
  SINGLETOWER_OFFICE: 'singleTowerUnitBrochureFileNames.officeBrochureFileName',
  SINGLETOWER_SHOP: 'singleTowerUnitBrochureFileNames.shopBrochureFileName',
  SINGLETOWER_HOUSE: 'singleTowerUnitBrochureFileNames.houseBrochureFileName',

  MULTITOWER_APARTMENT:
    'multiTowerUnitBrochureFileNames.apartmentBrochureFileName',
  MULTITOWER_AMENITIES:
    'multiTowerUnitBrochureFileNames.amenitiesBrochureFileName',
  MULTITOWER_OFFICE: 'multiTowerUnitBrochureFileNames.officeBrochureFileName',
  MULTITOWER_SHOP: 'multiTowerUnitBrochureFileNames.shopBrochureFileName',
  MULTITOWER_HOUSE: 'multiTowerUnitBrochureFileNames.houseBrochureFileName',

  NEIGHBOURHOOD_APARTMENT:
    'neighbourhoodUnitBrochureFileNames.apartmentBrochureFileName',
  NEIGHBOURHOOD_AMENITIES:
    'neighbourhoodUnitBrochureFileNames.amenitiesBrochureFileName',
  NEIGHBOURHOOD_OFFICE:
    'neighbourhoodUnitBrochureFileNames.officeBrochureFileName',
  NEIGHBOURHOOD_SHOP: 'neighbourhoodUnitBrochureFileNames.shopBrochureFileName',
  NEIGHBOURHOOD_HOUSE:
    'neighbourhoodUnitBrochureFileNames.houseBrochureFileName',
}
