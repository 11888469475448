<template>
  <div class="quote-wizard">
    <div ref="quoter" class="quote-wizard__content">
      <div class="quote-wizard__content__column">
        <div class="quote-wizard__content__main">
          <div
            v-show="shouldDisplayFormStep"
            class="quote-wizard__step quote-wizard__step--form"
          >
            <header class="quote-wizard__content__header">
              <h3>{{ $t('¡Obtén una cotización personalizada!') }}</h3>
              <p>
                {{
                  $t(
                    'Completa tus datos personales y obtén una cotización personalizada en segundos.'
                  )
                }}
              </p>
            </header>
            <QuoteForm @form-updated="onFormUpdated" />
          </div>
          <div
            v-show="shouldDisplayQuoter"
            class="quote-wizard__step quote-wizard__step--quote"
          >
            <header class="quote-wizard__content__header">
              <h3>{{ $t('Selecciona tu esquema de pago') }}</h3>
              <p>
                {{
                  $t(
                    'Elige la opción de pago que mejor se adapte a tus necesidades.'
                  )
                }}
              </p>
            </header>
            <AppQuoter :unit="unit" @quote-ready="onQuoteReady" />
            <p v-if="serverError" class="form-error">
              {{ serverError }}
            </p>
            <PDFMaker v-if="canPrintData" :key="quoteVersion" ref="pdf">
              <AppQuoterPDFContent
                :unit="unit"
                :user-info="storedFormData"
                :quote-data="quoteData"
              />
            </PDFMaker>
          </div>
        </div>
        <div class="quote-wizard__content__info">
          <AppUnitCard :unit="unit" disable-tour360-icon />
          <AppQuoterUserInfo
            v-if="storedFormData"
            :user-info="storedFormData"
          />
        </div>
      </div>
    </div>
    <div class="quote-wizard__controls">
      <div
        class="quote-wizard__controls__inner quote-wizard__controls__inner--align-start"
      >
        <AppButtonTextWithIcon
          v-if="!shouldDisplayFormStep"
          :text="$t('Volver')"
          :size="buttonSize"
          :button-style="backButtonStyle"
          :disabled="false"
          @click.native="resetStoredFormData"
        />
      </div>
      <div
        class="quote-wizard__controls__inner quote-wizard__controls__inner--align-end"
      >
        <AppButtonTextWithIcon
          v-if="shouldDisplayFormStep"
          :text="$t('Ver cotización')"
          :size="buttonSize"
          :button-style="sendButtonStyle"
          :disabled="isViewQuoteButtonDisabled"
          @click.native="submitLeadContactInfo"
        />
        <template v-if="shouldDisplayQuoter">
          <AppButtonTextWithIcon
            :text="$t('Imprimir')"
            :size="buttonSize"
            :button-style="printButtonStyle"
            :disabled="isPrintButtonDisabled"
            @click.native="printQuote"
          />
          <AppButtonTextWithIcon
            :text="$t('Enviar por email')"
            :size="buttonSize"
            :button-style="sendButtonStyle"
            :disabled="isSendButtonDisabled"
            @click.native="submitQuote"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import httpClient from '@/constants/api/http-client'
import { mapGetters } from 'vuex'
import AppQuoter from '@/components/UIKit/Standard/Organisms/AppQuoteWizard/AppQuoter'
import AppQuoterPDFContent from '@/components/UIKit/Standard/Organisms/AppQuoteWizard/AppQuoterPDFContent'
import AppQuoterUserInfo from '@/components/UIKit/Standard/Atoms/AppQuoterUserInfo'
import AppUnitCard from '@/components/UIKit/Standard/Organisms/AppUnitCard'
import AppButtonTextWithIcon from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonTextWithIcon'
import PDFMaker from '@/components/Gadgets/PDFMaker'
import QuoteForm from '@/components/Form/QuoteForm'
import Unit from '@/classes/ProjectStructure/Unit/Unit'
import ContactApiEndpoints from '@/classes/Form/ContactApiEndpoints'
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'
import * as contactApiEndpointNames from '@/constants/api/contact-api-endpoint-names'
import modalSupportedComponents from '@/constants/ui-kit/standard/modal/modal-supported-components'
import UnitQuoteFileNameGeneratorFactory from '@/classes/UnitQuoteFileNameGenerators/UnitQuoteFileNameGeneratorFactory'

export default {
  name: 'AppQuoteWizard',
  components: {
    AppQuoter,
    AppQuoterPDFContent,
    AppQuoterUserInfo,
    AppUnitCard,
    AppButtonTextWithIcon,
    QuoteForm,
    PDFMaker,
  },
  props: {
    unit: {
      type: Unit,
      required: true,
    },
  },
  data() {
    return {
      buttonSize: sizes.SM,
      backButtonStyle: styles.LIGHT,
      printButtonStyle: styles.SECONDARY,
      sendButtonStyle: styles.PRIMARY,
      quoteData: undefined,
      storedFormData: undefined,
      formData: undefined,
      leadUUID: undefined,
      formDataReady: false,
      serverError: undefined,
      preventSubmit: false,
      preventQuotePrint: false,
      quoteVersion: 0,
    }
  },
  computed: {
    ...mapGetters({
      // FIXME: Consider adding another field with the project name only outside the context of contactData
      contactData: 'Project/contactData',
    }),
    shouldDisplayFormStep() {
      return !this.storedFormData
    },
    shouldDisplayQuoter() {
      return !!this.storedFormData && !!this.unit
    },
    isViewQuoteButtonDisabled() {
      return !this.formDataReady || this.preventSubmit
    },
    isPrintButtonDisabled() {
      return !this.canPrintData || this.preventQuotePrint
    },
    isSendButtonDisabled() {
      return !this.canSubmitQuoteData || this.preventSubmit
    },
    canSubmitLeadContactData() {
      return !!this.contactApiRequestData
    },
    canSubmitQuoteData() {
      return !!this.fullApiRequestData
    },
    canPrintData() {
      return !!this.storedFormData && !!this.quoteData
    },
    quoteApiRequestData() {
      return this.quoteData?.apiRequestData
    },
    contactApiRequestData() {
      if (!!this.formData && !!this.leadUUID) {
        return {
          id: this.leadUUID,
          name: this.formData.name,
          email: this.formData.email,
          seller_email: this.formData.sellerEmail || '',
          phone_number: this.formData.phoneNumber,
          unit_id: this.unit.id,
          info: this.$t('Consulta de Precio con Opciones de Pago'),
        }
      }
      return undefined
    },
    fullApiRequestData() {
      if (!!this.contactApiRequestData && !!this.quoteApiRequestData) {
        return {
          ...this.contactApiRequestData,
          ...this.quoteApiRequestData,
        }
      }

      return undefined
    },
  },
  mounted() {
    this.leadUUID = this.createLeadUUID()
  },
  methods: {
    createLeadUUID() {
      const bytes = new Uint8Array(16)
      crypto.getRandomValues(bytes)

      const hex = bytes.reduce((acc, byte) => {
        return acc + byte.toString(16).padStart(2, '0')
      }, '')

      return (
        `${hex.slice(0, 8)}-` +
        `${hex.slice(8, 12)}-` +
        `${hex.slice(12, 16)}-` +
        `${hex.slice(16, 20)}-` +
        `${hex.slice(20)}`
      )
    },
    resetStoredFormData() {
      this.leadUUID = this.createLeadUUID()
      this.storedFormData = undefined
    },
    onFormUpdated(formPayload) {
      this.formDataReady = formPayload.ready
      this.formData = formPayload.data
    },
    onQuoteReady(quoteData) {
      this.quoteVersion++
      this.quoteData = quoteData
    },
    viewQuote() {
      this.quoteVersion++
      this.storedFormData = this.formData
    },
    async submitLeadContactInfo() {
      if (this.canSubmitLeadContactData) {
        this.preventSubmit = true
        try {
          const apiEndpoints = new ContactApiEndpoints()
          const formTarget = apiEndpoints.getEndpointUrl(
            contactApiEndpointNames.UNIT_PRICE_QUOTATION_FIRST_STEP
          )
          await httpClient.post(formTarget, this.contactApiRequestData)
        } catch (error) {
          this.$loggingService.logError(
            `Error while submitting contact data: ${error.toString()}`
          )
        } finally {
          this.viewQuote()
          this.preventSubmit = false
        }
      }
    },
    async printQuote() {
      this.preventQuotePrint = true
      await this.$nextTick()
      const quoteFileNameGenerator = UnitQuoteFileNameGeneratorFactory.make(
        this.$i18n
      )
      const fileName = quoteFileNameGenerator.makeQuoteFileName(
        this.unit,
        this.contactData.projectName
      )
      await this.$refs.pdf.print(fileName)
      this.preventQuotePrint = false
    },
    async submitQuote() {
      if (this.canSubmitQuoteData) {
        this.preventSubmit = true
        try {
          const apiEndpoints = new ContactApiEndpoints()
          const formTarget = apiEndpoints.getEndpointUrl(
            contactApiEndpointNames.UNIT_PRICE_QUOTATION_SECOND_STEP
          )
          await httpClient.post(formTarget, this.fullApiRequestData)
          this.quoteSubmittedSuccessfully()
        } catch (error) {
          this.$loggingService.logError(
            `Error while submitting quote: ${error.toString()}`
          )
          this.serverError = this.$t(
            'Ha ocurrido un error. Intenta nuevamente más tarde.'
          )
        } finally {
          this.preventSubmit = false
        }
      }
    },
    quoteSubmittedSuccessfully() {
      this.$store.dispatch('ModalLauncher/launchModal', {
        component: modalSupportedComponents.AppQuoterConfirmationModal,
        properties: { unitIdentifier: this.unit.shortIdentifier },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.quote-wizard {
  position: relative;

  &__content {
    background-color: var(--gray-50);
    padding: 0.9375rem;

    &__column {
      display: flex;
      flex-direction: row-reverse;
      gap: 0.9375rem;
      max-width: 620px;
      margin: 0 auto;

      @include small-screens-vertical {
        flex-direction: column-reverse;
      }
    }

    &__info {
      width: 230px;

      @include small-screens-vertical {
        width: auto;
      }
    }

    &__main {
      flex: 1;
      flex-basis: 60%;
      padding: 1.25rem;
      border-radius: 0.5rem;
      background: #fff;
    }

    &__header {
      display: flex;
      flex-direction: column;
      gap: 0.625rem;
      margin-bottom: 1.25rem;

      h3,
      p {
        font-size: 1rem;
        font-weight: var(--font-weight-medium);
        margin: 0;
      }

      p {
        font-size: 0.875rem;
        font-weight: var(--font-weight-regular);
      }
    }
  }

  &__controls {
    display: flex;
    gap: 0.9375rem;
    justify-content: space-between;
    background: #fff;
    border-top: 1px solid var(--gray-100);
    position: sticky;
    bottom: 0;
    z-index: 2;
    padding: 0.9375rem;
    will-change: transform;

    &__inner {
      display: flex;
      gap: 0.9375rem;
    }
  }
}
.form-error {
  color: var(--form-error-color);
  font-weight: var(--font-weight-bold);
  font-size: 1rem;
  margin: 1rem 0 10px;
}
</style>
