<template>
  <component :is="tag" ref="touchArea">
    <slot></slot>
  </component>
</template>

<script>
const DIRECTIONS = {
  UP: 'up',
  RIGHT: 'right',
  DOWN: 'down',
  LEFT: 'left',
}

export default {
  name: 'TouchManager',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
  },
  data() {
    return {
      touchInitialX: 0,
      touchInitialY: 0,
      touchInitialTime: 0,
      dragPreviousX: 0,
      dragPreviousY: 0,
      dragPreviousTime: 0,
      dragCurrentX: 0,
      dragCurrentY: 0,
      dragCurrentTime: 0,
      dragDirectionX: undefined,
      dragDirectionY: undefined,
      tapDurationThreshold: 600,
      pressDurationThreshold: 1000,
      flickMaxDuration: 200,
      flickMinDistance: 25,
      dragMinDistance: 25,
      timer: null,
      movement: null,
    }
  },
  mounted() {
    const touchArea = this.$refs.touchArea
    touchArea.addEventListener('touchstart', this.touchStart)
    touchArea.addEventListener('touchmove', this.touchMove)
    touchArea.addEventListener('touchend', this.touchEnd)
    touchArea.addEventListener('touchcancel', this.touchCancel)
  },
  beforeDestroy() {
    const touchArea = this.$refs.touchArea
    touchArea.removeEventListener('touchstart', this.touchStart)
    touchArea.removeEventListener('touchmove', this.touchMove)
    touchArea.removeEventListener('touchend', this.touchEnd)
    touchArea.removeEventListener('touchcancel', this.touchCancel)
  },
  methods: {
    touchStart(e) {
      const touch = e.touches[0]
      this.touchInitialX = this.dragPreviousX = this.dragCurrentX =
        touch.clientX
      this.touchInitialY = this.dragPreviousY = this.dragCurrentY =
        touch.clientY
      this.touchInitialTime = this.dragPreviousTime = this.dragCurrentTime = Date.now()

      this.timer = setTimeout(
        () => this.touchHeld(e),
        this.pressDurationThreshold
      )
      this.movement = null

      const eventInformation = {
        x: this.touchInitialX,
        y: this.touchInitialY,
      }

      this.$emit('touch-start', eventInformation)
    },
    touchMove(e) {
      clearTimeout(this.timer)

      const touch = e.touches[0]
      this.dragPreviousX = this.dragCurrentX
      this.dragPreviousY = this.dragCurrentY
      this.dragPreviousTime = this.dragCurrentTime
      this.dragCurrentX = touch.clientX
      this.dragCurrentY = touch.clientY
      this.dragCurrentTime = Date.now()

      const moveDirection = this.getDirection(
        this.dragPreviousX,
        this.dragPreviousY,
        this.dragCurrentX,
        this.dragCurrentY
      )
      this.dragDirectionX =
        moveDirection.horizontalDirection || this.dragDirectionX
      this.dragDirectionY =
        moveDirection.verticalDirection || this.dragDirectionY

      const eventInformation = {
        x: this.dragCurrentX,
        y: this.dragCurrentY,
        distanceX: this.dragCurrentX - this.touchInitialX,
        distanceY: this.dragCurrentY - this.touchInitialY,
        offsetX: this.dragCurrentX - this.dragPreviousX,
        offsetY: this.dragCurrentY - this.dragPreviousY,
        directionX: this.dragDirectionX,
        directionY: this.dragDirectionY,
        event: e,
      }
      this.movement = eventInformation

      this.$emit('touch-move', eventInformation)
    },
    touchEnd() {
      clearTimeout(this.timer)

      const finalTime = Date.now()
      const touchDuration = finalTime - this.touchInitialTime
      const touchData = {
        duration: touchDuration,
        x: this.dragCurrentX,
        y: this.dragCurrentY,
        distanceX: this.dragCurrentX - this.touchInitialX,
        distanceY: this.dragCurrentY - this.touchInitialY,
      }

      if (this.movement) {
        const absDistanceX = Math.abs(this.movement.distanceX)
        const absDistanceY = Math.abs(this.movement.distanceY)
        const maxDistance = Math.max(absDistanceX, absDistanceY)
        touchData.direction =
          absDistanceX > absDistanceY
            ? this.dragDirectionX
            : this.dragDirectionY

        if (
          maxDistance >= this.flickMinDistance &&
          touchDuration < this.flickMaxDuration
        ) {
          // flick: quickly brush surface with fingertip
          this.$emit(`flick-${touchData.direction}`)
          // swipe: move fingertip over surface in any way
          this.$emit(`swipe-${touchData.direction}`)
        } else if (
          maxDistance >= this.dragMinDistance &&
          ((touchData.direction === DIRECTIONS.UP &&
            this.movement.distanceY < 0) ||
            (touchData.direction === DIRECTIONS.DOWN &&
              this.movement.distanceY > 0) ||
            (touchData.direction === DIRECTIONS.LEFT &&
              this.movement.distanceX < 0) ||
            (touchData.direction === DIRECTIONS.RIGHT &&
              this.movement.distanceX > 0))
        ) {
          // drag: move fingertip over surface without losing contact
          this.$emit(`drag-${touchData.direction}`)
          // swipe: move fingertip over surface in any way
          this.$emit(`swipe-${touchData.direction}`)
        }
      } else if (touchDuration < this.tapDurationThreshold) {
        // tap: briefly touch surface with fingertip
        this.$emit('tap')
      }

      this.$emit('touch-end', touchData)
    },
    touchCancel() {
      clearTimeout(this.timer)
      this.$emit('touch-cancel')
    },
    touchHeld() {
      // press: touch surface for extended period of time
      this.$emit('press')
    },
    getDirection(previousX, previousY, currentX, currentY) {
      const offsetX = currentX - previousX
      const offsetY = currentY - previousY
      let horizontalDirection = undefined
      let verticalDirection = undefined

      if (offsetY < 0) {
        verticalDirection = DIRECTIONS.UP
      } else if (offsetY > 0) {
        verticalDirection = DIRECTIONS.DOWN
      }

      if (offsetX < 0) {
        horizontalDirection = DIRECTIONS.LEFT
      } else if (offsetX > 0) {
        horizontalDirection = DIRECTIONS.RIGHT
      }

      return {
        horizontalDirection,
        verticalDirection,
      }
    },
  },
}
</script>
