import Menu from '@/classes/Navigation/Menu'
import LinkMenuItem from '@/classes/Navigation/MenuItem/LinkMenuItem'
import LinkMenuOption from '@/classes/Navigation/MenuOption/LinkMenuOption'
import MenuFilters from '@/classes/Navigation/MenuFilters'
export default class MenuGenerator {
  constructor({
    projectType,
    isOfflineModeEnabled,
    enabledFeatureFlags,
    params,
  }) {
    this._projectType = projectType
    this._isOfflineModeEnabled = isOfflineModeEnabled
    this._enabledFeatureFlags = enabledFeatureFlags
    this._params = params
  }

  _filterOptions(menuOptions) {
    return menuOptions
      .filter((option) =>
        MenuFilters.filterByProjectType(option, this._projectType)
      )
      .filter((option) =>
        MenuFilters.filterByOfflineMode(option, this._isOfflineModeEnabled)
      )
      .filter((option) =>
        MenuFilters.filterByFeatureFlags(option, this._enabledFeatureFlags)
      )
      .filter((option) =>
        MenuFilters.filterByRequiredParams(option, this._params)
      )
  }

  _adaptMenuChild(menuOption) {
    if (menuOption.customAdapter) {
      return menuOption.customAdapter(menuOption, this._params)
    } else {
      if (menuOption instanceof LinkMenuOption) {
        return new LinkMenuItem(menuOption)
      }
    }
  }

  make(menuOptions) {
    const menuInstance = new Menu('master-menu')
    const filteredOptions = this._filterOptions(menuOptions)

    for (const filteredOption of filteredOptions) {
      try {
        const adaptedMenuChild = this._adaptMenuChild(filteredOption)
        menuInstance.addChild(adaptedMenuChild)
      } catch (error) {
        throw new Error(
          `There was an error while creating Menu: ${error.toString()}`
        )
      }
    }

    return menuInstance
  }
}
