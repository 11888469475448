<template>
  <p class="sheet-price" :class="[alignmentClassName]">
    {{ displayPrice }}
  </p>
</template>
<script>
import BasePrice from '@/classes/Price/BasePrice'
import RentalPrice from '@/classes/Price/RentalPrice'
import alignments from '@/constants/ui-kit/standard/alignments'
import rentPaymentPeriodsTranslationKeys from '@/constants/price/rent-payment-periods-translation-keys'

export default {
  name: 'AppSheetPrice',
  props: {
    price: {
      type: BasePrice,
      default: () => undefined,
    },
    align: {
      type: String,
      default: alignments.ALIGN_LEFT,
      validator: (value) => Object.values(alignments).includes(value),
    },
  },
  computed: {
    alignmentClassName() {
      return `sheet-price--${this.align}`
    },
    displayPrice() {
      try {
        const formattedPrice = this.$i18n.priceFormatter.format(this.price)
        if (this.price instanceof RentalPrice) {
          const priceTranslationKey =
            rentPaymentPeriodsTranslationKeys[this.price.rentPaymentPeriod]

          return this.$i18n.t(priceTranslationKey, {
            price: formattedPrice,
          })
        } else {
          return formattedPrice
        }
      } catch (error) {
        this.$loggingService.logError(
          `Malformatted Price ${this.price}. Error: ${error}.`
        )
        return null
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.sheet-price {
  font-size: 0.875rem;
  line-height: 1;
  font-weight: $font-weight-medium;
  margin: 0;
  color: var(--primary-color);

  @at-root #{&}--#{$align-center} {
    text-align: center;
  }
  @at-root #{&}--#{$align-left} {
    text-align: start;
  }
  @at-root #{&}--#{$align-right} {
    text-align: end;
  }
}
</style>
