<template>
  <div class="app-zoom-controls-buttons">
    <AppButtonIcon
      :icon="zoomInIcon"
      :size="zoomControlsButtonsSize"
      :button-style="zoomControlsButtonsStyle"
      :disabled="!isZoomInEnabled"
      @click.native="$emit('zoom-in')"
    />
    <AppButtonIcon
      :icon="zoomOutIcon"
      :size="zoomControlsButtonsSize"
      :button-style="zoomControlsButtonsStyle"
      :disabled="!isZoomOutEnabled"
      @click.native="$emit('zoom-out')"
    />
  </div>
</template>
<script>
import AppButtonIcon from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonIcon.vue'
import iconNames from '@/constants/ui-kit/standard/icon-names'
import styles from '@/constants/ui-kit/standard/styles'
import sizes from '@/constants/ui-kit/standard/sizes'

export default {
  name: 'AppZoomControlsButtons',
  components: {
    AppButtonIcon,
  },
  props: {
    isZoomInEnabled: {
      type: Boolean,
      default: false,
    },
    isZoomOutEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      zoomControlsButtonsSize: sizes.XS,
      zoomControlsButtonsStyle: styles.DARK,
      zoomInIcon: iconNames.UIZoomIn,
      zoomOutIcon: iconNames.UIZoomOut,
    }
  },
}
</script>
<style lang="scss" scoped>
.app-zoom-controls-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.625rem;
  pointer-events: all;
}
</style>
