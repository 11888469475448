<template>
  <transition name="overlay" appear>
    <div class="app-overlay"></div>
  </transition>
</template>

<script>
export default {
  name: 'AppOverlay',
}
</script>

<style lang="scss" scoped>
.app-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--gray-900);
  opacity: 0.9;
}
.overlay-enter-active,
.overlay-leave-active {
  transition: all 0.3s ease-in-out;
}
.overlay-enter,
.overlay-leave-to {
  opacity: 0;
}
</style>
