import VideoTransition from './VideoTransition'
import AnimationTransition from './AnimationTransition'
import EmptyTransition from './EmptyTransition'
import AnimationTransitionParams from '@/classes/TransitionTree/AnimationTransitionParams'
import VideoTransitionParams from '@/classes/TransitionTree/VideoTransitionParams'

export default class TransitionFactory {
  static makeTransition(transitionParams, { videoElement } = {}) {
    if (!transitionParams) {
      return new EmptyTransition()
    }
    const paramTypeTransitionMap = {
      [AnimationTransitionParams.TYPE]: AnimationTransition,
      [VideoTransitionParams.TYPE]: VideoTransition,
    }
    const Transition = paramTypeTransitionMap[transitionParams.type]
    if (!Transition) {
      throw new Error(
        `TransitionFactory: Invalid type ${transitionParams.type}`
      )
    }
    return new Transition(transitionParams, { videoElement })
  }
}
