import Feature from './Feature'

class StringValueFeature extends Feature {
  constructor(featureData, i18n) {
    super(featureData, i18n)
    const parsedString = String(featureData.value)
    if (typeof featureData.value === 'string') {
      this._value = parsedString
    } else {
      throw new Error(
        `Cannot parse value ${featureData.value} to a valid String`
      )
    }
  }
}
export default StringValueFeature
