import SalePrice from './SalePrice'
import RentalPrice from './RentalPrice'
import { OfferTypes } from '@/constants/units'

class PriceFactory {
  constructor() {
    this._priceByOfferType = {
      [OfferTypes.FOR_SALE]: SalePrice,
      [OfferTypes.FOR_RENT]: RentalPrice,
    }
  }
  makePrice(priceJsonData, offerType) {
    const Price = this._priceByOfferType[offerType]
    if (!Price) {
      throw new Error(`PriceFactory: Invalid offerType ${offerType}.`)
    }
    return new Price(priceJsonData)
  }
}
export default PriceFactory
