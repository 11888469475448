<template>
  <div
    class="sheet-row"
    :class="{
      'sheet-row--divider--top': dividerTop,
      'sheet-row--divider--bottom': dividerBottom,
      'sheet-row--separator--top': separatorTop,
      'sheet-row--separator--bottom': separatorBottom,
      'sheet-row--overflow-hidden': overflowHidden,
      'sheet-row--overflow-scroll': overflowScroll,
      'sheet-row--background': background,
    }"
  >
    <AppScrollbarContainer
      v-if="overflowScroll"
      class="sheet-row__scrollbar-container"
    >
      <slot></slot>
    </AppScrollbarContainer>
    <template v-else>
      <slot></slot>
    </template>
  </div>
</template>

<script>
import AppScrollbarContainer from '@/components/UIKit/Standard/Atoms/AppScrollbarContainer'

export default {
  name: 'SheetRow',
  components: {
    AppScrollbarContainer,
  },
  props: {
    dividerTop: {
      type: Boolean,
      value: false,
    },
    dividerBottom: {
      type: Boolean,
      value: false,
    },
    separatorTop: {
      type: Boolean,
      value: false,
    },
    separatorBottom: {
      type: Boolean,
      value: false,
    },
    overflowHidden: {
      type: Boolean,
      value: false,
    },
    overflowScroll: {
      type: Boolean,
      value: false,
    },
    background: {
      type: Boolean,
      value: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.sheet-row {
  padding: 0 1.25rem;

  @at-root &--divider--top {
    padding-top: 1rem;
    border-top: 1px solid var(--gray-100);
  }
  @at-root &--divider--bottom:not(:last-child) {
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--gray-100);
  }

  @at-root &--separator--top::before {
    content: '';
    display: block;
    padding-top: 1rem;
    border-top: 1px solid var(--gray-100);
  }
  @at-root &--separator--bottom:not(:last-child)::after {
    content: '';
    display: block;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--gray-100);
  }

  @at-root &--divider--bottom + &,
    &--separator--bottom + & {
    padding-top: unset;
    border-top: unset;
    &::before {
      content: unset;
    }
  }

  @at-root &--overflow-hidden {
    overflow: hidden;
    flex: 1;
  }

  @at-root &--overflow-scroll {
    overflow: hidden;
    flex: 1;
  }

  @at-root &--background {
    background-color: var(--gray-100);
    border: 0;
    &::before,
    &::after {
      content: unset;
    }
  }

  @at-root &__scrollbar-container {
    height: 100%;
    overflow: auto;
  }
}
</style>
