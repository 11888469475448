<template>
  <component
    :is="unitPanelContentComponent"
    :project-element="unit"
    :access-links-enabled="accessLinksEnabled"
  ></component>
</template>

<script>
import { UnitTypes } from '@/constants/units'
import Unit from '@/classes/ProjectStructure/Unit/Unit'
import UnitPanelSmallContent from '@/components/PanelContent/panels/UnitPanel/UnitPanelSmallContent'
import UnitPanelSmallContentAmenities from '@/components/PanelContent/panels/UnitPanel/UnitPanelSmallContentAmenities'

export default {
  name: 'UnitPanelSmallContentFactory',
  components: {
    UnitPanelSmallContent,
  },
  props: {
    projectElement: {
      type: Unit,
      default: () => undefined,
    },
    accessLinksEnabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    unit() {
      return this.projectElement
    },
    unitPanelContentComponent() {
      const panelContentsByUnitType = {
        [UnitTypes.AMENITIES]: UnitPanelSmallContentAmenities,
        [UnitTypes.APARTMENT]: UnitPanelSmallContent,
        [UnitTypes.OFFICE]: UnitPanelSmallContent,
        [UnitTypes.SHOP]: UnitPanelSmallContent,
        [UnitTypes.HOUSE]: UnitPanelSmallContent,
      }
      return panelContentsByUnitType[this.unit.type]
    },
  },
}
</script>
