<template>
  <div class="prop-control">
    <label class="prop-control__info">
      <input
        type="checkbox"
        :checked="bindableModel.enabled"
        :disabled="bindableModel.required"
        @change="toggle"
      />
      <span class="prop-control__info__name">{{ name }}</span>
      <span class="prop-control__info__type">({{ bindableModel.type }})</span>
    </label>
    <div v-if="bindableModel.enabled" class="prop-control__input">
      <div
        v-if="bindableModel.presets.length > 1"
        class="prop-control__input__module prop-control__input__module--presets"
      >
        <select v-model="bindableModel.value">
          <option
            v-for="(preset, index) in bindableModel.presets"
            :key="index"
            :value="preset.value"
            >{{ preset.label }}</option
          >
        </select>
      </div>
      <div
        v-else-if="bindableModel.type === 'String'"
        class="prop-control__input__module prop-control__input__module--string"
      >
        <input v-model="bindableModel.value" type="text" />
      </div>
    </div>
  </div>
</template>

<script>
import AppPropModel from '@/components/UIKit/Standard/ViewerSections/classes/AppPropModel'

export default {
  name: 'PropControl',
  props: {
    model: {
      type: AppPropModel,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      bindableModel: this.model,
    }
  },
  computed: {
    id() {
      return `prop-control--${this.name}`
    },
  },
  methods: {
    toggle() {
      if (this.bindableModel.enabled) {
        this.bindableModel.disable()
      } else {
        this.bindableModel.enable()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.prop-control {
  color: var(--gray-700);
  border-bottom: 1px solid var(--gray-100);
  margin-bottom: 10px;

  @at-root &__input {
    margin-bottom: 10px;
  }

  @at-root &__info {
    display: block;
    margin-bottom: 10px;
    font-weight: var(--font-weight-medium);
    font-size: 1rem;

    @at-root &__name {
      margin-left: 10px;
    }

    @at-root &__type {
      font-size: 0.875rem;
      font-weight: var(--font-weight-light);
      &::before {
        content: ' ';
      }
    }
  }

  input[type='text'],
  select {
    display: block;
    font-size: 0.875rem;
    font-family: var(--font-family);
    color: var(--gray-700);
    background-color: #fff;
    border: 1px solid var(--gray-300);
    border-radius: 4px;
    padding: 0.25rem 10px;
    width: 100%;
  }
}
</style>
