<template>
  <div
    class="top-bar-resizable-block"
    :class="{ 'top-bar-resizable-block--moved': isMoved }"
    :style="offsetValue"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'TopBarResizableBlock',
  props: {
    offset: {
      type: Number,
      default: 0,
    },
    menuOffset: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    offsetValue() {
      return `--right-offset: ${this.offset}px; --left-offset: ${this.menuOffset}px;`
    },
    isMoved() {
      return this.offset !== 0 || this.menuOffset !== 0
    },
  },
}
</script>

<style lang="scss" scoped>
.top-bar-resizable-block {
  --right-offset: 0;
  --left-offset: 0;
  transition: margin 600ms cubic-bezier(0.15, 0, 0, 1);
  transition-delay: 50ms;
  @at-root #{&}--moved {
    margin-right: var(--right-offset);
    margin-left: var(--left-offset);
  }
}
</style>
