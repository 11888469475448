<template>
  <div class="app-legal-text" v-html="htmlContent"></div>
</template>

<script>
export default {
  name: 'AppLegalText',
  props: {
    htmlContent: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
.app-legal-text::v-deep {
  height: 100%;
  width: 100%;
  h5 {
    font-size: 0.875rem;
    margin-bottom: 20px;
    font-weight: var(--font-weight-bold);
  }

  p {
    font-size: 0.875rem;
    margin-bottom: 20px;
  }

  p + h5 {
    margin-top: 40px;
  }
}
</style>
