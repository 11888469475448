<template>
  <AppMenuWrapper class="menu-footer">
    <AppMenuRow
      v-if="shouldDisplaySocialNetworks"
      class="menu-footer__social-links"
    >
      <AppSocialNetworkButtons
        :facebook-url="facebookUrl"
        :instagram-url="instagramUrl"
        :whatsapp-url="whatsappUrl"
      />
    </AppMenuRow>
    <AppMenuRow
      v-if="developerWebsiteLink"
      class="menu-footer__developer-links"
    >
      <a :href="developerWebsiteLink.url" target="_blank">
        {{ developerWebsiteLink.displayText }}
      </a>
      <span v-if="developerBrochureUrl">-</span>
      <a
        v-if="developerBrochureUrl"
        :href="developerBrochureUrl"
        :download="developerBrochureFileName"
      >
        {{ $t('Brochure') }}
      </a>
    </AppMenuRow>
    <AppMenuRow v-if="withCopyright" divider-top>
      <AppCopyright :with-link="copyrightWithLink" />
    </AppMenuRow>
  </AppMenuWrapper>
</template>

<script>
import AppMenuWrapper from '@/components/UIKit/Standard/Atoms/AppMenuWrapper'
import AppMenuRow from '@/components/UIKit/Standard/Atoms/AppMenuRow'
import AppSocialNetworkButtons from '@/components/UIKit/Standard/Molecules/AppSocialNetworkButtons'
import AppCopyright from '@/components/UIKit/Standard/Atoms/AppCopyright'

export default {
  name: 'AppMenuFooter',
  components: {
    AppMenuWrapper,
    AppMenuRow,
    AppSocialNetworkButtons,
    AppCopyright,
  },
  props: {
    facebookUrl: {
      type: String,
      default: () => undefined,
    },
    instagramUrl: {
      type: String,
      default: () => undefined,
    },
    whatsappUrl: {
      type: String,
      default: () => undefined,
    },
    developerWebsiteLink: {
      type: Object,
      default: () => undefined,
      validator: (value) => {
        return !!value.url && !!value.displayText
      },
    },
    developerBrochureUrl: {
      type: String,
      default: undefined,
    },
    withCopyright: {
      type: Boolean,
      default: false,
    },
    copyrightWithLink: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    shouldDisplaySocialNetworks() {
      return this.facebookUrl || this.instagramUrl || this.whatsappUrl
    },
    developerBrochureFileName() {
      return this.$t('developerBrochureFileName', {
        developerName: this.developerWebsiteLink?.displayText,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.menu-footer {
  @at-root &__developer-links {
    text-align: center;
    display: flex;
    gap: 0.25rem;
    justify-content: center;
    &:last-child {
      margin-bottom: 0.9375rem;
    }
    a {
      font-size: 0.875rem;
      text-decoration: underline;
      color: var(--gray-700);
    }
  }
  @at-root &__social-links:last-child {
    margin-bottom: 0.9375rem;
  }
}
</style>
