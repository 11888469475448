<template>
  <p class="copyright">
    <small>
      {{ currentYear }}©
      <a v-if="withLink" :href="creditsUrl" target="_blank">
        <b>{{ creditsText }}</b>
      </a>
      <b v-else>{{ creditsText }}</b>
    </small>
  </p>
</template>

<script>
export default {
  name: 'AppCopyright',
  props: {
    withLink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      creditsText: 'Web3D.app',
      creditsUrl: 'https://www.web3d.app/',
      currentYear: new Date().getFullYear(),
    }
  },
}
</script>

<style lang="scss" scoped>
.copyright {
  font-weight: 0.875rem;
  text-align: center;
  color: var(--gray-500);
  margin: 0;

  b {
    font-weight: inherit;
  }
  a {
    color: inherit;
  }
}
</style>
