import ProjectConstants from '@/constants/project-structure/project-constants'
import SingleTowerUnitIdentifierGenerator from './SingleTowerUnitIdentifierGenerator'
import MultiTowerUnitIdentifierGenerator from './MultiTowerUnitIdentifierGenerator'
import NeighbourhoodUnitIdentifierGenerator from './NeighbourhoodUnitIdentifierGenerator'
import ProjectType from '@/classes/ProjectType'

const { PROJECT_TYPES } = ProjectConstants

class UnitIdentifierGeneratorFactory {
  static make(i18n) {
    const projectType = new ProjectType()
    const projectTypeUnitIdentifierGeneratorMap = {
      [PROJECT_TYPES.SINGLETOWER_TYPE]: () =>
        SingleTowerUnitIdentifierGenerator,
      [PROJECT_TYPES.MULTITOWER_TYPE]: () => MultiTowerUnitIdentifierGenerator,
      [PROJECT_TYPES.NEIGHBOURHOOD_TYPE]: () =>
        NeighbourhoodUnitIdentifierGenerator,
    }
    const UnitIdentifierGenerator = projectType.switcher(
      projectTypeUnitIdentifierGeneratorMap
    )
    if (!UnitIdentifierGenerator) {
      throw new Error(
        `UnitIdentifierGeneratorFactory: passed projectType ${projectType.name} is not mapped.`
      )
    }
    return new UnitIdentifierGenerator(i18n)
  }
}
export default UnitIdentifierGeneratorFactory
