<template>
  <div
    class="layout"
    :class="{ 'layout--transition-active': transitionActive }"
  >
    <TheMenu class="menu" />
    <div class="main">
      <AppReadabilityShadow
        :top="$route.meta.shadowTop"
        :bottom="$route.meta.shadowBottom"
        class="transitions"
      >
        <TheTransitionManager />
      </AppReadabilityShadow>
      <div class="content">
        <router-view />
      </div>
    </div>
    <HintContainer class="hints" />
    <AppModalManager class="modals" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TheTransitionManager from '@/components/Gadgets/TheTransitionManager.vue'
import TheMenu from '@/components/Gadgets/TheMenu'
import HintContainer from '@/components/Hint/HintContainer'
import AppReadabilityShadow from '@/components/UIKit/Standard/Atoms/AppReadabilityShadow'
import AppModalManager from '@/components/UIKit/Standard/Organisms/AppModal/AppModalManager'
export default {
  name: 'LayoutDefault',
  components: {
    TheMenu,
    TheTransitionManager,
    HintContainer,
    AppReadabilityShadow,
    AppModalManager,
  },
  computed: {
    ...mapGetters({
      transitionActive: 'transitionActive',
    }),
  },
}
</script>

<style lang="scss" scoped>
.layout {
  .main {
    z-index: 0;
    position: relative;
  }
  .menu {
    position: absolute;
    z-index: 1;
  }
  .hints {
    position: absolute;
    z-index: 2;
    top: 0;
  }
  .modals {
    z-index: 3;
  }

  /* This layer blocks the interactions with elements (without hiding them) during transitions. */
  @at-root &--transition-active::after {
    content: '';
    display: block;
    position: fixed;
    inset: 0;
    z-index: 10;
  }
}
</style>
