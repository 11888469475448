import ColorHandler from '@/classes/ColorHandler'

export default class ColorPallete {
  constructor(rgbColorHexString) {
    this._baseColorHandler = new ColorHandler(rgbColorHexString)
    this._whiteColorHandler = new ColorHandler('#fff')
    this._blackColorHandler = new ColorHandler('#000')

    this._variants = {}
    this._baseBlendFactor = 0.1285
    this._regularVariantId = '600'
    this._lightVariantIds = ['500', '400', '300', '200', '100', '50', '25']
    this._darkVariantIds = ['700', '800', '900']
    this._allVariantIds = [
      this._regularVariantId,
      ...this._lightVariantIds,
      ...this._darkVariantIds,
    ].sort(this._compareVariantIds)

    this._addVariant(this._regularVariantId, this._baseColorHandler)
    this._createAndAddVariants(this._lightVariantIds, this._whiteColorHandler)
    this._createAndAddVariants(this._darkVariantIds, this._blackColorHandler)
  }

  _addVariant(variantId, variantColorHandler) {
    if (!this._allVariantIds.includes(variantId)) {
      throw new Error(
        `ColorPallete._addVariant(): Invalid variantId ${variantId}.`
      )
    }
    if (this.variant(variantId) !== undefined) {
      throw new Error(
        `ColorPallete._addVariant(): Variant with id ${variantId} already exists.`
      )
    }
    if (!(variantColorHandler instanceof ColorHandler)) {
      throw new Error(
        `ColorPallete._addVariant(): Invalid variantColorHandler ${variantColorHandler}.`
      )
    }

    this._variants[variantId] = variantColorHandler
  }

  _createAndAddVariants(variantIds, blendColorHandler) {
    variantIds.forEach((variantId, index) => {
      const step = index + 1
      const blendFactor = step * this._baseBlendFactor
      const resultingColor = this._blend(
        this._baseColorHandler,
        blendColorHandler,
        blendFactor
      )
      const variantColorHandler = new ColorHandler(resultingColor)
      this._addVariant(variantId, variantColorHandler)
    })
  }

  _rgbColorObjectToHexString(rgbColorCobject) {
    const hexRr = `0${rgbColorCobject.r.toString(16)}`.slice(-2)
    const hexGg = `0${rgbColorCobject.g.toString(16)}`.slice(-2)
    const hexBb = `0${rgbColorCobject.b.toString(16)}`.slice(-2)

    return `#${hexRr}${hexGg}${hexBb}`
  }

  _blend(colorHandlerA, colorHandlerB, blendFactor) {
    const blendRgbColorCobject = {
      r: Math.round(
        colorHandlerA.r * (1 - blendFactor) + colorHandlerB.r * blendFactor
      ),
      g: Math.round(
        colorHandlerA.g * (1 - blendFactor) + colorHandlerB.g * blendFactor
      ),
      b: Math.round(
        colorHandlerA.b * (1 - blendFactor) + colorHandlerB.b * blendFactor
      ),
    }

    return this._rgbColorObjectToHexString(blendRgbColorCobject)
  }

  _compareVariantIds(variantIdA, variantIdB) {
    const numberIdA = parseInt(variantIdA)
    const numberIdB = parseInt(variantIdB)
    return numberIdA - numberIdB
  }

  variant(variantId) {
    return this._variants[variantId]
  }

  get variantIds() {
    return this._allVariantIds
  }

  get regular() {
    return this._variants[this._regularVariantId]
  }
}
