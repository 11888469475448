import { ParametricBlendAnimationTween } from './AnimationTween'
import LoggingServiceFactory from '@/classes/LoggingService/LoggingServiceFactory'

class PanScrollManager {
  constructor({ container }) {
    this.container = container
    this.containerScrollWidth = 0
    this.containerWidth = 0
    this.scrollCenterPosition = 0
    this.setContainerOverflowScrolling()
    this.update()
    this.loggingService = new LoggingServiceFactory().makeService()
  }

  setContainerOverflowScrolling() {
    // -webkit-overflow-scrolling: touch; is used to avoid glitches in iOS when the scroll is manipulated using JavaScript.
    this.container.style['-webkit-overflow-scrolling'] = 'touch'
  }

  update() {
    this.containerScrollWidth = this.container.scrollWidth
    this.containerWidth = this.container.offsetWidth
    this.scrollCenterPosition =
      (this.containerScrollWidth - this.containerWidth) / 2
  }

  async scrollToCenter(smoothBehavior = false) {
    if (this.isScrollCentered()) {
      return
    }
    this.loggingService.logMessage('Scroll to center')
    if (smoothBehavior) {
      await this.setSmoothScrollLeft(this.scrollCenterPosition)
    } else {
      this.setScrollLeft(this.scrollCenterPosition)
    }
  }

  isScrollCentered() {
    return Math.abs(this.scrollCenterPosition - this.container.scrollLeft) < 2
  }

  setSmoothScrollLeft(scrollLeft) {
    if (this.scrollCenterPosition <= 0) {
      this.loggingService.logError(
        'PanScrollManager.setSmoothScrollLeft(): scrollCenterPosition must be higher than 0. ' +
          `Current value: ${this.scrollCenterPosition}. ` +
          'Maybe you forgot to call PanScrollManager.update() before?'
      )
    } else {
      const animationDuration =
        400 +
        400 *
          (Math.abs(scrollLeft - this.container.scrollLeft) /
            this.scrollCenterPosition)
      const animation = new ParametricBlendAnimationTween(
        this.container,
        { scrollLeft },
        animationDuration
      )
      return animation.play()
    }
  }

  setScrollLeft(scrollLeft) {
    this.container.scrollLeft = scrollLeft
  }

  resetScrollPosition() {
    this.setScrollLeft(0)
  }
}

export default PanScrollManager
