<template>
  <SvgInteractionsListener :svg-url="svgUrl" :svg-data="svgData" />
</template>

<script>
import { mapGetters } from 'vuex'
import SvgInteractionsListener from '@/components/Gadgets/SvgInteractionsListener'

export default {
  name: 'TopviewInteractiveSvgLayer',
  components: {
    SvgInteractionsListener,
  },
  props: {
    svgUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      findLevelById: 'Topview/findLevelById',
    }),
    level() {
      const levelId = this.$route.params.levelId
      return this.findLevelById(levelId)
    },
    svgData() {
      return this.level?.svgData
    },
  },
}
</script>
