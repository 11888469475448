<template>
  <validation-observer
    ref="validationObserver"
    tag="form"
    @input="updateFormStatus"
  >
    <fieldset>
      <BaseInput
        ref="nameField"
        v-model="fields.name"
        :placeholder="$t('Nombre')"
        :name="$t('Nombre')"
        rules="required|max:100"
      />
      <BaseInput
        ref="phoneNumberField"
        v-model="fields.phoneNumber"
        :placeholder="$t('Teléfono')"
        :name="$t('Teléfono')"
        type="phone"
        rules="required|max:50"
      />
      <BaseInput
        ref="emailField"
        v-model="fields.email"
        :placeholder="$t('Correo electrónico')"
        :name="$t('Correo electrónico')"
        type="email"
        rules="required|max:100"
      />
      <BaseInput
        ref="emailField"
        v-model="fields.sellerEmail"
        :placeholder="$t('Correo electrónico del vendedor (Opcional)')"
        :name="$t('Correo electrónico del vendedor (Opcional)')"
        type="email"
        rules="max:100"
      />
    </fieldset>
    <BaseInput
      ref="policyField"
      v-model="fields.policy"
      type="checkbox"
      class="check"
      :name="$t('form.privacyPolicy')"
      :rules="{ 'require-accept': { allowFalse: false } }"
    >
      <i18n path="form.privacyPolicyCheck" tag="span">
        <template #privacyPolicy>
          <a href="#" @click.prevent="showPrivacyPolicy()">{{
            $t('form.privacyPolicy')
          }}</a>
        </template>
      </i18n>
    </BaseInput>
    <PrivacyPolicyBox v-if="isShownPrivacyPolicyBox" class="privacy-policy" />
    <template v-if="disclaimer">
      <button
        class="disclaimer-button"
        @click.prevent="isShownDisclaimerBox = !isShownDisclaimerBox"
      >
        {{ $t('Disclaimer') }}
      </button>
      <DisclaimerBox v-if="isShownDisclaimerBox" class="disclaimer" />
    </template>
  </validation-observer>
</template>

<script>
import { mapGetters } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import BaseInput from '@/components/Form/BaseInput'
import PrivacyPolicyBox from '@/components/Form/PrivacyPolicyBox'
import DisclaimerBox from '@/components/Form/DisclaimerBox'

export default {
  name: 'QuoteForm',
  components: {
    PrivacyPolicyBox,
    DisclaimerBox,
    BaseInput,
    ValidationObserver,
  },
  data() {
    return {
      fields: {
        name: '',
        phoneNumber: '',
        email: '',
        sellerEmail: '',
        policy: false,
      },
      isShownPrivacyPolicyBox: false,
      isShownDisclaimerBox: false,
    }
  },
  methods: {
    async updateFormStatus() {
      await this.$nextTick()
      const isValid = await this.$refs.validationObserver.validate({
        silent: true,
      })
      this.$emit('form-updated', {
        ready: isValid,
        data: this.fields,
      })
    },
    showPrivacyPolicy() {
      this.isShownPrivacyPolicyBox = true
    },
  },
  computed: {
    ...mapGetters({
      disclaimer: 'Project/disclaimer',
    }),
  },
}
</script>

<style lang="scss" scoped>
.form-error {
  color: var(--form-error-color);
  font-weight: var(--font-weight-bold);
  font-size: 1rem;
  margin: 0 0 10px;
}
fieldset {
  margin-bottom: 2.5rem;
}
.button {
  width: 100%;
}
.check {
  margin-bottom: 2rem;
}
.box + button {
  margin-top: 11px;
}
.privacy-policy {
  margin-bottom: 2rem;
}
.disclaimer-button {
  font-size: 0.875rem;
  font-weight: var(--font-weight-regular);
  color: var(--gray-500);
  text-decoration: underline;
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
}
.disclaimer {
  margin-top: 0.5rem;
}
</style>
