var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sheet-row",class:{
    'sheet-row--divider--top': _vm.dividerTop,
    'sheet-row--divider--bottom': _vm.dividerBottom,
    'sheet-row--separator--top': _vm.separatorTop,
    'sheet-row--separator--bottom': _vm.separatorBottom,
    'sheet-row--overflow-hidden': _vm.overflowHidden,
    'sheet-row--overflow-scroll': _vm.overflowScroll,
    'sheet-row--background': _vm.background,
  }},[(_vm.overflowScroll)?_c('AppScrollbarContainer',{staticClass:"sheet-row__scrollbar-container"},[_vm._t("default")],2):[_vm._t("default")]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }