import ProjectType from '@/classes/ProjectType'

import ProjectConstants from '@/constants/project-structure/project-constants'
import TopBarSupportedControlsComponents from '@/constants/top-bar/top-bar-supported-controls-components'

const { PROJECT_TYPES } = ProjectConstants

export default class TopBarControl {
  constructor({
    component,
    projectTypesSupported,
    show,
    props,
    eventName,
    eventHandler,
  }) {
    if (!this._isComponentValid(component)) {
      throw new Error(
        'TopBarControl: component passed is invalid (expect: supported vue component)'
      )
    }
    this._component = component

    if (!this._isprojectTypesSupportedValid(projectTypesSupported)) {
      throw new Error(
        'TopBarControl: projectTypesSupported array passed is invalid'
      )
    }
    this._projectTypesSupported = projectTypesSupported

    if (!this._isShowValid(show)) {
      throw new Error(
        'TopBarControl: show passed is invalid (expect: boolean function)'
      )
    }
    this._show = show

    if (!this._isEventNameValid(eventName)) {
      throw new Error(
        'TopBarControl: eventName passed is invalid (expect: no empty string)'
      )
    }
    this._eventName = eventName

    if (!this._isEventHandlerValid(eventHandler)) {
      throw new Error(
        'TopBarControl: eventHandler passed is invalid (expect: function)'
      )
    }
    this._eventHandler = eventHandler

    if (!this._isPropsValueValid(props)) {
      throw new Error(
        'TopBarControl: props value passed is invalid (expect: Object)'
      )
    }

    this._props = props || {}
    this._key = undefined
  }

  _isComponentValid(component) {
    return Object.values(TopBarSupportedControlsComponents).includes(component)
  }

  _isprojectTypesSupportedValid(projectTypesSupported) {
    return (
      projectTypesSupported === undefined ||
      this._areRequiredProjectTypesSupported(projectTypesSupported)
    )
  }

  // FIXME: Consider move this function to ProjectType Plugin (Duplicated on MenuOption class)
  _areRequiredProjectTypesSupported(requiredProjectTypes) {
    const supportedProjectTypes = Object.values(PROJECT_TYPES)
    if (Array.isArray(requiredProjectTypes)) {
      for (const requiredType of requiredProjectTypes) {
        if (!supportedProjectTypes.includes(requiredType)) {
          return false
        }
      }

      return true
    } else {
      return false
    }
  }

  _isShowValid(show) {
    return typeof show === 'function' && typeof show() === 'boolean'
  }

  _isEventNameValid(eventName) {
    return typeof eventName === 'string' && eventName.trim() !== ''
  }

  _isEventHandlerValid(eventHandler) {
    return typeof eventHandler === 'function'
  }

  _isPropsValueValid(props) {
    if (props !== undefined) {
      return typeof props === 'object' && !Array.isArray(props)
    } else {
      return true
    }
  }

  _isCurrentProjectTypeSupported() {
    if (this._projectTypesSupported !== undefined) {
      return new ProjectType().is(this._projectTypesSupported)
    } else {
      return true
    }
  }

  setKey(key) {
    this._key = key
  }

  get key() {
    return this._key
  }

  get component() {
    return this._component
  }

  get show() {
    return this._isCurrentProjectTypeSupported() ? this._show : () => false
  }

  get props() {
    return this._props
  }

  get eventName() {
    return this._eventName
  }

  get eventHandler() {
    return this._eventHandler
  }
}
