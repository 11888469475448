<template>
  <section class="quote-confirmation-message">
    <svg
      width="25"
      height="18"
      viewBox="0 0 25 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 9L10 17L24 1"
        stroke="#039855"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <h2>
      <TextWrapper
        :text="$t('_quoteConfirmationMessageTitle', { unitIdentifier })"
      />
    </h2>
    <p>
      <TextWrapper
        :text="
          $t(
            'Si tiene alguna pregunta adicional o necesita información adicional, no dude en contactarnos a través de nuestra sección de contacto en el menú.'
          )
        "
      />
    </p>
  </section>
</template>

<script>
import TextWrapper from '@/components/TextWrapper'

export default {
  name: 'AppQuoteConfirmationMessage',
  components: {
    TextWrapper,
  },
  props: {
    unitIdentifier: {
      type: String,
      default: () => undefined,
    },
  },
}
</script>

<style lang="scss" scoped>
.quote-confirmation-message {
  text-align: center;
  padding: 14px 0;

  h2 {
    font-size: 1.125rem;
    font-weight: var(--font-weight-medium);
    color: var(--gray-700);
    margin: 1.5625rem 0 1.25rem;
  }

  p {
    font-size: 0.875rem;
    font-weight: var(--font-weight-regular);
    color: var(--gray-700);
    margin: 0;
  }

  .tick-icon {
    margin: 35px auto 30px;
  }

  @include small-screens {
    padding: 0;

    p {
      margin-bottom: 0;
    }

    .tick-icon {
      margin-top: 20px;
    }
  }

  @include small-screens-horizontal {
    .tick-icon {
      margin-top: 10px;
    }
  }
}
</style>
