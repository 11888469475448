<template>
  <transition name="modal" mode="out-in">
    <component
      :is="modalComponent"
      v-if="showModal"
      v-bind="modalProperties"
      @close="handleCloseModal"
    />
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AppModalManager',
  computed: {
    ...mapGetters({
      showModal: 'ModalLauncher/isModalLaunched',
      modalComponent: 'ModalLauncher/modalComponent',
      modalProperties: 'ModalLauncher/modalProperties',
    }),
  },
  methods: {
    handleCloseModal() {
      this.$store.dispatch('ModalLauncher/closeModal')
    },
  },
}
</script>
