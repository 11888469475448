<template>
  <div v-if="isThereAnyDataToShow" class="contact-panel">
    <ul>
      <li v-if="contactData.emailText">
        <AppIcon class="icon" :size="iconSize" :icon="iconEmail" />
        <p>{{ contactData.emailText }}</p>
      </li>
      <li v-if="contactData.phoneText">
        <AppIcon class="icon" :size="iconSize" :icon="iconPhone" />
        <p>{{ contactData.phoneText }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AppIcon from '@/components/UIKit/Standard/Atoms/AppIcon'
import sizes from '@/constants/ui-kit/standard/sizes'
import iconNames from '@/constants/ui-kit/standard/icon-names'

export default {
  name: 'InfoPanelOfflineModeContactBar',
  components: {
    AppIcon,
  },
  data() {
    return {
      iconSize: sizes.SM,
      iconPhone: iconNames.UIPhone,
      iconEmail: iconNames.UIEmail,
    }
  },
  computed: {
    ...mapGetters({
      contactData: 'Project/contactData',
    }),
    isThereAnyDataToShow() {
      return !!this.contactData.emailText || !!this.contactData.phoneText
    },
  },
}
</script>

<style lang="scss" scoped>
.contact-panel {
  background: #fff;
  padding: 1.5rem 1.25rem;
  border-top: 1px solid var(--gray-100);
  font-size: 0.875rem;
  font-weight: var(--font-weight-regular);
  white-space: nowrap;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      &:not(:last-child) {
        margin-bottom: 1.25rem;
      }
      p {
        display: inline;
        color: var(--gray-700);
      }
    }
    .icon {
      fill: var(--gray-700);
      stroke: var(--gray-700);
      margin-right: 1rem;
      vertical-align: middle;
      border-style: none;
    }
  }
}
</style>
