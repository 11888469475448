<template>
  <div class="rotate-device-hint" @click="handleHintClose">
    <AppButtonIcon
      :icon="closeButtonIcon"
      :size="closeButtonSize"
      :button-style="closeButtonStyle"
      class="rotate-device-hint__close-button"
      @click.native="handleHintClose"
    />
    <div class="rotate-device-hint__icon">
      <svg
        width="123"
        height="95"
        viewBox="0 0 123 95"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M56.7233 8.1165C69.6359 10.4223 80.0582 22.0437 83.6553 34.6796L78.767 31.8204C77.9369 31.267 76.8301 31.4515 76.2767 32.1893C75.7233 33.0194 75.9077 34.1262 76.6456 34.6796C76.7378 34.7718 76.8301 34.7718 76.9223 34.8641L84.7621 39.4757C85.0388 39.6602 85.3155 39.7524 85.6844 39.7524H86.1456C86.6068 39.6602 86.9757 39.3835 87.2524 38.9223L91.864 31.0825C92.3252 30.2524 92.1407 29.1456 91.2184 28.5922C90.3883 28.1311 89.1893 28.4078 88.7281 29.2379L86.8835 32.3738C82.4563 19.0922 71.1116 7.10194 57.3689 4.61165C57.1844 4.61165 57.0922 4.70388 57 4.79611L56.4466 7.74757C56.4466 7.93204 56.5388 8.1165 56.7233 8.1165Z"
          fill="white"
        />
        <path
          d="M113.631 43.2573H36.432C31.5437 43.2573 27.5776 47.2233 27.5776 52.1116V86.0534C27.5776 87.9903 28.2233 89.8349 29.3301 91.4029C30.9903 93.6165 33.5728 95 36.432 95H113.631C118.519 95 122.485 91.034 122.485 86.1456V52.1116C122.578 47.2233 118.612 43.2573 113.631 43.2573ZM117.136 55.6165H116.767C115.291 55.6165 114.092 56.8155 114.092 58.2913V79.8738C114.092 81.3495 115.291 82.5485 116.767 82.5485H117.136V86.0534C117.136 87.9903 115.568 89.5582 113.631 89.5582H36.432C34.4951 89.5582 32.9272 87.9903 32.9272 86.0534V52.1116C32.9272 50.1748 34.4951 48.6068 36.432 48.6068H113.631C115.568 48.6068 117.136 50.1748 117.136 52.1116V55.6165Z"
          fill="white"
        />
        <path
          d="M24.9029 89.7427C24.9029 89.6505 24.8107 89.6505 24.7184 89.6505H7.93204C6.54854 89.6505 5.34951 88.5437 5.34951 87.068V7.93204C5.34951 6.54854 6.45631 5.34951 7.93204 5.34951H12.267C12.267 5.34951 12.3592 5.34951 12.3592 5.44175V6.64078C12.3592 7.65534 13.1893 8.3932 14.1116 8.3932H37.6311C38.6456 8.3932 39.3835 7.56311 39.3835 6.64078V5.44175C39.3835 5.44175 39.3835 5.34951 39.4757 5.34951H43.8107C45.1942 5.34951 46.3932 6.45631 46.3932 7.93204V40.1214C46.3932 40.2136 46.4854 40.3981 46.6699 40.3981H51.5582C51.6505 40.3981 51.8349 40.3058 51.8349 40.1214V7.93204C51.8349 3.50485 48.2379 0 43.9029 0H7.93204C3.59709 0 0 3.59709 0 7.93204V86.9757C0 91.4029 3.59709 94.9078 7.93204 94.9078H27.3932C27.4854 94.9078 27.5777 94.9078 27.5777 94.8155C27.5777 94.7233 27.5777 94.6311 27.5777 94.6311L24.9029 89.7427Z"
          fill="#BDBDBD"
          fill-opacity="0.8"
        />
      </svg>
    </div>
    <i18n
      tag="h2"
      path="hints.rotateDevice.title"
      class="rotate-device-hint__title"
    >
      <template #requiredAction>
        <strong>{{ $t('hints.rotateDevice.requiredAction') }}</strong>
      </template>
    </i18n>
    <i18n
      tag="p"
      path="hints.rotateDevice.subtitle"
      class="rotate-device-hint__subtitle"
    >
      <template #optionName>
        <i>{{ $t('hints.rotateDevice.optionName') }}</i>
      </template>
    </i18n>
    <AppButtonText
      class="rotate-device-hint__button"
      :text="$t('hints.rotateDevice.closeButtonText')"
      :button-style="closeButtonStyle"
      @click.native="handleHintClose"
    />
  </div>
</template>

<script>
import AppButtonText from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonText'
import AppButtonIcon from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonIcon'
import responsiveMixin from '@/mixins/responsiveMixin'
import iconNames from '@/constants/ui-kit/standard/icon-names'
import styles from '@/constants/ui-kit/standard/styles'
import sizes from '@/constants/ui-kit/standard/sizes'

const HINT_TIMEOUT_MS = 12000

export default {
  name: 'RotateDeviceHint',
  components: {
    AppButtonText,
    AppButtonIcon,
  },
  mixins: [responsiveMixin],
  data() {
    return {
      hintCloseTimeout: null,
      closeButtonStyle: styles.LINK,
      closeButtonSize: sizes.XS,
      closeButtonIcon: iconNames.UIClose,
    }
  },
  watch: {
    isSmallScreenHorizontal(newValue) {
      if (newValue === true) {
        this.closeHint()
      }
    },
  },
  mounted() {
    this.hintCloseTimeout = setTimeout(() => {
      this.closeHint()
    }, HINT_TIMEOUT_MS)
  },
  methods: {
    handleHintClose() {
      this.closeHint()
    },
    closeHint() {
      this.$emit('hintClose')
      if (this.hintCloseTimeout) {
        clearTimeout(this.hintCloseTimeout)
        this.hintCloseTimeout = null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.rotate-device-hint {
  height: 100%;
  width: 100%;
  padding: 16px;
  margin: auto;
  overflow: auto;
  background-color: rgba(21, 21, 21, 0.95);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  @at-root #{&}__title {
    padding: 0;
    margin: 10px 0;
    max-width: 300px;
    color: white;
    font-size: 2rem;
    font-weight: var(--font-weight-light);
    line-height: 1.172;
    text-align: center;
    strong {
      font-weight: var(--font-weight-bold);
    }
  }
  @at-root #{&}__subtitle {
    font-size: 1rem;
    max-width: 300px;
    font-weight: var(--font-weight-light);
    line-height: 1.375;
    text-align: center;
    color: white;
    padding: 0;
    margin: 10px 0;
    i {
      font-weight: var(--font-weight-regular);
      font-style: italic;
    }
  }
  @at-root #{&}__icon {
    margin-bottom: 14px;
  }
  @at-root #{&}__button {
    margin-top: 22px;
  }
  @at-root #{&}__close-button {
    position: fixed;
    right: 16px;
    top: 16px;
  }
}
</style>
