<template>
  <div class="text-hint-tooltip" @click="showAfterMount && hide()">
    <transition name="fade">
      <div
        v-show="hintText && showTooltip"
        class="text-hint-tooltip__hint"
        :class="position"
      >
        <label>{{ hintText }}</label>
      </div>
    </transition>
    <div class="text-hint-tooltip__content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { textHintTooltipPositions } from '@/constants/hint'

export default {
  name: 'TextHintTooltip',
  props: {
    showAfterMount: {
      type: Boolean,
      default: false,
    },
    delayToShow: {
      type: Number,
      default: 0,
    },
    delayToHide: {
      type: Number,
      default: 0,
    },
    position: {
      type: String,
      validator: (positionValue) => {
        return Object.values(textHintTooltipPositions).includes(positionValue)
      },
      default: textHintTooltipPositions.LEFT_TOP,
    },
    hintText: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      showTooltip: false,
    }
  },
  mounted() {
    if (this.showAfterMount) {
      this.setAutomaticShowAndHide()
    }
  },
  methods: {
    show() {
      this.showTooltip = true
    },
    hide() {
      this.showTooltip = false
    },
    setAutomaticShowAndHide() {
      setTimeout(() => {
        this.show()

        if (this.delayToHide !== undefined) {
          setTimeout(() => {
            this.hide()
          }, this.delayToHide)
        }
      }, this.delayToShow)
    },
  },
}
</script>
<style lang="scss" scoped>
.text-hint-tooltip {
  width: fit-content;
  height: fit-content;
  position: relative;
  &__hint {
    position: absolute;
    background: #15151599;
    color: #fff;
    padding: 0.5rem 0.5rem;
    min-width: 4rem;
    font-size: 12px;
    text-align: center;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    label {
      margin: 0;
      line-height: 1;
    }
    &.left-top {
      bottom: 100%;
      right: 100%;
    }
    &.right-top {
      left: 100%;
      bottom: 100%;
    }
    &.left-bottom {
      top: 100%;
      right: 100%;
    }
    &.right-bottom {
      top: 100%;
      left: 100%;
    }

    @include small-screens {
      &.left-top {
        bottom: calc(100% + 7px);
        right: 0;
      }
      &.right-top {
        left: 0;
        bottom: calc(100% + 7px);
      }
      &.left-bottom {
        top: calc(100% + 7px);
        right: 0;
      }
      &.right-bottom {
        top: calc(100% + 7px);
        left: 0;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-1rem);
}
</style>
