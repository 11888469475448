const LoggingServiceFactory = require('../../classes/LoggingService/LoggingServiceFactory')
module.exports = class BaseMissingInterpolatedValuesHandler {
  constructor() {
    this.loggingService = new LoggingServiceFactory().makeService()
  }
  // eslint-disable-next-line no-unused-vars
  handleMissingInterpolatedValue(message, value) {
    throw Error(
      'BaseMissingInterpolatedValuesHandler: handleMissingInterpolatedValue not implemented'
    )
  }
}
