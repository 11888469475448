import ProjectElementTypes from '@/constants/project-structure/project-element-types'
import BaseProjectElement from '@/classes/ProjectStructure/BaseProjectElement'
import ProjectType from '@/classes/ProjectType'

class SlugGenerator {
  static make(projectElement) {
    if (!(projectElement instanceof BaseProjectElement)) {
      throw new Error(
        `SlugGenerator.make(): Project element ${projectElement} is not an instance of BaseProjectElement class.`
      )
    }

    let slugPerProjectElementType = {}
    Object.values(ProjectElementTypes).forEach((type) => {
      slugPerProjectElementType[type] = this.getSlugFromFirstParentOfType(
        projectElement,
        type
      )
    })

    const projectType = new ProjectType()
    const slugOrder = projectType.switcher({
      [projectType.SINGLETOWER_TYPE]: SlugGenerator._singleTowerSlugOrder,
      [projectType.MULTITOWER_TYPE]: SlugGenerator._multiTowerSlugOrder,
      [projectType.NEIGHBOURHOOD_TYPE]: SlugGenerator._neighbourhoodSlugOrder,
    })

    const orderedSlugs = slugOrder
      .map((slugKey) => slugPerProjectElementType[slugKey])
      .filter((slug) => !!slug)
    return orderedSlugs.join('-')
  }
  static get _singleTowerSlugOrder() {
    return [
      ProjectElementTypes.LEVEL,
      ProjectElementTypes.UNIT,
      ProjectElementTypes.POINT_OF_INTEREST,
    ]
  }
  static get _multiTowerSlugOrder() {
    return [
      ProjectElementTypes.TOWER,
      ProjectElementTypes.LEVEL,
      ProjectElementTypes.UNIT,
      ProjectElementTypes.POINT_OF_INTEREST,
    ]
  }
  static get _neighbourhoodSlugOrder() {
    return [
      ProjectElementTypes.NEIGHBOURHOOD_SUBDIVISION,
      ProjectElementTypes.UNIT,
      ProjectElementTypes.POINT_OF_INTEREST,
    ]
  }

  static getSlugFromFirstParentOfType(projectElement, type) {
    if (!(projectElement instanceof BaseProjectElement)) {
      throw new Error(
        `SlugGenerator.instanceof(): Project element ${projectElement} is not an instance of BaseProjectElement class.`
      )
    }

    if (projectElement.projectElementType === type) {
      return projectElement.slug
    } else if (!projectElement.parent()) {
      return undefined
    } else {
      return this.getSlugFromFirstParentOfType(projectElement.parent(), type)
    }
  }
}
export default SlugGenerator
