<template>
  <component :is="popupContentComponent" :project-element="projectElement" />
</template>

<script>
import BaseProjectElement from '@/classes/ProjectStructure/BaseProjectElement'
import PointOfInterestPopupContent from './popups/PointOfInterestPopupContent'
import TowerPopupContent from './popups/TowerPopupContent'
import NeighbourhoodSubdivisionPopupContent from './popups/NeighbourhoodSubdivisionPopupContent'
import UnitPopupContentFactory from './popups/UnitPopupContent/UnitPopupContentFactory'
import MinimalModePopupContent from './popups/MinimalModePopupContent.vue'
import ProjectElementTypes from '@/constants/project-structure/project-element-types'

const popupContentsByProjectElementClassName = {
  [ProjectElementTypes.TOWER]: 'TowerPopupContent',
  [ProjectElementTypes.POINT_OF_INTEREST]: 'PointOfInterestPopupContent',
  [ProjectElementTypes.UNIT]: 'UnitPopupContentFactory',
  [ProjectElementTypes.NEIGHBOURHOOD_SUBDIVISION]:
    'NeighbourhoodSubdivisionPopupContent',
}

export default {
  name: 'ProjectElementPopupContentFactory',
  components: {
    PointOfInterestPopupContent,
    TowerPopupContent,
    UnitPopupContentFactory,
    NeighbourhoodSubdivisionPopupContent,
    MinimalModePopupContent,
  },
  props: {
    projectElement: {
      type: BaseProjectElement,
      required: true,
    },
    minimalContentMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    popupContentComponent() {
      const projectElementClassName = this.projectElement.projectElementType
      const popupContent = this.minimalContentMode
        ? MinimalModePopupContent
        : popupContentsByProjectElementClassName[projectElementClassName]
      if (popupContent) {
        return popupContent
      } else {
        this.$loggingService.logError(
          `ProjectElementPopupContentFactory: there's no PopupContent for elements of type ${projectElementClassName}.`
        )
        return undefined
      }
    },
  },
}
</script>
