import Menu from '@/classes/Navigation/Menu'
import LinkMenuItem from '@/classes/Navigation/MenuItem/LinkMenuItem'
import locationModes from '@/constants/location/modes'
import TowerRouteObjectGenerator from '@/classes/RouteObjectGenerators/TowerRouteObjectGenerator'
import NeighbourhoodSubdivisionRouteObjectGenerator from '@/classes/RouteObjectGenerators/NeighbourhoodSubdivisionRouteObjectGenerator'
import LinkMenuOption from '@/classes/Navigation/MenuOption/LinkMenuOption'
import { MENU_REQUIRED_PARAMS } from '@/constants/navigation/menu-constants'
import DownloableMenuItem from '@/classes/Navigation/MenuItem/DownloableMenuItem'
export default class MenuAdapters {
  static adaptLevelsItem(option, params) {
    const singleTower = params[MENU_REQUIRED_PARAMS.SINGLE_TOWER]
    if (!singleTower) {
      throw new Error(
        'MenuAdapters.adaptLevelsItem: SINGLE_TOWER param is required to adapt levels item'
      )
    }

    const singleTowerRouteObject = TowerRouteObjectGenerator.make(singleTower)

    option.routeParams = {
      ...option.routeParams,
      ...singleTowerRouteObject.params,
    }

    const singleTowerItem = new LinkMenuItem(option)
    return singleTowerItem
  }

  static adaptAmenitiesItem(option, params) {
    const amenitiesGallery =
      params[MENU_REQUIRED_PARAMS.GENERAL_AMENITIES_GALLERY]

    if (!amenitiesGallery) {
      throw new Error(
        'MenuAdapters.adaptLevelsItem: GENERAL_AMENITIES_GALLERY param is required to adapt amenities item'
      )
    }

    const firstAvailableCollectionSlug =
      amenitiesGallery.firstAvailableCollection?.slug

    option.routeParams = {
      ...option.routeParams,
      collectionSlug: firstAvailableCollectionSlug,
      resourceId: '1',
    }

    const amenitiesItem = new LinkMenuItem(option)
    return amenitiesItem
  }

  static adaptLocationItem(option, params) {
    const isLocationCustomMapAvailable =
      params[MENU_REQUIRED_PARAMS.IS_LOCATION_CUSTOM_MAP_AVAILABLE]

    if (isLocationCustomMapAvailable === undefined) {
      throw new Error(
        'MenuAdapters.adaptLocationItem: IS_LOCATION_CUSTOM_MAP_AVAILABLE param is undefined'
      )
    }

    if (isLocationCustomMapAvailable) {
      option.routeParams = {
        ...option.routeParams,
        mode: locationModes.CUSTOM_MAP,
      }
    }

    const locationItem = new LinkMenuItem(option)
    return locationItem
  }

  static adaptNBHSubdivisionsSubmenu(option, params) {
    const subdivisions = params[MENU_REQUIRED_PARAMS.SUBDIVISIONS]

    if (!subdivisions || !Array.isArray(subdivisions)) {
      throw new Error(
        'MenuAdapters.adaptNBHSubdivisionsSubmenu: SUBDIVISIONS array is required to adapt neighbourhoodSubdivisions item'
      )
    }

    const subdivisionsSubMenu = new Menu(option.nameTranslationKey, option.icon)

    subdivisions.forEach((subdivision) => {
      const subdivisionRouteObject = NeighbourhoodSubdivisionRouteObjectGenerator.make(
        subdivision
      )
      const subdivisionOption = new LinkMenuOption({
        name: subdivision.name,
        routeName: subdivisionRouteObject.name,
        routeParams: subdivisionRouteObject.params,
        exact: true,
        order: subdivision.order,
      })

      const subdivisionItem = new LinkMenuItem(subdivisionOption)

      subdivisionsSubMenu.addChild(subdivisionItem)
    })
    return subdivisionsSubMenu
  }

  static adaptTowersSubmenu(option, params) {
    const towers = params[MENU_REQUIRED_PARAMS.TOWERS]

    if (!towers || !Array.isArray(towers)) {
      throw new Error(
        'MenuAdapters.adaptTowersSubmenu: TOWERS array is required to adapt towersSubmenu item'
      )
    }

    const towersSubMenu = new Menu(option.nameTranslationKey, option.icon)

    towers.forEach((tower) => {
      const towerRouteObject = TowerRouteObjectGenerator.make(tower)
      const towerOption = new LinkMenuOption({
        name: tower.name,
        routeName: towerRouteObject.name,
        routeParams: towerRouteObject.params,
        exact: true,
        order: tower.order,
      })

      const towerItem = new LinkMenuItem(towerOption)

      towerItem.setMatchMethod((route) => {
        return tower.levels.some((level) => level.id === route.params.levelId)
      })

      towersSubMenu.addChild(towerItem)
    })
    return towersSubMenu
  }

  static adaptDownloableProjectBrochure(option, params) {
    const projectName = params[MENU_REQUIRED_PARAMS.PROJECT_NAME]
    const projectBrochureUrl = params[MENU_REQUIRED_PARAMS.PROJECT_BROCHURE_URL]

    option.addFileNameParam({
      key: 'projectName',
      value: projectName,
    })

    option.downloadUrl = projectBrochureUrl

    return new DownloableMenuItem(option)
  }
}
