<template>
  <transition-group
    tag="div"
    class="top-bar-controls-group"
    name="top-bar-controls-group__transition"
    mode="out-in"
  >
    <component
      :is="control.component"
      v-for="control in controls"
      :key="control.key"
      v-bind="control.props"
      v-on="{
        [control.eventName]: control.eventHandler,
      }"
    />
  </transition-group>
</template>

<script>
import TopBarControlsSuite from '@/classes/TopBarControls/TopBarControlsSuite'

export default {
  name: 'TopBarControlsGroup',
  props: {
    controls: {
      type: Array,
      required: true,
      validator: (controls) => {
        const checkTopBarControlKeys = true
        return TopBarControlsSuite.isControlsArrayValid(
          controls,
          checkTopBarControlKeys
        )
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.top-bar-controls-group {
  display: flex;
  gap: 16px;
  @at-root #{&}__transition {
    @at-root &-enter-active {
      transition: opacity 300ms ease-out;
      transition-delay: 150ms;
      pointer-events: none;
    }
    @at-root &-leave-active {
      display: none;
    }
    @at-root &-enter {
      opacity: 0;
    }
    @at-root &-move {
      transition: transform 250ms ease-out;
    }
  }
}
</style>
