import Unit from './Unit'
import CollectionSlugs from '@/classes/Gallery/CollectionSlugs'
import { UnitTypes, BrochureFileNameLocaleKeys } from '@/constants/units'
import ProjectType from '@/classes/ProjectType'

export default class AmenitiesUnit extends Unit {
  // eslint-disable-next-line no-unused-vars
  setPrice(price) {
    throw new Error(
      'AmenitiesUnit.setPrice(): It is not allowed to set price for Amenities units.'
    )
  }
  // eslint-disable-next-line no-unused-vars
  setAvailability(status, color) {
    throw new Error(
      'AmenitiesUnit.setAvailability(): It is not allowed to set availability for Amenities units.'
    )
  }
  // eslint-disable-next-line no-unused-vars
  setOfferType(offerType) {
    throw new Error(
      'AmenitiesUnit.setOfferType(): It is not allowed to set offerType for Amenities units.'
    )
  }
  get _defaultGallerySlug() {
    return CollectionSlugs.GALLERY
  }
  get isAmenities() {
    return true
  }
  get brochureFileNameLocaleKey() {
    const projectType = new ProjectType()
    return projectType.switcher({
      [projectType.SINGLETOWER_TYPE]:
        BrochureFileNameLocaleKeys.SINGLETOWER_AMENITIES,
      [projectType.MULTITOWER_TYPE]:
        BrochureFileNameLocaleKeys.MULTITOWER_AMENITIES,
      [projectType.NEIGHBOURHOOD_TYPE]:
        BrochureFileNameLocaleKeys.NEIGHBOURHOOD_AMENITIES,
    })
  }
  get type() {
    return UnitTypes.AMENITIES
  }
  get offerType() {
    return null
  }
}
