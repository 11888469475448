import Unit from './Unit'
import { UnitTypes, BrochureFileNameLocaleKeys } from '@/constants/units'
import ProjectType from '@/classes/ProjectType'
export default class ApartmentUnit extends Unit {
  get brochureFileNameLocaleKey() {
    const projectType = new ProjectType()
    return projectType.switcher({
      [projectType.SINGLETOWER_TYPE]:
        BrochureFileNameLocaleKeys.SINGLETOWER_APARTMENT,
      [projectType.MULTITOWER_TYPE]:
        BrochureFileNameLocaleKeys.MULTITOWER_APARTMENT,
      [projectType.NEIGHBOURHOOD_TYPE]:
        BrochureFileNameLocaleKeys.NEIGHBOURHOOD_APARTMENT,
    })
  }
  get type() {
    return UnitTypes.APARTMENT
  }
}
