import BaseMenuOption from '@/classes/Navigation/MenuOption/BaseMenuOption'

export default class MenuItem {
  constructor(option) {
    if (!(option instanceof BaseMenuOption)) {
      throw new Error(
        'BaseMenuItem.constructor: Option passed is not an instance of class BaseMenuOption'
      )
    }

    this._name = option.name
    this._nameTranslationKey = option.nameTranslationKey
    this._icon = option.icon
    this._nameId = option.nameId
    this._order = option.order
    this._showHintDependingOnInternetConnection =
      option.showHintDependingOnInternetConnection
    this._customMatchMethod = () => false
  }

  get name() {
    return this._name
  }
  get nameTranslationKey() {
    return this._nameTranslationKey
  }
  get icon() {
    return this._icon
  }
  get nameId() {
    return this._nameId
  }
  get order() {
    return this._order
  }
  get showHintDependingOnInternetConnection() {
    return this._showHintDependingOnInternetConnection
  }
}
