<template>
  <div class="feature-list-small">
    <ul class="feature-list-small__list">
      <li
        v-for="feature in filteredFeatures"
        :key="feature.id"
        class="feature-list-small__list__item"
      >
        <AppFeatureListItem
          :text="feature.shortText"
          :icon="feature.icon"
          small
        />
      </li>
    </ul>
  </div>
</template>

<script>
import AppFeatureListItem from '@/components/UIKit/Standard/Atoms/AppFeatureListItem'
import Feature from '@/classes/Feature/Feature'

export default {
  name: 'AppFeatureListSmall',
  components: {
    AppFeatureListItem,
  },
  props: {
    features: {
      type: Array,
      required: true,
      validator: (value) =>
        value.every((feature) => feature instanceof Feature),
    },
  },
  computed: {
    filteredFeatures() {
      return this.features.filter((feature) => feature.showInSmallPopup)
    },
  },
}
</script>

<style lang="scss" scoped>
.feature-list-small {
  @at-root &__list {
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  ul,
  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
}
</style>
