<template>
  <AppSheetProjectElement
    :title="neighbourhoodSubdivision.name"
    :enter-to="neighbourhoodSubdivisionRouteObject"
    :exterior-tour360-to="exteriorTour360RouteObject"
  />
</template>

<script>
import AppSheetProjectElement from '@/components/UIKit/Standard/Organisms/AppSheet/AppSheetProjectElement'
import NeighbourhoodSubdivision from '@/classes/ProjectStructure/NeighbourhoodSubdivision'
import NeighbourhoodSubdivisionRouteObjectGenerator from '@/classes/RouteObjectGenerators/NeighbourhoodSubdivisionRouteObjectGenerator'
import ExteriorTour360RouteObjectGenerator from '@/classes/RouteObjectGenerators/ExteriorTour360RouteObjectGenerator'

export default {
  name: 'NeighbourhoodSubdivisionPopupContent',
  components: {
    AppSheetProjectElement,
  },
  props: {
    projectElement: {
      type: NeighbourhoodSubdivision,
      required: true,
    },
  },
  computed: {
    neighbourhoodSubdivision() {
      return this.projectElement
    },
    neighbourhoodSubdivisionRouteObject() {
      const params = {
        useTransition: true,
        hideUIControlsDuringTransition: true,
      }
      return NeighbourhoodSubdivisionRouteObjectGenerator.make(
        this.neighbourhoodSubdivision,
        params
      )
    },
    hasExteriorTour360() {
      return !!this.neighbourhoodSubdivision.exteriorTour360Url
    },
    exteriorTour360RouteObject() {
      return this.hasExteriorTour360
        ? ExteriorTour360RouteObjectGenerator.make(
            this.neighbourhoodSubdivision
          )
        : undefined
    },
  },
}
</script>
