import FinancingQuoter from '@/classes/Quoter/FinancingQuoter'
import PriceFactory from '@/classes/Price/PriceFactory'
import { OfferTypes } from '@/constants/units'

export default class PaymentMethod {
  constructor({ id, methodName, bookingPrice, financingSettings }) {
    this._methodNameValidation(methodName)

    this._id = id
    this._methodName = methodName

    if (financingSettings) {
      this._financingSettings = {
        ...financingSettings,
        initialPaymentPercentage: parseFloat(
          financingSettings.initialPaymentPercentage
        ),
        deferredPaymentPercentage: parseFloat(
          financingSettings.deferredPaymentPercentage
        ),
      }
    }

    this._bookingPrice = new PriceFactory().makePrice(
      {
        amount: parseFloat(bookingPrice.amount),
        currencyCode: bookingPrice.currencyCode,
      },
      OfferTypes.FOR_SALE
    )

    if (this._financingSettings) {
      this._financingQuoter = new FinancingQuoter(
        this._financingSettings,
        this._bookingPrice.amount
      )
    }
  }

  _methodNameValidation(methodName) {
    if (typeof methodName !== 'string') {
      throw new Error(`PaymentMethod: Invalid methodName ${methodName} type.`)
    }
    if (!methodName.length > 0) {
      throw new Error(`PaymentMethod: methodName is an empty string.`)
    }
  }

  quote(priceAmount) {
    const quoteDate = new Date()
    const financing = this._financingQuoter?.quote(priceAmount, quoteDate)

    return {
      quoteDate,
      paymentOptionName: this._methodName,
      priceAmount,
      bookingPrice: this._bookingPrice,
      ...financing,
    }
  }

  get name() {
    return this._methodName
  }

  get id() {
    return this._id
  }
}
