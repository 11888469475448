<template>
  <section class="container">
    <div class="image">
      <label>Image without LazySrc and Cover mode (Source Landscape)</label>
      <AppImage
        class="image__component"
        src="https://ik.imagekit.io/demo/img/image6.jpeg?tr=w-3840,h-2160"
      />
    </div>
    <div class="image">
      <label>Image without LazySrc and Cover mode (Source Portrait)</label>
      <AppImage
        class="image__component"
        src="https://ik.imagekit.io/demo/img/image6.jpeg?tr=w-2160,h-3840"
      />
    </div>
    <div class="image">
      <label>Contain mode (Source landscape)</label>
      <AppImage
        class="image__component portrait"
        src="https://ik.imagekit.io/demo/img/image7.jpeg?tr=w-3840,h-2160"
        contain
      />
    </div>
    <div class="image">
      <label>Contain mode (Source Portrait)</label>
      <AppImage
        class="image__component"
        src="https://ik.imagekit.io/demo/img/image7.jpeg?tr=w-2160,h-3840"
        contain
      />
    </div>
  </section>
</template>
<script>
import AppImage from '@/components/UIKit/Standard/Atoms/AppImage'

export default {
  name: 'ImageViewer',
  components: {
    AppImage,
  },
}
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: #e9edee;

  .image {
    margin: 0.5rem;
    &__component {
      width: 480px;
      height: 270px;
      &.portrait {
        width: 270px;
        height: 480px;
      }
    }
  }
}
</style>
