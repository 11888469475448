import ProjectElementTypes from '@/constants/project-structure/project-element-types'
import SvgTargetElementIdMap from '@/classes/SvgTargetElementIdMap'
import IdProjectElementMap from '@/classes/IdProjectElementMap'

export default class BaseProjectElement {
  constructor({ id, svgTargetElementId }) {
    this._id = id
    this._svgTargetElementId = svgTargetElementId
    this._root = undefined
    this._parent = undefined
    this._children = {}
    this._validChildElementTypes.forEach((elementType) => {
      this._children[elementType] = new IdProjectElementMap(elementType)
    })
    this._svgTargetElementIdMap = undefined
  }
  get id() {
    return this._id
  }
  get svgTargetElementId() {
    return this._svgTargetElementId
  }
  get svgData() {
    return this._svgTargetElementIdMap
  }
  get projectElementType() {
    throw new Error(
      'projectElementType() is not implemented in base class BaseProjectElement'
    )
  }
  get _validChildElementTypes() {
    return Object.values(ProjectElementTypes)
  }
  setRoot(rootElement) {
    if (this._root) {
      throw new Error(
        'BaseProjectElement.setRoot(): root element is already set.'
      )
    }
    if (!(rootElement instanceof BaseProjectElement)) {
      throw new Error(
        'BaseProjectElement.setRoot(): Invalid rootElement passed!'
      )
    }
    if (rootElement.projectElementType !== ProjectElementTypes.ROOT) {
      throw new Error(
        `BaseProjectElement.setRoot(): elements of type ${rootElement.projectElementType} cannot be a project root element.`
      )
    }
    rootElement.addDescendant(this)
    this._root = rootElement
    this.children().forEach((child) => {
      child.setRoot(rootElement)
    })
  }
  setParent(parentElement) {
    if (this._parent) {
      throw new Error(
        'BaseProjectElement.setParent(): parent element is already set.'
      )
    }
    if (!(parentElement instanceof BaseProjectElement)) {
      throw new Error(
        'BaseProjectElement.setParent(): Invalid parentElement passed!'
      )
    }
    if (parentElement === this) {
      throw new Error(
        'BaseProjectElement.setParent(): An element cannot be a parent of itself.'
      )
    }
    this._parent = parentElement
    if (parentElement.root()) {
      try {
        this.setRoot(parentElement.root())
      } catch (error) {
        throw new Error(
          `BaseProjectElement.setParent(): Error inheriting the parent element root. ${error}`
        )
      }
    }
  }
  addChild(childElement) {
    if (!(childElement instanceof BaseProjectElement)) {
      throw new Error(
        'BaseProjectElement.addChild(): Invalid childElement passed!'
      )
    }
    if (
      !this._validChildElementTypes.includes(childElement.projectElementType)
    ) {
      throw new Error(
        `BaseProjectElement.addChild(): elements of type ${childElement.projectElementType} cannot be children of elements of type ${this.projectElementType}`
      )
    }
    if (this.children().includes(childElement)) {
      throw new Error(
        `BaseProjectElement.addChild(): passed element had already been added as a child element.`
      )
    }
    try {
      childElement.setParent(this)
      this._children[childElement.projectElementType].add(childElement)
      if (this._svgTargetElementIdMap) {
        this._svgTargetElementIdMap.add(childElement)
      }
    } catch (error) {
      throw new Error(
        `BaseProjectElement.addChild(): Error trying to add child. Maybe childElement._parent is already set? ${error}`
      )
    }
  }
  root() {
    return this._root
  }
  parent() {
    return this._parent
  }
  children() {
    return this._validChildElementTypes
      .map((type) => this.childrenOfType(type))
      .flat()
  }
  childrenOfType(type) {
    return this._children[type]?.values
  }
  initSvgData() {
    if (!this._svgTargetElementIdMap) {
      this._svgTargetElementIdMap = new SvgTargetElementIdMap()
    } else {
      throw new Error(
        `BaseProjectElement.initSvgData(): The svgData is already initialized.`
      )
    }
  }
}
