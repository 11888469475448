<template>
  <div class="sheet-preview-logo">
    <AppImage class="sheet-preview-logo__image" :src="logoImageUrl" contain />
  </div>
</template>

<script>
import AppImage from '@/components/UIKit/Standard/Atoms/AppImage'

export default {
  name: 'AppSheetPreviewLogo',
  components: {
    AppImage,
  },
  props: {
    logoImageUrl: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.sheet-preview-logo {
  position: relative;
  aspect-ratio: 322 / 147;
  overflow: hidden;
  background: #fff;
  border-radius: 8px;
  padding: 1.375rem;
  border: 1px solid var(--gray-100);

  @at-root #{&}__image {
    width: 100%;
    height: 100%;
  }
}
</style>
