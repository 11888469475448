<template>
  <div
    class="app-rich-text"
    :class="{ 'app-rich-text--compact': compact }"
    v-html="htmlContent"
  ></div>
</template>

<script>
export default {
  name: 'AppRichText',
  props: {
    htmlContent: {
      type: String,
      required: true,
    },
    compact: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.app-rich-text::v-deep {
  font-size: 0.875rem;
  font-weight: $font-weight-regular;
  color: var(--gray-500);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--gray-700);
    font-weight: $font-weight-medium;
    font-size: 1rem;
    margin: 0 0 1.375em;
  }
  p,
  td {
    margin: 1.375em 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
  b {
    font-weight: $font-weight-bold;
  }
  table {
    width: 100%;
    margin-top: 2.5em;
    border-top: 1px solid var(--gray-100);
  }
  tr:first-child td {
    padding-top: 1em;
  }
  td:last-child {
    text-align: right;
  }
}
.rich-text--compact::v-deep {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.875rem;
  }
}
</style>
