import BaseUnitIdentifierGenerator from './BaseUnitIdentifierGenerator'
import Tower from '@/classes/ProjectStructure/Tower'
import Level from '@/classes/ProjectStructure/Level'
import Unit from '@/classes/ProjectStructure/Unit/Unit'

class MultiTowerUnitIdentifierGenerator extends BaseUnitIdentifierGenerator {
  _getTranslationArguments({ unit }) {
    const level = unit.parent()
    const tower = level.parent()
    return {
      unitId: unit.label,
      name: unit.name,
      level: level.name,
      tower: tower.name,
    }
  }
  _validateGeneratorArguments({ unit }) {
    if (!(unit instanceof Unit)) {
      throw new Error(
        'MultiTowerUnitIdentifierGenerator._validateGeneratorArguments(): passed unit argument is not an instance of Unit class.'
      )
    }
    const level = unit.parent()
    if (!(level instanceof Level)) {
      throw new Error(
        'MultiTowerUnitIdentifierGenerator._validateGeneratorArguments(): Parent of unit is not an instance of Level class.'
      )
    }
    const tower = level.parent()
    if (!(tower instanceof Tower)) {
      throw new Error(
        'MultiTowerUnitIdentifierGenerator._validateGeneratorArguments(): Parent of level is not an instance of Tower class.'
      )
    }
  }
  get _localeGroupKey() {
    return 'multiTowerUnitIdentifiers'
  }
}
export default MultiTowerUnitIdentifierGenerator
