<template>
  <AppSheetUnitMedium
    :title="unitShortIdentifier"
    :enter-to="unitEnterToLink"
    :tour360-to="unitTour360ToLink"
    :features="unitFeatures"
    :with-unavailable-message="shouldDisplayUnavailableMessage"
    :with-check-price-button="shouldDisplayCheckPriceButton"
    :with-embedded-form-button="shouldDisplayEmbeddedFormButton"
    :with-quoter-button="shouldDisplayQuoterButton"
    :availability-status="unitDisplayAvailabilityText"
    :availability-color="unitAvailabilityColor"
    :price="unitPrice"
    :embedded-contact-form-button-text="unitEmbeddedContactFormButtonText"
    @request-price="openUnitInfoRequestPrice"
    @launch-quoter="openUnitQuoter"
    @launch-embedded-contact-form="openUnitEmbeddedContactForm"
  />
</template>

<script>
import Unit from '@/classes/ProjectStructure/Unit/Unit'
import AppSheetUnitMedium from '@/components/UIKit/Standard/Organisms/AppSheet/AppSheetUnitMedium'
import UnitAvailabilityStatusTranslationFactory from '@/classes/UnitAvailabilityStatusTranslationFactory'
import UnitAccessRouterLinks from '@/classes/UnitAccessRouterLinks'
import { mapGetters } from 'vuex'

import modalSupportedComponents from '@/constants/ui-kit/standard/modal/modal-supported-components'

export default {
  name: 'UnitPanelMediumContent',
  components: {
    AppSheetUnitMedium,
  },
  props: {
    projectElement: {
      type: Unit,
      default: () => undefined,
    },
    accessLinksEnabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getUnitPrice: 'Topview/getUnitPrice',
      enableCheckPriceForm: 'Preferences/enableCheckPriceForm',
      displayAvailability: 'Preferences/displayAvailability',
      displayPrice: 'Preferences/displayPrice',
      isQuoterModuleEnabled: 'QuoterModule/isQuoterModuleEnabled',
      canUnitBeQuoted: 'QuoterModule/canUnitBeQuoted',
      isUnitContactEmbeddedFormAvailable:
        'EmbeddedFormsModule/isUnitContactEmbeddedFormAvailable',
      unitEmbeddedContactFormButtonText:
        'EmbeddedFormsModule/unitEmbeddedContactFormButtonText',
      unitEmbeddedContactFormUrl:
        'EmbeddedFormsModule/unitEmbeddedContactFormUrl',
      hideQuoterButton: 'Preferences/hideQuoterButton',
    }),
    unit() {
      return this.projectElement
    },
    unitShortIdentifier() {
      return this.unit.shortIdentifier
    },
    shouldDisplayUnavailableMessage() {
      return this.displayAvailability && !this.unit.available
    },
    unitFeatures() {
      return this.unit.features
    },
    shouldDisplayPrice() {
      return (
        !this.isQuoterModuleEnabled &&
        this.displayPrice &&
        !this.shouldDisplayUnavailableMessage
      )
    },
    unitPrice() {
      return this.shouldDisplayPrice ? this.getUnitPrice(this.unit) : undefined
    },
    shouldDisplayCheckPriceButton() {
      if (
        this.shouldDisplayQuoterButton ||
        this.shouldDisplayEmbeddedFormButton
      ) {
        return false
      }
      return (
        this.enableCheckPriceForm &&
        !this.unitPrice &&
        !this.shouldDisplayUnavailableMessage
      )
    },
    shouldDisplayQuoterButton() {
      if (this.hideQuoterButton) {
        return false
      }
      const canUnitBeQuoted = this.canUnitBeQuoted(this.unit)
      const isUnitExplicitlyUnavailable = this.shouldDisplayUnavailableMessage
      return !isUnitExplicitlyUnavailable && canUnitBeQuoted
    },
    unitAvailabilityColor() {
      return this.displayAvailability ? this.unit.availabilityColor : undefined
    },
    unitDisplayAvailabilityText() {
      return this.displayAvailability
        ? this.$t(
            UnitAvailabilityStatusTranslationFactory.makeTranslationKeyByUnit(
              this.unit
            )
          )
        : undefined
    },
    unitAccessRouterLinks() {
      try {
        return new UnitAccessRouterLinks(this.unit, this.$loggingService)
      } catch (error) {
        this.$loggingService.logError(
          `UnitPanelLargeContent.unitAccessRouterLinks: error creating UnitAccessRouterLinks. ${error.toString()}`
        )
        return null
      }
    },
    unitEnterToLink() {
      return this.accessLinksEnabled
        ? this.unitAccessRouterLinks.default
        : undefined
    },
    unitTour360ToLink() {
      return this.accessLinksEnabled
        ? this.unitAccessRouterLinks.tour360
        : undefined
    },
    shouldDisplayEmbeddedFormButton() {
      if (this.shouldDisplayQuoterButton) {
        return false
      }
      return (
        this.isUnitContactEmbeddedFormAvailable &&
        !this.shouldDisplayUnavailableMessage
      )
    },
  },
  methods: {
    openUnitInfoRequestPrice() {
      this.$store.dispatch('Topview/openUnitInfoRequestForm', {
        unit: this.unit,
        requestPrice: true,
      })
    },
    openUnitQuoter() {
      this.$store.dispatch('QuoterModule/openQuoterModal', this.unit)
    },
    openUnitEmbeddedContactForm() {
      this.$store.dispatch('ModalLauncher/launchModal', {
        component: modalSupportedComponents.AppEmbeddedContactFormModal,
        properties: {
          title: this.unitEmbeddedContactFormButtonText,
          formUrl: this.unitEmbeddedContactFormUrl + this.unit.slug,
        },
      })
    },
  },
}
</script>
