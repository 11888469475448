<template>
  <section class="container">
    <h1>AppSheetPrice</h1>
    <table>
      <tr>
        <th v-for="alignment in alignments" :key="alignment">
          {{ alignment }}
        </th>
      </tr>
      <tr>
        <td v-for="alignment in alignments" :key="alignment">
          <AppSheetPrice :price="examplePrice" :align="alignment" />
        </td>
      </tr>
    </table>
  </section>
</template>
<script>
import BasePrice from '@/classes/Price/BasePrice'
import AppSheetPrice from '@/components/UIKit/Standard/Atoms/AppSheetPrice'
import alignments from '@/constants/ui-kit/standard/alignments'

export default {
  name: 'AppSheetPriceViewer',
  components: {
    AppSheetPrice,
  },
  data() {
    return {
      examplePrice: new BasePrice({
        amount: 2000000,
        currencyCode: 'VND',
      }),
      alignments,
    }
  },
}
</script>
<style lang="scss" scoped>
.container {
  margin: 80px 50px;
}
h1 {
  font-size: 1.5rem;
  margin: 2.5em 0 1.5em;
  border-bottom: 1px solid black;
}
th {
  font-weight: 400;
  font-size: 0.75rem;
}
th,
td {
  padding: 5px 10px;
  min-width: 300px;
}
</style>
