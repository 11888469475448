import ProjectConstants from '@/constants/project-structure/project-constants'

class ProjectType {
  constructor(projectType) {
    if (ProjectType._instance) {
      return ProjectType._instance
    }
    if (!projectType) {
      throw new Error('ProjectType: projectType is required!')
    }
    if (!this._isProjectTypeValid(projectType)) {
      throw new Error('ProjectType: projectType is not included in constants!')
    }
    this._projectType = projectType
    this._registerProjectTypeConstants()
    ProjectType._instance = this
    ProjectType.exist = true
  }
  _isProjectTypeValid(projectType) {
    return Object.values(ProjectConstants.PROJECT_TYPES).includes(projectType)
  }
  _registerProjectTypeConstants() {
    Object.entries(ProjectConstants.PROJECT_TYPES).forEach(
      ([typeInstanceName, typeConstantName]) =>
        (this[typeInstanceName] = typeConstantName)
    )
  }
  is(ACCEPTED_TYPES_ARRAY) {
    return ACCEPTED_TYPES_ARRAY.includes(this._projectType)
  }
  switcher(optionsObject) {
    const optionSelectedByType = optionsObject[this._projectType]
    if (typeof optionSelectedByType === 'function') {
      return optionSelectedByType()
    } else {
      return optionSelectedByType
    }
  }
  get name() {
    return this._projectType
  }
}

export default ProjectType
