import PaymentMethod from '@/classes/Quoter/PaymentMethod'
import modalSupportedComponents from '@/constants/ui-kit/standard/modal/modal-supported-components'

export default {
  async adapterData({ commit }, availabilityData) {
    const { quoterOptions } = availabilityData

    if (quoterOptions && Array.isArray(quoterOptions)) {
      quoterOptions.forEach((rawPaymentMethod) => {
        const newPaymentMethodInstance = new PaymentMethod(rawPaymentMethod)

        commit('addPaymentMethod', newPaymentMethodInstance)
      })
    }
  },
  openQuoterModal({ commit, dispatch, getters }, unitToQuote) {
    if (getters.canUnitBeQuoted(unitToQuote)) {
      commit('setUnitToQuote', unitToQuote)
      dispatch(
        'ModalLauncher/launchModal',
        {
          component: modalSupportedComponents.AppQuoterModal,
          closeCallback: () => {
            commit('setUnitToQuote', undefined)
          },
        },
        { root: true }
      )
    } else {
      this.$loggingService.logError(
        `QuoterModule/openQuoterModal: received unit ${unitToQuote} cannot be quoted.`
      )
    }
  },
}
