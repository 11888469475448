export default {
  storeProject(state, projectRootElement) {
    state.project = projectRootElement
  },
  updateSelectedSvgElement(state, projectElement) {
    state.selectedSvgElement = projectElement
  },
  updateUnitInfoRequestFormDetails(state, unitDetails) {
    state.unitInfoRequestFormDetails = unitDetails
  },
}
