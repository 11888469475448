<template>
  <div class="unit-vimeo-video-player" :style="wrapperStyles">
    <iframe
      :src="vimeoVideoUrl"
      class="unit-vimeo-video-player__iframe"
      frameborder="0"
      allow="fullscreen; picture-in-picture"
      allowfullscreen
    >
    </iframe>
  </div>
</template>
<script>
export default {
  name: 'UnitVimeoVideoPlayer',
  props: {
    leftOffset: {
      type: Number,
      default: 0,
    },
    rightOffset: {
      type: Number,
      default: 0,
    },
    vimeoVideoUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    wrapperStyles() {
      return `--right-offset: ${this.rightOffset}px; --left-offset: ${this.leftOffset}px;`
    },
  },
}
</script>
<style lang="scss" scoped>
.unit-vimeo-video-player {
  --right-offset: 0;
  --left-offset: 0;
  --topbar-height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: var(--topbar-height);
  bottom: 0;
  right: var(--right-offset);
  left: var(--left-offset);
  &__iframe {
    width: 90%;
    max-height: 90%;
    aspect-ratio: 16/9;
    background: transparent;
    transition: 200ms;
  }
}
</style>
