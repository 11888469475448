import Unit from '@/classes/ProjectStructure/Unit/Unit'
import CollectionSlugs from '@/classes/Gallery/CollectionSlugs'
import UnitRouteObjectGenerator from '@/classes/RouteObjectGenerators/UnitRouteObjectGenerator'

export default class UnitAccessRouterLinks {
  constructor(unit) {
    if (!(unit instanceof Unit)) {
      throw new Error('UnitAccessRouterLinks: Invalid unit.')
    }
    this._defaultLink = UnitRouteObjectGenerator.make(unit)
    this._tour360Link = unit.gallery.includes(CollectionSlugs.TOUR360)
      ? UnitRouteObjectGenerator.make(unit, {
          collectionSlug: CollectionSlugs.TOUR360,
        })
      : null
  }
  get default() {
    return this._defaultLink
  }
  get tour360() {
    return this._tour360Link
  }
}
