export default {
  storeAvailabilityGallery(state, availabilityGallery) {
    state.availabilityGallery = availabilityGallery
  },
  storeAvailabilityGallerySvgData(state, availabilityGallerySvgData) {
    state.availabilityGallerySvgData = availabilityGallerySvgData
  },
  storePaymentMethodId(state, paymentMethodId) {
    state.selectedPaymentMethodId = paymentMethodId
  },
  storeSvgPriceTag(state, svgPriceTag) {
    state.priceTags.push(svgPriceTag)
  },
}
