import FullscreenHandlerFactory from '@/classes/FullscreenHandler/FullscreenHandlerFactory'

const FullscreenHandlerPlugin = {}

FullscreenHandlerPlugin.install = function(Vue) {
  Vue.prototype.$fullscreenHandler = Vue.observable(
    new FullscreenHandlerFactory().makeFullscreenHandler(document.body)
  )
}

export default FullscreenHandlerPlugin
