export const MENU_REQUIRED_PARAMS = {
  TOWERS: 'Towers',
  SUBDIVISIONS: 'subdivisions',
  SINGLE_TOWER: 'singleTower',
  GENERAL_AMENITIES_GALLERY: 'generalAmenitiesGallery',
  IS_VIDEO_AVAILABLE: 'isVideoAvailable',
  IS_LOCATION_CUSTOM_MAP_AVAILABLE: 'isLocationCustomMapAvailable',
  IS_GENERAL_AMENITIES_GALLERY_AVAILABLE: 'isGeneralAmenitiesGalleryAvailable',
  IS_AVAILABILITY_VIEW_ENABLED: 'isAvailabilityViewEnabled',
  PROJECT_NAME: 'projectName',
  PROJECT_BROCHURE_URL: 'projectBrochureUrl',
  IS_PROJECT_BROCHURE_URL_AVAILABLE: 'isProjectBrochureUrlAvailable',
  IS_GENERAL_TOUR360_GALLERY_ENABLED: 'isGeneralTour360GalleryEnabled',
}
