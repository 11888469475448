import sassConstants from '@/constants/sass'

export default {
  TOP: sassConstants.placementTop,
  TOP_START: sassConstants.placementTopStart,
  TOP_END: sassConstants.placementTopEnd,
  RIGHT: sassConstants.placementRight,
  BOTTOM: sassConstants.placementBottom,
  BOTTOM_START: sassConstants.placementBottomStart,
  BOTTOM_END: sassConstants.placementBottomEnd,
  LEFT: sassConstants.placementLeft,
}
