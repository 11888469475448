<template>
  <section class="container">
    <h1>Preview Image</h1>
    <AppSheetPreviewImage
      preview-image-url="https://aurora.dev.web3d.page/data/imageforimagegallery/2020/07/image/b9b8011b90db496a893d614b1620ea83"
    />
  </section>
</template>
<script>
import AppSheetPreviewImage from '@/components/UIKit/Standard/Molecules/AppSheetPreviewImage'

export default {
  name: 'ImageViewer',
  components: {
    AppSheetPreviewImage,
  },
}
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  padding: 1rem;
  h1 {
    font-size: 1.5rem;
    margin: 2.5em 0 1.5em;
    border-bottom: 1px solid black;
  }
}
</style>
