import BaseTransitionParams from './BaseTransitionParams'

class TransitionTreeNode {
  constructor() {
    this._routeTransitionParamsMap = {}
  }
  add(transitionParams, destinationRouteNameOrPath) {
    try {
      this._validateTransitionParams(transitionParams)
      this._validateRouteNameOrPath(destinationRouteNameOrPath)
      this._validateIfElementExists(destinationRouteNameOrPath)

      this._routeTransitionParamsMap[
        destinationRouteNameOrPath
      ] = transitionParams
    } catch (error) {
      throw new Error(
        `TransitionTreeNode.add(): Error adding element to ${destinationRouteNameOrPath}. ${error.toString()}`
      )
    }
  }
  to(routeNameOrPath) {
    return this._routeTransitionParamsMap[routeNameOrPath]
  }
  list() {
    return Object.values(this._routeTransitionParamsMap)
  }
  _validateIfElementExists(routeNameOrPath) {
    if (
      this._routeTransitionParamsMap[routeNameOrPath] instanceof
      BaseTransitionParams
    ) {
      throw new Error(
        `TransitionTreeNode._validateIfElementExists(): The transition params to the "${routeNameOrPath}" route already exists.`
      )
    }
  }
  _validateRouteNameOrPath(routeNameOrPath) {
    if (typeof routeNameOrPath !== 'string' || routeNameOrPath.trim() === '') {
      throw new Error(
        `TransitionTreeNode._validateRouteNameOrPath(): routeNameOrPath ${routeNameOrPath} is not a valid string.`
      )
    }
  }
  _validateTransitionParams(transitionParams) {
    if (!(transitionParams instanceof BaseTransitionParams)) {
      throw new Error(
        `TransitionTreeNode._validateTransitionParams(): Passed transitionParams is not an instance of class BaseTransitionParams`
      )
    }
  }
}
export default TransitionTreeNode
