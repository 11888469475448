export const ALLOW_FULLSCREEN_NAVIGATION = 'allowFullscreenNavigation'
export const DISPLAY_MENU_CREDITS = 'displayMenuCredits'
export const DISPLAY_AVAILABILITY = 'displayAvailability'
export const DISPLAY_PRICE = 'displayPrice'
export const ENABLE_CONTACT_FORMS = 'enableContactForms'
export const ENABLE_CHECK_PRICE_FORMS = 'enableCheckPriceForm'
export const ENABLE_INTERACTIVE_BACKGROUND_ZOOM =
  'enableInteractiveBackgroundZoom'
export const ENABLE_UNIT_PRICE_QUOTATION = 'enableUnitPriceQuotation'
export const ENABLE_AVAILABILITY_VIEW = 'enableAvailabilityView'
export const DISPLAY_PRICES_ON_AVAILABILITY_VIEW =
  'enablePricesOnAvailabilityView'
export const ENABLE_GENERAL_TOUR360_GALLERY_VIEW =
  'enableGeneralTour360GalleryView'
export const HIDE_QUOTER_BUTTON = 'hideQuoterButton'
export const USE_TYPOLOGY_FOR_AMENITIES_DESCRIPTION =
  'useTypologyForAmenitiesDescription'
