<template>
  <TopBarControlWrapper>
    <AppDropdown
      :options="options"
      :selected-value="selectedValue"
      :dropdown-style="dropdownStyle"
      :size="size"
      @selectedOptionChange="selectedOptionChange"
    />
  </TopBarControlWrapper>
</template>

<script>
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'
import TopBarControlWrapper from '@/components/Gadgets/TheTopBar/snippets/TopBarControlWrapper'
import AppDropdown from '@/components/UIKit/Standard/Molecules/AppDropdown'

export default {
  name: 'TopBarButtonText',
  components: {
    AppDropdown,
    TopBarControlWrapper,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    selectedValue: {
      type: String,
      required: true,
    },
    dropdownStyle: {
      type: String,
      default: styles.DARK,
      validator: (value) => Object.values(styles).includes(value),
    },
    size: {
      type: String,
      default: sizes.SM,
      validator: (value) => Object.values(sizes).includes(value),
    },
  },
  methods: {
    selectedOptionChange(selectedValue) {
      this.$emit('selectedOptionChange', selectedValue)
    },
  },
}
</script>
