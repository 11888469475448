import BaseFullscreenHandler from '@/classes/FullscreenHandler/BaseFullscreenHandler'

class FullscreenHandler extends BaseFullscreenHandler {
  constructor(fullscreenElement) {
    super(fullscreenElement)
    this._isFullscreen = this._isNativeFullscreenActive()
    this._setFullscreenChangeListeners()
  }
  get supportsFullscreenNavigation() {
    return true
  }
  fullscreen() {
    this._requestNativeFullscreen()
  }
  exit() {
    if (this._isNativeFullscreenActive()) {
      this._exitNativeFullscreen()
    }
  }
  _setFullscreenChangeListeners() {
    this._fullscreenElement.addEventListener(
      'fullscreenchange',
      this._onNativeFullscreenChange.bind(this)
    )
    this._fullscreenElement.addEventListener(
      'webkitfullscreenchange',
      this._onNativeFullscreenChange.bind(this)
    )
  }
  _onNativeFullscreenChange() {
    this._setFullscreenStatus(this._isNativeFullscreenActive())
  }
  _requestNativeFullscreen() {
    if (this._fullscreenElement.requestFullscreen) {
      this._fullscreenElement.requestFullscreen()
    } else if (this._fullscreenElement.webkitRequestFullscreen) {
      this._fullscreenElement.webkitRequestFullscreen()
    }
  }
  _exitNativeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    }
  }
  _isNativeFullscreenActive() {
    return !!(document.fullscreenElement || document.webkitFullscreenElement)
  }
}

export default FullscreenHandler
