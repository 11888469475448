<template>
  <div v-click-outside="collapseMenu" class="share-menu">
    <AppButtonIcon
      :icon="shareIcon"
      :size="shareButtonSize"
      :button-style="shareButtonStyle"
      @click.native="toggleCollapse"
    />
    <transition-group name="share-menu-transition">
      <div
        v-if="isFeedbackVisible"
        key="feedback"
        class="share-menu__feedback"
        :class="[feedbackAlignmentClassName]"
      >
        <span>{{ feedbackText }}</span>
      </div>
      <AppIconGroup
        v-if="!collapsed"
        key="icon-group"
        class="share-menu__icon-group"
        :class="[menuAlignmentClassName]"
      >
        <AppButtonIconExternalLink
          v-if="facebookUrl"
          class="share-menu__icon-group__button-icon"
          :icon="facebookIcon"
          :button-style="iconGroupStyle"
          :size="iconGroupSize"
          :href="facebookUrl"
          target="_blank"
          square
        />
        <AppButtonIcon
          v-if="navigatorHasClipboard"
          class="share-menu__icon-group__button-icon"
          :icon="copyLinkIcon"
          :button-style="iconGroupStyle"
          :size="iconGroupSize"
          square
          @click.native="copyUrlToClipboard"
        />
        <AppButtonIconExternalLink
          v-if="whatsappUrl"
          class="share-menu__icon-group__button-icon"
          :icon="whatsappIcon"
          :button-style="iconGroupStyle"
          :size="iconGroupSize"
          :href="whatsappUrl"
          square
        />
      </AppIconGroup>
    </transition-group>
  </div>
</template>
<script>
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'
import alignments from '@/constants/ui-kit/standard/alignments'
import AppButtonIcon from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonIcon'
import AppButtonIconExternalLink from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonIconExternalLink'
import AppIconGroup from '@/components/UIKit/Standard/Atoms/AppIconGroup'
import iconNames from '@/constants/ui-kit/standard/icon-names'
import {
  FacebookShareLinkGenerator,
  WhatsappShareLinkGenerator,
} from '@/classes/ShareLinkGenerator'

export default {
  name: 'AppShareMenu',
  components: {
    AppButtonIcon,
    AppButtonIconExternalLink,
    AppIconGroup,
  },
  props: {
    menuAlignment: {
      type: String,
      default: alignments.ALIGN_LEFT,
      validator: (value) =>
        [alignments.ALIGN_LEFT, alignments.ALIGN_RIGHT].includes(value),
    },
    shareUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      collapsed: true,

      shareIcon: iconNames.UIShare,
      shareButtonStyle: styles.LIGHT,
      shareButtonSize: sizes.SM,

      facebookIcon: iconNames.UIFacebook,
      whatsappIcon: iconNames.UIWhatsapp,
      copyLinkIcon: iconNames.UILink,
      iconGroupSize: sizes.SM,
      iconGroupStyle: styles.LINK,

      isFeedbackVisible: false,
      feedbackText: undefined,
      feedbackTimer: undefined,
      feedbackDuration: 2000,
    }
  },
  computed: {
    menuAlignmentClassName() {
      return `share-menu__icon-group--${this.menuAlignment}`
    },
    feedbackAlignmentClassName() {
      return `share-menu__feedback--${this.menuAlignment}`
    },
    facebookUrl() {
      return new FacebookShareLinkGenerator(this.shareUrl).getHref()
    },
    whatsappUrl() {
      return new WhatsappShareLinkGenerator(this.shareUrl).getHref()
    },
    navigatorHasClipboard() {
      return !!navigator.clipboard
    },
    navigatorCanShare() {
      return !!navigator.canShare && navigator.canShare({ url: this.shareUrl })
    },
  },
  methods: {
    toggleCollapse() {
      if (this.collapsed) {
        this.openShareMenu()
      } else {
        this.collapseMenu()
      }
    },
    openShareMenu() {
      if (this.navigatorCanShare) {
        navigator.share({ url: this.shareUrl })
      } else {
        this.collapsed = false
      }
    },
    collapseMenu() {
      this.collapsed = true
    },
    copyUrlToClipboard() {
      navigator.clipboard
        .writeText(this.shareUrl)
        .then(() => {
          const successfullCopyInClipboardMessage = this.$t(
            'La dirección fue copiada al portapapeles'
          )
          this.showFeedback(successfullCopyInClipboardMessage)
        })
        .catch((err) => {
          const wrongCopyInClipboardMessage = this.$t(
            'No se pudo copiar la dirección al portapapeles'
          )
          this.showFeedback(wrongCopyInClipboardMessage)

          this.$loggingService.logError('Could not copy url to share: ', err)
        })
        .finally(() => {
          this.collapseMenu()
        })
    },
    showFeedback(message) {
      clearTimeout(this.feedbackTimer)
      this.feedbackText = message
      this.isFeedbackVisible = true
      this.feedbackTimer = setTimeout(() => {
        this.hideFeedback()
      }, this.feedbackDuration)
    },
    hideFeedback() {
      this.isFeedbackVisible = false
      this.feedbackText = undefined
    },
  },
}
</script>
<style lang="scss" scoped>
.share-menu {
  position: relative;
  width: fit-content;
  &__feedback {
    font-size: 0.75rem;
    color: #fff;
    background: #344054e5;
    padding: 6px 16px;
    border-radius: 90px;
    width: max-content;
    @include noselect;
  }
  &__icon-group,
  &__feedback {
    bottom: 100%;
    position: absolute;
    margin-bottom: 5px;
    z-index: 10;
    &--#{$align-left} {
      left: 0;
    }
    &--#{$align-right} {
      right: 0;
    }
    &__button-icon {
      &:hover {
        background: var(--gray-50);
      }
    }
  }
}

.share-menu-transition-enter-active,
.share-menu-transition-leave-active {
  transition: transform 300ms cubic-bezier(0.25, 0.1, 0.5, 1),
    opacity 200ms cubic-bezier(0.25, 0.1, 0.5, 1);
}
.share-menu-transition-enter,
.share-menu-transition-leave-to {
  opacity: 0;
}
.share-menu-transition-enter {
  transform: translate3d(0, 10px, 0);
}
.share-menu-transition-leave-active {
  transition: opacity 150ms ease-out;
}
</style>
