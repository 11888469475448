import VideoTransitionParams from './VideoTransitionParams'
import AnimationTransitionParams from './AnimationTransitionParams'

export default class TransitionParamsFactory {
  static make(type, { url, effect }) {
    const typeTransitionParamsMap = {
      [VideoTransitionParams.TYPE]: VideoTransitionParams,
      [AnimationTransitionParams.TYPE]: AnimationTransitionParams,
    }
    const TransitionParams = typeTransitionParamsMap[type]
    if (!TransitionParams) {
      throw new Error(`TransitionParamsFactory: Invalid type ${type}`)
    }
    return new TransitionParams({ url, effect })
  }
}
