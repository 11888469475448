<template>
  <div class="sheet-block-title-and-availability">
    <AppSheetTitle
      class="sheet-block-title-and-availability__title"
      :title="title"
      :subtitle="subtitle"
      :align="titleAlignment"
    />
    <AppBadge
      v-if="displayAvailabilityBadge"
      class="sheet-block-title-and-availability__availability"
      :text="availabilityStatus"
      :color="availabilityColor"
    />
  </div>
</template>

<script>
import AppSheetTitle from '@/components/UIKit/Standard/Atoms/AppSheetTitle'
import AppBadge from '@/components/UIKit/Standard/Atoms/AppBadge'
import alignments from '@/constants/ui-kit/standard/alignments'

export default {
  name: 'SheetBlockTitleAndAvailability',
  components: {
    AppSheetTitle,
    AppBadge,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: undefined,
    },
    availabilityStatus: {
      type: String,
      default: undefined,
    },
    availabilityColor: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      titleAlignment: alignments.ALIGN_LEFT,
    }
  },
  computed: {
    displayAvailabilityBadge() {
      return !!this.availabilityColor && !!this.availabilityStatus
    },
  },
}
</script>

<style lang="scss" scoped>
.sheet-block-title-and-availability {
  display: flex;
  align-items: flex-start;
  @at-root #{&}__title {
    flex: 1;
  }
  @at-root #{&}__availability {
    height: fit-content;
  }
}
</style>
