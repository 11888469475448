<template>
  <div class="dropdown-list" :class="[styleClassName, sizeClassName]">
    <div
      v-for="option in options"
      :key="option.value"
      class="dropdown-list__option"
      :class="{ disabled: option.disabled, selected: markAsSelected(option) }"
      @click="optionClicked(option.value)"
      @mouseover="disableSelectedOptionIndicator"
    >
      {{ option.text }}
    </div>
  </div>
</template>
<script>
import sizes from '@/constants/ui-kit/standard/sizes'
import styles from '@/constants/ui-kit/standard/styles'

export default {
  name: 'AppDropdownList',
  props: {
    dropdownStyle: {
      type: String,
      default: styles.DARK,
      validator: (value) => Object.values(styles).includes(value),
    },
    size: {
      type: String,
      default: sizes.SM,
      validator: (value) => Object.values(sizes).includes(value),
    },
    options: {
      type: Array,
      required: true,
      validator: function(array) {
        return array.every((item) => !!item.value && !!item.text)
      },
    },
    selectedOption: {
      type: Object,
      default: undefined,
      validator: function(option) {
        return !!option.value && !!option.text
      },
    },
  },
  data() {
    return {
      isSelectedOptionIndicatorActive: true,
    }
  },
  computed: {
    styleClassName() {
      return `dropdown-list--${this.dropdownStyle}`
    },
    sizeClassName() {
      return `dropdown-list--${this.size}`
    },
  },
  methods: {
    optionClicked(option) {
      this.$emit('option-clicked', option)
    },
    disableSelectedOptionIndicator() {
      this.isSelectedOptionIndicatorActive = false
    },
    markAsSelected(option) {
      return (
        this.isSelectedOptionIndicatorActive &&
        option.value === this.selectedOption.value
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.dropdown-list {
  border-radius: 12px;
  overflow: hidden;
  @include noselect;

  $root-class: &;

  #{$root-class}__option {
    cursor: pointer;
    padding: 10px 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.disabled {
      cursor: default;
      pointer-events: none;
    }
  }

  @at-root &--#{$xs} {
    font-size: 0.875rem;
  }
  @at-root &--#{$sm} {
    font-size: 0.875rem;
  }
  @at-root &--#{$md} {
    font-size: 1rem;
  }
  @at-root &--#{$lg} {
    font-size: 1rem;
  }
  @at-root &--#{$xl} {
    font-size: 1.125rem;
  }

  @at-root &--#{$primary} {
    color: #fff;
    background-color: var(--primary-color);
    #{$root-class}__option {
      &.selected,
      &:hover {
        background: var(--primary-color-700);
      }
      &.disabled {
        color: rgba(255, 255, 255, 0.4);
        background-color: var(--primary-color-100);
      }
    }
  }
  @at-root &--#{$secondary} {
    color: var(--primary-color);
    background-color: var(--primary-color-50);
    box-shadow: 0px 1px 2px var(--primary-color-25);
    #{$root-class}__option {
      &.selected,
      &:hover {
        background: var(--primary-color-100);
      }
      &.disabled {
        color: var(--primary-color-300);
        background-color: var(--primary-color-25);
      }
    }
  }
  @at-root &--#{$dark} {
    color: #fff;
    background-color: var(--gray-700);
    #{$root-class}__option {
      &.selected,
      &:hover {
        background: var(--gray-800);
      }
      &.disabled {
        color: rgba(255, 255, 255, 0.4);
        background-color: var(--gray-300);
      }
    }
  }
  @at-root &--#{$light} {
    color: var(--gray-700);
    background-color: #fff;
    border: 1px solid var(--gray-300);
    box-shadow: 0px 1px 2px var(--primary-color-25);
    #{$root-class}__option {
      &.selected,
      &:hover {
        background: var(--gray-50);
      }
      &.disabled {
        color: var(--gray-300);
        background-color: #fff;
      }
    }
  }
  @at-root &--#{$extra-light} {
    color: var(--gray-700);
    background-color: var(--gray-100);
    #{$root-class}__option {
      &.selected,
      &:hover {
        background: var(--gray-200);
      }

      &.disabled {
        color: var(--gray-300);
        background-color: var(--gray-50);
      }
    }
  }
  @at-root &--#{$primary-link} {
    color: var(--primary-color);
    background-color: transparent;
    #{$root-class}__option {
      &.selected,
      &:hover {
        background: var(--primary-color-25);
      }
      &.disabled {
        color: var(--primary-color-300);
      }
    }
  }
  @at-root &--#{$link} {
    color: var(--gray-500);
    background-color: transparent;
    #{$root-class}__option {
      &.selected,
      &:hover {
        background: var(--gray-50);
      }
      &.disabled {
        color: var(--gray-300);
      }
    }
  }
}
</style>
