<template>
  <section class="base-modal">
    <div class="base-modal__overlay" @click.stop="hide"></div>
    <AppScrollbarContainer
      class="base-modal__modal"
      :class="{ 'base-modal__modal--expand': expandContent }"
      :style="{ width }"
    >
      <div class="base-modal__modal__head">
        <h2 class="base-modal__modal__title">
          {{ title }}
        </h2>
        <AppButtonIcon
          class="base-modal__modal__close-button"
          :icon="closeButtonIcon"
          :size="closeButtonSize"
          :button-style="closeButtonStyle"
          @click.native="hide"
        />
      </div>
      <div class="base-modal__modal__body">
        <slot></slot>
      </div>
    </AppScrollbarContainer>
  </section>
</template>

<script>
import AppScrollbarContainer from '@/components/UIKit/Standard/Atoms/AppScrollbarContainer'
import responsiveMixin from '@/mixins/responsiveMixin'
import AppButtonIcon from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonIcon'
import iconNames from '@/constants/ui-kit/standard/icon-names'
import styles from '@/constants/ui-kit/standard/styles'
import sizes from '@/constants/ui-kit/standard/sizes'

export default {
  name: 'AppBaseModal',
  components: {
    AppButtonIcon,
    AppScrollbarContainer,
  },
  mixins: [responsiveMixin],
  props: {
    title: {
      type: String,
      default: undefined,
    },
    modalWidth: {
      type: Number,
      default: undefined,
    },
    expandContent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      closeButtonStyle: styles.LINK,
      closeButtonSize: sizes.XS,
      closeButtonIcon: iconNames.UIClose,
    }
  },
  computed: {
    width() {
      return this.modalWidth && !this.isSmallScreen
        ? `${this.modalWidth}px`
        : '100%'
    },
  },
  methods: {
    hide() {
      // FIXME: It would be nice to store input data before modal close to prevent user from losing their changes if they accidentally close it
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.base-modal {
  position: fixed;
  inset: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px;

  @include small-screens {
    padding: 0;
    justify-content: stretch;
    align-items: stretch;
  }

  @at-root &__modal {
    position: relative;
    z-index: 1;
    background-color: #fff;
    border-radius: 0.75rem;
    padding: 0 30px 48px;
    border-bottom: 2px solid #fff;
    max-height: 100%;
    max-width: 100%;

    @include small-screens {
      overflow: auto;
      border-radius: 0;
    }

    @at-root &__head {
      background: #fff;
      padding: 30px 0;
      position: sticky;
      top: 0;
      z-index: 5;
    }

    @at-root &__title {
      font-size: 1.125rem;
      color: var(--gray-700);
      margin: 0 30px 0 0;
    }

    @at-root &__close-button {
      position: absolute;
      top: 50%;
      right: -10px;
      transform: translateY(-50%);
    }

    $head: #{&}__head;
    $close-button: #{&}__close-button;
    @at-root &--expand {
      border-bottom: 0.75rem solid #fff;
      border-top: 0.75rem solid #fff;
      padding: 0;

      #{$head} {
        padding: calc(30px - 0.75rem) 30px;
        border-bottom: 1px solid var(--gray-100);
      }

      #{$close-button} {
        right: 20px;
        top: calc(50% - 0.325rem);
      }
    }
  }

  @at-root &__overlay {
    position: absolute;
    inset: 0;
    z-index: 0;
    background-color: rgba(0, 0, 0, 0.45);
  }
}
</style>
