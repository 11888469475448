import { UnitTypes } from '@/constants/units'

class BaseUnitQuoteFileNameGenerator {
  constructor(i18n) {
    this._validateI18nIsValid(i18n)
    this._i18n = i18n
  }
  makeQuoteFileName(unit, projectName) {
    if (!projectName) {
      throw new Error(
        'QuoteFileNameGenerator.makeQuoteFileName(): projectName is required.'
      )
    }
    this._validateUnit(unit)
    const translationKey = this._getQuoteFileNameLocaleKey(unit.type)
    const translationArguments = {
      ...this._getTranslationArguments(unit),
      projectName,
    }
    return this._i18n.t(translationKey, translationArguments)
  }

  _getQuoteFileNameLocaleKey(unitType) {
    const localeKey = this._quoteFileNameLocaleKeys[unitType]
    if (localeKey === undefined) {
      throw new Error(
        `QuoteFileNameGenerator._getQuoteFileNameLocaleKey(): there's no quote file name locale key for unitType ${unitType}.`
      )
    }
    return localeKey
  }
  // eslint-disable-next-line no-unused-vars
  _getTranslationArguments(unit) {
    throw new Error(
      '_getTranslationArguments is not implemented in base class BaseUnitQuoteFileNameGenerator.'
    )
  }
  // eslint-disable-next-line no-unused-vars
  _validateUnit(unit) {
    throw new Error(
      '_validateUnit is not implemented in base class BaseUnitQuoteFileNameGenerator.'
    )
  }
  _validateI18nIsValid(i18n) {
    if (
      typeof i18n !== 'object' ||
      typeof i18n.t !== 'function' ||
      typeof i18n.te !== 'function'
    ) {
      throw new Error(
        `QuoteFileNameGenerator._validateI18nIsValid(): i18n passed is not an object or it does not have t functions`
      )
    }
  }
  get _quoteFileNameLocaleKeys() {
    return {
      [UnitTypes.APARTMENT]: `${this._localeGroupKey}.apartmentQuoteFileName`,
      [UnitTypes.OFFICE]: `${this._localeGroupKey}.officeQuoteFileName`,
      [UnitTypes.SHOP]: `${this._localeGroupKey}.shopQuoteFileName`,
      [UnitTypes.HOUSE]: `${this._localeGroupKey}.houseQuoteFileName`,
    }
  }
  get _localeGroupKey() {
    throw new Error(
      '_localeGroupKey is not implemented in base class BaseUnitQuoteFileNameGenerator.'
    )
  }
}
export default BaseUnitQuoteFileNameGenerator
