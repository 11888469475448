<template>
  <TheTopBar :controls="controls" with-fullscreen-button>
    <template #pointer-events-disabled-relative-slot>
      <TopBarLabel v-if="!!labelText" align="left">
        {{ labelText }}
      </TopBarLabel>
    </template>
  </TheTopBar>
</template>

<script>
import TheTopBar from '@/components/Gadgets/TheTopBar'
import TopBarControlsSuite from '@/classes/TopBarControls/TopBarControlsSuite'
import TopBarLabel from '@/components/Gadgets/TheTopBar/snippets/TopBarLabel'
import TopBarDropdownGenerator from '@/classes/TopBarControls/TopBarDropdownGenerator'
import responsiveMixin from '@/mixins/responsiveMixin'

export default {
  name: 'GeneralAmenitiesGalleryTopBarControls',
  components: {
    TheTopBar,
    TopBarLabel,
  },
  mixins: [responsiveMixin],
  props: {
    labelText: {
      type: String,
      default: undefined,
    },
    selectedTour360: {
      type: String,
      default: undefined,
    },
    tour360SelectOptions: {
      type: Array,
      default: () => [],
    },
    shouldTour360SelectorBeShown: {
      type: Boolean,
      default: undefined,
    },
    galleryCollectionsOptions: {
      type: Array,
      default: () => [],
    },
    selectedGallerySlug: {
      type: String,
      required: true,
    },
  },
  computed: {
    controls() {
      return new TopBarControlsSuite({
        rightControls: [
          TopBarDropdownGenerator.create({
            selectedValue: this.selectedGallerySlug,
            options: this.galleryCollectionsOptions,
            show: () =>
              !this.isSmallScreen && this.galleryCollectionsOptions.length > 1,
            eventHandler: (e) =>
              this.$emit('redirect-to-gallery-collection', e),
          }),
          TopBarDropdownGenerator.create({
            selectedValue: this.selectedTour360,
            options: this.tour360SelectOptions,
            show: () => this.shouldTour360SelectorBeShown,
            eventHandler: (e) => this.$emit('navigate-to-tour360', e),
          }),
        ],
      })
    },
  },
}
</script>
