import Menu from '@/classes/Navigation/Menu'
import BaseProjectRootElement from '@/classes/ProjectStructure/BaseProjectRootElement'
import GalleryPageRouteObjectGenerator from '@/classes/RouteObjectGenerators/GalleryPageRouteObjectGenerator'
import ExteriorTour360RouteObjectGenerator from '@/classes/RouteObjectGenerators/ExteriorTour360RouteObjectGenerator'
import UnitRouteObjectGenerator from '@/classes/RouteObjectGenerators/UnitRouteObjectGenerator'
import ProjectElementTypes from '@/constants/project-structure/project-element-types'

class PageNameGenerator {
  constructor(i18n, mainMenu, projectRootElement) {
    this._validateI18nIsValid(i18n)
    this._validateMainMenuIsValid(mainMenu)
    this._validateProjectRootElementIsValid(projectRootElement)

    this._i18n = i18n
    this._mainMenu = mainMenu
    this._projectRootElement = projectRootElement

    this._pageNameGeneratorSpecialMethods = {
      [GalleryPageRouteObjectGenerator.ROUTE_NAME]: this
        ._pageNameGeneratorMethodForProjectGallery,
      [ExteriorTour360RouteObjectGenerator.ROUTE_NAME]: this
        ._pageNameGeneratorMethodForExteriorTour360,
      [UnitRouteObjectGenerator.ROUTE_NAME]: this
        ._pageNameGeneratorMethodForUnits,
    }
  }
  makePageName(route) {
    const specialMethod = this._getPageNameGeneratorSpecialMethod(route.name)
    if (specialMethod) {
      return specialMethod(route)
    }
    return this._pageNameGeneratorMethodForMenuListedOptions(route)
  }
  _getPageNameGeneratorSpecialMethod(routeName) {
    return this._pageNameGeneratorSpecialMethods[routeName]?.bind(this)
  }
  // eslint-disable-next-line no-unused-vars
  _pageNameGeneratorMethodForProjectGallery(route) {
    return this._i18n.t('Galería')
  }
  // eslint-disable-next-line no-unused-vars
  _pageNameGeneratorMethodForExteriorTour360(route) {
    return this._i18n.t('Vista aérea')
  }
  _pageNameGeneratorMethodForUnits(route) {
    const unitElement = this._projectRootElement
      .getDescendantsOfType(ProjectElementTypes.UNIT)
      .get(route.params.unitId)

    return unitElement?.longIdentifier
  }
  _pageNameGeneratorMethodForMenuListedOptions(route) {
    const activeMenuItem = this._findActiveMenuItem(
      this._mainMenu.children,
      route
    )
    if (activeMenuItem) {
      return (
        activeMenuItem.name || this._i18n.t(activeMenuItem.nameTranslationKey)
      )
    }
  }
  _findActiveMenuItem(menuItems, route) {
    const activeMenuItem = menuItems.find((menuItem) => {
      return menuItem.match(route)
    })

    if (activeMenuItem?.children) {
      return this._findActiveMenuItem(activeMenuItem.children, route)
    }

    return activeMenuItem
  }
  _validateI18nIsValid(i18n) {
    if (
      typeof i18n !== 'object' ||
      typeof i18n.t !== 'function' ||
      typeof i18n.te !== 'function'
    ) {
      throw new Error(
        'PageNameGenerator: i18n passed is not an object or it does not have t functions'
      )
    }
  }
  _validateMainMenuIsValid(mainMenu) {
    if (!(mainMenu instanceof Menu)) {
      throw new Error(`PageNameGenerator: Invalid mainMenu ${mainMenu}.`)
    }
  }
  _validateProjectRootElementIsValid(projectRootElement) {
    if (!(projectRootElement instanceof BaseProjectRootElement)) {
      throw new Error(
        `PageNameGenerator: Invalid projectRootElement ${projectRootElement}.`
      )
    }
  }
}

export default PageNameGenerator
