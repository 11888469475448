import NeighbourhoodSubdivision from '@/classes/ProjectStructure/NeighbourhoodSubdivision'

export default class NeighbourhoodSubdivisionAdapter {
  static adapt(neighbourhoodSubdivisionJsonData) {
    const {
      neigbourhoodSectionId,
      order,
      svgTargetElementId,
      displayName,
      clusterSlug,
      enterTransitionVideos,
      backgroundImgUrl,
      backgroundSvgUrl,
      exteriorTour360,
    } = neighbourhoodSubdivisionJsonData

    const adaptedNeighbourhoodSubdivision = new NeighbourhoodSubdivision({
      id: `${neigbourhoodSectionId}`,
      order,
      svgTargetElementId,
      name: displayName,
      slug: clusterSlug,
      exteriorTour360Url: exteriorTour360?.tour360Url,
      image: backgroundImgUrl,
      svg: backgroundSvgUrl,
    })

    enterTransitionVideos.forEach((enterTransitionVideo) => {
      adaptedNeighbourhoodSubdivision.enterTransitionVideos.add(
        enterTransitionVideo.stopPointSlug,
        enterTransitionVideo.videoUrl
      )
    })

    return adaptedNeighbourhoodSubdivision
  }
}
