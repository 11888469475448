export default {
  launchSvgPanel({ commit }, svgElement) {
    commit('updateSelectedSvgElement', svgElement)
  },
  hideSvgElementPanel({ commit }) {
    commit('updateSelectedSvgElement', undefined)
  },
  launchSvgPopup(
    { commit, dispatch },
    { svgElement, positionX, positionY, svgWidth, svgHeight }
  ) {
    dispatch('clearHideSvgElementPopupDelay')

    commit('updateSvgElementToPreview', svgElement)
    commit('updatePreviewPositionX', positionX)
    commit('updatePreviewPositionY', positionY)
    commit('updateSvgWidth', svgWidth)
    commit('updateSvgHeight', svgHeight)
  },
  hideSvgElementPopup({ dispatch, commit }) {
    dispatch('clearHideSvgElementPopupDelay')
    commit('updateSvgElementToPreview', undefined)
    commit('updatePreviewPositionX', undefined)
    commit('updatePreviewPositionY', undefined)
    commit('updateSvgWidth', undefined)
    commit('updateSvgHeight', undefined)
  },
  setHideSvgElementPopupDelay({ commit, dispatch }) {
    dispatch('clearHideSvgElementPopupDelay')

    const hideSvgElementPopupDelay = setTimeout(() => {
      dispatch('hideSvgElementPopup')
    }, 800)

    commit('updateHidePreviewDelay', hideSvgElementPopupDelay)
  },
  clearHideSvgElementPopupDelay({ state }) {
    clearTimeout(state.hidePreviewDelay)
  },
}
