import IntegerValueFeature from './IntegerValueFeature'
import BooleanValueFeature from './BooleanValueFeature'
import DecimalValueFeature from './DecimalValueFeature'
import StringValueFeature from './StringValueFeature'

export default class FeatureFactory {
  constructor() {
    this.featureClasses = {
      Integer: IntegerValueFeature,
      Boolean: BooleanValueFeature,
      Decimal: DecimalValueFeature,
      String: StringValueFeature,
    }
  }
  makeFeature(featureData, i18n) {
    const FeatureClassToUse = this.featureClasses[featureData.valueType]
    try {
      return new FeatureClassToUse(featureData, i18n)
    } catch (error) {
      throw new Error(
        `FeatureFactory: There was a problem creating Feature with id ${featureData.id} of valueType ${featureData.valueType}: ${error}`
      )
    }
  }
}
