import httpClient from '@/constants/api/http-client'
import RouteObjectGeneratorFactory from '@/classes/RouteObjectGenerators/RouteObjectGeneratorFactory'
import TransitionParamsFactory from '@/classes/TransitionTree/TransitionParamsFactory'
import VideoTransitionParams from '@/classes/TransitionTree/VideoTransitionParams'
import VideoTransitionDownloadManager from '@/classes/VideoTransitionDownloadManager'
import router from '@/router'
import MasterplanRouteObjectGenerator from '@/classes/RouteObjectGenerators/MasterplanRouteObjectGenerator'
import HomeRouteObjectGenerator from '@/classes/RouteObjectGenerators/HomeRouteObjectGenerator'
import stopPointSlugs from '@/constants/stop-point-slugs'

const DownloadManagerActions = {
  async adapterData({ dispatch, commit }, { projectData }) {
    const videoTransitionDownloadManager = new VideoTransitionDownloadManager()
    commit(
      'storeVideoTransitionDownloadManager',
      videoTransitionDownloadManager
    )

    dispatch('addMainSpin360Transitions', projectData.projectStructure.spin360)
    dispatch('downloadSprites', projectData.sprites)
  },
  async downloadTransitionVideos({ getters }, { fromRouteName }) {
    const transitionTree = getters.transitionTree
    const transitionsFromRouteName = transitionTree.from(fromRouteName)
    if (transitionsFromRouteName) {
      const videoTransitionParams = transitionsFromRouteName
        .list()
        .filter(
          (transitionParams) =>
            transitionParams.type === VideoTransitionParams.TYPE
        )
      const { videoTransitionDownloadManager } = getters
      videoTransitionDownloadManager.updateQueue(videoTransitionParams)
      videoTransitionDownloadManager.startDownloads()
    }
  },
  addMainSpin360Transitions({ getters }, spin360Data) {
    const MASTERPLAN_ROUTE_OBJECTS = {
      [stopPointSlugs.MASTERPLAN_0]: MasterplanRouteObjectGenerator.make(),
      [stopPointSlugs.MASTERPLAN_120]: MasterplanRouteObjectGenerator.make({
        stopPointSlug: stopPointSlugs.MASTERPLAN_120,
      }),
      [stopPointSlugs.MASTERPLAN_240]: MasterplanRouteObjectGenerator.make({
        stopPointSlug: stopPointSlugs.MASTERPLAN_240,
      }),
    }

    const transitionTree = getters.transitionTree

    const enterSpinTransitionVideoUrl = spin360Data.enterTransitionVideoUrl

    if (enterSpinTransitionVideoUrl) {
      const transitionParamsObject = TransitionParamsFactory.make('video', {
        url: enterSpinTransitionVideoUrl,
      })

      transitionTree.add(
        transitionParamsObject,
        HomeRouteObjectGenerator.ROUTE_NAME,
        MasterplanRouteObjectGenerator.ROUTE_NAME
      )
    } else {
      const transitionParamsObject = TransitionParamsFactory.make('animation', {
        effect: 'fade',
      })

      transitionTree.add(
        transitionParamsObject,
        HomeRouteObjectGenerator.ROUTE_NAME,
        MasterplanRouteObjectGenerator.ROUTE_NAME
      )
    }

    spin360Data.videoTransitions?.forEach((stopPoint) => {
      const routeObjectFrom =
        MASTERPLAN_ROUTE_OBJECTS[stopPoint.stopPointFromSlug]
      const routeFrom = router.resolve(routeObjectFrom).resolved.path

      const routeObjectTo = MASTERPLAN_ROUTE_OBJECTS[stopPoint.stopPointToSlug]
      const routeTo = router.resolve(routeObjectTo).resolved.path

      const transitionParamsObject = TransitionParamsFactory.make('video', {
        url: stopPoint.videoUrl,
      })

      transitionTree.add(transitionParamsObject, routeFrom, routeTo)
    })
  },
  addTransitionsBasedOnStore({ getters, rootGetters }) {
    const transitionTree = getters.transitionTree
    const svgData = rootGetters['Topview/projectSvgData']
    const svgElementsWithTransitionVideo = svgData.elements.filter(
      (element) => element.enterTransitionVideos?.list.length > 0
    )
    svgElementsWithTransitionVideo.forEach((element) => {
      try {
        const routeObjectGenerator = RouteObjectGeneratorFactory.make(
          element.projectElementType
        )
        const routeObject = routeObjectGenerator.make(element)
        const destinationRoutePath = router.resolve(routeObject).resolved.path

        element.enterTransitionVideos.list.forEach((enterTransitionVideo) => {
          const transitionParamsObject = TransitionParamsFactory.make(
            VideoTransitionParams.TYPE,
            { url: enterTransitionVideo.url }
          )

          const originRouteObject = MasterplanRouteObjectGenerator.make({
            stopPointSlug: enterTransitionVideo.stopPointSlug,
          })
          const originRoutePath = router.resolve(originRouteObject).resolved
            .path
          transitionTree.add(
            transitionParamsObject,
            originRoutePath,
            destinationRoutePath
          )
        })
      } catch (error) {
        this.$loggingService.logError(
          `DownloadManager/addTransitionsBasedOnStore: error creating transition params. ${error.toString()}`
        )
      }
    })
  },
  async downloadSprites({ commit }, sprites) {
    const downloadPromises = []
    commit('storeSpritesReady', false)
    sprites.forEach((sprite) => {
      downloadPromises.push(
        httpClient({
          url: sprite.imgUrl,
          method: 'GET',
          responseType: 'blob',
        })
          .then((response) => {
            const spriteImageBlob = window.URL.createObjectURL(
              new Blob([response.data], { type: 'image/jpeg' })
            )
            sprite.sortedIncludedImages.forEach((imgUrl, index) => {
              const spriteClip = {
                correspondingImageUrl: imgUrl,
                spriteImageBlob,
                spriteOrder: index,
              }
              commit('storeSpriteClip', spriteClip)
            })
          })
          .catch((error) => {
            throw new Error(
              `DownloadManager/downloadSprites: Error while downloading a sprite with imgUrl='${sprite.imgUrl}': ${error}`
            )
          })
      )
    })
    await Promise.all(downloadPromises)
    commit('storeSpritesReady', true)
  },
}

export default DownloadManagerActions
