<template>
  <section class="container">
    <h1>TextButton</h1>
    <table>
      <tr>
        <th></th>
        <th v-for="alignment in alignments" :key="alignment">
          {{ alignment }}
        </th>
      </tr>
      <tr v-for="style in styles" :key="style">
        <th>{{ style }}</th>
        <th v-for="alignment in alignments" :key="alignment + style">
          <AppSheetTitle
            :title="exampleTitle"
            :subtitle="exampleSubtitle"
            :align="alignment"
            :primary="style === 'primary'"
          />
        </th>
      </tr>
    </table>
  </section>
</template>
<script>
import AppSheetTitle from '@/components/UIKit/Standard/Atoms/AppSheetTitle'
import alignments from '@/constants/ui-kit/standard/alignments'

export default {
  name: 'TitleViewer',
  components: {
    AppSheetTitle,
  },
  data() {
    return {
      exampleTitle: 'Título de prueba',
      exampleSubtitle: 'Subtitulo de prueba para el átomo Título',
      alignments,
      styles: ['default', 'primary'],
    }
  },
}
</script>
<style lang="scss" scoped>
.container {
  margin: 80px 50px;
}
h1 {
  font-size: 1.5rem;
  margin: 2.5em 0 1.5em;
  border-bottom: 1px solid black;
}
th {
  font-weight: 400;
  font-size: 0.75rem;
}
th,
td {
  padding: 5px 10px;
}
</style>
