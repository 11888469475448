import BaseMenuItem from '@/classes/Navigation/MenuItem/BaseMenuItem'
import LinkMenuOption from '@/classes/Navigation/MenuOption/LinkMenuOption'
import router from '@/router'

export default class LinkMenuItem extends BaseMenuItem {
  constructor(option) {
    if (!(option instanceof LinkMenuOption)) {
      throw new Error(
        'LinkMenuItem.constructor: Option passed is not an instance of class LinkMenuOption'
      )
    }

    super(option)

    this._routeName = option.routeName
    this._routeParams = option.routeParams
    this._exact = option.exact
  }

  setOrUpdateRouteParam(param, value) {
    if (!this._routeParams) {
      this._routeParams = {}
    }
    this._routeParams[param] = value
  }
  setMatchMethod(matchMethod) {
    if (typeof matchMethod !== 'function') {
      throw new Error(
        `MenuItem.setMatchMethod(): matchMethod ${matchMethod} is not a valid function.`
      )
    }
    this._customMatchMethod = matchMethod
  }
  match(route) {
    return this._defaultMatchMethod(route) || this._customMatchMethod(route)
  }
  _defaultMatchMethod(route) {
    const resolvedRoute = router.resolve(this.routeObject).resolved
    return (
      resolvedRoute.path === route.path ||
      (!this._exact && resolvedRoute.name === route.name)
    )
  }

  get routeName() {
    return this._routeName
  }
  get routeParams() {
    return this._routeParams
  }
  get routeObject() {
    return {
      name: this._routeName,
      params: this._routeParams,
    }
  }
  get exact() {
    return this._exact
  }
}
