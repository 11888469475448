<template>
  <div class="title" :class="[alignmentClassName, primaryStyleClassName]">
    <h3 class="highlight-text">
      {{ title }}
    </h3>
    <p v-if="subtitle" class="descriptive-text">
      {{ subtitle }}
    </p>
  </div>
</template>
<script>
import alignments from '@/constants/ui-kit/standard/alignments'

export default {
  name: 'AppSheetTitle',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: undefined,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    align: {
      type: String,
      default: alignments.ALIGN_LEFT,
      validator: (value) => Object.values(alignments).includes(value),
    },
  },
  computed: {
    alignmentClassName() {
      return `title--${this.align}`
    },
    primaryStyleClassName() {
      return this.primary ? 'title--primary' : ''
    },
  },
}
</script>
<style lang="scss" scoped>
.title {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .highlight-text {
    font-size: 1rem;
    font-weight: $font-weight-medium;
    margin: 0;
    color: var(--gray-700);
  }
  .descriptive-text {
    white-space: pre-line;
    font-size: 1rem;
    font-weight: $font-weight-regular;
    margin: 0;
    color: var(--gray-500);
  }

  @at-root #{&}--#{$align-center} {
    text-align: center;
    align-items: center;
  }
  @at-root #{&}--#{$align-left} {
    text-align: start;
    align-items: flex-start;
  }
  @at-root #{&}--#{$align-right} {
    text-align: end;
    align-items: flex-end;
  }

  @at-root &--#{$primary} {
    .highlight-text {
      color: var(--primary-color);
    }
  }
}
</style>
