import Locale from './Locale'
import LocaleLoadError from '@/classes/Exceptions/LocaleLoadError'

class LocaleLoader {
  constructor() {
    this._supportedLocales = new Map()
    this._loadedLocales = new Map()
    this._setSupportedLocales()
  }
  _setSupportedLocales() {
    this._supportedLocales.set('es-PA', 'es-PA.json')
    this._supportedLocales.set('nl-NL', 'nl-NL.json')
    this._supportedLocales.set('es-MX', 'es-MX.json')
    this._supportedLocales.set('en-US', 'en-US.json')
    this._supportedLocales.set('vi-VN', 'vi-VN.json')
    this._supportedLocales.set('he-IL', 'he-IL.json')
    this._supportedLocales.set('fr-FR', 'fr-FR.json')
  }
  localeIsLoaded(code) {
    return this._loadedLocales.get(code) !== undefined
  }
  localeIsSupported(code) {
    return this._supportedLocales.get(code) !== undefined
  }
  load(code) {
    let filePath = null
    try {
      if (!this.localeIsSupported(code)) {
        throw new Error('Locale is not supported')
      }
      if (this.localeIsLoaded(code)) {
        throw new Error('Locale already loaded')
      }
      filePath = this._supportedLocales.get(code)
      const localeObject = require(`../locales/${filePath}`)
      this._loadedLocales.set(code, new Locale(code, localeObject.messages))
    } catch (error) {
      throw new LocaleLoadError(code, filePath, error)
    }
  }
  getLocale(code) {
    return this._loadedLocales.get(code)
  }
  get localesMessages() {
    let messages = {}
    for (let [code, localeObject] of this._loadedLocales) {
      messages = {
        ...messages,
        [code]: localeObject.messages,
      }
    }
    return messages
  }
  get supportedLocalesCodes() {
    return Array.from(this._supportedLocales.keys())
  }
}
export default LocaleLoader
