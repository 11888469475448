<template>
  <div class="menu-container">
    <AppOverlay v-if="!menuCollapsed" @click.native.prevent="closeMenu" />
    <AppTooltip
      class="menu-container__button"
      :text="$t('menu.tooltip')"
      :placement="tooltipPlacement"
    >
      <AppButtonIcon
        :icon="menuButtonIcon"
        :button-style="menuButtonStyle"
        @click.native="openMenu()"
      />
    </AppTooltip>
    <transition name="menu-slide" @enter="updateMenuWidth">
      <AppScrollbarContainer
        v-if="!menuCollapsed"
        class="menu-container__menu__scroll"
      >
        <AppMenu
          class="menu-container__menu"
          :menu="menuData"
          :logo-image-url="logoImageUrl"
          :facebook-url="contactData.facebookUrl"
          :instagram-url="contactData.instagramUrl"
          :whatsapp-url="contactData.whatsAppUrl"
          :developer-website-link="developerWebsiteLink"
          :developer-brochure-url="developerBrochureUrl"
          :with-copyright="displayMenuCredits"
          :copyright-with-link="!isOfflineModeEnabled"
          :limited-connection="isConnectionLimited"
          @close="closeMenu"
        />
      </AppScrollbarContainer>
    </transition>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import AppMenu from '@/components/UIKit/Standard/Organisms/AppMenu'
import AppOverlay from '@/components/UIKit/Standard/Atoms/AppOverlay'
import responsiveMixin from '@/mixins/responsiveMixin'
import onlineStatusMixin from '@/mixins/onlineStatusMixin'
import AppButtonIcon from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonIcon'
import AppTooltip from '@/components/UIKit/Standard/Molecules/AppTooltip'
import AppScrollbarContainer from '@/components/UIKit/Standard/Atoms/AppScrollbarContainer'
import iconNames from '@/constants/ui-kit/standard/icon-names'
import styles from '@/constants/ui-kit/standard/styles'
import sizes from '@/constants/ui-kit/standard/sizes'
import tooltipPlacements from '@/constants/ui-kit/standard/tooltip-placements'

export default {
  name: 'TheMenu',
  components: {
    AppMenu,
    AppOverlay,
    AppButtonIcon,
    AppTooltip,
    AppScrollbarContainer,
  },
  mixins: [responsiveMixin, onlineStatusMixin],
  data: () => {
    return {
      menuCollapsed: true,
      menuButtonStyle: styles.PRIMARY,
      menuButtonSize: sizes.SM,
      menuButtonIcon: iconNames.UIMenu,
      tooltipPlacement: tooltipPlacements.BOTTOM_START,
    }
  },
  computed: {
    ...mapGetters({
      menuData: 'Layout/menuData',
      contactData: 'Project/contactData',
      logoData: 'Project/logo',
      displayMenuCredits: 'Preferences/displayMenuCredits',
      developerWebsiteLink: 'Project/developerWebsiteLink',
      developerBrochureUrl: 'Project/developerBrochureUrl',
      isOfflineModeEnabled: 'Project/isOfflineModeEnabled',
    }),
    logoImageUrl() {
      return this.logoData.display ? this.logoData.imgUrl : undefined
    },
    isConnectionLimited() {
      return this.isOfflineModeEnabled && !this.isOnline
    },
  },
  watch: {
    $route() {
      this.closeMenu()
    },
  },
  methods: {
    openMenu() {
      if (this.menuCollapsed) {
        this.menuCollapsed = false
      }
    },
    closeMenu() {
      if (!this.menuCollapsed) {
        this.menuCollapsed = true
        this.$store.dispatch('Layout/setMenuWidth', 0)
      }
    },
    updateMenuWidth() {
      const menuWidth = this.$refs.menu ? this.$refs.menu.offsetWidth : 0
      this.$store.dispatch('Layout/setMenuWidth', menuWidth)
    },
  },
}
</script>
<style lang="scss" scoped>
.menu-container {
  user-select: none;

  @at-root &__button {
    position: fixed;
    top: 16px;
    left: 16px;
    transform: translate3d(var(--menu-offset), 0, 0);
  }

  @at-root &__menu {
    min-height: 100%;
    min-height: $inner-height;

    @at-root &__scroll {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
      background-color: #ffffff;
      width: 360px;
      @include inner-height;
      overflow: auto;

      @include small-screens {
        width: 350px;
        max-width: calc(100vw - 25px);
      }
    }
  }

  --open-menu-transition: transform 450ms cubic-bezier(0, 0.6, 0.4, 1);
  --close-menu-transition: transform 600ms ease;
  .menu-slide-enter-active,
  .menu-slide-leave-active {
    transition: var(--open-menu-transition);
  }
  .menu-slide-enter,
  .menu-slide-leave-to {
    transform: translate3d(-100%, 0, 0);
  }
  .menu-slide-leave-to {
    transition: var(--close-menu-transition);
  }
}
</style>
