import BaseMediaResource from './BaseMediaResource'

class ImageResource extends BaseMediaResource {
  constructor(galleryResourceData) {
    super(galleryResourceData)
    this._svgLayerUrl = undefined
    this._animationLayerVideoUrl = undefined
  }
  get imgUrl() {
    return this.resourceUrl
  }
  setSvgLayer(svgUrl) {
    if (typeof svgUrl !== 'string' || svgUrl.trim() === '') {
      throw new Error(
        `ImageResource.setSvgLayer(): SVG url ${svgUrl} is not a valid string`
      )
    }
    this._svgLayerUrl = svgUrl
  }
  setAnimationLayerVideoUrl(animationVideoUrl) {
    if (
      typeof animationVideoUrl !== 'string' ||
      animationVideoUrl.trim() === ''
    ) {
      throw new Error(
        `ImageResource.setAnimationLayerVideoUrl(): animation video url ${animationVideoUrl} is not a valid string`
      )
    }
    this._animationLayerVideoUrl = animationVideoUrl
  }
  get svgLayerUrl() {
    return this._svgLayerUrl
  }
  get animationLayerVideoUrl() {
    return this._animationLayerVideoUrl
  }
}
export default ImageResource
