import ColorPalleteViewer from '@/components/UIKit/Standard/ViewerSections/ColorPalleteViewer'
import ButtonIconViewer from '@/components/UIKit/Standard/ViewerSections/ButtonIconViewer'
import ButtonIconRouterLinkViewer from '@/components/UIKit/Standard/ViewerSections/ButtonIconRouterLinkViewer'
import ButtonTextViewer from '@/components/UIKit/Standard/ViewerSections/ButtonTextViewer'
import ButtonTextRouterLinkViewer from '@/components/UIKit/Standard/ViewerSections/ButtonTextRouterLinkViewer'
import AppCopyrightViewer from '@/components/UIKit/Standard/ViewerSections/AppCopyrightViewer'
import AppButtonTextWithIconViewer from '@/components/UIKit/Standard/ViewerSections/AppButtonTextWithIconViewer'
import BadgeViewer from '@/components/UIKit/Standard/ViewerSections/BadgeViewer'
import FeatureListViewer from '@/components/UIKit/Standard/ViewerSections/FeatureListViewer'
import LevelsMenuViewer from '@/components/UIKit/Standard/ViewerSections/LevelsMenuViewer'
import TitleViewer from '@/components/UIKit/Standard/ViewerSections/TitleViewer'
import IconsViewer from '@/components/UIKit/Standard/ViewerSections/IconsViewer'
import PictureViewer from '@/components/UIKit/Standard/ViewerSections/PictureViewer'
import PreviewImageViewer from '@/components/UIKit/Standard/ViewerSections/PreviewImageViewer'
import SheetContentProjectElementViewer from '@/components/UIKit/Standard/ViewerSections/SheetContentProjectElementViewer'
import AppSheetProjectElementLargeViewer from '@/components/UIKit/Standard/ViewerSections/AppSheetProjectElementLargeViewer'
import AppSheetUnitLargeViewer from '@/components/UIKit/Standard/ViewerSections/AppSheetUnitLargeViewer'
import AppSheetUnitSmallViewer from '@/components/UIKit/Standard/ViewerSections/AppSheetUnitSmallViewer'
import AppSheetUnitMediumViewer from '@/components/UIKit/Standard/ViewerSections/AppSheetUnitMediumViewer'
import AppSheetUnitMediumAmenitiesViewer from '@/components/UIKit/Standard/ViewerSections/AppSheetUnitMediumAmenitiesViewer'
import AppSheetUnitViewer from '@/components/UIKit/Standard/ViewerSections/AppSheetUnitViewer'
import AppSheetAmenitiesViewer from '@/components/UIKit/Standard/ViewerSections/AppSheetAmenitiesViewer'
import SheetPriceViewer from '@/components/UIKit/Standard/ViewerSections/SheetPriceViewer'
import DropdownViewer from '@/components/UIKit/Standard/ViewerSections/DropdownViewer'
import ShareMenuViewer from '@/components/UIKit/Standard/ViewerSections/ShareMenuViewer'
import FlapViewer from '@/components/UIKit/Standard/ViewerSections/FlapViewer'
import FlapDropdownViewer from '@/components/UIKit/Standard/ViewerSections/FlapDropdownViewer'
import AppSheetContactInformationViewer from '@/components/UIKit/Standard/ViewerSections/AppSheetContactInformationViewer'
import AppSubmenuTitleViewer from '@/components/UIKit/Standard/ViewerSections/AppSubmenuTitleViewer'
import AppSocialNetworkButtonsViewer from '@/components/UIKit/Standard/ViewerSections/AppSocialNetworkButtonsViewer'
import AppOverlayViewer from '@/components/UIKit/Standard/ViewerSections/AppOverlayViewer'
import AppMenuItemViewer from '@/components/UIKit/Standard/ViewerSections/AppMenuItemViewer'
import AppTooltipElementViewer from '@/components/UIKit/Standard/ViewerSections/AppTooltipElementViewer'
import AppTooltipViewer from '@/components/UIKit/Standard/ViewerSections/AppTooltipViewer'
import AppMenuItemsListViewer from '@/components/UIKit/Standard/ViewerSections/AppMenuItemsListViewer'
import AppMenuViewer from '@/components/UIKit/Standard/ViewerSections/AppMenuViewer'
import AppMinimapViewer from '@/components/UIKit/Standard/ViewerSections/AppMinimapViewer'
import AppUnitCardViewer from '@/components/UIKit/Standard/ViewerSections/AppUnitCardViewer'
import AppQuoteWizardViewer from '@/components/UIKit/Standard/ViewerSections/AppQuoteWizardViewer'
import AppQuoterPDFContentViewer from '@/components/UIKit/Standard/ViewerSections/AppQuoterPDFContentViewer'

export default [
  {
    category: 'System',
    components: {
      ColorPallete: ColorPalleteViewer,
      Icons: IconsViewer,
    },
  },
  {
    category: 'Atoms',
    components: {
      ButtonText: ButtonTextViewer,
      ButtonTextRouterLink: ButtonTextRouterLinkViewer,
      ButtonIcon: ButtonIconViewer,
      ButtonIconRouterLink: ButtonIconRouterLinkViewer,
      ButtonFlap: FlapViewer,
      Copyright: AppCopyrightViewer,
      ButtonTextWithIcon: AppButtonTextWithIconViewer,
      Badge: BadgeViewer,
      AppImage: PictureViewer,
      SheetTitle: TitleViewer,
      SheetPrice: SheetPriceViewer,
      Overlay: AppOverlayViewer,
      SubmenuTitle: AppSubmenuTitleViewer,
      MenuItem: AppMenuItemViewer,
      TooltipElement: AppTooltipElementViewer,
      Tooltip: AppTooltipViewer,
    },
  },
  {
    category: 'Molecules',
    components: {
      PreviewImage: PreviewImageViewer,
      FeatureList: FeatureListViewer,
      Dropdown: DropdownViewer,
      FlapDropdown: FlapDropdownViewer,
      ShareMenu: ShareMenuViewer,
      LevelsMenu: LevelsMenuViewer,
      SheetContactInformation: AppSheetContactInformationViewer,
      SocialNetworkButtons: AppSocialNetworkButtonsViewer,
      Tooltip: AppTooltipViewer,
      MenuItemsList: AppMenuItemsListViewer,
      MinimapViewer: AppMinimapViewer,
    },
  },
  {
    category: 'Organisms',
    components: {
      Menu: AppMenuViewer,
      SheetProjectElement: SheetContentProjectElementViewer,
      SheetUnit: AppSheetUnitViewer,
      SheetAmenities: AppSheetAmenitiesViewer,
      SheetProjectElementLarge: AppSheetProjectElementLargeViewer,
      SheetUnitLarge: AppSheetUnitLargeViewer,
      SheetUnitMedium: AppSheetUnitMediumViewer,
      SheetUnitMediumAmenities: AppSheetUnitMediumAmenitiesViewer,
      SheetUnitSmall: AppSheetUnitSmallViewer,
      UnitCard: AppUnitCardViewer,
      QuoteWizard: AppQuoteWizardViewer,
      QuoterPDFContent: AppQuoterPDFContentViewer,
    },
  },
]
