<template>
  <section>
    <VTitle text="AppQuoteWizard" />
    <div class="example-component">
      <div class="example-component__controls">
        <VPropControl
          v-for="(propModel, propName) in propModels"
          :key="propName"
          :model="propModel"
          :name="propName"
        />
      </div>
      <div class="example-component__component">
        <AppQuoteWizard
          :key="propModels.unit.value.id"
          :unit="propModels.unit.value"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import AppQuoteWizard from '@/components/UIKit/Standard/Organisms/AppQuoteWizard/AppQuoteWizard'
import AppPropModel from '@/components/UIKit/Standard/ViewerSections/classes/AppPropModel'
import VTitle from '@/components/UIKit/Standard/ViewerSections/components/snippets/VTitle'
import VPropControl from '@/components/UIKit/Standard/ViewerSections/components/snippets/VPropControl'
import ApartmentUnit from '@/classes/ProjectStructure/Unit/ApartmentUnit'
import OfficeUnit from '@/classes/ProjectStructure/Unit/OfficeUnit'
import ShopUnit from '@/classes/ProjectStructure/Unit/ShopUnit'
import HouseUnit from '@/classes/ProjectStructure/Unit/HouseUnit'

const acceptedUnitTypes = [ApartmentUnit, OfficeUnit, ShopUnit, HouseUnit]

export default {
  name: 'AppQuoteWizardViewer',
  components: {
    AppQuoteWizard,
    VTitle,
    VPropControl,
  },
  data() {
    return {
      propModels: {
        unit: new AppPropModel({
          type: acceptedUnitTypes,
          required: true,
          defaultValue: new ApartmentUnit({
            slug: 'test-apartment-unit',
            label: 'apartment Test',
            svgTargetElementId: 'a1',
            name: 'apartment Test',
            model: 'Model B',
            id: '1',
          }),
        }),
      },
    }
  },
  computed: {
    ...mapGetters({
      units: 'Topview/units',
      getUnitPrice: 'Topview/getUnitPrice',
    }),
    validUnits() {
      return this.units.filter((unit) => {
        return (
          acceptedUnitTypes.some((UnitType) => unit instanceof UnitType) &&
          unit.available &&
          this.getUnitPrice(unit)
        )
      })
    },
  },
  created() {
    this.propModels.unit.updateDefault(this.validUnits[0])
    this.validUnits.forEach((unit) => {
      this.propModels.unit.addPreset(unit, unit.name)
    })
  },
}
</script>

<style lang="scss" scoped>
.example-component {
  display: flex;
  justify-content: stretch;
  align-items: stretch;

  @at-root &__controls {
    background: #fff;
    padding: 30px;
    border: 1px solid var(--gray-200);
    flex-basis: 300px;
    border-radius: 12px;
  }

  @at-root &__component {
    flex: 1;
    padding: 0 30px;
  }
}
</style>
