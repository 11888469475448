import ProjectConstants from '@/constants/project-structure/project-constants'
import SingleTowerUnitQuoteFileNameGenerator from './SingleTowerUnitQuoteFileNameGenerator'
import MultiTowerUnitQuoteFileNameGenerator from './MultiTowerUnitQuoteFileNameGenerator'
import NeighbourhoodUnitQuoteFileNameGenerator from './NeighbourhoodUnitQuoteFileNameGenerator'
import ProjectType from '@/classes/ProjectType'

const { PROJECT_TYPES } = ProjectConstants

class UnitQuoteFileNameGeneratorFactory {
  static make(i18n) {
    const projectType = new ProjectType()
    const projectTypeUnitQuoteFileNameGeneratorMap = {
      [PROJECT_TYPES.SINGLETOWER_TYPE]: () =>
        SingleTowerUnitQuoteFileNameGenerator,
      [PROJECT_TYPES.MULTITOWER_TYPE]: () =>
        MultiTowerUnitQuoteFileNameGenerator,
      [PROJECT_TYPES.NEIGHBOURHOOD_TYPE]: () =>
        NeighbourhoodUnitQuoteFileNameGenerator,
    }
    const UnitQuoteFileNameGenerator = projectType.switcher(
      projectTypeUnitQuoteFileNameGeneratorMap
    )
    if (!UnitQuoteFileNameGenerator) {
      throw new Error(
        `UnitQuoteFileNameGeneratorFactory: passed projectType ${projectType.name} is not mapped.`
      )
    }
    return new UnitQuoteFileNameGenerator(i18n)
  }
}
export default UnitQuoteFileNameGeneratorFactory
