export default () => ({
  selectedSvgElement: undefined,

  svgElementToPreview: undefined,
  hidePreviewDelay: null,

  svgWidth: undefined,
  svgHeight: undefined,

  previewPositionX: undefined,
  previewPositionY: undefined,
})
