<template>
  <AppBaseModal :title="title" :modal-width="960" v-on="$listeners">
    <AppScrollbarContainer class="disclaimer-modal">
      <AppLegalText class="text-container" :html-content="disclaimer" />
    </AppScrollbarContainer>
  </AppBaseModal>
</template>

<script>
import AppScrollbarContainer from '@/components/UIKit/Standard/Atoms/AppScrollbarContainer'
import AppLegalText from '@/components/UIKit/Standard/Atoms/AppLegalText'
import AppBaseModal from '@/components/UIKit/Standard/Atoms/AppBaseModal'
import { mapGetters } from 'vuex'

export default {
  name: 'AppDisclaimerModal',
  components: {
    AppLegalText,
    AppBaseModal,
    AppScrollbarContainer,
  },
  data() {
    return {
      title: this.$t('Disclaimer'),
    }
  },
  computed: {
    ...mapGetters({
      disclaimer: 'Project/disclaimer',
    }),
  },
}
</script>

<style lang="scss" scoped>
.disclaimer-modal {
  max-height: max(50vh, 385px);
  padding-right: 10px;
  overflow: auto;

  @include small-screens {
    padding-right: 0;
    max-height: none;
  }
}
</style>
