export default {
  updateImageZoom(state, zoomValue) {
    state.imageZoom = zoomValue
  },
  updateImagePosition(state, { positionX, positionY }) {
    if (positionX !== undefined) {
      state.imagePositionX = positionX
    }
    if (positionY !== undefined) {
      state.imagePositionY = positionY
    }
  },
  updateGestureDragActive(state, isGestureDragActiveValue) {
    state.gestureDragActive = isGestureDragActiveValue
  },
  updateGesturePinchOrSpreadActive(state, isGesturePinchOrSpreadActiveValue) {
    state.gesturePinchOrSpreadActive = isGesturePinchOrSpreadActiveValue
  },
}
