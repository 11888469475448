import Hint from './Hint'

class RotateDeviceHint extends Hint {
  constructor() {
    super({
      component: 'RotateDeviceHint',
      transition: 'hint-fade',
      maxShowCount: 1,
    })
  }
}
export default RotateDeviceHint
