import BasePrice from './BasePrice'
import PriceError from '@/classes/Exceptions/PriceError'

class SalePrice extends BasePrice {
  constructor({ amount, currencyCode, rentPaymentPeriod }) {
    super({ amount, currencyCode })
    this._className = 'SalePrice'

    if (rentPaymentPeriod) {
      throw new PriceError('Unexpected rentPaymentPeriod received.', this)
    }
  }
  get rentPaymentPeriod() {
    throw new PriceError(
      'rentPaymentPeriod is not available in SalePrice.',
      this
    )
  }
}
export default SalePrice
